import React, { useEffect, useState } from 'react'
import NavStripBar from '../_components/NavStripBar'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import { Formik } from 'formik'
import { TextFieldIcon } from '../../_components'
import { CircularProgress } from '@material-ui/core'
import Button from '../_components/Button'
import { API_BASE } from '../../_utils'


export default function ResetPassword() {
  const navigate = useNavigate()
  const [ token, setToken ] = useState( "" )
  const [ emailAddress, setEmail ] = useState( "" )

  useEffect( () => {
    let queryParameters = new URLSearchParams( window.location.search )
    let token = queryParameters.get( "token" )
    let email = queryParameters.get( "email" )
    
    if( token ) {
        setToken( token )
    }
    if( token ) {
      setEmail( email )
  }
  }, [])

  return (
   <div className="w-screen h-screen bg-gray-50">
      <NavStripBar />
      <div className="px-5 sm:px-8 md:px-0 lg:px-0">
        <Form navigate={navigate} token={token} emailAddress={emailAddress}/>
      </div>
   </div>
  )
}

const Form = ( { navigate, token, emailAddress } ) => {
    const [ signing, setSigning ] = useState( false )
  
    const handleSubmit = async ( values ) => {
        if( values.password?.toLowerCase()   !== values?.confirm_password?.toLowerCase() ) {
            return alert( "Passwords don't match. Make sure your passwords match." )
        }
      try {
        setSigning( true )
        const response = await Axios.post( `${API_BASE}/account/password/reset`, {
          token,
          email: emailAddress,
          password: values.password,
          password_confirmation: values.password
        } )
  
        if( response.data ){
          await localStorage.removeItem( "email_reset" )
          alert( "Your password has been successfully reset. Login with your new password." );
          navigate( "/login" )
        }
      } catch (error) {
       if( error?.response ) {
        const { status, statusText } =  error.response
        if( status === 401 ) {
          alert( `${statusText} - Your account may not exist yet.` )
        }
        else {
          alert( error.response?.data?.message )
        }
       }
       else {
        alert( "You may have an internet connection issue. Check your network connectivity." )
       }
       setSigning( false )
      }
    }
  
    return (
      <div className="py-5">
        <div className="w-full h-full flex items-center justify-center">
          <div className= "md:p-12 lg:p-12 p-5 lg:w-2/5 md:w-2/5 w-full h-auto bg-white rounded shadow-md mx-2">
            <div className="flex flex-col items-center justify-center">
              <h2 className="font-bold px-3 py-2 text-center md:text-xl lg:text-xl text-base">
                {`Change your Account Password`}
              </h2>
            </div>
            <Formik initialValues={{
                email: emailAddress || "",
                password: "",
                confirm_password: ""
            }} onSubmit={handleSubmit}>
                { ( { handleSubmit, handleBlur, handleChange, values, isSubmitting } ) => {
                  return (
                      <form onSubmit={handleSubmit}>

                        <TextFieldIcon 
                          name="email"
                          type="email"
                          label="Email Address"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={emailAddress}
                          required={true}
                          disabled={true}
                          question="Email Address"
                        />
                        
                        <TextFieldIcon 
                          name="password"
                          type="password"
                          label="Password"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.password}
                          required={true}
                          disabled={signing}
                          question="Password"
                        />

                        <TextFieldIcon 
                          name="confirm_password"
                          type="password"
                          label="Password"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.confirm_password}
                          required={true}
                          disabled={signing}
                          question="Confirm Password"
                        />
  
                        <div className="w-full py-2 grid grid-cols-1">
                            {signing ? (
                            <div className="flex items-center justify-center">
                              <CircularProgress size={24} color="primary" />
                            </div>
                            ):
                              ( 
                            <div className="flex flex-col">
                              <Button 
                                type="submit"
                                loading={isSubmitting} 
                                disabled={isSubmitting} 
                                label={isSubmitting ? "Submitting..." : "Submit"} />
  
                                {/* <div className="flex flex-col items-center justify-center">
                                    <div className="cursor-pointer text-xs md:text-sm lg:text-sm my-2 hover:text-red-500 py-2" onClick={() => navigate( "/register" )}>
                                      {`Don't have an account? Request Sign Up`}
                                    </div>
                                    <div className="cursor-pointer hover:text-blue-800" onClick={() => navigate("/change-password")}>
                                      <p className="text-xs md:text-sm lg:text-sm my-1">
                                        {`Forgot Password?`}
                                        <span className="px-2 underline">{`Reset your password`}</span>
                                      </p>
                                    </div>
                                </div> */}
                            </div> 
                            )
                            }
                        </div>
                      </form>
                  )
                }  }
            </Formik>
          </div>
          </div>
        <div> 
        </div>
      </div>
    )
}