// export const API_BASE = `https://api.ebiz.go.ug/api`
export const API_BASE = `https://mis.nec.go.ug/api/auth`

export const handleError = ( error ) => {
    if( error.response?.data ) {
        return alert( error.response?.data?.message )
    }
    else {
        return alert( "Server error occured or Network connection error occured. Please try again later." )
    }
}


export const sanitizeValue = ( val ) => {
    return val?.replace(/([^\w ]|_)/g, '')
}