import React, { useEffect, useState } from 'react'
import { Wrap, Panel, TextAreaIcon, TextFieldIcon, SelectFieldIcon, PhoneFieldIcon, TextField } from '../../../../_components'
import { API_BASE, truncate, convertFileToBase } from '../../../../_utils'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setDisplayModal, setEducationList, setEngineeringList, setPracticalList, setPositionList, setMembershipList } from '../../../../_slices/appSlice'

export default function SectionB({ handleChange, handleBlur , values}) {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const state = useSelector( state => {
    return {
      education: state?.application?.education,
      engineering: state?.application?.engineering,
      practicals:  state?.application?.practicals,
      positions:  state?.application?.positions,
      membership:  state?.application?.membership,
      flag: state?.application?.displayModal?.flag
    }
  } )

  const [passportFile, setPassportFile] = useState(null);
  const [uceFile, setUCEFile] = useState(null);
  const [uaceFile, setUACEFile] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [transcriptFile, setTranscriptFile] = useState(null);
  const [trainings, setTrainings] = useState(['', '']);
  const [ inputs, setInputs ] = useState( {} )

  const [ display, setDisplay ] = useState( false )
  const [ displayEngineering, setDisplayEngineering ] = useState( false )
  const [ displayPractical, setDisplayPractical ] = useState( false )
  const [ displayPosition, setDisplayPosition ] = useState( false )
  const [ displayMembership, setDisplayMembership ] = useState( false )

  const [ education, setEducation ] = useState( {} )
  const [ engineering, setEngineering ] = useState( {} )
  const [ practical, setPractical ] = useState( {} )
  const [ position, setPosition ] = useState( {} )
  const [ membership, setMembership ] = useState( {} )

  const [ file, setFile] = useState( null )
  const [ fileEngineering, setEngineeringFile] = useState( null )
  const [ membershipFile, setMembershipFile ] = useState( null )
  const [ filePosition, setPositionFile] = useState( null )

  const [ GeneralEducation, setGeneralEducation ] = useState( [] )

  useEffect( () => {
    if( education?.attach_file ){
      handleFile( education?.attach_file );
    }
  }, [ education ])

  useEffect( () => {
    if( engineering?.attach_file ){
      handleEngineeringFile( engineering?.attach_file );
    }
  }, [ engineering ])

  useEffect( () => {
    if( membership?.attach_file ){
      handleMembershipFile( membership?.attach_file );
    }
  }, [ membership ])

  useEffect( () => {
    if( position?.attach_file ){
      handlePositionFile( position?.attach_file );
    }
  }, [ position ])


  const handleFile = ( obj  ) => {
    convertFileToBase(obj, response => {
      setFile( response )
    } )
  }

  const handleEngineeringFile = ( obj  ) => {
    convertFileToBase(obj, response => {
      setEngineeringFile( response )
    } )
  }

  const handleMembershipFile = ( obj  ) => {
    convertFileToBase(obj, response => {
      setMembershipFile( response )
    } )
  }

  const handlePositionFile = ( obj  ) => {
    convertFileToBase(obj, response => {
      setPositionFile( response )
    } )
  }

  const handleInputChange = async ( evt ) => {
    setEducation( prevState => {
      return { ...prevState, [ evt.target.name ] : evt.target.name === "attach_file" ? evt.target.files :  evt.target.value }
    } )
  }

  const handleInputChangeEngineeering = async ( evt ) => {
    setEngineering( prevState => {
      return { ...prevState, [ evt.target.name ] : evt.target.name === "attach_file" ? evt.target.files :  evt.target.value }
    } )
  }

  const handleInputChangeMembership = async ( evt ) => {
    setMembership( prevState => {
      return { ...prevState, [ evt.target.name ] : evt.target.name === "attach_file" ? evt.target.files :  evt.target.value }
    } )
  }

  const handleInputChangePractical = async ( evt ) => {
    setPractical( prevState => {
      return { ...prevState, [ evt.target.name ] : evt.target.name === "attach_file" ? evt.target.files :  evt.target.value }
    } )
  }

  const handleInputChangePositions = async ( evt ) => {
    setPosition( prevState => {
      return { ...prevState, [ evt.target.name ] : evt.target.name === "attach_file" ? evt.target.files :  evt.target.value }
    } )
  }

  const handleOnSave = async () => {
    await dispatch( setEducationList( [ ...state.education, {
      start_date: education?.start_date,
      qualification: education?.qualification,
      institution: education?.institution,
      attach_file: file
    } ] ) )

    setDisplay( !display )
  }

  const handleOnSaveEngineering = async () => {
    await dispatch( setEngineeringList( [ ...state.engineering, {
      start_date: engineering?.start_date,
      institution: engineering?.institution,
      attach_file: fileEngineering
    } ] ) )

    setDisplayEngineering( !displayEngineering )
  }

  const handleOnSavePractical = async () => {
    await dispatch( setPracticalList( [ ...state.practicals, {
      start_date: practical?.start_date,
      end_date: practical?.end_date,
      organisation: practical?.organisation,
      cadre: practical?.cadre
    } ] ) )

    setDisplayPractical( !displayPractical )
  }

  const handleOnSavePosition = async () => {
    await dispatch( setPositionList( [ ...state.positions, {
      start_date: position?.start_date,
      end_date: position?.end_date,
      organisation: position?.organisation,
      cadre: position?.cadre
    } ] ) )

    setDisplayPosition( !displayPosition )
  }

  const handleOnSaveMembership = async () => {
    await dispatch( setMembershipList( [ ...state.membership, {
      membership_name: membership?.membership_name,
      attach_file: membershipFile
    } ] ) )

    setDisplayMembership( !displayMembership )
  }

  const handleDisplayModal = async ( payload ) => {
    await dispatch( setDisplayModal( {
      flag: !state?.flag,
      title: payload.title,
      tag: payload.tag,
      operation: "add"
    } ) )
  }

  return (
    <>
      <Panel label="Section B: General Education">
        {state?.flag && <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3">
            

            <div className="flex flex-row justify-end items-end">
              <div />
              <button type="button" className="my-2 w-16 h-8 text-sm bg-blue-500 p-2 text-white" onClick={handleOnSave}>
                {`Save`}
              </button>
            </div>
        </div>}

        <div className="grid grid-cols-1 bg-white">
          <div className="flex flex-row items-center justify-between p-2">
            <div />
            {!display && <button onClick={() => handleDisplayModal( {
              title: "General Education",
              tag: "education"
            } )} type="button" className="text-xs bg-red-500 p-1 px-3 text-white">
              {`Add`}
            </button>}
          </div>
          <table className="w-full border">
            <tr className="text-xs font-bold text-start">
              <th className="text-start p-2">{`Start Date`}</th>
              <th className="text-start p-2">{`End Date`}</th>
              <th className="text-start p-2">{`Qualification`}</th>
              <th className="text-start">{`Institution`}</th>
              <th className="text-start p-2">{`Attach Document`}</th>
            </tr>
            <tbody>
                {state?.education?.map( ( item, index ) => {
                    return (
                        <tr key={index} className="text-xs p-2 text-start border">
                            <td className="py-2 text-center">{item?.start_date}</td>
                            <td className="py-2 text-center">{item?.end_date}</td>
                            <td className="py-2 text-center">{item?.qualification}</td>
                            <td className="py-2 text-center">{item?.institution}</td>
                            <td className="py-2 text-center">
                              <a className="bg-green-800 text-white px-3 py-1" href={item?.attach_file} download>{`Download File`}</a>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
      </div>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {[ { id: 1, label: "Passport Bio Data", name: "passport", labelName: "passport_text"  } ].map( fileItem => {
              return (
                <div className="flex flex-col">
                  <TextFieldIcon 
                    name={fileItem?.name}
                    type="file"
                    handleChange={ e => handleInputs( e, "file" )}
                    handleBlur={handleBlur}
                    // value={}
                    question={fileItem.label}
                    label={fileItem.label}
                    required={true}
                  />
                  <TextAreaIcon 
                    name={fileItem?.labelName}
                    handleChange={ e => handleInputs( e )}
                    handleBlur={handleBlur}
                    question={"Text"}
                    label={fileItem.label}
                    required={true}
                  />
                </div>
              )
            } )}
          </div> */}
      </Panel> 

      <Panel label="Section B: Engineering Education">
      {/* {displayEngineering && <></>

          <div className="flex flex-row justify-end items-end">
            <div />
            <button type="button" className="my-2 w-16 h-8 text-sm bg-blue-500 p-2 text-white" onClick={handleOnSaveEngineering}>
              {`Save`}
            </button>
          </div>
      </div>} */}

      <div className="grid grid-cols-1 bg-white">
        <div className="flex flex-row items-center justify-between p-2">
          <div />
            {!displayEngineering && <button onClick={() => handleDisplayModal( {
              title: "Engineering Education",
              tag: "engineering"
            } )} type="button" className="text-xs bg-red-500 p-1 px-3 text-white">
              {`Add`}
            </button>}
        </div>
        <table className="w-full border">
          <tr className="text-xs font-bold text-start">
            <th className="text-start p-2">{`Start Date`}</th>
            <th className="text-start p-2">{`End Date`}</th>
            <th className="text-start">{`Institution`}</th>
            <th className="text-start p-2">{`Attach Document`}</th>
          </tr>
          <tbody>
              {state?.engineering?.map( ( item, index ) => {
                  return (
                      <tr key={index} className="text-xs p-2 text-start border">
                          <td className="py-2 text-center">{item?.start_date}</td>
                          <td className="py-2 text-center">{item?.end_date}</td>
                          <td className="py-2 text-center">{item?.institution}</td>
                          <td className="py-2 text-center">
                            <a className="bg-green-800 text-white px-3 py-1" href={item?.attach_file} download>{`Download File`}</a>
                          </td>
                      </tr>
                  )
              })}
          </tbody>
      </table>
      </div>
      </Panel>   

      <Panel label="Section B: Practical Engineering Training">
        {/* {displayPractical && <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3">
            <TextFieldIcon 
              name="start_date"
              type="date"
              handleChange={handleInputChangePractical}
              handleBlur={handleBlur}
              value={values.start_date}
              question="Start Date"
              label="Start Date"
              required={displayPractical && true}
              disabled={false}
            />

            <TextFieldIcon 
              name="end_date"
              type="date"
              handleChange={handleInputChangePractical}
              handleBlur={handleBlur}
              value={values.end_date}
              question="End Date"
              label="End Date"
              required={displayPractical && true}
              disabled={false}
            />

            <TextFieldIcon 
              name="organisation"
              type="text"
              handleChange={handleInputChangePractical}
              handleBlur={handleBlur}
              value={values.organisation}
              question="Organisation"
              label="Organisation"
              required={displayPractical && true}
              disabled={false}
            />

            <TextFieldIcon 
              name="cadre"
              type="text"
              handleChange={handleInputChangePractical}
              handleBlur={handleBlur}
              value={values.cadre}
              question="Engineering Cadre"
              label="Engineering Cadre"
              required={displayPractical && true}
              disabled={false}
            />

            <div className="flex flex-row justify-end items-end">
              <div />
              <button type="button" className="my-2 w-16 h-8 text-sm bg-blue-500 p-2 text-white" onClick={handleOnSavePractical}>
                {`Save`}
              </button>
            </div>
        </div>} */}

        <div className="grid grid-cols-1 bg-white">
          <div className="flex flex-row items-center justify-between p-2">
            <div />
            {/* {!displayPractical && <button onClick={ () => setDisplayPractical( !displayPractical )} type="button" className="text-xs bg-red-500 p-1 px-3 text-white">
              {`Add`}
            </button>} */}
            {!displayPractical && <button onClick={() => handleDisplayModal( {
              title: "Practical Engineering Training",
              tag: "practicals"
            } )} type="button" className="text-xs bg-red-500 p-1 px-3 text-white">
              {`Add`}
            </button>}
          </div>
          <table className="w-full border">
            <tr className="text-xs font-bold text-start">
              <th className="text-start p-2">{`Start Date`}</th>
              <th className="text-start">{`End Date`}</th>
              <th className="text-start">{`Organisation`}</th>
              <th className="text-start p-2">{`Supervisor/Cadre`}</th>
            </tr>
            <tbody>
                {state?.practicals?.map( ( item, index ) => {
                    return (
                        <tr key={index} className="text-xs p-2 text-start border">
                            <td className="py-2 text-center">{item?.start_date}</td>
                            <td className="py-2 text-center">{item?.end_date}</td>
                            <td className="py-2 text-center">{item?.organisation}</td>
                            <td className="py-2 text-center">{item?.cadre}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
        </div>
      </Panel>  

      <Panel label="Section B: Positions Held">
        {/* {displayPosition && <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3">
            <TextFieldIcon 
              name="start_date"
              type="date"
              handleChange={handleInputChangePositions}
              handleBlur={handleBlur}
              value={values.start_date}
              question="Start Date"
              label="Start Date"
              required={displayPosition && true}
              disabled={false}
            />

            <TextFieldIcon 
              name="end_date"
              type="date"
              handleChange={handleInputChangePositions}
              handleBlur={handleBlur}
              value={values.end_date}
              question="End Date"
              label="End Date"
              required={displayPosition && true}
              disabled={false}
            />

            <TextFieldIcon 
              name="organisation"
              type="text"
              handleChange={handleInputChangePositions}
              handleBlur={handleBlur}
              value={values.organisation}
              question="Organisation"
              label="Organisation"
              required={displayPosition && true}
              disabled={false}
            />

            <TextFieldIcon 
              name="cadre"
              type="text"
              handleChange={handleInputChangePositions}
              handleBlur={handleBlur}
              value={values.cadre}
              question="Engineering Cadre"
              label="Supervisor / Engineering Cadre"
              required={displayPosition && true}
              disabled={false}
            />

            <div className="flex flex-row justify-end items-end">
              <div />
              <button type="button" className="my-2 w-16 h-8 text-sm bg-blue-500 p-2 text-white" onClick={handleOnSavePosition}>
                {`Save`}
              </button>
            </div>
        </div>} */}
        
        <div className="grid grid-cols-1 bg-white">
          <div className="flex flex-row items-center justify-between p-2">
            <div />
            {!displayPosition && <button onClick={() => handleDisplayModal( {
              title: "Positions Held",
              tag: "positions"
            } )} type="button" className="text-xs bg-red-500 p-1 px-3 text-white">
              {`Add`}
            </button>}
          </div>
          <table className="w-full border">
            <tr className="text-xs font-bold text-start">
              <th className="text-start p-2">{`Start Date`}</th>
              <th className="text-start">{`End Date`}</th>
              <th className="text-start">{`Organisation`}</th>
              <th className="text-start p-2">{`Supervisor/Cadre`}</th>
            </tr>
            <tbody>
                {state?.positions?.map( ( item, index ) => {
                    return (
                        <tr key={index} className="text-xs p-2 text-start border">
                            <td className="py-2 text-center">{item?.start_date}</td>
                            <td className="py-2 text-center">{item?.end_date}</td>
                            <td className="py-2 text-center">{item?.organisation}</td>
                            <td className="py-2 text-center">{item?.cadre}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
        </div>
      </Panel> 

      <Panel label="Section B: Membership of Engineering Institutions">
        {/* {displayMembership && <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3">
            <TextFieldIcon 
              name="membership_name"
              type="text"
              handleChange={handleInputChangeMembership}
              handleBlur={handleBlur}
              value={values.membership_name}
              question="Name of Institution(Membership)"
              label="Name of Institution"
              required={displayMembership && true}
              disabled={false}
            />

          <TextFieldIcon 
            name="attach_file"
            type="file"
            handleChange={handleInputChangeMembership}
            handleBlur={handleBlur}
            value={values.attach_file}
            question="Attach Proof of Membership/Registration"
            label="Attach Proof of Membership/Registration"
            required={displayMembership && true}
            disabled={false}
          />

            <div className="flex flex-row justify-end items-end">
              <div />
              <button type="button" className="my-2 w-16 h-8 text-sm bg-blue-500 p-2 text-white" onClick={handleOnSaveMembership}>
                {`Save`}
              </button>
            </div>
        </div>} */}

        <div className="grid grid-cols-1 bg-white">
          <div className="flex flex-row items-center justify-between p-2">
            <div />
            {/* {!displayMembership && <button onClick={ () => setDisplayMembership( !displayMembership )} type="button" className="text-xs bg-red-500 p-1 px-3 text-white">
              {`Add`}
            </button>} */}
            {!displayMembership && <button onClick={() => handleDisplayModal( {
              title: "Membership of Engineering Institutions",
              tag: "membership"
            } )} type="button" className="text-xs bg-red-500 p-1 px-3 text-white">
              {`Add`}
            </button>}
          </div>
          <table className="w-full border">
            <tr className="text-xs font-bold text-start">
              <th className="text-start p-2">{`Name`}</th>
              <th className="text-start p-2">{`File Attached`}</th>
            </tr>
            <tbody>
                {state?.membership?.map( ( item, index ) => {
                    return (
                        <tr key={index} className="text-xs p-2 text-start border">
                            <td className="py-2 text-center">{item?.membership_name}</td>
                            <td className="py-2 text-center">
                              <a className="bg-green-800 text-white px-3 py-1" href={item?.attach_file} download>{`Download File`}</a>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
        </div>
      </Panel> 
    </>
  );
}
