import React from 'react'
import NavStripBar from '../_scenes/_components/NavStripBar'
import Sidebar from '../_scenes/_components/Sidebar'

export default function Wrap( { children, sidebar = true, footer = true, noScroll = false } ) {
  return (
  <>
    <div className={`flex flex-col h-screen ${ noScroll ? "scrollbar-hide" : ""}`}>
      <NavStripBar />
      <div className="flex flex-1 overflow-hidden">
        <aside className="hidden sm:block overflow-y-auto">
          {sidebar && <Sidebar />}
        </aside>
       
        <main className={ ` ${ sidebar ? "md:ml-[200px] lg:ml-[200px]" : "ml-0" } py-5 flex flex-col flex-1 overflow-y-auto`}>
            {children}
            {/* <Footer /> */}
        </main>
        
      </div>
    </div>
  </>
  )
}

const Footer = () => {
  return(
    <div className="grid grid-cols-4 gap-2 w-full py-3 bg-[#F00711] md:h-20 lg:h-20 h-14" style={{height: "26%"}}>
          <div className="col-span-1 text-white">
            <div className="flex flex-col items-center justify-between">
              <div />
              <h2 className="py-2 px-2 text-xs md:text-sm lg:text-sm font-bold" >{`Follow us on:`}</h2>
              <span className="text-xs">{`Twitter`}</span>
              <span className="text-xs">{`LinkedIn`}</span>
              <span className="text-xs">{`Instagram`}</span>
            </div>
          </div>
          <div className="border-l-2 border-gray-200 col-span-2 flex flex-col text-white px-2 py-1" style={{fontFamily: "Roboto, sans-serif", }}>
            <h2 className='font-bold'>{`Useful Links`}</h2>
            <ol className="list-disc flex flex-wrap mx-2 w-3/4 px-2 py-1">
              <li className="mx-2 text-xs md:text-xs lg:text-xs">{`Uganda Institution of Professional Engineers (UIPE)`}</li>
              <li className="mx-2 text-xs md:text-xs lg:text-xs">{`Engineers Registration Board Tanzania (ERB Tanzania)`}</li>
              <li className="mx-2 text-xs md:text-xs lg:text-xs">{`Institution of Engineers Rwanda (IER)`}</li>
              <li className="mx-2 text-xs md:text-xs lg:text-xs">{`Engineers Board of Kenya (EBK)`}</li>
              <li className="mx-2 text-xs md:text-xs lg:text-xs">{`National Building Review Board (NBRB)`}</li>
              <li className="mx-2 text-xs md:text-xs lg:text-xs">{`Uganda Road Fund (URF)`}</li>
              <li className="mx-2 text-xs md:text-xs lg:text-xs">{`Uganda National Roads Authority (UNRA)`}</li>
              <li className="mx-2 text-xs md:text-xs lg:text-xs">{`Kampala Capital City Authority (KCCA)`}</li>             
            </ol>
          </div>
          <div className="col-span-1 text-white items-start justify-center">            
              <h2 className='font-bold'>{`Contact Us`}</h2>
              <div className="flex flex-col">
                <span className="text-xs">{`Management Support Unit Building,`}</span>
                <span className="text-xs">{`Public Works Training Centre`}</span>
                <span className="text-xs">{`Plot 2, Gloucester Avenue, Kyambogo`}</span>
                <span className="text-xs">{`P.O. Box 29267, Kampala`}</span>
                <span className="text-xs">{`Tel.: +256 (0) 393 194942`}</span>   
              </div>            
          </div> 
        </div>
  )
}