"use strict"

import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { Register, ChangePassword, Home, ResetPassword, ActivatedAccount, Login, Welcome,
   Application, MyApplications, ApplicationDetails, ArticleStory, 
   InvestmentLicense, Sponsors, MyRenewals, MyDefer
} from './_scenes'
import Contact from './_scenes/Account/Contact'
import CategoryDetails from './_scenes/Account/CategoryDetails'
import PrivacyPolicy from './_scenes/Account/PrivacyPolicy'
// import Certificate from './_scenes/InvestmentLicense'

import './App.css'

function App() {
  var isLoggedIn = localStorage.getItem( "token" );

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={isLoggedIn ? <Home /> : <Welcome />}></Route>
        <Route exact path='/success-activation' element={<ActivatedAccount />}></Route>
        <Route exact path='/dashboard' element={<Home />}></Route> 
        <Route exact path='/privacy-policy-statement' element={<PrivacyPolicy />}></Route> 
        <Route exact path='/login' element={<Login />}></Route> 
        <Route exact path='/password-reset' element={<ChangePassword />}></Route> 
        <Route exact path='/reset-password' element={<ResetPassword />}></Route>
        <Route exact path='/register' element={<Register />}></Route>
        
        <Route exact path='/contact' element={<Contact />}></Route> 
        <Route exact path='/article-news' element={<ArticleStory />}></Route> 
        <Route exact path='/category-details/:type' element={<CategoryDetails />} />
        <Route exact path='/application' element={<Application />} />
        <Route exact path='/my-approved-licence' element={<InvestmentLicense />} />
        <Route exact path='/my-applications' element={<MyApplications />} />
        <Route exact path='/my-deferred-applications' element={<MyDefer />} />
        <Route exact path='/my-renewals' element={<MyRenewals />} />
        <Route exact path='/my-sponsor-applications' element={<Sponsors />} />
        <Route exact path='/my-application-details/:id' element={<ApplicationDetails />} />

        <Route path="/" element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        } />
      </Routes>
    </Router>
    
  )
}

const ProtectedRoute = ( { children } ) => {
  const location = useLocation()
  const token = localStorage.getItem( "token" )

  if( !token ) {
    return <Navigate to="/" state={{ 
      from: location 
    }} replace />
  }

  return children
}
export default App;


 






