import React, { useEffect, useMemo, useState } from 'react'
import { Wrap, ProgressBar } from '../../_components'
import Table from '../../_components/Table'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { CircularProgress } from '@material-ui/core'
import { API_BASE } from '../../config'
import { truncate, calculateProgress } from '../_utils'


    const ActionView =  ( record ) => {
        const navigate = useNavigate()
        let user = JSON.parse( localStorage.getItem( "user" ) )


        const handleApprove = async () => {
            try {
                const response = await axios.post( `${API_BASE}/erb_updateSponsor`, {
                    id: record.value,
                    status: "APPROVED"
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                } ) 
                if( response.data ) {
                    window.location.reload()
                }
            } catch (error) {
                // alert( "" )
            }
        }

        console.log( record?.row?.original )
        console.log( user )

        if( parseInt( user?.id ) !== parseInt( record?.row?.original?.user_id ) && record?.row?.original?.status !== "APPROVED" ) {
            return (
                <div className="flex flex-row items-center cursor-pointer" onClick={handleApprove}>
                    <span className="text-xs text-white bg-green-500 px-2 py-1">
                        {`Approve`}
                    </span>
                    {/* <button type="button">{`Decline`}</button> */}
                </div>
            )
        }


        return <></>
        
    }

    const StatusField = record => {
        let color = ""
        switch( record.value?.toLowerCase() ){
            case "review":
                color =  "blue"
            default:
                color = "white"
        }

        const display = () => {
            switch( record.value?.toLowerCase() ) {
                case "review":
                    return "UNDER PROCESSING"
                case "verified":
                    return "VERIFIED"
                case "issued":
                case "approved":
                    return "APPROVED"
                case "rejected":
                    return "REJECTED";
                case "deferred":
                    return "DEFERRED"
                default:
                    return "UNDER PROCESSING";
            }
        }

        return (
            <span className={`px-2 py-1 text-xs font-bold uppercase text-black`}>
                {display()}
            </span>
        )
    }

export default function Sponsors() {
    const navigate = useNavigate()
    const columns = useMemo(
    () => [
            {
                Header: "Name",
                accessor: "sponsor_name",
            },
            {
                Header: "Discipline",
                accessor: "discipline",
            },
            {
                Header: "Registration Status",
                accessor: "registered",
            },
            {
                Header: "Registration No.",
                accessor: "registration_number",
            },
            // {
            //     Header: "Progress",
            //     accessor: "progress",
            //     sortable: false,
            //     filterable: false,
            //     Cell: ProgressField
            // },
            // {
            //     Header: "Status",
            //     accessor: "status",
            //     sortable: false,
            //     filterable: false,
            //     Cell: StatusField
            // },
            {
                Header:  "Actions",
                accessor: "id",
                sortable: false,
                filterable: false,
                Cell: ActionView
            },
        ]
    )
    const [ loading, setLoading ] = useState( true )
    const [ results, setResults ] = useState( [] )
    const [ admin, setAdmin ] = useState( "" )
  
    useEffect( () => {
        if( loading ) {
            fetchSponsors()
        }
    }, [ loading ] )

    const fetchSponsors  = async () => {
        let role = await localStorage.getItem( "role" )
        setAdmin( role )
        let user = JSON.parse( localStorage.getItem( "user" ) )

        try {
            const response = await axios.get( `${API_BASE}/erb-getSponsors`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            } ) 

            if( response.data ) {
                setResults( response.data?.sponsors )
            }
            setLoading( false )
        } catch ( error ) {
            return alert( error?.response?.data?.message || "Could not return applications. Server error occured while Fetching applications." )
        }
    }

    return (
    <Wrap navigate={navigate}>
        <div className="flex flex-col items-center py-2 px-3 bg-white my-4 w-full">
            <div className="w-full flex items-center justify-between">
                {results?.length > 0 ? <h2 className="font-bold">
                    {`My Sponsor Approvals`}
                </h2> : (
                    <div />
                )}
            </div>
            { loading ? (
                <div className="flex items-center justify-center">
                    <span>{`Loading Depedencies...`}</span>
                    <CircularProgress size={24} color="green" />
                </div>
            ) :( results.length > 0 ) ?
             <Table
                label={"Applications"}
                columns={columns}
                data={results}
                hidePagination={false}
            /> 
            : (
                <div className="flex flex-col items-center justify-center py-2">
                    <h5 className="font-bold uppercase">
                        {`Your Sponsor Applications`}
                    </h5>
                    {( admin === "REGISTRAR" || admin === "SUBCOMMITEE" || admin === "BOARD" ) ? (
                        <p className="p-2">
                            {`You currently do not have any pending applications on your docket. Your Docket is Empty.`}
                        </p>
                    ) :
                    <p className="p-2">
                        {`You have not made any new Applications. Please Click Apply for a Licence and make a new Application.`}
                    </p>}
                </div>
            ) }
        </div>
        
    </Wrap>
  )
}
