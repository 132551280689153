import React from 'react'
import { Panel, Wrap } from '../../_components'
import { useNavigate, useLocation } from 'react-router-dom'

export default function ArticleStory() {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Wrap navigate={navigate} sidebar={false}>
        <div className="p-5 w-full">
           <Panel label={`Article- ${location.state?.article?.label}`}>
                <div className="text-red-500 cursor-pointer" onClick={() => navigate( "/" )}>{`Back to Home`}</div>
                <div className="flex items-center h-1/2 py-2">
                    <img src={location.state?.article?.src} className="h-20 md:h-32 lg:h-52"/>
                </div>
                {location.state?.content?.length > 0 && location.state?.content.map( ( para, idx ) => {
                    return (
                        <p key={idx} className="text-base my-2 py-2">
                            {para[Object.keys( para )?.[ 0 ]]}
                        </p>
                    )
                })}
           </Panel>
        </div>
    </Wrap>
  )
}
