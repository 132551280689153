import React, { useEffect, useState } from 'react'
import Preview from './GeneratePDF'
import { useLocation } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'

export default function() {
  const location = useLocation();
  const currentLocation = location.pathname.split( "/" )?.[ 1 ].split("-")?.[ 1 ]

  const [ loading, setLoading ] = useState( true )
  const [ QRDataURLCode, setQRDataCode ] = useState( null )

  useEffect( () => {
    if( loading ) {
        const qrCodeCanvas = document.querySelector( "canvas" )
        const QRDataURL = qrCodeCanvas?.toDataURL( "image/jpg", 0.3 )

        setQRDataCode( QRDataURL )
        setLoading( false )
    }
}, [ loading ])

  let [ data, setData ] = useState( {
    title: "Uganda Engineers Registration Board",
    subtitle: "Engineers Board",
    heading: "ERB"
  } )

  if( loading ) {
    return (
      <div className="flex flex-col items-center">
        <span>{`Loading...`}</span>
        <CircularProgress size={24} color="primary"/>
      </div>
    )
  }

  return (
   <div className="w-screen h-screen flex flex-row">
     <div className="bg-white w-full h-full">
       <Preview 
        data={{}} 
        record={location?.state} 
        type={currentLocation} 
        imageQR={QRDataURLCode} />
     </div>
   </div>
  )
}