import React from 'react'
import { View, Text, Image } from '@react-pdf/renderer'
import logoImage from '../../../_images/shortERBlogo.png'
import moment from 'moment'
import { sumFooterColumns, truncate, formatCurrency } from '../../_utils'
import DGSignature from './DDG_signature.png'


const Body = ( { imageQR, record, type, remarks } ) => {

    let date = moment( new Date() ).format( "DD-MMM-YYYY" )

    console.log( date )

    return (
        <View style={{ width: "100%", height: "100%", backgroundColor: "#FFF", padding: "5px" }}>
            <View style={{ display: "flex", flexDirection: "row" }}>
                <View style={{ width: "30%"}} />
                <View style={{ width: "30%"}}>
                    <View style={{ display: "flex" }}>
                        <Image src={logoImage} style={{ width: "200px" }} />
                    </View>
                </View>
                <View style={{ width: "40%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ fontSize: "10px"}}>{`Licence No:`}</Text>
                </View>
            </View>

            <View style={{ width: "100%", marginTop: "5px", marginBottom: "10px"}}>
                <Text style={{ textAlign: "center", textTransform: "uppercase", padding: "2px", fontSize: "20px", fontWeight: "bold", color: "red" }}>
                    {`Engineers Registration Board`}
                </Text>
                <Text style={{ textAlign: "center", textTransform: "uppercase", padding: "2px", fontSize: "12px", fontWeight: "bold", color: "#000" }}>
                    {`(Est. under the Engineers Registration Act Cap 271 of 1969)`}
                </Text>
            </View>

            <View style={{ width: "100%", marginHorizontal: "100px", marginVertical: "15px", display: "flex", alignItems: "start", justifyCenter: "center" }}>
                {/* <Text style={{ textAlign: "center", fontSize: "24px", padding: "2px"}}>
                    {`Practicing License for the Year 20....`}
                </Text> */}
                <ComponentLabel padding={"0px"} expand={false} label="Practicing License for the Year 20" width="50px" font={"24px"} value={``} />
            </View>


            <View style={{ display: "flex", flexDirection: "column" }}>
                <ComponentLabel label="Name:" value={``}/>
                <ComponentLabel label="Holder of ERB Registration Certification No:" value={``} />
                <ComponentLabel label="Field of Practice:" value={``} />
                <ComponentLabel label="is hereby licensed to practice in accordance with the Engineers Registration Act, 1969, Section 19, from date of issue to 31st December 20:" value={``} />
                
                <View style={{ display: "flex", flexDirection: "column", marginVertical: "5px" }}>
                    <Text style={{ fontSize: "12px" }}>{`In witness whereof the Common seal of the Board is hereunto affixed`}</Text>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                        <ComponentLabel  width="100px" expand={false} label="this" value="" />
                        <ComponentLabel  width="100px" expand={false} label="day of" value="" />
                        <ComponentLabel  width="100px"expand={false} label="(month)" value="" />
                        <ComponentLabel  width="100px" expand={false} label="(year)" value="" />
                    </View>
                </View>

                <View style={{ display: "flex", flexDirection: "row", marginVertical: "32px" }}>
                    <View style={{ width: "40%", display: "flex", flexDirection: "column", borderTopWidth: 1, borderTopStyle: "dotted", paddingVertical: "3px" }}>
                        <Text style={{ textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                            {`Chairman`}
                        </Text>
                    </View>
                    <View style={{ width: "25%"}}></View>

                    <View style={{ width: "40%", display: "flex", flexDirection: "column", borderTopWidth: 1, borderTopStyle: "dotted", paddingVertical: "3px" }}>
                        <Text style={{ textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                            {`Registrar`}
                        </Text>
                    </View>
                </View>


                <View style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", marginHorizontal: "15px" }}>
                        {imageQR && <Image allowDangerousPaths source={{ uri: imageQR }} style={{ width: "75px",height: "75px" }} />}
                        <Text style={{ fontFamily: "OpenBold", fontSize: "10px", paddingVertical: "5px" }}>
                            {``}
                        </Text>
                </View>
            </View>

            <View style={{ position: "absolute", bottom: 2 , width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", flexWrap: "wrap" }}>
               <ComponentLabel width="20px" expand={false} label="This Licence shall remain valid until the thirty first day of December"/>
               <Text style={{ fontSize: "12px"}}>
                {`It is renewable annually on application being made on the prescribed form.`}
               </Text>
            </View>
        </View>
    )
}  

export default Body

const ComponentLabel = ( { font, label, value, expand = true, width, align, padding = "1px" } ) => {
    return (
        <View style={{ display: "flex", flexDirection: "row", paddingVertical: "2px", marginVertical: "10px" }}>
            <Text style={{ fontSize: font ? font : "12px", textAlign: align ? "center" : "start", padding: padding }}>{label}</Text>
            <View style={{ flexGrow: expand ? 1 : 0, width: !expand ? width : "auto", borderBottomWidth: 1, borderBottomStyle: "dotted" }}>
                <Text style={{ fontSize: "10px", textAlign: "center", padding: "1px" }}>{value}</Text> 
            </View>
        </View>
    )
}

{/* <View style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>{`1. Name and address of Business Enterprise:`}</Text>
                <Text style={{ fontFamily: "OpenMedium", textTransform: "uppercase", fontSize: "10px",  paddingLeft: "2px" }}>
                    {`${record?.entity_name}, Registration No ${record?.brn}`}
                </Text>
                <Text style={{ fontFamily: "OpenMedium", textTransform: "uppercase", fontSize: "10px",  paddingLeft: "2px"  }}>
                    {`${record?.company_physical_address}`}
                </Text>
            </View>

            <View style={{ display: "flex", flexDirection: "column", alignItems: "start", marginVertical: "2px" }}>
                <Text style={{ fontFamily: "OpenLight", fontSize: "10px", marginVertical: "2px" }}>
                    {`2. Name, Country of Origin and Address of the Shareholder(s):`}
                </Text>
                {record?.shareholders?.map( ( shareholder, index ) => {
                    return (
                        <View key={index+1} style={{ display: "flex", flexDirection: "row", marginVertical: "0px" }}>
                            <View style={{ display: "flex",  flex: 1,  alignItems: "center", justifyContent: "center", borderTopWidth: 1, borderBottomWidth: 1, borderLeftWidth: 1, borderColor: "#666", padding: "2px"}}>
                                <Text style={{ fontFamily: "OpenMedium", fontSize: "10px", textTransform: "uppercase", textAlign: "center" }}>
                                    {`${truncate( shareholder.first_name, 30 )} ${shareholder.surname && truncate( shareholder.surname, 25 )}`} 
                                </Text>
                            </View>
                            <View style={{ display: "flex", flex: 1,  alignItems: "center", justifyContent: "center", borderTopWidth: 1, borderBottomWidth: 1, borderColor: "#666", borderLeftWidth: 1, borderRightWidth: 1, padding: "2px" }}>
                                <Text style={{ fontFamily: "OpenMedium", fontSize: "10px", textTransform: "uppercase" }}>
                                    {`${shareholder?.nationality}`}
                                </Text>
                            </View>
                            <View style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center", borderTopWidth: 1, borderBottomWidth: 1, borderColor: "#666", borderRightWidth: 1, padding: "2px" }}>
                                <Text style={{ fontFamily: "OpenMedium", fontSize: "10px", textTransform: "uppercase" }}>
                                    {`${truncate( shareholder?.address, 30 )}`}
                                </Text>
                            </View>
                        </View>
                    )
                } )}
            </View>

            <View style={{ display: "flex", flexDirection: "column", alignItems: "start", marginVertical: "2px" }}>
                <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>{`3. Location of the project:`}</Text>
                <Text style={{ fontFamily: "OpenMedium", textTransform: "uppercase", fontSize: "10px",  paddingLeft: "2px" }}>
                    {`${record?.project?.location}`}
                </Text>
            </View>

            <View style={{ display: "flex", flexDirection: "column", alignItems: "start", marginVertical: "2px" }}>
                <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>{`4. Nature of the business enterprise:`}</Text>
                <Text style={{ fontFamily: "OpenMedium", fontSize: "10px", paddingLeft: "2px" }}>
                    {`${record?.sector_info?.sector}`}
                </Text>
            </View>

            <View style={{ display: "flex", flexDirection: "column", alignItems: "start", marginVertical: "2px" }}>
                <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                    {`5. Capital Investment:`}
                </Text>
                <Text style={{ fontFamily: "OpenMedium", textTransform: "uppercase", fontSize: "10px", paddingLeft: "2px" }}>
                    {`$${formatCurrency( sumFooterColumns( record )?.finance_total ) }`}
                </Text>
            </View>

            <View style={{ display: "flex", flexDirection: "column", alignItems: "start", marginVertical: record?.shareholders?.length > 5 ? "10px" : "2px" }}>
                <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>{`6. Proposed activity Description:`}</Text>
                <Text style={{ fontFamily: "OpenMedium", textTransform: "uppercase", fontSize: "10px", paddingLeft: "2px" }}>
                    {`${truncate( record?.project?.proposed_business_activity, 150 )}`}
                </Text>
            </View>

            <View style={{ display: "flex", flexDirection: "column", alignItems: "start", marginVertical: "2px", marginTop: record?.shareholders?.length > 8 ? "110px" : "0px" }}>
                <Text style={{ fontFamily: "OpenLight", fontSize: "10px", marginTop: record?.shareholders?.length > 5 ? "12px" : "0px" }}>{`7. Financing( US $):`}</Text>
                <View style={{ display: "flex", flexDirection: "row" }}>
                    <View style={{ display: "flex",  flex: 1,  alignItems: "center", justifyContent: "center", borderWidth: 2 , borderColor: "black", padding: "5px"}}>
                        {``}
                    </View>
                    <View style={{ display: "flex", flex: 1,  alignItems: "center", justifyContent: "center", borderBottomWidth: 2, borderTopWidth: 2, borderRightWidth: 2, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenMedium", fontSize: "10px", }}>
                            {`Foreign`}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center", borderBottomWidth: 2,borderRightWidth: 2, borderTopWidth: 2, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenMedium", fontSize: "10px", }}>
                            {`Domestic`}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center", borderBottomWidth: 2,borderRightWidth: 2, borderTopWidth: 2, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenMedium", fontSize: "10px", }}>
                            {`Total`}
                        </Text>
                    </View>
                </View>

                <View style={{ display: "flex", flexDirection: "row" }}>
                    <View style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center", borderWidth: 2 , borderTopWidth: 0, borderColor: "black", padding: "5px"}}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                            {`Equity`}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flex: 1,  alignItems: "center", justifyContent: "center", borderBottomWidth: 2, borderTopWidth: 0, borderRightWidth: 2, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                            {`$${formatCurrency( record?.financing.share_capital?.foreign )}`}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flex: 1,  alignItems: "center", justifyContent: "center", borderBottomWidth: 2,borderRightWidth: 2, borderTopWidth: 0, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                        {`$${formatCurrency( record?.financing.share_capital?.local )}`}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flex: 1,  alignItems: "center", justifyContent: "center", borderBottomWidth: 2,borderRightWidth: 2, borderTopWidth: 0, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                            {`$${formatCurrency( record?.financing.share_capital?.total )}`}
                        </Text>
                    </View>
                </View>

                <View style={{ display: "flex", flexDirection: "row" }}>
                    <View style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center", borderWidth: 2 , borderTopWidth: 0, borderColor: "black", padding: "5px"}}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>Loan</Text>
                    </View>
                    <View style={{ display: "flex", flex: 1,  alignItems: "center", justifyContent: "center", borderBottomWidth: 2, borderTopWidth: 0, borderRightWidth: 2, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                            {`$${formatCurrency( record?.financing.loan_capital?.foreign )}`}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flex: 1,  alignItems: "center", justifyContent: "center", borderBottomWidth: 2,borderRightWidth: 2, borderTopWidth: 0, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                            {`$${formatCurrency( record?.financing.loan_capital?.local )}`}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flex: 1,  alignItems: "center", justifyContent: "center", borderBottomWidth: 2,borderRightWidth: 2, borderTopWidth: 0, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                            {`$${formatCurrency( record?.financing.loan_capital?.total )}`}
                        </Text>
                    </View>
                </View>

                <View style={{ display: "flex", flexDirection: "row" }}>
                    <View style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center", borderWidth: 2 , borderTopWidth: 0, borderColor: "black", padding: "5px"}}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>Others</Text>
                    </View>
                    <View style={{ display: "flex", flex: 1,  alignItems: "center", justifyContent: "center", borderBottomWidth: 2, borderTopWidth: 0, borderRightWidth: 2, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                            {`$${formatCurrency( record?.financing.other_sources?.foreign )}`}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flex: 1,  alignItems: "center", justifyContent: "center", borderBottomWidth: 2,borderRightWidth: 2, borderTopWidth: 0, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                            {`$${formatCurrency( record?.financing.other_sources?.local )}`}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flex: 1,  alignItems: "center", justifyContent: "center", borderBottomWidth: 2,borderRightWidth: 2, borderTopWidth: 0, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                            {`$${formatCurrency( record?.financing.other_sources?.total )}`}
                        </Text>
                    </View>
                </View>

                <View style={{ display: "flex", flexDirection: "row" }}>
                    <View style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center", borderWidth: 2 , borderTopWidth: 0, borderColor: "black", padding: "5px"}}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>Total</Text>
                    </View>
                    <View style={{ display: "flex", flex: 1,  alignItems: "center", justifyContent: "center", borderBottomWidth: 2, borderTopWidth: 0, borderRightWidth: 2, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                            {`$${ formatCurrency( handleSums( "foreign",record ) ) }`}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flex: 1,  alignItems: "center", justifyContent: "center", borderBottomWidth: 2,borderRightWidth: 2, borderTopWidth: 0, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                        {`$${ formatCurrency( handleSums( "local", record ) ) }`}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flex: 1,  alignItems: "center", justifyContent: "center", borderBottomWidth: 2,borderRightWidth: 2, borderTopWidth: 0, padding: "5px" }}>
                        <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                        {`$${ formatCurrency( handleSums( "total", record ) ) }`}
                        </Text>
                    </View>
                </View>
            </View>
            

            <View style={{ marginVertical: "5px" }}>
                <View style={{ display: "flex", flexDirection: "column", alignItems: "start"}}>
                    <Text style={{ fontFamily: "OpenLight", fontSize: "10px" }}>
                        {`8. Period of Validity of the Investment License:`}
                    </Text>
                </View>

                <View style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "5px", marginBottom: "5px" }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Text style={{ fontFamily: "OpenMedium", fontSize: "10px", }}>{`Date of Issue:`}</Text>
                        <Text style={{ fontFamily: "OpenMedium", fontSize: "10px", marginHorizontal: "5px" }}>
                            {`${ moment( record?.updated_at ).format( "DD MMMM YYYY" ) }`}
                        </Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Text style={{ fontFamily: "OpenMedium", fontSize: "10px", }}>{`Date of Expiry:`}</Text>
                        <Text style={{ fontFamily: "OpenMedium", fontSize: "10px", marginHorizontal: "5px" }}>
                            {`${moment( record?.updated_at ).add(5, 'years').format( "DD MMMM YYYY" )}`}
                        </Text>
                    </View>
                </View>

                <View style={{ height: "120px", paddingHorizontal: "50px",   display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start", paddingVertical: "5px", fontFamily: "OpenMedium" }}>
                    <View style={{ fontSize: "9px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", flexWrap: "wrap"  }}>
                        <View style={{ width: "300px", display: "flex", position: "relative" }}>
                            <Text style={{ fontSize: "10px" }}>{``}</Text>
                            <View style={{ flex: 1, borderBottomColor: "#000", borderBottomWidth: 1, borderBottomStyle: "dotted" }}>
                                <Image source={person?.length > 0 ? DGSignature : Signature} alt="Signature 2" style={{ height: "50px", position: "absolute", top: "-50px", left: "100px" }} />
                            </View>
                        </View>

                        <Text style={{  textTransform: "uppercase", fontFamily: "OpenBold", color: "#441722", fontSize: "12px", paddingLeft: "10px", paddingVertical: "5px" }}>
                            { person?.length > 0 ? `Dr. Paul Kyalimpa` : `Mr. Robert Mukiza`}
                        </Text>
                    </View>
                    <Text style={{ fontSize: "10px", }}>
                        { person?.length > 0 ? `Deputy Director General` : `Director General`}
                    </Text>
                </View>
            </View> */}


