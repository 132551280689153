export default {
    countries: [
        {
            "num_code": "4",
            "alpha_2_code": "AF",
            "alpha_3_code": "AFG",
            "en_short_name": "Afghanistan",
            "nationality": "Afghan"
        },
        {
            "num_code": "8",
            "alpha_2_code": "AL",
            "alpha_3_code": "ALB",
            "en_short_name": "Albania",
            "nationality": "Albanian"
        },
        {
            "num_code": "12",
            "alpha_2_code": "DZ",
            "alpha_3_code": "DZA",
            "en_short_name": "Algeria",
            "nationality": "Algerian"
        },
        {
            "num_code": "16",
            "alpha_2_code": "AS",
            "alpha_3_code": "ASM",
            "en_short_name": "American Samoa",
            "nationality": "American Samoan"
        },
        {
            "num_code": "20",
            "alpha_2_code": "AD",
            "alpha_3_code": "AND",
            "en_short_name": "Andorra",
            "nationality": "Andorran"
        },
        {
            "num_code": "24",
            "alpha_2_code": "AO",
            "alpha_3_code": "AGO",
            "en_short_name": "Angola",
            "nationality": "Angolan"
        },
        {
            "num_code": "660",
            "alpha_2_code": "AI",
            "alpha_3_code": "AIA",
            "en_short_name": "Anguilla",
            "nationality": "Anguillan"
        },
        {
            "num_code": "10",
            "alpha_2_code": "AQ",
            "alpha_3_code": "ATA",
            "en_short_name": "Antarctica",
            "nationality": "Antarctic"
        },
        {
            "num_code": "28",
            "alpha_2_code": "AG",
            "alpha_3_code": "ATG",
            "en_short_name": "Antigua and Barbuda",
            "nationality": "Antiguan or Barbudan"
        },
        {
            "num_code": "32",
            "alpha_2_code": "AR",
            "alpha_3_code": "ARG",
            "en_short_name": "Argentina",
            "nationality": "Argentine"
        },
        {
            "num_code": "51",
            "alpha_2_code": "AM",
            "alpha_3_code": "ARM",
            "en_short_name": "Armenia",
            "nationality": "Armenian"
        },
        {
            "num_code": "533",
            "alpha_2_code": "AW",
            "alpha_3_code": "ABW",
            "en_short_name": "Aruba",
            "nationality": "Aruban"
        },
        {
            "num_code": "36",
            "alpha_2_code": "AU",
            "alpha_3_code": "AUS",
            "en_short_name": "Australia",
            "nationality": "Australian"
        },
        {
            "num_code": "40",
            "alpha_2_code": "AT",
            "alpha_3_code": "AUT",
            "en_short_name": "Austria",
            "nationality": "Austrian"
        },
        {
            "num_code": "31",
            "alpha_2_code": "AZ",
            "alpha_3_code": "AZE",
            "en_short_name": "Azerbaijan",
            "nationality": "Azerbaijani, Azeri"
        },
        {
            "num_code": "44",
            "alpha_2_code": "BS",
            "alpha_3_code": "BHS",
            "en_short_name": "Bahamas",
            "nationality": "Bahamian"
        },
        {
            "num_code": "48",
            "alpha_2_code": "BH",
            "alpha_3_code": "BHR",
            "en_short_name": "Bahrain",
            "nationality": "Bahraini"
        },
        {
            "num_code": "50",
            "alpha_2_code": "BD",
            "alpha_3_code": "BGD",
            "en_short_name": "Bangladesh",
            "nationality": "Bangladeshi"
        },
        {
            "num_code": "52",
            "alpha_2_code": "BB",
            "alpha_3_code": "BRB",
            "en_short_name": "Barbados",
            "nationality": "Barbadian"
        },
        {
            "num_code": "112",
            "alpha_2_code": "BY",
            "alpha_3_code": "BLR",
            "en_short_name": "Belarus",
            "nationality": "Belarusian"
        },
        {
            "num_code": "56",
            "alpha_2_code": "BE",
            "alpha_3_code": "BEL",
            "en_short_name": "Belgium",
            "nationality": "Belgian"
        },
        {
            "num_code": "84",
            "alpha_2_code": "BZ",
            "alpha_3_code": "BLZ",
            "en_short_name": "Belize",
            "nationality": "Belizean"
        },
        {
            "num_code": "204",
            "alpha_2_code": "BJ",
            "alpha_3_code": "BEN",
            "en_short_name": "Benin",
            "nationality": "Beninese, Beninois"
        },
        {
            "num_code": "60",
            "alpha_2_code": "BM",
            "alpha_3_code": "BMU",
            "en_short_name": "Bermuda",
            "nationality": "Bermudian, Bermudan"
        },
        {
            "num_code": "64",
            "alpha_2_code": "BT",
            "alpha_3_code": "BTN",
            "en_short_name": "Bhutan",
            "nationality": "Bhutanese"
        },
        {
            "num_code": "68",
            "alpha_2_code": "BO",
            "alpha_3_code": "BOL",
            "en_short_name": "Bolivia (Plurinational State of)",
            "nationality": "Bolivian"
        },
        {
            "num_code": "535",
            "alpha_2_code": "BQ",
            "alpha_3_code": "BES",
            "en_short_name": "Bonaire, Sint Eustatius and Saba",
            "nationality": "Bonaire"
        },
        {
            "num_code": "70",
            "alpha_2_code": "BA",
            "alpha_3_code": "BIH",
            "en_short_name": "Bosnia and Herzegovina",
            "nationality": "Bosnian or Herzegovinian"
        },
        {
            "num_code": "72",
            "alpha_2_code": "BW",
            "alpha_3_code": "BWA",
            "en_short_name": "Botswana",
            "nationality": "Motswana, Botswanan"
        },
        {
            "num_code": "74",
            "alpha_2_code": "BV",
            "alpha_3_code": "BVT",
            "en_short_name": "Bouvet Island",
            "nationality": "Bouvet Island"
        },
        {
            "num_code": "76",
            "alpha_2_code": "BR",
            "alpha_3_code": "BRA",
            "en_short_name": "Brazil",
            "nationality": "Brazilian"
        },
        {
            "num_code": "86",
            "alpha_2_code": "IO",
            "alpha_3_code": "IOT",
            "en_short_name": "British Indian Ocean Territory",
            "nationality": "BIOT"
        },
        {
            "num_code": "96",
            "alpha_2_code": "BN",
            "alpha_3_code": "BRN",
            "en_short_name": "Brunei Darussalam",
            "nationality": "Bruneian"
        },
        {
            "num_code": "100",
            "alpha_2_code": "BG",
            "alpha_3_code": "BGR",
            "en_short_name": "Bulgaria",
            "nationality": "Bulgarian"
        },
        {
            "num_code": "854",
            "alpha_2_code": "BF",
            "alpha_3_code": "BFA",
            "en_short_name": "Burkina Faso",
            "nationality": "Burkinab\u00e9"
        },
        {
            "num_code": "108",
            "alpha_2_code": "BI",
            "alpha_3_code": "BDI",
            "en_short_name": "Burundi",
            "nationality": "Burundian"
        },
        {
            "num_code": "132",
            "alpha_2_code": "CV",
            "alpha_3_code": "CPV",
            "en_short_name": "Cabo Verde",
            "nationality": "Cabo Verdean"
        },
        {
            "num_code": "116",
            "alpha_2_code": "KH",
            "alpha_3_code": "KHM",
            "en_short_name": "Cambodia",
            "nationality": "Cambodian"
        },
        {
            "num_code": "120",
            "alpha_2_code": "CM",
            "alpha_3_code": "CMR",
            "en_short_name": "Cameroon",
            "nationality": "Cameroonian"
        },
        {
            "num_code": "124",
            "alpha_2_code": "CA",
            "alpha_3_code": "CAN",
            "en_short_name": "Canada",
            "nationality": "Canadian"
        },
        {
            "num_code": "136",
            "alpha_2_code": "KY",
            "alpha_3_code": "CYM",
            "en_short_name": "Cayman Islands",
            "nationality": "Caymanian"
        },
        {
            "num_code": "140",
            "alpha_2_code": "CF",
            "alpha_3_code": "CAF",
            "en_short_name": "Central African Republic",
            "nationality": "Central African"
        },
        {
            "num_code": "148",
            "alpha_2_code": "TD",
            "alpha_3_code": "TCD",
            "en_short_name": "Chad",
            "nationality": "Chadian"
        },
        {
            "num_code": "152",
            "alpha_2_code": "CL",
            "alpha_3_code": "CHL",
            "en_short_name": "Chile",
            "nationality": "Chilean"
        },
        {
            "num_code": "156",
            "alpha_2_code": "CN",
            "alpha_3_code": "CHN",
            "en_short_name": "China",
            "nationality": "Chinese"
        },
        {
            "num_code": "162",
            "alpha_2_code": "CX",
            "alpha_3_code": "CXR",
            "en_short_name": "Christmas Island",
            "nationality": "Christmas Island"
        },
        {
            "num_code": "166",
            "alpha_2_code": "CC",
            "alpha_3_code": "CCK",
            "en_short_name": "Cocos (Keeling) Islands",
            "nationality": "Cocos Island"
        },
        {
            "num_code": "170",
            "alpha_2_code": "CO",
            "alpha_3_code": "COL",
            "en_short_name": "Colombia",
            "nationality": "Colombian"
        },
        {
            "num_code": "174",
            "alpha_2_code": "KM",
            "alpha_3_code": "COM",
            "en_short_name": "Comoros",
            "nationality": "Comoran, Comorian"
        },
        {
            "num_code": "178",
            "alpha_2_code": "CG",
            "alpha_3_code": "COG",
            "en_short_name": "Congo (Republic of the)",
            "nationality": "Congolese"
        },
        {
            "num_code": "180",
            "alpha_2_code": "CD",
            "alpha_3_code": "COD",
            "en_short_name": "Congo (Democratic Republic of the)",
            "nationality": "Congolese"
        },
        {
            "num_code": "184",
            "alpha_2_code": "CK",
            "alpha_3_code": "COK",
            "en_short_name": "Cook Islands",
            "nationality": "Cook Island"
        },
        {
            "num_code": "188",
            "alpha_2_code": "CR",
            "alpha_3_code": "CRI",
            "en_short_name": "Costa Rica",
            "nationality": "Costa Rican"
        },
        {
            "num_code": "384",
            "alpha_2_code": "CI",
            "alpha_3_code": "CIV",
            "en_short_name": "C\u00f4te d'Ivoire",
            "nationality": "Ivorian"
        },
        {
            "num_code": "191",
            "alpha_2_code": "HR",
            "alpha_3_code": "HRV",
            "en_short_name": "Croatia",
            "nationality": "Croatian"
        },
        {
            "num_code": "192",
            "alpha_2_code": "CU",
            "alpha_3_code": "CUB",
            "en_short_name": "Cuba",
            "nationality": "Cuban"
        },
        {
            "num_code": "531",
            "alpha_2_code": "CW",
            "alpha_3_code": "CUW",
            "en_short_name": "Cura\u00e7ao",
            "nationality": "Cura\u00e7aoan"
        },
        {
            "num_code": "196",
            "alpha_2_code": "CY",
            "alpha_3_code": "CYP",
            "en_short_name": "Cyprus",
            "nationality": "Cypriot"
        },
        {
            "num_code": "203",
            "alpha_2_code": "CZ",
            "alpha_3_code": "CZE",
            "en_short_name": "Czech Republic",
            "nationality": "Czech"
        },
        {
            "num_code": "208",
            "alpha_2_code": "DK",
            "alpha_3_code": "DNK",
            "en_short_name": "Denmark",
            "nationality": "Danish"
        },
        {
            "num_code": "262",
            "alpha_2_code": "DJ",
            "alpha_3_code": "DJI",
            "en_short_name": "Djibouti",
            "nationality": "Djiboutian"
        },
        {
            "num_code": "212",
            "alpha_2_code": "DM",
            "alpha_3_code": "DMA",
            "en_short_name": "Dominica",
            "nationality": "Dominican"
        },
        {
            "num_code": "214",
            "alpha_2_code": "DO",
            "alpha_3_code": "DOM",
            "en_short_name": "Dominican Republic",
            "nationality": "Dominican"
        },
        {
            "num_code": "218",
            "alpha_2_code": "EC",
            "alpha_3_code": "ECU",
            "en_short_name": "Ecuador",
            "nationality": "Ecuadorian"
        },
        {
            "num_code": "818",
            "alpha_2_code": "EG",
            "alpha_3_code": "EGY",
            "en_short_name": "Egypt",
            "nationality": "Egyptian"
        },
        {
            "num_code": "222",
            "alpha_2_code": "SV",
            "alpha_3_code": "SLV",
            "en_short_name": "El Salvador",
            "nationality": "Salvadoran"
        },
        {
            "num_code": "226",
            "alpha_2_code": "GQ",
            "alpha_3_code": "GNQ",
            "en_short_name": "Equatorial Guinea",
            "nationality": "Equatorial Guinean, Equatoguinean"
        },
        {
            "num_code": "232",
            "alpha_2_code": "ER",
            "alpha_3_code": "ERI",
            "en_short_name": "Eritrea",
            "nationality": "Eritrean"
        },
        {
            "num_code": "233",
            "alpha_2_code": "EE",
            "alpha_3_code": "EST",
            "en_short_name": "Estonia",
            "nationality": "Estonian"
        },
        {
            "num_code": "231",
            "alpha_2_code": "ET",
            "alpha_3_code": "ETH",
            "en_short_name": "Ethiopia",
            "nationality": "Ethiopian"
        },
        {
            "num_code": "238",
            "alpha_2_code": "FK",
            "alpha_3_code": "FLK",
            "en_short_name": "Falkland Islands (Malvinas)",
            "nationality": "Falkland Island"
        },
        {
            "num_code": "234",
            "alpha_2_code": "FO",
            "alpha_3_code": "FRO",
            "en_short_name": "Faroe Islands",
            "nationality": "Faroese"
        },
        {
            "num_code": "242",
            "alpha_2_code": "FJ",
            "alpha_3_code": "FJI",
            "en_short_name": "Fiji",
            "nationality": "Fijian"
        },
        {
            "num_code": "246",
            "alpha_2_code": "FI",
            "alpha_3_code": "FIN",
            "en_short_name": "Finland",
            "nationality": "Finnish"
        },
        {
            "num_code": "250",
            "alpha_2_code": "FR",
            "alpha_3_code": "FRA",
            "en_short_name": "France",
            "nationality": "French"
        },
        {
            "num_code": "254",
            "alpha_2_code": "GF",
            "alpha_3_code": "GUF",
            "en_short_name": "French Guiana",
            "nationality": "French Guianese"
        },
        {
            "num_code": "258",
            "alpha_2_code": "PF",
            "alpha_3_code": "PYF",
            "en_short_name": "French Polynesia",
            "nationality": "French Polynesian"
        },
        {
            "num_code": "260",
            "alpha_2_code": "TF",
            "alpha_3_code": "ATF",
            "en_short_name": "French Southern Territories",
            "nationality": "French Southern Territories"
        },
        {
            "num_code": "266",
            "alpha_2_code": "GA",
            "alpha_3_code": "GAB",
            "en_short_name": "Gabon",
            "nationality": "Gabonese"
        },
        {
            "num_code": "270",
            "alpha_2_code": "GM",
            "alpha_3_code": "GMB",
            "en_short_name": "Gambia",
            "nationality": "Gambian"
        },
        {
            "num_code": "268",
            "alpha_2_code": "GE",
            "alpha_3_code": "GEO",
            "en_short_name": "Georgia",
            "nationality": "Georgian"
        },
        {
            "num_code": "276",
            "alpha_2_code": "DE",
            "alpha_3_code": "DEU",
            "en_short_name": "Germany",
            "nationality": "German"
        },
        {
            "num_code": "288",
            "alpha_2_code": "GH",
            "alpha_3_code": "GHA",
            "en_short_name": "Ghana",
            "nationality": "Ghanaian"
        },
        {
            "num_code": "292",
            "alpha_2_code": "GI",
            "alpha_3_code": "GIB",
            "en_short_name": "Gibraltar",
            "nationality": "Gibraltar"
        },
        {
            "num_code": "300",
            "alpha_2_code": "GR",
            "alpha_3_code": "GRC",
            "en_short_name": "Greece",
            "nationality": "Greek, Hellenic"
        },
        {
            "num_code": "304",
            "alpha_2_code": "GL",
            "alpha_3_code": "GRL",
            "en_short_name": "Greenland",
            "nationality": "Greenlandic"
        },
        {
            "num_code": "308",
            "alpha_2_code": "GD",
            "alpha_3_code": "GRD",
            "en_short_name": "Grenada",
            "nationality": "Grenadian"
        },
        {
            "num_code": "312",
            "alpha_2_code": "GP",
            "alpha_3_code": "GLP",
            "en_short_name": "Guadeloupe",
            "nationality": "Guadeloupe"
        },
        {
            "num_code": "316",
            "alpha_2_code": "GU",
            "alpha_3_code": "GUM",
            "en_short_name": "Guam",
            "nationality": "Guamanian, Guambat"
        },
        {
            "num_code": "320",
            "alpha_2_code": "GT",
            "alpha_3_code": "GTM",
            "en_short_name": "Guatemala",
            "nationality": "Guatemalan"
        },
        {
            "num_code": "831",
            "alpha_2_code": "GG",
            "alpha_3_code": "GGY",
            "en_short_name": "Guernsey",
            "nationality": "Channel Island"
        },
        {
            "num_code": "324",
            "alpha_2_code": "GN",
            "alpha_3_code": "GIN",
            "en_short_name": "Guinea",
            "nationality": "Guinean"
        },
        {
            "num_code": "624",
            "alpha_2_code": "GW",
            "alpha_3_code": "GNB",
            "en_short_name": "Guinea-Bissau",
            "nationality": "Bissau-Guinean"
        },
        {
            "num_code": "328",
            "alpha_2_code": "GY",
            "alpha_3_code": "GUY",
            "en_short_name": "Guyana",
            "nationality": "Guyanese"
        },
        {
            "num_code": "332",
            "alpha_2_code": "HT",
            "alpha_3_code": "HTI",
            "en_short_name": "Haiti",
            "nationality": "Haitian"
        },
        {
            "num_code": "334",
            "alpha_2_code": "HM",
            "alpha_3_code": "HMD",
            "en_short_name": "Heard Island and McDonald Islands",
            "nationality": "Heard Island or McDonald Islands"
        },
        {
            "num_code": "336",
            "alpha_2_code": "VA",
            "alpha_3_code": "VAT",
            "en_short_name": "Vatican City State",
            "nationality": "Vatican"
        },
        {
            "num_code": "340",
            "alpha_2_code": "HN",
            "alpha_3_code": "HND",
            "en_short_name": "Honduras",
            "nationality": "Honduran"
        },
        {
            "num_code": "344",
            "alpha_2_code": "HK",
            "alpha_3_code": "HKG",
            "en_short_name": "Hong Kong",
            "nationality": "Hong Kong, Hong Kongese"
        },
        {
            "num_code": "348",
            "alpha_2_code": "HU",
            "alpha_3_code": "HUN",
            "en_short_name": "Hungary",
            "nationality": "Hungarian, Magyar"
        },
        {
            "num_code": "352",
            "alpha_2_code": "IS",
            "alpha_3_code": "ISL",
            "en_short_name": "Iceland",
            "nationality": "Icelandic"
        },
        {
            "num_code": "356",
            "alpha_2_code": "IN",
            "alpha_3_code": "IND",
            "en_short_name": "India",
            "nationality": "Indian"
        },
        {
            "num_code": "360",
            "alpha_2_code": "ID",
            "alpha_3_code": "IDN",
            "en_short_name": "Indonesia",
            "nationality": "Indonesian"
        },
        {
            "num_code": "364",
            "alpha_2_code": "IR",
            "alpha_3_code": "IRN",
            "en_short_name": "Iran",
            "nationality": "Iranian, Persian"
        },
        {
            "num_code": "368",
            "alpha_2_code": "IQ",
            "alpha_3_code": "IRQ",
            "en_short_name": "Iraq",
            "nationality": "Iraqi"
        },
        {
            "num_code": "372",
            "alpha_2_code": "IE",
            "alpha_3_code": "IRL",
            "en_short_name": "Ireland",
            "nationality": "Irish"
        },
        {
            "num_code": "833",
            "alpha_2_code": "IM",
            "alpha_3_code": "IMN",
            "en_short_name": "Isle of Man",
            "nationality": "Manx"
        },
        {
            "num_code": "376",
            "alpha_2_code": "IL",
            "alpha_3_code": "ISR",
            "en_short_name": "Israel",
            "nationality": "Israeli"
        },
        {
            "num_code": "380",
            "alpha_2_code": "IT",
            "alpha_3_code": "ITA",
            "en_short_name": "Italy",
            "nationality": "Italian"
        },
        {
            "num_code": "388",
            "alpha_2_code": "JM",
            "alpha_3_code": "JAM",
            "en_short_name": "Jamaica",
            "nationality": "Jamaican"
        },
        {
            "num_code": "392",
            "alpha_2_code": "JP",
            "alpha_3_code": "JPN",
            "en_short_name": "Japan",
            "nationality": "Japanese"
        },
        {
            "num_code": "832",
            "alpha_2_code": "JE",
            "alpha_3_code": "JEY",
            "en_short_name": "Jersey",
            "nationality": "Channel Island"
        },
        {
            "num_code": "400",
            "alpha_2_code": "JO",
            "alpha_3_code": "JOR",
            "en_short_name": "Jordan",
            "nationality": "Jordanian"
        },
        {
            "num_code": "398",
            "alpha_2_code": "KZ",
            "alpha_3_code": "KAZ",
            "en_short_name": "Kazakhstan",
            "nationality": "Kazakhstani, Kazakh"
        },
        {
            "num_code": "404",
            "alpha_2_code": "KE",
            "alpha_3_code": "KEN",
            "en_short_name": "Kenya",
            "nationality": "Kenyan"
        },
        {
            "num_code": "296",
            "alpha_2_code": "KI",
            "alpha_3_code": "KIR",
            "en_short_name": "Kiribati",
            "nationality": "I-Kiribati"
        },
        {
            "num_code": "408",
            "alpha_2_code": "KP",
            "alpha_3_code": "PRK",
            "en_short_name": "Korea (Democratic People's Republic of)",
            "nationality": "North Korean"
        },
        {
            "num_code": "410",
            "alpha_2_code": "KR",
            "alpha_3_code": "KOR",
            "en_short_name": "Korea (Republic of)",
            "nationality": "South Korean"
        },
        {
            "num_code": "414",
            "alpha_2_code": "KW",
            "alpha_3_code": "KWT",
            "en_short_name": "Kuwait",
            "nationality": "Kuwaiti"
        },
        {
            "num_code": "417",
            "alpha_2_code": "KG",
            "alpha_3_code": "KGZ",
            "en_short_name": "Kyrgyzstan",
            "nationality": "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz"
        },
        {
            "num_code": "418",
            "alpha_2_code": "LA",
            "alpha_3_code": "LAO",
            "en_short_name": "Lao People's Democratic Republic",
            "nationality": "Lao, Laotian"
        },
        {
            "num_code": "428",
            "alpha_2_code": "LV",
            "alpha_3_code": "LVA",
            "en_short_name": "Latvia",
            "nationality": "Latvian"
        },
        {
            "num_code": "422",
            "alpha_2_code": "LB",
            "alpha_3_code": "LBN",
            "en_short_name": "Lebanon",
            "nationality": "Lebanese"
        },
        {
            "num_code": "426",
            "alpha_2_code": "LS",
            "alpha_3_code": "LSO",
            "en_short_name": "Lesotho",
            "nationality": "Basotho"
        },
        {
            "num_code": "430",
            "alpha_2_code": "LR",
            "alpha_3_code": "LBR",
            "en_short_name": "Liberia",
            "nationality": "Liberian"
        },
        {
            "num_code": "434",
            "alpha_2_code": "LY",
            "alpha_3_code": "LBY",
            "en_short_name": "Libya",
            "nationality": "Libyan"
        },
        {
            "num_code": "438",
            "alpha_2_code": "LI",
            "alpha_3_code": "LIE",
            "en_short_name": "Liechtenstein",
            "nationality": "Liechtenstein"
        },
        {
            "num_code": "440",
            "alpha_2_code": "LT",
            "alpha_3_code": "LTU",
            "en_short_name": "Lithuania",
            "nationality": "Lithuanian"
        },
        {
            "num_code": "442",
            "alpha_2_code": "LU",
            "alpha_3_code": "LUX",
            "en_short_name": "Luxembourg",
            "nationality": "Luxembourg, Luxembourgish"
        },
        {
            "num_code": "446",
            "alpha_2_code": "MO",
            "alpha_3_code": "MAC",
            "en_short_name": "Macao",
            "nationality": "Macanese, Chinese"
        },
        {
            "num_code": "807",
            "alpha_2_code": "MK",
            "alpha_3_code": "MKD",
            "en_short_name": "Macedonia (the former Yugoslav Republic of)",
            "nationality": "Macedonian"
        },
        {
            "num_code": "450",
            "alpha_2_code": "MG",
            "alpha_3_code": "MDG",
            "en_short_name": "Madagascar",
            "nationality": "Malagasy"
        },
        {
            "num_code": "454",
            "alpha_2_code": "MW",
            "alpha_3_code": "MWI",
            "en_short_name": "Malawi",
            "nationality": "Malawian"
        },
        {
            "num_code": "458",
            "alpha_2_code": "MY",
            "alpha_3_code": "MYS",
            "en_short_name": "Malaysia",
            "nationality": "Malaysian"
        },
        {
            "num_code": "462",
            "alpha_2_code": "MV",
            "alpha_3_code": "MDV",
            "en_short_name": "Maldives",
            "nationality": "Maldivian"
        },
        {
            "num_code": "466",
            "alpha_2_code": "ML",
            "alpha_3_code": "MLI",
            "en_short_name": "Mali",
            "nationality": "Malian, Malinese"
        },
        {
            "num_code": "470",
            "alpha_2_code": "MT",
            "alpha_3_code": "MLT",
            "en_short_name": "Malta",
            "nationality": "Maltese"
        },
        {
            "num_code": "584",
            "alpha_2_code": "MH",
            "alpha_3_code": "MHL",
            "en_short_name": "Marshall Islands",
            "nationality": "Marshallese"
        },
        {
            "num_code": "474",
            "alpha_2_code": "MQ",
            "alpha_3_code": "MTQ",
            "en_short_name": "Martinique",
            "nationality": "Martiniquais, Martinican"
        },
        {
            "num_code": "478",
            "alpha_2_code": "MR",
            "alpha_3_code": "MRT",
            "en_short_name": "Mauritania",
            "nationality": "Mauritanian"
        },
        {
            "num_code": "480",
            "alpha_2_code": "MU",
            "alpha_3_code": "MUS",
            "en_short_name": "Mauritius",
            "nationality": "Mauritian"
        },
        {
            "num_code": "175",
            "alpha_2_code": "YT",
            "alpha_3_code": "MYT",
            "en_short_name": "Mayotte",
            "nationality": "Mahoran"
        },
        {
            "num_code": "484",
            "alpha_2_code": "MX",
            "alpha_3_code": "MEX",
            "en_short_name": "Mexico",
            "nationality": "Mexican"
        },
        {
            "num_code": "583",
            "alpha_2_code": "FM",
            "alpha_3_code": "FSM",
            "en_short_name": "Micronesia (Federated States of)",
            "nationality": "Micronesian"
        },
        {
            "num_code": "498",
            "alpha_2_code": "MD",
            "alpha_3_code": "MDA",
            "en_short_name": "Moldova (Republic of)",
            "nationality": "Moldovan"
        },
        {
            "num_code": "492",
            "alpha_2_code": "MC",
            "alpha_3_code": "MCO",
            "en_short_name": "Monaco",
            "nationality": "Mon\u00e9gasque, Monacan"
        },
        {
            "num_code": "496",
            "alpha_2_code": "MN",
            "alpha_3_code": "MNG",
            "en_short_name": "Mongolia",
            "nationality": "Mongolian"
        },
        {
            "num_code": "499",
            "alpha_2_code": "ME",
            "alpha_3_code": "MNE",
            "en_short_name": "Montenegro",
            "nationality": "Montenegrin"
        },
        {
            "num_code": "500",
            "alpha_2_code": "MS",
            "alpha_3_code": "MSR",
            "en_short_name": "Montserrat",
            "nationality": "Montserratian"
        },
        {
            "num_code": "504",
            "alpha_2_code": "MA",
            "alpha_3_code": "MAR",
            "en_short_name": "Morocco",
            "nationality": "Moroccan"
        },
        {
            "num_code": "508",
            "alpha_2_code": "MZ",
            "alpha_3_code": "MOZ",
            "en_short_name": "Mozambique",
            "nationality": "Mozambican"
        },
        {
            "num_code": "104",
            "alpha_2_code": "MM",
            "alpha_3_code": "MMR",
            "en_short_name": "Myanmar",
            "nationality": "Burmese"
        },
        {
            "num_code": "516",
            "alpha_2_code": "NA",
            "alpha_3_code": "NAM",
            "en_short_name": "Namibia",
            "nationality": "Namibian"
        },
        {
            "num_code": "520",
            "alpha_2_code": "NR",
            "alpha_3_code": "NRU",
            "en_short_name": "Nauru",
            "nationality": "Nauruan"
        },
        {
            "num_code": "524",
            "alpha_2_code": "NP",
            "alpha_3_code": "NPL",
            "en_short_name": "Nepal",
            "nationality": "Nepali, Nepalese"
        },
        {
            "num_code": "528",
            "alpha_2_code": "NL",
            "alpha_3_code": "NLD",
            "en_short_name": "Netherlands",
            "nationality": "Dutch, Netherlandic"
        },
        {
            "num_code": "540",
            "alpha_2_code": "NC",
            "alpha_3_code": "NCL",
            "en_short_name": "New Caledonia",
            "nationality": "New Caledonian"
        },
        {
            "num_code": "554",
            "alpha_2_code": "NZ",
            "alpha_3_code": "NZL",
            "en_short_name": "New Zealand",
            "nationality": "New Zealand, NZ"
        },
        {
            "num_code": "558",
            "alpha_2_code": "NI",
            "alpha_3_code": "NIC",
            "en_short_name": "Nicaragua",
            "nationality": "Nicaraguan"
        },
        {
            "num_code": "562",
            "alpha_2_code": "NE",
            "alpha_3_code": "NER",
            "en_short_name": "Niger",
            "nationality": "Nigerien"
        },
        {
            "num_code": "566",
            "alpha_2_code": "NG",
            "alpha_3_code": "NGA",
            "en_short_name": "Nigeria",
            "nationality": "Nigerian"
        },
        {
            "num_code": "570",
            "alpha_2_code": "NU",
            "alpha_3_code": "NIU",
            "en_short_name": "Niue",
            "nationality": "Niuean"
        },
        {
            "num_code": "574",
            "alpha_2_code": "NF",
            "alpha_3_code": "NFK",
            "en_short_name": "Norfolk Island",
            "nationality": "Norfolk Island"
        },
        {
            "num_code": "580",
            "alpha_2_code": "MP",
            "alpha_3_code": "MNP",
            "en_short_name": "Northern Mariana Islands",
            "nationality": "Northern Marianan"
        },
        {
            "num_code": "578",
            "alpha_2_code": "NO",
            "alpha_3_code": "NOR",
            "en_short_name": "Norway",
            "nationality": "Norwegian"
        },
        {
            "num_code": "512",
            "alpha_2_code": "OM",
            "alpha_3_code": "OMN",
            "en_short_name": "Oman",
            "nationality": "Omani"
        },
        {
            "num_code": "586",
            "alpha_2_code": "PK",
            "alpha_3_code": "PAK",
            "en_short_name": "Pakistan",
            "nationality": "Pakistani"
        },
        {
            "num_code": "585",
            "alpha_2_code": "PW",
            "alpha_3_code": "PLW",
            "en_short_name": "Palau",
            "nationality": "Palauan"
        },
        {
            "num_code": "275",
            "alpha_2_code": "PS",
            "alpha_3_code": "PSE",
            "en_short_name": "Palestine, State of",
            "nationality": "Palestinian"
        },
        {
            "num_code": "591",
            "alpha_2_code": "PA",
            "alpha_3_code": "PAN",
            "en_short_name": "Panama",
            "nationality": "Panamanian"
        },
        {
            "num_code": "598",
            "alpha_2_code": "PG",
            "alpha_3_code": "PNG",
            "en_short_name": "Papua New Guinea",
            "nationality": "Papua New Guinean, Papuan"
        },
        {
            "num_code": "600",
            "alpha_2_code": "PY",
            "alpha_3_code": "PRY",
            "en_short_name": "Paraguay",
            "nationality": "Paraguayan"
        },
        {
            "num_code": "604",
            "alpha_2_code": "PE",
            "alpha_3_code": "PER",
            "en_short_name": "Peru",
            "nationality": "Peruvian"
        },
        {
            "num_code": "608",
            "alpha_2_code": "PH",
            "alpha_3_code": "PHL",
            "en_short_name": "Philippines",
            "nationality": "Philippine, Filipino"
        },
        {
            "num_code": "612",
            "alpha_2_code": "PN",
            "alpha_3_code": "PCN",
            "en_short_name": "Pitcairn",
            "nationality": "Pitcairn Island"
        },
        {
            "num_code": "616",
            "alpha_2_code": "PL",
            "alpha_3_code": "POL",
            "en_short_name": "Poland",
            "nationality": "Polish"
        },
        {
            "num_code": "620",
            "alpha_2_code": "PT",
            "alpha_3_code": "PRT",
            "en_short_name": "Portugal",
            "nationality": "Portuguese"
        },
        {
            "num_code": "630",
            "alpha_2_code": "PR",
            "alpha_3_code": "PRI",
            "en_short_name": "Puerto Rico",
            "nationality": "Puerto Rican"
        },
        {
            "num_code": "634",
            "alpha_2_code": "QA",
            "alpha_3_code": "QAT",
            "en_short_name": "Qatar",
            "nationality": "Qatari"
        },
        {
            "num_code": "638",
            "alpha_2_code": "RE",
            "alpha_3_code": "REU",
            "en_short_name": "R\u00e9union",
            "nationality": "R\u00e9unionese, R\u00e9unionnais"
        },
        {
            "num_code": "642",
            "alpha_2_code": "RO",
            "alpha_3_code": "ROU",
            "en_short_name": "Romania",
            "nationality": "Romanian"
        },
        {
            "num_code": "643",
            "alpha_2_code": "RU",
            "alpha_3_code": "RUS",
            "en_short_name": "Russian Federation",
            "nationality": "Russian"
        },
        {
            "num_code": "646",
            "alpha_2_code": "RW",
            "alpha_3_code": "RWA",
            "en_short_name": "Rwanda",
            "nationality": "Rwandan"
        },
        {
            "num_code": "652",
            "alpha_2_code": "BL",
            "alpha_3_code": "BLM",
            "en_short_name": "Saint Barth\u00e9lemy",
            "nationality": "Barth\u00e9lemois"
        },
        {
            "num_code": "654",
            "alpha_2_code": "SH",
            "alpha_3_code": "SHN",
            "en_short_name": "Saint Helena, Ascension and Tristan da Cunha",
            "nationality": "Saint Helenian"
        },
        {
            "num_code": "659",
            "alpha_2_code": "KN",
            "alpha_3_code": "KNA",
            "en_short_name": "Saint Kitts and Nevis",
            "nationality": "Kittitian or Nevisian"
        },
        {
            "num_code": "662",
            "alpha_2_code": "LC",
            "alpha_3_code": "LCA",
            "en_short_name": "Saint Lucia",
            "nationality": "Saint Lucian"
        },
        {
            "num_code": "663",
            "alpha_2_code": "MF",
            "alpha_3_code": "MAF",
            "en_short_name": "Saint Martin (French part)",
            "nationality": "Saint-Martinoise"
        },
        {
            "num_code": "666",
            "alpha_2_code": "PM",
            "alpha_3_code": "SPM",
            "en_short_name": "Saint Pierre and Miquelon",
            "nationality": "Saint-Pierrais or Miquelonnais"
        },
        {
            "num_code": "670",
            "alpha_2_code": "VC",
            "alpha_3_code": "VCT",
            "en_short_name": "Saint Vincent and the Grenadines",
            "nationality": "Saint Vincentian, Vincentian"
        },
        {
            "num_code": "882",
            "alpha_2_code": "WS",
            "alpha_3_code": "WSM",
            "en_short_name": "Samoa",
            "nationality": "Samoan"
        },
        {
            "num_code": "674",
            "alpha_2_code": "SM",
            "alpha_3_code": "SMR",
            "en_short_name": "San Marino",
            "nationality": "Sammarinese"
        },
        {
            "num_code": "678",
            "alpha_2_code": "ST",
            "alpha_3_code": "STP",
            "en_short_name": "Sao Tome and Principe",
            "nationality": "Sao Tome and Principe"
        },
        {
            "num_code": "682",
            "alpha_2_code": "SA",
            "alpha_3_code": "SAU",
            "en_short_name": "Saudi Arabia",
            "nationality": "Saudi, Saudi Arabian"
        },
        {
            "num_code": "686",
            "alpha_2_code": "SN",
            "alpha_3_code": "SEN",
            "en_short_name": "Senegal",
            "nationality": "Senegalese"
        },
        {
            "num_code": "688",
            "alpha_2_code": "RS",
            "alpha_3_code": "SRB",
            "en_short_name": "Serbia",
            "nationality": "Serbian"
        },
        {
            "num_code": "690",
            "alpha_2_code": "SC",
            "alpha_3_code": "SYC",
            "en_short_name": "Seychelles",
            "nationality": "Seychellois"
        },
        {
            "num_code": "694",
            "alpha_2_code": "SL",
            "alpha_3_code": "SLE",
            "en_short_name": "Sierra Leone",
            "nationality": "Sierra Leonean"
        },
        {
            "num_code": "702",
            "alpha_2_code": "SG",
            "alpha_3_code": "SGP",
            "en_short_name": "Singapore",
            "nationality": "Singaporean"
        },
        {
            "num_code": "534",
            "alpha_2_code": "SX",
            "alpha_3_code": "SXM",
            "en_short_name": "Sint Maarten (Dutch part)",
            "nationality": "Sint Maarten"
        },
        {
            "num_code": "703",
            "alpha_2_code": "SK",
            "alpha_3_code": "SVK",
            "en_short_name": "Slovakia",
            "nationality": "Slovak"
        },
        {
            "num_code": "705",
            "alpha_2_code": "SI",
            "alpha_3_code": "SVN",
            "en_short_name": "Slovenia",
            "nationality": "Slovenian, Slovene"
        },
        {
            "num_code": "90",
            "alpha_2_code": "SB",
            "alpha_3_code": "SLB",
            "en_short_name": "Solomon Islands",
            "nationality": "Solomon Island"
        },
        {
            "num_code": "706",
            "alpha_2_code": "SO",
            "alpha_3_code": "SOM",
            "en_short_name": "Somalia",
            "nationality": "Somali, Somalian"
        },
        {
            "num_code": "710",
            "alpha_2_code": "ZA",
            "alpha_3_code": "ZAF",
            "en_short_name": "South Africa",
            "nationality": "South African"
        },
        {
            "num_code": "239",
            "alpha_2_code": "GS",
            "alpha_3_code": "SGS",
            "en_short_name": "South Georgia and the South Sandwich Islands",
            "nationality": "South Georgia or South Sandwich Islands"
        },
        {
            "num_code": "728",
            "alpha_2_code": "SS",
            "alpha_3_code": "SSD",
            "en_short_name": "South Sudan",
            "nationality": "South Sudanese"
        },
        {
            "num_code": "724",
            "alpha_2_code": "ES",
            "alpha_3_code": "ESP",
            "en_short_name": "Spain",
            "nationality": "Spanish"
        },
        {
            "num_code": "144",
            "alpha_2_code": "LK",
            "alpha_3_code": "LKA",
            "en_short_name": "Sri Lanka",
            "nationality": "Sri Lankan"
        },
        {
            "num_code": "729",
            "alpha_2_code": "SD",
            "alpha_3_code": "SDN",
            "en_short_name": "Sudan",
            "nationality": "Sudanese"
        },
        {
            "num_code": "740",
            "alpha_2_code": "SR",
            "alpha_3_code": "SUR",
            "en_short_name": "Suriname",
            "nationality": "Surinamese"
        },
        {
            "num_code": "744",
            "alpha_2_code": "SJ",
            "alpha_3_code": "SJM",
            "en_short_name": "Svalbard and Jan Mayen",
            "nationality": "Svalbard"
        },
        {
            "num_code": "748",
            "alpha_2_code": "SZ",
            "alpha_3_code": "SWZ",
            "en_short_name": "Swaziland",
            "nationality": "Swazi"
        },
        {
            "num_code": "752",
            "alpha_2_code": "SE",
            "alpha_3_code": "SWE",
            "en_short_name": "Sweden",
            "nationality": "Swedish"
        },
        {
            "num_code": "756",
            "alpha_2_code": "CH",
            "alpha_3_code": "CHE",
            "en_short_name": "Switzerland",
            "nationality": "Swiss"
        },
        {
            "num_code": "760",
            "alpha_2_code": "SY",
            "alpha_3_code": "SYR",
            "en_short_name": "Syrian Arab Republic",
            "nationality": "Syrian"
        },
        {
            "num_code": "158",
            "alpha_2_code": "TW",
            "alpha_3_code": "TWN",
            "en_short_name": "Taiwan, Province of China",
            "nationality": "Chinese, Taiwanese"
        },
        {
            "num_code": "762",
            "alpha_2_code": "TJ",
            "alpha_3_code": "TJK",
            "en_short_name": "Tajikistan",
            "nationality": "Tajikistani"
        },
        {
            "num_code": "834",
            "alpha_2_code": "TZ",
            "alpha_3_code": "TZA",
            "en_short_name": "Tanzania, United Republic of",
            "nationality": "Tanzanian"
        },
        {
            "num_code": "764",
            "alpha_2_code": "TH",
            "alpha_3_code": "THA",
            "en_short_name": "Thailand",
            "nationality": "Thai"
        },
        {
            "num_code": "626",
            "alpha_2_code": "TL",
            "alpha_3_code": "TLS",
            "en_short_name": "Timor-Leste",
            "nationality": "Timorese"
        },
        {
            "num_code": "768",
            "alpha_2_code": "TG",
            "alpha_3_code": "TGO",
            "en_short_name": "Togo",
            "nationality": "Togolese"
        },
        {
            "num_code": "772",
            "alpha_2_code": "TK",
            "alpha_3_code": "TKL",
            "en_short_name": "Tokelau",
            "nationality": "Tokelauan"
        },
        {
            "num_code": "776",
            "alpha_2_code": "TO",
            "alpha_3_code": "TON",
            "en_short_name": "Tonga",
            "nationality": "Tongan"
        },
        {
            "num_code": "780",
            "alpha_2_code": "TT",
            "alpha_3_code": "TTO",
            "en_short_name": "Trinidad and Tobago",
            "nationality": "Trinidadian or Tobagonian"
        },
        {
            "num_code": "788",
            "alpha_2_code": "TN",
            "alpha_3_code": "TUN",
            "en_short_name": "Tunisia",
            "nationality": "Tunisian"
        },
        {
            "num_code": "792",
            "alpha_2_code": "TR",
            "alpha_3_code": "TUR",
            "en_short_name": "Turkey",
            "nationality": "Turkish"
        },
        {
            "num_code": "795",
            "alpha_2_code": "TM",
            "alpha_3_code": "TKM",
            "en_short_name": "Turkmenistan",
            "nationality": "Turkmen"
        },
        {
            "num_code": "796",
            "alpha_2_code": "TC",
            "alpha_3_code": "TCA",
            "en_short_name": "Turks and Caicos Islands",
            "nationality": "Turks and Caicos Island"
        },
        {
            "num_code": "798",
            "alpha_2_code": "TV",
            "alpha_3_code": "TUV",
            "en_short_name": "Tuvalu",
            "nationality": "Tuvaluan"
        },
        {
            "num_code": "800",
            "alpha_2_code": "UG",
            "alpha_3_code": "UGA",
            "en_short_name": "Uganda",
            "nationality": "Ugandan"
        },
        {
            "num_code": "804",
            "alpha_2_code": "UA",
            "alpha_3_code": "UKR",
            "en_short_name": "Ukraine",
            "nationality": "Ukrainian"
        },
        {
            "num_code": "784",
            "alpha_2_code": "AE",
            "alpha_3_code": "ARE",
            "en_short_name": "United Arab Emirates",
            "nationality": "Emirati, Emirian, Emiri"
        },
        {
            "num_code": "826",
            "alpha_2_code": "GB",
            "alpha_3_code": "GBR",
            "en_short_name": "United Kingdom of Great Britain and Northern Ireland",
            "nationality": "British, UK"
        },
        {
            "num_code": "581",
            "alpha_2_code": "UM",
            "alpha_3_code": "UMI",
            "en_short_name": "United States Minor Outlying Islands",
            "nationality": "American"
        },
        {
            "num_code": "840",
            "alpha_2_code": "US",
            "alpha_3_code": "USA",
            "en_short_name": "United States of America",
            "nationality": "American"
        },
        {
            "num_code": "858",
            "alpha_2_code": "UY",
            "alpha_3_code": "URY",
            "en_short_name": "Uruguay",
            "nationality": "Uruguayan"
        },
        {
            "num_code": "860",
            "alpha_2_code": "UZ",
            "alpha_3_code": "UZB",
            "en_short_name": "Uzbekistan",
            "nationality": "Uzbekistani, Uzbek"
        },
        {
            "num_code": "548",
            "alpha_2_code": "VU",
            "alpha_3_code": "VUT",
            "en_short_name": "Vanuatu",
            "nationality": "Ni-Vanuatu, Vanuatuan"
        },
        {
            "num_code": "862",
            "alpha_2_code": "VE",
            "alpha_3_code": "VEN",
            "en_short_name": "Venezuela (Bolivarian Republic of)",
            "nationality": "Venezuelan"
        },
        {
            "num_code": "704",
            "alpha_2_code": "VN",
            "alpha_3_code": "VNM",
            "en_short_name": "Vietnam",
            "nationality": "Vietnamese"
        },
        {
            "num_code": "92",
            "alpha_2_code": "VG",
            "alpha_3_code": "VGB",
            "en_short_name": "Virgin Islands (British)",
            "nationality": "British Virgin Island"
        },
        {
            "num_code": "850",
            "alpha_2_code": "VI",
            "alpha_3_code": "VIR",
            "en_short_name": "Virgin Islands (U.S.)",
            "nationality": "U.S. Virgin Island"
        },
        {
            "num_code": "876",
            "alpha_2_code": "WF",
            "alpha_3_code": "WLF",
            "en_short_name": "Wallis and Futuna",
            "nationality": "Wallis and Futuna, Wallisian or Futunan"
        },
        {
            "num_code": "732",
            "alpha_2_code": "EH",
            "alpha_3_code": "ESH",
            "en_short_name": "Western Sahara",
            "nationality": "Sahrawi, Sahrawian, Sahraouian"
        },
        {
            "num_code": "887",
            "alpha_2_code": "YE",
            "alpha_3_code": "YEM",
            "en_short_name": "Yemen",
            "nationality": "Yemeni"
        },
        {
            "num_code": "894",
            "alpha_2_code": "ZM",
            "alpha_3_code": "ZMB",
            "en_short_name": "Zambia",
            "nationality": "Zambian"
        },
        {
            "num_code": "716",
            "alpha_2_code": "ZW",
            "alpha_3_code": "ZWE",
            "en_short_name": "Zimbabwe",
            "nationality": "Zimbabwean"
        }
    ],
    districts: [
        {
            "id": 70,
            "name": "ABIM",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja"
        },
        {
            "id": 40,
            "name": "ADJUMANI",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi"
        },
        {
            "id": 111,
            "name": "AGAGO",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi"
        },
        {
            "id": 88,
            "name": "ALEBTONG",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango"
        },
        {
            "id": 57,
            "name": "AMOLATAR",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango"
        },
        {
            "id": 81,
            "name": "AMUDAT",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja"
        },
        {
            "id": 58,
            "name": "AMURIA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso"
        },
        {
            "id": 71,
            "name": "AMURU",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi"
        },
        {
            "id": 1,
            "name": "APAC",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango"
        },
        {
            "id": 2,
            "name": "ARUA",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile"
        },
        {
            "id": 136,
            "name": "ARUA CITY",
            "type": "City",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile"
        },
        {
            "id": 72,
            "name": "BUDAKA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi"
        },
        {
            "id": 78,
            "name": "BUDUDA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu"
        },
        {
            "id": 41,
            "name": "BUGIRI",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga"
        },
        {
            "id": 123,
            "name": "BUGWERI",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga"
        },
        {
            "id": 109,
            "name": "BUHWEJU",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole"
        },
        {
            "id": 82,
            "name": "BUIKWE",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 79,
            "name": "BUKEDEA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso"
        },
        {
            "id": 98,
            "name": "BUKOMANSIMBI",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 59,
            "name": "BUKWO",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei"
        },
        {
            "id": 89,
            "name": "BULAMBULI",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu"
        },
        {
            "id": 73,
            "name": "BULIISA",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro"
        },
        {
            "id": 3,
            "name": "BUNDIBUGYO",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori"
        },
        {
            "id": 117,
            "name": "BUNYANGABU",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro"
        },
        {
            "id": 4,
            "name": "BUSHENYI",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole"
        },
        {
            "id": 42,
            "name": "BUSIA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi"
        },
        {
            "id": 60,
            "name": "BUTALEJA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi"
        },
        {
            "id": 99,
            "name": "BUTAMBALA",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 118,
            "name": "BUTEBO",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi"
        },
        {
            "id": 90,
            "name": "BUVUMA",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 83,
            "name": "BUYENDE",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga"
        },
        {
            "id": 74,
            "name": "DOKOLO",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango"
        },
        {
            "id": 139,
            "name": "FORT PORTAL CITY",
            "type": "City",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro"
        },
        {
            "id": 91,
            "name": "GOMBA",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 5,
            "name": "GULU",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi"
        },
        {
            "id": 137,
            "name": "GULU CITY",
            "type": "City",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi"
        },
        {
            "id": 6,
            "name": "HOIMA",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro"
        },
        {
            "id": 145,
            "name": "HOIMA CITY",
            "type": "City",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro"
        },
        {
            "id": 61,
            "name": "IBANDA",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole"
        },
        {
            "id": 7,
            "name": "IGANGA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga"
        },
        {
            "id": 62,
            "name": "ISINGIRO",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole"
        },
        {
            "id": 8,
            "name": "JINJA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga"
        },
        {
            "id": 138,
            "name": "JINJA CITY",
            "type": "City",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga"
        },
        {
            "id": 63,
            "name": "KAABONG",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja"
        },
        {
            "id": 9,
            "name": "KABALE",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi"
        },
        {
            "id": 10,
            "name": "KABAROLE",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro"
        },
        {
            "id": 54,
            "name": "KABERAMAIDO",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso"
        },
        {
            "id": 113,
            "name": "KAGADI",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro"
        },
        {
            "id": 114,
            "name": "KAKUMIRO",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro"
        },
        {
            "id": 129,
            "name": "KALAKI",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso"
        },
        {
            "id": 11,
            "name": "KALANGALA",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 64,
            "name": "KALIRO",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga"
        },
        {
            "id": 100,
            "name": "KALUNGU",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 12,
            "name": "KAMPALA",
            "type": "City",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 13,
            "name": "KAMULI",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga"
        },
        {
            "id": 46,
            "name": "KAMWENGE",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro"
        },
        {
            "id": 55,
            "name": "KANUNGU",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi"
        },
        {
            "id": 14,
            "name": "KAPCHORWA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei"
        },
        {
            "id": 124,
            "name": "KAPELEBYONG",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso"
        },
        {
            "id": 130,
            "name": "KARENGA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja"
        },
        {
            "id": 15,
            "name": "KASESE",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori"
        },
        {
            "id": 125,
            "name": "KASSANDA",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 43,
            "name": "KATAKWI",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso"
        },
        {
            "id": 47,
            "name": "KAYUNGA",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 131,
            "name": "KAZO",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole"
        },
        {
            "id": 16,
            "name": "KIBAALE",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro"
        },
        {
            "id": 17,
            "name": "KIBOGA",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 102,
            "name": "KIBUKU",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi"
        },
        {
            "id": 126,
            "name": "KIKUUBE",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro"
        },
        {
            "id": 65,
            "name": "KIRUHURA",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole"
        },
        {
            "id": 92,
            "name": "KIRYANDONGO",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro"
        },
        {
            "id": 18,
            "name": "KISORO",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi"
        },
        {
            "id": 132,
            "name": "KITAGWENDA",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro"
        },
        {
            "id": 19,
            "name": "KITGUM",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi"
        },
        {
            "id": 66,
            "name": "KOBOKO",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile"
        },
        {
            "id": 103,
            "name": "KOLE",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango"
        },
        {
            "id": 20,
            "name": "KOTIDO",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja"
        },
        {
            "id": 21,
            "name": "KUMI",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso"
        },
        {
            "id": 127,
            "name": "KWANIA",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango"
        },
        {
            "id": 104,
            "name": "KWEEN",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei"
        },
        {
            "id": 93,
            "name": "KYANKWANZI",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 84,
            "name": "KYEGEGWA",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro"
        },
        {
            "id": 48,
            "name": "KYENJOJO",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro"
        },
        {
            "id": 119,
            "name": "KYOTERA",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 85,
            "name": "LAMWO",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi"
        },
        {
            "id": 22,
            "name": "LIRA",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango"
        },
        {
            "id": 144,
            "name": "LIRA CITY",
            "type": "City",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango"
        },
        {
            "id": 94,
            "name": "LUUKA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga"
        },
        {
            "id": 23,
            "name": "LUWEERO",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 105,
            "name": "LWENGO",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 80,
            "name": "LYANTONDE",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 133,
            "name": "MADI-OKOLLO",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile"
        },
        {
            "id": 67,
            "name": "MANAFWA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu"
        },
        {
            "id": 77,
            "name": "MARACHA",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile"
        },
        {
            "id": 24,
            "name": "MASAKA",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 141,
            "name": "MASAKA CITY",
            "type": "City",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 25,
            "name": "MASINDI",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro"
        },
        {
            "id": 49,
            "name": "MAYUGE",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga"
        },
        {
            "id": 26,
            "name": "MBALE",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu"
        },
        {
            "id": 142,
            "name": "MBALE CITY",
            "type": "City",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu"
        },
        {
            "id": 27,
            "name": "MBARARA",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole"
        },
        {
            "id": 140,
            "name": "MBARARA CITY",
            "type": "City",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole"
        },
        {
            "id": 106,
            "name": "MITOOMA",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole"
        },
        {
            "id": 68,
            "name": "MITYANA",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 28,
            "name": "MOROTO",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja"
        },
        {
            "id": 29,
            "name": "MOYO",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi"
        },
        {
            "id": 30,
            "name": "MPIGI",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 31,
            "name": "MUBENDE",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 32,
            "name": "MUKONO",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 128,
            "name": "NABILATUK",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja"
        },
        {
            "id": 56,
            "name": "NAKAPIRIPIRIT",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja"
        },
        {
            "id": 69,
            "name": "NAKASEKE",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 44,
            "name": "NAKASONGOLA",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 95,
            "name": "NAMAYINGO",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga"
        },
        {
            "id": 120,
            "name": "NAMISINDWA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu"
        },
        {
            "id": 75,
            "name": "NAMUTUMBA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga"
        },
        {
            "id": 107,
            "name": "NAPAK",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja"
        },
        {
            "id": 33,
            "name": "NEBBI",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile"
        },
        {
            "id": 108,
            "name": "NGORA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso"
        },
        {
            "id": 96,
            "name": "NTOROKO",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori"
        },
        {
            "id": 34,
            "name": "NTUNGAMO",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole"
        },
        {
            "id": 110,
            "name": "NWOYA",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi"
        },
        {
            "id": 134,
            "name": "OBONGI",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi"
        },
        {
            "id": 115,
            "name": "OMORO",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi"
        },
        {
            "id": 86,
            "name": "OTUKE",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango"
        },
        {
            "id": 76,
            "name": "OYAM",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango"
        },
        {
            "id": 50,
            "name": "PADER",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi"
        },
        {
            "id": 121,
            "name": "PAKWACH",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile"
        },
        {
            "id": 35,
            "name": "PALLISA",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi"
        },
        {
            "id": 36,
            "name": "RAKAI",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 116,
            "name": "RUBANDA",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi"
        },
        {
            "id": 112,
            "name": "RUBIRIZI",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole"
        },
        {
            "id": 122,
            "name": "RUKIGA",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi"
        },
        {
            "id": 37,
            "name": "RUKUNGIRI",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi"
        },
        {
            "id": 135,
            "name": "RWAMPARA",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole"
        },
        {
            "id": 97,
            "name": "SERERE",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso"
        },
        {
            "id": 101,
            "name": "SHEEMA",
            "type": "District",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole"
        },
        {
            "id": 51,
            "name": "SIRONKO",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu"
        },
        {
            "id": 38,
            "name": "SOROTI",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso"
        },
        {
            "id": 146,
            "name": "SOROTI CITY",
            "type": "City",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso"
        },
        {
            "id": 45,
            "name": "SSEMBABULE",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 143,
            "name": "TEREGO",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile"
        },
        {
            "id": 39,
            "name": "TORORO",
            "type": "District",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi"
        },
        {
            "id": 52,
            "name": "WAKISO",
            "type": "District",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda"
        },
        {
            "id": 53,
            "name": "YUMBE",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile"
        },
        {
            "id": 87,
            "name": "ZOMBO",
            "type": "District",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile"
        }
    ],
    counties: [
        {
            "id": 98,
            "name": "ADJUMANI EAST County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 40,
            "district_name": "ADJUMANI"
        },
        {
            "id": 99,
            "name": "ADJUMANI WEST County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 40,
            "district_name": "ADJUMANI"
        },
        {
            "id": 253,
            "name": "AGAGO County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO"
        },
        {
            "id": 254,
            "name": "AGAGO NORTH County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO"
        },
        {
            "id": 252,
            "name": "AGAGO WEST County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO"
        },
        {
            "id": 80,
            "name": "AGULE County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA"
        },
        {
            "id": 206,
            "name": "AJURI County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG"
        },
        {
            "id": 141,
            "name": "AMURIA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA"
        },
        {
            "id": 1,
            "name": "APAC Municipality",
            "tyep": "Municipality",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC"
        },
        {
            "id": 130,
            "name": "ARINGA County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE"
        },
        {
            "id": 131,
            "name": "ARINGA EAST County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE"
        },
        {
            "id": 132,
            "name": "ARINGA NORTH County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE"
        },
        {
            "id": 133,
            "name": "ARINGA SOUTH County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE"
        },
        {
            "id": 292,
            "name": "ARUA CENTRAL DIVISION",
            "tyep": "City-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 136,
            "district_name": "ARUA CITY"
        },
        {
            "id": 121,
            "name": "ARUU County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER"
        },
        {
            "id": 122,
            "name": "ARUU NORTH County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER"
        },
        {
            "id": 9,
            "name": "ASWA County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 5,
            "district_name": "GULU"
        },
        {
            "id": 293,
            "name": "AYIVU DIVISION",
            "tyep": "City-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 136,
            "district_name": "ARUA CITY"
        },
        {
            "id": 51,
            "name": "BAMUNANIKA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO"
        },
        {
            "id": 294,
            "name": "BARDEGE-LAYIBI DIVISION",
            "tyep": "City-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 137,
            "district_name": "GULU CITY"
        },
        {
            "id": 116,
            "name": "BBALE County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA"
        },
        {
            "id": 244,
            "name": "BOKORA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK"
        },
        {
            "id": 245,
            "name": "BOKORA EAST County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK"
        },
        {
            "id": 269,
            "name": "BUBULO EAST County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA"
        },
        {
            "id": 163,
            "name": "BUBULO WEST County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA"
        },
        {
            "id": 123,
            "name": "BUDADIRI County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO"
        },
        {
            "id": 174,
            "name": "BUDAKA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA"
        },
        {
            "id": 195,
            "name": "BUDIOPE EAST County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE"
        },
        {
            "id": 196,
            "name": "BUDIOPE WEST County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE"
        },
        {
            "id": 108,
            "name": "BUDYEBO County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA"
        },
        {
            "id": 39,
            "name": "BUFUMBIRA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO"
        },
        {
            "id": 27,
            "name": "BUGABULA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI"
        },
        {
            "id": 10,
            "name": "BUGAHYA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA"
        },
        {
            "id": 259,
            "name": "BUGANGAIZI EAST County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO"
        },
        {
            "id": 260,
            "name": "BUGANGAIZI SOUTH County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO"
        },
        {
            "id": 261,
            "name": "BUGANGAIZI WEST County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO"
        },
        {
            "id": 5,
            "name": "BUGHENDERA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO"
        },
        {
            "id": 100,
            "name": "BUGIRI Municipality",
            "tyep": "Municipality",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI"
        },
        {
            "id": 273,
            "name": "BUGWERI County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 123,
            "district_name": "BUGWERI"
        },
        {
            "id": 277,
            "name": "BUHAGUZI County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 126,
            "district_name": "KIKUUBE"
        },
        {
            "id": 278,
            "name": "BUHAGUZI EAST County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 126,
            "district_name": "KIKUUBE"
        },
        {
            "id": 248,
            "name": "BUHWEJU County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU"
        },
        {
            "id": 249,
            "name": "BUHWEJU WEST County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU"
        },
        {
            "id": 192,
            "name": "BUIKWE County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE"
        },
        {
            "id": 54,
            "name": "BUJENJE County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI"
        },
        {
            "id": 20,
            "name": "BUJUMBA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 11,
            "district_name": "KALANGALA"
        },
        {
            "id": 149,
            "name": "BUKANGA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO"
        },
        {
            "id": 150,
            "name": "BUKANGA NORTH County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO"
        },
        {
            "id": 188,
            "name": "BUKEDEA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA"
        },
        {
            "id": 40,
            "name": "BUKIMBIRI County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO"
        },
        {
            "id": 227,
            "name": "BUKOMANSIMBI NORTH County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 98,
            "district_name": "BUKOMANSIMBI"
        },
        {
            "id": 228,
            "name": "BUKOMANSIMBI SOUTH County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 98,
            "district_name": "BUKOMANSIMBI"
        },
        {
            "id": 32,
            "name": "BUKONJO County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE"
        },
        {
            "id": 179,
            "name": "BUKONO County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA"
        },
        {
            "id": 101,
            "name": "BUKOOLI County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI"
        },
        {
            "id": 220,
            "name": "BUKOOLI ISLAND County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 95,
            "district_name": "NAMAYINGO"
        },
        {
            "id": 221,
            "name": "BUKOOLI SOUTH County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 95,
            "district_name": "NAMAYINGO"
        },
        {
            "id": 53,
            "name": "BUKOTO County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 24,
            "district_name": "MASAKA"
        },
        {
            "id": 240,
            "name": "BUKOTO County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 105,
            "district_name": "LWENGO"
        },
        {
            "id": 275,
            "name": "BUKUYA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA"
        },
        {
            "id": 208,
            "name": "BULAMBULI County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI"
        },
        {
            "id": 156,
            "name": "BULAMOGI County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO"
        },
        {
            "id": 157,
            "name": "BULAMOGI NORTH WEST County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO"
        },
        {
            "id": 176,
            "name": "BULIISA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 73,
            "district_name": "BULIISA"
        },
        {
            "id": 57,
            "name": "BUNGOKHO CENTRAL County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE"
        },
        {
            "id": 58,
            "name": "BUNGOKHO County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE"
        },
        {
            "id": 120,
            "name": "BUNYA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE"
        },
        {
            "id": 265,
            "name": "BUNYANGABU County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU"
        },
        {
            "id": 255,
            "name": "BUNYARUGURU County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 112,
            "district_name": "RUBIRIZI"
        },
        {
            "id": 145,
            "name": "BUNYOLE EAST County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA"
        },
        {
            "id": 146,
            "name": "BUNYOLE WEST County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA"
        },
        {
            "id": 19,
            "name": "BURAHYA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE"
        },
        {
            "id": 55,
            "name": "BURULI County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI"
        },
        {
            "id": 7,
            "name": "BUSHENYI-ISHAKA Municipality",
            "tyep": "Municipality",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI"
        },
        {
            "id": 185,
            "name": "BUSHIGAI County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA"
        },
        {
            "id": 102,
            "name": "BUSIA Municipality",
            "tyep": "Municipality",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA"
        },
        {
            "id": 180,
            "name": "BUSIKI County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA"
        },
        {
            "id": 181,
            "name": "BUSIKI NORTH County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA"
        },
        {
            "id": 124,
            "name": "BUSIRO County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO"
        },
        {
            "id": 33,
            "name": "BUSONGORA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE"
        },
        {
            "id": 165,
            "name": "BUSUJJU County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA"
        },
        {
            "id": 229,
            "name": "BUTAMBALA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 99,
            "district_name": "BUTAMBALA"
        },
        {
            "id": 266,
            "name": "BUTEBO County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO"
        },
        {
            "id": 216,
            "name": "BUTEMBA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI"
        },
        {
            "id": 14,
            "name": "BUTEMBE County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 8,
            "district_name": "JINJA"
        },
        {
            "id": 164,
            "name": "BUTIRU County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA"
        },
        {
            "id": 211,
            "name": "BUVUMA ISLANDS County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 90,
            "district_name": "BUVUMA"
        },
        {
            "id": 66,
            "name": "BUWEKULA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE"
        },
        {
            "id": 67,
            "name": "BUWEKULA SOUTH County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE"
        },
        {
            "id": 257,
            "name": "BUYAGA EAST County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI"
        },
        {
            "id": 258,
            "name": "BUYAGA WEST County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI"
        },
        {
            "id": 84,
            "name": "BUYAMBA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI"
        },
        {
            "id": 35,
            "name": "BUYANJA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE"
        },
        {
            "id": 36,
            "name": "BUYANJA EAST County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE"
        },
        {
            "id": 28,
            "name": "BUZAAYA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI"
        },
        {
            "id": 6,
            "name": "BWAMBA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO"
        },
        {
            "id": 137,
            "name": "CHEKWII County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 56,
            "district_name": "NAKAPIRIPIRIT"
        },
        {
            "id": 138,
            "name": "CHEKWII EAST County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 56,
            "district_name": "NAKAPIRIPIRIT"
        },
        {
            "id": 42,
            "name": "CHUA EAST County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM"
        },
        {
            "id": 43,
            "name": "CHUA WEST County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM"
        },
        {
            "id": 89,
            "name": "DAKABELA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI"
        },
        {
            "id": 153,
            "name": "DODOTH EAST County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG"
        },
        {
            "id": 154,
            "name": "DODOTH NORTH County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG"
        },
        {
            "id": 283,
            "name": "DODOTH WEST County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 130,
            "district_name": "KARENGA"
        },
        {
            "id": 177,
            "name": "DOKOLO NORTH County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO"
        },
        {
            "id": 178,
            "name": "DOKOLO SOUTH County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO"
        },
        {
            "id": 209,
            "name": "ELGON County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI"
        },
        {
            "id": 210,
            "name": "ELGON NORTH County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI"
        },
        {
            "id": 126,
            "name": "ENTEBBE Municipality",
            "tyep": "Municipality",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO"
        },
        {
            "id": 50,
            "name": "ERUTE County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 22,
            "district_name": "LIRA"
        },
        {
            "id": 298,
            "name": "FORT PORTAL CENTRAL DIVISION",
            "tyep": "City-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 139,
            "district_name": "FORT PORTAL CITY"
        },
        {
            "id": 299,
            "name": "FORT PORTAL NORTH DIVISION",
            "tyep": "City-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 139,
            "district_name": "FORT PORTAL CITY"
        },
        {
            "id": 81,
            "name": "GOGONYO County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA"
        },
        {
            "id": 212,
            "name": "GOMBA EAST County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 91,
            "district_name": "GOMBA"
        },
        {
            "id": 213,
            "name": "GOMBA WEST County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 91,
            "district_name": "GOMBA"
        },
        {
            "id": 90,
            "name": "GWERI County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI"
        },
        {
            "id": 310,
            "name": "HOIMA EAST DIVISION",
            "tyep": "City-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 145,
            "district_name": "HOIMA CITY"
        },
        {
            "id": 311,
            "name": "HOIMA WEST DIVISION",
            "tyep": "City-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 145,
            "district_name": "HOIMA CITY"
        },
        {
            "id": 147,
            "name": "IBANDA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA"
        },
        {
            "id": 148,
            "name": "IBANDA Municipality",
            "tyep": "Municipality",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA"
        },
        {
            "id": 12,
            "name": "IGANGA Municipality",
            "tyep": "Municipality",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 7,
            "district_name": "IGANGA"
        },
        {
            "id": 8,
            "name": "IGARA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI"
        },
        {
            "id": 155,
            "name": "IK County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG"
        },
        {
            "id": 175,
            "name": "IKI-IKI County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA"
        },
        {
            "id": 304,
            "name": "INDUSTRIAL DIVISION",
            "tyep": "City-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 142,
            "district_name": "MBALE CITY"
        },
        {
            "id": 151,
            "name": "ISINGIRO County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO"
        },
        {
            "id": 152,
            "name": "ISINGIRO WEST County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO"
        },
        {
            "id": 45,
            "name": "JIE County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO"
        },
        {
            "id": 296,
            "name": "JINJA NORTH DIVISION",
            "tyep": "City-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 138,
            "district_name": "JINJA CITY"
        },
        {
            "id": 297,
            "name": "JINJA SOUTH DIVISION",
            "tyep": "City-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 138,
            "district_name": "JINJA CITY"
        },
        {
            "id": 271,
            "name": "JONAM County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 121,
            "district_name": "PAKWACH"
        },
        {
            "id": 17,
            "name": "KABALE Municipality",
            "tyep": "Municipality",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE"
        },
        {
            "id": 134,
            "name": "KABERAMAIDO County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 54,
            "district_name": "KABERAMAIDO"
        },
        {
            "id": 190,
            "name": "KABULA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 80,
            "district_name": "LYANTONDE"
        },
        {
            "id": 234,
            "name": "KABWERI County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU"
        },
        {
            "id": 189,
            "name": "KACHUMBALA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA"
        },
        {
            "id": 15,
            "name": "KAGOMA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 8,
            "district_name": "JINJA"
        },
        {
            "id": 16,
            "name": "KAGOMA NORTH County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 8,
            "district_name": "JINJA"
        },
        {
            "id": 76,
            "name": "KAJARA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO"
        },
        {
            "id": 267,
            "name": "KAKUUTO County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA"
        },
        {
            "id": 282,
            "name": "KALAKI County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 129,
            "district_name": "KALAKI"
        },
        {
            "id": 230,
            "name": "KALUNGU EAST County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 100,
            "district_name": "KALUNGU"
        },
        {
            "id": 231,
            "name": "KALUNGU WEST County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 100,
            "district_name": "KALUNGU"
        },
        {
            "id": 22,
            "name": "KAMPALA CENTRAL DIVISION",
            "tyep": "City-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 12,
            "district_name": "KAMPALA"
        },
        {
            "id": 29,
            "name": "KAMULI Municipality",
            "tyep": "Municipality",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI"
        },
        {
            "id": 47,
            "name": "KANYUM County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI"
        },
        {
            "id": 30,
            "name": "KAPCHORWA Municipality",
            "tyep": "Municipality",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA"
        },
        {
            "id": 274,
            "name": "KAPELEBYONG County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 124,
            "district_name": "KAPELEBYONG"
        },
        {
            "id": 246,
            "name": "KAPIR County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 108,
            "district_name": "NGORA"
        },
        {
            "id": 68,
            "name": "KASAMBYA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE"
        },
        {
            "id": 34,
            "name": "KASESE Municipality",
            "tyep": "Municipality",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE"
        },
        {
            "id": 59,
            "name": "KASHARI NORTH County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 27,
            "district_name": "MBARARA"
        },
        {
            "id": 60,
            "name": "KASHARI SOUTH County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 27,
            "district_name": "MBARARA"
        },
        {
            "id": 158,
            "name": "KASHONGI County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA"
        },
        {
            "id": 224,
            "name": "KASILO County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE"
        },
        {
            "id": 276,
            "name": "KASSANDA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA"
        },
        {
            "id": 256,
            "name": "KATERERA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 112,
            "district_name": "RUBIRIZI"
        },
        {
            "id": 52,
            "name": "KATIKAMU County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO"
        },
        {
            "id": 23,
            "name": "KAWEMPE DIVISION",
            "tyep": "City-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 12,
            "district_name": "KAMPALA"
        },
        {
            "id": 285,
            "name": "KAZO County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 131,
            "district_name": "KAZO"
        },
        {
            "id": 114,
            "name": "KIBALE County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE"
        },
        {
            "id": 82,
            "name": "KIBALE County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA"
        },
        {
            "id": 115,
            "name": "KIBALE EAST County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE"
        },
        {
            "id": 214,
            "name": "KIBANDA NORTH County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO"
        },
        {
            "id": 215,
            "name": "KIBANDA SOUTH County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO"
        },
        {
            "id": 37,
            "name": "KIBOGA EAST County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA"
        },
        {
            "id": 38,
            "name": "KIBOGA WEST County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA"
        },
        {
            "id": 235,
            "name": "KIBUKU County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU"
        },
        {
            "id": 11,
            "name": "KIGOROBYA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA"
        },
        {
            "id": 13,
            "name": "KIGULU County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 7,
            "district_name": "IGANGA"
        },
        {
            "id": 172,
            "name": "KILAK NORTH County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU"
        },
        {
            "id": 173,
            "name": "KILAK SOUTH County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU"
        },
        {
            "id": 302,
            "name": "KIMAANYA-KABONERA DIVISION",
            "tyep": "City-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 141,
            "district_name": "MASAKA CITY"
        },
        {
            "id": 136,
            "name": "KINKIZI County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU"
        },
        {
            "id": 139,
            "name": "KIOGA County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR"
        },
        {
            "id": 140,
            "name": "KIOGA NORTH County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR"
        },
        {
            "id": 127,
            "name": "KIRA Municipality",
            "tyep": "Municipality",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO"
        },
        {
            "id": 41,
            "name": "KISORO Municipality",
            "tyep": "Municipality",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO"
        },
        {
            "id": 286,
            "name": "KITAGWENDA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 132,
            "district_name": "KITAGWENDA"
        },
        {
            "id": 44,
            "name": "KITGUM Municipality",
            "tyep": "Municipality",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM"
        },
        {
            "id": 160,
            "name": "KOBOKO County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 66,
            "district_name": "KOBOKO"
        },
        {
            "id": 162,
            "name": "KOBOKO Municipality",
            "tyep": "Municipality",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 66,
            "district_name": "KOBOKO"
        },
        {
            "id": 161,
            "name": "KOBOKO NORTH County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 66,
            "district_name": "KOBOKO"
        },
        {
            "id": 236,
            "name": "KOLE NORTH County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 103,
            "district_name": "KOLE"
        },
        {
            "id": 237,
            "name": "KOLE SOUTH County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 103,
            "district_name": "KOLE"
        },
        {
            "id": 143,
            "name": "KONGASIS County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO"
        },
        {
            "id": 85,
            "name": "KOOKI County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI"
        },
        {
            "id": 46,
            "name": "KOTIDO Municipality",
            "tyep": "Municipality",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO"
        },
        {
            "id": 48,
            "name": "KUMI County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI"
        },
        {
            "id": 49,
            "name": "KUMI Municipality",
            "tyep": "Municipality",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI"
        },
        {
            "id": 279,
            "name": "KWANIA County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 127,
            "district_name": "KWANIA"
        },
        {
            "id": 280,
            "name": "KWANIA NORTH County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 127,
            "district_name": "KWANIA"
        },
        {
            "id": 238,
            "name": "KWEEN County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN"
        },
        {
            "id": 125,
            "name": "KYADONDO County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO"
        },
        {
            "id": 197,
            "name": "KYAKA CENTRAL County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA"
        },
        {
            "id": 198,
            "name": "KYAKA NORTH County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA"
        },
        {
            "id": 199,
            "name": "KYAKA SOUTH County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA"
        },
        {
            "id": 21,
            "name": "KYAMUSWA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 11,
            "district_name": "KALANGALA"
        },
        {
            "id": 268,
            "name": "KYOTERA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA"
        },
        {
            "id": 171,
            "name": "LABWOR County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM"
        },
        {
            "id": 200,
            "name": "LAMWO County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO"
        },
        {
            "id": 295,
            "name": "LAROO-PECE DIVISION",
            "tyep": "City-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 137,
            "district_name": "GULU CITY"
        },
        {
            "id": 308,
            "name": "LIRA EAST DIVISION",
            "tyep": "City-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 144,
            "district_name": "LIRA CITY"
        },
        {
            "id": 309,
            "name": "LIRA WEST DIVISION",
            "tyep": "City-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 144,
            "district_name": "LIRA CITY"
        },
        {
            "id": 287,
            "name": "LOWER MADI County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 133,
            "district_name": "MADI-OKOLLO"
        },
        {
            "id": 193,
            "name": "LUGAZI Municipality",
            "tyep": "Municipality",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE"
        },
        {
            "id": 186,
            "name": "LUTSESHE County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA"
        },
        {
            "id": 218,
            "name": "LUUKA NORTH County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 94,
            "district_name": "LUUKA"
        },
        {
            "id": 219,
            "name": "LUUKA SOUTH County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 94,
            "district_name": "LUUKA"
        },
        {
            "id": 110,
            "name": "LWEMIYAGA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE"
        },
        {
            "id": 24,
            "name": "MAKINDYE DIVISION",
            "tyep": "City-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 12,
            "district_name": "KAMPALA"
        },
        {
            "id": 128,
            "name": "MAKINDYE-SSABAGABO Municipality",
            "tyep": "Municipality",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO"
        },
        {
            "id": 187,
            "name": "MANJIYA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA"
        },
        {
            "id": 183,
            "name": "MARACHA County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA"
        },
        {
            "id": 184,
            "name": "MARACHA EAST County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA"
        },
        {
            "id": 2,
            "name": "MARUZI County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC"
        },
        {
            "id": 3,
            "name": "MARUZI NORTH County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC"
        },
        {
            "id": 56,
            "name": "MASINDI Municipality",
            "tyep": "Municipality",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI"
        },
        {
            "id": 61,
            "name": "MATHENIKO County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 28,
            "district_name": "MOROTO"
        },
        {
            "id": 111,
            "name": "MAWOGOLA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE"
        },
        {
            "id": 112,
            "name": "MAWOGOLA NORTH County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE"
        },
        {
            "id": 113,
            "name": "MAWOGOLA WEST County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE"
        },
        {
            "id": 65,
            "name": "MAWOKOTA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 30,
            "district_name": "MPIGI"
        },
        {
            "id": 300,
            "name": "MBARARA NORTH DIVISION",
            "tyep": "City-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 140,
            "district_name": "MBARARA CITY"
        },
        {
            "id": 301,
            "name": "MBARARA SOUTH DIVISION",
            "tyep": "City-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 140,
            "district_name": "MBARARA CITY"
        },
        {
            "id": 166,
            "name": "MITYANA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA"
        },
        {
            "id": 167,
            "name": "MITYANA Municipality",
            "tyep": "Municipality",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA"
        },
        {
            "id": 207,
            "name": "MOROTO County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG"
        },
        {
            "id": 63,
            "name": "MOROTO Municipality",
            "tyep": "Municipality",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 28,
            "district_name": "MOROTO"
        },
        {
            "id": 69,
            "name": "MUBENDE Municipality",
            "tyep": "Municipality",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE"
        },
        {
            "id": 70,
            "name": "MUKONO County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO"
        },
        {
            "id": 72,
            "name": "MUKONO Municipality",
            "tyep": "Municipality",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO"
        },
        {
            "id": 118,
            "name": "MWENGE CENTRAL County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO"
        },
        {
            "id": 119,
            "name": "MWENGE County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO"
        },
        {
            "id": 168,
            "name": "NAKASEKE CENTRAL County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE"
        },
        {
            "id": 169,
            "name": "NAKASEKE NORTH County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE"
        },
        {
            "id": 170,
            "name": "NAKASEKE SOUTH County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE"
        },
        {
            "id": 109,
            "name": "NAKASONGOLA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA"
        },
        {
            "id": 25,
            "name": "NAKAWA DIVISION",
            "tyep": "City-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 12,
            "district_name": "KAMPALA"
        },
        {
            "id": 71,
            "name": "NAKIFUMA County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO"
        },
        {
            "id": 222,
            "name": "NAMAYINGO SOUTH County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 95,
            "district_name": "NAMAYINGO"
        },
        {
            "id": 270,
            "name": "NAMISINDWA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA"
        },
        {
            "id": 129,
            "name": "NANSANA Municipality",
            "tyep": "Municipality",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO"
        },
        {
            "id": 284,
            "name": "NAPORE WEST County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 130,
            "district_name": "KARENGA"
        },
        {
            "id": 18,
            "name": "NDORWA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE"
        },
        {
            "id": 73,
            "name": "NEBBI Municipality",
            "tyep": "Municipality",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI"
        },
        {
            "id": 105,
            "name": "NGARIAM County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI"
        },
        {
            "id": 247,
            "name": "NGORA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 108,
            "district_name": "NGORA"
        },
        {
            "id": 194,
            "name": "NJERU Municipality",
            "tyep": "Municipality",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE"
        },
        {
            "id": 305,
            "name": "NORTHERN DIVISION",
            "tyep": "City-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 142,
            "district_name": "MBALE CITY"
        },
        {
            "id": 117,
            "name": "NTENJERU County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA"
        },
        {
            "id": 223,
            "name": "NTOROKO County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 96,
            "district_name": "NTOROKO"
        },
        {
            "id": 75,
            "name": "NTUNGAMO Municipality",
            "tyep": "Municipality",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO"
        },
        {
            "id": 217,
            "name": "NTWETWE County",
            "tyep": "County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI"
        },
        {
            "id": 250,
            "name": "NWOYA County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 110,
            "district_name": "NWOYA"
        },
        {
            "id": 251,
            "name": "NWOYA EAST County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 110,
            "district_name": "NWOYA"
        },
        {
            "id": 159,
            "name": "NYABUSHOZI County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA"
        },
        {
            "id": 303,
            "name": "NYENDO-MUKUNGWE DIVISION",
            "tyep": "City-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 141,
            "district_name": "MASAKA CITY"
        },
        {
            "id": 289,
            "name": "OBONGI County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 134,
            "district_name": "OBONGI"
        },
        {
            "id": 135,
            "name": "OCHERO County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 54,
            "district_name": "KABERAMAIDO"
        },
        {
            "id": 204,
            "name": "OKORO County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO"
        },
        {
            "id": 262,
            "name": "OMORO County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO"
        },
        {
            "id": 205,
            "name": "ORA County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO"
        },
        {
            "id": 142,
            "name": "ORUNGO County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA"
        },
        {
            "id": 202,
            "name": "OTUKE County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE"
        },
        {
            "id": 203,
            "name": "OTUKE EAST County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE"
        },
        {
            "id": 182,
            "name": "OYAM County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM"
        },
        {
            "id": 74,
            "name": "PADYERE County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI"
        },
        {
            "id": 201,
            "name": "PALABEK County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO"
        },
        {
            "id": 83,
            "name": "PALLISA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA"
        },
        {
            "id": 281,
            "name": "PIAN County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 128,
            "district_name": "NABILATUK"
        },
        {
            "id": 225,
            "name": "PINGIRE County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE"
        },
        {
            "id": 86,
            "name": "RUBABO County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI"
        },
        {
            "id": 26,
            "name": "RUBAGA DIVISION",
            "tyep": "City-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 12,
            "district_name": "KAMPALA"
        },
        {
            "id": 264,
            "name": "RUBANDA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA"
        },
        {
            "id": 77,
            "name": "RUHAAMA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO"
        },
        {
            "id": 78,
            "name": "RUHAAMA EAST County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO"
        },
        {
            "id": 241,
            "name": "RUHINDA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA"
        },
        {
            "id": 242,
            "name": "RUHINDA NORTH County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA"
        },
        {
            "id": 243,
            "name": "RUHINDA SOUTH County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA"
        },
        {
            "id": 87,
            "name": "RUJUMBURA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI"
        },
        {
            "id": 272,
            "name": "RUKIGA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 122,
            "district_name": "RUKIGA"
        },
        {
            "id": 88,
            "name": "RUKUNGIRI Municipality",
            "tyep": "Municipality",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI"
        },
        {
            "id": 79,
            "name": "RUSHENYI County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO"
        },
        {
            "id": 290,
            "name": "RWAMPARA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 135,
            "district_name": "RWAMPARA"
        },
        {
            "id": 291,
            "name": "RWAMPARA EAST County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 135,
            "district_name": "RWAMPARA"
        },
        {
            "id": 103,
            "name": "SAMIA BUGWE CENTRAL County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA"
        },
        {
            "id": 104,
            "name": "SAMIA BUGWE County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA"
        },
        {
            "id": 226,
            "name": "SERERE County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE"
        },
        {
            "id": 232,
            "name": "SHEEMA County",
            "tyep": "County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA"
        },
        {
            "id": 233,
            "name": "SHEEMA Municipality",
            "tyep": "Municipality",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA"
        },
        {
            "id": 239,
            "name": "SOI County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN"
        },
        {
            "id": 91,
            "name": "SOROTI County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI"
        },
        {
            "id": 312,
            "name": "SOROTI EAST DIVISION",
            "tyep": "City-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 146,
            "district_name": "SOROTI CITY"
        },
        {
            "id": 313,
            "name": "SOROTI WEST DIVISION",
            "tyep": "City-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 146,
            "district_name": "SOROTI CITY"
        },
        {
            "id": 62,
            "name": "TEPETH County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 28,
            "district_name": "MOROTO"
        },
        {
            "id": 306,
            "name": "TEREGO EAST County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 143,
            "district_name": "TEREGO"
        },
        {
            "id": 307,
            "name": "TEREGO WEST County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 143,
            "district_name": "TEREGO"
        },
        {
            "id": 31,
            "name": "TINGEY County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA"
        },
        {
            "id": 263,
            "name": "TOCHI County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO"
        },
        {
            "id": 144,
            "name": "TOO County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO"
        },
        {
            "id": 106,
            "name": "TOROMA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI"
        },
        {
            "id": 92,
            "name": "TORORO Municipality",
            "tyep": "Municipality",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO"
        },
        {
            "id": 93,
            "name": "TORORO NORTH County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO"
        },
        {
            "id": 94,
            "name": "TORORO SOUTH County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO"
        },
        {
            "id": 191,
            "name": "UPE County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 81,
            "district_name": "AMUDAT"
        },
        {
            "id": 288,
            "name": "UPPER MADI County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 133,
            "district_name": "MADI-OKOLLO"
        },
        {
            "id": 107,
            "name": "USUK County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI"
        },
        {
            "id": 4,
            "name": "VURRA County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 2,
            "district_name": "ARUA"
        },
        {
            "id": 95,
            "name": "WEST BUDAMA CENTRAL County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO"
        },
        {
            "id": 96,
            "name": "WEST BUDAMA County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO"
        },
        {
            "id": 97,
            "name": "WEST BUDAMA NORTH EAST County",
            "tyep": "County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO"
        },
        {
            "id": 64,
            "name": "WEST MOYO County",
            "tyep": "County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 29,
            "district_name": "MOYO"
        }
    ],
    subcounties: [
        {
            "id": 1515,
            "name": "ABAKO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG",
            "county_id": 206,
            "county_name": "AJURI County",
            "constituency_id": 242,
            "constituency_name": "AJURI COUNTY"
        },
        {
            "id": 1500,
            "name": "ABANGA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 204,
            "county_name": "OKORO County",
            "constituency_id": 240,
            "constituency_name": "OKORO COUNTY"
        },
        {
            "id": 1007,
            "name": "ABARILELA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 141,
            "county_name": "AMURIA County",
            "constituency_id": 173,
            "constituency_name": "AMURIA COUNTY"
        },
        {
            "id": 998,
            "name": "ABEJA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 140,
            "county_name": "KIOGA NORTH County",
            "constituency_id": 172,
            "constituency_name": "KIOGA NORTH COUNTY"
        },
        {
            "id": 1331,
            "name": "ABER",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 218,
            "constituency_name": "OYAM COUNTY SOUTH"
        },
        {
            "id": 1008,
            "name": "ABIA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 141,
            "county_name": "AMURIA County",
            "constituency_id": 173,
            "constituency_name": "AMURIA COUNTY"
        },
        {
            "id": 1522,
            "name": "ABIA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG",
            "county_id": 207,
            "county_name": "MOROTO County",
            "constituency_id": 243,
            "constituency_name": "MOROTO COUNTY"
        },
        {
            "id": 1410,
            "name": "ABILIYEP",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 81,
            "district_name": "AMUDAT",
            "county_id": 191,
            "county_name": "UPE County",
            "constituency_id": 227,
            "constituency_name": "UPE COUNTY"
        },
        {
            "id": 1230,
            "name": "ABIM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 1231,
            "name": "ABIM TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 487,
            "name": "ABINDU DIVISION",
            "type": "Municipal-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 73,
            "county_name": "NEBBI Municipality",
            "constituency_id": 94,
            "constituency_name": "NEBBI MUNICIPALITY"
        },
        {
            "id": 1323,
            "name": "ABOK",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 217,
            "constituency_name": "OYAM COUNTY NORTH"
        },
        {
            "id": 1715,
            "name": "ABOKE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 103,
            "district_name": "KOLE",
            "county_id": 236,
            "county_name": "KOLE NORTH County",
            "constituency_id": 273,
            "constituency_name": "KOLE NORTH COUNTY"
        },
        {
            "id": 1716,
            "name": "ABOKE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 103,
            "district_name": "KOLE",
            "county_id": 236,
            "county_name": "KOLE NORTH County",
            "constituency_id": 273,
            "constituency_name": "KOLE NORTH COUNTY"
        },
        {
            "id": 2078,
            "name": "ABONGOMOLA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 127,
            "district_name": "KWANIA",
            "county_id": 280,
            "county_name": "KWANIA NORTH County",
            "constituency_id": 318,
            "constituency_name": "KWANIA NORTH COUNTY"
        },
        {
            "id": 1917,
            "name": "ABUGA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 263,
            "county_name": "TOCHI County",
            "constituency_id": 299,
            "constituency_name": "TOCHI COUNTY"
        },
        {
            "id": 1232,
            "name": "ABUK TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 1161,
            "name": "ABUKU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 66,
            "district_name": "KOBOKO",
            "county_id": 161,
            "county_name": "KOBOKO NORTH County",
            "constituency_id": 195,
            "constituency_name": "KOBOKO NORTH COUNTY"
        },
        {
            "id": 490,
            "name": "ACANA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 74,
            "county_name": "PADYERE County",
            "constituency_id": 95,
            "constituency_name": "PADYERE COUNTY"
        },
        {
            "id": 1467,
            "name": "ACEBA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 200,
            "county_name": "LAMWO County",
            "constituency_id": 236,
            "constituency_name": "LAMWO COUNTY"
        },
        {
            "id": 1909,
            "name": "ACET TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 262,
            "county_name": "OMORO County",
            "constituency_id": 298,
            "constituency_name": "OMORO COUNTY"
        },
        {
            "id": 1324,
            "name": "ACHABA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 217,
            "constituency_name": "OYAM COUNTY NORTH"
        },
        {
            "id": 837,
            "name": "ACHOLI - BUR TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 122,
            "county_name": "ARUU NORTH County",
            "constituency_id": 150,
            "constituency_name": "ARUU NORTH COUNTY"
        },
        {
            "id": 838,
            "name": "ACHOLI-BUR",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 122,
            "county_name": "ARUU NORTH County",
            "constituency_id": 150,
            "constituency_name": "ARUU NORTH COUNTY"
        },
        {
            "id": 1411,
            "name": "ACHORICHOR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 81,
            "district_name": "AMUDAT",
            "county_id": 191,
            "county_name": "UPE County",
            "constituency_id": 227,
            "constituency_name": "UPE COUNTY"
        },
        {
            "id": 991,
            "name": "ACII",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 139,
            "county_name": "KIOGA County",
            "constituency_id": 171,
            "constituency_name": "KIOGA COUNTY"
        },
        {
            "id": 2040,
            "name": "ACINGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 124,
            "district_name": "KAPELEBYONG",
            "county_id": 274,
            "county_name": "KAPELEBYONG County",
            "constituency_id": 311,
            "constituency_name": "KAPELEBYONG COUNTY"
        },
        {
            "id": 2041,
            "name": "ACOWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 124,
            "district_name": "KAPELEBYONG",
            "county_id": 274,
            "county_name": "KAPELEBYONG County",
            "constituency_id": 311,
            "constituency_name": "KAPELEBYONG COUNTY"
        },
        {
            "id": 2042,
            "name": "ACOWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 124,
            "district_name": "KAPELEBYONG",
            "county_id": 274,
            "county_name": "KAPELEBYONG County",
            "constituency_id": 311,
            "constituency_name": "KAPELEBYONG COUNTY"
        },
        {
            "id": 1297,
            "name": "ADEKNINO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO",
            "county_id": 178,
            "county_name": "DOKOLO SOUTH County",
            "constituency_id": 213,
            "constituency_name": "DOKOLO SOUTH COUNTY"
        },
        {
            "id": 1820,
            "name": "ADILANG",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 253,
            "county_name": "AGAGO County",
            "constituency_id": 289,
            "constituency_name": "AGAGO COUNTY"
        },
        {
            "id": 1821,
            "name": "ADILANG TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 253,
            "county_name": "AGAGO County",
            "constituency_id": 289,
            "constituency_name": "AGAGO COUNTY"
        },
        {
            "id": 662,
            "name": "ADJUMANI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 40,
            "district_name": "ADJUMANI",
            "county_id": 99,
            "county_name": "ADJUMANI WEST County",
            "constituency_id": 122,
            "constituency_name": "ADJUMANI WEST COUNTY"
        },
        {
            "id": 1289,
            "name": "ADOK",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO",
            "county_id": 177,
            "county_name": "DOKOLO NORTH County",
            "constituency_id": 212,
            "constituency_name": "DOKOLO NORTH COUNTY"
        },
        {
            "id": 663,
            "name": "ADROPI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 40,
            "district_name": "ADJUMANI",
            "county_id": 99,
            "county_name": "ADJUMANI WEST County",
            "constituency_id": 122,
            "constituency_name": "ADJUMANI WEST COUNTY"
        },
        {
            "id": 2079,
            "name": "ADUKU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 127,
            "district_name": "KWANIA",
            "county_id": 280,
            "county_name": "KWANIA NORTH County",
            "constituency_id": 318,
            "constituency_name": "KWANIA NORTH COUNTY"
        },
        {
            "id": 2080,
            "name": "ADUKU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 127,
            "district_name": "KWANIA",
            "county_id": 280,
            "county_name": "KWANIA NORTH County",
            "constituency_id": 318,
            "constituency_name": "KWANIA NORTH COUNTY"
        },
        {
            "id": 1486,
            "name": "ADWARI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE",
            "county_id": 202,
            "county_name": "OTUKE County",
            "constituency_id": 238,
            "constituency_name": "OTUKE COUNTY"
        },
        {
            "id": 1487,
            "name": "ADWARI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE",
            "county_id": 202,
            "county_name": "OTUKE County",
            "constituency_id": 238,
            "constituency_name": "OTUKE COUNTY"
        },
        {
            "id": 1516,
            "name": "ADWIR",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG",
            "county_id": 206,
            "county_name": "AJURI County",
            "constituency_id": 242,
            "constituency_name": "AJURI COUNTY"
        },
        {
            "id": 1822,
            "name": "AGAGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 253,
            "county_name": "AGAGO County",
            "constituency_id": 289,
            "constituency_name": "AGAGO COUNTY"
        },
        {
            "id": 1339,
            "name": "AGAII TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 183,
            "county_name": "MARACHA County",
            "constituency_id": 219,
            "constituency_name": "MARACHA COUNTY"
        },
        {
            "id": 338,
            "name": "AGALI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 22,
            "district_name": "LIRA",
            "county_id": 50,
            "county_name": "ERUTE County",
            "constituency_id": 63,
            "constituency_name": "ERUTE COUNTY SOUTH"
        },
        {
            "id": 1813,
            "name": "AGENGO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 252,
            "county_name": "AGAGO WEST County",
            "constituency_id": 288,
            "constituency_name": "AGAGO WEST COUNTY"
        },
        {
            "id": 999,
            "name": "AGIKDAK",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 140,
            "county_name": "KIOGA NORTH County",
            "constituency_id": 172,
            "constituency_name": "KIOGA NORTH COUNTY"
        },
        {
            "id": 1777,
            "name": "AGIRIGIROI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 108,
            "district_name": "NGORA",
            "county_id": 246,
            "county_name": "KAPIR County",
            "constituency_id": 282,
            "constituency_name": "KAPIR COUNTY"
        },
        {
            "id": 1468,
            "name": "AGORO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 200,
            "county_name": "LAMWO County",
            "constituency_id": 236,
            "constituency_name": "LAMWO COUNTY"
        },
        {
            "id": 537,
            "name": "AGULE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 80,
            "county_name": "AGULE County",
            "constituency_id": 101,
            "constituency_name": "AGULE COUNTY"
        },
        {
            "id": 538,
            "name": "AGULE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 80,
            "county_name": "AGULE County",
            "constituency_id": 101,
            "constituency_name": "AGULE COUNTY"
        },
        {
            "id": 1,
            "name": "AGULLU DIVISION",
            "type": "Municipal-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC",
            "county_id": 1,
            "county_name": "APAC Municipality",
            "constituency_id": 1,
            "constituency_name": "APAC MUNICIPALITY"
        },
        {
            "id": 1290,
            "name": "AGWATA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO",
            "county_id": 177,
            "county_name": "DOKOLO NORTH County",
            "constituency_id": 212,
            "constituency_name": "DOKOLO NORTH COUNTY"
        },
        {
            "id": 1291,
            "name": "AGWATTA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO",
            "county_id": 177,
            "county_name": "DOKOLO NORTH County",
            "constituency_id": 212,
            "constituency_name": "DOKOLO NORTH COUNTY"
        },
        {
            "id": 333,
            "name": "AGWENG",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 22,
            "district_name": "LIRA",
            "county_id": 50,
            "county_name": "ERUTE County",
            "constituency_id": 62,
            "constituency_name": "ERUTE COUNTY NORTH"
        },
        {
            "id": 334,
            "name": "AGWENG TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 22,
            "district_name": "LIRA",
            "county_id": 50,
            "county_name": "ERUTE County",
            "constituency_id": 62,
            "constituency_name": "ERUTE COUNTY NORTH"
        },
        {
            "id": 992,
            "name": "AGWINGIRI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 139,
            "county_name": "KIOGA County",
            "constituency_id": 171,
            "constituency_name": "KIOGA COUNTY"
        },
        {
            "id": 2162,
            "name": "AII-VU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 143,
            "district_name": "TEREGO",
            "county_id": 307,
            "county_name": "TEREGO WEST County",
            "constituency_id": 347,
            "constituency_name": "TEREGO WEST COUNTY"
        },
        {
            "id": 1823,
            "name": "AJALI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 253,
            "county_name": "AGAGO County",
            "constituency_id": 289,
            "constituency_name": "AGAGO COUNTY"
        },
        {
            "id": 839,
            "name": "AJAN",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 122,
            "county_name": "ARUU NORTH County",
            "constituency_id": 150,
            "constituency_name": "ARUU NORTH COUNTY"
        },
        {
            "id": 13,
            "name": "AJIA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 2,
            "district_name": "ARUA",
            "county_id": 4,
            "county_name": "VURRA County",
            "constituency_id": 4,
            "constituency_name": "VURRA COUNTY"
        },
        {
            "id": 1347,
            "name": "AJIRA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 184,
            "county_name": "MARACHA EAST County",
            "constituency_id": 220,
            "constituency_name": "MARACHA EAST COUNTY"
        },
        {
            "id": 1508,
            "name": "AKAA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 205,
            "county_name": "ORA County",
            "constituency_id": 241,
            "constituency_name": "ORA COUNTY"
        },
        {
            "id": 616,
            "name": "AKADOT",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 93,
            "county_name": "TORORO NORTH County",
            "constituency_id": 115,
            "constituency_name": "TORORO NORTH COUNTY"
        },
        {
            "id": 2081,
            "name": "AKALI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 127,
            "district_name": "KWANIA",
            "county_id": 280,
            "county_name": "KWANIA NORTH County",
            "constituency_id": 318,
            "constituency_name": "KWANIA NORTH COUNTY"
        },
        {
            "id": 1720,
            "name": "AKALO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 103,
            "district_name": "KOLE",
            "county_id": 237,
            "county_name": "KOLE SOUTH County",
            "constituency_id": 274,
            "constituency_name": "KOLE SOUTH COUNTY"
        },
        {
            "id": 1721,
            "name": "AKALO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 103,
            "district_name": "KOLE",
            "county_id": 237,
            "county_name": "KOLE SOUTH County",
            "constituency_id": 274,
            "constituency_name": "KOLE SOUTH COUNTY"
        },
        {
            "id": 1143,
            "name": "AKAYANJA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA",
            "county_id": 159,
            "county_name": "NYABUSHOZI County",
            "constituency_id": 193,
            "constituency_name": "NYABUSHOZI COUNTY"
        },
        {
            "id": 2,
            "name": "AKERE DIVISION",
            "type": "Municipal-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC",
            "county_id": 1,
            "county_name": "APAC Municipality",
            "constituency_id": 1,
            "constituency_name": "APAC MUNICIPALITY"
        },
        {
            "id": 1018,
            "name": "AKERIAU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 142,
            "county_name": "ORUNGO County",
            "constituency_id": 174,
            "constituency_name": "ORUNGO COUNTY"
        },
        {
            "id": 1910,
            "name": "AKIDI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 262,
            "county_name": "OMORO County",
            "constituency_id": 298,
            "constituency_name": "OMORO COUNTY"
        },
        {
            "id": 539,
            "name": "AKISIM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 80,
            "county_name": "AGULE County",
            "constituency_id": 101,
            "constituency_name": "AGULE COUNTY"
        },
        {
            "id": 707,
            "name": "AKOBOI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 105,
            "county_name": "NGARIAM County",
            "constituency_id": 130,
            "constituency_name": "NGARIAM COUNTY"
        },
        {
            "id": 5,
            "name": "AKOKORO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC",
            "county_id": 2,
            "county_name": "MARUZI County",
            "constituency_id": 2,
            "constituency_name": "MARUZI COUNTY"
        },
        {
            "id": 6,
            "name": "AKOKORO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC",
            "county_id": 2,
            "county_name": "MARUZI County",
            "constituency_id": 2,
            "constituency_name": "MARUZI COUNTY"
        },
        {
            "id": 2043,
            "name": "AKORE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 124,
            "district_name": "KAPELEBYONG",
            "county_id": 274,
            "county_name": "KAPELEBYONG County",
            "constituency_id": 311,
            "constituency_name": "KAPELEBYONG COUNTY"
        },
        {
            "id": 2044,
            "name": "AKOROMIT",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 124,
            "district_name": "KAPELEBYONG",
            "county_id": 274,
            "county_name": "KAPELEBYONG County",
            "constituency_id": 311,
            "constituency_name": "KAPELEBYONG COUNTY"
        },
        {
            "id": 1523,
            "name": "AKURA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG",
            "county_id": 207,
            "county_name": "MOROTO County",
            "constituency_id": 243,
            "constituency_name": "MOROTO COUNTY"
        },
        {
            "id": 285,
            "name": "AKWANG",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 43,
            "county_name": "CHUA WEST County",
            "constituency_id": 55,
            "constituency_name": "CHUA WEST COUNTY"
        },
        {
            "id": 1000,
            "name": "AKWON",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 140,
            "county_name": "KIOGA NORTH County",
            "constituency_id": 172,
            "constituency_name": "KIOGA NORTH COUNTY"
        },
        {
            "id": 491,
            "name": "AKWORO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 74,
            "county_name": "PADYERE County",
            "constituency_id": 95,
            "constituency_name": "PADYERE COUNTY"
        },
        {
            "id": 492,
            "name": "ALALA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 74,
            "county_name": "PADYERE County",
            "constituency_id": 95,
            "constituency_name": "PADYERE COUNTY"
        },
        {
            "id": 1509,
            "name": "ALANGI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 205,
            "county_name": "ORA County",
            "constituency_id": 241,
            "constituency_name": "ORA COUNTY"
        },
        {
            "id": 1488,
            "name": "ALANGO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE",
            "county_id": 202,
            "county_name": "OTUKE County",
            "constituency_id": 238,
            "constituency_name": "OTUKE COUNTY"
        },
        {
            "id": 1524,
            "name": "ALEBTONG TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG",
            "county_id": 207,
            "county_name": "MOROTO County",
            "constituency_id": 243,
            "constituency_name": "MOROTO COUNTY"
        },
        {
            "id": 1325,
            "name": "ALEKA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 217,
            "constituency_name": "OYAM COUNTY NORTH"
        },
        {
            "id": 1233,
            "name": "ALEREK",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 1234,
            "name": "ALEREK TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 1808,
            "name": "ALERO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 110,
            "district_name": "NWOYA",
            "county_id": 251,
            "county_name": "NWOYA EAST County",
            "constituency_id": 287,
            "constituency_name": "NWOYA EAST COUNTY"
        },
        {
            "id": 2145,
            "name": "ALIBA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 134,
            "district_name": "OBONGI",
            "county_id": 289,
            "county_name": "OBONGI County",
            "constituency_id": 327,
            "constituency_name": "OBONGI COUNTY"
        },
        {
            "id": 1397,
            "name": "ALIGOI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 189,
            "county_name": "KACHUMBALA County",
            "constituency_id": 225,
            "constituency_name": "KACHUMBALA COUNTY"
        },
        {
            "id": 1348,
            "name": "ALIKUA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 184,
            "county_name": "MARACHA EAST County",
            "constituency_id": 220,
            "constituency_name": "MARACHA EAST COUNTY"
        },
        {
            "id": 1717,
            "name": "ALITO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 103,
            "district_name": "KOLE",
            "county_id": 236,
            "county_name": "KOLE NORTH County",
            "constituency_id": 273,
            "constituency_name": "KOLE NORTH COUNTY"
        },
        {
            "id": 2045,
            "name": "ALITO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 124,
            "district_name": "KAPELEBYONG",
            "county_id": 274,
            "county_name": "KAPELEBYONG County",
            "constituency_id": 311,
            "constituency_name": "KAPELEBYONG COUNTY"
        },
        {
            "id": 1718,
            "name": "ALITO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 103,
            "district_name": "KOLE",
            "county_id": 236,
            "county_name": "KOLE NORTH County",
            "constituency_id": 273,
            "constituency_name": "KOLE NORTH COUNTY"
        },
        {
            "id": 1525,
            "name": "ALOI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG",
            "county_id": 207,
            "county_name": "MOROTO County",
            "constituency_id": 243,
            "constituency_name": "MOROTO COUNTY"
        },
        {
            "id": 1526,
            "name": "ALOI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG",
            "county_id": 207,
            "county_name": "MOROTO County",
            "constituency_id": 243,
            "constituency_name": "MOROTO COUNTY"
        },
        {
            "id": 431,
            "name": "ALURU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 29,
            "district_name": "MOYO",
            "county_id": 64,
            "county_name": "WEST MOYO County",
            "constituency_id": 83,
            "constituency_name": "WEST MOYO COUNTY"
        },
        {
            "id": 946,
            "name": "ALWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 54,
            "district_name": "KABERAMAIDO",
            "county_id": 134,
            "county_name": "KABERAMAIDO County",
            "constituency_id": 165,
            "constituency_name": "KABERAMAIDO COUNTY"
        },
        {
            "id": 2016,
            "name": "ALWI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 121,
            "district_name": "PAKWACH",
            "county_id": 271,
            "county_name": "JONAM County",
            "constituency_id": 308,
            "constituency_name": "JONAM COUNTY"
        },
        {
            "id": 339,
            "name": "AMACH",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 22,
            "district_name": "LIRA",
            "county_id": 50,
            "county_name": "ERUTE County",
            "constituency_id": 63,
            "constituency_name": "ERUTE COUNTY SOUTH"
        },
        {
            "id": 340,
            "name": "AMACH TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 22,
            "district_name": "LIRA",
            "county_id": 50,
            "county_name": "ERUTE County",
            "constituency_id": 63,
            "constituency_name": "ERUTE COUNTY SOUTH"
        },
        {
            "id": 1025,
            "name": "AMANANG",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 143,
            "county_name": "KONGASIS County",
            "constituency_id": 175,
            "constituency_name": "KONGASIS COUNTY"
        },
        {
            "id": 1386,
            "name": "AMINIT",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 188,
            "county_name": "BUKEDEA County",
            "constituency_id": 224,
            "constituency_name": "BUKEDEA COUNTY"
        },
        {
            "id": 993,
            "name": "AMOLATAR TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 139,
            "county_name": "KIOGA County",
            "constituency_id": 171,
            "constituency_name": "KIOGA COUNTY"
        },
        {
            "id": 1009,
            "name": "AMOLO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 141,
            "county_name": "AMURIA County",
            "constituency_id": 173,
            "constituency_name": "AMURIA COUNTY"
        },
        {
            "id": 1412,
            "name": "AMUDAT",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 81,
            "district_name": "AMUDAT",
            "county_id": 191,
            "county_name": "UPE County",
            "constituency_id": 227,
            "constituency_name": "UPE COUNTY"
        },
        {
            "id": 1413,
            "name": "AMUDAT TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 81,
            "district_name": "AMUDAT",
            "county_id": 191,
            "county_name": "UPE County",
            "constituency_id": 227,
            "constituency_name": "UPE COUNTY"
        },
        {
            "id": 1517,
            "name": "AMUGU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG",
            "county_id": 206,
            "county_name": "AJURI County",
            "constituency_id": 242,
            "constituency_name": "AJURI COUNTY"
        },
        {
            "id": 1518,
            "name": "AMUGU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG",
            "county_id": 206,
            "county_name": "AJURI County",
            "constituency_id": 242,
            "constituency_name": "AJURI COUNTY"
        },
        {
            "id": 166,
            "name": "AMUKOL",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 31,
            "county_name": "TINGEY County",
            "constituency_id": 39,
            "constituency_name": "TINGEY COUNTY"
        },
        {
            "id": 1010,
            "name": "AMURIA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 141,
            "county_name": "AMURIA County",
            "constituency_id": 173,
            "constituency_name": "AMURIA COUNTY"
        },
        {
            "id": 1253,
            "name": "AMURU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU",
            "county_id": 173,
            "county_name": "KILAK SOUTH County",
            "constituency_id": 208,
            "constituency_name": "KILAK SOUTH COUNTY"
        },
        {
            "id": 1254,
            "name": "AMURU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU",
            "county_id": 173,
            "county_name": "KILAK SOUTH County",
            "constituency_id": 208,
            "constituency_name": "KILAK SOUTH COUNTY"
        },
        {
            "id": 714,
            "name": "AMUSIA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 106,
            "county_name": "TOROMA County",
            "constituency_id": 131,
            "constituency_name": "TOROMA COUNTY"
        },
        {
            "id": 1292,
            "name": "AMWOMA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO",
            "county_id": 177,
            "county_name": "DOKOLO NORTH County",
            "constituency_id": 212,
            "constituency_name": "DOKOLO NORTH COUNTY"
        },
        {
            "id": 1802,
            "name": "ANAKA (PAYIRA)",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 110,
            "district_name": "NWOYA",
            "county_id": 250,
            "county_name": "NWOYA County",
            "constituency_id": 286,
            "constituency_name": "NWOYA COUNTY"
        },
        {
            "id": 1805,
            "name": "ANAKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 110,
            "district_name": "NWOYA",
            "county_id": 250,
            "county_name": "NWOYA County",
            "constituency_id": 286,
            "constituency_name": "NWOYA COUNTY"
        },
        {
            "id": 840,
            "name": "ANGAGURA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 122,
            "county_name": "ARUU NORTH County",
            "constituency_id": 150,
            "constituency_name": "ARUU NORTH COUNTY"
        },
        {
            "id": 1519,
            "name": "ANGETTA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG",
            "county_id": 206,
            "county_name": "AJURI County",
            "constituency_id": 242,
            "constituency_name": "AJURI COUNTY"
        },
        {
            "id": 715,
            "name": "ANGODINGOD",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 106,
            "county_name": "TOROMA County",
            "constituency_id": 131,
            "constituency_name": "TOROMA COUNTY"
        },
        {
            "id": 2090,
            "name": "ANYARA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 129,
            "district_name": "KALAKI",
            "county_id": 282,
            "county_name": "KALAKI County",
            "constituency_id": 320,
            "constituency_name": "KALAKI COUNTY"
        },
        {
            "id": 2140,
            "name": "ANYIRIBU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 133,
            "district_name": "MADI-OKOLLO",
            "county_id": 288,
            "county_name": "UPPER MADI County",
            "constituency_id": 326,
            "constituency_name": "UPPER MADI COUNTY"
        },
        {
            "id": 10,
            "name": "APAC",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC",
            "county_id": 3,
            "county_name": "MARUZI NORTH County",
            "constituency_id": 3,
            "constituency_name": "MARUZI NORTH COUNTY"
        },
        {
            "id": 1527,
            "name": "APALA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG",
            "county_id": 207,
            "county_name": "MOROTO County",
            "constituency_id": 243,
            "constituency_name": "MOROTO COUNTY"
        },
        {
            "id": 1528,
            "name": "APALA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG",
            "county_id": 207,
            "county_name": "MOROTO County",
            "constituency_id": 243,
            "constituency_name": "MOROTO COUNTY"
        },
        {
            "id": 2091,
            "name": "APAPAI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 129,
            "district_name": "KALAKI",
            "county_id": 282,
            "county_name": "KALAKI County",
            "constituency_id": 320,
            "constituency_name": "KALAKI COUNTY"
        },
        {
            "id": 1011,
            "name": "APEDURU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 141,
            "county_name": "AMURIA County",
            "constituency_id": 173,
            "constituency_name": "AMURIA COUNTY"
        },
        {
            "id": 1763,
            "name": "APEITOLIM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK",
            "county_id": 244,
            "county_name": "BOKORA County",
            "constituency_id": 280,
            "constituency_name": "BOKORA COUNTY"
        },
        {
            "id": 947,
            "name": "APERIKIRA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 54,
            "district_name": "KABERAMAIDO",
            "county_id": 134,
            "county_name": "KABERAMAIDO County",
            "constituency_id": 165,
            "constituency_name": "KABERAMAIDO COUNTY"
        },
        {
            "id": 617,
            "name": "APETAI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 93,
            "county_name": "TORORO NORTH County",
            "constituency_id": 115,
            "constituency_name": "TORORO NORTH COUNTY"
        },
        {
            "id": 925,
            "name": "APO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 131,
            "county_name": "ARINGA EAST County",
            "constituency_id": 162,
            "constituency_name": "ARINGA EAST COUNTY"
        },
        {
            "id": 7,
            "name": "APOI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC",
            "county_id": 2,
            "county_name": "MARUZI County",
            "constituency_id": 2,
            "constituency_name": "MARUZI COUNTY"
        },
        {
            "id": 623,
            "name": "APOKOR TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 94,
            "county_name": "TORORO SOUTH County",
            "constituency_id": 116,
            "constituency_name": "TORORO SOUTH COUNTY"
        },
        {
            "id": 543,
            "name": "APOPONG",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 81,
            "county_name": "GOGONYO County",
            "constituency_id": 102,
            "constituency_name": "GOGONYO COUNTY"
        },
        {
            "id": 1001,
            "name": "APUTI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 140,
            "county_name": "KIOGA NORTH County",
            "constituency_id": 172,
            "constituency_name": "KIOGA NORTH COUNTY"
        },
        {
            "id": 938,
            "name": "ARAFA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 133,
            "county_name": "ARINGA SOUTH County",
            "constituency_id": 164,
            "constituency_name": "ARINGA SOUTH COUNTY"
        },
        {
            "id": 602,
            "name": "ARAPAI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI",
            "county_id": 89,
            "county_name": "DAKABELA County",
            "constituency_id": 111,
            "constituency_name": "DAKABELA COUNTY"
        },
        {
            "id": 1918,
            "name": "AREMO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 263,
            "county_name": "TOCHI County",
            "constituency_id": 299,
            "constituency_name": "TOCHI COUNTY"
        },
        {
            "id": 926,
            "name": "ARIA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 131,
            "county_name": "ARINGA EAST County",
            "constituency_id": 162,
            "constituency_name": "ARINGA EAST COUNTY"
        },
        {
            "id": 931,
            "name": "ARILO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 132,
            "county_name": "ARINGA NORTH County",
            "constituency_id": 163,
            "constituency_name": "ARINGA NORTH COUNTY"
        },
        {
            "id": 656,
            "name": "ARINYAPI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 40,
            "district_name": "ADJUMANI",
            "county_id": 98,
            "county_name": "ADJUMANI EAST County",
            "constituency_id": 121,
            "constituency_name": "ADJUMANI EAST COUNTY"
        },
        {
            "id": 14,
            "name": "ARIVU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 2,
            "district_name": "ARUA",
            "county_id": 4,
            "county_name": "VURRA County",
            "constituency_id": 4,
            "constituency_name": "VURRA COUNTY"
        },
        {
            "id": 939,
            "name": "ARIWA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 133,
            "county_name": "ARINGA SOUTH County",
            "constituency_id": 164,
            "constituency_name": "ARINGA SOUTH COUNTY"
        },
        {
            "id": 3,
            "name": "AROCHA DIVISION",
            "type": "Municipal-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC",
            "county_id": 1,
            "county_name": "APAC Municipality",
            "constituency_id": 1,
            "constituency_name": "APAC MUNICIPALITY"
        },
        {
            "id": 335,
            "name": "AROMO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 22,
            "district_name": "LIRA",
            "county_id": 50,
            "county_name": "ERUTE County",
            "constituency_id": 62,
            "constituency_name": "ERUTE COUNTY NORTH"
        },
        {
            "id": 1814,
            "name": "ARUM",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 252,
            "county_name": "AGAGO WEST County",
            "constituency_id": 288,
            "constituency_name": "AGAGO WEST COUNTY"
        },
        {
            "id": 1002,
            "name": "ARWOTCEK",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 140,
            "county_name": "KIOGA NORTH County",
            "constituency_id": 172,
            "constituency_name": "KIOGA NORTH COUNTY"
        },
        {
            "id": 1012,
            "name": "ASAMUK",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 141,
            "county_name": "AMURIA County",
            "constituency_id": 173,
            "constituency_name": "AMURIA COUNTY"
        },
        {
            "id": 1013,
            "name": "ASAMUK TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 141,
            "county_name": "AMURIA County",
            "constituency_id": 173,
            "constituency_name": "AMURIA COUNTY"
        },
        {
            "id": 610,
            "name": "ASURET",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI",
            "county_id": 91,
            "county_name": "SOROTI County",
            "constituency_id": 113,
            "constituency_name": "SOROTI COUNTY"
        },
        {
            "id": 841,
            "name": "ATANGA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 122,
            "county_name": "ARUU NORTH County",
            "constituency_id": 150,
            "constituency_name": "ARUU NORTH COUNTY"
        },
        {
            "id": 842,
            "name": "ATANGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 122,
            "county_name": "ARUU NORTH County",
            "constituency_id": 150,
            "constituency_name": "ARUU NORTH COUNTY"
        },
        {
            "id": 493,
            "name": "ATEGO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 74,
            "county_name": "PADYERE County",
            "constituency_id": 95,
            "constituency_name": "PADYERE COUNTY"
        },
        {
            "id": 1501,
            "name": "ATHUMA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 204,
            "county_name": "OKORO County",
            "constituency_id": 240,
            "constituency_name": "OKORO COUNTY"
        },
        {
            "id": 1246,
            "name": "ATIAK",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU",
            "county_id": 172,
            "county_name": "KILAK NORTH County",
            "constituency_id": 207,
            "constituency_name": "KILAK NORTH COUNTY"
        },
        {
            "id": 1247,
            "name": "ATIAK TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU",
            "county_id": 172,
            "county_name": "KILAK NORTH County",
            "constituency_id": 207,
            "constituency_name": "KILAK NORTH COUNTY"
        },
        {
            "id": 1648,
            "name": "ATIIRA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 226,
            "county_name": "SERERE County",
            "constituency_id": 262,
            "constituency_name": "SERERE COUNTY"
        },
        {
            "id": 4,
            "name": "ATIK DIVISION",
            "type": "Municipal-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC",
            "county_id": 1,
            "county_name": "APAC Municipality",
            "constituency_id": 1,
            "constituency_name": "APAC MUNICIPALITY"
        },
        {
            "id": 2073,
            "name": "ATONGTIDI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 127,
            "district_name": "KWANIA",
            "county_id": 279,
            "county_name": "KWANIA County",
            "constituency_id": 317,
            "constituency_name": "KWANIA COUNTY"
        },
        {
            "id": 1782,
            "name": "ATOOT",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 108,
            "district_name": "NGORA",
            "county_id": 247,
            "county_name": "NGORA County",
            "constituency_id": 283,
            "constituency_name": "NGORA COUNTY"
        },
        {
            "id": 1235,
            "name": "ATUNGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 322,
            "name": "ATUTUR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 48,
            "county_name": "KUMI County",
            "constituency_id": 60,
            "constituency_name": "KUMI COUNTY"
        },
        {
            "id": 1510,
            "name": "ATYAK",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 205,
            "county_name": "ORA County",
            "constituency_id": 241,
            "constituency_name": "ORA COUNTY"
        },
        {
            "id": 607,
            "name": "AUKOT",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI",
            "county_id": 90,
            "county_name": "GWERI County",
            "constituency_id": 112,
            "constituency_name": "GWERI COUNTY"
        },
        {
            "id": 1236,
            "name": "AWACH",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 61,
            "name": "AWACH",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 5,
            "district_name": "GULU",
            "county_id": 9,
            "county_name": "ASWA County",
            "constituency_id": 10,
            "constituency_name": "ASWA COUNTY"
        },
        {
            "id": 608,
            "name": "AWALIWAL",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI",
            "county_id": 90,
            "county_name": "GWERI County",
            "constituency_id": 112,
            "constituency_name": "GWERI COUNTY"
        },
        {
            "id": 1520,
            "name": "AWEI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG",
            "county_id": 206,
            "county_name": "AJURI County",
            "constituency_id": 242,
            "constituency_name": "AJURI COUNTY"
        },
        {
            "id": 1003,
            "name": "AWELLO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 140,
            "county_name": "KIOGA NORTH County",
            "constituency_id": 172,
            "constituency_name": "KIOGA NORTH COUNTY"
        },
        {
            "id": 828,
            "name": "AWERE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 121,
            "county_name": "ARUU County",
            "constituency_id": 149,
            "constituency_name": "ARUU COUNTY"
        },
        {
            "id": 1340,
            "name": "AWIZIRU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 183,
            "county_name": "MARACHA County",
            "constituency_id": 219,
            "constituency_name": "MARACHA COUNTY"
        },
        {
            "id": 2074,
            "name": "AYABI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 127,
            "district_name": "KWANIA",
            "county_id": 279,
            "county_name": "KWANIA County",
            "constituency_id": 317,
            "constituency_name": "KWANIA COUNTY"
        },
        {
            "id": 2075,
            "name": "AYABI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 127,
            "district_name": "KWANIA",
            "county_id": 279,
            "county_name": "KWANIA County",
            "constituency_id": 317,
            "constituency_name": "KWANIA COUNTY"
        },
        {
            "id": 336,
            "name": "AYAMI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 22,
            "district_name": "LIRA",
            "county_id": 50,
            "county_name": "ERUTE County",
            "constituency_id": 62,
            "constituency_name": "ERUTE COUNTY NORTH"
        },
        {
            "id": 1722,
            "name": "AYER",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 103,
            "district_name": "KOLE",
            "county_id": 237,
            "county_name": "KOLE SOUTH County",
            "constituency_id": 274,
            "constituency_name": "KOLE SOUTH COUNTY"
        },
        {
            "id": 458,
            "name": "BAGEZZA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 68,
            "county_name": "KASAMBYA County",
            "constituency_id": 88,
            "constituency_name": "KASAMBYA COUNTY"
        },
        {
            "id": 822,
            "name": "BAITAMBOGWE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 148,
            "constituency_name": "BUNYA COUNTY WEST"
        },
        {
            "id": 1723,
            "name": "BALA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 103,
            "district_name": "KOLE",
            "county_id": 237,
            "county_name": "KOLE SOUTH County",
            "constituency_id": 274,
            "constituency_name": "KOLE SOUTH COUNTY"
        },
        {
            "id": 1724,
            "name": "BALA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 103,
            "district_name": "KOLE",
            "county_id": 237,
            "county_name": "KOLE SOUTH County",
            "constituency_id": 274,
            "constituency_name": "KOLE SOUTH COUNTY"
        },
        {
            "id": 141,
            "name": "BALAWOLI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 27,
            "county_name": "BUGABULA County",
            "constituency_id": 34,
            "constituency_name": "BUGABULA COUNTY NORTH"
        },
        {
            "id": 142,
            "name": "BALAWOLI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 27,
            "county_name": "BUGABULA County",
            "constituency_id": 34,
            "constituency_name": "BUGABULA COUNTY NORTH"
        },
        {
            "id": 344,
            "name": "BAMUNANIKA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 51,
            "county_name": "BAMUNANIKA County",
            "constituency_id": 64,
            "constituency_name": "BAMUNANIKA COUNTY"
        },
        {
            "id": 1586,
            "name": "BANANYWA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 216,
            "county_name": "BUTEMBA County",
            "constituency_id": 252,
            "constituency_name": "BUTEMBA COUNTY"
        },
        {
            "id": 1625,
            "name": "BANDA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 95,
            "district_name": "NAMAYINGO",
            "county_id": 222,
            "county_name": "NAMAYINGO SOUTH County",
            "constituency_id": 258,
            "constituency_name": "NAMAYINGO SOUTH COUNTY"
        },
        {
            "id": 1587,
            "name": "BANDA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 216,
            "county_name": "BUTEMBA County",
            "constituency_id": 252,
            "constituency_name": "BUTEMBA COUNTY"
        },
        {
            "id": 1626,
            "name": "BANDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 95,
            "district_name": "NAMAYINGO",
            "county_id": 222,
            "county_name": "NAMAYINGO SOUTH County",
            "constituency_id": 258,
            "constituency_name": "NAMAYINGO SOUTH COUNTY"
        },
        {
            "id": 927,
            "name": "BARAKALA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 131,
            "county_name": "ARINGA EAST County",
            "constituency_id": 162,
            "constituency_name": "ARINGA EAST COUNTY"
        },
        {
            "id": 1489,
            "name": "BARJOBI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE",
            "county_id": 202,
            "county_name": "OTUKE County",
            "constituency_id": 238,
            "constituency_name": "OTUKE COUNTY"
        },
        {
            "id": 1490,
            "name": "BARJOBI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE",
            "county_id": 202,
            "county_name": "OTUKE County",
            "constituency_id": 238,
            "constituency_name": "OTUKE COUNTY"
        },
        {
            "id": 341,
            "name": "BARR",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 22,
            "district_name": "LIRA",
            "county_id": 50,
            "county_name": "ERUTE County",
            "constituency_id": 63,
            "constituency_name": "ERUTE COUNTY SOUTH"
        },
        {
            "id": 1293,
            "name": "BATA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO",
            "county_id": 177,
            "county_name": "DOKOLO NORTH County",
            "constituency_id": 212,
            "constituency_name": "DOKOLO NORTH COUNTY"
        },
        {
            "id": 788,
            "name": "BATALIKA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 144,
            "constituency_name": "MWENGE COUNTY NORTH"
        },
        {
            "id": 1294,
            "name": "BATTA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO",
            "county_id": 177,
            "county_name": "DOKOLO NORTH County",
            "constituency_id": 212,
            "constituency_name": "DOKOLO NORTH COUNTY"
        },
        {
            "id": 768,
            "name": "BBAALE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA",
            "county_id": 116,
            "county_name": "BBALE County",
            "constituency_id": 140,
            "constituency_name": "BBALE COUNTY"
        },
        {
            "id": 1198,
            "name": "BBANDA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 165,
            "county_name": "BUSUJJU County",
            "constituency_id": 199,
            "constituency_name": "BUSUJJU COUNTY"
        },
        {
            "id": 1199,
            "name": "BBANDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 165,
            "county_name": "BUSUJJU County",
            "constituency_id": 199,
            "constituency_name": "BUSUJJU COUNTY"
        },
        {
            "id": 2163,
            "name": "BELEAFE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 143,
            "district_name": "TEREGO",
            "county_id": 307,
            "county_name": "TEREGO WEST County",
            "constituency_id": 347,
            "constituency_name": "TEREGO WEST COUNTY"
        },
        {
            "id": 1726,
            "name": "BENET",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 1655,
            "name": "BIGASA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 98,
            "district_name": "BUKOMANSIMBI",
            "county_id": 227,
            "county_name": "BUKOMANSIMBI NORTH County",
            "constituency_id": 263,
            "constituency_name": "BUKOMANSIMBI NORTH COUNTY"
        },
        {
            "id": 547,
            "name": "BIGODI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 114,
            "county_name": "KIBALE County",
            "constituency_id": 104,
            "constituency_name": "KIBALE COUNTY"
        },
        {
            "id": 759,
            "name": "BIGULI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 115,
            "county_name": "KIBALE EAST County",
            "constituency_id": 139,
            "constituency_name": "KIBALE EAST COUNTY"
        },
        {
            "id": 760,
            "name": "BIGULI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 115,
            "county_name": "KIBALE EAST County",
            "constituency_id": 139,
            "constituency_name": "KIBALE EAST COUNTY"
        },
        {
            "id": 762,
            "name": "BIHANGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 115,
            "county_name": "KIBALE EAST County",
            "constituency_id": 139,
            "constituency_name": "KIBALE EAST COUNTY"
        },
        {
            "id": 1794,
            "name": "BIHANGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU",
            "county_id": 249,
            "county_name": "BUHWEJU WEST County",
            "constituency_id": 285,
            "constituency_name": "BUHWEJU WEST COUNTY"
        },
        {
            "id": 1279,
            "name": "BIISO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 73,
            "district_name": "BULIISA",
            "county_id": 176,
            "county_name": "BULIISA County",
            "constituency_id": 211,
            "constituency_name": "BULIISA COUNTY"
        },
        {
            "id": 1280,
            "name": "BIISO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 73,
            "district_name": "BULIISA",
            "county_id": 176,
            "county_name": "BULIISA County",
            "constituency_id": 211,
            "constituency_name": "BULIISA COUNTY"
        },
        {
            "id": 920,
            "name": "BIJO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 130,
            "county_name": "ARINGA County",
            "constituency_id": 161,
            "constituency_name": "ARINGA COUNTY"
        },
        {
            "id": 376,
            "name": "BIKONZI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 54,
            "county_name": "BUJENJE County",
            "constituency_id": 72,
            "constituency_name": "BUJENJE COUNTY"
        },
        {
            "id": 592,
            "name": "BIKURUNGU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 87,
            "county_name": "RUJUMBURA County",
            "constituency_id": 109,
            "constituency_name": "RUJUMBURA COUNTY"
        },
        {
            "id": 1727,
            "name": "BINYINY",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 1728,
            "name": "BINYINY TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 1894,
            "name": "BIREMBO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 260,
            "county_name": "BUGANGAIZI SOUTH County",
            "constituency_id": 296,
            "constituency_name": "BUGANGAIZI SOUTH COUNTY"
        },
        {
            "id": 1088,
            "name": "BIRERE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 184,
            "constituency_name": "ISINGIRO COUNTY NORTH"
        },
        {
            "id": 1074,
            "name": "BISHESHE DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 148,
            "county_name": "IBANDA Municipality",
            "constituency_id": 181,
            "constituency_name": "IBANDA MUNICIPALITY"
        },
        {
            "id": 1750,
            "name": "BITEREKO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 242,
            "county_name": "RUHINDA NORTH County",
            "constituency_id": 278,
            "constituency_name": "RUHINDA NORTH COUNTY"
        },
        {
            "id": 54,
            "name": "BITOOMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 8,
            "county_name": "IGARA County",
            "constituency_id": 9,
            "constituency_name": "IGARA COUNTY WEST"
        },
        {
            "id": 1788,
            "name": "BITSYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU",
            "county_id": 248,
            "county_name": "BUHWEJU County",
            "constituency_id": 284,
            "constituency_name": "BUHWEJU COUNTY"
        },
        {
            "id": 1919,
            "name": "BOBI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 263,
            "county_name": "TOCHI County",
            "constituency_id": 299,
            "constituency_name": "TOCHI COUNTY"
        },
        {
            "id": 559,
            "name": "BOLISO I",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 83,
            "county_name": "PALLISA County",
            "constituency_id": 105,
            "constituency_name": "PALLISA COUNTY"
        },
        {
            "id": 79,
            "name": "BOMBO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA",
            "county_id": 11,
            "county_name": "KIGOROBYA County",
            "constituency_id": 12,
            "constituency_name": "KIGOROBYA COUNTY"
        },
        {
            "id": 357,
            "name": "BOMBO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 52,
            "county_name": "KATIKAMU County",
            "constituency_id": 66,
            "constituency_name": "KATIKAMU COUNTY SOUTH"
        },
        {
            "id": 843,
            "name": "BONGTIKO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 122,
            "county_name": "ARUU NORTH County",
            "constituency_id": 150,
            "constituency_name": "ARUU NORTH COUNTY"
        },
        {
            "id": 1036,
            "name": "BRIM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 144,
            "county_name": "TOO County",
            "constituency_id": 176,
            "constituency_name": "TOO COUNTY"
        },
        {
            "id": 416,
            "name": "BUBAARE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 27,
            "district_name": "MBARARA",
            "county_id": 60,
            "county_name": "KASHARI SOUTH County",
            "constituency_id": 79,
            "constituency_name": "KASHARI SOUTH COUNTY"
        },
        {
            "id": 1924,
            "name": "BUBAARE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 300,
            "constituency_name": "RUBANDA COUNTY EAST"
        },
        {
            "id": 31,
            "name": "BUBANDI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 6,
            "county_name": "BWAMBA County",
            "constituency_id": 6,
            "constituency_name": "BWAMBA COUNTY"
        },
        {
            "id": 222,
            "name": "BUBANGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE",
            "county_id": 35,
            "county_name": "BUYANJA County",
            "constituency_id": 45,
            "constituency_name": "BUYANJA COUNTY"
        },
        {
            "id": 1925,
            "name": "BUBARE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 300,
            "constituency_name": "RUBANDA COUNTY EAST"
        },
        {
            "id": 851,
            "name": "BUBBEZA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 137,
            "name": "BUBEKE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 11,
            "district_name": "KALANGALA",
            "county_id": 21,
            "county_name": "KYAMUSWA County",
            "constituency_id": 24,
            "constituency_name": "KYAMUSWA COUNTY"
        },
        {
            "id": 1364,
            "name": "BUBIITA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 186,
            "county_name": "LUTSESHE County",
            "constituency_id": 222,
            "constituency_name": "LUTSESHE COUNTY"
        },
        {
            "id": 32,
            "name": "BUBUKWANGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 6,
            "county_name": "BWAMBA County",
            "constituency_id": 6,
            "constituency_name": "BWAMBA COUNTY"
        },
        {
            "id": 1987,
            "name": "BUBUTU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 1988,
            "name": "BUBUTU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 400,
            "name": "BUBYANGU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 58,
            "county_name": "BUNGOKHO County",
            "constituency_id": 76,
            "constituency_name": "BUNGOKHO COUNTY NORTH"
        },
        {
            "id": 872,
            "name": "BUDADIRI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 1259,
            "name": "BUDAKA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 174,
            "county_name": "BUDAKA County",
            "constituency_id": 209,
            "constituency_name": "BUDAKA COUNTY"
        },
        {
            "id": 1260,
            "name": "BUDAKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 174,
            "county_name": "BUDAKA County",
            "constituency_id": 209,
            "constituency_name": "BUDAKA COUNTY"
        },
        {
            "id": 1664,
            "name": "BUDDE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 99,
            "district_name": "BUTAMBALA",
            "county_id": 229,
            "county_name": "BUTAMBALA County",
            "constituency_id": 265,
            "constituency_name": "BUTAMBALA COUNTY"
        },
        {
            "id": 669,
            "name": "BUDHAYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 124,
            "constituency_name": "BUKOOLI COUNTY CENTRAL"
        },
        {
            "id": 1126,
            "name": "BUDOMERO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 156,
            "county_name": "BULAMOGI County",
            "constituency_id": 190,
            "constituency_name": "BULAMOGI COUNTY"
        },
        {
            "id": 377,
            "name": "BUDONGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 54,
            "county_name": "BUJENJE County",
            "constituency_id": 72,
            "constituency_name": "BUJENJE COUNTY"
        },
        {
            "id": 1377,
            "name": "BUDUDA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 187,
            "county_name": "MANJIYA County",
            "constituency_id": 223,
            "constituency_name": "MANJIYA COUNTY"
        },
        {
            "id": 1378,
            "name": "BUDUDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 187,
            "county_name": "MANJIYA County",
            "constituency_id": 223,
            "constituency_name": "MANJIYA COUNTY"
        },
        {
            "id": 1054,
            "name": "BUDUMBA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 146,
            "county_name": "BUNYOLE WEST County",
            "constituency_id": 178,
            "constituency_name": "BUNYOLE WEST COUNTY"
        },
        {
            "id": 401,
            "name": "BUDWALE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 58,
            "county_name": "BUNGOKHO County",
            "constituency_id": 76,
            "constituency_name": "BUNGOKHO COUNTY NORTH"
        },
        {
            "id": 1046,
            "name": "BUFUJJA-KACHONGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 145,
            "county_name": "BUNYOLE EAST County",
            "constituency_id": 177,
            "constituency_name": "BUNYOLE EAST COUNTY"
        },
        {
            "id": 1365,
            "name": "BUFUMA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 186,
            "county_name": "LUTSESHE County",
            "constituency_id": 222,
            "constituency_name": "LUTSESHE COUNTY"
        },
        {
            "id": 1546,
            "name": "BUFUMBO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 210,
            "county_name": "ELGON NORTH County",
            "constituency_id": 246,
            "constituency_name": "ELGON NORTH COUNTY"
        },
        {
            "id": 402,
            "name": "BUFUMBO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 58,
            "county_name": "BUNGOKHO County",
            "constituency_id": 76,
            "constituency_name": "BUNGOKHO COUNTY NORTH"
        },
        {
            "id": 138,
            "name": "BUFUMIRA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 11,
            "district_name": "KALANGALA",
            "county_id": 21,
            "county_name": "KYAMUSWA County",
            "constituency_id": 24,
            "constituency_name": "KYAMUSWA COUNTY"
        },
        {
            "id": 1075,
            "name": "BUFUNDA DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 148,
            "county_name": "IBANDA Municipality",
            "constituency_id": 181,
            "constituency_name": "IBANDA MUNICIPALITY"
        },
        {
            "id": 1930,
            "name": "BUFUNDI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 301,
            "constituency_name": "RUBANDA COUNTY WEST"
        },
        {
            "id": 789,
            "name": "BUFUNJO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 144,
            "constituency_name": "MWENGE COUNTY NORTH"
        },
        {
            "id": 781,
            "name": "BUGAAKI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 118,
            "county_name": "MWENGE CENTRAL County",
            "constituency_id": 143,
            "constituency_name": "MWENGE CENTRAL COUNTY"
        },
        {
            "id": 816,
            "name": "BUGADDE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 147,
            "constituency_name": "BUNYA COUNTY SOUTH"
        },
        {
            "id": 2155,
            "name": "BUGAMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 135,
            "district_name": "RWAMPARA",
            "county_id": 291,
            "county_name": "RWAMPARA EAST County",
            "constituency_id": 329,
            "constituency_name": "RWAMPARA EAST COUNTY"
        },
        {
            "id": 2070,
            "name": "BUGAMBE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 126,
            "district_name": "KIKUUBE",
            "county_id": 278,
            "county_name": "BUHAGUZI EAST County",
            "constituency_id": 316,
            "constituency_name": "BUHAGUZI EAST COUNTY"
        },
        {
            "id": 852,
            "name": "BUGAMBI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 593,
            "name": "BUGANGARI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 87,
            "county_name": "RUJUMBURA County",
            "constituency_id": 109,
            "constituency_name": "RUJUMBURA COUNTY"
        },
        {
            "id": 1077,
            "name": "BUGANGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 149,
            "county_name": "BUKANGA County",
            "constituency_id": 182,
            "constituency_name": "BUKANGA COUNTY"
        },
        {
            "id": 33,
            "name": "BUGANIKERE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 6,
            "county_name": "BWAMBA County",
            "constituency_id": 6,
            "constituency_name": "BWAMBA COUNTY"
        },
        {
            "id": 1434,
            "name": "BUGAYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE",
            "county_id": 195,
            "county_name": "BUDIOPE EAST County",
            "constituency_id": 231,
            "constituency_name": "BUDIOPE EAST COUNTY"
        },
        {
            "id": 1555,
            "name": "BUGAYA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 90,
            "district_name": "BUVUMA",
            "county_id": 211,
            "county_name": "BUVUMA ISLANDS County",
            "constituency_id": 247,
            "constituency_name": "BUVUMA ISLANDS COUNTY"
        },
        {
            "id": 1547,
            "name": "BUGINYANYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 210,
            "county_name": "ELGON NORTH County",
            "constituency_id": 246,
            "constituency_name": "ELGON NORTH COUNTY"
        },
        {
            "id": 873,
            "name": "BUGITIMWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 1166,
            "name": "BUGOBERO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 1167,
            "name": "BUGOBERO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 1310,
            "name": "BUGOBI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 180,
            "county_name": "BUSIKI County",
            "constituency_id": 215,
            "constituency_name": "BUSIKI COUNTY"
        },
        {
            "id": 1311,
            "name": "BUGOBI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 180,
            "county_name": "BUSIKI County",
            "constituency_id": 215,
            "constituency_name": "BUSIKI COUNTY"
        },
        {
            "id": 1453,
            "name": "BUGOGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 198,
            "county_name": "KYAKA NORTH County",
            "constituency_id": 234,
            "constituency_name": "KYAKA NORTH COUNTY"
        },
        {
            "id": 1640,
            "name": "BUGONDO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 224,
            "county_name": "KASILO County",
            "constituency_id": 260,
            "constituency_name": "KASILO COUNTY"
        },
        {
            "id": 955,
            "name": "BUGONGI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 167,
            "constituency_name": "KINKIZI COUNTY EAST"
        },
        {
            "id": 1683,
            "name": "BUGONGI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 232,
            "county_name": "SHEEMA County",
            "constituency_id": 269,
            "constituency_name": "SHEEMA COUNTY SOUTH"
        },
        {
            "id": 196,
            "name": "BUGOYE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 42,
            "constituency_name": "BUSONGORA COUNTY NORTH"
        },
        {
            "id": 151,
            "name": "BUGULUMBYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 28,
            "county_name": "BUZAAYA County",
            "constituency_id": 36,
            "constituency_name": "BUZAAYA COUNTY"
        },
        {
            "id": 853,
            "name": "BUGUSEGE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 2120,
            "name": "BUHANDA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 132,
            "district_name": "KITAGWENDA",
            "county_id": 286,
            "county_name": "KITAGWENDA County",
            "constituency_id": 324,
            "constituency_name": "KITAGWENDA COUNTY"
        },
        {
            "id": 72,
            "name": "BUHANIKA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA",
            "county_id": 10,
            "county_name": "BUGAHYA County",
            "constituency_id": 11,
            "constituency_name": "BUGAHYA COUNTY"
        },
        {
            "id": 107,
            "name": "BUHARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 18,
            "county_name": "NDORWA County",
            "constituency_id": 20,
            "constituency_name": "NDORWA COUNTY EAST"
        },
        {
            "id": 1941,
            "name": "BUHEESI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 1942,
            "name": "BUHEESI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 689,
            "name": "BUHEHE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 103,
            "county_name": "SAMIA BUGWE CENTRAL County",
            "constituency_id": 127,
            "constituency_name": "SAMIA BUGWE CENTRAL COUNTY"
        },
        {
            "id": 1627,
            "name": "BUHEMBA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 95,
            "district_name": "NAMAYINGO",
            "county_id": 222,
            "county_name": "NAMAYINGO SOUTH County",
            "constituency_id": 258,
            "constituency_name": "NAMAYINGO SOUTH COUNTY"
        },
        {
            "id": 2071,
            "name": "BUHIMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 126,
            "district_name": "KIKUUBE",
            "county_id": 278,
            "county_name": "BUHAGUZI EAST County",
            "constituency_id": 316,
            "constituency_name": "BUHAGUZI EAST COUNTY"
        },
        {
            "id": 2072,
            "name": "BUHIMBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 126,
            "district_name": "KIKUUBE",
            "county_id": 278,
            "county_name": "BUHAGUZI EAST County",
            "constituency_id": 316,
            "constituency_name": "BUHAGUZI EAST COUNTY"
        },
        {
            "id": 968,
            "name": "BUHOMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 168,
            "constituency_name": "KINKIZI COUNTY WEST"
        },
        {
            "id": 874,
            "name": "BUHUGU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 197,
            "name": "BUHUHIRA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 42,
            "constituency_name": "BUSONGORA COUNTY NORTH"
        },
        {
            "id": 1866,
            "name": "BUHUMULIRO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 1789,
            "name": "BUHUNGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU",
            "county_id": 248,
            "county_name": "BUHWEJU County",
            "constituency_id": 284,
            "constituency_name": "BUHWEJU COUNTY"
        },
        {
            "id": 594,
            "name": "BUHUNGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 87,
            "county_name": "RUJUMBURA County",
            "constituency_id": 109,
            "constituency_name": "RUJUMBURA COUNTY"
        },
        {
            "id": 1421,
            "name": "BUIKWE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE",
            "county_id": 192,
            "county_name": "BUIKWE County",
            "constituency_id": 228,
            "constituency_name": "BUIKWE COUNTY SOUTH"
        },
        {
            "id": 1422,
            "name": "BUIKWE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE",
            "county_id": 192,
            "county_name": "BUIKWE County",
            "constituency_id": 228,
            "constituency_name": "BUIKWE COUNTY SOUTH"
        },
        {
            "id": 134,
            "name": "BUJUMBA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 11,
            "district_name": "KALANGALA",
            "county_id": 20,
            "county_name": "BUJUMBA County",
            "constituency_id": 23,
            "constituency_name": "BUJUMBA COUNTY"
        },
        {
            "id": 812,
            "name": "BUKABOOLI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 146,
            "constituency_name": "BUNYA COUNTY EAST"
        },
        {
            "id": 374,
            "name": "BUKAKATA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 24,
            "district_name": "MASAKA",
            "county_id": 53,
            "county_name": "BUKOTO County",
            "constituency_id": 71,
            "constituency_name": "BUKOTO COUNTY EAST"
        },
        {
            "id": 1366,
            "name": "BUKALASI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 186,
            "county_name": "LUTSESHE County",
            "constituency_id": 222,
            "constituency_name": "LUTSESHE COUNTY"
        },
        {
            "id": 1137,
            "name": "BUKAMBA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 157,
            "county_name": "BULAMOGI NORTH WEST County",
            "constituency_id": 191,
            "constituency_name": "BULAMOGI NORTH WEST COUNTY"
        },
        {
            "id": 1619,
            "name": "BUKANA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 95,
            "district_name": "NAMAYINGO",
            "county_id": 220,
            "county_name": "BUKOOLI ISLAND County",
            "constituency_id": 256,
            "constituency_name": "BUKOOLI ISLAND COUNTY"
        },
        {
            "id": 1612,
            "name": "BUKANGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 94,
            "district_name": "LUUKA",
            "county_id": 219,
            "county_name": "LUUKA SOUTH County",
            "constituency_id": 255,
            "constituency_name": "LUUKA SOUTH COUNTY"
        },
        {
            "id": 1656,
            "name": "BUKANGO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 98,
            "district_name": "BUKOMANSIMBI",
            "county_id": 227,
            "county_name": "BUKOMANSIMBI NORTH County",
            "constituency_id": 263,
            "constituency_name": "BUKOMANSIMBI NORTH COUNTY"
        },
        {
            "id": 1943,
            "name": "BUKARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 823,
            "name": "BUKATUBE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 148,
            "constituency_name": "BUNYA COUNTY WEST"
        },
        {
            "id": 1387,
            "name": "BUKEDEA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 188,
            "county_name": "BUKEDEA County",
            "constituency_id": 224,
            "constituency_name": "BUKEDEA COUNTY"
        },
        {
            "id": 1388,
            "name": "BUKEDEA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 188,
            "county_name": "BUKEDEA County",
            "constituency_id": 224,
            "constituency_name": "BUKEDEA COUNTY"
        },
        {
            "id": 1168,
            "name": "BUKEWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 2003,
            "name": "BUKHABUSI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 270,
            "county_name": "NAMISINDWA County",
            "constituency_id": 307,
            "constituency_name": "NAMISINDWA COUNTY"
        },
        {
            "id": 1185,
            "name": "BUKHADALA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 164,
            "county_name": "BUTIRU County",
            "constituency_id": 198,
            "constituency_name": "BUTIRU COUNTY"
        },
        {
            "id": 1529,
            "name": "BUKHALU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 208,
            "county_name": "BULAMBULI County",
            "constituency_id": 244,
            "constituency_name": "BULAMBULI COUNTY"
        },
        {
            "id": 2004,
            "name": "BUKHAWEKA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 270,
            "county_name": "NAMISINDWA County",
            "constituency_id": 307,
            "constituency_name": "NAMISINDWA COUNTY"
        },
        {
            "id": 405,
            "name": "BUKHIENDE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 58,
            "county_name": "BUNGOKHO County",
            "constituency_id": 77,
            "constituency_name": "BUNGOKHO COUNTY SOUTH"
        },
        {
            "id": 1186,
            "name": "BUKHOFU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 164,
            "county_name": "BUTIRU County",
            "constituency_id": 198,
            "constituency_name": "BUTIRU COUNTY"
        },
        {
            "id": 854,
            "name": "BUKHULO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 1989,
            "name": "BUKIABI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 1379,
            "name": "BUKIBINO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 187,
            "county_name": "MANJIYA County",
            "constituency_id": 223,
            "constituency_name": "MANJIYA COUNTY"
        },
        {
            "id": 1380,
            "name": "BUKIBOKOLO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 187,
            "county_name": "MANJIYA County",
            "constituency_id": 223,
            "constituency_name": "MANJIYA COUNTY"
        },
        {
            "id": 1358,
            "name": "BUKIGAI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 185,
            "county_name": "BUSHIGAI County",
            "constituency_id": 221,
            "constituency_name": "BUSHIGAI COUNTY"
        },
        {
            "id": 1359,
            "name": "BUKIGAI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 185,
            "county_name": "BUSHIGAI County",
            "constituency_id": 221,
            "constituency_name": "BUSHIGAI COUNTY"
        },
        {
            "id": 417,
            "name": "BUKIIRO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 27,
            "district_name": "MBARARA",
            "county_id": 60,
            "county_name": "KASHARI SOUTH County",
            "constituency_id": 79,
            "constituency_name": "KASHARI SOUTH COUNTY"
        },
        {
            "id": 418,
            "name": "BUKIIRO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 27,
            "district_name": "MBARARA",
            "county_id": 60,
            "county_name": "KASHARI SOUTH County",
            "constituency_id": 79,
            "constituency_name": "KASHARI SOUTH COUNTY"
        },
        {
            "id": 875,
            "name": "BUKIISE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 855,
            "name": "BUKIITI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 264,
            "name": "BUKIMBIRI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 40,
            "county_name": "BUKIMBIRI County",
            "constituency_id": 52,
            "constituency_name": "BUKIMBIRI COUNTY"
        },
        {
            "id": 2026,
            "name": "BUKINDA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 122,
            "district_name": "RUKIGA",
            "county_id": 272,
            "county_name": "RUKIGA County",
            "constituency_id": 309,
            "constituency_name": "RUKIGA COUNTY"
        },
        {
            "id": 856,
            "name": "BUKIYI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 1990,
            "name": "BUKOKHO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 1187,
            "name": "BUKOMA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 164,
            "county_name": "BUTIRU County",
            "constituency_id": 198,
            "constituency_name": "BUTIRU COUNTY"
        },
        {
            "id": 1657,
            "name": "BUKOMANSIMBI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 98,
            "district_name": "BUKOMANSIMBI",
            "county_id": 227,
            "county_name": "BUKOMANSIMBI NORTH County",
            "constituency_id": 263,
            "constituency_name": "BUKOMANSIMBI NORTH COUNTY"
        },
        {
            "id": 236,
            "name": "BUKOMERO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA",
            "county_id": 37,
            "county_name": "KIBOGA EAST County",
            "constituency_id": 47,
            "constituency_name": "KIBOGA EAST COUNTY"
        },
        {
            "id": 237,
            "name": "BUKOMERO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA",
            "county_id": 37,
            "county_name": "KIBOGA EAST County",
            "constituency_id": 47,
            "constituency_name": "KIBOGA EAST COUNTY"
        },
        {
            "id": 17,
            "name": "BUKONZO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 5,
            "county_name": "BUGHENDERA County",
            "constituency_id": 5,
            "constituency_name": "BUGHENDERA COUNTY"
        },
        {
            "id": 1607,
            "name": "BUKOOMA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 94,
            "district_name": "LUUKA",
            "county_id": 218,
            "county_name": "LUUKA NORTH County",
            "constituency_id": 254,
            "constituency_name": "LUUKA NORTH COUNTY"
        },
        {
            "id": 1608,
            "name": "BUKOOVA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 94,
            "district_name": "LUUKA",
            "county_id": 218,
            "county_name": "LUUKA NORTH County",
            "constituency_id": 254,
            "constituency_name": "LUUKA NORTH COUNTY"
        },
        {
            "id": 1671,
            "name": "BUKULULA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 100,
            "district_name": "KALUNGU",
            "county_id": 230,
            "county_name": "KALUNGU EAST County",
            "constituency_id": 266,
            "constituency_name": "KALUNGU EAST COUNTY"
        },
        {
            "id": 1440,
            "name": "BUKUNGU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE",
            "county_id": 196,
            "county_name": "BUDIOPE WEST County",
            "constituency_id": 232,
            "constituency_name": "BUDIOPE WEST COUNTY"
        },
        {
            "id": 2121,
            "name": "BUKURUNGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 132,
            "district_name": "KITAGWENDA",
            "county_id": 286,
            "county_name": "KITAGWENDA County",
            "constituency_id": 324,
            "constituency_name": "KITAGWENDA COUNTY"
        },
        {
            "id": 1188,
            "name": "BUKUSU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 164,
            "county_name": "BUTIRU County",
            "constituency_id": 198,
            "constituency_name": "BUTIRU COUNTY"
        },
        {
            "id": 2051,
            "name": "BUKUYA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 275,
            "county_name": "BUKUYA County",
            "constituency_id": 312,
            "constituency_name": "BUKUYA COUNTY"
        },
        {
            "id": 2052,
            "name": "BUKUYA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 275,
            "county_name": "BUKUYA County",
            "constituency_id": 312,
            "constituency_name": "BUKUYA COUNTY"
        },
        {
            "id": 1026,
            "name": "BUKWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 143,
            "county_name": "KONGASIS County",
            "constituency_id": 175,
            "constituency_name": "KONGASIS COUNTY"
        },
        {
            "id": 2005,
            "name": "BUKWHAWEKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 270,
            "county_name": "NAMISINDWA County",
            "constituency_id": 307,
            "constituency_name": "NAMISINDWA COUNTY"
        },
        {
            "id": 1027,
            "name": "BUKWO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 143,
            "county_name": "KONGASIS County",
            "constituency_id": 175,
            "constituency_name": "KONGASIS COUNTY"
        },
        {
            "id": 876,
            "name": "BUKYABO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 877,
            "name": "BUKYAMBI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 1539,
            "name": "BULAGO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 209,
            "county_name": "ELGON County",
            "constituency_id": 245,
            "constituency_name": "ELGON COUNTY"
        },
        {
            "id": 93,
            "name": "BULAMAGI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 7,
            "district_name": "IGANGA",
            "county_id": 13,
            "county_name": "KIGULU County",
            "constituency_id": 15,
            "constituency_name": "KIGULU COUNTY SOUTH"
        },
        {
            "id": 1530,
            "name": "BULAMBULI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 208,
            "county_name": "BULAMBULI County",
            "constituency_id": 244,
            "constituency_name": "BULAMBULI COUNTY"
        },
        {
            "id": 1613,
            "name": "BULANGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 94,
            "district_name": "LUUKA",
            "county_id": 219,
            "county_name": "LUUKA SOUTH County",
            "constituency_id": 255,
            "constituency_name": "LUUKA SOUTH COUNTY"
        },
        {
            "id": 1312,
            "name": "BULANGE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 180,
            "county_name": "BUSIKI County",
            "constituency_id": 215,
            "constituency_name": "BUSIKI COUNTY"
        },
        {
            "id": 1693,
            "name": "BULANGIRA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 234,
            "county_name": "KABWERI County",
            "constituency_id": 271,
            "constituency_name": "KABWERI COUNTY"
        },
        {
            "id": 1694,
            "name": "BULANGIRA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 234,
            "county_name": "KABWERI County",
            "constituency_id": 271,
            "constituency_name": "KABWERI COUNTY"
        },
        {
            "id": 1548,
            "name": "BULEGENI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 210,
            "county_name": "ELGON NORTH County",
            "constituency_id": 246,
            "constituency_name": "ELGON NORTH COUNTY"
        },
        {
            "id": 1549,
            "name": "BULEGENI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 210,
            "county_name": "ELGON NORTH County",
            "constituency_id": 246,
            "constituency_name": "ELGON NORTH COUNTY"
        },
        {
            "id": 219,
            "name": "BULEMBIA DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 34,
            "county_name": "KASESE Municipality",
            "constituency_id": 44,
            "constituency_name": "KASESE MUNICIPALITY"
        },
        {
            "id": 1205,
            "name": "BULERA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 166,
            "county_name": "MITYANA County",
            "constituency_id": 200,
            "constituency_name": "MITYANA COUNTY NORTH"
        },
        {
            "id": 670,
            "name": "BULESA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 124,
            "constituency_name": "BUKOOLI COUNTY CENTRAL"
        },
        {
            "id": 671,
            "name": "BULIDHA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 124,
            "constituency_name": "BUKOOLI COUNTY CENTRAL"
        },
        {
            "id": 1281,
            "name": "BULIISA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 73,
            "district_name": "BULIISA",
            "county_id": 176,
            "county_name": "BULIISA County",
            "constituency_id": 211,
            "constituency_name": "BULIISA COUNTY"
        },
        {
            "id": 1282,
            "name": "BULIISA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 73,
            "district_name": "BULIISA",
            "county_id": 176,
            "county_name": "BULIISA County",
            "constituency_id": 211,
            "constituency_name": "BULIISA COUNTY"
        },
        {
            "id": 378,
            "name": "BULIMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 54,
            "county_name": "BUJENJE County",
            "constituency_id": 72,
            "constituency_name": "BUJENJE COUNTY"
        },
        {
            "id": 73,
            "name": "BULINDI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA",
            "county_id": 10,
            "county_name": "BUGAHYA County",
            "constituency_id": 11,
            "constituency_name": "BUGAHYA COUNTY"
        },
        {
            "id": 1665,
            "name": "BULO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 99,
            "district_name": "BUTAMBALA",
            "county_id": 229,
            "county_name": "BUTAMBALA County",
            "constituency_id": 265,
            "constituency_name": "BUTAMBALA COUNTY"
        },
        {
            "id": 1609,
            "name": "BULONGO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 94,
            "district_name": "LUUKA",
            "county_id": 218,
            "county_name": "LUUKA NORTH County",
            "constituency_id": 254,
            "constituency_name": "LUUKA NORTH COUNTY"
        },
        {
            "id": 742,
            "name": "BULONGO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 110,
            "county_name": "LWEMIYAGA County",
            "constituency_id": 135,
            "constituency_name": "LWEMIYAGA COUNTY"
        },
        {
            "id": 146,
            "name": "BULOPA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 27,
            "county_name": "BUGABULA County",
            "constituency_id": 35,
            "constituency_name": "BUGABULA COUNTY SOUTH"
        },
        {
            "id": 1367,
            "name": "BULUCHEKE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 186,
            "county_name": "LUTSESHE County",
            "constituency_id": 222,
            "constituency_name": "LUTSESHE COUNTY"
        },
        {
            "id": 1540,
            "name": "BULUGANYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 209,
            "county_name": "ELGON County",
            "constituency_id": 245,
            "constituency_name": "ELGON COUNTY"
        },
        {
            "id": 680,
            "name": "BULUGUYI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 125,
            "constituency_name": "BUKOOLI COUNTY NORTH"
        },
        {
            "id": 2092,
            "name": "BULULU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 129,
            "district_name": "KALAKI",
            "county_id": 282,
            "county_name": "KALAKI County",
            "constituency_id": 320,
            "constituency_name": "KALAKI COUNTY"
        },
        {
            "id": 1127,
            "name": "BULUMBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 156,
            "county_name": "BULAMOGI County",
            "constituency_id": 190,
            "constituency_name": "BULAMOGI COUNTY"
        },
        {
            "id": 694,
            "name": "BULUMBI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 104,
            "county_name": "SAMIA BUGWE County",
            "constituency_id": 128,
            "constituency_name": "SAMIA BUGWE COUNTY NORTH"
        },
        {
            "id": 878,
            "name": "BUMALIMBA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 1128,
            "name": "BUMANYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 156,
            "county_name": "BULAMOGI County",
            "constituency_id": 190,
            "constituency_name": "BULAMOGI COUNTY"
        },
        {
            "id": 1381,
            "name": "BUMASHETI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 187,
            "county_name": "MANJIYA County",
            "constituency_id": 223,
            "constituency_name": "MANJIYA COUNTY"
        },
        {
            "id": 879,
            "name": "BUMASIFWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 406,
            "name": "BUMASIKYE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 58,
            "county_name": "BUNGOKHO County",
            "constituency_id": 77,
            "constituency_name": "BUNGOKHO COUNTY SOUTH"
        },
        {
            "id": 1541,
            "name": "BUMASOBO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 209,
            "county_name": "ELGON County",
            "constituency_id": 245,
            "constituency_name": "ELGON COUNTY"
        },
        {
            "id": 1368,
            "name": "BUMAYOKA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 186,
            "county_name": "LUTSESHE County",
            "constituency_id": 222,
            "constituency_name": "LUTSESHE COUNTY"
        },
        {
            "id": 47,
            "name": "BUMBAIRE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 8,
            "county_name": "IGARA County",
            "constituency_id": 8,
            "constituency_name": "IGARA COUNTY EAST"
        },
        {
            "id": 1991,
            "name": "BUMBO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 1992,
            "name": "BUMBO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 394,
            "name": "BUMBOBI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 57,
            "county_name": "BUNGOKHO CENTRAL County",
            "constituency_id": 75,
            "constituency_name": "BUNGOKHO CENTRAL COUNTY"
        },
        {
            "id": 1993,
            "name": "BUMITYERO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 1531,
            "name": "BUMUFUNI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 208,
            "county_name": "BULAMBULI County",
            "constituency_id": 244,
            "constituency_name": "BULAMBULI COUNTY"
        },
        {
            "id": 1550,
            "name": "BUMUGIBOLE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 210,
            "county_name": "ELGON NORTH County",
            "constituency_id": 246,
            "constituency_name": "ELGON NORTH COUNTY"
        },
        {
            "id": 880,
            "name": "BUMULISHA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 2006,
            "name": "BUMUMALI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 270,
            "county_name": "NAMISINDWA County",
            "constituency_id": 307,
            "constituency_name": "NAMISINDWA COUNTY"
        },
        {
            "id": 1369,
            "name": "BUMWALUKANI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 186,
            "county_name": "LUTSESHE County",
            "constituency_id": 222,
            "constituency_name": "LUTSESHE COUNTY"
        },
        {
            "id": 1994,
            "name": "BUMWONI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 1382,
            "name": "BUNABUTITI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 187,
            "county_name": "MANJIYA County",
            "constituency_id": 223,
            "constituency_name": "MANJIYA COUNTY"
        },
        {
            "id": 1169,
            "name": "BUNABUTSALE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 1189,
            "name": "BUNABWANA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 164,
            "county_name": "BUTIRU County",
            "constituency_id": 198,
            "constituency_name": "BUTIRU COUNTY"
        },
        {
            "id": 258,
            "name": "BUNAGANA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 39,
            "county_name": "BUFUMBIRA County",
            "constituency_id": 51,
            "constituency_name": "BUFUMBIRA COUNTY SOUTH"
        },
        {
            "id": 1532,
            "name": "BUNALWERE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 208,
            "county_name": "BULAMBULI County",
            "constituency_id": 244,
            "constituency_name": "BULAMBULI COUNTY"
        },
        {
            "id": 1533,
            "name": "BUNAMBUTYE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 208,
            "county_name": "BULAMBULI County",
            "constituency_id": 244,
            "constituency_name": "BULAMBULI COUNTY"
        },
        {
            "id": 407,
            "name": "BUNAMBUTYE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 58,
            "county_name": "BUNGOKHO County",
            "constituency_id": 77,
            "constituency_name": "BUNGOKHO COUNTY SOUTH"
        },
        {
            "id": 913,
            "name": "BUNAMWAYA DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 128,
            "county_name": "MAKINDYE-SSABAGABO Municipality",
            "constituency_id": 159,
            "constituency_name": "MAKINDYE-SSABAGABO MUNICIPALITY"
        },
        {
            "id": 1360,
            "name": "BUNATSAMI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 185,
            "county_name": "BUSHIGAI County",
            "constituency_id": 221,
            "constituency_name": "BUSHIGAI COUNTY"
        },
        {
            "id": 1370,
            "name": "BUNDESI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 186,
            "county_name": "LUTSESHE County",
            "constituency_id": 222,
            "constituency_name": "LUTSESHE COUNTY"
        },
        {
            "id": 34,
            "name": "BUNDIBUGYO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 6,
            "county_name": "BWAMBA County",
            "constituency_id": 6,
            "constituency_name": "BWAMBA COUNTY"
        },
        {
            "id": 35,
            "name": "BUNDINGOMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 6,
            "county_name": "BWAMBA County",
            "constituency_id": 6,
            "constituency_name": "BWAMBA COUNTY"
        },
        {
            "id": 2007,
            "name": "BUNGATI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 270,
            "county_name": "NAMISINDWA County",
            "constituency_id": 307,
            "constituency_name": "NAMISINDWA COUNTY"
        },
        {
            "id": 62,
            "name": "BUNGATIRA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 5,
            "district_name": "GULU",
            "county_id": 9,
            "county_name": "ASWA County",
            "constituency_id": 10,
            "constituency_name": "ASWA COUNTY"
        },
        {
            "id": 395,
            "name": "BUNGOKHO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 57,
            "county_name": "BUNGOKHO CENTRAL County",
            "constituency_id": 75,
            "constituency_name": "BUNGOKHO CENTRAL COUNTY"
        },
        {
            "id": 857,
            "name": "BUNYAFWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 2008,
            "name": "BUPOTO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 270,
            "county_name": "NAMISINDWA County",
            "constituency_id": 307,
            "constituency_name": "NAMISINDWA COUNTY"
        },
        {
            "id": 74,
            "name": "BURARU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA",
            "county_id": 10,
            "county_name": "BUGAHYA County",
            "constituency_id": 11,
            "constituency_name": "BUGAHYA COUNTY"
        },
        {
            "id": 2110,
            "name": "BUREMBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 131,
            "district_name": "KAZO",
            "county_id": 285,
            "county_name": "KAZO County",
            "constituency_id": 323,
            "constituency_name": "KAZO COUNTY"
        },
        {
            "id": 1795,
            "name": "BURERE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU",
            "county_id": 249,
            "county_name": "BUHWEJU WEST County",
            "constituency_id": 285,
            "constituency_name": "BUHWEJU WEST COUNTY"
        },
        {
            "id": 18,
            "name": "BURONDO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 5,
            "county_name": "BUGHENDERA County",
            "constituency_id": 5,
            "constituency_name": "BUGHENDERA COUNTY"
        },
        {
            "id": 1867,
            "name": "BURORA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 2111,
            "name": "BURUNGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 131,
            "district_name": "KAZO",
            "county_id": 285,
            "county_name": "KAZO County",
            "constituency_id": 323,
            "constituency_name": "KAZO COUNTY"
        },
        {
            "id": 773,
            "name": "BUSAANA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA",
            "county_id": 117,
            "county_name": "NTENJERU County",
            "constituency_id": 141,
            "constituency_name": "NTENJERU COUNTY NORTH"
        },
        {
            "id": 774,
            "name": "BUSAANA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA",
            "county_id": 117,
            "county_name": "NTENJERU County",
            "constituency_id": 141,
            "constituency_name": "NTENJERU COUNTY NORTH"
        },
        {
            "id": 1055,
            "name": "BUSABA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 146,
            "county_name": "BUNYOLE WEST County",
            "constituency_id": 178,
            "constituency_name": "BUNYOLE WEST COUNTY"
        },
        {
            "id": 1056,
            "name": "BUSABA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 146,
            "county_name": "BUNYOLE WEST County",
            "constituency_id": 178,
            "constituency_name": "BUNYOLE WEST COUNTY"
        },
        {
            "id": 1057,
            "name": "BUSABI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 146,
            "county_name": "BUNYOLE WEST County",
            "constituency_id": 178,
            "constituency_name": "BUNYOLE WEST COUNTY"
        },
        {
            "id": 817,
            "name": "BUSAKIRA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 147,
            "constituency_name": "BUNYA COUNTY SOUTH"
        },
        {
            "id": 1614,
            "name": "BUSALAMU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 94,
            "district_name": "LUUKA",
            "county_id": 219,
            "county_name": "LUUKA SOUTH County",
            "constituency_id": 255,
            "constituency_name": "LUUKA SOUTH COUNTY"
        },
        {
            "id": 858,
            "name": "BUSAMAGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 1556,
            "name": "BUSAMUZI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 90,
            "district_name": "BUVUMA",
            "county_id": 211,
            "county_name": "BUVUMA ISLANDS County",
            "constituency_id": 247,
            "constituency_name": "BUVUMA ISLANDS COUNTY"
        },
        {
            "id": 396,
            "name": "BUSANO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 57,
            "county_name": "BUNGOKHO CENTRAL County",
            "constituency_id": 75,
            "constituency_name": "BUNGOKHO CENTRAL COUNTY"
        },
        {
            "id": 254,
            "name": "BUSANZA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 39,
            "county_name": "BUFUMBIRA County",
            "constituency_id": 50,
            "constituency_name": "BUFUMBIRA COUNTY NORTH"
        },
        {
            "id": 36,
            "name": "BUSARU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 6,
            "county_name": "BWAMBA County",
            "constituency_id": 6,
            "constituency_name": "BWAMBA COUNTY"
        },
        {
            "id": 97,
            "name": "BUSEDDE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 8,
            "district_name": "JINJA",
            "county_id": 14,
            "county_name": "BUTEMBE County",
            "constituency_id": 16,
            "constituency_name": "BUTEMBE COUNTY"
        },
        {
            "id": 2032,
            "name": "BUSEMBATIA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 123,
            "district_name": "BUGWERI",
            "county_id": 273,
            "county_name": "BUGWERI County",
            "constituency_id": 310,
            "constituency_name": "BUGWERI COUNTY"
        },
        {
            "id": 75,
            "name": "BUSERUKA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA",
            "county_id": 10,
            "county_name": "BUGAHYA County",
            "constituency_id": 11,
            "constituency_name": "BUGAHYA COUNTY"
        },
        {
            "id": 2039,
            "name": "BUSESA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 123,
            "district_name": "BUGWERI",
            "county_id": 273,
            "county_name": "BUGWERI County",
            "constituency_id": 310,
            "constituency_name": "BUGWERI COUNTY"
        },
        {
            "id": 1705,
            "name": "BUSETA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 235,
            "county_name": "KIBUKU County",
            "constituency_id": 272,
            "constituency_name": "KIBUKU COUNTY"
        },
        {
            "id": 1383,
            "name": "BUSHIKA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 187,
            "county_name": "MANJIYA County",
            "constituency_id": 223,
            "constituency_name": "MANJIYA COUNTY"
        },
        {
            "id": 1361,
            "name": "BUSHIRIBO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 185,
            "county_name": "BUSHIGAI County",
            "constituency_id": 221,
            "constituency_name": "BUSHIGAI COUNTY"
        },
        {
            "id": 1371,
            "name": "BUSHIYI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 186,
            "county_name": "LUTSESHE County",
            "constituency_id": 222,
            "constituency_name": "LUTSESHE COUNTY"
        },
        {
            "id": 345,
            "name": "BUSIIKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 51,
            "county_name": "BAMUNANIKA County",
            "constituency_id": 64,
            "constituency_name": "BAMUNANIKA COUNTY"
        },
        {
            "id": 881,
            "name": "BUSIITA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 1212,
            "name": "BUSIMBI DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 167,
            "county_name": "MITYANA Municipality",
            "constituency_id": 202,
            "constituency_name": "MITYANA MUNICIPALITY"
        },
        {
            "id": 702,
            "name": "BUSIME",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 104,
            "county_name": "SAMIA BUGWE County",
            "constituency_id": 129,
            "constituency_name": "SAMIA BUGWE COUNTY SOUTH"
        },
        {
            "id": 548,
            "name": "BUSIRIBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 114,
            "county_name": "KIBALE County",
            "constituency_id": 104,
            "constituency_name": "KIBALE COUNTY"
        },
        {
            "id": 1372,
            "name": "BUSIRIWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 186,
            "county_name": "LUTSESHE County",
            "constituency_id": 222,
            "constituency_name": "LUTSESHE COUNTY"
        },
        {
            "id": 695,
            "name": "BUSITEMA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 104,
            "county_name": "SAMIA BUGWE County",
            "constituency_id": 128,
            "constituency_name": "SAMIA BUGWE COUNTY NORTH"
        },
        {
            "id": 408,
            "name": "BUSIU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 58,
            "county_name": "BUNGOKHO County",
            "constituency_id": 77,
            "constituency_name": "BUNGOKHO COUNTY SOUTH"
        },
        {
            "id": 409,
            "name": "BUSIU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 58,
            "county_name": "BUNGOKHO County",
            "constituency_id": 77,
            "constituency_name": "BUNGOKHO COUNTY SOUTH"
        },
        {
            "id": 397,
            "name": "BUSOBA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 57,
            "county_name": "BUNGOKHO CENTRAL County",
            "constituency_id": 75,
            "constituency_name": "BUNGOKHO CENTRAL COUNTY"
        },
        {
            "id": 1058,
            "name": "BUSOLWE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 146,
            "county_name": "BUNYOLE WEST County",
            "constituency_id": 178,
            "constituency_name": "BUNYOLE WEST COUNTY"
        },
        {
            "id": 1059,
            "name": "BUSOLWE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 146,
            "county_name": "BUNYOLE WEST County",
            "constituency_id": 178,
            "constituency_name": "BUNYOLE WEST COUNTY"
        },
        {
            "id": 119,
            "name": "BUSORO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 672,
            "name": "BUSOWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 124,
            "constituency_name": "BUKOOLI COUNTY CENTRAL"
        },
        {
            "id": 903,
            "name": "BUSSI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 124,
            "county_name": "BUSIRO County",
            "constituency_id": 155,
            "constituency_name": "BUSIRO COUNTY SOUTH"
        },
        {
            "id": 916,
            "name": "BUSUKUMA DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 129,
            "county_name": "NANSANA Municipality",
            "constituency_id": 160,
            "constituency_name": "NANSANA MUNICIPALITY"
        },
        {
            "id": 1170,
            "name": "BUSUKUYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 882,
            "name": "BUSULANI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 37,
            "name": "BUSUNGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 6,
            "county_name": "BWAMBA County",
            "constituency_id": 6,
            "constituency_name": "BWAMBA COUNTY"
        },
        {
            "id": 1208,
            "name": "BUSUNJU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 166,
            "county_name": "MITYANA County",
            "constituency_id": 201,
            "constituency_name": "MITYANA COUNTY SOUTH"
        },
        {
            "id": 1622,
            "name": "BUSWALE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 95,
            "district_name": "NAMAYINGO",
            "county_id": 221,
            "county_name": "BUKOOLI SOUTH County",
            "constituency_id": 257,
            "constituency_name": "BUKOOLI SOUTH COUNTY"
        },
        {
            "id": 99,
            "name": "BUTAGAYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 8,
            "district_name": "JINJA",
            "county_id": 15,
            "county_name": "KAGOMA County",
            "constituency_id": 17,
            "constituency_name": "KAGOMA COUNTY"
        },
        {
            "id": 1047,
            "name": "BUTALEJA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 145,
            "county_name": "BUNYOLE EAST County",
            "constituency_id": 177,
            "constituency_name": "BUNYOLE EAST COUNTY"
        },
        {
            "id": 1048,
            "name": "BUTALEJA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 145,
            "county_name": "BUNYOLE EAST County",
            "constituency_id": 177,
            "constituency_name": "BUNYOLE EAST COUNTY"
        },
        {
            "id": 19,
            "name": "BUTAMA-MITUNDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 5,
            "county_name": "BUGHENDERA County",
            "constituency_id": 5,
            "constituency_name": "BUGHENDERA COUNTY"
        },
        {
            "id": 111,
            "name": "BUTANDA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 18,
            "county_name": "NDORWA County",
            "constituency_id": 21,
            "constituency_name": "NDORWA COUNTY WEST"
        },
        {
            "id": 883,
            "name": "BUTANDIGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 884,
            "name": "BUTANDIGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 147,
            "name": "BUTANSI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 27,
            "county_name": "BUGABULA County",
            "constituency_id": 35,
            "constituency_name": "BUGABULA COUNTY SOUTH"
        },
        {
            "id": 1931,
            "name": "BUTARE-KATOJO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 301,
            "constituency_name": "RUBANDA COUNTY WEST"
        },
        {
            "id": 1200,
            "name": "BUTAYUNJA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 165,
            "county_name": "BUSUJJU County",
            "constituency_id": 199,
            "constituency_name": "BUSUJJU COUNTY"
        },
        {
            "id": 696,
            "name": "BUTEBA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 104,
            "county_name": "SAMIA BUGWE County",
            "constituency_id": 128,
            "constituency_name": "SAMIA BUGWE COUNTY NORTH"
        },
        {
            "id": 1956,
            "name": "BUTEBO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 1957,
            "name": "BUTEBO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 1588,
            "name": "BUTEMBA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 216,
            "county_name": "BUTEMBA County",
            "constituency_id": 252,
            "constituency_name": "BUTEMBA COUNTY"
        },
        {
            "id": 1589,
            "name": "BUTEMBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 216,
            "county_name": "BUTEMBA County",
            "constituency_id": 252,
            "constituency_name": "BUTEMBA COUNTY"
        },
        {
            "id": 1661,
            "name": "BUTENGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 98,
            "district_name": "BUKOMANSIMBI",
            "county_id": 228,
            "county_name": "BUKOMANSIMBI SOUTH County",
            "constituency_id": 264,
            "constituency_name": "BUKOMANSIMBI SOUTH COUNTY"
        },
        {
            "id": 1662,
            "name": "BUTENGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 98,
            "district_name": "BUKOMANSIMBI",
            "county_id": 228,
            "county_name": "BUKOMANSIMBI SOUTH County",
            "constituency_id": 264,
            "constituency_name": "BUKOMANSIMBI SOUTH COUNTY"
        },
        {
            "id": 2151,
            "name": "BUTERANIRO-NYEIHANGA TOWN COUN",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 135,
            "district_name": "RWAMPARA",
            "county_id": 290,
            "county_name": "RWAMPARA County",
            "constituency_id": 328,
            "constituency_name": "RWAMPARA COUNTY"
        },
        {
            "id": 859,
            "name": "BUTEZA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 860,
            "name": "BUTEZA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 1283,
            "name": "BUTIABA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 73,
            "district_name": "BULIISA",
            "county_id": 176,
            "county_name": "BULIISA County",
            "constituency_id": 211,
            "constituency_name": "BULIISA COUNTY"
        },
        {
            "id": 1284,
            "name": "BUTIABA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 73,
            "district_name": "BULIISA",
            "county_id": 176,
            "county_name": "BULIISA County",
            "constituency_id": 211,
            "constituency_name": "BULIISA COUNTY"
        },
        {
            "id": 799,
            "name": "BUTIITI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 145,
            "constituency_name": "MWENGE COUNTY SOUTH"
        },
        {
            "id": 800,
            "name": "BUTIITI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 145,
            "constituency_name": "MWENGE COUNTY SOUTH"
        },
        {
            "id": 1190,
            "name": "BUTIRU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 164,
            "county_name": "BUTIRU County",
            "constituency_id": 198,
            "constituency_name": "BUTIRU COUNTY"
        },
        {
            "id": 1191,
            "name": "BUTIRU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 164,
            "county_name": "BUTIRU County",
            "constituency_id": 198,
            "constituency_name": "BUTIRU COUNTY"
        },
        {
            "id": 969,
            "name": "BUTOGOTA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 168,
            "constituency_name": "KINKIZI COUNTY WEST"
        },
        {
            "id": 450,
            "name": "BUTOLOOGO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 66,
            "county_name": "BUWEKULA County",
            "constituency_id": 86,
            "constituency_name": "BUWEKULA COUNTY"
        },
        {
            "id": 1171,
            "name": "BUTOOTO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 1172,
            "name": "BUTTA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 801,
            "name": "BUTUNDUZI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 145,
            "constituency_name": "MWENGE COUNTY SOUTH"
        },
        {
            "id": 802,
            "name": "BUTUNDUZI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 145,
            "constituency_name": "MWENGE COUNTY SOUTH"
        },
        {
            "id": 1630,
            "name": "BUTUNGAMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 96,
            "district_name": "NTOROKO",
            "county_id": 223,
            "county_name": "NTOROKO County",
            "constituency_id": 259,
            "constituency_name": "NTOROKO COUNTY"
        },
        {
            "id": 353,
            "name": "BUTUNTUMULA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 52,
            "county_name": "KATIKAMU County",
            "constituency_id": 65,
            "constituency_name": "KATIKAMU COUNTY NORTH"
        },
        {
            "id": 1557,
            "name": "BUVUMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 90,
            "district_name": "BUVUMA",
            "county_id": 211,
            "county_name": "BUVUMA ISLANDS County",
            "constituency_id": 247,
            "constituency_name": "BUVUMA ISLANDS COUNTY"
        },
        {
            "id": 813,
            "name": "BUWAAYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 146,
            "constituency_name": "BUNYA COUNTY EAST"
        },
        {
            "id": 2009,
            "name": "BUWABWALA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 270,
            "county_name": "NAMISINDWA County",
            "constituency_id": 307,
            "constituency_name": "NAMISINDWA COUNTY"
        },
        {
            "id": 1173,
            "name": "BUWAGOGO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 861,
            "name": "BUWALASI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 1373,
            "name": "BUWALI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 186,
            "county_name": "LUTSESHE County",
            "constituency_id": 222,
            "constituency_name": "LUTSESHE COUNTY"
        },
        {
            "id": 445,
            "name": "BUWAMA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 30,
            "district_name": "MPIGI",
            "county_id": 65,
            "county_name": "MAWOKOTA County",
            "constituency_id": 85,
            "constituency_name": "MAWOKOTA COUNTY SOUTH"
        },
        {
            "id": 446,
            "name": "BUWAMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 30,
            "district_name": "MPIGI",
            "county_id": 65,
            "county_name": "MAWOKOTA County",
            "constituency_id": 85,
            "constituency_name": "MAWOKOTA COUNTY SOUTH"
        },
        {
            "id": 1995,
            "name": "BUWAMBWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 1174,
            "name": "BUWANGANI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 1534,
            "name": "BUWANYANGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 208,
            "county_name": "BULAMBULI County",
            "constituency_id": 244,
            "constituency_name": "BULAMBULI COUNTY"
        },
        {
            "id": 862,
            "name": "BUWASA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 2010,
            "name": "BUWATUWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 270,
            "county_name": "NAMISINDWA County",
            "constituency_id": 307,
            "constituency_name": "NAMISINDWA COUNTY"
        },
        {
            "id": 1192,
            "name": "BUWAYA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 164,
            "county_name": "BUTIRU County",
            "constituency_id": 198,
            "constituency_name": "BUTIRU COUNTY"
        },
        {
            "id": 101,
            "name": "BUWENGE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 8,
            "district_name": "JINJA",
            "county_id": 16,
            "county_name": "KAGOMA NORTH County",
            "constituency_id": 18,
            "constituency_name": "KAGOMA NORTH COUNTY"
        },
        {
            "id": 102,
            "name": "BUWENGE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 8,
            "district_name": "JINJA",
            "county_id": 16,
            "county_name": "KAGOMA NORTH County",
            "constituency_id": 18,
            "constituency_name": "KAGOMA NORTH COUNTY"
        },
        {
            "id": 863,
            "name": "BUWERI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 1558,
            "name": "BUWOOYA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 90,
            "district_name": "BUVUMA",
            "county_id": 211,
            "county_name": "BUVUMA ISLANDS County",
            "constituency_id": 247,
            "constituency_name": "BUVUMA ISLANDS COUNTY"
        },
        {
            "id": 375,
            "name": "BUWUNGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 24,
            "district_name": "MASAKA",
            "county_id": 53,
            "county_name": "BUKOTO County",
            "constituency_id": 71,
            "constituency_name": "BUKOTO COUNTY EAST"
        },
        {
            "id": 673,
            "name": "BUWUNGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 124,
            "constituency_name": "BUKOOLI COUNTY CENTRAL"
        },
        {
            "id": 674,
            "name": "BUWUNI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 124,
            "constituency_name": "BUKOOLI COUNTY CENTRAL"
        },
        {
            "id": 1535,
            "name": "BUYAGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 208,
            "county_name": "BULAMBULI County",
            "constituency_id": 244,
            "constituency_name": "BULAMBULI COUNTY"
        },
        {
            "id": 2033,
            "name": "BUYANGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 123,
            "district_name": "BUGWERI",
            "county_id": 273,
            "county_name": "BUGWERI County",
            "constituency_id": 310,
            "constituency_name": "BUGWERI COUNTY"
        },
        {
            "id": 697,
            "name": "BUYANGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 104,
            "county_name": "SAMIA BUGWE County",
            "constituency_id": 128,
            "constituency_name": "SAMIA BUGWE COUNTY NORTH"
        },
        {
            "id": 1441,
            "name": "BUYANJA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE",
            "county_id": 196,
            "county_name": "BUDIOPE WEST County",
            "constituency_id": 232,
            "constituency_name": "BUDIOPE WEST COUNTY"
        },
        {
            "id": 586,
            "name": "BUYANJA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 86,
            "county_name": "RUBABO County",
            "constituency_id": 108,
            "constituency_name": "RUBABO COUNTY"
        },
        {
            "id": 587,
            "name": "BUYANJA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 86,
            "county_name": "RUBABO County",
            "constituency_id": 108,
            "constituency_name": "RUBABO COUNTY"
        },
        {
            "id": 1442,
            "name": "BUYENDE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE",
            "county_id": 196,
            "county_name": "BUDIOPE WEST County",
            "constituency_id": 232,
            "constituency_name": "BUDIOPE WEST COUNTY"
        },
        {
            "id": 1443,
            "name": "BUYENDE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE",
            "county_id": 196,
            "county_name": "BUDIOPE WEST County",
            "constituency_id": 232,
            "constituency_name": "BUDIOPE WEST COUNTY"
        },
        {
            "id": 103,
            "name": "BUYENGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 8,
            "district_name": "JINJA",
            "county_id": 16,
            "county_name": "KAGOMA NORTH County",
            "constituency_id": 18,
            "constituency_name": "KAGOMA NORTH COUNTY"
        },
        {
            "id": 1129,
            "name": "BUYINDA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 156,
            "county_name": "BULAMOGI County",
            "constituency_id": 190,
            "constituency_name": "BULAMOGI COUNTY"
        },
        {
            "id": 1623,
            "name": "BUYINJA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 95,
            "district_name": "NAMAYINGO",
            "county_id": 221,
            "county_name": "BUKOOLI SOUTH County",
            "constituency_id": 257,
            "constituency_name": "BUKOOLI SOUTH COUNTY"
        },
        {
            "id": 1193,
            "name": "BUYINZA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 164,
            "county_name": "BUTIRU County",
            "constituency_id": 198,
            "constituency_name": "BUTIRU COUNTY"
        },
        {
            "id": 864,
            "name": "BUYOBO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 595,
            "name": "BWAMBARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 87,
            "county_name": "RUJUMBURA County",
            "constituency_id": 109,
            "constituency_name": "RUJUMBURA COUNTY"
        },
        {
            "id": 223,
            "name": "BWAMIRAMIRA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE",
            "county_id": 35,
            "county_name": "BUYANJA County",
            "constituency_id": 45,
            "constituency_name": "BUYANJA COUNTY"
        },
        {
            "id": 1895,
            "name": "BWANSWA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 260,
            "county_name": "BUGANGAIZI SOUTH County",
            "constituency_id": 296,
            "constituency_name": "BUGANGAIZI SOUTH COUNTY"
        },
        {
            "id": 1559,
            "name": "BWEEMA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 90,
            "district_name": "BUVUMA",
            "county_id": 211,
            "county_name": "BUVUMA ISLANDS County",
            "constituency_id": 247,
            "constituency_name": "BUVUMA ISLANDS COUNTY"
        },
        {
            "id": 178,
            "name": "BWERA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 40,
            "constituency_name": "BUKONJO COUNTY WEST"
        },
        {
            "id": 1631,
            "name": "BWERAMULE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 96,
            "district_name": "NTOROKO",
            "county_id": 223,
            "county_name": "NTOROKO County",
            "constituency_id": 259,
            "constituency_name": "NTOROKO COUNTY"
        },
        {
            "id": 198,
            "name": "BWESUMBU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 42,
            "constituency_name": "BUSONGORA COUNTY NORTH"
        },
        {
            "id": 1573,
            "name": "BWEYALE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO",
            "county_id": 214,
            "county_name": "KIBANDA NORTH County",
            "constituency_id": 250,
            "constituency_name": "KIBANDA NORTH COUNTY"
        },
        {
            "id": 910,
            "name": "BWEYOGERERE DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 127,
            "county_name": "KIRA Municipality",
            "constituency_id": 158,
            "constituency_name": "KIRA MUNICIPALITY"
        },
        {
            "id": 379,
            "name": "BWIJANGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 54,
            "county_name": "BUJENJE County",
            "constituency_id": 72,
            "constituency_name": "BUJENJE COUNTY"
        },
        {
            "id": 1868,
            "name": "BWIKARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 1536,
            "name": "BWIKHONGE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 208,
            "county_name": "BULAMBULI County",
            "constituency_id": 244,
            "constituency_name": "BULAMBULI COUNTY"
        },
        {
            "id": 763,
            "name": "BWIZI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 115,
            "county_name": "KIBALE EAST County",
            "constituency_id": 139,
            "constituency_name": "KIBALE EAST COUNTY"
        },
        {
            "id": 419,
            "name": "BWIZIBWERA-RUTOOMA TOWN COUNCI",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 27,
            "district_name": "MBARARA",
            "county_id": 60,
            "county_name": "KASHARI SOUTH County",
            "constituency_id": 79,
            "constituency_name": "KASHARI SOUTH COUNTY"
        },
        {
            "id": 818,
            "name": "BWONDHA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 147,
            "constituency_name": "BUNYA COUNTY SOUTH"
        },
        {
            "id": 506,
            "name": "BWONGYERA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 76,
            "county_name": "KAJARA County",
            "constituency_id": 97,
            "constituency_name": "KAJARA COUNTY"
        },
        {
            "id": 574,
            "name": "BYAKABANDA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 85,
            "county_name": "KOOKI County",
            "constituency_id": 107,
            "constituency_name": "KOOKI COUNTY"
        },
        {
            "id": 1590,
            "name": "BYERIMA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 216,
            "county_name": "BUTEMBA County",
            "constituency_id": 252,
            "constituency_name": "BUTEMBA COUNTY"
        },
        {
            "id": 1237,
            "name": "CAMKOK",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 2076,
            "name": "CAWENTE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 127,
            "district_name": "KWANIA",
            "county_id": 279,
            "county_name": "KWANIA County",
            "constituency_id": 317,
            "constituency_name": "KWANIA COUNTY"
        },
        {
            "id": 311,
            "name": "CENTRAL DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 46,
            "county_name": "KOTIDO Municipality",
            "constituency_id": 58,
            "constituency_name": "KOTIDO MUNICIPALITY"
        },
        {
            "id": 488,
            "name": "CENTRAL DIVISION",
            "type": "Municipal-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 73,
            "county_name": "NEBBI Municipality",
            "constituency_id": 94,
            "constituency_name": "NEBBI MUNICIPALITY"
        },
        {
            "id": 1213,
            "name": "CENTRAL DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 167,
            "county_name": "MITYANA Municipality",
            "constituency_id": 202,
            "constituency_name": "MITYANA MUNICIPALITY"
        },
        {
            "id": 1428,
            "name": "CENTRAL DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE",
            "county_id": 193,
            "county_name": "LUGAZI Municipality",
            "constituency_id": 229,
            "constituency_name": "LUGAZI MUNICIPALITY"
        },
        {
            "id": 44,
            "name": "CENTRAL DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 7,
            "county_name": "BUSHENYI-ISHAKA Municipality",
            "constituency_id": 7,
            "constituency_name": "BUSHENYI -ISHAKA MUNICIPALITY"
        },
        {
            "id": 271,
            "name": "CENTRAL DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 41,
            "county_name": "KISORO Municipality",
            "constituency_id": 53,
            "constituency_name": "KISORO MUNICIPALITY"
        },
        {
            "id": 86,
            "name": "CENTRAL DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 7,
            "district_name": "IGANGA",
            "county_id": 12,
            "county_name": "IGANGA Municipality",
            "constituency_id": 13,
            "constituency_name": "IGANGA MUNICIPALITY"
        },
        {
            "id": 163,
            "name": "CENTRAL DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 30,
            "county_name": "KAPCHORWA Municipality",
            "constituency_id": 38,
            "constituency_name": "KAPCHORWA MUNICIPALITY"
        },
        {
            "id": 503,
            "name": "CENTRAL DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 75,
            "county_name": "NTUNGAMO Municipality",
            "constituency_id": 96,
            "constituency_name": "NTUNGAMO MUNICIPALITY"
        },
        {
            "id": 293,
            "name": "CENTRAL DIVISION",
            "type": "Municipal-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 44,
            "county_name": "KITGUM Municipality",
            "constituency_id": 56,
            "constituency_name": "KITGUM MUNICIPALITY"
        },
        {
            "id": 390,
            "name": "CENTRAL DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 56,
            "county_name": "MASINDI Municipality",
            "constituency_id": 74,
            "constituency_name": "MASINDI MUNICIPALITY"
        },
        {
            "id": 220,
            "name": "CENTRAL DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 34,
            "county_name": "KASESE Municipality",
            "constituency_id": 44,
            "constituency_name": "KASESE MUNICIPALITY"
        },
        {
            "id": 250,
            "name": "CHAHAFI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 39,
            "county_name": "BUFUMBIRA County",
            "constituency_id": 49,
            "constituency_name": "BUFUMBIRA COUNTY EAST"
        },
        {
            "id": 259,
            "name": "CHAHI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 39,
            "county_name": "BUFUMBIRA County",
            "constituency_id": 51,
            "constituency_name": "BUFUMBIRA COUNTY SOUTH"
        },
        {
            "id": 11,
            "name": "CHEGERE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC",
            "county_id": 3,
            "county_name": "MARUZI NORTH County",
            "constituency_id": 3,
            "constituency_name": "MARUZI NORTH COUNTY"
        },
        {
            "id": 540,
            "name": "CHELEKURA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 80,
            "county_name": "AGULE County",
            "constituency_id": 101,
            "constituency_name": "AGULE COUNTY"
        },
        {
            "id": 167,
            "name": "CHEMA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 31,
            "county_name": "TINGEY County",
            "constituency_id": 39,
            "constituency_name": "TINGEY COUNTY"
        },
        {
            "id": 1028,
            "name": "CHEPKWASTA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 143,
            "county_name": "KONGASIS County",
            "constituency_id": 175,
            "constituency_name": "KONGASIS COUNTY"
        },
        {
            "id": 1741,
            "name": "CHEPSUKUNYA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 239,
            "county_name": "SOI County",
            "constituency_id": 276,
            "constituency_name": "SOI COUNTY"
        },
        {
            "id": 168,
            "name": "CHEPTERECH",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 31,
            "county_name": "TINGEY County",
            "constituency_id": 39,
            "constituency_name": "TINGEY COUNTY"
        },
        {
            "id": 1037,
            "name": "CHESOWER",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 144,
            "county_name": "TOO County",
            "constituency_id": 176,
            "constituency_name": "TOO COUNTY"
        },
        {
            "id": 664,
            "name": "CIFORO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 40,
            "district_name": "ADJUMANI",
            "county_id": 99,
            "county_name": "ADJUMANI WEST County",
            "constituency_id": 122,
            "constituency_name": "ADJUMANI WEST COUNTY"
        },
        {
            "id": 260,
            "name": "CYANIKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 39,
            "county_name": "BUFUMBIRA County",
            "constituency_id": 51,
            "constituency_name": "BUFUMBIRA COUNTY SOUTH"
        },
        {
            "id": 698,
            "name": "DABANI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 104,
            "county_name": "SAMIA BUGWE County",
            "constituency_id": 128,
            "constituency_name": "SAMIA BUGWE COUNTY NORTH"
        },
        {
            "id": 865,
            "name": "DAHAMI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 567,
            "name": "DDWANIRO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 84,
            "county_name": "BUYAMBA County",
            "constituency_id": 106,
            "constituency_name": "BUYAMBA COUNTY"
        },
        {
            "id": 238,
            "name": "DDWANIRO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA",
            "county_id": 37,
            "county_name": "KIBOGA EAST County",
            "constituency_id": 47,
            "constituency_name": "KIBOGA EAST COUNTY"
        },
        {
            "id": 2017,
            "name": "DEI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 121,
            "district_name": "PAKWACH",
            "county_id": 271,
            "county_name": "JONAM County",
            "constituency_id": 308,
            "constituency_name": "JONAM COUNTY"
        },
        {
            "id": 1574,
            "name": "DIIMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO",
            "county_id": 214,
            "county_name": "KIBANDA NORTH County",
            "constituency_id": 250,
            "constituency_name": "KIBANDA NORTH COUNTY"
        },
        {
            "id": 908,
            "name": "DIVISION A",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 126,
            "county_name": "ENTEBBE Municipality",
            "constituency_id": 157,
            "constituency_name": "ENTEBBE MUNICIPALITY"
        },
        {
            "id": 909,
            "name": "DIVISION B",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 126,
            "county_name": "ENTEBBE Municipality",
            "constituency_id": 157,
            "constituency_name": "ENTEBBE MUNICIPALITY"
        },
        {
            "id": 1295,
            "name": "DOKOLO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO",
            "county_id": 177,
            "county_name": "DOKOLO NORTH County",
            "constituency_id": 212,
            "constituency_name": "DOKOLO NORTH COUNTY"
        },
        {
            "id": 1298,
            "name": "DOKOLO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO",
            "county_id": 178,
            "county_name": "DOKOLO SOUTH County",
            "constituency_id": 213,
            "constituency_name": "DOKOLO SOUTH COUNTY"
        },
        {
            "id": 940,
            "name": "DRAJINI/ARAJIM",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 133,
            "county_name": "ARINGA SOUTH County",
            "constituency_id": 164,
            "constituency_name": "ARINGA SOUTH COUNTY"
        },
        {
            "id": 1349,
            "name": "DRAMBU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 184,
            "county_name": "MARACHA EAST County",
            "constituency_id": 220,
            "constituency_name": "MARACHA EAST COUNTY"
        },
        {
            "id": 1155,
            "name": "DRANYA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 66,
            "district_name": "KOBOKO",
            "county_id": 160,
            "county_name": "KOBOKO County",
            "constituency_id": 194,
            "constituency_name": "KOBOKO COUNTY"
        },
        {
            "id": 432,
            "name": "DUFILE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 29,
            "district_name": "MOYO",
            "county_id": 64,
            "county_name": "WEST MOYO County",
            "constituency_id": 83,
            "constituency_name": "WEST MOYO COUNTY"
        },
        {
            "id": 575,
            "name": "DYANGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 85,
            "county_name": "KOOKI County",
            "constituency_id": 107,
            "constituency_name": "KOOKI COUNTY"
        },
        {
            "id": 657,
            "name": "DZAIPI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 40,
            "district_name": "ADJUMANI",
            "county_id": 98,
            "county_name": "ADJUMANI EAST County",
            "constituency_id": 121,
            "constituency_name": "ADJUMANI EAST COUNTY"
        },
        {
            "id": 164,
            "name": "EAST DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 30,
            "county_name": "KAPCHORWA Municipality",
            "constituency_id": 38,
            "constituency_name": "KAPCHORWA MUNICIPALITY"
        },
        {
            "id": 466,
            "name": "EAST DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 69,
            "county_name": "MUBENDE Municipality",
            "constituency_id": 89,
            "constituency_name": "MUBENDE MUNICIPALITY"
        },
        {
            "id": 599,
            "name": "EASTERN DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 88,
            "county_name": "RUKUNGIRI Municipality",
            "constituency_id": 110,
            "constituency_name": "RUKUNGIRI MUNICIPALITY"
        },
        {
            "id": 504,
            "name": "EASTERN DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 75,
            "county_name": "NTUNGAMO Municipality",
            "constituency_id": 96,
            "constituency_name": "NTUNGAMO MUNICIPALITY"
        },
        {
            "id": 687,
            "name": "EASTERN DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 102,
            "county_name": "BUSIA Municipality",
            "constituency_id": 126,
            "constituency_name": "BUSIA MUNICIPALITY"
        },
        {
            "id": 667,
            "name": "EASTERN DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 100,
            "county_name": "BUGIRI Municipality",
            "constituency_id": 123,
            "constituency_name": "BUGIRI MUNICIPALITY"
        },
        {
            "id": 1248,
            "name": "ELEGU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU",
            "county_id": 172,
            "county_name": "KILAK NORTH County",
            "constituency_id": 207,
            "constituency_name": "KILAK NORTH COUNTY"
        },
        {
            "id": 885,
            "name": "ELGON",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 1078,
            "name": "ENDIINZI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 149,
            "county_name": "BUKANGA County",
            "constituency_id": 182,
            "constituency_name": "BUKANGA COUNTY"
        },
        {
            "id": 1079,
            "name": "ENDINZI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 149,
            "county_name": "BUKANGA County",
            "constituency_id": 182,
            "constituency_name": "BUKANGA COUNTY"
        },
        {
            "id": 1796,
            "name": "ENGAJU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU",
            "county_id": 249,
            "county_name": "BUHWEJU WEST County",
            "constituency_id": 285,
            "constituency_name": "BUHWEJU WEST COUNTY"
        },
        {
            "id": 2112,
            "name": "ENGARI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 131,
            "district_name": "KAZO",
            "county_id": 285,
            "county_name": "KAZO County",
            "constituency_id": 323,
            "constituency_name": "KAZO COUNTY"
        },
        {
            "id": 494,
            "name": "ERUSSI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 74,
            "county_name": "PADYERE County",
            "constituency_id": 95,
            "constituency_name": "PADYERE COUNTY"
        },
        {
            "id": 1004,
            "name": "ETAM",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 140,
            "county_name": "KIOGA NORTH County",
            "constituency_id": 172,
            "constituency_name": "KIOGA NORTH COUNTY"
        },
        {
            "id": 1005,
            "name": "ETAM TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 140,
            "county_name": "KIOGA NORTH County",
            "constituency_id": 172,
            "constituency_name": "KIOGA NORTH COUNTY"
        },
        {
            "id": 2146,
            "name": "EWAFA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 134,
            "district_name": "OBONGI",
            "county_id": 289,
            "county_name": "OBONGI County",
            "constituency_id": 327,
            "constituency_name": "OBONGI COUNTY"
        },
        {
            "id": 2133,
            "name": "EWANGA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 133,
            "district_name": "MADI-OKOLLO",
            "county_id": 287,
            "county_name": "LOWER MADI County",
            "constituency_id": 325,
            "constituency_name": "LOWER MADI COUNTY"
        },
        {
            "id": 1130,
            "name": "GADUMIRE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 156,
            "county_name": "BULAMOGI County",
            "constituency_id": 190,
            "constituency_name": "BULAMOGI COUNTY"
        },
        {
            "id": 1869,
            "name": "GALIBOLEKA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 769,
            "name": "GALIRAYA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA",
            "county_id": 116,
            "county_name": "BBALE County",
            "constituency_id": 140,
            "constituency_name": "BBALE COUNTY"
        },
        {
            "id": 169,
            "name": "GAMOGO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 31,
            "county_name": "TINGEY County",
            "constituency_id": 39,
            "constituency_name": "TINGEY COUNTY"
        },
        {
            "id": 1596,
            "name": "GAYAZA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 217,
            "county_name": "NTWETWE County",
            "constituency_id": 253,
            "constituency_name": "NTWETWE COUNTY"
        },
        {
            "id": 1815,
            "name": "GEREGERE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 252,
            "county_name": "AGAGO WEST County",
            "constituency_id": 288,
            "constituency_name": "AGAGO WEST COUNTY"
        },
        {
            "id": 708,
            "name": "GETOM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 105,
            "county_name": "NGARIAM County",
            "constituency_id": 130,
            "constituency_name": "NGARIAM COUNTY"
        },
        {
            "id": 2147,
            "name": "GIMARA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 134,
            "district_name": "OBONGI",
            "county_id": 289,
            "county_name": "OBONGI County",
            "constituency_id": 327,
            "constituency_name": "OBONGI COUNTY"
        },
        {
            "id": 544,
            "name": "GOGONYO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 81,
            "county_name": "GOGONYO County",
            "constituency_id": 102,
            "constituency_name": "GOGONYO COUNTY"
        },
        {
            "id": 1695,
            "name": "GOLI-GOLI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 234,
            "county_name": "KABWERI County",
            "constituency_id": 271,
            "constituency_name": "KABWERI COUNTY"
        },
        {
            "id": 485,
            "name": "GOMA DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 72,
            "county_name": "MUKONO Municipality",
            "constituency_id": 93,
            "constituency_name": "MUKONO MUNICIPALITY"
        },
        {
            "id": 917,
            "name": "GOMBE DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 129,
            "county_name": "NANSANA Municipality",
            "constituency_id": 160,
            "constituency_name": "NANSANA MUNICIPALITY"
        },
        {
            "id": 886,
            "name": "GOMBE GASAWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 1666,
            "name": "GOMBE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 99,
            "district_name": "BUTAMBALA",
            "county_id": 229,
            "county_name": "BUTAMBALA County",
            "constituency_id": 265,
            "constituency_name": "BUTAMBALA COUNTY"
        },
        {
            "id": 1803,
            "name": "GOT APWOYO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 110,
            "district_name": "NWOYA",
            "county_id": 250,
            "county_name": "NWOYA County",
            "constituency_id": 286,
            "constituency_name": "NWOYA COUNTY"
        },
        {
            "id": 1742,
            "name": "GREEK RIVER (KIRIKI)",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 239,
            "county_name": "SOI County",
            "constituency_id": 276,
            "constituency_name": "SOI COUNTY"
        },
        {
            "id": 1435,
            "name": "GUMPI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE",
            "county_id": 195,
            "county_name": "BUDIOPE EAST County",
            "constituency_id": 231,
            "constituency_name": "BUDIOPE EAST COUNTY"
        },
        {
            "id": 1255,
            "name": "GURU GURU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU",
            "county_id": 173,
            "county_name": "KILAK SOUTH County",
            "constituency_id": 208,
            "constituency_name": "KILAK SOUTH COUNTY"
        },
        {
            "id": 722,
            "name": "GUYAGUYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 107,
            "county_name": "USUK County",
            "constituency_id": 132,
            "constituency_name": "USUK COUNTY"
        },
        {
            "id": 609,
            "name": "GWERI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI",
            "county_id": 90,
            "county_name": "GWERI County",
            "constituency_id": 112,
            "constituency_name": "GWERI COUNTY"
        },
        {
            "id": 1932,
            "name": "HABUHUTU-MUGYERA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 301,
            "constituency_name": "RUBANDA COUNTY WEST"
        },
        {
            "id": 120,
            "name": "HAKIBALE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 1926,
            "name": "HAMUHAMBO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 300,
            "constituency_name": "RUBANDA COUNTY EAST"
        },
        {
            "id": 1927,
            "name": "HAMURWA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 300,
            "constituency_name": "RUBANDA COUNTY EAST"
        },
        {
            "id": 1928,
            "name": "HAMURWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 300,
            "constituency_name": "RUBANDA COUNTY EAST"
        },
        {
            "id": 1454,
            "name": "HAPUUYO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 198,
            "county_name": "KYAKA NORTH County",
            "constituency_id": 234,
            "constituency_name": "KYAKA NORTH COUNTY"
        },
        {
            "id": 1455,
            "name": "HAPUUYO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 198,
            "county_name": "KYAKA NORTH County",
            "constituency_id": 234,
            "constituency_name": "KYAKA NORTH COUNTY"
        },
        {
            "id": 20,
            "name": "HARUGALI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 5,
            "county_name": "BUGHENDERA County",
            "constituency_id": 5,
            "constituency_name": "BUGHENDERA COUNTY"
        },
        {
            "id": 121,
            "name": "HARUGONGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 199,
            "name": "HIMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 42,
            "constituency_name": "BUSONGORA COUNTY NORTH"
        },
        {
            "id": 1049,
            "name": "HIMUTU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 145,
            "county_name": "BUNYOLE EAST County",
            "constituency_id": 177,
            "constituency_name": "BUNYOLE EAST COUNTY"
        },
        {
            "id": 48,
            "name": "IBAARE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 8,
            "county_name": "IGARA County",
            "constituency_id": 8,
            "constituency_name": "IGARA COUNTY EAST"
        },
        {
            "id": 200,
            "name": "IBANDA-KYANYA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 42,
            "constituency_name": "BUSONGORA COUNTY NORTH"
        },
        {
            "id": 8,
            "name": "IBUJE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC",
            "county_id": 2,
            "county_name": "MARUZI County",
            "constituency_id": 2,
            "constituency_name": "MARUZI COUNTY"
        },
        {
            "id": 9,
            "name": "IBUJE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC",
            "county_id": 2,
            "county_name": "MARUZI County",
            "constituency_id": 2,
            "constituency_name": "MARUZI COUNTY"
        },
        {
            "id": 2034,
            "name": "IBULANKU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 123,
            "district_name": "BUGWERI",
            "county_id": 273,
            "county_name": "BUGWERI County",
            "constituency_id": 310,
            "constituency_name": "BUGWERI COUNTY"
        },
        {
            "id": 1326,
            "name": "ICHEME",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 217,
            "constituency_name": "OYAM COUNTY NORTH"
        },
        {
            "id": 1327,
            "name": "ICHEME TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 217,
            "constituency_name": "OYAM COUNTY NORTH"
        },
        {
            "id": 2035,
            "name": "IDUDI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 123,
            "district_name": "BUGWERI",
            "county_id": 273,
            "county_name": "BUGWERI County",
            "constituency_id": 310,
            "constituency_name": "BUGWERI COUNTY"
        },
        {
            "id": 1896,
            "name": "IGAYAZA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 260,
            "county_name": "BUGANGAIZI SOUTH County",
            "constituency_id": 296,
            "constituency_name": "BUGANGAIZI SOUTH COUNTY"
        },
        {
            "id": 2036,
            "name": "IGOMBE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 123,
            "district_name": "BUGWERI",
            "county_id": 273,
            "county_name": "BUGWERI County",
            "constituency_id": 310,
            "constituency_name": "BUGWERI COUNTY"
        },
        {
            "id": 1068,
            "name": "IGORORA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 147,
            "county_name": "IBANDA County",
            "constituency_id": 180,
            "constituency_name": "IBANDA COUNTY SOUTH"
        },
        {
            "id": 179,
            "name": "IHANDIRO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 40,
            "constituency_name": "BUKONJO COUNTY WEST"
        },
        {
            "id": 507,
            "name": "IHUNGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 76,
            "county_name": "KAJARA County",
            "constituency_id": 97,
            "constituency_name": "KAJARA COUNTY"
        },
        {
            "id": 1270,
            "name": "IKI-IKI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 175,
            "county_name": "IKI-IKI County",
            "constituency_id": 210,
            "constituency_name": "IKI-IKI COUNTY"
        },
        {
            "id": 1271,
            "name": "IKI-IKI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 175,
            "county_name": "IKI-IKI County",
            "constituency_id": 210,
            "constituency_name": "IKI-IKI COUNTY"
        },
        {
            "id": 1933,
            "name": "IKUMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 301,
            "constituency_name": "RUBANDA COUNTY WEST"
        },
        {
            "id": 1610,
            "name": "IKUMBYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 94,
            "district_name": "LUUKA",
            "county_id": 218,
            "county_name": "LUUKA NORTH County",
            "constituency_id": 254,
            "constituency_name": "LUUKA NORTH COUNTY"
        },
        {
            "id": 824,
            "name": "IMANYIRO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 148,
            "constituency_name": "BUNYA COUNTY WEST"
        },
        {
            "id": 2134,
            "name": "INDE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 133,
            "district_name": "MADI-OKOLLO",
            "county_id": 287,
            "county_name": "LOWER MADI County",
            "constituency_id": 325,
            "constituency_name": "LOWER MADI COUNTY"
        },
        {
            "id": 2082,
            "name": "INOMO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 127,
            "district_name": "KWANIA",
            "county_id": 280,
            "county_name": "KWANIA NORTH County",
            "constituency_id": 318,
            "constituency_name": "KWANIA NORTH COUNTY"
        },
        {
            "id": 2083,
            "name": "INOMO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 127,
            "district_name": "KWANIA",
            "county_id": 280,
            "county_name": "KWANIA NORTH County",
            "constituency_id": 318,
            "constituency_name": "KWANIA NORTH COUNTY"
        },
        {
            "id": 1770,
            "name": "IRIIRI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK",
            "county_id": 245,
            "county_name": "BOKORA EAST County",
            "constituency_id": 281,
            "constituency_name": "BOKORA EAST COUNTY"
        },
        {
            "id": 1615,
            "name": "IRONGO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 94,
            "district_name": "LUUKA",
            "county_id": 219,
            "county_name": "LUUKA SOUTH County",
            "constituency_id": 255,
            "constituency_name": "LUUKA SOUTH COUNTY"
        },
        {
            "id": 1436,
            "name": "IRUNDU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE",
            "county_id": 195,
            "county_name": "BUDIOPE EAST County",
            "constituency_id": 231,
            "constituency_name": "BUDIOPE EAST COUNTY"
        },
        {
            "id": 1437,
            "name": "IRUNDU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE",
            "county_id": 195,
            "county_name": "BUDIOPE EAST County",
            "constituency_id": 231,
            "constituency_name": "BUDIOPE EAST COUNTY"
        },
        {
            "id": 180,
            "name": "ISANGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 40,
            "constituency_name": "BUKONJO COUNTY WEST"
        },
        {
            "id": 45,
            "name": "ISHAKA DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 7,
            "county_name": "BUSHENYI-ISHAKA Municipality",
            "constituency_id": 7,
            "constituency_name": "BUSHENYI -ISHAKA MUNICIPALITY"
        },
        {
            "id": 1061,
            "name": "ISHONGORORO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 147,
            "county_name": "IBANDA County",
            "constituency_id": 179,
            "constituency_name": "IBANDA COUNTY NORTH"
        },
        {
            "id": 1062,
            "name": "ISHONGORORO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 147,
            "county_name": "IBANDA County",
            "constituency_id": 179,
            "constituency_name": "IBANDA COUNTY NORTH"
        },
        {
            "id": 1089,
            "name": "ISINGIRO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 184,
            "constituency_name": "ISINGIRO COUNTY NORTH"
        },
        {
            "id": 1850,
            "name": "ISUNGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 342,
            "name": "ITEK",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 22,
            "district_name": "LIRA",
            "county_id": 50,
            "county_name": "ERUTE County",
            "constituency_id": 63,
            "constituency_name": "ERUTE COUNTY SOUTH"
        },
        {
            "id": 658,
            "name": "ITIRIKWA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 40,
            "district_name": "ADJUMANI",
            "county_id": 98,
            "county_name": "ADJUMANI EAST County",
            "constituency_id": 121,
            "constituency_name": "ADJUMANI EAST COUNTY"
        },
        {
            "id": 515,
            "name": "ITOJO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 77,
            "county_name": "RUHAAMA County",
            "constituency_id": 98,
            "constituency_name": "RUHAAMA COUNTY"
        },
        {
            "id": 2148,
            "name": "ITULA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 134,
            "district_name": "OBONGI",
            "county_id": 289,
            "county_name": "OBONGI County",
            "constituency_id": 327,
            "constituency_name": "OBONGI COUNTY"
        },
        {
            "id": 1303,
            "name": "IVUKULA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 179,
            "county_name": "BUKONO County",
            "constituency_id": 214,
            "constituency_name": "BUKONO COUNTY"
        },
        {
            "id": 1304,
            "name": "IVUKULA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 179,
            "county_name": "BUKONO County",
            "constituency_id": 214,
            "constituency_name": "BUKONO COUNTY"
        },
        {
            "id": 681,
            "name": "IWEMBA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 125,
            "constituency_name": "BUKOOLI COUNTY NORTH"
        },
        {
            "id": 643,
            "name": "IYOLWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 96,
            "county_name": "WEST BUDAMA County",
            "constituency_id": 119,
            "constituency_name": "WEST BUDAMA COUNTY SOUTH"
        },
        {
            "id": 644,
            "name": "IYOLWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 96,
            "county_name": "WEST BUDAMA County",
            "constituency_id": 119,
            "constituency_name": "WEST BUDAMA COUNTY SOUTH"
        },
        {
            "id": 819,
            "name": "JAGUZI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 147,
            "constituency_name": "BUNYA COUNTY SOUTH"
        },
        {
            "id": 1502,
            "name": "JANGOKORO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 204,
            "county_name": "OKORO County",
            "constituency_id": 240,
            "constituency_name": "OKORO COUNTY"
        },
        {
            "id": 403,
            "name": "JEWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 58,
            "county_name": "BUNGOKHO County",
            "constituency_id": 76,
            "constituency_name": "BUNGOKHO COUNTY NORTH"
        },
        {
            "id": 495,
            "name": "JUPANGIRA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 74,
            "county_name": "PADYERE County",
            "constituency_id": 95,
            "constituency_name": "PADYERE COUNTY"
        },
        {
            "id": 1116,
            "name": "KAABONG EAST",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 154,
            "county_name": "DODOTH NORTH County",
            "constituency_id": 188,
            "constituency_name": "DODOTH NORTH COUNTY"
        },
        {
            "id": 1107,
            "name": "KAABONG TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 153,
            "county_name": "DODOTH EAST County",
            "constituency_id": 187,
            "constituency_name": "DODOTH EAST COUNTY"
        },
        {
            "id": 1108,
            "name": "KAABONG WEST",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 153,
            "county_name": "DODOTH EAST County",
            "constituency_id": 187,
            "constituency_name": "DODOTH EAST COUNTY"
        },
        {
            "id": 1224,
            "name": "KAASANGOMBE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 170,
            "county_name": "NAKASEKE SOUTH County",
            "constituency_id": 205,
            "constituency_name": "NAKASEKE SOUTH COUNTY"
        },
        {
            "id": 1175,
            "name": "KAATO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 982,
            "name": "KAAWACH",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 56,
            "district_name": "NAKAPIRIPIRIT",
            "county_id": 137,
            "county_name": "CHEKWII County",
            "constituency_id": 169,
            "constituency_name": "CHEKWII COUNTY"
        },
        {
            "id": 76,
            "name": "KABAALE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA",
            "county_id": 10,
            "county_name": "BUGAHYA County",
            "constituency_id": 11,
            "constituency_name": "BUGAHYA COUNTY"
        },
        {
            "id": 104,
            "name": "KABALE CENTRAL",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 17,
            "county_name": "KABALE Municipality",
            "constituency_id": 19,
            "constituency_name": "KABALE MUNICIPALITY"
        },
        {
            "id": 105,
            "name": "KABALE NORTHERN",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 17,
            "county_name": "KABALE Municipality",
            "constituency_id": 19,
            "constituency_name": "KABALE MUNICIPALITY"
        },
        {
            "id": 106,
            "name": "KABALE SOUTHERN",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 17,
            "county_name": "KABALE Municipality",
            "constituency_id": 19,
            "constituency_name": "KABALE MUNICIPALITY"
        },
        {
            "id": 1851,
            "name": "KABAMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 549,
            "name": "KABAMBIRO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 114,
            "county_name": "KIBALE County",
            "constituency_id": 104,
            "constituency_name": "KIBALE COUNTY"
        },
        {
            "id": 380,
            "name": "KABANGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 54,
            "county_name": "BUJENJE County",
            "constituency_id": 72,
            "constituency_name": "BUJENJE COUNTY"
        },
        {
            "id": 1389,
            "name": "KABARWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 188,
            "county_name": "BUKEDEA County",
            "constituency_id": 224,
            "constituency_name": "BUKEDEA COUNTY"
        },
        {
            "id": 224,
            "name": "KABASEKENDE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE",
            "county_id": 35,
            "county_name": "BUYANJA County",
            "constituency_id": 45,
            "constituency_name": "BUYANJA COUNTY"
        },
        {
            "id": 201,
            "name": "KABATUNDA-KIRABAHO TOWN COUNCI",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 42,
            "constituency_name": "BUSONGORA COUNTY NORTH"
        },
        {
            "id": 1038,
            "name": "KABEI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 144,
            "county_name": "TOO County",
            "constituency_id": 176,
            "constituency_name": "TOO COUNTY"
        },
        {
            "id": 1958,
            "name": "KABELAI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 122,
            "name": "KABENDE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 948,
            "name": "KABERAMAIDO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 54,
            "district_name": "KABERAMAIDO",
            "county_id": 134,
            "county_name": "KABERAMAIDO County",
            "constituency_id": 165,
            "constituency_name": "KABERAMAIDO COUNTY"
        },
        {
            "id": 949,
            "name": "KABERAMAIDO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 54,
            "district_name": "KABERAMAIDO",
            "county_id": 134,
            "county_name": "KABERAMAIDO County",
            "constituency_id": 165,
            "constituency_name": "KABERAMAIDO COUNTY"
        },
        {
            "id": 1090,
            "name": "KABEREBERE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 184,
            "constituency_name": "ISINGIRO COUNTY NORTH"
        },
        {
            "id": 170,
            "name": "KABEYWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 31,
            "county_name": "TINGEY County",
            "constituency_id": 39,
            "constituency_name": "TINGEY COUNTY"
        },
        {
            "id": 1091,
            "name": "KABINGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 184,
            "constituency_name": "ISINGIRO COUNTY NORTH"
        },
        {
            "id": 1756,
            "name": "KABIRA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 243,
            "county_name": "RUHINDA SOUTH County",
            "constituency_id": 279,
            "constituency_name": "RUHINDA SOUTH COUNTY"
        },
        {
            "id": 1979,
            "name": "KABIRA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA",
            "county_id": 268,
            "county_name": "KYOTERA County",
            "constituency_id": 305,
            "constituency_name": "KYOTERA COUNTY"
        },
        {
            "id": 1757,
            "name": "KABIRA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 243,
            "county_name": "RUHINDA SOUTH County",
            "constituency_id": 279,
            "constituency_name": "RUHINDA SOUTH COUNTY"
        },
        {
            "id": 1944,
            "name": "KABONERO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 550,
            "name": "KABUGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 114,
            "county_name": "KIBALE County",
            "constituency_id": 104,
            "constituency_name": "KIBALE COUNTY"
        },
        {
            "id": 2122,
            "name": "KABUJOGERA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 132,
            "district_name": "KITAGWENDA",
            "county_id": 286,
            "county_name": "KITAGWENDA County",
            "constituency_id": 324,
            "constituency_name": "KITAGWENDA COUNTY"
        },
        {
            "id": 1568,
            "name": "KABULASOKE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 91,
            "district_name": "GOMBA",
            "county_id": 213,
            "county_name": "GOMBA WEST County",
            "constituency_id": 249,
            "constituency_name": "GOMBA WEST COUNTY"
        },
        {
            "id": 1261,
            "name": "KABUNA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 174,
            "county_name": "BUDAKA County",
            "constituency_id": 209,
            "constituency_name": "BUDAKA COUNTY"
        },
        {
            "id": 2156,
            "name": "KABURA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 135,
            "district_name": "RWAMPARA",
            "county_id": 291,
            "county_name": "RWAMPARA EAST County",
            "constituency_id": 329,
            "constituency_name": "RWAMPARA EAST COUNTY"
        },
        {
            "id": 1103,
            "name": "KABUYANDA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 152,
            "county_name": "ISINGIRO WEST County",
            "constituency_id": 186,
            "constituency_name": "ISINGIRO WEST COUNTY"
        },
        {
            "id": 1104,
            "name": "KABUYANDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 152,
            "county_name": "ISINGIRO WEST County",
            "constituency_id": 186,
            "constituency_name": "ISINGIRO WEST COUNTY"
        },
        {
            "id": 761,
            "name": "KABUYE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 115,
            "county_name": "KIBALE EAST County",
            "constituency_id": 139,
            "constituency_name": "KIBALE EAST COUNTY"
        },
        {
            "id": 1959,
            "name": "KABWANGASI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 1960,
            "name": "KABWANGASI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 1696,
            "name": "KABWERI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 234,
            "county_name": "KABWERI County",
            "constituency_id": 271,
            "constituency_name": "KABWERI COUNTY"
        },
        {
            "id": 1689,
            "name": "KABWOHE DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 233,
            "county_name": "SHEEMA Municipality",
            "constituency_id": 270,
            "constituency_name": "SHEEMA MUNICIPALITY"
        },
        {
            "id": 2066,
            "name": "KABWOYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 126,
            "district_name": "KIKUUBE",
            "county_id": 277,
            "county_name": "BUHAGUZI County",
            "constituency_id": 315,
            "constituency_name": "BUHAGUZI COUNTY"
        },
        {
            "id": 1934,
            "name": "KACERERE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 301,
            "constituency_name": "RUBANDA COUNTY WEST"
        },
        {
            "id": 568,
            "name": "KACHEERA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 84,
            "county_name": "BUYAMBA County",
            "constituency_id": 106,
            "constituency_name": "BUYAMBA COUNTY"
        },
        {
            "id": 296,
            "name": "KACHERI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 297,
            "name": "KACHERI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 1262,
            "name": "KACHOMO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 174,
            "county_name": "BUDAKA County",
            "constituency_id": 209,
            "constituency_name": "BUDAKA COUNTY"
        },
        {
            "id": 1263,
            "name": "KACHOMO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 174,
            "county_name": "BUDAKA County",
            "constituency_id": 209,
            "constituency_name": "BUDAKA COUNTY"
        },
        {
            "id": 1050,
            "name": "KACHONGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 145,
            "county_name": "BUNYOLE EAST County",
            "constituency_id": 177,
            "constituency_name": "BUNYOLE EAST COUNTY"
        },
        {
            "id": 1398,
            "name": "KACHUMBALA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 189,
            "county_name": "KACHUMBALA County",
            "constituency_id": 225,
            "constituency_name": "KACHUMBALA COUNTY"
        },
        {
            "id": 1961,
            "name": "KACHURU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 1697,
            "name": "KADAMA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 234,
            "county_name": "KABWERI County",
            "constituency_id": 271,
            "constituency_name": "KABWERI COUNTY"
        },
        {
            "id": 1698,
            "name": "KADAMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 234,
            "county_name": "KABWERI County",
            "constituency_id": 271,
            "constituency_name": "KABWERI COUNTY"
        },
        {
            "id": 315,
            "name": "KADAMI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 47,
            "county_name": "KANYUM County",
            "constituency_id": 59,
            "constituency_name": "KANYUM COUNTY"
        },
        {
            "id": 1264,
            "name": "KADERUNA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 174,
            "county_name": "BUDAKA County",
            "constituency_id": 209,
            "constituency_name": "BUDAKA COUNTY"
        },
        {
            "id": 1272,
            "name": "KADIMUKOLI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 175,
            "county_name": "IKI-IKI County",
            "constituency_id": 210,
            "constituency_name": "IKI-IKI COUNTY"
        },
        {
            "id": 1962,
            "name": "KADOKOLENE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 1641,
            "name": "KADUNGULU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 224,
            "county_name": "KASILO County",
            "constituency_id": 260,
            "constituency_name": "KASILO COUNTY"
        },
        {
            "id": 1642,
            "name": "KADUNGULU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 224,
            "county_name": "KASILO County",
            "constituency_id": 260,
            "constituency_name": "KASILO COUNTY"
        },
        {
            "id": 516,
            "name": "KAFUNJO-MIRAMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 77,
            "county_name": "RUHAAMA County",
            "constituency_id": 98,
            "constituency_name": "RUHAAMA COUNTY"
        },
        {
            "id": 1852,
            "name": "KAGADI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 1853,
            "name": "KAGADI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 569,
            "name": "KAGAMBA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 84,
            "county_name": "BUYAMBA County",
            "constituency_id": 106,
            "constituency_name": "BUYAMBA COUNTY"
        },
        {
            "id": 1690,
            "name": "KAGANGO DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 233,
            "county_name": "SHEEMA Municipality",
            "constituency_id": 270,
            "constituency_name": "SHEEMA MUNICIPALITY"
        },
        {
            "id": 1092,
            "name": "KAGARAMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 184,
            "constituency_name": "ISINGIRO COUNTY NORTH"
        },
        {
            "id": 508,
            "name": "KAGARAMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 76,
            "county_name": "KAJARA County",
            "constituency_id": 97,
            "constituency_name": "KAJARA COUNTY"
        },
        {
            "id": 38,
            "name": "KAGHEMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 6,
            "county_name": "BWAMBA County",
            "constituency_id": 6,
            "constituency_name": "BWAMBA COUNTY"
        },
        {
            "id": 1658,
            "name": "KAGOLOGOLO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 98,
            "district_name": "BUKOMANSIMBI",
            "county_id": 227,
            "county_name": "BUKOMANSIMBI NORTH County",
            "constituency_id": 263,
            "constituency_name": "BUKOMANSIMBI NORTH COUNTY"
        },
        {
            "id": 411,
            "name": "KAGONGI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 27,
            "district_name": "MBARARA",
            "county_id": 59,
            "county_name": "KASHARI NORTH County",
            "constituency_id": 78,
            "constituency_name": "KASHARI NORTH COUNTY"
        },
        {
            "id": 1076,
            "name": "KAGONGO DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 148,
            "county_name": "IBANDA Municipality",
            "constituency_id": 181,
            "constituency_name": "IBANDA MUNICIPALITY"
        },
        {
            "id": 21,
            "name": "KAGUGU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 5,
            "county_name": "BUGHENDERA County",
            "constituency_id": 5,
            "constituency_name": "BUGHENDERA COUNTY"
        },
        {
            "id": 1438,
            "name": "KAGULU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE",
            "county_id": 195,
            "county_name": "BUDIOPE EAST County",
            "constituency_id": 231,
            "constituency_name": "BUDIOPE EAST COUNTY"
        },
        {
            "id": 1319,
            "name": "KAGULU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 181,
            "county_name": "BUSIKI NORTH County",
            "constituency_id": 216,
            "constituency_name": "BUSIKI NORTH COUNTY"
        },
        {
            "id": 143,
            "name": "KAGUMBA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 27,
            "county_name": "BUGABULA County",
            "constituency_id": 34,
            "constituency_name": "BUGABULA COUNTY NORTH"
        },
        {
            "id": 1699,
            "name": "KAGUMU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 234,
            "county_name": "KABWERI County",
            "constituency_id": 271,
            "constituency_name": "KABWERI COUNTY"
        },
        {
            "id": 1643,
            "name": "KAGWARA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 224,
            "county_name": "KASILO County",
            "constituency_id": 260,
            "constituency_name": "KASILO COUNTY"
        },
        {
            "id": 108,
            "name": "KAHARO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 18,
            "county_name": "NDORWA County",
            "constituency_id": 20,
            "constituency_name": "NDORWA COUNTY EAST"
        },
        {
            "id": 209,
            "name": "KAHOKYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 43,
            "constituency_name": "BUSONGORA COUNTY SOUTH"
        },
        {
            "id": 551,
            "name": "KAHUNGE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 114,
            "county_name": "KIBALE County",
            "constituency_id": 104,
            "constituency_name": "KIBALE COUNTY"
        },
        {
            "id": 552,
            "name": "KAHUNGE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 114,
            "county_name": "KIBALE County",
            "constituency_id": 104,
            "constituency_name": "KIBALE COUNTY"
        },
        {
            "id": 112,
            "name": "KAHUNGYE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 18,
            "county_name": "NDORWA County",
            "constituency_id": 21,
            "constituency_name": "NDORWA COUNTY WEST"
        },
        {
            "id": 904,
            "name": "KAJJANSI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 124,
            "county_name": "BUSIRO County",
            "constituency_id": 155,
            "constituency_name": "BUSIRO COUNTY SOUTH"
        },
        {
            "id": 1456,
            "name": "KAKABARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 198,
            "county_name": "KYAKA NORTH County",
            "constituency_id": 234,
            "constituency_name": "KYAKA NORTH COUNTY"
        },
        {
            "id": 1448,
            "name": "KAKABARA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 197,
            "county_name": "KYAKA CENTRAL County",
            "constituency_id": 233,
            "constituency_name": "KYAKA CENTRAL COUNTY"
        },
        {
            "id": 1109,
            "name": "KAKAMAR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 153,
            "county_name": "DODOTH EAST County",
            "constituency_id": 187,
            "constituency_name": "DODOTH EAST COUNTY"
        },
        {
            "id": 1082,
            "name": "KAKAMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 150,
            "county_name": "BUKANGA NORTH County",
            "constituency_id": 183,
            "constituency_name": "BUKANGA NORTH COUNTY"
        },
        {
            "id": 55,
            "name": "KAKANJU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 8,
            "county_name": "IGARA County",
            "constituency_id": 9,
            "constituency_name": "IGARA COUNTY WEST"
        },
        {
            "id": 2123,
            "name": "KAKASI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 132,
            "district_name": "KITAGWENDA",
            "county_id": 286,
            "county_name": "KITAGWENDA County",
            "constituency_id": 324,
            "constituency_name": "KITAGWENDA COUNTY"
        },
        {
            "id": 1901,
            "name": "KAKINDO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 261,
            "county_name": "BUGANGAIZI WEST County",
            "constituency_id": 297,
            "constituency_name": "BUGANGAIZI WEST COUNTY"
        },
        {
            "id": 1902,
            "name": "KAKINDO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 261,
            "county_name": "BUGANGAIZI WEST County",
            "constituency_id": 297,
            "constituency_name": "BUGANGAIZI WEST COUNTY"
        },
        {
            "id": 1678,
            "name": "KAKINDO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 232,
            "county_name": "SHEEMA County",
            "constituency_id": 268,
            "constituency_name": "SHEEMA COUNTY NORTH"
        },
        {
            "id": 1201,
            "name": "KAKINDU",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 165,
            "county_name": "BUSUJJU County",
            "constituency_id": 199,
            "constituency_name": "BUSUJJU COUNTY"
        },
        {
            "id": 1945,
            "name": "KAKINGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 98,
            "name": "KAKIRA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 8,
            "district_name": "JINJA",
            "county_id": 14,
            "county_name": "BUTEMBE County",
            "constituency_id": 16,
            "constituency_name": "BUTEMBE COUNTY"
        },
        {
            "id": 897,
            "name": "KAKIRI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 124,
            "county_name": "BUSIRO County",
            "constituency_id": 154,
            "constituency_name": "BUSIRO COUNTY NORTH"
        },
        {
            "id": 898,
            "name": "KAKIRI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 124,
            "county_name": "BUSIRO County",
            "constituency_id": 154,
            "constituency_name": "BUSIRO COUNTY NORTH"
        },
        {
            "id": 1273,
            "name": "KAKOLI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 175,
            "county_name": "IKI-IKI County",
            "constituency_id": 210,
            "constituency_name": "IKI-IKI COUNTY"
        },
        {
            "id": 983,
            "name": "KAKOMONGOLE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 56,
            "district_name": "NAKAPIRIPIRIT",
            "county_id": 137,
            "county_name": "CHEKWII County",
            "constituency_id": 169,
            "constituency_name": "CHEKWII COUNTY"
        },
        {
            "id": 733,
            "name": "KAKOOGE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 109,
            "county_name": "NAKASONGOLA County",
            "constituency_id": 134,
            "constituency_name": "NAKASONGOLA COUNTY"
        },
        {
            "id": 734,
            "name": "KAKOOGE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 109,
            "county_name": "NAKASONGOLA County",
            "constituency_id": 134,
            "constituency_name": "NAKASONGOLA COUNTY"
        },
        {
            "id": 1963,
            "name": "KAKORO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 1964,
            "name": "KAKORO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 517,
            "name": "KAKUKURU-RWENANURA TOWN COUNCI",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 77,
            "county_name": "RUHAAMA County",
            "constituency_id": 98,
            "constituency_name": "RUHAAMA COUNTY"
        },
        {
            "id": 1265,
            "name": "KAKULE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 174,
            "county_name": "BUDAKA County",
            "constituency_id": 209,
            "constituency_name": "BUDAKA COUNTY"
        },
        {
            "id": 1897,
            "name": "KAKUMIRO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 260,
            "county_name": "BUGANGAIZI SOUTH County",
            "constituency_id": 296,
            "constituency_name": "BUGANGAIZI SOUTH COUNTY"
        },
        {
            "id": 2093,
            "name": "KAKURE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 129,
            "district_name": "KALAKI",
            "county_id": 282,
            "county_name": "KALAKI County",
            "constituency_id": 320,
            "constituency_name": "KALAKI COUNTY"
        },
        {
            "id": 316,
            "name": "KAKURES",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 47,
            "county_name": "KANYUM County",
            "constituency_id": 59,
            "constituency_name": "KANYUM COUNTY"
        },
        {
            "id": 1700,
            "name": "KAKUTU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 234,
            "county_name": "KABWERI County",
            "constituency_id": 271,
            "constituency_name": "KABWERI COUNTY"
        },
        {
            "id": 1973,
            "name": "KAKUUTO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA",
            "county_id": 267,
            "county_name": "KAKUUTO County",
            "constituency_id": 304,
            "constituency_name": "KAKUUTO COUNTY"
        },
        {
            "id": 2100,
            "name": "KAKWANGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 130,
            "district_name": "KARENGA",
            "county_id": 283,
            "county_name": "DODOTH WEST County",
            "constituency_id": 321,
            "constituency_name": "DODOTH WEST COUNTY"
        },
        {
            "id": 346,
            "name": "KALAGALA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 51,
            "county_name": "BAMUNANIKA County",
            "constituency_id": 64,
            "constituency_name": "BAMUNANIKA COUNTY"
        },
        {
            "id": 624,
            "name": "KALAIT",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 94,
            "county_name": "TORORO SOUTH County",
            "constituency_id": 116,
            "constituency_name": "TORORO SOUTH COUNTY"
        },
        {
            "id": 2094,
            "name": "KALAKI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 129,
            "district_name": "KALAKI",
            "county_id": 282,
            "county_name": "KALAKI County",
            "constituency_id": 320,
            "constituency_name": "KALAKI COUNTY"
        },
        {
            "id": 2095,
            "name": "KALAKI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 129,
            "district_name": "KALAKI",
            "county_id": 282,
            "county_name": "KALAKI County",
            "constituency_id": 320,
            "constituency_name": "KALAKI COUNTY"
        },
        {
            "id": 1667,
            "name": "KALAMBA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 99,
            "district_name": "BUTAMBALA",
            "county_id": 229,
            "county_name": "BUTAMBALA County",
            "constituency_id": 265,
            "constituency_name": "BUTAMBALA COUNTY"
        },
        {
            "id": 1668,
            "name": "KALAMBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 99,
            "district_name": "BUTAMBALA",
            "county_id": 229,
            "county_name": "BUTAMBALA County",
            "constituency_id": 265,
            "constituency_name": "BUTAMBALA COUNTY"
        },
        {
            "id": 1206,
            "name": "KALANGAALO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 166,
            "county_name": "MITYANA County",
            "constituency_id": 200,
            "constituency_name": "MITYANA COUNTY NORTH"
        },
        {
            "id": 135,
            "name": "KALANGALA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 11,
            "district_name": "KALANGALA",
            "county_id": 20,
            "county_name": "BUJUMBA County",
            "constituency_id": 23,
            "constituency_name": "BUJUMBA COUNTY"
        },
        {
            "id": 1117,
            "name": "KALAPATA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 154,
            "county_name": "DODOTH NORTH County",
            "constituency_id": 188,
            "constituency_name": "DODOTH NORTH COUNTY"
        },
        {
            "id": 1118,
            "name": "KALAPATA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 154,
            "county_name": "DODOTH NORTH County",
            "constituency_id": 188,
            "constituency_name": "DODOTH NORTH COUNTY"
        },
        {
            "id": 1402,
            "name": "KALIIRO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 80,
            "district_name": "LYANTONDE",
            "county_id": 190,
            "county_name": "KABULA County",
            "constituency_id": 226,
            "constituency_name": "KABULA COUNTY"
        },
        {
            "id": 1403,
            "name": "KALIIRO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 80,
            "district_name": "LYANTONDE",
            "county_id": 190,
            "county_name": "KABULA County",
            "constituency_id": 226,
            "constituency_name": "KABULA COUNTY"
        },
        {
            "id": 1131,
            "name": "KALIRO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 156,
            "county_name": "BULAMOGI County",
            "constituency_id": 190,
            "constituency_name": "BULAMOGI COUNTY"
        },
        {
            "id": 1980,
            "name": "KALISIZO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA",
            "county_id": 268,
            "county_name": "KYOTERA County",
            "constituency_id": 305,
            "constituency_name": "KYOTERA COUNTY"
        },
        {
            "id": 1981,
            "name": "KALISIZO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA",
            "county_id": 268,
            "county_name": "KYOTERA County",
            "constituency_id": 305,
            "constituency_name": "KYOTERA COUNTY"
        },
        {
            "id": 454,
            "name": "KALONGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 67,
            "county_name": "BUWEKULA SOUTH County",
            "constituency_id": 87,
            "constituency_name": "BUWEKULA SOUTH COUNTY"
        },
        {
            "id": 735,
            "name": "KALONGO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 109,
            "county_name": "NAKASONGOLA County",
            "constituency_id": 134,
            "constituency_name": "NAKASONGOLA COUNTY"
        },
        {
            "id": 1829,
            "name": "KALONGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 254,
            "county_name": "AGAGO NORTH County",
            "constituency_id": 290,
            "constituency_name": "AGAGO NORTH COUNTY"
        },
        {
            "id": 736,
            "name": "KALUNGI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 109,
            "county_name": "NAKASONGOLA County",
            "constituency_id": 134,
            "constituency_name": "NAKASONGOLA COUNTY"
        },
        {
            "id": 1674,
            "name": "KALUNGU",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 100,
            "district_name": "KALUNGU",
            "county_id": 231,
            "county_name": "KALUNGU WEST County",
            "constituency_id": 267,
            "constituency_name": "KALUNGU WEST COUNTY"
        },
        {
            "id": 1675,
            "name": "KALUNGU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 100,
            "district_name": "KALUNGU",
            "county_id": 231,
            "county_name": "KALUNGU WEST County",
            "constituency_id": 267,
            "constituency_name": "KALUNGU WEST COUNTY"
        },
        {
            "id": 2057,
            "name": "KALWANA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 276,
            "county_name": "KASSANDA County",
            "constituency_id": 313,
            "constituency_name": "KASSANDA COUNTY NORTH"
        },
        {
            "id": 866,
            "name": "KAMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 317,
            "name": "KAMACA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 47,
            "county_name": "KANYUM County",
            "constituency_id": 59,
            "constituency_name": "KANYUM COUNTY"
        },
        {
            "id": 956,
            "name": "KAMBUGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 167,
            "constituency_name": "KINKIZI COUNTY EAST"
        },
        {
            "id": 957,
            "name": "KAMBUGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 167,
            "constituency_name": "KINKIZI COUNTY EAST"
        },
        {
            "id": 1332,
            "name": "KAMDINI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 218,
            "constituency_name": "OYAM COUNTY SOUTH"
        },
        {
            "id": 1333,
            "name": "KAMDINI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 218,
            "constituency_name": "OYAM COUNTY SOUTH"
        },
        {
            "id": 541,
            "name": "KAMEKE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 80,
            "county_name": "AGULE County",
            "constituency_id": 101,
            "constituency_name": "AGULE COUNTY"
        },
        {
            "id": 1274,
            "name": "KAMERUKA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 175,
            "county_name": "IKI-IKI County",
            "constituency_id": 210,
            "constituency_name": "IKI-IKI COUNTY"
        },
        {
            "id": 1039,
            "name": "KAMET",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 144,
            "county_name": "TOO County",
            "constituency_id": 176,
            "constituency_name": "TOO COUNTY"
        },
        {
            "id": 1123,
            "name": "KAMION",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 155,
            "county_name": "IK County",
            "constituency_id": 189,
            "constituency_name": "IK COUNTY"
        },
        {
            "id": 347,
            "name": "KAMIRA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 51,
            "county_name": "BAMUNANIKA County",
            "constituency_id": 64,
            "constituency_name": "BAMUNANIKA COUNTY"
        },
        {
            "id": 348,
            "name": "KAMIRA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 51,
            "county_name": "BAMUNANIKA County",
            "constituency_id": 64,
            "constituency_name": "BAMUNANIKA COUNTY"
        },
        {
            "id": 441,
            "name": "KAMMENGO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 30,
            "district_name": "MPIGI",
            "county_id": 65,
            "county_name": "MAWOKOTA County",
            "constituency_id": 84,
            "constituency_name": "MAWOKOTA COUNTY NORTH"
        },
        {
            "id": 1275,
            "name": "KAMONKOLI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 175,
            "county_name": "IKI-IKI County",
            "constituency_id": 210,
            "constituency_name": "IKI-IKI COUNTY"
        },
        {
            "id": 1276,
            "name": "KAMONKOLI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 175,
            "county_name": "IKI-IKI County",
            "constituency_id": 210,
            "constituency_name": "IKI-IKI COUNTY"
        },
        {
            "id": 298,
            "name": "KAMOR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 1551,
            "name": "KAMU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 210,
            "county_name": "ELGON NORTH County",
            "constituency_id": 246,
            "constituency_name": "ELGON NORTH COUNTY"
        },
        {
            "id": 1096,
            "name": "KAMUBEIZI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 185,
            "constituency_name": "ISINGIRO COUNTY SOUTH"
        },
        {
            "id": 1097,
            "name": "KAMUBEIZI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 185,
            "constituency_name": "ISINGIRO COUNTY SOUTH"
        },
        {
            "id": 611,
            "name": "KAMUDA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI",
            "county_id": 91,
            "county_name": "SOROTI County",
            "constituency_id": 113,
            "constituency_name": "SOROTI COUNTY"
        },
        {
            "id": 113,
            "name": "KAMUGANGUZI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 18,
            "county_name": "NDORWA County",
            "constituency_id": 21,
            "constituency_name": "NDORWA COUNTY WEST"
        },
        {
            "id": 560,
            "name": "KAMUGE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 83,
            "county_name": "PALLISA County",
            "constituency_id": 105,
            "constituency_name": "PALLISA COUNTY"
        },
        {
            "id": 561,
            "name": "KAMUGE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 83,
            "county_name": "PALLISA County",
            "constituency_id": 105,
            "constituency_name": "PALLISA COUNTY"
        },
        {
            "id": 2058,
            "name": "KAMULI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 276,
            "county_name": "KASSANDA County",
            "constituency_id": 313,
            "constituency_name": "KASSANDA COUNTY NORTH"
        },
        {
            "id": 1854,
            "name": "KAMUROZA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 1390,
            "name": "KAMUTUR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 188,
            "county_name": "BUKEDEA County",
            "constituency_id": 224,
            "constituency_name": "BUKEDEA COUNTY"
        },
        {
            "id": 553,
            "name": "KAMWENGE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 114,
            "county_name": "KIBALE County",
            "constituency_id": 104,
            "constituency_name": "KIBALE COUNTY"
        },
        {
            "id": 554,
            "name": "KAMWENGE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 114,
            "county_name": "KIBALE County",
            "constituency_id": 104,
            "constituency_name": "KIBALE COUNTY"
        },
        {
            "id": 2027,
            "name": "KAMWEZI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 122,
            "district_name": "RUKIGA",
            "county_id": 272,
            "county_name": "RUKIGA County",
            "constituency_id": 309,
            "constituency_name": "RUKIGA COUNTY"
        },
        {
            "id": 251,
            "name": "KANABA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 39,
            "county_name": "BUFUMBIRA County",
            "constituency_id": 49,
            "constituency_name": "BUFUMBIRA COUNTY EAST"
        },
        {
            "id": 299,
            "name": "KANAIR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 323,
            "name": "KANAPA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 48,
            "county_name": "KUMI County",
            "constituency_id": 60,
            "constituency_name": "KUMI COUNTY"
        },
        {
            "id": 2124,
            "name": "KANARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 132,
            "district_name": "KITAGWENDA",
            "county_id": 286,
            "county_name": "KITAGWENDA County",
            "constituency_id": 324,
            "constituency_name": "KITAGWENDA COUNTY"
        },
        {
            "id": 1632,
            "name": "KANARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 96,
            "district_name": "NTOROKO",
            "county_id": 223,
            "county_name": "NTOROKO County",
            "constituency_id": 259,
            "constituency_name": "NTOROKO COUNTY"
        },
        {
            "id": 1633,
            "name": "KANARA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 96,
            "district_name": "NTOROKO",
            "county_id": 223,
            "county_name": "NTOROKO County",
            "constituency_id": 259,
            "constituency_name": "NTOROKO COUNTY"
        },
        {
            "id": 1299,
            "name": "KANGAI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO",
            "county_id": 178,
            "county_name": "DOKOLO SOUTH County",
            "constituency_id": 213,
            "constituency_name": "DOKOLO SOUTH COUNTY"
        },
        {
            "id": 1300,
            "name": "KANGAI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO",
            "county_id": 178,
            "county_name": "DOKOLO SOUTH County",
            "constituency_id": 213,
            "constituency_name": "DOKOLO SOUTH COUNTY"
        },
        {
            "id": 1965,
            "name": "KANGINIMA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 1966,
            "name": "KANGINIMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 1511,
            "name": "KANGO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 205,
            "county_name": "ORA County",
            "constituency_id": 241,
            "constituency_name": "ORA COUNTY"
        },
        {
            "id": 1391,
            "name": "KANGOLE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 188,
            "county_name": "BUKEDEA County",
            "constituency_id": 224,
            "constituency_name": "BUKEDEA COUNTY"
        },
        {
            "id": 1771,
            "name": "KANGOLE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK",
            "county_id": 245,
            "county_name": "BOKORA EAST County",
            "constituency_id": 281,
            "constituency_name": "BOKORA EAST COUNTY"
        },
        {
            "id": 777,
            "name": "KANGULUMIRA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA",
            "county_id": 117,
            "county_name": "NTENJERU County",
            "constituency_id": 142,
            "constituency_name": "NTENJERU COUNTY SOUTH"
        },
        {
            "id": 778,
            "name": "KANGULUMIRA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA",
            "county_id": 117,
            "county_name": "NTENJERU County",
            "constituency_id": 142,
            "constituency_name": "NTENJERU COUNTY SOUTH"
        },
        {
            "id": 2113,
            "name": "KANONI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 131,
            "district_name": "KAZO",
            "county_id": 285,
            "county_name": "KAZO County",
            "constituency_id": 323,
            "constituency_name": "KAZO COUNTY"
        },
        {
            "id": 1564,
            "name": "KANONI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 91,
            "district_name": "GOMBA",
            "county_id": 212,
            "county_name": "GOMBA EAST County",
            "constituency_id": 248,
            "constituency_name": "GOMBA EAST COUNTY"
        },
        {
            "id": 958,
            "name": "KANUNGU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 167,
            "constituency_name": "KINKIZI COUNTY EAST"
        },
        {
            "id": 1870,
            "name": "KANYABEEBE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 1751,
            "name": "KANYABWANGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 242,
            "county_name": "RUHINDA NORTH County",
            "constituency_id": 278,
            "constituency_name": "RUHINDA NORTH COUNTY"
        },
        {
            "id": 971,
            "name": "KANYANTOROGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 168,
            "constituency_name": "KINKIZI COUNTY WEST"
        },
        {
            "id": 972,
            "name": "KANYANTOROGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 168,
            "constituency_name": "KINKIZI COUNTY WEST"
        },
        {
            "id": 1063,
            "name": "KANYARUGIRI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 147,
            "county_name": "IBANDA County",
            "constituency_id": 179,
            "constituency_name": "IBANDA COUNTY NORTH"
        },
        {
            "id": 1144,
            "name": "KANYARYERU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA",
            "county_id": 159,
            "county_name": "NYABUSHOZI County",
            "constituency_id": 193,
            "constituency_name": "NYABUSHOZI COUNTY"
        },
        {
            "id": 790,
            "name": "KANYEGARAMIRE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 144,
            "constituency_name": "MWENGE COUNTY NORTH"
        },
        {
            "id": 318,
            "name": "KANYUM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 47,
            "county_name": "KANYUM County",
            "constituency_id": 59,
            "constituency_name": "KANYUM COUNTY"
        },
        {
            "id": 1967,
            "name": "KANYUM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 319,
            "name": "KANYUM TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 47,
            "county_name": "KANYUM County",
            "constituency_id": 59,
            "constituency_name": "KANYUM COUNTY"
        },
        {
            "id": 80,
            "name": "KAPAAPI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA",
            "county_id": 11,
            "county_name": "KIGOROBYA County",
            "constituency_id": 12,
            "constituency_name": "KIGOROBYA COUNTY"
        },
        {
            "id": 2101,
            "name": "KAPEDO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 130,
            "district_name": "KARENGA",
            "county_id": 283,
            "county_name": "DODOTH WEST County",
            "constituency_id": 321,
            "constituency_name": "DODOTH WEST COUNTY"
        },
        {
            "id": 2102,
            "name": "KAPEDO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 130,
            "district_name": "KARENGA",
            "county_id": 283,
            "county_name": "DODOTH WEST County",
            "constituency_id": 321,
            "constituency_name": "DODOTH WEST COUNTY"
        },
        {
            "id": 1225,
            "name": "KAPEEKA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 170,
            "county_name": "NAKASEKE SOUTH County",
            "constituency_id": 205,
            "constituency_name": "NAKASEKE SOUTH COUNTY"
        },
        {
            "id": 244,
            "name": "KAPEKE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA",
            "county_id": 38,
            "county_name": "KIBOGA WEST County",
            "constituency_id": 48,
            "constituency_name": "KIBOGA WEST COUNTY"
        },
        {
            "id": 2046,
            "name": "KAPELEBYONG",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 124,
            "district_name": "KAPELEBYONG",
            "county_id": 274,
            "county_name": "KAPELEBYONG County",
            "constituency_id": 311,
            "constituency_name": "KAPELEBYONG COUNTY"
        },
        {
            "id": 2047,
            "name": "KAPELEBYONG TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 124,
            "district_name": "KAPELEBYONG",
            "county_id": 274,
            "county_name": "KAPELEBYONG County",
            "constituency_id": 311,
            "constituency_name": "KAPELEBYONG COUNTY"
        },
        {
            "id": 300,
            "name": "KAPETA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 1778,
            "name": "KAPIR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 108,
            "district_name": "NGORA",
            "county_id": 246,
            "county_name": "KAPIR County",
            "constituency_id": 282,
            "constituency_name": "KAPIR COUNTY"
        },
        {
            "id": 1029,
            "name": "KAPKOROS",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 143,
            "county_name": "KONGASIS County",
            "constituency_id": 175,
            "constituency_name": "KONGASIS COUNTY"
        },
        {
            "id": 1729,
            "name": "KAPKWATA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 1030,
            "name": "KAPNANDI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 143,
            "county_name": "KONGASIS County",
            "constituency_id": 175,
            "constituency_name": "KONGASIS COUNTY"
        },
        {
            "id": 1730,
            "name": "KAPNARKUT TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 1731,
            "name": "KAPRORON",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 1732,
            "name": "KAPRORON TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 1031,
            "name": "KAPSARUR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 143,
            "county_name": "KONGASIS County",
            "constituency_id": 175,
            "constituency_name": "KONGASIS COUNTY"
        },
        {
            "id": 171,
            "name": "KAPSINDA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 31,
            "county_name": "TINGEY County",
            "constituency_id": 39,
            "constituency_name": "TINGEY COUNTY"
        },
        {
            "id": 172,
            "name": "KAPTANYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 31,
            "county_name": "TINGEY County",
            "constituency_id": 39,
            "constituency_name": "TINGEY COUNTY"
        },
        {
            "id": 1032,
            "name": "KAPTERERWO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 143,
            "county_name": "KONGASIS County",
            "constituency_id": 175,
            "constituency_name": "KONGASIS COUNTY"
        },
        {
            "id": 1733,
            "name": "KAPTOYOY",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 1734,
            "name": "KAPTUM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 716,
            "name": "KAPUJAN",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 106,
            "county_name": "TOROMA County",
            "constituency_id": 131,
            "constituency_name": "TOROMA COUNTY"
        },
        {
            "id": 1968,
            "name": "KAPUNYASI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 682,
            "name": "KAPYANGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 125,
            "constituency_name": "BUKOOLI COUNTY NORTH"
        },
        {
            "id": 230,
            "name": "KARAMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE",
            "county_id": 36,
            "county_name": "BUYANJA EAST County",
            "constituency_id": 46,
            "constituency_name": "BUYANJA EAST COUNTY"
        },
        {
            "id": 181,
            "name": "KARAMBI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 40,
            "constituency_name": "BUKONJO COUNTY WEST"
        },
        {
            "id": 123,
            "name": "KARANGURA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 2106,
            "name": "KARENGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 130,
            "district_name": "KARENGA",
            "county_id": 284,
            "county_name": "NAPORE WEST County",
            "constituency_id": 322,
            "constituency_name": "NAPORE WEST COUNTY"
        },
        {
            "id": 2107,
            "name": "KARENGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 130,
            "district_name": "KARENGA",
            "county_id": 284,
            "county_name": "NAPORE WEST County",
            "constituency_id": 322,
            "constituency_name": "NAPORE WEST COUNTY"
        },
        {
            "id": 1414,
            "name": "KARITA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 81,
            "district_name": "AMUDAT",
            "county_id": 191,
            "county_name": "UPE County",
            "constituency_id": 227,
            "constituency_name": "UPE COUNTY"
        },
        {
            "id": 1415,
            "name": "KARITA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 81,
            "district_name": "AMUDAT",
            "county_id": 191,
            "county_name": "UPE County",
            "constituency_id": 227,
            "constituency_name": "UPE COUNTY"
        },
        {
            "id": 1634,
            "name": "KARUGUTU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 96,
            "district_name": "NTOROKO",
            "county_id": 223,
            "county_name": "NTOROKO County",
            "constituency_id": 259,
            "constituency_name": "NTOROKO COUNTY"
        },
        {
            "id": 1635,
            "name": "KARUGUTU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 96,
            "district_name": "NTOROKO",
            "county_id": 223,
            "county_name": "NTOROKO County",
            "constituency_id": 259,
            "constituency_name": "NTOROKO COUNTY"
        },
        {
            "id": 391,
            "name": "KARUJUBU DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 56,
            "county_name": "MASINDI Municipality",
            "constituency_id": 74,
            "constituency_name": "MASINDI MUNICIPALITY"
        },
        {
            "id": 1575,
            "name": "KARUMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO",
            "county_id": 214,
            "county_name": "KIBANDA NORTH County",
            "constituency_id": 250,
            "constituency_name": "KIBANDA NORTH COUNTY"
        },
        {
            "id": 1790,
            "name": "KARUNGU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU",
            "county_id": 248,
            "county_name": "BUHWEJU County",
            "constituency_id": 284,
            "constituency_name": "BUHWEJU COUNTY"
        },
        {
            "id": 210,
            "name": "KARUSANDARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 43,
            "constituency_name": "BUSONGORA COUNTY SOUTH"
        },
        {
            "id": 1982,
            "name": "KASAALI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA",
            "county_id": 268,
            "county_name": "KYOTERA County",
            "constituency_id": 305,
            "constituency_name": "KYOTERA COUNTY"
        },
        {
            "id": 1684,
            "name": "KASAANA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 232,
            "county_name": "SHEEMA County",
            "constituency_id": 269,
            "constituency_name": "SHEEMA COUNTY SOUTH"
        },
        {
            "id": 1404,
            "name": "KASAGAMA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 80,
            "district_name": "LYANTONDE",
            "county_id": 190,
            "county_name": "KABULA County",
            "constituency_id": 226,
            "constituency_name": "KABULA COUNTY"
        },
        {
            "id": 152,
            "name": "KASAMBIRA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 28,
            "county_name": "BUZAAYA County",
            "constituency_id": 36,
            "constituency_name": "BUZAAYA COUNTY"
        },
        {
            "id": 1898,
            "name": "KASAMBYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 260,
            "county_name": "BUGANGAIZI SOUTH County",
            "constituency_id": 296,
            "constituency_name": "BUGANGAIZI SOUTH COUNTY"
        },
        {
            "id": 459,
            "name": "KASAMBYA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 68,
            "county_name": "KASAMBYA County",
            "constituency_id": 88,
            "constituency_name": "KASAMBYA COUNTY"
        },
        {
            "id": 460,
            "name": "KASAMBYA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 68,
            "county_name": "KASAMBYA County",
            "constituency_id": 88,
            "constituency_name": "KASAMBYA COUNTY"
        },
        {
            "id": 907,
            "name": "KASANGATI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 125,
            "county_name": "KYADONDO County",
            "constituency_id": 156,
            "constituency_name": "KYADONDO COUNTY EAST"
        },
        {
            "id": 905,
            "name": "KASANJE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 124,
            "county_name": "BUSIRO County",
            "constituency_id": 155,
            "constituency_name": "BUSIRO COUNTY SOUTH"
        },
        {
            "id": 570,
            "name": "KASANKALA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 84,
            "county_name": "BUYAMBA County",
            "constituency_id": 106,
            "constituency_name": "BUYAMBA COUNTY"
        },
        {
            "id": 1974,
            "name": "KASASA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA",
            "county_id": 267,
            "county_name": "KAKUUTO County",
            "constituency_id": 304,
            "constituency_name": "KAKUUTO COUNTY"
        },
        {
            "id": 1706,
            "name": "KASASIRA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 235,
            "county_name": "KIBUKU County",
            "constituency_id": 272,
            "constituency_name": "KIBUKU COUNTY"
        },
        {
            "id": 1707,
            "name": "KASASIRA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 235,
            "county_name": "KIBUKU County",
            "constituency_id": 272,
            "constituency_name": "KIBUKU COUNTY"
        },
        {
            "id": 477,
            "name": "KASAWO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 71,
            "county_name": "NAKIFUMA County",
            "constituency_id": 92,
            "constituency_name": "NAKIFUMA COUNTY"
        },
        {
            "id": 478,
            "name": "KASAWO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 71,
            "county_name": "NAKIFUMA County",
            "constituency_id": 92,
            "constituency_name": "NAKIFUMA COUNTY"
        },
        {
            "id": 1735,
            "name": "KASEKO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 124,
            "name": "KASENDA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 125,
            "name": "KASENDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 1975,
            "name": "KASENSERO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA",
            "county_id": 267,
            "county_name": "KAKUUTO County",
            "constituency_id": 304,
            "constituency_name": "KAKUUTO COUNTY"
        },
        {
            "id": 173,
            "name": "KASEREM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 31,
            "county_name": "TINGEY County",
            "constituency_id": 39,
            "constituency_name": "TINGEY COUNTY"
        },
        {
            "id": 2028,
            "name": "KASHAMBYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 122,
            "district_name": "RUKIGA",
            "county_id": 272,
            "county_name": "RUKIGA County",
            "constituency_id": 309,
            "constituency_name": "RUKIGA COUNTY"
        },
        {
            "id": 412,
            "name": "KASHARE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 27,
            "district_name": "MBARARA",
            "county_id": 59,
            "county_name": "KASHARI NORTH County",
            "constituency_id": 78,
            "constituency_name": "KASHARI NORTH COUNTY"
        },
        {
            "id": 1935,
            "name": "KASHASHA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 301,
            "constituency_name": "RUBANDA COUNTY WEST"
        },
        {
            "id": 1745,
            "name": "KASHENSHERO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 241,
            "county_name": "RUHINDA County",
            "constituency_id": 277,
            "constituency_name": "RUHINDA COUNTY"
        },
        {
            "id": 1746,
            "name": "KASHENSHERO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 241,
            "county_name": "RUHINDA County",
            "constituency_id": 277,
            "constituency_name": "RUHINDA COUNTY"
        },
        {
            "id": 1791,
            "name": "KASHENYI-KAJANI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU",
            "county_id": 248,
            "county_name": "BUHWEJU County",
            "constituency_id": 284,
            "constituency_name": "BUHWEJU COUNTY"
        },
        {
            "id": 1141,
            "name": "KASHONGI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA",
            "county_id": 158,
            "county_name": "KASHONGI County",
            "constituency_id": 192,
            "constituency_name": "KASHONGI COUNTY"
        },
        {
            "id": 1691,
            "name": "KASHOZI DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 233,
            "county_name": "SHEEMA Municipality",
            "constituency_id": 270,
            "constituency_name": "SHEEMA MUNICIPALITY"
        },
        {
            "id": 1083,
            "name": "KASHUMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 150,
            "county_name": "BUKANGA NORTH County",
            "constituency_id": 183,
            "constituency_name": "BUKANGA NORTH COUNTY"
        },
        {
            "id": 1644,
            "name": "KASILO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 224,
            "county_name": "KASILO County",
            "constituency_id": 260,
            "constituency_name": "KASILO COUNTY"
        },
        {
            "id": 231,
            "name": "KASIMBI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE",
            "county_id": 36,
            "county_name": "BUYANJA EAST County",
            "constituency_id": 46,
            "constituency_name": "BUYANJA EAST COUNTY"
        },
        {
            "id": 22,
            "name": "KASITU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 5,
            "county_name": "BUGHENDERA County",
            "constituency_id": 5,
            "constituency_name": "BUGHENDERA COUNTY"
        },
        {
            "id": 562,
            "name": "KASODO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 83,
            "county_name": "PALLISA County",
            "constituency_id": 105,
            "constituency_name": "PALLISA COUNTY"
        },
        {
            "id": 1132,
            "name": "KASOKWE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 156,
            "county_name": "BULAMOGI County",
            "constituency_id": 190,
            "constituency_name": "BULAMOGI COUNTY"
        },
        {
            "id": 2059,
            "name": "KASSANDA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 276,
            "county_name": "KASSANDA County",
            "constituency_id": 313,
            "constituency_name": "KASSANDA COUNTY NORTH"
        },
        {
            "id": 2060,
            "name": "KASSANDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 276,
            "county_name": "KASSANDA County",
            "constituency_id": 313,
            "constituency_name": "KASSANDA COUNTY NORTH"
        },
        {
            "id": 1457,
            "name": "KASULE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 198,
            "county_name": "KYAKA NORTH County",
            "constituency_id": 234,
            "constituency_name": "KYAKA NORTH COUNTY"
        },
        {
            "id": 906,
            "name": "KATABI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 124,
            "county_name": "BUSIRO County",
            "constituency_id": 155,
            "constituency_name": "BUSIRO COUNTY SOUTH"
        },
        {
            "id": 1416,
            "name": "KATABOK",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 81,
            "district_name": "AMUDAT",
            "county_id": 191,
            "county_name": "UPE County",
            "constituency_id": 227,
            "constituency_name": "UPE COUNTY"
        },
        {
            "id": 638,
            "name": "KATAJULA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 96,
            "county_name": "WEST BUDAMA County",
            "constituency_id": 118,
            "constituency_name": "WEST BUDAMA COUNTY NORTH"
        },
        {
            "id": 709,
            "name": "KATAKWI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 105,
            "county_name": "NGARIAM County",
            "constituency_id": 130,
            "constituency_name": "NGARIAM COUNTY"
        },
        {
            "id": 710,
            "name": "KATAKWI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 105,
            "county_name": "NGARIAM County",
            "constituency_id": 130,
            "constituency_name": "NGARIAM COUNTY"
        },
        {
            "id": 1845,
            "name": "KATANDA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 112,
            "district_name": "RUBIRIZI",
            "county_id": 256,
            "county_name": "KATERERA County",
            "constituency_id": 292,
            "constituency_name": "KATERERA COUNTY"
        },
        {
            "id": 1946,
            "name": "KATEEBWA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 1747,
            "name": "KATENGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 241,
            "county_name": "RUHINDA County",
            "constituency_id": 277,
            "constituency_name": "RUHINDA COUNTY"
        },
        {
            "id": 1846,
            "name": "KATERERA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 112,
            "district_name": "RUBIRIZI",
            "county_id": 256,
            "county_name": "KATERERA County",
            "constituency_id": 292,
            "constituency_name": "KATERERA COUNTY"
        },
        {
            "id": 1847,
            "name": "KATERERA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 112,
            "district_name": "RUBIRIZI",
            "county_id": 256,
            "county_name": "KATERERA County",
            "constituency_id": 292,
            "constituency_name": "KATERERA COUNTY"
        },
        {
            "id": 1649,
            "name": "KATETA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 226,
            "county_name": "SERERE County",
            "constituency_id": 262,
            "constituency_name": "SERERE COUNTY"
        },
        {
            "id": 959,
            "name": "KATETE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 167,
            "constituency_name": "KINKIZI COUNTY EAST"
        },
        {
            "id": 1119,
            "name": "KATHILE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 154,
            "county_name": "DODOTH NORTH County",
            "constituency_id": 188,
            "constituency_name": "DODOTH NORTH COUNTY"
        },
        {
            "id": 1120,
            "name": "KATHILE SOUTH",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 154,
            "county_name": "DODOTH NORTH County",
            "constituency_id": 188,
            "constituency_name": "DODOTH NORTH COUNTY"
        },
        {
            "id": 1121,
            "name": "KATHILE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 154,
            "county_name": "DODOTH NORTH County",
            "constituency_id": 188,
            "constituency_name": "DODOTH NORTH COUNTY"
        },
        {
            "id": 356,
            "name": "KATIKAMU",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 52,
            "county_name": "KATIKAMU County",
            "constituency_id": 65,
            "constituency_name": "KATIKAMU COUNTY NORTH"
        },
        {
            "id": 1885,
            "name": "KATIKARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 259,
            "county_name": "BUGANGAIZI EAST County",
            "constituency_id": 295,
            "constituency_name": "BUGANGAIZI EAST COUNTY"
        },
        {
            "id": 427,
            "name": "KATIKEKILE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 28,
            "district_name": "MOROTO",
            "county_id": 62,
            "county_name": "TEPETH County",
            "constituency_id": 81,
            "constituency_name": "TEPETH COUNTY"
        },
        {
            "id": 603,
            "name": "KATINE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI",
            "county_id": 89,
            "county_name": "DAKABELA County",
            "constituency_id": 111,
            "constituency_name": "DAKABELA COUNTY"
        },
        {
            "id": 1277,
            "name": "KATIRA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 175,
            "county_name": "IKI-IKI County",
            "constituency_id": 210,
            "constituency_name": "IKI-IKI COUNTY"
        },
        {
            "id": 782,
            "name": "KATOOKE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 118,
            "county_name": "MWENGE CENTRAL County",
            "constituency_id": 143,
            "constituency_name": "MWENGE CENTRAL COUNTY"
        },
        {
            "id": 783,
            "name": "KATOOKE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 118,
            "county_name": "MWENGE CENTRAL County",
            "constituency_id": 143,
            "constituency_name": "MWENGE CENTRAL COUNTY"
        },
        {
            "id": 471,
            "name": "KATOSI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 70,
            "county_name": "MUKONO County",
            "constituency_id": 91,
            "constituency_name": "MUKONO COUNTY SOUTH"
        },
        {
            "id": 365,
            "name": "KATOVU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 105,
            "district_name": "LWENGO",
            "county_id": 240,
            "county_name": "BUKOTO County",
            "constituency_id": 68,
            "constituency_name": "BUKOTO COUNTY WEST"
        },
        {
            "id": 2164,
            "name": "KATRINI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 143,
            "district_name": "TEREGO",
            "county_id": 307,
            "county_name": "TEREGO WEST County",
            "constituency_id": 347,
            "constituency_name": "TEREGO WEST COUNTY"
        },
        {
            "id": 1469,
            "name": "KATUM",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 200,
            "county_name": "LAMWO County",
            "constituency_id": 236,
            "constituency_name": "LAMWO COUNTY"
        },
        {
            "id": 114,
            "name": "KATUNA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 18,
            "county_name": "NDORWA County",
            "constituency_id": 21,
            "constituency_name": "NDORWA COUNTY WEST"
        },
        {
            "id": 1839,
            "name": "KATUNGURU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 112,
            "district_name": "RUBIRIZI",
            "county_id": 255,
            "county_name": "BUNYARUGURU County",
            "constituency_id": 291,
            "constituency_name": "BUNYARUGURU COUNTY"
        },
        {
            "id": 737,
            "name": "KATUUGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 109,
            "county_name": "NAKASONGOLA County",
            "constituency_id": 134,
            "constituency_name": "NAKASONGOLA COUNTY"
        },
        {
            "id": 756,
            "name": "KATWE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 113,
            "county_name": "MAWOGOLA WEST County",
            "constituency_id": 138,
            "constituency_name": "MAWOGOLA WEST COUNTY"
        },
        {
            "id": 212,
            "name": "KATWE - KABATORO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 43,
            "constituency_name": "BUSONGORA COUNTY SOUTH"
        },
        {
            "id": 545,
            "name": "KAUKURA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 81,
            "county_name": "GOGONYO County",
            "constituency_id": 102,
            "constituency_name": "GOGONYO COUNTY"
        },
        {
            "id": 2103,
            "name": "KAWALAKOL",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 130,
            "district_name": "KARENGA",
            "county_id": 283,
            "county_name": "DODOTH WEST County",
            "constituency_id": 321,
            "constituency_name": "DODOTH WEST COUNTY"
        },
        {
            "id": 750,
            "name": "KAWANDA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 112,
            "county_name": "MAWOGOLA NORTH County",
            "constituency_id": 137,
            "constituency_name": "MAWOGOLA NORTH COUNTY"
        },
        {
            "id": 1429,
            "name": "KAWOLO DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE",
            "county_id": 193,
            "county_name": "LUGAZI Municipality",
            "constituency_id": 229,
            "constituency_name": "LUGAZI MUNICIPALITY"
        },
        {
            "id": 174,
            "name": "KAWOWO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 31,
            "county_name": "TINGEY County",
            "constituency_id": 39,
            "constituency_name": "TINGEY COUNTY"
        },
        {
            "id": 447,
            "name": "KAYABWE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 30,
            "district_name": "MPIGI",
            "county_id": 65,
            "county_name": "MAWOKOTA County",
            "constituency_id": 85,
            "constituency_name": "MAWOKOTA COUNTY SOUTH"
        },
        {
            "id": 232,
            "name": "KAYANJA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE",
            "county_id": 36,
            "county_name": "BUYANJA EAST County",
            "constituency_id": 46,
            "constituency_name": "BUYANJA EAST COUNTY"
        },
        {
            "id": 455,
            "name": "KAYEBE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 67,
            "county_name": "BUWEKULA SOUTH County",
            "constituency_id": 87,
            "constituency_name": "BUWEKULA SOUTH COUNTY"
        },
        {
            "id": 245,
            "name": "KAYERA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA",
            "county_id": 38,
            "county_name": "KIBOGA WEST County",
            "constituency_id": 48,
            "constituency_name": "KIBOGA WEST COUNTY"
        },
        {
            "id": 770,
            "name": "KAYONZA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA",
            "county_id": 116,
            "county_name": "BBALE County",
            "constituency_id": 140,
            "constituency_name": "BBALE COUNTY"
        },
        {
            "id": 973,
            "name": "KAYONZA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 168,
            "constituency_name": "KINKIZI COUNTY WEST"
        },
        {
            "id": 530,
            "name": "KAYONZA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 79,
            "county_name": "RUSHENYI County",
            "constituency_id": 100,
            "constituency_name": "RUSHENYI COUNTY"
        },
        {
            "id": 625,
            "name": "KAYORO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 94,
            "county_name": "TORORO SOUTH County",
            "constituency_id": 116,
            "constituency_name": "TORORO SOUTH COUNTY"
        },
        {
            "id": 775,
            "name": "KAYUNGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA",
            "county_id": 117,
            "county_name": "NTENJERU County",
            "constituency_id": 141,
            "constituency_name": "NTENJERU COUNTY NORTH"
        },
        {
            "id": 776,
            "name": "KAYUNGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA",
            "county_id": 117,
            "county_name": "NTENJERU County",
            "constituency_id": 141,
            "constituency_name": "NTENJERU COUNTY NORTH"
        },
        {
            "id": 965,
            "name": "KAYUNGWE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 167,
            "constituency_name": "KINKIZI COUNTY EAST"
        },
        {
            "id": 1461,
            "name": "KAZINGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 199,
            "county_name": "KYAKA SOUTH County",
            "constituency_id": 235,
            "constituency_name": "KYAKA SOUTH COUNTY"
        },
        {
            "id": 2114,
            "name": "KAZO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 131,
            "district_name": "KAZO",
            "county_id": 285,
            "county_name": "KAZO County",
            "constituency_id": 323,
            "constituency_name": "KAZO COUNTY"
        },
        {
            "id": 2115,
            "name": "KAZO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 131,
            "district_name": "KAZO",
            "county_id": 285,
            "county_name": "KAZO County",
            "constituency_id": 323,
            "constituency_name": "KAZO COUNTY"
        },
        {
            "id": 738,
            "name": "KAZWAMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 109,
            "county_name": "NAKASONGOLA County",
            "constituency_id": 134,
            "constituency_name": "NAKASONGOLA COUNTY"
        },
        {
            "id": 588,
            "name": "KEBISONI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 86,
            "county_name": "RUBABO County",
            "constituency_id": 108,
            "constituency_name": "RUBABO COUNTY"
        },
        {
            "id": 589,
            "name": "KEBISONI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 86,
            "county_name": "RUBABO County",
            "constituency_id": 108,
            "constituency_name": "RUBABO COUNTY"
        },
        {
            "id": 932,
            "name": "KEI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 132,
            "county_name": "ARINGA NORTH County",
            "constituency_id": 163,
            "constituency_name": "ARINGA NORTH COUNTY"
        },
        {
            "id": 1069,
            "name": "KEIHANGARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 147,
            "county_name": "IBANDA County",
            "constituency_id": 180,
            "constituency_name": "IBANDA COUNTY SOUTH"
        },
        {
            "id": 1701,
            "name": "KENKEBU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 234,
            "county_name": "KABWERI County",
            "constituency_id": 271,
            "constituency_name": "KABWERI COUNTY"
        },
        {
            "id": 1145,
            "name": "KENSHUNGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA",
            "county_id": 159,
            "county_name": "NYABUSHOZI County",
            "constituency_id": 193,
            "constituency_name": "NYABUSHOZI COUNTY"
        },
        {
            "id": 1156,
            "name": "KERI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 66,
            "district_name": "KOBOKO",
            "county_id": 160,
            "county_name": "KOBOKO County",
            "constituency_id": 194,
            "constituency_name": "KOBOKO COUNTY"
        },
        {
            "id": 933,
            "name": "KERWA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 132,
            "county_name": "ARINGA NORTH County",
            "constituency_id": 163,
            "constituency_name": "ARINGA NORTH COUNTY"
        },
        {
            "id": 1176,
            "name": "KHABUTOOLA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 1305,
            "name": "KIBAALE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 179,
            "county_name": "BUKONO County",
            "constituency_id": 214,
            "constituency_name": "BUKONO COUNTY"
        },
        {
            "id": 576,
            "name": "KIBAALE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 85,
            "county_name": "KOOKI County",
            "constituency_id": 107,
            "constituency_name": "KOOKI COUNTY"
        },
        {
            "id": 225,
            "name": "KIBAALE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE",
            "county_id": 35,
            "county_name": "BUYANJA County",
            "constituency_id": 45,
            "constituency_name": "BUYANJA COUNTY"
        },
        {
            "id": 556,
            "name": "KIBALE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 82,
            "county_name": "KIBALE County",
            "constituency_id": 103,
            "constituency_name": "KIBALE COUNTY"
        },
        {
            "id": 1306,
            "name": "KIBALE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 179,
            "county_name": "BUKONO County",
            "constituency_id": 214,
            "constituency_name": "BUKONO COUNTY"
        },
        {
            "id": 557,
            "name": "KIBALE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 82,
            "county_name": "KIBALE County",
            "constituency_id": 103,
            "constituency_name": "KIBALE COUNTY"
        },
        {
            "id": 461,
            "name": "KIBALINGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 68,
            "county_name": "KASAMBYA County",
            "constituency_id": 88,
            "constituency_name": "KASAMBYA COUNTY"
        },
        {
            "id": 577,
            "name": "KIBANDA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 85,
            "county_name": "KOOKI County",
            "constituency_id": 107,
            "constituency_name": "KOOKI COUNTY"
        },
        {
            "id": 126,
            "name": "KIBASI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 509,
            "name": "KIBATSI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 76,
            "county_name": "KAJARA County",
            "constituency_id": 97,
            "constituency_name": "KAJARA COUNTY"
        },
        {
            "id": 1669,
            "name": "KIBIBI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 99,
            "district_name": "BUTAMBALA",
            "county_id": 229,
            "county_name": "BUTAMBALA County",
            "constituency_id": 265,
            "constituency_name": "BUTAMBALA COUNTY"
        },
        {
            "id": 246,
            "name": "KIBIGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA",
            "county_id": 38,
            "county_name": "KIBOGA WEST County",
            "constituency_id": 48,
            "constituency_name": "KIBOGA WEST COUNTY"
        },
        {
            "id": 1947,
            "name": "KIBIITO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 1948,
            "name": "KIBIITO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 1886,
            "name": "KIBIJJO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 259,
            "county_name": "BUGANGAIZI EAST County",
            "constituency_id": 295,
            "constituency_name": "BUGANGAIZI EAST COUNTY"
        },
        {
            "id": 1663,
            "name": "KIBINGE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 98,
            "district_name": "BUKOMANSIMBI",
            "county_id": 228,
            "county_name": "BUKOMANSIMBI SOUTH County",
            "constituency_id": 264,
            "constituency_name": "BUKOMANSIMBI SOUTH COUNTY"
        },
        {
            "id": 247,
            "name": "KIBOGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA",
            "county_id": 38,
            "county_name": "KIBOGA WEST County",
            "constituency_id": 48,
            "constituency_name": "KIBOGA WEST COUNTY"
        },
        {
            "id": 115,
            "name": "KIBUGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 18,
            "county_name": "NDORWA County",
            "constituency_id": 21,
            "constituency_name": "NDORWA COUNTY WEST"
        },
        {
            "id": 1708,
            "name": "KIBUKU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 235,
            "county_name": "KIBUKU County",
            "constituency_id": 272,
            "constituency_name": "KIBUKU COUNTY"
        },
        {
            "id": 1709,
            "name": "KIBUKU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 235,
            "county_name": "KIBUKU County",
            "constituency_id": 272,
            "constituency_name": "KIBUKU COUNTY"
        },
        {
            "id": 1636,
            "name": "KIBUUKU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 96,
            "district_name": "NTOROKO",
            "county_id": 223,
            "county_name": "NTOROKO County",
            "constituency_id": 259,
            "constituency_name": "NTOROKO COUNTY"
        },
        {
            "id": 2125,
            "name": "KICHECHE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 132,
            "district_name": "KITAGWENDA",
            "county_id": 286,
            "county_name": "KITAGWENDA County",
            "constituency_id": 324,
            "constituency_name": "KITAGWENDA COUNTY"
        },
        {
            "id": 1576,
            "name": "KICHWABUGINGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO",
            "county_id": 214,
            "county_name": "KIBANDA NORTH County",
            "constituency_id": 250,
            "constituency_name": "KIBANDA NORTH COUNTY"
        },
        {
            "id": 1840,
            "name": "KICHWAMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 112,
            "district_name": "RUBIRIZI",
            "county_id": 255,
            "county_name": "BUNYARUGURU County",
            "constituency_id": 291,
            "constituency_name": "BUNYARUGURU COUNTY"
        },
        {
            "id": 1855,
            "name": "KICUCURA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 1070,
            "name": "KICUZI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 147,
            "county_name": "IBANDA County",
            "constituency_id": 180,
            "constituency_name": "IBANDA COUNTY SOUTH"
        },
        {
            "id": 127,
            "name": "KICWAMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 88,
            "name": "KIDAAGO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 7,
            "district_name": "IGANGA",
            "county_id": 13,
            "county_name": "KIGULU County",
            "constituency_id": 14,
            "constituency_name": "KIGULU COUNTY NORTH"
        },
        {
            "id": 2108,
            "name": "KIDEPO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 130,
            "district_name": "KARENGA",
            "county_id": 284,
            "county_name": "NAPORE WEST County",
            "constituency_id": 322,
            "constituency_name": "NAPORE WEST COUNTY"
        },
        {
            "id": 1444,
            "name": "KIDERA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE",
            "county_id": 196,
            "county_name": "BUDIOPE WEST County",
            "constituency_id": 232,
            "constituency_name": "BUDIOPE WEST COUNTY"
        },
        {
            "id": 1445,
            "name": "KIDERA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE",
            "county_id": 196,
            "county_name": "BUDIOPE WEST County",
            "constituency_id": 232,
            "constituency_name": "BUDIOPE WEST COUNTY"
        },
        {
            "id": 1645,
            "name": "KIDETOK TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 225,
            "county_name": "PINGIRE County",
            "constituency_id": 261,
            "constituency_name": "PINGIRE COUNTY"
        },
        {
            "id": 1392,
            "name": "KIDONGOLE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 188,
            "county_name": "BUKEDEA County",
            "constituency_id": 224,
            "constituency_name": "BUKEDEA COUNTY"
        },
        {
            "id": 578,
            "name": "KIFAMBA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 85,
            "county_name": "KOOKI County",
            "constituency_id": 107,
            "constituency_name": "KOOKI COUNTY"
        },
        {
            "id": 1569,
            "name": "KIFAMPA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 91,
            "district_name": "GOMBA",
            "county_id": 213,
            "county_name": "GOMBA WEST County",
            "constituency_id": 249,
            "constituency_name": "GOMBA WEST COUNTY"
        },
        {
            "id": 791,
            "name": "KIFUKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 144,
            "constituency_name": "MWENGE COUNTY NORTH"
        },
        {
            "id": 1458,
            "name": "KIGAMBO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 198,
            "county_name": "KYAKA NORTH County",
            "constituency_id": 234,
            "constituency_name": "KYAKA NORTH COUNTY"
        },
        {
            "id": 2061,
            "name": "KIGANDA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 276,
            "county_name": "KASSANDA County",
            "constituency_id": 314,
            "constituency_name": "KASSANDA COUNTY SOUTH"
        },
        {
            "id": 2062,
            "name": "KIGANDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 276,
            "county_name": "KASSANDA County",
            "constituency_id": 314,
            "constituency_name": "KASSANDA COUNTY SOUTH"
        },
        {
            "id": 814,
            "name": "KIGANDALO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 146,
            "constituency_name": "BUNYA COUNTY EAST"
        },
        {
            "id": 1591,
            "name": "KIGANDO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 216,
            "county_name": "BUTEMBA County",
            "constituency_id": 252,
            "constituency_name": "BUTEMBA COUNTY"
        },
        {
            "id": 462,
            "name": "KIGANDO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 68,
            "county_name": "KASAMBYA County",
            "constituency_id": 88,
            "constituency_name": "KASAMBYA COUNTY"
        },
        {
            "id": 1659,
            "name": "KIGANGAZZI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 98,
            "district_name": "BUKOMANSIMBI",
            "county_id": 227,
            "county_name": "BUKOMANSIMBI NORTH County",
            "constituency_id": 263,
            "constituency_name": "BUKOMANSIMBI NORTH COUNTY"
        },
        {
            "id": 81,
            "name": "KIGANJA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA",
            "county_id": 11,
            "county_name": "KIGOROBYA County",
            "constituency_id": 12,
            "constituency_name": "KIGOROBYA COUNTY"
        },
        {
            "id": 803,
            "name": "KIGARAALE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 145,
            "constituency_name": "MWENGE COUNTY SOUTH"
        },
        {
            "id": 1679,
            "name": "KIGARAMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 232,
            "county_name": "SHEEMA County",
            "constituency_id": 268,
            "constituency_name": "SHEEMA COUNTY NORTH"
        },
        {
            "id": 82,
            "name": "KIGOROBYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA",
            "county_id": 11,
            "county_name": "KIGOROBYA County",
            "constituency_id": 12,
            "constituency_name": "KIGOROBYA COUNTY"
        },
        {
            "id": 83,
            "name": "KIGOROBYA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA",
            "county_id": 11,
            "county_name": "KIGOROBYA County",
            "constituency_id": 12,
            "constituency_name": "KIGOROBYA COUNTY"
        },
        {
            "id": 792,
            "name": "KIGOYERA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 144,
            "constituency_name": "MWENGE COUNTY NORTH"
        },
        {
            "id": 392,
            "name": "KIGULYA DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 56,
            "county_name": "MASINDI Municipality",
            "constituency_id": 74,
            "constituency_name": "MASINDI MUNICIPALITY"
        },
        {
            "id": 1582,
            "name": "KIGUMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO",
            "county_id": 215,
            "county_name": "KIBANDA SOUTH County",
            "constituency_id": 251,
            "constituency_name": "KIBANDA SOUTH COUNTY"
        },
        {
            "id": 1583,
            "name": "KIGUMBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO",
            "county_id": 215,
            "county_name": "KIBANDA SOUTH County",
            "constituency_id": 251,
            "constituency_name": "KIBANDA SOUTH COUNTY"
        },
        {
            "id": 1285,
            "name": "KIGWERA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 73,
            "district_name": "BULIISA",
            "county_id": 176,
            "county_name": "BULIISA County",
            "constituency_id": 211,
            "constituency_name": "BULIISA COUNTY"
        },
        {
            "id": 1752,
            "name": "KIGYENDE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 242,
            "county_name": "RUHINDA NORTH County",
            "constituency_id": 278,
            "constituency_name": "RUHINDA NORTH COUNTY"
        },
        {
            "id": 960,
            "name": "KIHANDA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 167,
            "constituency_name": "KINKIZI COUNTY EAST"
        },
        {
            "id": 970,
            "name": "KIHEMBE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 168,
            "constituency_name": "KINKIZI COUNTY WEST"
        },
        {
            "id": 974,
            "name": "KIHIIHI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 168,
            "constituency_name": "KINKIZI COUNTY WEST"
        },
        {
            "id": 975,
            "name": "KIHIIHI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 168,
            "constituency_name": "KINKIZI COUNTY WEST"
        },
        {
            "id": 1287,
            "name": "KIHUNGYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 73,
            "district_name": "BULIISA",
            "county_id": 176,
            "county_name": "BULIISA County",
            "constituency_id": 211,
            "constituency_name": "BULIISA COUNTY"
        },
        {
            "id": 804,
            "name": "KIHUURA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 145,
            "constituency_name": "MWENGE COUNTY SOUTH"
        },
        {
            "id": 1903,
            "name": "KIJANGI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 261,
            "county_name": "BUGANGAIZI WEST County",
            "constituency_id": 297,
            "constituency_name": "BUGANGAIZI WEST COUNTY"
        },
        {
            "id": 2053,
            "name": "KIJJUNA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 275,
            "county_name": "BUKUYA County",
            "constituency_id": 312,
            "constituency_name": "BUKUYA COUNTY"
        },
        {
            "id": 1341,
            "name": "KIJOMORO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 183,
            "county_name": "MARACHA County",
            "constituency_id": 219,
            "constituency_name": "MARACHA COUNTY"
        },
        {
            "id": 84,
            "name": "KIJONGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA",
            "county_id": 11,
            "county_name": "KIGOROBYA County",
            "constituency_id": 12,
            "constituency_name": "KIGOROBYA COUNTY"
        },
        {
            "id": 1064,
            "name": "KIJONGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 147,
            "county_name": "IBANDA County",
            "constituency_id": 179,
            "constituency_name": "IBANDA COUNTY NORTH"
        },
        {
            "id": 382,
            "name": "KIJUNJUBWA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 55,
            "county_name": "BURULI County",
            "constituency_id": 73,
            "constituency_name": "BURULI COUNTY"
        },
        {
            "id": 383,
            "name": "KIJUNJUBWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 55,
            "county_name": "BURULI County",
            "constituency_id": 73,
            "constituency_name": "BURULI COUNTY"
        },
        {
            "id": 128,
            "name": "KIJURA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 1098,
            "name": "KIKAGATE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 185,
            "constituency_name": "ISINGIRO COUNTY SOUTH"
        },
        {
            "id": 1099,
            "name": "KIKAGATE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 185,
            "constituency_name": "ISINGIRO COUNTY SOUTH"
        },
        {
            "id": 1215,
            "name": "KIKAMULO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 168,
            "county_name": "NAKASEKE CENTRAL County",
            "constituency_id": 203,
            "constituency_name": "NAKASEKE CENTRAL COUNTY"
        },
        {
            "id": 1207,
            "name": "KIKANDWA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 166,
            "county_name": "MITYANA County",
            "constituency_id": 200,
            "constituency_name": "MITYANA COUNTY NORTH"
        },
        {
            "id": 1146,
            "name": "KIKATSI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA",
            "county_id": 159,
            "county_name": "NYABUSHOZI County",
            "constituency_id": 193,
            "constituency_name": "NYABUSHOZI COUNTY"
        },
        {
            "id": 1362,
            "name": "KIKHOLO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 185,
            "county_name": "BUSHIGAI County",
            "constituency_id": 221,
            "constituency_name": "BUSHIGAI COUNTY"
        },
        {
            "id": 129,
            "name": "KIKO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 887,
            "name": "KIKOBERO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 1904,
            "name": "KIKOORA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 261,
            "county_name": "BUGANGAIZI WEST County",
            "constituency_id": 297,
            "constituency_name": "BUGANGAIZI WEST COUNTY"
        },
        {
            "id": 2067,
            "name": "KIKUUBE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 126,
            "district_name": "KIKUUBE",
            "county_id": 277,
            "county_name": "BUHAGUZI County",
            "constituency_id": 315,
            "constituency_name": "BUHAGUZI COUNTY"
        },
        {
            "id": 1905,
            "name": "KIKWAYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 261,
            "county_name": "BUGANGAIZI WEST County",
            "constituency_id": 297,
            "constituency_name": "BUGANGAIZI WEST COUNTY"
        },
        {
            "id": 1071,
            "name": "KIKYENKYE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 147,
            "county_name": "IBANDA County",
            "constituency_id": 180,
            "constituency_name": "IBANDA COUNTY SOUTH"
        },
        {
            "id": 349,
            "name": "KIKYUSA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 51,
            "county_name": "BAMUNANIKA County",
            "constituency_id": 64,
            "constituency_name": "BAMUNANIKA COUNTY"
        },
        {
            "id": 350,
            "name": "KIKYUSA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 51,
            "county_name": "BAMUNANIKA County",
            "constituency_id": 64,
            "constituency_name": "BAMUNANIKA COUNTY"
        },
        {
            "id": 211,
            "name": "KILEMBE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 43,
            "constituency_name": "BUSONGORA COUNTY SOUTH"
        },
        {
            "id": 1177,
            "name": "KIMALULI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 384,
            "name": "KIMENGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 55,
            "county_name": "BURULI County",
            "constituency_id": 73,
            "constituency_name": "BURULI COUNTY"
        },
        {
            "id": 479,
            "name": "KIMENYEDDE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 71,
            "county_name": "NAKIFUMA County",
            "constituency_id": 92,
            "constituency_name": "NAKIFUMA COUNTY"
        },
        {
            "id": 961,
            "name": "KINAABA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 167,
            "constituency_name": "KINKIZI COUNTY EAST"
        },
        {
            "id": 369,
            "name": "KINGO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 105,
            "district_name": "LWENGO",
            "county_id": 240,
            "county_name": "BUKOTO County",
            "constituency_id": 69,
            "constituency_name": "BUKOTO COUNTY SOUTH"
        },
        {
            "id": 1147,
            "name": "KINONI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA",
            "county_id": 159,
            "county_name": "NYABUSHOZI County",
            "constituency_id": 193,
            "constituency_name": "NYABUSHOZI COUNTY"
        },
        {
            "id": 1220,
            "name": "KINONI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 169,
            "county_name": "NAKASEKE NORTH County",
            "constituency_id": 204,
            "constituency_name": "NAKASEKE NORTH COUNTY"
        },
        {
            "id": 2152,
            "name": "KINONI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 135,
            "district_name": "RWAMPARA",
            "county_id": 290,
            "county_name": "RWAMPARA County",
            "constituency_id": 328,
            "constituency_name": "RWAMPARA COUNTY"
        },
        {
            "id": 370,
            "name": "KINONI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 105,
            "district_name": "LWENGO",
            "county_id": 240,
            "county_name": "BUKOTO County",
            "constituency_id": 69,
            "constituency_name": "BUKOTO COUNTY SOUTH"
        },
        {
            "id": 1405,
            "name": "KINUUKA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 80,
            "district_name": "LYANTONDE",
            "county_id": 190,
            "county_name": "KABULA County",
            "constituency_id": 226,
            "constituency_name": "KABULA COUNTY"
        },
        {
            "id": 186,
            "name": "KINYAMESEKE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 41,
            "constituency_name": "BUKONZO COUNTY EAST"
        },
        {
            "id": 1856,
            "name": "KINYARUGONJO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 1221,
            "name": "KINYOGOGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 169,
            "county_name": "NAKASEKE NORTH County",
            "constituency_id": 204,
            "constituency_name": "NAKASEKE NORTH COUNTY"
        },
        {
            "id": 911,
            "name": "KIRA DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 127,
            "county_name": "KIRA Municipality",
            "constituency_id": 158,
            "constituency_name": "KIRA MUNICIPALITY"
        },
        {
            "id": 650,
            "name": "KIREWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 97,
            "county_name": "WEST BUDAMA NORTH EAST County",
            "constituency_id": 120,
            "constituency_name": "WEST BUDAMA NORTH EAST COUNTY"
        },
        {
            "id": 1702,
            "name": "KIRIKA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 234,
            "county_name": "KABWERI County",
            "constituency_id": 271,
            "constituency_name": "KABWERI COUNTY"
        },
        {
            "id": 962,
            "name": "KIRIMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 167,
            "constituency_name": "KINKIZI COUNTY EAST"
        },
        {
            "id": 442,
            "name": "KIRINGENTE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 30,
            "district_name": "MPIGI",
            "county_id": 65,
            "county_name": "MAWOKOTA County",
            "constituency_id": 84,
            "constituency_name": "MAWOKOTA COUNTY NORTH"
        },
        {
            "id": 1238,
            "name": "KIRU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 1848,
            "name": "KIRUGU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 112,
            "district_name": "RUBIRIZI",
            "county_id": 256,
            "county_name": "KATERERA County",
            "constituency_id": 292,
            "constituency_name": "KATERERA COUNTY"
        },
        {
            "id": 1148,
            "name": "KIRUHURA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA",
            "county_id": 159,
            "county_name": "NYABUSHOZI County",
            "constituency_id": 193,
            "constituency_name": "NYABUSHOZI COUNTY"
        },
        {
            "id": 385,
            "name": "KIRULI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 55,
            "county_name": "BURULI County",
            "constituency_id": 73,
            "constituency_name": "BURULI COUNTY"
        },
        {
            "id": 1983,
            "name": "KIRUMBA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA",
            "county_id": 268,
            "county_name": "KYOTERA County",
            "constituency_id": 305,
            "constituency_name": "KYOTERA COUNTY"
        },
        {
            "id": 39,
            "name": "KIRUMYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 6,
            "county_name": "BWAMBA County",
            "constituency_id": 6,
            "constituency_name": "BWAMBA COUNTY"
        },
        {
            "id": 265,
            "name": "KIRUNDO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 40,
            "county_name": "BUKIMBIRI County",
            "constituency_id": 52,
            "constituency_name": "BUKIMBIRI COUNTY"
        },
        {
            "id": 451,
            "name": "KIRUUMA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 66,
            "county_name": "BUWEKULA County",
            "constituency_id": 86,
            "constituency_name": "BUWEKULA COUNTY"
        },
        {
            "id": 1577,
            "name": "KIRYANDONGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO",
            "county_id": 214,
            "county_name": "KIBANDA NORTH County",
            "constituency_id": 250,
            "constituency_name": "KIBANDA NORTH COUNTY"
        },
        {
            "id": 1578,
            "name": "KIRYANDONGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO",
            "county_id": 214,
            "county_name": "KIBANDA NORTH County",
            "constituency_id": 250,
            "constituency_name": "KIBANDA NORTH COUNTY"
        },
        {
            "id": 1857,
            "name": "KIRYANGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 1597,
            "name": "KIRYANNONGO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 217,
            "county_name": "NTWETWE County",
            "constituency_id": 253,
            "constituency_name": "NTWETWE COUNTY"
        },
        {
            "id": 1598,
            "name": "KISALA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 217,
            "county_name": "NTWETWE County",
            "constituency_id": 253,
            "constituency_name": "NTWETWE COUNTY"
        },
        {
            "id": 371,
            "name": "KISEKKA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 105,
            "district_name": "LWENGO",
            "county_id": 240,
            "county_name": "BUKOTO County",
            "constituency_id": 69,
            "constituency_name": "BUKOTO COUNTY SOUTH"
        },
        {
            "id": 1899,
            "name": "KISENGWE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 260,
            "county_name": "BUGANGAIZI SOUTH County",
            "constituency_id": 296,
            "constituency_name": "BUGANGAIZI SOUTH COUNTY"
        },
        {
            "id": 1887,
            "name": "KISIITA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 259,
            "county_name": "BUGANGAIZI EAST County",
            "constituency_id": 295,
            "constituency_name": "BUGANGAIZI EAST COUNTY"
        },
        {
            "id": 1888,
            "name": "KISIITA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 259,
            "county_name": "BUGANGAIZI EAST County",
            "constituency_id": 295,
            "constituency_name": "BUGANGAIZI EAST COUNTY"
        },
        {
            "id": 1133,
            "name": "KISINDA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 156,
            "county_name": "BULAMOGI County",
            "constituency_id": 190,
            "constituency_name": "BULAMOGI COUNTY"
        },
        {
            "id": 187,
            "name": "KISINGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 41,
            "constituency_name": "BUKONZO COUNTY EAST"
        },
        {
            "id": 188,
            "name": "KISINGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 41,
            "constituency_name": "BUKONZO COUNTY EAST"
        },
        {
            "id": 805,
            "name": "KISOJO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 145,
            "constituency_name": "MWENGE COUNTY SOUTH"
        },
        {
            "id": 806,
            "name": "KISOJO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 145,
            "constituency_name": "MWENGE COUNTY SOUTH"
        },
        {
            "id": 639,
            "name": "KISOKO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 96,
            "county_name": "WEST BUDAMA County",
            "constituency_id": 118,
            "constituency_name": "WEST BUDAMA COUNTY NORTH"
        },
        {
            "id": 1949,
            "name": "KISOMORO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 153,
            "name": "KISOZI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 28,
            "county_name": "BUZAAYA County",
            "constituency_id": 36,
            "constituency_name": "BUZAAYA COUNTY"
        },
        {
            "id": 154,
            "name": "KISOZI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 28,
            "county_name": "BUZAAYA County",
            "constituency_id": 36,
            "constituency_name": "BUZAAYA COUNTY"
        },
        {
            "id": 40,
            "name": "KISUBBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 6,
            "county_name": "BWAMBA County",
            "constituency_id": 6,
            "constituency_name": "BWAMBA COUNTY"
        },
        {
            "id": 85,
            "name": "KISUKUMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA",
            "county_id": 11,
            "county_name": "KIGOROBYA County",
            "constituency_id": 12,
            "constituency_name": "KIGOROBYA COUNTY"
        },
        {
            "id": 189,
            "name": "KITABU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 41,
            "constituency_name": "BUKONZO COUNTY EAST"
        },
        {
            "id": 1685,
            "name": "KITAGATA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 232,
            "county_name": "SHEEMA County",
            "constituency_id": 269,
            "constituency_name": "SHEEMA COUNTY SOUTH"
        },
        {
            "id": 1686,
            "name": "KITAGATA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 232,
            "county_name": "SHEEMA County",
            "constituency_id": 269,
            "constituency_name": "SHEEMA COUNTY SOUTH"
        },
        {
            "id": 2126,
            "name": "KITAGWENDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 132,
            "district_name": "KITAGWENDA",
            "county_id": 286,
            "county_name": "KITAGWENDA County",
            "constituency_id": 324,
            "constituency_name": "KITAGWENDA COUNTY"
        },
        {
            "id": 1906,
            "name": "KITAIHUKA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 261,
            "county_name": "BUGANGAIZI WEST County",
            "constituency_id": 297,
            "constituency_name": "BUGANGAIZI WEST COUNTY"
        },
        {
            "id": 1660,
            "name": "KITANDA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 98,
            "district_name": "BUKOMANSIMBI",
            "county_id": 227,
            "county_name": "BUKOMANSIMBI NORTH County",
            "constituency_id": 263,
            "constituency_name": "BUKOMANSIMBI NORTH COUNTY"
        },
        {
            "id": 1736,
            "name": "KITAWOI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 148,
            "name": "KITAYUNJWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 27,
            "county_name": "BUGABULA County",
            "constituency_id": 35,
            "constituency_name": "BUGABULA COUNTY SOUTH"
        },
        {
            "id": 793,
            "name": "KITEGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 144,
            "constituency_name": "MWENGE COUNTY NORTH"
        },
        {
            "id": 456,
            "name": "KITENGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 67,
            "county_name": "BUWEKULA SOUTH County",
            "constituency_id": 87,
            "constituency_name": "BUWEKULA SOUTH COUNTY"
        },
        {
            "id": 274,
            "name": "KITENY",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 42,
            "county_name": "CHUA EAST County",
            "constituency_id": 54,
            "constituency_name": "CHUA EAST COUNTY"
        },
        {
            "id": 286,
            "name": "KITGUM MATIDI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 43,
            "county_name": "CHUA WEST County",
            "constituency_id": 55,
            "constituency_name": "CHUA WEST COUNTY"
        },
        {
            "id": 287,
            "name": "KITGUM MATIDI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 43,
            "county_name": "CHUA WEST County",
            "constituency_id": 55,
            "constituency_name": "CHUA WEST COUNTY"
        },
        {
            "id": 182,
            "name": "KITHOLU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 40,
            "constituency_name": "BUKONJO COUNTY WEST"
        },
        {
            "id": 183,
            "name": "KITHOMA-KANYATSI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 40,
            "constituency_name": "BUKONJO COUNTY WEST"
        },
        {
            "id": 771,
            "name": "KITIMBWA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA",
            "county_id": 116,
            "county_name": "BBALE County",
            "constituency_id": 140,
            "constituency_name": "BBALE COUNTY"
        },
        {
            "id": 772,
            "name": "KITIMBWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA",
            "county_id": 116,
            "county_name": "BBALE County",
            "constituency_id": 140,
            "constituency_name": "BBALE COUNTY"
        },
        {
            "id": 77,
            "name": "KITOBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA",
            "county_id": 10,
            "county_name": "BUGAHYA County",
            "constituency_id": 11,
            "constituency_name": "BUGAHYA COUNTY"
        },
        {
            "id": 202,
            "name": "KITSWAMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 42,
            "constituency_name": "BUSONGORA COUNTY NORTH"
        },
        {
            "id": 203,
            "name": "KITSWAMBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 42,
            "constituency_name": "BUSONGORA COUNTY NORTH"
        },
        {
            "id": 1216,
            "name": "KITTO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 168,
            "county_name": "NAKASEKE CENTRAL County",
            "constituency_id": 203,
            "constituency_name": "NAKASEKE CENTRAL COUNTY"
        },
        {
            "id": 116,
            "name": "KITUMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 18,
            "county_name": "NDORWA County",
            "constituency_id": 21,
            "constituency_name": "NDORWA COUNTY WEST"
        },
        {
            "id": 2054,
            "name": "KITUMBI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 275,
            "county_name": "BUKUYA County",
            "constituency_id": 312,
            "constituency_name": "BUKUYA COUNTY"
        },
        {
            "id": 448,
            "name": "KITUNTU",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 30,
            "district_name": "MPIGI",
            "county_id": 65,
            "county_name": "MAWOKOTA County",
            "constituency_id": 85,
            "constituency_name": "MAWOKOTA COUNTY SOUTH"
        },
        {
            "id": 1142,
            "name": "KITURA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA",
            "county_id": 158,
            "county_name": "KASHONGI County",
            "constituency_id": 192,
            "constituency_name": "KASHONGI COUNTY"
        },
        {
            "id": 1710,
            "name": "KITUTI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 235,
            "county_name": "KIBUKU County",
            "constituency_id": 272,
            "constituency_name": "KIBUKU COUNTY"
        },
        {
            "id": 524,
            "name": "KITWE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 78,
            "county_name": "RUHAAMA EAST County",
            "constituency_id": 99,
            "constituency_name": "RUHAAMA EAST COUNTY"
        },
        {
            "id": 820,
            "name": "KITYERERA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 147,
            "constituency_name": "BUNYA COUNTY SOUTH"
        },
        {
            "id": 1320,
            "name": "KIWANYI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 181,
            "county_name": "BUSIKI NORTH County",
            "constituency_id": 216,
            "constituency_name": "BUSIKI NORTH COUNTY"
        },
        {
            "id": 1217,
            "name": "KIWOKO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 168,
            "county_name": "NAKASEKE CENTRAL County",
            "constituency_id": 203,
            "constituency_name": "NAKASEKE CENTRAL COUNTY"
        },
        {
            "id": 1753,
            "name": "KIYANGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 242,
            "county_name": "RUHINDA NORTH County",
            "constituency_id": 278,
            "constituency_name": "RUHINDA NORTH COUNTY"
        },
        {
            "id": 1423,
            "name": "KIYINDI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE",
            "county_id": 192,
            "county_name": "BUIKWE County",
            "constituency_id": 228,
            "constituency_name": "BUIKWE COUNTY SOUTH"
        },
        {
            "id": 1950,
            "name": "KIYOMBYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 452,
            "name": "KIYUUNI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 66,
            "county_name": "BUWEKULA County",
            "constituency_id": 86,
            "constituency_name": "BUWEKULA COUNTY"
        },
        {
            "id": 579,
            "name": "KIZIBA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 85,
            "county_name": "KOOKI County",
            "constituency_id": 107,
            "constituency_name": "KOOKI COUNTY"
        },
        {
            "id": 580,
            "name": "KIZIBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 85,
            "county_name": "KOOKI County",
            "constituency_id": 107,
            "constituency_name": "KOOKI COUNTY"
        },
        {
            "id": 56,
            "name": "KIZINDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 8,
            "county_name": "IGARA County",
            "constituency_id": 9,
            "constituency_name": "IGARA COUNTY WEST"
        },
        {
            "id": 2068,
            "name": "KIZIRANFUMBI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 126,
            "district_name": "KIKUUBE",
            "county_id": 277,
            "county_name": "BUHAGUZI County",
            "constituency_id": 315,
            "constituency_name": "BUHAGUZI COUNTY"
        },
        {
            "id": 1313,
            "name": "KIZUBA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 180,
            "county_name": "BUSIKI County",
            "constituency_id": 215,
            "constituency_name": "BUSIKI COUNTY"
        },
        {
            "id": 951,
            "name": "KOBULUBULU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 54,
            "district_name": "KABERAMAIDO",
            "county_id": 135,
            "county_name": "OCHERO County",
            "constituency_id": 166,
            "constituency_name": "OCHERO COUNTY"
        },
        {
            "id": 1783,
            "name": "KOBWIN",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 108,
            "district_name": "NGORA",
            "county_id": 247,
            "county_name": "NGORA County",
            "constituency_id": 283,
            "constituency_name": "NGORA COUNTY"
        },
        {
            "id": 1809,
            "name": "KOCH GOMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 110,
            "district_name": "NWOYA",
            "county_id": 251,
            "county_name": "NWOYA EAST County",
            "constituency_id": 287,
            "constituency_name": "NWOYA EAST COUNTY"
        },
        {
            "id": 1810,
            "name": "KOCH-GOMA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 110,
            "district_name": "NWOYA",
            "county_id": 251,
            "county_name": "NWOYA EAST County",
            "constituency_id": 287,
            "constituency_name": "NWOYA EAST COUNTY"
        },
        {
            "id": 1393,
            "name": "KOCHEKA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 188,
            "county_name": "BUKEDEA County",
            "constituency_id": 224,
            "constituency_name": "BUKEDEA COUNTY"
        },
        {
            "id": 928,
            "name": "KOCHI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 131,
            "county_name": "ARINGA EAST County",
            "constituency_id": 162,
            "constituency_name": "ARINGA EAST COUNTY"
        },
        {
            "id": 1394,
            "name": "KOENA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 188,
            "county_name": "BUKEDEA County",
            "constituency_id": 224,
            "constituency_name": "BUKEDEA COUNTY"
        },
        {
            "id": 1725,
            "name": "KOLE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 103,
            "district_name": "KOLE",
            "county_id": 237,
            "county_name": "KOLE SOUTH County",
            "constituency_id": 274,
            "constituency_name": "KOLE SOUTH COUNTY"
        },
        {
            "id": 1395,
            "name": "KOLIR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 188,
            "county_name": "BUKEDEA County",
            "constituency_id": 224,
            "constituency_name": "BUKEDEA COUNTY"
        },
        {
            "id": 1399,
            "name": "KOMUGE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 189,
            "county_name": "KACHUMBALA County",
            "constituency_id": 225,
            "constituency_name": "KACHUMBALA COUNTY"
        },
        {
            "id": 1417,
            "name": "KONGOROK",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 81,
            "district_name": "AMUDAT",
            "county_id": 191,
            "county_name": "UPE County",
            "constituency_id": 227,
            "constituency_name": "UPE COUNTY"
        },
        {
            "id": 1400,
            "name": "KONGUNGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 189,
            "county_name": "KACHUMBALA County",
            "constituency_id": 225,
            "constituency_name": "KACHUMBALA COUNTY"
        },
        {
            "id": 472,
            "name": "KOOME ISLANDS",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 70,
            "county_name": "MUKONO County",
            "constituency_id": 91,
            "constituency_name": "MUKONO COUNTY SOUTH"
        },
        {
            "id": 1920,
            "name": "KORO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 263,
            "county_name": "TOCHI County",
            "constituency_id": 299,
            "constituency_name": "TOCHI COUNTY"
        },
        {
            "id": 1040,
            "name": "KORTEK",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 144,
            "county_name": "TOO County",
            "constituency_id": 176,
            "constituency_name": "TOO COUNTY"
        },
        {
            "id": 2084,
            "name": "KOSIKE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 128,
            "district_name": "NABILATUK",
            "county_id": 281,
            "county_name": "PIAN County",
            "constituency_id": 319,
            "constituency_name": "PIAN COUNTY"
        },
        {
            "id": 301,
            "name": "KOTIDO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 1824,
            "name": "KOTOMOR",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 253,
            "county_name": "AGAGO County",
            "constituency_id": 289,
            "constituency_name": "AGAGO COUNTY"
        },
        {
            "id": 496,
            "name": "KUCWINY",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 74,
            "county_name": "PADYERE County",
            "constituency_id": 95,
            "constituency_name": "PADYERE COUNTY"
        },
        {
            "id": 1014,
            "name": "KUJU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 141,
            "county_name": "AMURIA County",
            "constituency_id": 173,
            "constituency_name": "AMURIA COUNTY"
        },
        {
            "id": 941,
            "name": "KULIKULINGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 133,
            "county_name": "ARINGA SOUTH County",
            "constituency_id": 164,
            "constituency_name": "ARINGA SOUTH COUNTY"
        },
        {
            "id": 1157,
            "name": "KULUBA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 66,
            "district_name": "KOBOKO",
            "county_id": 160,
            "county_name": "KOBOKO County",
            "constituency_id": 194,
            "constituency_name": "KOBOKO COUNTY"
        },
        {
            "id": 921,
            "name": "KULULU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 130,
            "county_name": "ARINGA County",
            "constituency_id": 161,
            "constituency_name": "ARINGA COUNTY"
        },
        {
            "id": 324,
            "name": "KUMI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 48,
            "county_name": "KUMI County",
            "constituency_id": 60,
            "constituency_name": "KUMI COUNTY"
        },
        {
            "id": 922,
            "name": "KURU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 130,
            "county_name": "ARINGA County",
            "constituency_id": 161,
            "constituency_name": "ARINGA COUNTY"
        },
        {
            "id": 923,
            "name": "KURU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 130,
            "county_name": "ARINGA County",
            "constituency_id": 161,
            "constituency_name": "ARINGA COUNTY"
        },
        {
            "id": 1374,
            "name": "KUUSHU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 186,
            "county_name": "LUTSESHE County",
            "constituency_id": 222,
            "constituency_name": "LUTSESHE COUNTY"
        },
        {
            "id": 1830,
            "name": "KUYWEE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 254,
            "county_name": "AGAGO NORTH County",
            "constituency_id": 290,
            "constituency_name": "AGAGO NORTH COUNTY"
        },
        {
            "id": 1737,
            "name": "KWANYIY",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 626,
            "name": "KWAPA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 94,
            "county_name": "TORORO SOUTH County",
            "constituency_id": 116,
            "constituency_name": "TORORO SOUTH COUNTY"
        },
        {
            "id": 627,
            "name": "KWAPA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 94,
            "county_name": "TORORO SOUTH County",
            "constituency_id": 116,
            "constituency_name": "TORORO SOUTH COUNTY"
        },
        {
            "id": 1401,
            "name": "KWARIKWAR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 189,
            "county_name": "KACHUMBALA County",
            "constituency_id": 225,
            "constituency_name": "KACHUMBALA COUNTY"
        },
        {
            "id": 1301,
            "name": "KWERA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO",
            "county_id": 178,
            "county_name": "DOKOLO SOUTH County",
            "constituency_id": 213,
            "constituency_name": "DOKOLO SOUTH COUNTY"
        },
        {
            "id": 1738,
            "name": "KWOSIR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 1849,
            "name": "KYABAKARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 112,
            "district_name": "RUBIRIZI",
            "county_id": 256,
            "county_name": "KATERERA County",
            "constituency_id": 292,
            "constituency_name": "KATERERA COUNTY"
        },
        {
            "id": 204,
            "name": "KYABARUNGIRA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 42,
            "constituency_name": "BUSONGORA COUNTY NORTH"
        },
        {
            "id": 1900,
            "name": "KYABASAIJA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 260,
            "county_name": "BUGANGAIZI SOUTH County",
            "constituency_id": 296,
            "constituency_name": "BUGANGAIZI SOUTH COUNTY"
        },
        {
            "id": 78,
            "name": "KYABIGAMBIRE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 6,
            "district_name": "HOIMA",
            "county_id": 10,
            "county_name": "BUGAHYA County",
            "constituency_id": 11,
            "constituency_name": "BUGAHYA COUNTY"
        },
        {
            "id": 49,
            "name": "KYABUGIMBI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 8,
            "county_name": "IGARA County",
            "constituency_id": 8,
            "constituency_name": "IGARA COUNTY EAST"
        },
        {
            "id": 50,
            "name": "KYABUGIMBI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 8,
            "county_name": "IGARA County",
            "constituency_id": 8,
            "constituency_name": "IGARA COUNTY EAST"
        },
        {
            "id": 1797,
            "name": "KYAHENDA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU",
            "county_id": 249,
            "county_name": "BUHWEJU WEST County",
            "constituency_id": 285,
            "constituency_name": "BUHWEJU WEST COUNTY"
        },
        {
            "id": 1871,
            "name": "KYAKABADIIMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 807,
            "name": "KYAKATWIRE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 145,
            "constituency_name": "MWENGE COUNTY SOUTH"
        },
        {
            "id": 226,
            "name": "KYAKAZIHIRE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE",
            "county_id": 35,
            "county_name": "BUYANJA County",
            "constituency_id": 45,
            "constituency_name": "BUYANJA COUNTY"
        },
        {
            "id": 581,
            "name": "KYALULANGIRA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 85,
            "county_name": "KOOKI County",
            "constituency_id": 107,
            "constituency_name": "KOOKI COUNTY"
        },
        {
            "id": 2116,
            "name": "KYAMPANGARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 131,
            "district_name": "KAZO",
            "county_id": 285,
            "county_name": "KAZO County",
            "constituency_id": 323,
            "constituency_name": "KAZO COUNTY"
        },
        {
            "id": 469,
            "name": "KYAMPISI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 70,
            "county_name": "MUKONO County",
            "constituency_id": 90,
            "constituency_name": "MUKONO COUNTY NORTH"
        },
        {
            "id": 57,
            "name": "KYAMUHUNGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 8,
            "county_name": "IGARA County",
            "constituency_id": 9,
            "constituency_name": "IGARA COUNTY WEST"
        },
        {
            "id": 58,
            "name": "KYAMUHUNGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 8,
            "county_name": "IGARA County",
            "constituency_id": 9,
            "constituency_name": "IGARA COUNTY WEST"
        },
        {
            "id": 1951,
            "name": "KYAMUKUBE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 1676,
            "name": "KYAMULIBWA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 100,
            "district_name": "KALUNGU",
            "county_id": 231,
            "county_name": "KALUNGU WEST County",
            "constituency_id": 267,
            "constituency_name": "KALUNGU WEST COUNTY"
        },
        {
            "id": 1677,
            "name": "KYAMULIBWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 100,
            "district_name": "KALUNGU",
            "county_id": 231,
            "county_name": "KALUNGU WEST County",
            "constituency_id": 267,
            "constituency_name": "KALUNGU WEST COUNTY"
        },
        {
            "id": 139,
            "name": "KYAMUSWA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 11,
            "district_name": "KALANGALA",
            "county_id": 21,
            "county_name": "KYAMUSWA County",
            "constituency_id": 24,
            "constituency_name": "KYAMUSWA COUNTY"
        },
        {
            "id": 794,
            "name": "KYAMUTUNZI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 144,
            "constituency_name": "MWENGE COUNTY NORTH"
        },
        {
            "id": 1858,
            "name": "KYANAISOKE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 109,
            "name": "KYANAMIRA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 18,
            "county_name": "NDORWA County",
            "constituency_id": 20,
            "constituency_name": "NDORWA COUNTY EAST"
        },
        {
            "id": 372,
            "name": "KYANAMUKAKA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 24,
            "district_name": "MASAKA",
            "county_id": 53,
            "county_name": "BUKOTO County",
            "constituency_id": 70,
            "constituency_name": "BUKOTO COUNTY CENTRAL"
        },
        {
            "id": 2069,
            "name": "KYANGWALI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 126,
            "district_name": "KIKUUBE",
            "county_id": 277,
            "county_name": "BUHAGUZI County",
            "constituency_id": 315,
            "constituency_name": "BUHAGUZI COUNTY"
        },
        {
            "id": 1680,
            "name": "KYANGYENYI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 232,
            "county_name": "SHEEMA County",
            "constituency_id": 268,
            "constituency_name": "SHEEMA COUNTY NORTH"
        },
        {
            "id": 1579,
            "name": "KYANKENDE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO",
            "county_id": 214,
            "county_name": "KIBANDA NORTH County",
            "constituency_id": 250,
            "constituency_name": "KIBANDA NORTH COUNTY"
        },
        {
            "id": 1592,
            "name": "KYANKWANZI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 216,
            "county_name": "BUTEMBA County",
            "constituency_id": 252,
            "constituency_name": "BUTEMBA COUNTY"
        },
        {
            "id": 1593,
            "name": "KYANKWANZI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 216,
            "county_name": "BUTEMBA County",
            "constituency_id": 252,
            "constituency_name": "BUTEMBA COUNTY"
        },
        {
            "id": 1616,
            "name": "KYANVUMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 94,
            "district_name": "LUUKA",
            "county_id": 219,
            "county_name": "LUUKA SOUTH County",
            "constituency_id": 255,
            "constituency_name": "LUUKA SOUTH COUNTY"
        },
        {
            "id": 190,
            "name": "KYARUMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 41,
            "constituency_name": "BUKONZO COUNTY EAST"
        },
        {
            "id": 191,
            "name": "KYARUMBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 41,
            "constituency_name": "BUKONZO COUNTY EAST"
        },
        {
            "id": 784,
            "name": "KYARUSOZI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 118,
            "county_name": "MWENGE CENTRAL County",
            "constituency_id": 143,
            "constituency_name": "MWENGE CENTRAL COUNTY"
        },
        {
            "id": 785,
            "name": "KYARUSOZI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 118,
            "county_name": "MWENGE CENTRAL County",
            "constituency_id": 143,
            "constituency_name": "MWENGE CENTRAL COUNTY"
        },
        {
            "id": 1449,
            "name": "KYATEGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 197,
            "county_name": "KYAKA CENTRAL County",
            "constituency_id": 233,
            "constituency_name": "KYAKA CENTRAL COUNTY"
        },
        {
            "id": 1872,
            "name": "KYATEREKERA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 1873,
            "name": "KYATEREKERA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 386,
            "name": "KYATIRI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 55,
            "county_name": "BURULI County",
            "constituency_id": 73,
            "constituency_name": "BURULI COUNTY"
        },
        {
            "id": 1570,
            "name": "KYAYI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 91,
            "district_name": "GOMBA",
            "county_id": 213,
            "county_name": "GOMBA WEST County",
            "constituency_id": 249,
            "constituency_name": "GOMBA WEST COUNTY"
        },
        {
            "id": 366,
            "name": "KYAZANGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 105,
            "district_name": "LWENGO",
            "county_id": 240,
            "county_name": "BUKOTO County",
            "constituency_id": 68,
            "constituency_name": "BUKOTO COUNTY WEST"
        },
        {
            "id": 367,
            "name": "KYAZANGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 105,
            "district_name": "LWENGO",
            "county_id": 240,
            "county_name": "BUKOTO County",
            "constituency_id": 68,
            "constituency_name": "BUKOTO COUNTY WEST"
        },
        {
            "id": 233,
            "name": "KYEBANDO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE",
            "county_id": 36,
            "county_name": "BUYANJA EAST County",
            "constituency_id": 46,
            "constituency_name": "BUYANJA EAST COUNTY"
        },
        {
            "id": 1976,
            "name": "KYEBE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA",
            "county_id": 267,
            "county_name": "KAKUUTO County",
            "constituency_id": 304,
            "constituency_name": "KAKUUTO COUNTY"
        },
        {
            "id": 743,
            "name": "KYEERA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 110,
            "county_name": "LWEMIYAGA County",
            "constituency_id": 135,
            "constituency_name": "LWEMIYAGA COUNTY"
        },
        {
            "id": 1450,
            "name": "KYEGEGWA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 197,
            "county_name": "KYAKA CENTRAL County",
            "constituency_id": 233,
            "constituency_name": "KYAKA CENTRAL COUNTY"
        },
        {
            "id": 1451,
            "name": "KYEGEGWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 197,
            "county_name": "KYAKA CENTRAL County",
            "constituency_id": 233,
            "constituency_name": "KYAKA CENTRAL COUNTY"
        },
        {
            "id": 1565,
            "name": "KYEGONZA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 91,
            "district_name": "GOMBA",
            "county_id": 212,
            "county_name": "GOMBA EAST County",
            "constituency_id": 248,
            "constituency_name": "GOMBA EAST COUNTY"
        },
        {
            "id": 51,
            "name": "KYEIZOOBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 8,
            "county_name": "IGARA County",
            "constituency_id": 8,
            "constituency_name": "IGARA COUNTY EAST"
        },
        {
            "id": 248,
            "name": "KYEKUMBYA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA",
            "county_id": 38,
            "county_name": "KIBOGA WEST County",
            "constituency_id": 48,
            "constituency_name": "KIBOGA WEST COUNTY"
        },
        {
            "id": 795,
            "name": "KYEMBOGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 144,
            "constituency_name": "MWENGE COUNTY NORTH"
        },
        {
            "id": 457,
            "name": "KYENDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 67,
            "county_name": "BUWEKULA SOUTH County",
            "constituency_id": 87,
            "constituency_name": "BUWEKULA SOUTH COUNTY"
        },
        {
            "id": 893,
            "name": "KYENGERA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 124,
            "county_name": "BUSIRO County",
            "constituency_id": 153,
            "constituency_name": "BUSIRO COUNTY EAST"
        },
        {
            "id": 808,
            "name": "KYENJOJO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 145,
            "constituency_name": "MWENGE COUNTY SOUTH"
        },
        {
            "id": 1859,
            "name": "KYENZIGE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 1860,
            "name": "KYENZIGE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 1650,
            "name": "KYERE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 226,
            "county_name": "SERERE County",
            "constituency_id": 262,
            "constituency_name": "SERERE COUNTY"
        },
        {
            "id": 1651,
            "name": "KYERE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 226,
            "county_name": "SERERE County",
            "constituency_id": 262,
            "constituency_name": "SERERE COUNTY"
        },
        {
            "id": 976,
            "name": "KYESHERO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 168,
            "constituency_name": "KINKIZI COUNTY WEST"
        },
        {
            "id": 373,
            "name": "KYESIIGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 24,
            "district_name": "MASAKA",
            "county_id": 53,
            "county_name": "BUKOTO County",
            "constituency_id": 70,
            "constituency_name": "BUKOTO COUNTY CENTRAL"
        },
        {
            "id": 239,
            "name": "KYOMYA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA",
            "county_id": 37,
            "county_name": "KIBOGA EAST County",
            "constituency_id": 47,
            "constituency_name": "KIBOGA EAST COUNTY"
        },
        {
            "id": 192,
            "name": "KYONDO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 41,
            "constituency_name": "BUKONZO COUNTY EAST"
        },
        {
            "id": 1984,
            "name": "KYOTERA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA",
            "county_id": 268,
            "county_name": "KYOTERA County",
            "constituency_id": 305,
            "constituency_name": "KYOTERA COUNTY"
        },
        {
            "id": 387,
            "name": "LABONGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 55,
            "county_name": "BURULI County",
            "constituency_id": 73,
            "constituency_name": "BURULI COUNTY"
        },
        {
            "id": 288,
            "name": "LABONGO-AMIDA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 43,
            "county_name": "CHUA WEST County",
            "constituency_id": 55,
            "constituency_name": "CHUA WEST COUNTY"
        },
        {
            "id": 289,
            "name": "LABONGO-AMIDA WEST",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 43,
            "county_name": "CHUA WEST County",
            "constituency_id": 55,
            "constituency_name": "CHUA WEST COUNTY"
        },
        {
            "id": 290,
            "name": "LABONGO-LAYAMO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 43,
            "county_name": "CHUA WEST County",
            "constituency_id": 55,
            "constituency_name": "CHUA WEST COUNTY"
        },
        {
            "id": 1646,
            "name": "LABOR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 225,
            "county_name": "PINGIRE County",
            "constituency_id": 261,
            "constituency_name": "PINGIRE COUNTY"
        },
        {
            "id": 1921,
            "name": "LABORA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 263,
            "county_name": "TOCHI County",
            "constituency_id": 299,
            "constituency_name": "TOCHI COUNTY"
        },
        {
            "id": 291,
            "name": "LAGORO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 43,
            "county_name": "CHUA WEST County",
            "constituency_id": 55,
            "constituency_name": "CHUA WEST COUNTY"
        },
        {
            "id": 844,
            "name": "LAGUTI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 122,
            "county_name": "ARUU NORTH County",
            "constituency_id": 150,
            "constituency_name": "ARUU NORTH COUNTY"
        },
        {
            "id": 1831,
            "name": "LAI-MUTTO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 254,
            "county_name": "AGAGO NORTH County",
            "constituency_id": 290,
            "constituency_name": "AGAGO NORTH COUNTY"
        },
        {
            "id": 1256,
            "name": "LAKANG",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU",
            "county_id": 173,
            "county_name": "KILAK SOUTH County",
            "constituency_id": 208,
            "constituency_name": "KILAK SOUTH COUNTY"
        },
        {
            "id": 213,
            "name": "LAKE KATWE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 43,
            "constituency_name": "BUSONGORA COUNTY SOUTH"
        },
        {
            "id": 1911,
            "name": "LAKWANA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 262,
            "county_name": "OMORO County",
            "constituency_id": 298,
            "constituency_name": "OMORO COUNTY"
        },
        {
            "id": 1912,
            "name": "LAKWAYA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 262,
            "county_name": "OMORO County",
            "constituency_id": 298,
            "constituency_name": "OMORO COUNTY"
        },
        {
            "id": 292,
            "name": "LALANO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 43,
            "county_name": "CHUA WEST County",
            "constituency_id": 55,
            "constituency_name": "CHUA WEST COUNTY"
        },
        {
            "id": 612,
            "name": "LALLE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI",
            "county_id": 91,
            "county_name": "SOROTI County",
            "constituency_id": 113,
            "constituency_name": "SOROTI COUNTY"
        },
        {
            "id": 1913,
            "name": "LALOGI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 262,
            "county_name": "OMORO County",
            "constituency_id": 298,
            "constituency_name": "OMORO COUNTY"
        },
        {
            "id": 1816,
            "name": "LAMIYO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 252,
            "county_name": "AGAGO WEST County",
            "constituency_id": 288,
            "constituency_name": "AGAGO WEST COUNTY"
        },
        {
            "id": 1257,
            "name": "LAMOGI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU",
            "county_id": 173,
            "county_name": "KILAK SOUTH County",
            "constituency_id": 208,
            "constituency_name": "KILAK SOUTH COUNTY"
        },
        {
            "id": 1470,
            "name": "LAMWO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 200,
            "county_name": "LAMWO County",
            "constituency_id": 236,
            "constituency_name": "LAMWO COUNTY"
        },
        {
            "id": 1825,
            "name": "LAPEREBONG",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 253,
            "county_name": "AGAGO County",
            "constituency_id": 289,
            "constituency_name": "AGAGO COUNTY"
        },
        {
            "id": 1832,
            "name": "LAPONO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 254,
            "county_name": "AGAGO NORTH County",
            "constituency_id": 290,
            "constituency_name": "AGAGO NORTH COUNTY"
        },
        {
            "id": 845,
            "name": "LAPUL",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 122,
            "county_name": "ARUU NORTH County",
            "constituency_id": 150,
            "constituency_name": "ARUU NORTH COUNTY"
        },
        {
            "id": 433,
            "name": "LAROPI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 29,
            "district_name": "MOYO",
            "county_id": 64,
            "county_name": "WEST MOYO County",
            "constituency_id": 83,
            "constituency_name": "WEST MOYO COUNTY"
        },
        {
            "id": 434,
            "name": "LAROPI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 29,
            "district_name": "MOYO",
            "county_id": 64,
            "county_name": "WEST MOYO County",
            "constituency_id": 83,
            "constituency_name": "WEST MOYO COUNTY"
        },
        {
            "id": 846,
            "name": "LATANYA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 122,
            "county_name": "ARUU NORTH County",
            "constituency_id": 150,
            "constituency_name": "ARUU NORTH COUNTY"
        },
        {
            "id": 1258,
            "name": "LAYIMA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU",
            "county_id": 173,
            "county_name": "KILAK SOUTH County",
            "constituency_id": 208,
            "constituency_name": "KILAK SOUTH COUNTY"
        },
        {
            "id": 435,
            "name": "LEFORI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 29,
            "district_name": "MOYO",
            "county_id": 64,
            "county_name": "WEST MOYO County",
            "constituency_id": 83,
            "constituency_name": "WEST MOYO COUNTY"
        },
        {
            "id": 436,
            "name": "LEFORI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 29,
            "district_name": "MOYO",
            "county_id": 64,
            "county_name": "WEST MOYO County",
            "constituency_id": 83,
            "constituency_name": "WEST MOYO COUNTY"
        },
        {
            "id": 888,
            "name": "LEGENYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 2165,
            "name": "LEJU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 143,
            "district_name": "TEREGO",
            "county_id": 307,
            "county_name": "TEREGO WEST County",
            "constituency_id": 347,
            "constituency_name": "TEREGO WEST COUNTY"
        },
        {
            "id": 987,
            "name": "LEMUSUI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 56,
            "district_name": "NAKAPIRIPIRIT",
            "county_id": 138,
            "county_name": "CHEKWII EAST County",
            "constituency_id": 170,
            "constituency_name": "CHEKWII EAST COUNTY"
        },
        {
            "id": 1811,
            "name": "LII",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 110,
            "district_name": "NWOYA",
            "county_id": 251,
            "county_name": "NWOYA EAST County",
            "constituency_id": 287,
            "constituency_name": "NWOYA EAST COUNTY"
        },
        {
            "id": 1833,
            "name": "LIRA KATO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 254,
            "county_name": "AGAGO NORTH County",
            "constituency_id": 290,
            "constituency_name": "AGAGO NORTH COUNTY"
        },
        {
            "id": 1817,
            "name": "LIRA-PALWO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 252,
            "county_name": "AGAGO WEST County",
            "constituency_id": 288,
            "constituency_name": "AGAGO WEST COUNTY"
        },
        {
            "id": 1818,
            "name": "LIRA-PALWO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 252,
            "county_name": "AGAGO WEST County",
            "constituency_id": 288,
            "constituency_name": "AGAGO WEST COUNTY"
        },
        {
            "id": 2104,
            "name": "LOBALANGIT",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 130,
            "district_name": "KARENGA",
            "county_id": 283,
            "county_name": "DODOTH WEST County",
            "constituency_id": 321,
            "constituency_name": "DODOTH WEST COUNTY"
        },
        {
            "id": 934,
            "name": "LOBE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 132,
            "county_name": "ARINGA NORTH County",
            "constituency_id": 163,
            "constituency_name": "ARINGA NORTH COUNTY"
        },
        {
            "id": 1110,
            "name": "LOBONGIA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 153,
            "county_name": "DODOTH EAST County",
            "constituency_id": 187,
            "constituency_name": "DODOTH EAST COUNTY"
        },
        {
            "id": 1158,
            "name": "LOBULE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 66,
            "district_name": "KOBOKO",
            "county_id": 160,
            "county_name": "KOBOKO County",
            "constituency_id": 194,
            "constituency_name": "KOBOKO COUNTY"
        },
        {
            "id": 1111,
            "name": "LODIKO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 153,
            "county_name": "DODOTH EAST County",
            "constituency_id": 187,
            "constituency_name": "DODOTH EAST COUNTY"
        },
        {
            "id": 942,
            "name": "LODONGA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 133,
            "county_name": "ARINGA SOUTH County",
            "constituency_id": 164,
            "constituency_name": "ARINGA SOUTH COUNTY"
        },
        {
            "id": 943,
            "name": "LODONGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 133,
            "county_name": "ARINGA SOUTH County",
            "constituency_id": 164,
            "constituency_name": "ARINGA SOUTH COUNTY"
        },
        {
            "id": 15,
            "name": "LOGIRI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 2,
            "district_name": "ARUA",
            "county_id": 4,
            "county_name": "VURRA County",
            "constituency_id": 4,
            "constituency_name": "VURRA COUNTY"
        },
        {
            "id": 1418,
            "name": "LOKALES",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 81,
            "district_name": "AMUDAT",
            "county_id": 191,
            "county_name": "UPE County",
            "constituency_id": 227,
            "constituency_name": "UPE COUNTY"
        },
        {
            "id": 1764,
            "name": "LOKITEDED TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK",
            "county_id": 244,
            "county_name": "BOKORA County",
            "constituency_id": 280,
            "constituency_name": "BOKORA COUNTY"
        },
        {
            "id": 302,
            "name": "LOKITELAEBU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 1826,
            "name": "LOKOLE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 253,
            "county_name": "AGAGO County",
            "constituency_id": 289,
            "constituency_name": "AGAGO COUNTY"
        },
        {
            "id": 1765,
            "name": "LOKOPO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK",
            "county_id": 244,
            "county_name": "BOKORA County",
            "constituency_id": 280,
            "constituency_name": "BOKORA COUNTY"
        },
        {
            "id": 2109,
            "name": "LOKORI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 130,
            "district_name": "KARENGA",
            "county_id": 284,
            "county_name": "NAPORE WEST County",
            "constituency_id": 322,
            "constituency_name": "NAPORE WEST COUNTY"
        },
        {
            "id": 1471,
            "name": "LOKUNG",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 200,
            "county_name": "LAMWO County",
            "constituency_id": 236,
            "constituency_name": "LAMWO COUNTY"
        },
        {
            "id": 1472,
            "name": "LOKUNG EAST",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 200,
            "county_name": "LAMWO County",
            "constituency_id": 236,
            "constituency_name": "LAMWO COUNTY"
        },
        {
            "id": 303,
            "name": "LOKWAKIAL",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 2085,
            "name": "LOLACHAT",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 128,
            "district_name": "NABILATUK",
            "county_id": 281,
            "county_name": "PIAN County",
            "constituency_id": 319,
            "constituency_name": "PIAN COUNTY"
        },
        {
            "id": 1112,
            "name": "LOLELIA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 153,
            "county_name": "DODOTH EAST County",
            "constituency_id": 187,
            "constituency_name": "DODOTH EAST COUNTY"
        },
        {
            "id": 1113,
            "name": "LOLELIA SOUTH",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 153,
            "county_name": "DODOTH EAST County",
            "constituency_id": 187,
            "constituency_name": "DODOTH EAST COUNTY"
        },
        {
            "id": 304,
            "name": "LOLETIO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 1620,
            "name": "LOLWE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 95,
            "district_name": "NAMAYINGO",
            "county_id": 220,
            "county_name": "BUKOOLI ISLAND County",
            "constituency_id": 256,
            "constituency_name": "BUKOOLI ISLAND COUNTY"
        },
        {
            "id": 305,
            "name": "LONGAROE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 1766,
            "name": "LOPEI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK",
            "county_id": 244,
            "county_name": "BOKORA County",
            "constituency_id": 280,
            "constituency_name": "BOKORA COUNTY"
        },
        {
            "id": 422,
            "name": "LOPUTUK",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 28,
            "district_name": "MOROTO",
            "county_id": 61,
            "county_name": "MATHENIKO County",
            "constituency_id": 80,
            "constituency_name": "MATHENIKO COUNTY"
        },
        {
            "id": 984,
            "name": "LOREGAE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 56,
            "district_name": "NAKAPIRIPIRIT",
            "county_id": 137,
            "county_name": "CHEKWII County",
            "constituency_id": 169,
            "constituency_name": "CHEKWII COUNTY"
        },
        {
            "id": 985,
            "name": "LORENG",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 56,
            "district_name": "NAKAPIRIPIRIT",
            "county_id": 137,
            "county_name": "CHEKWII County",
            "constituency_id": 169,
            "constituency_name": "CHEKWII COUNTY"
        },
        {
            "id": 1772,
            "name": "LORENGECORA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK",
            "county_id": 245,
            "county_name": "BOKORA EAST County",
            "constituency_id": 281,
            "constituency_name": "BOKORA EAST COUNTY"
        },
        {
            "id": 2086,
            "name": "LORENGEDWAT",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 128,
            "district_name": "NABILATUK",
            "county_id": 281,
            "county_name": "PIAN County",
            "constituency_id": 319,
            "constituency_name": "PIAN COUNTY"
        },
        {
            "id": 929,
            "name": "LORI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 131,
            "county_name": "ARINGA EAST County",
            "constituency_id": 162,
            "constituency_name": "ARINGA EAST COUNTY"
        },
        {
            "id": 1334,
            "name": "LORO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 218,
            "constituency_name": "OYAM COUNTY SOUTH"
        },
        {
            "id": 1335,
            "name": "LORO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 218,
            "constituency_name": "OYAM COUNTY SOUTH"
        },
        {
            "id": 1419,
            "name": "LOROO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 81,
            "district_name": "AMUDAT",
            "county_id": 191,
            "county_name": "UPE County",
            "constituency_id": 227,
            "constituency_name": "UPE COUNTY"
        },
        {
            "id": 1420,
            "name": "LOSIDOK",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 81,
            "district_name": "AMUDAT",
            "county_id": 191,
            "county_name": "UPE County",
            "constituency_id": 227,
            "constituency_name": "UPE COUNTY"
        },
        {
            "id": 1122,
            "name": "LOTIM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 154,
            "county_name": "DODOTH NORTH County",
            "constituency_id": 188,
            "constituency_name": "DODOTH NORTH COUNTY"
        },
        {
            "id": 423,
            "name": "LOTISAN",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 28,
            "district_name": "MOROTO",
            "county_id": 61,
            "county_name": "MATHENIKO County",
            "constituency_id": 80,
            "constituency_name": "MATHENIKO COUNTY"
        },
        {
            "id": 1773,
            "name": "LOTOME",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK",
            "county_id": 245,
            "county_name": "BOKORA EAST County",
            "constituency_id": 281,
            "constituency_name": "BOKORA EAST COUNTY"
        },
        {
            "id": 1239,
            "name": "LOTUKEI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 1114,
            "name": "LOYORO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 153,
            "county_name": "DODOTH EAST County",
            "constituency_id": 187,
            "constituency_name": "DODOTH EAST COUNTY"
        },
        {
            "id": 463,
            "name": "LUBIMBIRI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 68,
            "county_name": "KASAMBYA County",
            "constituency_id": 88,
            "constituency_name": "KASAMBYA COUNTY"
        },
        {
            "id": 1560,
            "name": "LUBYA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 90,
            "district_name": "BUVUMA",
            "county_id": 211,
            "county_name": "BUVUMA ISLANDS County",
            "constituency_id": 247,
            "constituency_name": "BUVUMA ISLANDS COUNTY"
        },
        {
            "id": 1162,
            "name": "LUDARA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 66,
            "district_name": "KOBOKO",
            "county_id": 161,
            "county_name": "KOBOKO NORTH County",
            "constituency_id": 195,
            "constituency_name": "KOBOKO NORTH COUNTY"
        },
        {
            "id": 751,
            "name": "LUGUSULU",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 112,
            "county_name": "MAWOGOLA NORTH County",
            "constituency_id": 137,
            "constituency_name": "MAWOGOLA NORTH COUNTY"
        },
        {
            "id": 1672,
            "name": "LUKAYA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 100,
            "district_name": "KALUNGU",
            "county_id": 230,
            "county_name": "KALUNGU EAST County",
            "constituency_id": 266,
            "constituency_name": "KALUNGU EAST COUNTY"
        },
        {
            "id": 410,
            "name": "LUKHONGE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 58,
            "county_name": "BUNGOKHO County",
            "constituency_id": 77,
            "constituency_name": "BUNGOKHO COUNTY SOUTH"
        },
        {
            "id": 867,
            "name": "LULENA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 703,
            "name": "LUMINO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 104,
            "county_name": "SAMIA BUGWE County",
            "constituency_id": 129,
            "constituency_name": "SAMIA BUGWE COUNTY SOUTH"
        },
        {
            "id": 704,
            "name": "LUMINO-MAJANJI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 104,
            "county_name": "SAMIA BUGWE County",
            "constituency_id": 129,
            "constituency_name": "SAMIA BUGWE COUNTY SOUTH"
        },
        {
            "id": 1804,
            "name": "LUNGULU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 110,
            "district_name": "NWOYA",
            "county_id": 250,
            "county_name": "NWOYA County",
            "constituency_id": 286,
            "constituency_name": "NWOYA COUNTY"
        },
        {
            "id": 829,
            "name": "LUNYIRI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 121,
            "county_name": "ARUU County",
            "constituency_id": 149,
            "constituency_name": "ARUU COUNTY"
        },
        {
            "id": 705,
            "name": "LUNYO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 104,
            "county_name": "SAMIA BUGWE County",
            "constituency_id": 129,
            "constituency_name": "SAMIA BUGWE COUNTY SOUTH"
        },
        {
            "id": 1542,
            "name": "LUSHA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 209,
            "county_name": "ELGON County",
            "constituency_id": 245,
            "constituency_name": "ELGON COUNTY"
        },
        {
            "id": 1611,
            "name": "LUUKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 94,
            "district_name": "LUUKA",
            "county_id": 218,
            "county_name": "LUUKA NORTH County",
            "constituency_id": 254,
            "constituency_name": "LUUKA NORTH COUNTY"
        },
        {
            "id": 2011,
            "name": "LUWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 270,
            "county_name": "NAMISINDWA County",
            "constituency_id": 307,
            "constituency_name": "NAMISINDWA COUNTY"
        },
        {
            "id": 354,
            "name": "LUWEERO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 52,
            "county_name": "KATIKAMU County",
            "constituency_id": 65,
            "constituency_name": "KATIKAMU COUNTY NORTH"
        },
        {
            "id": 355,
            "name": "LUWERO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 52,
            "county_name": "KATIKAMU County",
            "constituency_id": 65,
            "constituency_name": "KATIKAMU COUNTY NORTH"
        },
        {
            "id": 1673,
            "name": "LWABENGE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 100,
            "district_name": "KALUNGU",
            "county_id": 230,
            "county_name": "KALUNGU EAST County",
            "constituency_id": 266,
            "constituency_name": "KALUNGU EAST COUNTY"
        },
        {
            "id": 732,
            "name": "LWABIYATA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 108,
            "county_name": "BUDYEBO County",
            "constituency_id": 133,
            "constituency_name": "BUDYEBO COUNTY"
        },
        {
            "id": 1561,
            "name": "LWAJE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 90,
            "district_name": "BUVUMA",
            "county_id": 211,
            "county_name": "BUVUMA ISLANDS County",
            "constituency_id": 247,
            "constituency_name": "BUVUMA ISLANDS COUNTY"
        },
        {
            "id": 1996,
            "name": "LWAKHAKHA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 571,
            "name": "LWAMAGGWA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 84,
            "county_name": "BUYAMBA County",
            "constituency_id": 106,
            "constituency_name": "BUYAMBA COUNTY"
        },
        {
            "id": 572,
            "name": "LWAMAGGWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 84,
            "county_name": "BUYAMBA County",
            "constituency_id": 106,
            "constituency_name": "BUYAMBA COUNTY"
        },
        {
            "id": 240,
            "name": "LWAMATA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA",
            "county_id": 37,
            "county_name": "KIBOGA EAST County",
            "constituency_id": 47,
            "constituency_name": "KIBOGA EAST COUNTY"
        },
        {
            "id": 241,
            "name": "LWAMATA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA",
            "county_id": 37,
            "county_name": "KIBOGA EAST County",
            "constituency_id": 47,
            "constituency_name": "KIBOGA EAST COUNTY"
        },
        {
            "id": 727,
            "name": "LWAMPANGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 108,
            "county_name": "BUDYEBO County",
            "constituency_id": 133,
            "constituency_name": "BUDYEBO COUNTY"
        },
        {
            "id": 728,
            "name": "LWAMPANGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 108,
            "county_name": "BUDYEBO County",
            "constituency_id": 133,
            "constituency_name": "BUDYEBO COUNTY"
        },
        {
            "id": 582,
            "name": "LWANDA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 85,
            "county_name": "KOOKI County",
            "constituency_id": 107,
            "constituency_name": "KOOKI COUNTY"
        },
        {
            "id": 1178,
            "name": "LWANJUSI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 1985,
            "name": "LWANKONI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA",
            "county_id": 268,
            "county_name": "KYOTERA County",
            "constituency_id": 305,
            "constituency_name": "KYOTERA COUNTY"
        },
        {
            "id": 1711,
            "name": "LWATAMA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 235,
            "county_name": "KIBUKU County",
            "constituency_id": 272,
            "constituency_name": "KIBUKU COUNTY"
        },
        {
            "id": 757,
            "name": "LWEBITAKULI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 113,
            "county_name": "MAWOGOLA WEST County",
            "constituency_id": 138,
            "constituency_name": "MAWOGOLA WEST COUNTY"
        },
        {
            "id": 744,
            "name": "LWEMIYAGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 110,
            "county_name": "LWEMIYAGA County",
            "constituency_id": 135,
            "constituency_name": "LWEMIYAGA COUNTY"
        },
        {
            "id": 362,
            "name": "LWENGO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 105,
            "district_name": "LWENGO",
            "county_id": 240,
            "county_name": "BUKOTO County",
            "constituency_id": 67,
            "constituency_name": "BUKOTO COUNTY MID-WEST"
        },
        {
            "id": 363,
            "name": "LWENGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 105,
            "district_name": "LWENGO",
            "county_id": 240,
            "county_name": "BUKOTO County",
            "constituency_id": 67,
            "constituency_name": "BUKOTO COUNTY MID-WEST"
        },
        {
            "id": 573,
            "name": "LWENTULEGE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 84,
            "county_name": "BUYAMBA County",
            "constituency_id": 106,
            "constituency_name": "BUYAMBA COUNTY"
        },
        {
            "id": 1041,
            "name": "LWONGON",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 144,
            "county_name": "TOO County",
            "constituency_id": 176,
            "constituency_name": "TOO COUNTY"
        },
        {
            "id": 1562,
            "name": "LYABAANA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 90,
            "district_name": "BUVUMA",
            "county_id": 211,
            "county_name": "BUVUMA ISLANDS County",
            "constituency_id": 247,
            "constituency_name": "BUVUMA ISLANDS COUNTY"
        },
        {
            "id": 765,
            "name": "LYAKAHUNGU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 115,
            "county_name": "KIBALE EAST County",
            "constituency_id": 139,
            "constituency_name": "KIBALE EAST COUNTY"
        },
        {
            "id": 1406,
            "name": "LYAKAJURA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 80,
            "district_name": "LYANTONDE",
            "county_id": 190,
            "county_name": "KABULA County",
            "constituency_id": 226,
            "constituency_name": "KABULA COUNTY"
        },
        {
            "id": 1266,
            "name": "LYAMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 174,
            "county_name": "BUDAKA County",
            "constituency_id": 209,
            "constituency_name": "BUDAKA COUNTY"
        },
        {
            "id": 1407,
            "name": "LYANTONDE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 80,
            "district_name": "LYANTONDE",
            "county_id": 190,
            "county_name": "KABULA County",
            "constituency_id": 226,
            "constituency_name": "KABULA COUNTY"
        },
        {
            "id": 1408,
            "name": "LYANTONDE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 80,
            "district_name": "LYANTONDE",
            "county_id": 190,
            "county_name": "KABULA County",
            "constituency_id": 226,
            "constituency_name": "KABULA COUNTY"
        },
        {
            "id": 1202,
            "name": "MAANYI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 165,
            "county_name": "BUSUJJU County",
            "constituency_id": 199,
            "constituency_name": "BUSUJJU COUNTY"
        },
        {
            "id": 306,
            "name": "MAARU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 1861,
            "name": "MABAALE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 1862,
            "name": "MABAALE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 23,
            "name": "MABERE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 5,
            "county_name": "BUGHENDERA County",
            "constituency_id": 5,
            "constituency_name": "BUGHENDERA COUNTY"
        },
        {
            "id": 752,
            "name": "MABINDO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 112,
            "county_name": "MAWOGOLA NORTH County",
            "constituency_id": 137,
            "constituency_name": "MAWOGOLA NORTH COUNTY"
        },
        {
            "id": 796,
            "name": "MABIRA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 144,
            "constituency_name": "MWENGE COUNTY NORTH"
        },
        {
            "id": 1375,
            "name": "MABONO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 186,
            "county_name": "LUTSESHE County",
            "constituency_id": 222,
            "constituency_name": "LUTSESHE COUNTY"
        },
        {
            "id": 1571,
            "name": "MADDU",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 91,
            "district_name": "GOMBA",
            "county_id": 213,
            "county_name": "GOMBA WEST County",
            "constituency_id": 249,
            "constituency_name": "GOMBA WEST COUNTY"
        },
        {
            "id": 1572,
            "name": "MADDU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 91,
            "district_name": "GOMBA",
            "county_id": 213,
            "county_name": "GOMBA WEST County",
            "constituency_id": 249,
            "constituency_name": "GOMBA WEST COUNTY"
        },
        {
            "id": 1473,
            "name": "MADI-OPEI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 200,
            "county_name": "LAMWO County",
            "constituency_id": 236,
            "constituency_name": "LAMWO COUNTY"
        },
        {
            "id": 1474,
            "name": "MADI-OPEI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 200,
            "county_name": "LAMWO County",
            "constituency_id": 236,
            "constituency_name": "LAMWO COUNTY"
        },
        {
            "id": 453,
            "name": "MADUDU",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 66,
            "county_name": "BUWEKULA County",
            "constituency_id": 86,
            "constituency_name": "BUWEKULA COUNTY"
        },
        {
            "id": 1194,
            "name": "MAEFE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 164,
            "county_name": "BUTIRU County",
            "constituency_id": 198,
            "constituency_name": "BUTIRU COUNTY"
        },
        {
            "id": 868,
            "name": "MAFUDU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 1321,
            "name": "MAGADA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 181,
            "county_name": "BUSIKI NORTH County",
            "constituency_id": 216,
            "constituency_name": "BUSIKI NORTH COUNTY"
        },
        {
            "id": 1997,
            "name": "MAGALE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 1998,
            "name": "MAGALE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 1240,
            "name": "MAGAMAGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 825,
            "name": "MAGAMAGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 148,
            "constituency_name": "BUNYA COUNTY WEST"
        },
        {
            "id": 1841,
            "name": "MAGAMBO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 112,
            "district_name": "RUBIRIZI",
            "county_id": 255,
            "county_name": "BUNYARUGURU County",
            "constituency_id": 291,
            "constituency_name": "BUNYARUGURU COUNTY"
        },
        {
            "id": 618,
            "name": "MAGODES TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 93,
            "county_name": "TORORO NORTH County",
            "constituency_id": 115,
            "constituency_name": "TORORO NORTH COUNTY"
        },
        {
            "id": 155,
            "name": "MAGOGO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 28,
            "county_name": "BUZAAYA County",
            "constituency_id": 36,
            "constituency_name": "BUZAAYA COUNTY"
        },
        {
            "id": 645,
            "name": "MAGOLA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 96,
            "county_name": "WEST BUDAMA County",
            "constituency_id": 119,
            "constituency_name": "WEST BUDAMA COUNTY SOUTH"
        },
        {
            "id": 717,
            "name": "MAGORO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 106,
            "county_name": "TOROMA County",
            "constituency_id": 131,
            "constituency_name": "TOROMA COUNTY"
        },
        {
            "id": 718,
            "name": "MAGORO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 106,
            "county_name": "TOROMA County",
            "constituency_id": 131,
            "constituency_name": "TOROMA COUNTY"
        },
        {
            "id": 193,
            "name": "MAHANGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 41,
            "constituency_name": "BUKONZO COUNTY EAST"
        },
        {
            "id": 2127,
            "name": "MAHYORO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 132,
            "district_name": "KITAGWENDA",
            "county_id": 286,
            "county_name": "KITAGWENDA County",
            "constituency_id": 324,
            "constituency_name": "KITAGWENDA COUNTY"
        },
        {
            "id": 2128,
            "name": "MAHYORO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 132,
            "district_name": "KITAGWENDA",
            "county_id": 286,
            "county_name": "KITAGWENDA County",
            "constituency_id": 324,
            "constituency_name": "KITAGWENDA COUNTY"
        },
        {
            "id": 1874,
            "name": "MAIRIRWE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 1969,
            "name": "MAIZIMASA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 706,
            "name": "MAJANJI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 104,
            "county_name": "SAMIA BUGWE County",
            "constituency_id": 129,
            "constituency_name": "SAMIA BUGWE COUNTY SOUTH"
        },
        {
            "id": 1195,
            "name": "MAKENYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 164,
            "county_name": "BUTIRU County",
            "constituency_id": 198,
            "constituency_name": "BUTIRU COUNTY"
        },
        {
            "id": 2055,
            "name": "MAKOKOTO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 275,
            "county_name": "BUKUYA County",
            "constituency_id": 312,
            "constituency_name": "BUKUYA COUNTY"
        },
        {
            "id": 358,
            "name": "MAKULUBITA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 52,
            "county_name": "KATIKAMU County",
            "constituency_id": 66,
            "constituency_name": "KATIKAMU COUNTY SOUTH"
        },
        {
            "id": 2037,
            "name": "MAKUUTU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 123,
            "district_name": "BUGWERI",
            "county_id": 273,
            "county_name": "BUGWERI County",
            "constituency_id": 310,
            "constituency_name": "BUGWERI COUNTY"
        },
        {
            "id": 628,
            "name": "MALABA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 94,
            "county_name": "TORORO SOUTH County",
            "constituency_id": 116,
            "constituency_name": "TORORO SOUTH COUNTY"
        },
        {
            "id": 1203,
            "name": "MALANGALA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 165,
            "county_name": "BUSUJJU County",
            "constituency_id": 199,
            "constituency_name": "BUSUJJU COUNTY"
        },
        {
            "id": 1396,
            "name": "MALERA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 79,
            "district_name": "BUKEDEA",
            "county_id": 188,
            "county_name": "BUKEDEA County",
            "constituency_id": 224,
            "constituency_name": "BUKEDEA COUNTY"
        },
        {
            "id": 205,
            "name": "MALIBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 42,
            "constituency_name": "BUSONGORA COUNTY NORTH"
        },
        {
            "id": 206,
            "name": "MALIBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 42,
            "constituency_name": "BUSONGORA COUNTY NORTH"
        },
        {
            "id": 821,
            "name": "MALONGO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 147,
            "constituency_name": "BUNYA COUNTY SOUTH"
        },
        {
            "id": 368,
            "name": "MALONGO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 105,
            "district_name": "LWENGO",
            "county_id": 240,
            "county_name": "BUKOTO County",
            "constituency_id": 68,
            "constituency_name": "BUKOTO COUNTY WEST"
        },
        {
            "id": 1179,
            "name": "MANAFWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 2063,
            "name": "MANYOGASEKA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 276,
            "county_name": "KASSANDA County",
            "constituency_id": 314,
            "constituency_name": "KASSANDA COUNTY SOUTH"
        },
        {
            "id": 1350,
            "name": "MARACHA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 184,
            "county_name": "MARACHA EAST County",
            "constituency_id": 220,
            "constituency_name": "MARACHA EAST COUNTY"
        },
        {
            "id": 889,
            "name": "MASABA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 690,
            "name": "MASABA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 103,
            "county_name": "SAMIA BUGWE CENTRAL County",
            "constituency_id": 127,
            "constituency_name": "SAMIA BUGWE CENTRAL COUNTY"
        },
        {
            "id": 691,
            "name": "MASAFU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 103,
            "county_name": "SAMIA BUGWE CENTRAL County",
            "constituency_id": 127,
            "constituency_name": "SAMIA BUGWE CENTRAL COUNTY"
        },
        {
            "id": 692,
            "name": "MASAFU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 103,
            "county_name": "SAMIA BUGWE CENTRAL County",
            "constituency_id": 127,
            "constituency_name": "SAMIA BUGWE CENTRAL COUNTY"
        },
        {
            "id": 914,
            "name": "MASAJJA DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 128,
            "county_name": "MAKINDYE-SSABAGABO Municipality",
            "constituency_id": 159,
            "constituency_name": "MAKINDYE-SSABAGABO MUNICIPALITY"
        },
        {
            "id": 1180,
            "name": "MASAKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 1093,
            "name": "MASHA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 184,
            "constituency_name": "ISINGIRO COUNTY NORTH"
        },
        {
            "id": 1681,
            "name": "MASHERUKA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 232,
            "county_name": "SHEEMA County",
            "constituency_id": 268,
            "constituency_name": "SHEEMA COUNTY NORTH"
        },
        {
            "id": 1682,
            "name": "MASHERUKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 232,
            "county_name": "SHEEMA County",
            "constituency_id": 268,
            "constituency_name": "SHEEMA COUNTY NORTH"
        },
        {
            "id": 1584,
            "name": "MASINDI PORT",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO",
            "county_id": 215,
            "county_name": "KIBANDA SOUTH County",
            "constituency_id": 251,
            "constituency_name": "KIBANDA SOUTH COUNTY"
        },
        {
            "id": 693,
            "name": "MASINYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 103,
            "county_name": "SAMIA BUGWE CENTRAL County",
            "constituency_id": 127,
            "constituency_name": "SAMIA BUGWE CENTRAL COUNTY"
        },
        {
            "id": 1552,
            "name": "MASIRA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 210,
            "county_name": "ELGON NORTH County",
            "constituency_id": 246,
            "constituency_name": "ELGON NORTH COUNTY"
        },
        {
            "id": 1599,
            "name": "MASODDE-KALAGI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 217,
            "county_name": "NTWETWE County",
            "constituency_id": 253,
            "constituency_name": "NTWETWE COUNTY"
        },
        {
            "id": 899,
            "name": "MASULIITA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 124,
            "county_name": "BUSIRO County",
            "constituency_id": 154,
            "constituency_name": "BUSIRO COUNTY NORTH"
        },
        {
            "id": 900,
            "name": "MASULIITA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 124,
            "county_name": "BUSIRO County",
            "constituency_id": 154,
            "constituency_name": "BUSIRO COUNTY NORTH"
        },
        {
            "id": 234,
            "name": "MATALE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE",
            "county_id": 36,
            "county_name": "BUYANJA EAST County",
            "constituency_id": 46,
            "constituency_name": "BUYANJA EAST COUNTY"
        },
        {
            "id": 1767,
            "name": "MATANY",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK",
            "county_id": 244,
            "county_name": "BOKORA County",
            "constituency_id": 280,
            "constituency_name": "BOKORA COUNTY"
        },
        {
            "id": 1768,
            "name": "MATANY TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK",
            "county_id": 244,
            "county_name": "BOKORA County",
            "constituency_id": 280,
            "constituency_name": "BOKORA COUNTY"
        },
        {
            "id": 747,
            "name": "MATEETE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 111,
            "county_name": "MAWOGOLA County",
            "constituency_id": 136,
            "constituency_name": "MAWOGOLA COUNTY"
        },
        {
            "id": 748,
            "name": "MATEETE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 111,
            "county_name": "MAWOGOLA County",
            "constituency_id": 136,
            "constituency_name": "MAWOGOLA COUNTY"
        },
        {
            "id": 1758,
            "name": "MAYANGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 243,
            "county_name": "RUHINDA SOUTH County",
            "constituency_id": 279,
            "constituency_name": "RUHINDA SOUTH COUNTY"
        },
        {
            "id": 1196,
            "name": "MAYANZA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 164,
            "county_name": "BUTIRU County",
            "constituency_id": 198,
            "constituency_name": "BUTIRU COUNTY"
        },
        {
            "id": 739,
            "name": "MAYIRIKITI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 109,
            "county_name": "NAKASONGOLA County",
            "constituency_id": 134,
            "constituency_name": "NAKASONGOLA COUNTY"
        },
        {
            "id": 826,
            "name": "MAYUGE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 148,
            "constituency_name": "BUNYA COUNTY WEST"
        },
        {
            "id": 675,
            "name": "MAYUGE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 124,
            "constituency_name": "BUKOOLI COUNTY CENTRAL"
        },
        {
            "id": 110,
            "name": "MAZIBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 18,
            "county_name": "NDORWA County",
            "constituency_id": 20,
            "constituency_name": "NDORWA COUNTY EAST"
        },
        {
            "id": 1051,
            "name": "MAZIMASA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 145,
            "county_name": "BUNYOLE EAST County",
            "constituency_id": 177,
            "constituency_name": "BUNYOLE EAST COUNTY"
        },
        {
            "id": 140,
            "name": "MAZINGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 11,
            "district_name": "KALANGALA",
            "county_id": 21,
            "county_name": "KYAMUSWA County",
            "constituency_id": 24,
            "constituency_name": "KYAMUSWA COUNTY"
        },
        {
            "id": 1322,
            "name": "MAZUBA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 181,
            "county_name": "BUSIKI NORTH County",
            "constituency_id": 216,
            "constituency_name": "BUSIKI NORTH COUNTY"
        },
        {
            "id": 1080,
            "name": "MBAARE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 149,
            "county_name": "BUKANGA County",
            "constituency_id": 182,
            "constituency_name": "BUKANGA COUNTY"
        },
        {
            "id": 809,
            "name": "MBALE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 145,
            "constituency_name": "MWENGE COUNTY SOUTH"
        },
        {
            "id": 24,
            "name": "MBATYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 5,
            "county_name": "BUGHENDERA County",
            "constituency_id": 5,
            "constituency_name": "BUGHENDERA COUNTY"
        },
        {
            "id": 2056,
            "name": "MBIRIZI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 275,
            "county_name": "BUKUYA County",
            "constituency_id": 312,
            "constituency_name": "BUKUYA COUNTY"
        },
        {
            "id": 1585,
            "name": "MBOIRA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO",
            "county_id": 215,
            "county_name": "KIBANDA SOUTH County",
            "constituency_id": 251,
            "constituency_name": "KIBANDA SOUTH COUNTY"
        },
        {
            "id": 156,
            "name": "MBULAMUTI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 28,
            "county_name": "BUZAAYA County",
            "constituency_id": 36,
            "constituency_name": "BUZAAYA COUNTY"
        },
        {
            "id": 157,
            "name": "MBULAMUTI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 28,
            "county_name": "BUZAAYA County",
            "constituency_id": 36,
            "constituency_name": "BUZAAYA COUNTY"
        },
        {
            "id": 214,
            "name": "MBUNGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 43,
            "constituency_name": "BUSONGORA COUNTY SOUTH"
        },
        {
            "id": 629,
            "name": "MELLA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 94,
            "county_name": "TORORO SOUTH County",
            "constituency_id": 116,
            "constituency_name": "TORORO SOUTH COUNTY"
        },
        {
            "id": 894,
            "name": "MENDE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 124,
            "county_name": "BUSIRO County",
            "constituency_id": 153,
            "constituency_name": "BUSIRO COUNTY EAST"
        },
        {
            "id": 619,
            "name": "MERIKIT",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 93,
            "county_name": "TORORO NORTH County",
            "constituency_id": 115,
            "constituency_name": "TORORO NORTH COUNTY"
        },
        {
            "id": 620,
            "name": "MERIKIT TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 93,
            "county_name": "TORORO NORTH County",
            "constituency_id": 115,
            "constituency_name": "TORORO NORTH COUNTY"
        },
        {
            "id": 437,
            "name": "METU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 29,
            "district_name": "MOYO",
            "county_id": 64,
            "county_name": "WEST MOYO County",
            "constituency_id": 83,
            "constituency_name": "WEST MOYO COUNTY"
        },
        {
            "id": 1159,
            "name": "MIDIA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 66,
            "district_name": "KOBOKO",
            "county_id": 160,
            "county_name": "KOBOKO County",
            "constituency_id": 194,
            "constituency_name": "KOBOKO COUNTY"
        },
        {
            "id": 935,
            "name": "MIDIGO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 132,
            "county_name": "ARINGA NORTH County",
            "constituency_id": 163,
            "constituency_name": "ARINGA NORTH COUNTY"
        },
        {
            "id": 936,
            "name": "MIDIGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 132,
            "county_name": "ARINGA NORTH County",
            "constituency_id": 163,
            "constituency_name": "ARINGA NORTH COUNTY"
        },
        {
            "id": 1462,
            "name": "MIGAMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 199,
            "county_name": "KYAKA SOUTH County",
            "constituency_id": 235,
            "constituency_name": "KYAKA SOUTH COUNTY"
        },
        {
            "id": 729,
            "name": "MIGEERA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 108,
            "county_name": "BUDYEBO County",
            "constituency_id": 133,
            "constituency_name": "BUDYEBO COUNTY"
        },
        {
            "id": 2117,
            "name": "MIGINA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 131,
            "district_name": "KAZO",
            "county_id": 285,
            "county_name": "KAZO County",
            "constituency_id": 323,
            "constituency_name": "KAZO COUNTY"
        },
        {
            "id": 1459,
            "name": "MIGONGWE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 198,
            "county_name": "KYAKA NORTH County",
            "constituency_id": 234,
            "constituency_name": "KYAKA NORTH COUNTY"
        },
        {
            "id": 388,
            "name": "MIIRYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 55,
            "county_name": "BURULI County",
            "constituency_id": 73,
            "constituency_name": "BURULI COUNTY"
        },
        {
            "id": 753,
            "name": "MIJWALA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 112,
            "county_name": "MAWOGOLA NORTH County",
            "constituency_id": 137,
            "constituency_name": "MAWOGOLA NORTH COUNTY"
        },
        {
            "id": 1336,
            "name": "MINAKULU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 218,
            "constituency_name": "OYAM COUNTY SOUTH"
        },
        {
            "id": 1337,
            "name": "MINAKULU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 218,
            "constituency_name": "OYAM COUNTY SOUTH"
        },
        {
            "id": 41,
            "name": "MIRAMBI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 6,
            "county_name": "BWAMBA County",
            "constituency_id": 6,
            "constituency_name": "BWAMBA COUNTY"
        },
        {
            "id": 749,
            "name": "MITETE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 111,
            "county_name": "MAWOGOLA County",
            "constituency_id": 136,
            "constituency_name": "MAWOGOLA COUNTY"
        },
        {
            "id": 754,
            "name": "MITIMA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 112,
            "county_name": "MAWOGOLA NORTH County",
            "constituency_id": 137,
            "constituency_name": "MAWOGOLA NORTH COUNTY"
        },
        {
            "id": 1748,
            "name": "MITOOMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 241,
            "county_name": "RUHINDA County",
            "constituency_id": 277,
            "constituency_name": "RUHINDA COUNTY"
        },
        {
            "id": 1749,
            "name": "MITOOMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 241,
            "county_name": "RUHINDA County",
            "constituency_id": 277,
            "constituency_name": "RUHINDA COUNTY"
        },
        {
            "id": 621,
            "name": "MOLO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 93,
            "county_name": "TORORO NORTH County",
            "constituency_id": 115,
            "constituency_name": "TORORO NORTH COUNTY"
        },
        {
            "id": 988,
            "name": "MORUITA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 56,
            "district_name": "NAKAPIRIPIRIT",
            "county_id": 138,
            "county_name": "CHEKWII EAST County",
            "constituency_id": 170,
            "constituency_name": "CHEKWII EAST COUNTY"
        },
        {
            "id": 1779,
            "name": "MORUKAKISE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 108,
            "district_name": "NGORA",
            "county_id": 246,
            "county_name": "KAPIR County",
            "constituency_id": 282,
            "constituency_name": "KAPIR COUNTY"
        },
        {
            "id": 630,
            "name": "MORUKATIPE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 94,
            "county_name": "TORORO SOUTH County",
            "constituency_id": 116,
            "constituency_name": "TORORO SOUTH COUNTY"
        },
        {
            "id": 1241,
            "name": "MORULEM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 1242,
            "name": "MORULEM TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 1019,
            "name": "MORUNGATUNY",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 142,
            "county_name": "ORUNGO County",
            "constituency_id": 174,
            "constituency_name": "ORUNGO COUNTY"
        },
        {
            "id": 1124,
            "name": "MORUNGOLE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 155,
            "county_name": "IK County",
            "constituency_id": 189,
            "constituency_name": "IK COUNTY"
        },
        {
            "id": 438,
            "name": "MOYO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 29,
            "district_name": "MOYO",
            "county_id": 64,
            "county_name": "WEST MOYO County",
            "constituency_id": 83,
            "constituency_name": "WEST MOYO COUNTY"
        },
        {
            "id": 439,
            "name": "MOYO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 29,
            "district_name": "MOYO",
            "county_id": 64,
            "county_name": "WEST MOYO County",
            "constituency_id": 83,
            "constituency_name": "WEST MOYO COUNTY"
        },
        {
            "id": 1739,
            "name": "MOYOK",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 1463,
            "name": "MPARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 199,
            "county_name": "KYAKA SOUTH County",
            "constituency_id": 235,
            "constituency_name": "KYAKA SOUTH COUNTY"
        },
        {
            "id": 1464,
            "name": "MPARA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 199,
            "county_name": "KYAKA SOUTH County",
            "constituency_id": 235,
            "constituency_name": "KYAKA SOUTH COUNTY"
        },
        {
            "id": 2029,
            "name": "MPARO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 122,
            "district_name": "RUKIGA",
            "county_id": 272,
            "county_name": "RUKIGA County",
            "constituency_id": 309,
            "constituency_name": "RUKIGA COUNTY"
        },
        {
            "id": 1889,
            "name": "MPASAANA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 259,
            "county_name": "BUGANGAIZI EAST County",
            "constituency_id": 295,
            "constituency_name": "BUGANGAIZI EAST COUNTY"
        },
        {
            "id": 1890,
            "name": "MPASAANA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 259,
            "county_name": "BUGANGAIZI EAST County",
            "constituency_id": 295,
            "constituency_name": "BUGANGAIZI EAST COUNTY"
        },
        {
            "id": 473,
            "name": "MPATTA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 70,
            "county_name": "MUKONO County",
            "constituency_id": 91,
            "constituency_name": "MUKONO COUNTY SOUTH"
        },
        {
            "id": 1875,
            "name": "MPEEFU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 1876,
            "name": "MPEEFU YA SANDE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 1566,
            "name": "MPENJA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 91,
            "district_name": "GOMBA",
            "county_id": 212,
            "county_name": "GOMBA EAST County",
            "constituency_id": 248,
            "constituency_name": "GOMBA EAST COUNTY"
        },
        {
            "id": 443,
            "name": "MPIGI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 30,
            "district_name": "MPIGI",
            "county_id": 65,
            "county_name": "MAWOKOTA County",
            "constituency_id": 84,
            "constituency_name": "MAWOKOTA COUNTY NORTH"
        },
        {
            "id": 184,
            "name": "MPONDWE - LHUBIRIHA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 40,
            "constituency_name": "BUKONJO COUNTY WEST"
        },
        {
            "id": 1409,
            "name": "MPUMUDDE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 80,
            "district_name": "LYANTONDE",
            "county_id": 190,
            "county_name": "KABULA County",
            "constituency_id": 226,
            "constituency_name": "KABULA COUNTY"
        },
        {
            "id": 474,
            "name": "MPUNGE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 70,
            "county_name": "MUKONO County",
            "constituency_id": 91,
            "constituency_name": "MUKONO COUNTY SOUTH"
        },
        {
            "id": 977,
            "name": "MPUNGU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 168,
            "constituency_name": "KINKIZI COUNTY WEST"
        },
        {
            "id": 815,
            "name": "MPUNGWE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 146,
            "constituency_name": "BUNYA COUNTY EAST"
        },
        {
            "id": 207,
            "name": "MUBUKU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 42,
            "constituency_name": "BUSONGORA COUNTY NORTH"
        },
        {
            "id": 275,
            "name": "MUCHWINI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 42,
            "county_name": "CHUA EAST County",
            "constituency_id": 54,
            "constituency_name": "CHUA EAST COUNTY"
        },
        {
            "id": 276,
            "name": "MUCHWINI EAST",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 42,
            "county_name": "CHUA EAST County",
            "constituency_id": 54,
            "constituency_name": "CHUA EAST COUNTY"
        },
        {
            "id": 277,
            "name": "MUCHWINI WEST",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 42,
            "county_name": "CHUA EAST County",
            "constituency_id": 54,
            "constituency_name": "CHUA EAST COUNTY"
        },
        {
            "id": 444,
            "name": "MUDUMA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 30,
            "district_name": "MPIGI",
            "county_id": 65,
            "county_name": "MAWOKOTA County",
            "constituency_id": 84,
            "constituency_name": "MAWOKOTA COUNTY NORTH"
        },
        {
            "id": 227,
            "name": "MUGARAMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE",
            "county_id": 35,
            "county_name": "BUYANJA County",
            "constituency_id": 45,
            "constituency_name": "BUYANJA COUNTY"
        },
        {
            "id": 1278,
            "name": "MUGITI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 175,
            "county_name": "IKI-IKI County",
            "constituency_id": 210,
            "constituency_name": "IKI-IKI COUNTY"
        },
        {
            "id": 136,
            "name": "MUGOYE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 11,
            "district_name": "KALANGALA",
            "county_id": 20,
            "county_name": "BUJUMBA County",
            "constituency_id": 23,
            "constituency_name": "BUJUMBA COUNTY"
        },
        {
            "id": 130,
            "name": "MUGUSU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 131,
            "name": "MUGUSU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 2030,
            "name": "MUHANGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 122,
            "district_name": "RUKIGA",
            "county_id": 272,
            "county_name": "RUKIGA County",
            "constituency_id": 309,
            "constituency_name": "RUKIGA COUNTY"
        },
        {
            "id": 215,
            "name": "MUHOKYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 43,
            "constituency_name": "BUSONGORA COUNTY SOUTH"
        },
        {
            "id": 216,
            "name": "MUHOKYA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 43,
            "constituency_name": "BUSONGORA COUNTY SOUTH"
        },
        {
            "id": 1877,
            "name": "MUHORRO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 1878,
            "name": "MUHORRO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 1999,
            "name": "MUKHUYU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 1936,
            "name": "MUKO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 301,
            "constituency_name": "RUBANDA COUNTY WEST"
        },
        {
            "id": 320,
            "name": "MUKONGORO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 47,
            "county_name": "KANYUM County",
            "constituency_id": 59,
            "constituency_name": "KANYUM COUNTY"
        },
        {
            "id": 321,
            "name": "MUKONGORO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 47,
            "county_name": "KANYUM County",
            "constituency_id": 59,
            "constituency_name": "KANYUM COUNTY"
        },
        {
            "id": 486,
            "name": "MUKONO CENTRAL DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 72,
            "county_name": "MUKONO Municipality",
            "constituency_id": 93,
            "constituency_name": "MUKONO MUNICIPALITY"
        },
        {
            "id": 2012,
            "name": "MUKOTO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 270,
            "county_name": "NAMISINDWA County",
            "constituency_id": 307,
            "constituency_name": "NAMISINDWA COUNTY"
        },
        {
            "id": 622,
            "name": "MUKUJU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 93,
            "county_name": "TORORO NORTH County",
            "constituency_id": 115,
            "constituency_name": "TORORO NORTH COUNTY"
        },
        {
            "id": 1780,
            "name": "MUKURA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 108,
            "district_name": "NGORA",
            "county_id": 246,
            "county_name": "KAPIR County",
            "constituency_id": 282,
            "constituency_name": "KAPIR COUNTY"
        },
        {
            "id": 1781,
            "name": "MUKURA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 108,
            "district_name": "NGORA",
            "county_id": 246,
            "county_name": "KAPIR County",
            "constituency_id": 282,
            "constituency_name": "KAPIR COUNTY"
        },
        {
            "id": 1600,
            "name": "MULAGI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 217,
            "county_name": "NTWETWE County",
            "constituency_id": 253,
            "constituency_name": "NTWETWE COUNTY"
        },
        {
            "id": 632,
            "name": "MULANDA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 95,
            "county_name": "WEST BUDAMA CENTRAL County",
            "constituency_id": 117,
            "constituency_name": "WEST BUDAMA CENTRAL COUNTY"
        },
        {
            "id": 175,
            "name": "MUNARYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 31,
            "county_name": "TINGEY County",
            "constituency_id": 39,
            "constituency_name": "TINGEY COUNTY"
        },
        {
            "id": 194,
            "name": "MUNKUNYU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 41,
            "constituency_name": "BUKONZO COUNTY EAST"
        },
        {
            "id": 994,
            "name": "MUNTU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 139,
            "county_name": "KIOGA County",
            "constituency_id": 171,
            "constituency_name": "KIOGA COUNTY"
        },
        {
            "id": 255,
            "name": "MUPAKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 39,
            "county_name": "BUFUMBIRA County",
            "constituency_id": 50,
            "constituency_name": "BUFUMBIRA COUNTY NORTH"
        },
        {
            "id": 261,
            "name": "MURAMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 39,
            "county_name": "BUFUMBIRA County",
            "constituency_id": 51,
            "constituency_name": "BUFUMBIRA COUNTY SOUTH"
        },
        {
            "id": 252,
            "name": "MURORA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 39,
            "county_name": "BUFUMBIRA County",
            "constituency_id": 49,
            "constituency_name": "BUFUMBIRA COUNTY EAST"
        },
        {
            "id": 1759,
            "name": "MUTARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 243,
            "county_name": "RUHINDA SOUTH County",
            "constituency_id": 279,
            "constituency_name": "RUHINDA SOUTH COUNTY"
        },
        {
            "id": 1760,
            "name": "MUTARA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 243,
            "county_name": "RUHINDA SOUTH County",
            "constituency_id": 279,
            "constituency_name": "RUHINDA SOUTH COUNTY"
        },
        {
            "id": 676,
            "name": "MUTELELE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 124,
            "constituency_name": "BUKOOLI COUNTY CENTRAL"
        },
        {
            "id": 677,
            "name": "MUTERERE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 124,
            "constituency_name": "BUKOOLI COUNTY CENTRAL"
        },
        {
            "id": 890,
            "name": "MUTUFU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 1977,
            "name": "MUTUKULA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA",
            "county_id": 267,
            "county_name": "KAKUUTO County",
            "constituency_id": 304,
            "constituency_name": "KAKUUTO COUNTY"
        },
        {
            "id": 1628,
            "name": "MUTUMBA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 95,
            "district_name": "NAMAYINGO",
            "county_id": 222,
            "county_name": "NAMAYINGO SOUTH County",
            "constituency_id": 258,
            "constituency_name": "NAMAYINGO SOUTH COUNTY"
        },
        {
            "id": 1629,
            "name": "MUTUMBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 95,
            "district_name": "NAMAYINGO",
            "county_id": 222,
            "county_name": "NAMAYINGO SOUTH County",
            "constituency_id": 258,
            "constituency_name": "NAMAYINGO SOUTH COUNTY"
        },
        {
            "id": 1580,
            "name": "MUTUNDA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO",
            "county_id": 214,
            "county_name": "KIBANDA NORTH County",
            "constituency_id": 250,
            "constituency_name": "KIBANDA NORTH COUNTY"
        },
        {
            "id": 1042,
            "name": "MUTUSHET",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 144,
            "county_name": "TOO County",
            "constituency_id": 176,
            "constituency_name": "TOO COUNTY"
        },
        {
            "id": 242,
            "name": "MUWANGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA",
            "county_id": 37,
            "county_name": "KIBOGA EAST County",
            "constituency_id": 47,
            "constituency_name": "KIBOGA EAST COUNTY"
        },
        {
            "id": 1601,
            "name": "MUWANGI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 217,
            "county_name": "NTWETWE County",
            "constituency_id": 253,
            "constituency_name": "NTWETWE COUNTY"
        },
        {
            "id": 683,
            "name": "MUWAYO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 125,
            "constituency_name": "BUKOOLI COUNTY NORTH"
        },
        {
            "id": 1537,
            "name": "MUYEMBE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 208,
            "county_name": "BULAMBULI County",
            "constituency_id": 244,
            "constituency_name": "BULAMBULI COUNTY"
        },
        {
            "id": 633,
            "name": "MWELLO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 95,
            "county_name": "WEST BUDAMA CENTRAL County",
            "constituency_id": 117,
            "constituency_name": "WEST BUDAMA CENTRAL COUNTY"
        },
        {
            "id": 583,
            "name": "MWERUKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 85,
            "county_name": "KOOKI County",
            "constituency_id": 107,
            "constituency_name": "KOOKI COUNTY"
        },
        {
            "id": 1891,
            "name": "MWITANZIGE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 259,
            "county_name": "BUGANGAIZI EAST County",
            "constituency_id": 295,
            "constituency_name": "BUGANGAIZI EAST COUNTY"
        },
        {
            "id": 2157,
            "name": "MWIZI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 135,
            "district_name": "RWAMPARA",
            "county_id": 291,
            "county_name": "RWAMPARA EAST County",
            "constituency_id": 329,
            "constituency_name": "RWAMPARA EAST COUNTY"
        },
        {
            "id": 2064,
            "name": "MYANZI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 276,
            "county_name": "KASSANDA County",
            "constituency_id": 314,
            "constituency_name": "KASSANDA COUNTY SOUTH"
        },
        {
            "id": 1338,
            "name": "MYENE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 218,
            "constituency_name": "OYAM COUNTY SOUTH"
        },
        {
            "id": 1538,
            "name": "NABBONGO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 208,
            "county_name": "BULAMBULI County",
            "constituency_id": 244,
            "constituency_name": "BULAMBULI COUNTY"
        },
        {
            "id": 1052,
            "name": "NABIGANDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 145,
            "county_name": "BUNYOLE EAST County",
            "constituency_id": 177,
            "constituency_name": "BUNYOLE EAST COUNTY"
        },
        {
            "id": 1986,
            "name": "NABIGASA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA",
            "county_id": 268,
            "county_name": "KYOTERA County",
            "constituency_id": 305,
            "constituency_name": "KYOTERA COUNTY"
        },
        {
            "id": 2087,
            "name": "NABILATUK",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 128,
            "district_name": "NABILATUK",
            "county_id": 281,
            "county_name": "PIAN County",
            "constituency_id": 319,
            "constituency_name": "PIAN COUNTY"
        },
        {
            "id": 2088,
            "name": "NABILATUK TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 128,
            "district_name": "NABILATUK",
            "county_id": 281,
            "county_name": "PIAN County",
            "constituency_id": 319,
            "constituency_name": "PIAN COUNTY"
        },
        {
            "id": 464,
            "name": "NABINGOOLA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 68,
            "county_name": "KASAMBYA County",
            "constituency_id": 88,
            "constituency_name": "KASAMBYA COUNTY"
        },
        {
            "id": 465,
            "name": "NABINGOOLA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 68,
            "county_name": "KASAMBYA County",
            "constituency_id": 88,
            "constituency_name": "KASAMBYA COUNTY"
        },
        {
            "id": 1703,
            "name": "NABISWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 234,
            "county_name": "KABWERI County",
            "constituency_id": 271,
            "constituency_name": "KABWERI COUNTY"
        },
        {
            "id": 730,
            "name": "NABISWERA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 108,
            "county_name": "BUDYEBO County",
            "constituency_id": 133,
            "constituency_name": "BUDYEBO COUNTY"
        },
        {
            "id": 745,
            "name": "NABITANGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 110,
            "county_name": "LWEMIYAGA County",
            "constituency_id": 135,
            "constituency_name": "LWEMIYAGA COUNTY"
        },
        {
            "id": 89,
            "name": "NABITENDE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 7,
            "district_name": "IGANGA",
            "county_id": 13,
            "county_name": "KIGULU County",
            "constituency_id": 14,
            "constituency_name": "KIGULU COUNTY NORTH"
        },
        {
            "id": 2000,
            "name": "NABITSIKHI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 1543,
            "name": "NABIWUTULU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 209,
            "county_name": "ELGON County",
            "constituency_id": 245,
            "constituency_name": "ELGON COUNTY"
        },
        {
            "id": 1267,
            "name": "NABOA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 174,
            "county_name": "BUDAKA County",
            "constituency_id": 209,
            "constituency_name": "BUDAKA COUNTY"
        },
        {
            "id": 684,
            "name": "NABUKALU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 125,
            "constituency_name": "BUKOOLI COUNTY NORTH"
        },
        {
            "id": 685,
            "name": "NABUKALU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 125,
            "constituency_name": "BUKOOLI COUNTY NORTH"
        },
        {
            "id": 398,
            "name": "NABUMALI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 57,
            "county_name": "BUNGOKHO CENTRAL County",
            "constituency_id": 75,
            "constituency_name": "BUNGOKHO CENTRAL COUNTY"
        },
        {
            "id": 634,
            "name": "NABUYOGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 95,
            "county_name": "WEST BUDAMA CENTRAL County",
            "constituency_id": 117,
            "constituency_name": "WEST BUDAMA CENTRAL COUNTY"
        },
        {
            "id": 635,
            "name": "NABUYOGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 95,
            "county_name": "WEST BUDAMA CENTRAL County",
            "constituency_id": 117,
            "constituency_name": "WEST BUDAMA CENTRAL COUNTY"
        },
        {
            "id": 1774,
            "name": "NABWAL",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK",
            "county_id": 245,
            "county_name": "BOKORA EAST County",
            "constituency_id": 281,
            "constituency_name": "BOKORA EAST COUNTY"
        },
        {
            "id": 918,
            "name": "NABWERU DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 129,
            "county_name": "NANSANA Municipality",
            "constituency_id": 160,
            "constituency_name": "NANSANA MUNICIPALITY"
        },
        {
            "id": 1363,
            "name": "NABWEYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 185,
            "county_name": "BUSHIGAI County",
            "constituency_id": 221,
            "constituency_name": "BUSHIGAI COUNTY"
        },
        {
            "id": 1307,
            "name": "NABWEYO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 179,
            "county_name": "BUKONO County",
            "constituency_id": 214,
            "constituency_name": "BUKONO COUNTY"
        },
        {
            "id": 144,
            "name": "NABWIGULU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 27,
            "county_name": "BUGABULA County",
            "constituency_id": 34,
            "constituency_name": "BUGABULA COUNTY NORTH"
        },
        {
            "id": 424,
            "name": "NADUNGET",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 28,
            "district_name": "MOROTO",
            "county_id": 61,
            "county_name": "MATHENIKO County",
            "constituency_id": 80,
            "constituency_name": "MATHENIKO COUNTY"
        },
        {
            "id": 425,
            "name": "NADUNGET TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 28,
            "district_name": "MOROTO",
            "county_id": 61,
            "county_name": "MATHENIKO County",
            "constituency_id": 80,
            "constituency_name": "MATHENIKO COUNTY"
        },
        {
            "id": 480,
            "name": "NAGOJJE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 71,
            "county_name": "NAKIFUMA County",
            "constituency_id": 92,
            "constituency_name": "NAKIFUMA COUNTY"
        },
        {
            "id": 640,
            "name": "NAGONGERA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 96,
            "county_name": "WEST BUDAMA County",
            "constituency_id": 118,
            "constituency_name": "WEST BUDAMA COUNTY NORTH"
        },
        {
            "id": 641,
            "name": "NAGONGERA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 96,
            "county_name": "WEST BUDAMA County",
            "constituency_id": 118,
            "constituency_name": "WEST BUDAMA COUNTY NORTH"
        },
        {
            "id": 1563,
            "name": "NAIRAMBI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 90,
            "district_name": "BUVUMA",
            "county_id": 211,
            "county_name": "BUVUMA ISLANDS County",
            "constituency_id": 247,
            "constituency_name": "BUVUMA ISLANDS COUNTY"
        },
        {
            "id": 1424,
            "name": "NAJJA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE",
            "county_id": 192,
            "county_name": "BUIKWE County",
            "constituency_id": 228,
            "constituency_name": "BUIKWE COUNTY SOUTH"
        },
        {
            "id": 1430,
            "name": "NAJJEMBE DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE",
            "county_id": 193,
            "county_name": "LUGAZI Municipality",
            "constituency_id": 229,
            "constituency_name": "LUGAZI MUNICIPALITY"
        },
        {
            "id": 94,
            "name": "NAKALAMA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 7,
            "district_name": "IGANGA",
            "county_id": 13,
            "county_name": "KIGULU County",
            "constituency_id": 15,
            "constituency_name": "KIGULU COUNTY SOUTH"
        },
        {
            "id": 307,
            "name": "NAKAPELIMORU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 989,
            "name": "NAKAPIRIPIRIT TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 56,
            "district_name": "NAKAPIRIPIRIT",
            "county_id": 138,
            "county_name": "CHEKWII EAST County",
            "constituency_id": 170,
            "constituency_name": "CHEKWII EAST COUNTY"
        },
        {
            "id": 1226,
            "name": "NAKASEKE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 170,
            "county_name": "NAKASEKE SOUTH County",
            "constituency_id": 205,
            "constituency_name": "NAKASEKE SOUTH COUNTY"
        },
        {
            "id": 1218,
            "name": "NAKASEKE BUTALANGU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 168,
            "county_name": "NAKASEKE CENTRAL County",
            "constituency_id": 203,
            "constituency_name": "NAKASEKE CENTRAL COUNTY"
        },
        {
            "id": 1227,
            "name": "NAKASEKE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 170,
            "county_name": "NAKASEKE SOUTH County",
            "constituency_id": 205,
            "constituency_name": "NAKASEKE SOUTH COUNTY"
        },
        {
            "id": 758,
            "name": "NAKASENYI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 113,
            "county_name": "MAWOGOLA WEST County",
            "constituency_id": 138,
            "constituency_name": "MAWOGOLA WEST COUNTY"
        },
        {
            "id": 740,
            "name": "NAKASONGOLA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 109,
            "county_name": "NAKASONGOLA County",
            "constituency_id": 134,
            "constituency_name": "NAKASONGOLA COUNTY"
        },
        {
            "id": 243,
            "name": "NAKASOZI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA",
            "county_id": 37,
            "county_name": "KIBOGA EAST County",
            "constituency_id": 47,
            "constituency_name": "KIBOGA EAST COUNTY"
        },
        {
            "id": 1384,
            "name": "NAKATSI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 187,
            "county_name": "MANJIYA County",
            "constituency_id": 223,
            "constituency_name": "MANJIYA COUNTY"
        },
        {
            "id": 481,
            "name": "NAKIFUMA-NAGGALAMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 71,
            "county_name": "NAKIFUMA County",
            "constituency_id": 92,
            "constituency_name": "NAKIFUMA COUNTY"
        },
        {
            "id": 95,
            "name": "NAKIGO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 7,
            "district_name": "IGANGA",
            "county_id": 13,
            "county_name": "KIGULU County",
            "constituency_id": 15,
            "constituency_name": "KIGULU COUNTY SOUTH"
        },
        {
            "id": 475,
            "name": "NAKISUNGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 70,
            "county_name": "MUKONO County",
            "constituency_id": 91,
            "constituency_name": "MUKONO COUNTY SOUTH"
        },
        {
            "id": 731,
            "name": "NAKITOMA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 108,
            "county_name": "BUDYEBO County",
            "constituency_id": 133,
            "constituency_name": "BUDYEBO COUNTY"
        },
        {
            "id": 1181,
            "name": "NALONDO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 995,
            "name": "NALUBWOYO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 139,
            "county_name": "KIOGA County",
            "constituency_id": 171,
            "constituency_name": "KIOGA COUNTY"
        },
        {
            "id": 869,
            "name": "NALUSALA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 2065,
            "name": "NALUTUNTU",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 125,
            "district_name": "KASSANDA",
            "county_id": 276,
            "county_name": "KASSANDA County",
            "constituency_id": 314,
            "constituency_name": "KASSANDA COUNTY SOUTH"
        },
        {
            "id": 1376,
            "name": "NALWANZA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 186,
            "county_name": "LUTSESHE County",
            "constituency_id": 222,
            "constituency_name": "LUTSESHE COUNTY"
        },
        {
            "id": 1907,
            "name": "NALWEYO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 261,
            "county_name": "BUGANGAIZI WEST County",
            "constituency_id": 297,
            "constituency_name": "BUGANGAIZI WEST COUNTY"
        },
        {
            "id": 470,
            "name": "NAMA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 70,
            "county_name": "MUKONO County",
            "constituency_id": 90,
            "constituency_name": "MUKONO COUNTY NORTH"
        },
        {
            "id": 2013,
            "name": "NAMABYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 270,
            "county_name": "NAMISINDWA County",
            "constituency_id": 307,
            "constituency_name": "NAMISINDWA COUNTY"
        },
        {
            "id": 100,
            "name": "NAMAGERA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 8,
            "district_name": "JINJA",
            "county_id": 15,
            "county_name": "KAGOMA County",
            "constituency_id": 17,
            "constituency_name": "KAGOMA COUNTY"
        },
        {
            "id": 891,
            "name": "NAMAGULI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 2038,
            "name": "NAMALEMBA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 123,
            "district_name": "BUGWERI",
            "county_id": 273,
            "county_name": "BUGWERI County",
            "constituency_id": 310,
            "constituency_name": "BUGWERI COUNTY"
        },
        {
            "id": 986,
            "name": "NAMALU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 56,
            "district_name": "NAKAPIRIPIRIT",
            "county_id": 137,
            "county_name": "CHEKWII County",
            "constituency_id": 169,
            "constituency_name": "CHEKWII COUNTY"
        },
        {
            "id": 145,
            "name": "NAMASAGALI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 27,
            "county_name": "BUGABULA County",
            "constituency_id": 34,
            "constituency_name": "BUGABULA COUNTY NORTH"
        },
        {
            "id": 996,
            "name": "NAMASALE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 139,
            "county_name": "KIOGA County",
            "constituency_id": 171,
            "constituency_name": "KIOGA COUNTY"
        },
        {
            "id": 997,
            "name": "NAMASALE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 139,
            "county_name": "KIOGA County",
            "constituency_id": 171,
            "constituency_name": "KIOGA COUNTY"
        },
        {
            "id": 482,
            "name": "NAMATABA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 71,
            "county_name": "NAKIFUMA County",
            "constituency_id": 92,
            "constituency_name": "NAKIFUMA COUNTY"
        },
        {
            "id": 686,
            "name": "NAMAYEMBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 125,
            "constituency_name": "BUKOOLI COUNTY NORTH"
        },
        {
            "id": 1624,
            "name": "NAMAYINGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 95,
            "district_name": "NAMAYINGO",
            "county_id": 221,
            "county_name": "BUKOOLI SOUTH County",
            "constituency_id": 257,
            "constituency_name": "BUKOOLI SOUTH COUNTY"
        },
        {
            "id": 901,
            "name": "NAMAYUMBA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 124,
            "county_name": "BUSIRO County",
            "constituency_id": 154,
            "constituency_name": "BUSIRO COUNTY NORTH"
        },
        {
            "id": 902,
            "name": "NAMAYUMBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 124,
            "county_name": "BUSIRO County",
            "constituency_id": 154,
            "constituency_name": "BUSIRO COUNTY NORTH"
        },
        {
            "id": 90,
            "name": "NAMBALE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 7,
            "district_name": "IGANGA",
            "county_id": 13,
            "county_name": "KIGULU County",
            "constituency_id": 14,
            "constituency_name": "KIGULU COUNTY NORTH"
        },
        {
            "id": 2077,
            "name": "NAMBIESO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 127,
            "district_name": "KWANIA",
            "county_id": 279,
            "county_name": "KWANIA County",
            "constituency_id": 317,
            "constituency_name": "KWANIA COUNTY"
        },
        {
            "id": 2001,
            "name": "NAMBOKO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 2014,
            "name": "NAMISINDWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 270,
            "county_name": "NAMISINDWA County",
            "constituency_id": 307,
            "constituency_name": "NAMISINDWA COUNTY"
        },
        {
            "id": 1553,
            "name": "NAMISUNI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 210,
            "county_name": "ELGON NORTH County",
            "constituency_id": 246,
            "constituency_name": "ELGON NORTH COUNTY"
        },
        {
            "id": 2002,
            "name": "NAMITSA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 269,
            "county_name": "BUBULO EAST County",
            "constituency_id": 306,
            "constituency_name": "BUBULO EAST COUNTY"
        },
        {
            "id": 278,
            "name": "NAMOKORA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 42,
            "county_name": "CHUA EAST County",
            "constituency_id": 54,
            "constituency_name": "CHUA EAST COUNTY"
        },
        {
            "id": 279,
            "name": "NAMOKORA NORTH",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 42,
            "county_name": "CHUA EAST County",
            "constituency_id": 54,
            "constituency_name": "CHUA EAST COUNTY"
        },
        {
            "id": 280,
            "name": "NAMOKORA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 42,
            "county_name": "CHUA EAST County",
            "constituency_id": 54,
            "constituency_name": "CHUA EAST COUNTY"
        },
        {
            "id": 870,
            "name": "NAMUGABWE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 483,
            "name": "NAMUGANGA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 71,
            "county_name": "NAKIFUMA County",
            "constituency_id": 92,
            "constituency_name": "NAKIFUMA COUNTY"
        },
        {
            "id": 1134,
            "name": "NAMUGONGO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 156,
            "county_name": "BULAMOGI County",
            "constituency_id": 190,
            "constituency_name": "BULAMOGI COUNTY"
        },
        {
            "id": 912,
            "name": "NAMUGONGO DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 127,
            "county_name": "KIRA Municipality",
            "constituency_id": 158,
            "constituency_name": "KIRA MUNICIPALITY"
        },
        {
            "id": 91,
            "name": "NAMUNGALWE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 7,
            "district_name": "IGANGA",
            "county_id": 13,
            "county_name": "KIGULU County",
            "constituency_id": 14,
            "constituency_name": "KIGULU COUNTY NORTH"
        },
        {
            "id": 1209,
            "name": "NAMUNGO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 166,
            "county_name": "MITYANA County",
            "constituency_id": 201,
            "constituency_name": "MITYANA COUNTY SOUTH"
        },
        {
            "id": 699,
            "name": "NAMUNGODI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 104,
            "county_name": "SAMIA BUGWE County",
            "constituency_id": 128,
            "constituency_name": "SAMIA BUGWE COUNTY NORTH"
        },
        {
            "id": 1314,
            "name": "NAMUTUMBA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 180,
            "county_name": "BUSIKI County",
            "constituency_id": 215,
            "constituency_name": "BUSIKI COUNTY"
        },
        {
            "id": 1315,
            "name": "NAMUTUMBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 180,
            "county_name": "BUSIKI County",
            "constituency_id": 215,
            "constituency_name": "BUSIKI COUNTY"
        },
        {
            "id": 149,
            "name": "NAMWENDWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 27,
            "county_name": "BUGABULA County",
            "constituency_id": 35,
            "constituency_name": "BUGABULA COUNTY SOUTH"
        },
        {
            "id": 150,
            "name": "NAMWENDWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 27,
            "county_name": "BUGABULA County",
            "constituency_id": 35,
            "constituency_name": "BUGABULA COUNTY SOUTH"
        },
        {
            "id": 1135,
            "name": "NAMWIWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 156,
            "county_name": "BULAMOGI County",
            "constituency_id": 190,
            "constituency_name": "BULAMOGI COUNTY"
        },
        {
            "id": 1136,
            "name": "NAMWIWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 156,
            "county_name": "BULAMOGI County",
            "constituency_id": 190,
            "constituency_name": "BULAMOGI COUNTY"
        },
        {
            "id": 1704,
            "name": "NANDERE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 234,
            "county_name": "KABWERI County",
            "constituency_id": 271,
            "constituency_name": "KABWERI COUNTY"
        },
        {
            "id": 1385,
            "name": "NANGAKO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 78,
            "district_name": "BUDUDA",
            "county_id": 187,
            "county_name": "MANJIYA County",
            "constituency_id": 223,
            "constituency_name": "MANJIYA COUNTY"
        },
        {
            "id": 1182,
            "name": "NANGALWE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 1978,
            "name": "NANGOMA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 119,
            "district_name": "KYOTERA",
            "county_id": 267,
            "county_name": "KAKUUTO County",
            "constituency_id": 304,
            "constituency_name": "KAKUUTO COUNTY"
        },
        {
            "id": 1308,
            "name": "NANGONDE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 179,
            "county_name": "BUKONO County",
            "constituency_id": 214,
            "constituency_name": "BUKONO COUNTY"
        },
        {
            "id": 1309,
            "name": "NANGONDE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 179,
            "county_name": "BUKONO County",
            "constituency_id": 214,
            "constituency_name": "BUKONO COUNTY"
        },
        {
            "id": 1712,
            "name": "NANKODO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 235,
            "county_name": "KIBUKU County",
            "constituency_id": 272,
            "constituency_name": "KIBUKU COUNTY"
        },
        {
            "id": 678,
            "name": "NANKOMA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 124,
            "constituency_name": "BUKOOLI COUNTY CENTRAL"
        },
        {
            "id": 679,
            "name": "NANKOMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 101,
            "county_name": "BUKOOLI County",
            "constituency_id": 124,
            "constituency_name": "BUKOOLI COUNTY CENTRAL"
        },
        {
            "id": 919,
            "name": "NANSANA DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 129,
            "county_name": "NANSANA Municipality",
            "constituency_id": 160,
            "constituency_name": "NANSANA MUNICIPALITY"
        },
        {
            "id": 1268,
            "name": "NANSANGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 174,
            "county_name": "BUDAKA County",
            "constituency_id": 209,
            "constituency_name": "BUDAKA COUNTY"
        },
        {
            "id": 1138,
            "name": "NANSOLOLO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 157,
            "county_name": "BULAMOGI NORTH WEST County",
            "constituency_id": 191,
            "constituency_name": "BULAMOGI NORTH WEST COUNTY"
        },
        {
            "id": 1775,
            "name": "NAPAK TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK",
            "county_id": 245,
            "county_name": "BOKORA EAST County",
            "constituency_id": 281,
            "constituency_name": "BOKORA EAST COUNTY"
        },
        {
            "id": 308,
            "name": "NAPUMPUM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 2089,
            "name": "NATIRAE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 128,
            "district_name": "NABILATUK",
            "county_id": 281,
            "county_name": "PIAN County",
            "constituency_id": 319,
            "constituency_name": "PIAN COUNTY"
        },
        {
            "id": 1139,
            "name": "NAWAIKOKE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 157,
            "county_name": "BULAMOGI NORTH WEST County",
            "constituency_id": 191,
            "constituency_name": "BULAMOGI NORTH WEST COUNTY"
        },
        {
            "id": 1140,
            "name": "NAWAIKOKE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 64,
            "district_name": "KALIRO",
            "county_id": 157,
            "county_name": "BULAMOGI NORTH WEST County",
            "constituency_id": 191,
            "constituency_name": "BULAMOGI NORTH WEST COUNTY"
        },
        {
            "id": 1316,
            "name": "NAWAIKONA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 180,
            "county_name": "BUSIKI County",
            "constituency_id": 215,
            "constituency_name": "BUSIKI COUNTY"
        },
        {
            "id": 1617,
            "name": "NAWAMPITI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 94,
            "district_name": "LUUKA",
            "county_id": 219,
            "county_name": "LUUKA SOUTH County",
            "constituency_id": 255,
            "constituency_name": "LUUKA SOUTH COUNTY"
        },
        {
            "id": 92,
            "name": "NAWANDALA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 7,
            "district_name": "IGANGA",
            "county_id": 13,
            "county_name": "KIGULU County",
            "constituency_id": 14,
            "constituency_name": "KIGULU COUNTY NORTH"
        },
        {
            "id": 1060,
            "name": "NAWANJOFU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 146,
            "county_name": "BUNYOLE WEST County",
            "constituency_id": 178,
            "constituency_name": "BUNYOLE WEST COUNTY"
        },
        {
            "id": 158,
            "name": "NAWANYAGO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 28,
            "county_name": "BUZAAYA County",
            "constituency_id": 36,
            "constituency_name": "BUZAAYA COUNTY"
        },
        {
            "id": 159,
            "name": "NAWANYAGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 28,
            "county_name": "BUZAAYA County",
            "constituency_id": 36,
            "constituency_name": "BUZAAYA COUNTY"
        },
        {
            "id": 96,
            "name": "NAWANYINGI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 7,
            "district_name": "IGANGA",
            "county_id": 13,
            "county_name": "KIGULU County",
            "constituency_id": 15,
            "constituency_name": "KIGULU COUNTY SOUTH"
        },
        {
            "id": 1053,
            "name": "NAWEYO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 60,
            "district_name": "BUTALEJA",
            "county_id": 145,
            "county_name": "BUNYOLE EAST County",
            "constituency_id": 177,
            "constituency_name": "BUNYOLE EAST COUNTY"
        },
        {
            "id": 651,
            "name": "NAWIRE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 97,
            "county_name": "WEST BUDAMA NORTH EAST County",
            "constituency_id": 120,
            "constituency_name": "WEST BUDAMA NORTH EAST COUNTY"
        },
        {
            "id": 779,
            "name": "NAZIGO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA",
            "county_id": 117,
            "county_name": "NTENJERU County",
            "constituency_id": 142,
            "constituency_name": "NTENJERU COUNTY SOUTH"
        },
        {
            "id": 780,
            "name": "NAZIGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 47,
            "district_name": "KAYUNGA",
            "county_id": 117,
            "county_name": "NTENJERU County",
            "constituency_id": 142,
            "constituency_name": "NTENJERU COUNTY SOUTH"
        },
        {
            "id": 364,
            "name": "NDAGWE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 105,
            "district_name": "LWENGO",
            "county_id": 240,
            "county_name": "BUKOTO County",
            "constituency_id": 67,
            "constituency_name": "BUKOTO COUNTY MID-WEST"
        },
        {
            "id": 1879,
            "name": "NDAIGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 2153,
            "name": "NDEIJA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 135,
            "district_name": "RWAMPARA",
            "county_id": 290,
            "county_name": "RWAMPARA County",
            "constituency_id": 328,
            "constituency_name": "RWAMPARA COUNTY"
        },
        {
            "id": 915,
            "name": "NDEJJE DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 128,
            "county_name": "MAKINDYE-SSABAGABO Municipality",
            "constituency_id": 159,
            "constituency_name": "MAKINDYE-SSABAGABO MUNICIPALITY"
        },
        {
            "id": 359,
            "name": "NDEJJE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 52,
            "county_name": "KATIKAMU County",
            "constituency_id": 66,
            "constituency_name": "KATIKAMU COUNTY SOUTH"
        },
        {
            "id": 497,
            "name": "NDHEW",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 74,
            "county_name": "PADYERE County",
            "constituency_id": 95,
            "constituency_name": "PADYERE COUNTY"
        },
        {
            "id": 1446,
            "name": "NDOLWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE",
            "county_id": 196,
            "county_name": "BUDIOPE WEST County",
            "constituency_id": 232,
            "constituency_name": "BUDIOPE WEST COUNTY"
        },
        {
            "id": 25,
            "name": "NDUGUTU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 5,
            "county_name": "BUGHENDERA County",
            "constituency_id": 5,
            "constituency_name": "BUGHENDERA COUNTY"
        },
        {
            "id": 498,
            "name": "NEBBI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 74,
            "county_name": "PADYERE County",
            "constituency_id": 95,
            "constituency_name": "PADYERE COUNTY"
        },
        {
            "id": 1328,
            "name": "NGAI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 217,
            "constituency_name": "OYAM COUNTY NORTH"
        },
        {
            "id": 26,
            "name": "NGAMBA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 5,
            "county_name": "BUGHENDERA County",
            "constituency_id": 5,
            "constituency_name": "BUGHENDERA COUNTY"
        },
        {
            "id": 1439,
            "name": "NGANDHO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE",
            "county_id": 195,
            "county_name": "BUDIOPE EAST County",
            "constituency_id": 231,
            "constituency_name": "BUDIOPE EAST COUNTY"
        },
        {
            "id": 1670,
            "name": "NGANDO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 99,
            "district_name": "BUTAMBALA",
            "county_id": 229,
            "county_name": "BUTAMBALA County",
            "constituency_id": 265,
            "constituency_name": "BUTAMBALA COUNTY"
        },
        {
            "id": 1084,
            "name": "NGARAMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 150,
            "county_name": "BUKANGA NORTH County",
            "constituency_id": 183,
            "constituency_name": "BUKANGA NORTH COUNTY"
        },
        {
            "id": 711,
            "name": "NGARIAM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 105,
            "county_name": "NGARIAM County",
            "constituency_id": 130,
            "constituency_name": "NGARIAM COUNTY"
        },
        {
            "id": 1743,
            "name": "NGENGE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 239,
            "county_name": "SOI County",
            "constituency_id": 276,
            "constituency_name": "SOI COUNTY"
        },
        {
            "id": 27,
            "name": "NGITE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 5,
            "county_name": "BUGHENDERA County",
            "constituency_id": 5,
            "constituency_name": "BUGHENDERA COUNTY"
        },
        {
            "id": 1425,
            "name": "NGOGWE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE",
            "county_id": 192,
            "county_name": "BUIKWE County",
            "constituency_id": 228,
            "constituency_name": "BUIKWE COUNTY SOUTH"
        },
        {
            "id": 1776,
            "name": "NGOLERIET",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK",
            "county_id": 245,
            "county_name": "BOKORA EAST County",
            "constituency_id": 281,
            "constituency_name": "BOKORA EAST COUNTY"
        },
        {
            "id": 531,
            "name": "NGOMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 79,
            "county_name": "RUSHENYI County",
            "constituency_id": 100,
            "constituency_name": "RUSHENYI COUNTY"
        },
        {
            "id": 1222,
            "name": "NGOMA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 169,
            "county_name": "NAKASEKE NORTH County",
            "constituency_id": 204,
            "constituency_name": "NAKASEKE NORTH COUNTY"
        },
        {
            "id": 1223,
            "name": "NGOMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 169,
            "county_name": "NAKASEKE NORTH County",
            "constituency_id": 204,
            "constituency_name": "NAKASEKE NORTH COUNTY"
        },
        {
            "id": 1784,
            "name": "NGORA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 108,
            "district_name": "NGORA",
            "county_id": 247,
            "county_name": "NGORA County",
            "constituency_id": 283,
            "constituency_name": "NGORA COUNTY"
        },
        {
            "id": 1785,
            "name": "NGORA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 108,
            "district_name": "NGORA",
            "county_id": 247,
            "county_name": "NGORA County",
            "constituency_id": 283,
            "constituency_name": "NGORA COUNTY"
        },
        {
            "id": 1288,
            "name": "NGWEDO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 73,
            "district_name": "BULIISA",
            "county_id": 176,
            "county_name": "BULIISA County",
            "constituency_id": 211,
            "constituency_name": "BULIISA COUNTY"
        },
        {
            "id": 1431,
            "name": "NJERU DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE",
            "county_id": 194,
            "county_name": "NJERU Municipality",
            "constituency_id": 230,
            "constituency_name": "NJERU MUNICIPALITY"
        },
        {
            "id": 1460,
            "name": "NKAAKWA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 198,
            "county_name": "KYAKA NORTH County",
            "constituency_id": 234,
            "constituency_name": "KYAKA NORTH COUNTY"
        },
        {
            "id": 1602,
            "name": "NKANDWA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 217,
            "county_name": "NTWETWE County",
            "constituency_id": 253,
            "constituency_name": "NTWETWE COUNTY"
        },
        {
            "id": 249,
            "name": "NKANDWA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 17,
            "district_name": "KIBOGA",
            "county_id": 38,
            "county_name": "KIBOGA WEST County",
            "constituency_id": 48,
            "constituency_name": "KIBOGA WEST COUNTY"
        },
        {
            "id": 59,
            "name": "NKANGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 8,
            "county_name": "IGARA County",
            "constituency_id": 9,
            "constituency_name": "IGARA COUNTY WEST"
        },
        {
            "id": 1452,
            "name": "NKANJA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 197,
            "county_name": "KYAKA CENTRAL County",
            "constituency_id": 233,
            "constituency_name": "KYAKA CENTRAL COUNTY"
        },
        {
            "id": 1426,
            "name": "NKOKONJERU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE",
            "county_id": 192,
            "county_name": "BUIKWE County",
            "constituency_id": 228,
            "constituency_name": "BUIKWE COUNTY SOUTH"
        },
        {
            "id": 766,
            "name": "NKOMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 115,
            "county_name": "KIBALE EAST County",
            "constituency_id": 139,
            "constituency_name": "KIBALE EAST COUNTY"
        },
        {
            "id": 767,
            "name": "NKOMA-KATALYEBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 115,
            "county_name": "KIBALE EAST County",
            "constituency_id": 139,
            "constituency_name": "KIBALE EAST COUNTY"
        },
        {
            "id": 1447,
            "name": "NKONDO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 83,
            "district_name": "BUYENDE",
            "county_id": 196,
            "county_name": "BUDIOPE WEST County",
            "constituency_id": 232,
            "constituency_name": "BUDIOPE WEST COUNTY"
        },
        {
            "id": 1892,
            "name": "NKOOKO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 259,
            "county_name": "BUGANGAIZI EAST County",
            "constituency_id": 295,
            "constituency_name": "BUGANGAIZI EAST COUNTY"
        },
        {
            "id": 1893,
            "name": "NKOOKO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 259,
            "county_name": "BUGANGAIZI EAST County",
            "constituency_id": 295,
            "constituency_name": "BUGANGAIZI EAST COUNTY"
        },
        {
            "id": 449,
            "name": "NKOZI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 30,
            "district_name": "MPIGI",
            "county_id": 65,
            "county_name": "MAWOKOTA County",
            "constituency_id": 85,
            "constituency_name": "MAWOKOTA COUNTY SOUTH"
        },
        {
            "id": 2118,
            "name": "NKUNGU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 131,
            "district_name": "KAZO",
            "county_id": 285,
            "county_name": "KAZO County",
            "constituency_id": 323,
            "constituency_name": "KAZO COUNTY"
        },
        {
            "id": 266,
            "name": "NKURINGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 40,
            "county_name": "BUKIMBIRI County",
            "constituency_id": 52,
            "constituency_name": "BUKIMBIRI COUNTY"
        },
        {
            "id": 1637,
            "name": "NOMBE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 96,
            "district_name": "NTOROKO",
            "county_id": 223,
            "county_name": "NTOROKO County",
            "constituency_id": 259,
            "constituency_name": "NTOROKO COUNTY"
        },
        {
            "id": 429,
            "name": "NORTH DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 28,
            "district_name": "MOROTO",
            "county_id": 63,
            "county_name": "MOROTO Municipality",
            "constituency_id": 82,
            "constituency_name": "MOROTO MUNICIPALITY"
        },
        {
            "id": 1163,
            "name": "NORTH DIVISION",
            "type": "Municipal-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 66,
            "district_name": "KOBOKO",
            "county_id": 162,
            "county_name": "KOBOKO Municipality",
            "constituency_id": 196,
            "constituency_name": "KOBOKO MUNICIPALITY"
        },
        {
            "id": 272,
            "name": "NORTH DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 41,
            "county_name": "KISORO Municipality",
            "constituency_id": 53,
            "constituency_name": "KISORO MUNICIPALITY"
        },
        {
            "id": 331,
            "name": "NORTH DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 49,
            "county_name": "KUMI Municipality",
            "constituency_id": 61,
            "constituency_name": "KUMI MUNICIPALITY"
        },
        {
            "id": 312,
            "name": "NORTH DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 46,
            "county_name": "KOTIDO Municipality",
            "constituency_id": 58,
            "constituency_name": "KOTIDO MUNICIPALITY"
        },
        {
            "id": 161,
            "name": "NORTHERN DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 29,
            "county_name": "KAMULI Municipality",
            "constituency_id": 37,
            "constituency_name": "KAMULI MUNICIPALITY"
        },
        {
            "id": 87,
            "name": "NORTHERN DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 7,
            "district_name": "IGANGA",
            "county_id": 12,
            "county_name": "IGANGA Municipality",
            "constituency_id": 13,
            "constituency_name": "IGANGA MUNICIPALITY"
        },
        {
            "id": 1594,
            "name": "NSAMBYA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 216,
            "county_name": "BUTEMBA County",
            "constituency_id": 252,
            "constituency_name": "BUTEMBA COUNTY"
        },
        {
            "id": 1937,
            "name": "NSHANJARE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 301,
            "constituency_name": "RUBANDA COUNTY WEST"
        },
        {
            "id": 1792,
            "name": "NSIIKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU",
            "county_id": 248,
            "county_name": "BUHWEJU County",
            "constituency_id": 284,
            "constituency_name": "BUHWEJU COUNTY"
        },
        {
            "id": 1317,
            "name": "NSINZE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 180,
            "county_name": "BUSIKI County",
            "constituency_id": 215,
            "constituency_name": "BUSIKI COUNTY"
        },
        {
            "id": 1318,
            "name": "NSINZE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 75,
            "district_name": "NAMUTUMBA",
            "county_id": 180,
            "county_name": "BUSIKI County",
            "constituency_id": 215,
            "constituency_name": "BUSIKI COUNTY"
        },
        {
            "id": 28,
            "name": "NTANDI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 5,
            "county_name": "BUGHENDERA County",
            "constituency_id": 5,
            "constituency_name": "BUGHENDERA COUNTY"
        },
        {
            "id": 584,
            "name": "NTANTAMUKI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 85,
            "county_name": "KOOKI County",
            "constituency_id": 107,
            "constituency_name": "KOOKI COUNTY"
        },
        {
            "id": 2129,
            "name": "NTARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 132,
            "district_name": "KITAGWENDA",
            "county_id": 286,
            "county_name": "KITAGWENDA County",
            "constituency_id": 324,
            "constituency_name": "KITAGWENDA COUNTY"
        },
        {
            "id": 476,
            "name": "NTENJERU-KISOGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 70,
            "county_name": "MUKONO County",
            "constituency_id": 91,
            "constituency_name": "MUKONO COUNTY SOUTH"
        },
        {
            "id": 764,
            "name": "NTONWA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 115,
            "county_name": "KIBALE EAST County",
            "constituency_id": 139,
            "constituency_name": "KIBALE EAST COUNTY"
        },
        {
            "id": 29,
            "name": "NTOTORO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 5,
            "county_name": "BUGHENDERA County",
            "constituency_id": 5,
            "constituency_name": "BUGHENDERA COUNTY"
        },
        {
            "id": 484,
            "name": "NTUNDA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 32,
            "district_name": "MUKONO",
            "county_id": 71,
            "county_name": "NAKIFUMA County",
            "constituency_id": 92,
            "constituency_name": "NAKIFUMA COUNTY"
        },
        {
            "id": 1595,
            "name": "NTUNDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 216,
            "county_name": "BUTEMBA County",
            "constituency_id": 252,
            "constituency_name": "BUTEMBA COUNTY"
        },
        {
            "id": 518,
            "name": "NTUNGAMO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 77,
            "county_name": "RUHAAMA County",
            "constituency_id": 98,
            "constituency_name": "RUHAAMA COUNTY"
        },
        {
            "id": 1100,
            "name": "NTUNGU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 185,
            "constituency_name": "ISINGIRO COUNTY SOUTH"
        },
        {
            "id": 746,
            "name": "NTUUSI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 110,
            "county_name": "LWEMIYAGA County",
            "constituency_id": 135,
            "constituency_name": "LWEMIYAGA COUNTY"
        },
        {
            "id": 1603,
            "name": "NTWETWE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 217,
            "county_name": "NTWETWE County",
            "constituency_id": 253,
            "constituency_name": "NTWETWE COUNTY"
        },
        {
            "id": 1604,
            "name": "NTWETWE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 217,
            "county_name": "NTWETWE County",
            "constituency_id": 253,
            "constituency_name": "NTWETWE COUNTY"
        },
        {
            "id": 2130,
            "name": "NYABBANI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 132,
            "district_name": "KITAGWENDA",
            "county_id": 286,
            "county_name": "KITAGWENDA County",
            "constituency_id": 324,
            "constituency_name": "KITAGWENDA COUNTY"
        },
        {
            "id": 510,
            "name": "NYABIHOKO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 76,
            "county_name": "KAJARA County",
            "constituency_id": 97,
            "constituency_name": "KAJARA COUNTY"
        },
        {
            "id": 797,
            "name": "NYABIRONGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 144,
            "constituency_name": "MWENGE COUNTY NORTH"
        },
        {
            "id": 413,
            "name": "NYABISIRIRA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 27,
            "district_name": "MBARARA",
            "county_id": 59,
            "county_name": "KASHARI NORTH County",
            "constituency_id": 78,
            "constituency_name": "KASHARI NORTH COUNTY"
        },
        {
            "id": 60,
            "name": "NYABUBARE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 8,
            "county_name": "IGARA County",
            "constituency_id": 9,
            "constituency_name": "IGARA COUNTY WEST"
        },
        {
            "id": 810,
            "name": "NYABUHARWA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 145,
            "constituency_name": "MWENGE COUNTY SOUTH"
        },
        {
            "id": 1072,
            "name": "NYABUHIKYE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 147,
            "county_name": "IBANDA County",
            "constituency_id": 180,
            "constituency_name": "IBANDA COUNTY SOUTH"
        },
        {
            "id": 511,
            "name": "NYABUSHENYI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 76,
            "county_name": "KAJARA County",
            "constituency_id": 97,
            "constituency_name": "KAJARA COUNTY"
        },
        {
            "id": 1863,
            "name": "NYABUTANZI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 267,
            "name": "NYABWISHENYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 40,
            "county_name": "BUKIMBIRI County",
            "constituency_id": 52,
            "constituency_name": "BUKIMBIRI COUNTY"
        },
        {
            "id": 1351,
            "name": "NYADRI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 184,
            "county_name": "MARACHA EAST County",
            "constituency_id": 220,
            "constituency_name": "MARACHA EAST COUNTY"
        },
        {
            "id": 1352,
            "name": "NYADRI SOUTH",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 184,
            "county_name": "MARACHA EAST County",
            "constituency_id": 220,
            "constituency_name": "MARACHA EAST COUNTY"
        },
        {
            "id": 42,
            "name": "NYAHUKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 6,
            "county_name": "BWAMBA County",
            "constituency_id": 6,
            "constituency_name": "BWAMBA COUNTY"
        },
        {
            "id": 253,
            "name": "NYAKABANDE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 39,
            "county_name": "BUFUMBIRA County",
            "constituency_id": 49,
            "constituency_name": "BUFUMBIRA COUNTY EAST"
        },
        {
            "id": 217,
            "name": "NYAKABINGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 43,
            "constituency_name": "BUSONGORA COUNTY SOUTH"
        },
        {
            "id": 46,
            "name": "NYAKABIRIZI DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 7,
            "county_name": "BUSHENYI-ISHAKA Municipality",
            "constituency_id": 7,
            "constituency_name": "BUSHENYI -ISHAKA MUNICIPALITY"
        },
        {
            "id": 963,
            "name": "NYAKABUNGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 167,
            "constituency_name": "KINKIZI COUNTY EAST"
        },
        {
            "id": 596,
            "name": "NYAKAGYEME",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 87,
            "county_name": "RUJUMBURA County",
            "constituency_id": 109,
            "constituency_name": "RUJUMBURA COUNTY"
        },
        {
            "id": 1880,
            "name": "NYAKARONGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 1798,
            "name": "NYAKASHAKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU",
            "county_id": 249,
            "county_name": "BUHWEJU WEST County",
            "constituency_id": 285,
            "constituency_name": "BUHWEJU WEST COUNTY"
        },
        {
            "id": 1149,
            "name": "NYAKASHASHARA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA",
            "county_id": 159,
            "county_name": "NYABUSHOZI County",
            "constituency_id": 193,
            "constituency_name": "NYABUSHOZI COUNTY"
        },
        {
            "id": 195,
            "name": "NYAKATONZI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 41,
            "constituency_name": "BUKONZO COUNTY EAST"
        },
        {
            "id": 1799,
            "name": "NYAKAZIBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU",
            "county_id": 249,
            "county_name": "BUHWEJU WEST County",
            "constituency_id": 285,
            "constituency_name": "BUHWEJU WEST COUNTY"
        },
        {
            "id": 1952,
            "name": "NYAKIGUMBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 262,
            "name": "NYAKINAMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 39,
            "county_name": "BUFUMBIRA County",
            "constituency_id": 51,
            "constituency_name": "BUFUMBIRA COUNTY SOUTH"
        },
        {
            "id": 978,
            "name": "NYAKINONI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 168,
            "constituency_name": "KINKIZI COUNTY WEST"
        },
        {
            "id": 1800,
            "name": "NYAKISHANA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU",
            "county_id": 249,
            "county_name": "BUHWEJU WEST County",
            "constituency_id": 285,
            "constituency_name": "BUHWEJU WEST COUNTY"
        },
        {
            "id": 590,
            "name": "NYAKISHENYI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 86,
            "county_name": "RUBABO County",
            "constituency_id": 108,
            "constituency_name": "RUBABO COUNTY"
        },
        {
            "id": 786,
            "name": "NYAKISI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 118,
            "county_name": "MWENGE CENTRAL County",
            "constituency_id": 143,
            "constituency_name": "MWENGE CENTRAL COUNTY"
        },
        {
            "id": 1101,
            "name": "NYAKITUNDA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 185,
            "constituency_name": "ISINGIRO COUNTY SOUTH"
        },
        {
            "id": 185,
            "name": "NYAKIYUMBU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 32,
            "county_name": "BUKONJO County",
            "constituency_id": 40,
            "constituency_name": "BUKONJO COUNTY WEST"
        },
        {
            "id": 1761,
            "name": "NYAKIZINGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 243,
            "county_name": "RUHINDA SOUTH County",
            "constituency_id": 279,
            "constituency_name": "RUHINDA SOUTH COUNTY"
        },
        {
            "id": 1243,
            "name": "NYAKWAE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 525,
            "name": "NYAKYERA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 78,
            "county_name": "RUHAAMA EAST County",
            "constituency_id": 99,
            "constituency_name": "RUHAAMA EAST COUNTY"
        },
        {
            "id": 526,
            "name": "NYAKYERA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 78,
            "county_name": "RUHAAMA EAST County",
            "constituency_id": 99,
            "constituency_name": "RUHAAMA EAST COUNTY"
        },
        {
            "id": 1581,
            "name": "NYAMAHASA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 92,
            "district_name": "KIRYANDONGO",
            "county_id": 214,
            "county_name": "KIBANDA NORTH County",
            "constituency_id": 250,
            "constituency_name": "KIBANDA NORTH COUNTY"
        },
        {
            "id": 1065,
            "name": "NYAMAREBE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 147,
            "county_name": "IBANDA County",
            "constituency_id": 179,
            "constituency_name": "IBANDA COUNTY NORTH"
        },
        {
            "id": 228,
            "name": "NYAMARUNDA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE",
            "county_id": 35,
            "county_name": "BUYANJA County",
            "constituency_id": 45,
            "constituency_name": "BUYANJA COUNTY"
        },
        {
            "id": 229,
            "name": "NYAMARUNDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE",
            "county_id": 35,
            "county_name": "BUYANJA County",
            "constituency_id": 45,
            "constituency_name": "BUYANJA COUNTY"
        },
        {
            "id": 235,
            "name": "NYAMARWA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 16,
            "district_name": "KIBAALE",
            "county_id": 36,
            "county_name": "BUYANJA EAST County",
            "constituency_id": 46,
            "constituency_name": "BUYANJA EAST COUNTY"
        },
        {
            "id": 979,
            "name": "NYAMIRAMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 168,
            "constituency_name": "KINKIZI COUNTY WEST"
        },
        {
            "id": 980,
            "name": "NYAMIRAMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 168,
            "constituency_name": "KINKIZI COUNTY WEST"
        },
        {
            "id": 519,
            "name": "NYAMUKANA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 77,
            "county_name": "RUHAAMA County",
            "constituency_id": 98,
            "constituency_name": "RUHAAMA COUNTY"
        },
        {
            "id": 512,
            "name": "NYAMUNUKA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 76,
            "county_name": "KAJARA County",
            "constituency_id": 97,
            "constituency_name": "KAJARA COUNTY"
        },
        {
            "id": 1094,
            "name": "NYAMUYANJA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 184,
            "constituency_name": "ISINGIRO COUNTY NORTH"
        },
        {
            "id": 221,
            "name": "NYAMWAMBA DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 34,
            "county_name": "KASESE Municipality",
            "constituency_id": 44,
            "constituency_name": "KASESE MUNICIPALITY"
        },
        {
            "id": 1929,
            "name": "NYAMWEERU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 300,
            "constituency_name": "RUBANDA COUNTY EAST"
        },
        {
            "id": 268,
            "name": "NYANAMO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 40,
            "county_name": "BUKIMBIRI County",
            "constituency_id": 52,
            "constituency_name": "BUKIMBIRI COUNTY"
        },
        {
            "id": 981,
            "name": "NYANGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 168,
            "constituency_name": "KINKIZI COUNTY WEST"
        },
        {
            "id": 393,
            "name": "NYANGAHYA DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 56,
            "county_name": "MASINDI Municipality",
            "constituency_id": 74,
            "constituency_name": "MASINDI MUNICIPALITY"
        },
        {
            "id": 646,
            "name": "NYANGOLE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 96,
            "county_name": "WEST BUDAMA County",
            "constituency_id": 119,
            "constituency_name": "WEST BUDAMA COUNTY SOUTH"
        },
        {
            "id": 798,
            "name": "NYANKWANZI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 144,
            "constituency_name": "MWENGE COUNTY NORTH"
        },
        {
            "id": 381,
            "name": "NYANTONZI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 54,
            "county_name": "BUJENJE County",
            "constituency_id": 72,
            "constituency_name": "BUJENJE COUNTY"
        },
        {
            "id": 811,
            "name": "NYANTUNGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 119,
            "county_name": "MWENGE County",
            "constituency_id": 145,
            "constituency_name": "MWENGE COUNTY SOUTH"
        },
        {
            "id": 1503,
            "name": "NYAPEA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 204,
            "county_name": "OKORO County",
            "constituency_id": 240,
            "constituency_name": "OKORO COUNTY"
        },
        {
            "id": 499,
            "name": "NYARAVUR - ANGAL TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 74,
            "county_name": "PADYERE County",
            "constituency_id": 95,
            "constituency_name": "PADYERE COUNTY"
        },
        {
            "id": 256,
            "name": "NYARUBUYE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 39,
            "county_name": "BUFUMBIRA County",
            "constituency_id": 50,
            "constituency_name": "BUFUMBIRA COUNTY NORTH"
        },
        {
            "id": 591,
            "name": "NYARUSHANJE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 86,
            "county_name": "RUBABO County",
            "constituency_id": 108,
            "constituency_name": "RUBABO COUNTY"
        },
        {
            "id": 263,
            "name": "NYARUSIZA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 39,
            "county_name": "BUFUMBIRA County",
            "constituency_id": 51,
            "constituency_name": "BUFUMBIRA COUNTY SOUTH"
        },
        {
            "id": 520,
            "name": "NYARUTUNTU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 77,
            "county_name": "RUHAAMA County",
            "constituency_id": 98,
            "constituency_name": "RUHAAMA COUNTY"
        },
        {
            "id": 1908,
            "name": "NYARWEYO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 114,
            "district_name": "KAKUMIRO",
            "county_id": 261,
            "county_name": "BUGANGAIZI WEST County",
            "constituency_id": 297,
            "constituency_name": "BUGANGAIZI WEST COUNTY"
        },
        {
            "id": 1432,
            "name": "NYENGA DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE",
            "county_id": 194,
            "county_name": "NJERU Municipality",
            "constituency_id": 230,
            "constituency_name": "NJERU MUNICIPALITY"
        },
        {
            "id": 325,
            "name": "NYERO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 48,
            "county_name": "KUMI County",
            "constituency_id": 60,
            "constituency_name": "KUMI COUNTY"
        },
        {
            "id": 326,
            "name": "NYERO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 48,
            "county_name": "KUMI County",
            "constituency_id": 60,
            "constituency_name": "KUMI COUNTY"
        },
        {
            "id": 360,
            "name": "NYIMBWA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 52,
            "county_name": "KATIKAMU County",
            "constituency_id": 66,
            "constituency_name": "KATIKAMU COUNTY SOUTH"
        },
        {
            "id": 399,
            "name": "NYONDO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 57,
            "county_name": "BUNGOKHO CENTRAL County",
            "constituency_id": 75,
            "constituency_name": "BUNGOKHO CENTRAL COUNTY"
        },
        {
            "id": 269,
            "name": "NYUNDO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 40,
            "county_name": "BUKIMBIRI County",
            "constituency_id": 52,
            "constituency_name": "BUKIMBIRI COUNTY"
        },
        {
            "id": 2048,
            "name": "OBALANGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 124,
            "district_name": "KAPELEBYONG",
            "county_id": 274,
            "county_name": "KAPELEBYONG County",
            "constituency_id": 311,
            "constituency_name": "KAPELEBYONG COUNTY"
        },
        {
            "id": 2049,
            "name": "OBALANGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 124,
            "district_name": "KAPELEBYONG",
            "county_id": 274,
            "county_name": "KAPELEBYONG County",
            "constituency_id": 311,
            "constituency_name": "KAPELEBYONG COUNTY"
        },
        {
            "id": 1342,
            "name": "OBIBA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 183,
            "county_name": "MARACHA County",
            "constituency_id": 219,
            "constituency_name": "MARACHA COUNTY"
        },
        {
            "id": 542,
            "name": "OBOLISO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 80,
            "county_name": "AGULE County",
            "constituency_id": 101,
            "constituency_name": "AGULE COUNTY"
        },
        {
            "id": 2149,
            "name": "OBONGI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 134,
            "district_name": "OBONGI",
            "county_id": 289,
            "county_name": "OBONGI County",
            "constituency_id": 327,
            "constituency_name": "OBONGI COUNTY"
        },
        {
            "id": 546,
            "name": "OBUTET",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 81,
            "county_name": "GOGONYO County",
            "constituency_id": 102,
            "constituency_name": "GOGONYO COUNTY"
        },
        {
            "id": 1652,
            "name": "OCAAPA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 226,
            "county_name": "SERERE County",
            "constituency_id": 262,
            "constituency_name": "SERERE COUNTY"
        },
        {
            "id": 2096,
            "name": "OCELAKUR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 129,
            "district_name": "KALAKI",
            "county_id": 282,
            "county_name": "KALAKI County",
            "constituency_id": 320,
            "constituency_name": "KALAKI COUNTY"
        },
        {
            "id": 952,
            "name": "OCHERO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 54,
            "district_name": "KABERAMAIDO",
            "county_id": 135,
            "county_name": "OCHERO County",
            "constituency_id": 166,
            "constituency_name": "OCHERO COUNTY"
        },
        {
            "id": 953,
            "name": "OCHERO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 54,
            "district_name": "KABERAMAIDO",
            "county_id": 135,
            "county_name": "OCHERO County",
            "constituency_id": 166,
            "constituency_name": "OCHERO COUNTY"
        },
        {
            "id": 613,
            "name": "OCOKICAN",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI",
            "county_id": 91,
            "county_name": "SOROTI County",
            "constituency_id": 113,
            "constituency_name": "SOROTI COUNTY"
        },
        {
            "id": 604,
            "name": "OCULOI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI",
            "county_id": 89,
            "county_name": "DAKABELA County",
            "constituency_id": 111,
            "constituency_name": "DAKABELA COUNTY"
        },
        {
            "id": 1914,
            "name": "ODEK",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 262,
            "county_name": "OMORO County",
            "constituency_id": 298,
            "constituency_name": "OMORO COUNTY"
        },
        {
            "id": 944,
            "name": "ODRAVU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 133,
            "county_name": "ARINGA SOUTH County",
            "constituency_id": 164,
            "constituency_name": "ARINGA SOUTH COUNTY"
        },
        {
            "id": 945,
            "name": "ODRAVU WEST",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 133,
            "county_name": "ARINGA SOUTH County",
            "constituency_id": 164,
            "constituency_name": "ARINGA SOUTH COUNTY"
        },
        {
            "id": 2159,
            "name": "ODUPI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 143,
            "district_name": "TEREGO",
            "county_id": 306,
            "county_name": "TEREGO EAST County",
            "constituency_id": 346,
            "constituency_name": "TEREGO EAST COUNTY"
        },
        {
            "id": 1786,
            "name": "ODWARAT",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 108,
            "district_name": "NGORA",
            "county_id": 247,
            "county_name": "NGORA County",
            "constituency_id": 283,
            "constituency_name": "NGORA COUNTY"
        },
        {
            "id": 2141,
            "name": "OFFAKA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 133,
            "district_name": "MADI-OKOLLO",
            "county_id": 288,
            "county_name": "UPPER MADI County",
            "constituency_id": 326,
            "constituency_name": "UPPER MADI COUNTY"
        },
        {
            "id": 659,
            "name": "OFUA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 40,
            "district_name": "ADJUMANI",
            "county_id": 98,
            "county_name": "ADJUMANI EAST County",
            "constituency_id": 121,
            "constituency_name": "ADJUMANI EAST COUNTY"
        },
        {
            "id": 1480,
            "name": "OGILI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 201,
            "county_name": "PALABEK County",
            "constituency_id": 237,
            "constituency_name": "PALABEK COUNTY"
        },
        {
            "id": 2135,
            "name": "OGOKO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 133,
            "district_name": "MADI-OKOLLO",
            "county_id": 287,
            "county_name": "LOWER MADI County",
            "constituency_id": 325,
            "constituency_name": "LOWER MADI COUNTY"
        },
        {
            "id": 1020,
            "name": "OGOLAI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 142,
            "county_name": "ORUNGO County",
            "constituency_id": 174,
            "constituency_name": "ORUNGO COUNTY"
        },
        {
            "id": 830,
            "name": "OGOM",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 121,
            "county_name": "ARUU County",
            "constituency_id": 149,
            "constituency_name": "ARUU COUNTY"
        },
        {
            "id": 1021,
            "name": "OGONGORA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 142,
            "county_name": "ORUNGO County",
            "constituency_id": 174,
            "constituency_name": "ORUNGO COUNTY"
        },
        {
            "id": 327,
            "name": "OGOOMA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 48,
            "county_name": "KUMI County",
            "constituency_id": 60,
            "constituency_name": "KUMI COUNTY"
        },
        {
            "id": 1494,
            "name": "OGOR",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE",
            "county_id": 203,
            "county_name": "OTUKE EAST County",
            "constituency_id": 239,
            "constituency_name": "OTUKE EAST COUNTY"
        },
        {
            "id": 337,
            "name": "OGUR",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 22,
            "district_name": "LIRA",
            "county_id": 50,
            "county_name": "ERUTE County",
            "constituency_id": 62,
            "constituency_name": "ERUTE COUNTY NORTH"
        },
        {
            "id": 1495,
            "name": "OGWETTE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE",
            "county_id": 203,
            "county_name": "OTUKE EAST County",
            "constituency_id": 239,
            "constituency_name": "OTUKE EAST COUNTY"
        },
        {
            "id": 2097,
            "name": "OGWOLO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 129,
            "district_name": "KALAKI",
            "county_id": 282,
            "county_name": "KALAKI County",
            "constituency_id": 320,
            "constituency_name": "KALAKI COUNTY"
        },
        {
            "id": 647,
            "name": "OJILAI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 96,
            "county_name": "WEST BUDAMA County",
            "constituency_id": 119,
            "constituency_name": "WEST BUDAMA COUNTY SOUTH"
        },
        {
            "id": 954,
            "name": "OKILE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 54,
            "district_name": "KABERAMAIDO",
            "county_id": 135,
            "county_name": "OCHERO County",
            "constituency_id": 166,
            "constituency_name": "OCHERO COUNTY"
        },
        {
            "id": 1343,
            "name": "OKOKORO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 183,
            "county_name": "MARACHA County",
            "constituency_id": 219,
            "constituency_name": "MARACHA COUNTY"
        },
        {
            "id": 2142,
            "name": "OKOLLO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 133,
            "district_name": "MADI-OKOLLO",
            "county_id": 288,
            "county_name": "UPPER MADI County",
            "constituency_id": 326,
            "constituency_name": "UPPER MADI COUNTY"
        },
        {
            "id": 2143,
            "name": "OKOLLO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 133,
            "district_name": "MADI-OKOLLO",
            "county_id": 288,
            "county_name": "UPPER MADI County",
            "constituency_id": 326,
            "constituency_name": "UPPER MADI COUNTY"
        },
        {
            "id": 712,
            "name": "OKORE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 105,
            "county_name": "NGARIAM County",
            "constituency_id": 130,
            "constituency_name": "NGARIAM COUNTY"
        },
        {
            "id": 723,
            "name": "OKULONYO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 107,
            "county_name": "USUK County",
            "constituency_id": 132,
            "constituency_name": "USUK COUNTY"
        },
        {
            "id": 2050,
            "name": "OKUNGUR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 124,
            "district_name": "KAPELEBYONG",
            "county_id": 274,
            "county_name": "KAPELEBYONG County",
            "constituency_id": 311,
            "constituency_name": "KAPELEBYONG COUNTY"
        },
        {
            "id": 1296,
            "name": "OKWALONGWEN",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO",
            "county_id": 177,
            "county_name": "DOKOLO NORTH County",
            "constituency_id": 212,
            "constituency_name": "DOKOLO NORTH COUNTY"
        },
        {
            "id": 1491,
            "name": "OKWANG",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE",
            "county_id": 202,
            "county_name": "OTUKE County",
            "constituency_id": 238,
            "constituency_name": "OTUKE COUNTY"
        },
        {
            "id": 1492,
            "name": "OKWANG TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE",
            "county_id": 202,
            "county_name": "OTUKE County",
            "constituency_id": 238,
            "constituency_name": "OTUKE COUNTY"
        },
        {
            "id": 1719,
            "name": "OKWERODOT",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 103,
            "district_name": "KOLE",
            "county_id": 236,
            "county_name": "KOLE NORTH County",
            "constituency_id": 273,
            "constituency_name": "KOLE NORTH COUNTY"
        },
        {
            "id": 1493,
            "name": "OKWONGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE",
            "county_id": 202,
            "county_name": "OTUKE County",
            "constituency_id": 238,
            "constituency_name": "OTUKE COUNTY"
        },
        {
            "id": 1302,
            "name": "OKWONGODUL",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 74,
            "district_name": "DOKOLO",
            "county_id": 178,
            "county_name": "DOKOLO SOUTH County",
            "constituency_id": 213,
            "constituency_name": "DOKOLO SOUTH COUNTY"
        },
        {
            "id": 1353,
            "name": "OLEBA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 184,
            "county_name": "MARACHA EAST County",
            "constituency_id": 220,
            "constituency_name": "MARACHA EAST COUNTY"
        },
        {
            "id": 1354,
            "name": "OLEBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 184,
            "county_name": "MARACHA EAST County",
            "constituency_id": 220,
            "constituency_name": "MARACHA EAST COUNTY"
        },
        {
            "id": 1496,
            "name": "OLILIM",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE",
            "county_id": 203,
            "county_name": "OTUKE EAST County",
            "constituency_id": 239,
            "constituency_name": "OTUKE EAST COUNTY"
        },
        {
            "id": 1497,
            "name": "OLILIM TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE",
            "county_id": 203,
            "county_name": "OTUKE EAST County",
            "constituency_id": 239,
            "constituency_name": "OTUKE EAST COUNTY"
        },
        {
            "id": 563,
            "name": "OLOK",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 83,
            "county_name": "PALLISA County",
            "constituency_id": 105,
            "constituency_name": "PALLISA COUNTY"
        },
        {
            "id": 1344,
            "name": "OLUFE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 183,
            "county_name": "MARACHA County",
            "constituency_id": 219,
            "constituency_name": "MARACHA COUNTY"
        },
        {
            "id": 1345,
            "name": "OLUVU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 183,
            "county_name": "MARACHA County",
            "constituency_id": 219,
            "constituency_name": "MARACHA COUNTY"
        },
        {
            "id": 1022,
            "name": "OLWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 142,
            "county_name": "ORUNGO County",
            "constituency_id": 174,
            "constituency_name": "ORUNGO COUNTY"
        },
        {
            "id": 63,
            "name": "OMEL",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 5,
            "district_name": "GULU",
            "county_id": 9,
            "county_name": "ASWA County",
            "constituency_id": 10,
            "constituency_name": "ASWA COUNTY"
        },
        {
            "id": 1834,
            "name": "OMIYA PACWA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 254,
            "county_name": "AGAGO NORTH County",
            "constituency_id": 290,
            "constituency_name": "AGAGO NORTH COUNTY"
        },
        {
            "id": 281,
            "name": "OMIYA-ANYIMA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 42,
            "county_name": "CHUA EAST County",
            "constituency_id": 54,
            "constituency_name": "CHUA EAST COUNTY"
        },
        {
            "id": 282,
            "name": "OMIYA-ANYIMA WEST",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 42,
            "county_name": "CHUA EAST County",
            "constituency_id": 54,
            "constituency_name": "CHUA EAST COUNTY"
        },
        {
            "id": 719,
            "name": "OMODOI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 106,
            "county_name": "TOROMA County",
            "constituency_id": 131,
            "constituency_name": "TOROMA COUNTY"
        },
        {
            "id": 1521,
            "name": "OMORO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 88,
            "district_name": "ALEBTONG",
            "county_id": 206,
            "county_name": "AJURI County",
            "constituency_id": 242,
            "constituency_name": "AJURI COUNTY"
        },
        {
            "id": 1915,
            "name": "OMORO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 262,
            "county_name": "OMORO County",
            "constituency_id": 298,
            "constituency_name": "OMORO COUNTY"
        },
        {
            "id": 1819,
            "name": "OMOT",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 252,
            "county_name": "AGAGO WEST County",
            "constituency_id": 288,
            "constituency_name": "AGAGO WEST COUNTY"
        },
        {
            "id": 2160,
            "name": "OMUGO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 143,
            "district_name": "TEREGO",
            "county_id": 306,
            "county_name": "TEREGO EAST County",
            "constituency_id": 346,
            "constituency_name": "TEREGO EAST COUNTY"
        },
        {
            "id": 1922,
            "name": "ONGAKO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 263,
            "county_name": "TOCHI County",
            "constituency_id": 299,
            "constituency_name": "TOCHI COUNTY"
        },
        {
            "id": 328,
            "name": "ONGINO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 48,
            "county_name": "KUMI County",
            "constituency_id": 60,
            "constituency_name": "KUMI COUNTY"
        },
        {
            "id": 329,
            "name": "ONGINO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 48,
            "county_name": "KUMI County",
            "constituency_id": 60,
            "constituency_name": "KUMI COUNTY"
        },
        {
            "id": 724,
            "name": "ONGONGOJA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 107,
            "county_name": "USUK County",
            "constituency_id": 132,
            "constituency_name": "USUK COUNTY"
        },
        {
            "id": 1006,
            "name": "OPALI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 57,
            "district_name": "AMOLATAR",
            "county_id": 140,
            "county_name": "KIOGA NORTH County",
            "constituency_id": 172,
            "constituency_name": "KIOGA NORTH COUNTY"
        },
        {
            "id": 1249,
            "name": "OPARA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU",
            "county_id": 172,
            "county_name": "KILAK NORTH County",
            "constituency_id": 207,
            "constituency_name": "KILAK NORTH COUNTY"
        },
        {
            "id": 1244,
            "name": "OPOPONGO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 1787,
            "name": "OPOT TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 108,
            "district_name": "NGORA",
            "county_id": 247,
            "county_name": "NGORA County",
            "constituency_id": 283,
            "constituency_name": "NGORA COUNTY"
        },
        {
            "id": 558,
            "name": "OPWATETA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 82,
            "county_name": "KIBALE County",
            "constituency_id": 103,
            "constituency_name": "KIBALE COUNTY"
        },
        {
            "id": 1160,
            "name": "ORABA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 66,
            "district_name": "KOBOKO",
            "county_id": 160,
            "county_name": "KOBOKO County",
            "constituency_id": 194,
            "constituency_name": "KOBOKO COUNTY"
        },
        {
            "id": 1916,
            "name": "ORAPWOYO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 262,
            "county_name": "OMORO County",
            "constituency_id": 298,
            "constituency_name": "OMORO COUNTY"
        },
        {
            "id": 950,
            "name": "ORIAMO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 54,
            "district_name": "KABERAMAIDO",
            "county_id": 134,
            "county_name": "KABERAMAIDO County",
            "constituency_id": 165,
            "constituency_name": "KABERAMAIDO COUNTY"
        },
        {
            "id": 283,
            "name": "OROM",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 42,
            "county_name": "CHUA EAST County",
            "constituency_id": 54,
            "constituency_name": "CHUA EAST COUNTY"
        },
        {
            "id": 284,
            "name": "OROM EAST",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 42,
            "county_name": "CHUA EAST County",
            "constituency_id": 54,
            "constituency_name": "CHUA EAST COUNTY"
        },
        {
            "id": 1498,
            "name": "ORUM",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE",
            "county_id": 203,
            "county_name": "OTUKE EAST County",
            "constituency_id": 239,
            "constituency_name": "OTUKE EAST COUNTY"
        },
        {
            "id": 1023,
            "name": "ORUNGO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 142,
            "county_name": "ORUNGO County",
            "constituency_id": 174,
            "constituency_name": "ORUNGO COUNTY"
        },
        {
            "id": 1024,
            "name": "ORUNGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 142,
            "county_name": "ORUNGO County",
            "constituency_id": 174,
            "constituency_name": "ORUNGO COUNTY"
        },
        {
            "id": 1245,
            "name": "ORWAMUGE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 70,
            "district_name": "ABIM",
            "county_id": 171,
            "county_name": "LABWOR County",
            "constituency_id": 206,
            "constituency_name": "LABWOR COUNTY"
        },
        {
            "id": 648,
            "name": "OSIA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 96,
            "county_name": "WEST BUDAMA County",
            "constituency_id": 119,
            "constituency_name": "WEST BUDAMA COUNTY SOUTH"
        },
        {
            "id": 631,
            "name": "OSUKURU TOWN COUCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 94,
            "county_name": "TORORO SOUTH County",
            "constituency_id": 116,
            "constituency_name": "TORORO SOUTH COUNTY"
        },
        {
            "id": 440,
            "name": "OTCE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 29,
            "district_name": "MOYO",
            "county_id": 64,
            "county_name": "WEST MOYO County",
            "constituency_id": 83,
            "constituency_name": "WEST MOYO COUNTY"
        },
        {
            "id": 2098,
            "name": "OTUBOI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 129,
            "district_name": "KALAKI",
            "county_id": 282,
            "county_name": "KALAKI County",
            "constituency_id": 320,
            "constituency_name": "KALAKI COUNTY"
        },
        {
            "id": 2099,
            "name": "OTUBOI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 129,
            "district_name": "KALAKI",
            "county_id": 282,
            "county_name": "KALAKI County",
            "constituency_id": 320,
            "constituency_name": "KALAKI COUNTY"
        },
        {
            "id": 1499,
            "name": "OTUKE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 86,
            "district_name": "OTUKE",
            "county_id": 203,
            "county_name": "OTUKE EAST County",
            "constituency_id": 239,
            "constituency_name": "OTUKE EAST COUNTY"
        },
        {
            "id": 1329,
            "name": "OTWAL",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 217,
            "constituency_name": "OYAM COUNTY NORTH"
        },
        {
            "id": 1346,
            "name": "OVUJO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 183,
            "county_name": "MARACHA County",
            "constituency_id": 219,
            "constituency_name": "MARACHA COUNTY"
        },
        {
            "id": 64,
            "name": "OWALO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 5,
            "district_name": "GULU",
            "county_id": 9,
            "county_name": "ASWA County",
            "constituency_id": 10,
            "constituency_name": "ASWA COUNTY"
        },
        {
            "id": 65,
            "name": "OWOO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 5,
            "district_name": "GULU",
            "county_id": 9,
            "county_name": "ASWA County",
            "constituency_id": 10,
            "constituency_name": "ASWA COUNTY"
        },
        {
            "id": 1330,
            "name": "OYAM TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 76,
            "district_name": "OYAM",
            "county_id": 182,
            "county_name": "OYAM County",
            "constituency_id": 217,
            "constituency_name": "OYAM COUNTY NORTH"
        },
        {
            "id": 1250,
            "name": "PABBO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU",
            "county_id": 172,
            "county_name": "KILAK NORTH County",
            "constituency_id": 207,
            "constituency_name": "KILAK NORTH COUNTY"
        },
        {
            "id": 1251,
            "name": "PABBO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU",
            "county_id": 172,
            "county_name": "KILAK NORTH County",
            "constituency_id": 207,
            "constituency_name": "KILAK NORTH COUNTY"
        },
        {
            "id": 665,
            "name": "PACHARA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 40,
            "district_name": "ADJUMANI",
            "county_id": 99,
            "county_name": "ADJUMANI WEST County",
            "constituency_id": 122,
            "constituency_name": "ADJUMANI WEST COUNTY"
        },
        {
            "id": 1864,
            "name": "PACHWA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 1865,
            "name": "PACHWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 257,
            "county_name": "BUYAGA EAST County",
            "constituency_id": 293,
            "constituency_name": "BUYAGA EAST COUNTY"
        },
        {
            "id": 1504,
            "name": "PADEA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 204,
            "county_name": "OKORO County",
            "constituency_id": 240,
            "constituency_name": "OKORO COUNTY"
        },
        {
            "id": 831,
            "name": "PADER",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 121,
            "county_name": "ARUU County",
            "constituency_id": 149,
            "constituency_name": "ARUU COUNTY"
        },
        {
            "id": 832,
            "name": "PADER TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 121,
            "county_name": "ARUU County",
            "constituency_id": 149,
            "constituency_name": "ARUU COUNTY"
        },
        {
            "id": 1475,
            "name": "PADIBE EAST",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 200,
            "county_name": "LAMWO County",
            "constituency_id": 236,
            "constituency_name": "LAMWO COUNTY"
        },
        {
            "id": 1476,
            "name": "PADIBE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 200,
            "county_name": "LAMWO County",
            "constituency_id": 236,
            "constituency_name": "LAMWO COUNTY"
        },
        {
            "id": 1477,
            "name": "PADIBE WEST",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 200,
            "county_name": "LAMWO County",
            "constituency_id": 236,
            "constituency_name": "LAMWO COUNTY"
        },
        {
            "id": 500,
            "name": "PADWOT",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 74,
            "county_name": "PADYERE County",
            "constituency_id": 95,
            "constituency_name": "PADYERE COUNTY"
        },
        {
            "id": 294,
            "name": "PAGER DIVISION",
            "type": "Municipal-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 44,
            "county_name": "KITGUM Municipality",
            "constituency_id": 56,
            "constituency_name": "KITGUM MUNICIPALITY"
        },
        {
            "id": 66,
            "name": "PAIBONA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 5,
            "district_name": "GULU",
            "county_id": 9,
            "county_name": "ASWA County",
            "constituency_id": 10,
            "constituency_name": "ASWA COUNTY"
        },
        {
            "id": 67,
            "name": "PAICHO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 5,
            "district_name": "GULU",
            "county_id": 9,
            "county_name": "ASWA County",
            "constituency_id": 10,
            "constituency_name": "ASWA COUNTY"
        },
        {
            "id": 1505,
            "name": "PAIDHA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 204,
            "county_name": "OKORO County",
            "constituency_id": 240,
            "constituency_name": "OKORO COUNTY"
        },
        {
            "id": 1506,
            "name": "PAIDHA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 204,
            "county_name": "OKORO County",
            "constituency_id": 240,
            "constituency_name": "OKORO COUNTY"
        },
        {
            "id": 1835,
            "name": "PAIMOL",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 254,
            "county_name": "AGAGO NORTH County",
            "constituency_id": 290,
            "constituency_name": "AGAGO NORTH COUNTY"
        },
        {
            "id": 847,
            "name": "PAIULA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 122,
            "county_name": "ARUU NORTH County",
            "constituency_id": 150,
            "constituency_name": "ARUU NORTH COUNTY"
        },
        {
            "id": 848,
            "name": "PAJULE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 122,
            "county_name": "ARUU NORTH County",
            "constituency_id": 150,
            "constituency_name": "ARUU NORTH COUNTY"
        },
        {
            "id": 849,
            "name": "PAJULE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 122,
            "county_name": "ARUU NORTH County",
            "constituency_id": 150,
            "constituency_name": "ARUU NORTH COUNTY"
        },
        {
            "id": 636,
            "name": "PAJWENDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 95,
            "county_name": "WEST BUDAMA CENTRAL County",
            "constituency_id": 117,
            "constituency_name": "WEST BUDAMA CENTRAL COUNTY"
        },
        {
            "id": 389,
            "name": "PAKANYI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 25,
            "district_name": "MASINDI",
            "county_id": 55,
            "county_name": "BURULI County",
            "constituency_id": 73,
            "constituency_name": "BURULI COUNTY"
        },
        {
            "id": 660,
            "name": "PAKELE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 40,
            "district_name": "ADJUMANI",
            "county_id": 98,
            "county_name": "ADJUMANI EAST County",
            "constituency_id": 121,
            "constituency_name": "ADJUMANI EAST COUNTY"
        },
        {
            "id": 661,
            "name": "PAKELE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 40,
            "district_name": "ADJUMANI",
            "county_id": 98,
            "county_name": "ADJUMANI EAST County",
            "constituency_id": 121,
            "constituency_name": "ADJUMANI EAST COUNTY"
        },
        {
            "id": 2018,
            "name": "PAKWACH",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 121,
            "district_name": "PAKWACH",
            "county_id": 271,
            "county_name": "JONAM County",
            "constituency_id": 308,
            "constituency_name": "JONAM COUNTY"
        },
        {
            "id": 2019,
            "name": "PAKWACH TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 121,
            "district_name": "PAKWACH",
            "county_id": 271,
            "county_name": "JONAM County",
            "constituency_id": 308,
            "constituency_name": "JONAM COUNTY"
        },
        {
            "id": 1481,
            "name": "PALABEK ABERA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 201,
            "county_name": "PALABEK County",
            "constituency_id": 237,
            "constituency_name": "PALABEK COUNTY"
        },
        {
            "id": 1482,
            "name": "PALABEK KAL TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 201,
            "county_name": "PALABEK County",
            "constituency_id": 237,
            "constituency_name": "PALABEK COUNTY"
        },
        {
            "id": 1483,
            "name": "PALABEK NYIMUR",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 201,
            "county_name": "PALABEK County",
            "constituency_id": 237,
            "constituency_name": "PALABEK COUNTY"
        },
        {
            "id": 1484,
            "name": "PALABEK-GEM",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 201,
            "county_name": "PALABEK County",
            "constituency_id": 237,
            "constituency_name": "PALABEK COUNTY"
        },
        {
            "id": 1485,
            "name": "PALABEK-KAL",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 201,
            "county_name": "PALABEK County",
            "constituency_id": 237,
            "constituency_name": "PALABEK COUNTY"
        },
        {
            "id": 713,
            "name": "PALAM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 105,
            "county_name": "NGARIAM County",
            "constituency_id": 130,
            "constituency_name": "NGARIAM COUNTY"
        },
        {
            "id": 68,
            "name": "PALARO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 5,
            "district_name": "GULU",
            "county_id": 9,
            "county_name": "ASWA County",
            "constituency_id": 10,
            "constituency_name": "ASWA COUNTY"
        },
        {
            "id": 1923,
            "name": "PALENGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 115,
            "district_name": "OMORO",
            "county_id": 263,
            "county_name": "TOCHI County",
            "constituency_id": 299,
            "constituency_name": "TOCHI COUNTY"
        },
        {
            "id": 564,
            "name": "PALLISA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 83,
            "county_name": "PALLISA County",
            "constituency_id": 105,
            "constituency_name": "PALLISA COUNTY"
        },
        {
            "id": 565,
            "name": "PALLISA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 83,
            "county_name": "PALLISA County",
            "constituency_id": 105,
            "constituency_name": "PALLISA COUNTY"
        },
        {
            "id": 1478,
            "name": "PALOGA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 200,
            "county_name": "LAMWO County",
            "constituency_id": 236,
            "constituency_name": "LAMWO COUNTY"
        },
        {
            "id": 2150,
            "name": "PALORINYA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 134,
            "district_name": "OBONGI",
            "county_id": 289,
            "county_name": "OBONGI County",
            "constituency_id": 327,
            "constituency_name": "OBONGI COUNTY"
        },
        {
            "id": 1812,
            "name": "PAMINYAI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 110,
            "district_name": "NWOYA",
            "county_id": 251,
            "county_name": "NWOYA EAST County",
            "constituency_id": 287,
            "constituency_name": "NWOYA EAST COUNTY"
        },
        {
            "id": 295,
            "name": "PANDWONG DIVISION",
            "type": "Municipal-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 19,
            "district_name": "KITGUM",
            "county_id": 44,
            "county_name": "KITGUM Municipality",
            "constituency_id": 56,
            "constituency_name": "KITGUM MUNICIPALITY"
        },
        {
            "id": 309,
            "name": "PANYANGARA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 2020,
            "name": "PANYANGO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 121,
            "district_name": "PAKWACH",
            "county_id": 271,
            "county_name": "JONAM County",
            "constituency_id": 308,
            "constituency_name": "JONAM COUNTY"
        },
        {
            "id": 2021,
            "name": "PANYIMUR",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 121,
            "district_name": "PAKWACH",
            "county_id": 271,
            "county_name": "JONAM County",
            "constituency_id": 308,
            "constituency_name": "JONAM COUNTY"
        },
        {
            "id": 2022,
            "name": "PANYIMUR TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 121,
            "district_name": "PAKWACH",
            "county_id": 271,
            "county_name": "JONAM County",
            "constituency_id": 308,
            "constituency_name": "JONAM COUNTY"
        },
        {
            "id": 1836,
            "name": "PARABONGO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 254,
            "county_name": "AGAGO NORTH County",
            "constituency_id": 290,
            "constituency_name": "AGAGO NORTH COUNTY"
        },
        {
            "id": 1355,
            "name": "PARANGA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 184,
            "county_name": "MARACHA EAST County",
            "constituency_id": 220,
            "constituency_name": "MARACHA EAST COUNTY"
        },
        {
            "id": 501,
            "name": "PAROMBO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 74,
            "county_name": "PADYERE County",
            "constituency_id": 95,
            "constituency_name": "PADYERE COUNTY"
        },
        {
            "id": 502,
            "name": "PAROMBO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 74,
            "county_name": "PADYERE County",
            "constituency_id": 95,
            "constituency_name": "PADYERE COUNTY"
        },
        {
            "id": 69,
            "name": "PATIKO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 5,
            "district_name": "GULU",
            "county_id": 9,
            "county_name": "ASWA County",
            "constituency_id": 10,
            "constituency_name": "ASWA COUNTY"
        },
        {
            "id": 1827,
            "name": "PATONGO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 253,
            "county_name": "AGAGO County",
            "constituency_id": 289,
            "constituency_name": "AGAGO COUNTY"
        },
        {
            "id": 1828,
            "name": "PATONGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 253,
            "county_name": "AGAGO County",
            "constituency_id": 289,
            "constituency_name": "AGAGO COUNTY"
        },
        {
            "id": 2136,
            "name": "PAWOR",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 133,
            "district_name": "MADI-OKOLLO",
            "county_id": 287,
            "county_name": "LOWER MADI County",
            "constituency_id": 325,
            "constituency_name": "LOWER MADI COUNTY"
        },
        {
            "id": 652,
            "name": "PAYA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 97,
            "county_name": "WEST BUDAMA NORTH EAST County",
            "constituency_id": 120,
            "constituency_name": "WEST BUDAMA NORTH EAST COUNTY"
        },
        {
            "id": 1970,
            "name": "PETETE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 1971,
            "name": "PETETE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 642,
            "name": "PETTA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 96,
            "county_name": "WEST BUDAMA County",
            "constituency_id": 118,
            "constituency_name": "WEST BUDAMA COUNTY NORTH"
        },
        {
            "id": 1647,
            "name": "PINGIRE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 225,
            "county_name": "PINGIRE County",
            "constituency_id": 261,
            "constituency_name": "PINGIRE COUNTY"
        },
        {
            "id": 1252,
            "name": "POGO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 71,
            "district_name": "AMURU",
            "county_id": 172,
            "county_name": "KILAK NORTH County",
            "constituency_id": 207,
            "constituency_name": "KILAK NORTH COUNTY"
        },
        {
            "id": 2023,
            "name": "POKWERO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 121,
            "district_name": "PAKWACH",
            "county_id": 271,
            "county_name": "JONAM County",
            "constituency_id": 308,
            "constituency_name": "JONAM COUNTY"
        },
        {
            "id": 850,
            "name": "POROGALI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 122,
            "county_name": "ARUU NORTH County",
            "constituency_id": 150,
            "constituency_name": "ARUU NORTH COUNTY"
        },
        {
            "id": 1769,
            "name": "PORON",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 107,
            "district_name": "NAPAK",
            "county_id": 244,
            "county_name": "BOKORA County",
            "constituency_id": 280,
            "constituency_name": "BOKORA COUNTY"
        },
        {
            "id": 1479,
            "name": "POTIKA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 85,
            "district_name": "LAMWO",
            "county_id": 200,
            "county_name": "LAMWO County",
            "constituency_id": 236,
            "constituency_name": "LAMWO COUNTY"
        },
        {
            "id": 70,
            "name": "PUKONY",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 5,
            "district_name": "GULU",
            "county_id": 9,
            "county_name": "ASWA County",
            "constituency_id": 10,
            "constituency_name": "ASWA COUNTY"
        },
        {
            "id": 833,
            "name": "PUKOR",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 121,
            "county_name": "ARUU County",
            "constituency_id": 149,
            "constituency_name": "ARUU COUNTY"
        },
        {
            "id": 834,
            "name": "PURANGA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 121,
            "county_name": "ARUU County",
            "constituency_id": 149,
            "constituency_name": "ARUU COUNTY"
        },
        {
            "id": 835,
            "name": "PURANGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 121,
            "county_name": "ARUU County",
            "constituency_id": 149,
            "constituency_name": "ARUU COUNTY"
        },
        {
            "id": 1806,
            "name": "PURONGO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 110,
            "district_name": "NWOYA",
            "county_id": 250,
            "county_name": "NWOYA County",
            "constituency_id": 286,
            "constituency_name": "NWOYA COUNTY"
        },
        {
            "id": 1807,
            "name": "PURONGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 110,
            "district_name": "NWOYA",
            "county_id": 250,
            "county_name": "NWOYA County",
            "constituency_id": 286,
            "constituency_name": "NWOYA COUNTY"
        },
        {
            "id": 566,
            "name": "PUTI-PUTI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 35,
            "district_name": "PALLISA",
            "county_id": 83,
            "county_name": "PALLISA County",
            "constituency_id": 105,
            "constituency_name": "PALLISA COUNTY"
        },
        {
            "id": 1972,
            "name": "PUTTI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 118,
            "district_name": "BUTEBO",
            "county_id": 266,
            "county_name": "BUTEBO County",
            "constituency_id": 303,
            "constituency_name": "BUTEBO COUNTY"
        },
        {
            "id": 2024,
            "name": "RAGEM",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 121,
            "district_name": "PAKWACH",
            "county_id": 271,
            "county_name": "JONAM County",
            "constituency_id": 308,
            "constituency_name": "JONAM COUNTY"
        },
        {
            "id": 585,
            "name": "RAKAI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 36,
            "district_name": "RAKAI",
            "county_id": 85,
            "county_name": "KOOKI County",
            "constituency_id": 107,
            "constituency_name": "KOOKI COUNTY"
        },
        {
            "id": 310,
            "name": "RENGEN",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 45,
            "county_name": "JIE County",
            "constituency_id": 57,
            "constituency_name": "JIE COUNTY"
        },
        {
            "id": 2137,
            "name": "RHINO CAMP",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 133,
            "district_name": "MADI-OKOLLO",
            "county_id": 287,
            "county_name": "LOWER MADI County",
            "constituency_id": 325,
            "constituency_name": "LOWER MADI COUNTY"
        },
        {
            "id": 2138,
            "name": "RHINO CAMP TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 133,
            "district_name": "MADI-OKOLLO",
            "county_id": 287,
            "county_name": "LOWER MADI County",
            "constituency_id": 325,
            "constituency_name": "LOWER MADI COUNTY"
        },
        {
            "id": 2139,
            "name": "RIGBO",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 133,
            "district_name": "MADI-OKOLLO",
            "county_id": 287,
            "county_name": "LOWER MADI County",
            "constituency_id": 325,
            "constituency_name": "LOWER MADI COUNTY"
        },
        {
            "id": 1043,
            "name": "RIWO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 144,
            "county_name": "TOO County",
            "constituency_id": 176,
            "constituency_name": "TOO COUNTY"
        },
        {
            "id": 1044,
            "name": "RIWO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 144,
            "county_name": "TOO County",
            "constituency_id": 176,
            "constituency_name": "TOO COUNTY"
        },
        {
            "id": 930,
            "name": "ROMOGI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 131,
            "county_name": "ARINGA EAST County",
            "constituency_id": 162,
            "constituency_name": "ARINGA EAST COUNTY"
        },
        {
            "id": 532,
            "name": "RUBAARE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 79,
            "county_name": "RUSHENYI County",
            "constituency_id": 100,
            "constituency_name": "RUSHENYI COUNTY"
        },
        {
            "id": 533,
            "name": "RUBAARE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 79,
            "county_name": "RUSHENYI County",
            "constituency_id": 100,
            "constituency_name": "RUSHENYI COUNTY"
        },
        {
            "id": 1938,
            "name": "RUBANDA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 301,
            "constituency_name": "RUBANDA COUNTY WEST"
        },
        {
            "id": 117,
            "name": "RUBAYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 18,
            "county_name": "NDORWA County",
            "constituency_id": 21,
            "constituency_name": "NDORWA COUNTY WEST"
        },
        {
            "id": 420,
            "name": "RUBAYA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 27,
            "district_name": "MBARARA",
            "county_id": 60,
            "county_name": "KASHARI SOUTH County",
            "constituency_id": 79,
            "constituency_name": "KASHARI SOUTH COUNTY"
        },
        {
            "id": 1801,
            "name": "RUBENGYE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU",
            "county_id": 249,
            "county_name": "BUHWEJU WEST County",
            "constituency_id": 285,
            "constituency_name": "BUHWEJU WEST COUNTY"
        },
        {
            "id": 414,
            "name": "RUBINDI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 27,
            "district_name": "MBARARA",
            "county_id": 59,
            "county_name": "KASHARI NORTH County",
            "constituency_id": 78,
            "constituency_name": "KASHARI NORTH COUNTY"
        },
        {
            "id": 415,
            "name": "RUBINDI-RUHUMBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 27,
            "district_name": "MBARARA",
            "county_id": 59,
            "county_name": "KASHARI NORTH County",
            "constituency_id": 78,
            "constituency_name": "KASHARI NORTH COUNTY"
        },
        {
            "id": 1842,
            "name": "RUBIRIZI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 112,
            "district_name": "RUBIRIZI",
            "county_id": 255,
            "county_name": "BUNYARUGURU County",
            "constituency_id": 291,
            "constituency_name": "BUNYARUGURU COUNTY"
        },
        {
            "id": 1953,
            "name": "RUBONA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 649,
            "name": "RUBONGI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 96,
            "county_name": "WEST BUDAMA County",
            "constituency_id": 119,
            "constituency_name": "WEST BUDAMA COUNTY SOUTH"
        },
        {
            "id": 1105,
            "name": "RUBOROGOTA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 152,
            "county_name": "ISINGIRO WEST County",
            "constituency_id": 186,
            "constituency_name": "ISINGIRO WEST COUNTY"
        },
        {
            "id": 270,
            "name": "RUBUGURI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 40,
            "county_name": "BUKIMBIRI County",
            "constituency_id": 52,
            "constituency_name": "BUKIMBIRI COUNTY"
        },
        {
            "id": 1085,
            "name": "RUGAAGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 150,
            "county_name": "BUKANGA NORTH County",
            "constituency_id": 183,
            "constituency_name": "BUKANGA NORTH COUNTY"
        },
        {
            "id": 1086,
            "name": "RUGAAGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 150,
            "county_name": "BUKANGA NORTH County",
            "constituency_id": 183,
            "constituency_name": "BUKANGA NORTH COUNTY"
        },
        {
            "id": 2154,
            "name": "RUGANDO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 135,
            "district_name": "RWAMPARA",
            "county_id": 290,
            "county_name": "RWAMPARA County",
            "constituency_id": 328,
            "constituency_name": "RWAMPARA COUNTY"
        },
        {
            "id": 534,
            "name": "RUGARAMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 79,
            "county_name": "RUSHENYI County",
            "constituency_id": 100,
            "constituency_name": "RUSHENYI COUNTY"
        },
        {
            "id": 1687,
            "name": "RUGARAMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 232,
            "county_name": "SHEEMA County",
            "constituency_id": 269,
            "constituency_name": "SHEEMA COUNTY SOUTH"
        },
        {
            "id": 535,
            "name": "RUGARAMA NORTH",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 79,
            "county_name": "RUSHENYI County",
            "constituency_id": 100,
            "constituency_name": "RUSHENYI COUNTY"
        },
        {
            "id": 1881,
            "name": "RUGASHARI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 1882,
            "name": "RUGASHARI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 208,
            "name": "RUGENDABARA-KIKONGO TOWN COUNC",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 42,
            "constituency_name": "BUSONGORA COUNTY NORTH"
        },
        {
            "id": 787,
            "name": "RUGOMBE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 48,
            "district_name": "KYENJOJO",
            "county_id": 118,
            "county_name": "MWENGE CENTRAL County",
            "constituency_id": 143,
            "constituency_name": "MWENGE CENTRAL COUNTY"
        },
        {
            "id": 964,
            "name": "RUGYEYO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 167,
            "constituency_name": "KINKIZI COUNTY EAST"
        },
        {
            "id": 521,
            "name": "RUHAAMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 77,
            "county_name": "RUHAAMA County",
            "constituency_id": 98,
            "constituency_name": "RUHAAMA COUNTY"
        },
        {
            "id": 522,
            "name": "RUHAAMA EAST",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 77,
            "county_name": "RUHAAMA County",
            "constituency_id": 98,
            "constituency_name": "RUHAAMA COUNTY"
        },
        {
            "id": 1106,
            "name": "RUHIIRA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 152,
            "county_name": "ISINGIRO WEST County",
            "constituency_id": 186,
            "constituency_name": "ISINGIRO WEST COUNTY"
        },
        {
            "id": 1939,
            "name": "RUHIJA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 301,
            "constituency_name": "RUBANDA COUNTY WEST"
        },
        {
            "id": 1940,
            "name": "RUHIJA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 116,
            "district_name": "RUBANDA",
            "county_id": 264,
            "county_name": "RUBANDA County",
            "constituency_id": 301,
            "constituency_name": "RUBANDA COUNTY WEST"
        },
        {
            "id": 597,
            "name": "RUHINDA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 87,
            "county_name": "RUJUMBURA County",
            "constituency_id": 109,
            "constituency_name": "RUJUMBURA COUNTY"
        },
        {
            "id": 52,
            "name": "RUHUMURO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 8,
            "county_name": "IGARA County",
            "constituency_id": 8,
            "constituency_name": "IGARA COUNTY EAST"
        },
        {
            "id": 2131,
            "name": "RUHUNGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 132,
            "district_name": "KITAGWENDA",
            "county_id": 286,
            "county_name": "KITAGWENDA County",
            "constituency_id": 324,
            "constituency_name": "KITAGWENDA COUNTY"
        },
        {
            "id": 1073,
            "name": "RUKIRI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 147,
            "county_name": "IBANDA County",
            "constituency_id": 180,
            "constituency_name": "IBANDA COUNTY SOUTH"
        },
        {
            "id": 218,
            "name": "RUKOKI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 15,
            "district_name": "KASESE",
            "county_id": 33,
            "county_name": "BUSONGORA County",
            "constituency_id": 43,
            "constituency_name": "BUSONGORA COUNTY SOUTH"
        },
        {
            "id": 527,
            "name": "RUKONI EAST",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 78,
            "county_name": "RUHAAMA EAST County",
            "constituency_id": 99,
            "constituency_name": "RUHAAMA EAST COUNTY"
        },
        {
            "id": 528,
            "name": "RUKONI WEST",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 78,
            "county_name": "RUHAAMA EAST County",
            "constituency_id": 99,
            "constituency_name": "RUHAAMA EAST COUNTY"
        },
        {
            "id": 257,
            "name": "RUKUNDO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 39,
            "county_name": "BUFUMBIRA County",
            "constituency_id": 50,
            "constituency_name": "BUFUMBIRA COUNTY NORTH"
        },
        {
            "id": 555,
            "name": "RUKUNYU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 46,
            "district_name": "KAMWENGE",
            "county_id": 114,
            "county_name": "KIBALE County",
            "constituency_id": 104,
            "constituency_name": "KIBALE COUNTY"
        },
        {
            "id": 426,
            "name": "RUPA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 28,
            "district_name": "MOROTO",
            "county_id": 61,
            "county_name": "MATHENIKO County",
            "constituency_id": 80,
            "constituency_name": "MATHENIKO COUNTY"
        },
        {
            "id": 1762,
            "name": "RUREHE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 243,
            "county_name": "RUHINDA SOUTH County",
            "constituency_id": 279,
            "constituency_name": "RUHINDA SOUTH COUNTY"
        },
        {
            "id": 1066,
            "name": "RUSHANGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 147,
            "county_name": "IBANDA County",
            "constituency_id": 179,
            "constituency_name": "IBANDA COUNTY NORTH"
        },
        {
            "id": 1087,
            "name": "RUSHASHA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 150,
            "county_name": "BUKANGA NORTH County",
            "constituency_id": 183,
            "constituency_name": "BUKANGA NORTH COUNTY"
        },
        {
            "id": 1150,
            "name": "RUSHERE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA",
            "county_id": 159,
            "county_name": "NYABUSHOZI County",
            "constituency_id": 193,
            "constituency_name": "NYABUSHOZI COUNTY"
        },
        {
            "id": 1883,
            "name": "RUTEETE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 132,
            "name": "RUTEETE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 966,
            "name": "RUTENGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 167,
            "constituency_name": "KINKIZI COUNTY EAST"
        },
        {
            "id": 967,
            "name": "RUTENGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 55,
            "district_name": "KANUNGU",
            "county_id": 136,
            "county_name": "KINKIZI County",
            "constituency_id": 167,
            "constituency_name": "KINKIZI COUNTY EAST"
        },
        {
            "id": 1884,
            "name": "RUTETE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 113,
            "district_name": "KAGADI",
            "county_id": 258,
            "county_name": "BUYAGA WEST County",
            "constituency_id": 294,
            "constituency_name": "BUYAGA WEST COUNTY"
        },
        {
            "id": 1754,
            "name": "RUTOOKYE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 242,
            "county_name": "RUHINDA NORTH County",
            "constituency_id": 278,
            "constituency_name": "RUHINDA NORTH COUNTY"
        },
        {
            "id": 1843,
            "name": "RUTOTO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 112,
            "district_name": "RUBIRIZI",
            "county_id": 255,
            "county_name": "BUNYARUGURU County",
            "constituency_id": 291,
            "constituency_name": "BUNYARUGURU COUNTY"
        },
        {
            "id": 1102,
            "name": "RUYANGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 185,
            "constituency_name": "ISINGIRO COUNTY SOUTH"
        },
        {
            "id": 1465,
            "name": "RUYONZA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 199,
            "county_name": "KYAKA SOUTH County",
            "constituency_id": 235,
            "constituency_name": "KYAKA SOUTH COUNTY"
        },
        {
            "id": 513,
            "name": "RWAMABONDO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 76,
            "county_name": "KAJARA County",
            "constituency_id": 97,
            "constituency_name": "KAJARA COUNTY"
        },
        {
            "id": 2031,
            "name": "RWAMUCUCU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 122,
            "district_name": "RUKIGA",
            "county_id": 272,
            "county_name": "RUKIGA County",
            "constituency_id": 309,
            "constituency_name": "RUKIGA COUNTY"
        },
        {
            "id": 1081,
            "name": "RWANJOGYERA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 149,
            "county_name": "BUKANGA County",
            "constituency_id": 182,
            "constituency_name": "BUKANGA COUNTY"
        },
        {
            "id": 421,
            "name": "RWANYAMAHEMBE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 27,
            "district_name": "MBARARA",
            "county_id": 60,
            "county_name": "KASHARI SOUTH County",
            "constituency_id": 79,
            "constituency_name": "KASHARI SOUTH COUNTY"
        },
        {
            "id": 514,
            "name": "RWASHAMEIRE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 76,
            "county_name": "KAJARA County",
            "constituency_id": 97,
            "constituency_name": "KAJARA COUNTY"
        },
        {
            "id": 1638,
            "name": "RWEBISENGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 96,
            "district_name": "NTOROKO",
            "county_id": 223,
            "county_name": "NTOROKO County",
            "constituency_id": 259,
            "constituency_name": "NTOROKO COUNTY"
        },
        {
            "id": 1639,
            "name": "RWEBISENGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 96,
            "district_name": "NTOROKO",
            "county_id": 223,
            "county_name": "NTOROKO County",
            "constituency_id": 259,
            "constituency_name": "NTOROKO COUNTY"
        },
        {
            "id": 2158,
            "name": "RWEIBOGO-KIBINGO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 135,
            "district_name": "RWAMPARA",
            "county_id": 291,
            "county_name": "RWAMPARA EAST County",
            "constituency_id": 329,
            "constituency_name": "RWAMPARA EAST COUNTY"
        },
        {
            "id": 523,
            "name": "RWEIKINIRO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 77,
            "county_name": "RUHAAMA County",
            "constituency_id": 98,
            "constituency_name": "RUHAAMA COUNTY"
        },
        {
            "id": 2119,
            "name": "RWEMIKOMA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 131,
            "district_name": "KAZO",
            "county_id": 285,
            "county_name": "KAZO County",
            "constituency_id": 323,
            "constituency_name": "KAZO COUNTY"
        },
        {
            "id": 133,
            "name": "RWENGAJU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 10,
            "district_name": "KABAROLE",
            "county_id": 19,
            "county_name": "BURAHYA County",
            "constituency_id": 22,
            "constituency_name": "BURAHYA COUNTY"
        },
        {
            "id": 1793,
            "name": "RWENGWE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 109,
            "district_name": "BUHWEJU",
            "county_id": 248,
            "county_name": "BUHWEJU County",
            "constituency_id": 284,
            "constituency_name": "BUHWEJU COUNTY"
        },
        {
            "id": 2132,
            "name": "RWENJAZA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 132,
            "district_name": "KITAGWENDA",
            "county_id": 286,
            "county_name": "KITAGWENDA County",
            "constituency_id": 324,
            "constituency_name": "KITAGWENDA COUNTY"
        },
        {
            "id": 1067,
            "name": "RWENKOBWA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 61,
            "district_name": "IBANDA",
            "county_id": 147,
            "county_name": "IBANDA County",
            "constituency_id": 179,
            "constituency_name": "IBANDA COUNTY NORTH"
        },
        {
            "id": 1151,
            "name": "RWENSHANDE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA",
            "county_id": 159,
            "county_name": "NYABUSHOZI County",
            "constituency_id": 193,
            "constituency_name": "NYABUSHOZI COUNTY"
        },
        {
            "id": 536,
            "name": "RWENTOBO-RWAHI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 79,
            "county_name": "RUSHENYI County",
            "constituency_id": 100,
            "constituency_name": "RUSHENYI COUNTY"
        },
        {
            "id": 1466,
            "name": "RWENTUHA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 84,
            "district_name": "KYEGEGWA",
            "county_id": 199,
            "county_name": "KYAKA SOUTH County",
            "constituency_id": 235,
            "constituency_name": "KYAKA SOUTH COUNTY"
        },
        {
            "id": 53,
            "name": "RWENTUHA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 4,
            "district_name": "BUSHENYI",
            "county_id": 8,
            "county_name": "IGARA County",
            "constituency_id": 8,
            "constituency_name": "IGARA COUNTY EAST"
        },
        {
            "id": 598,
            "name": "RWERERE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 87,
            "county_name": "RUJUMBURA County",
            "constituency_id": 109,
            "constituency_name": "RUJUMBURA COUNTY"
        },
        {
            "id": 1152,
            "name": "RWETAMU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA",
            "county_id": 159,
            "county_name": "NYABUSHOZI County",
            "constituency_id": 193,
            "constituency_name": "NYABUSHOZI COUNTY"
        },
        {
            "id": 1095,
            "name": "RWETANGO",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 62,
            "district_name": "ISINGIRO",
            "county_id": 151,
            "county_name": "ISINGIRO County",
            "constituency_id": 184,
            "constituency_name": "ISINGIRO COUNTY NORTH"
        },
        {
            "id": 1954,
            "name": "RWIMI",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 1955,
            "name": "RWIMI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 16,
            "sub_region_name": "Tooro",
            "district_id": 117,
            "district_name": "BUNYANGABU",
            "county_id": 265,
            "county_name": "BUNYANGABU County",
            "constituency_id": 302,
            "constituency_name": "BUNYANGABU COUNTY"
        },
        {
            "id": 1755,
            "name": "RWOBURUNGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 106,
            "district_name": "MITOOMA",
            "county_id": 242,
            "county_name": "RUHINDA NORTH County",
            "constituency_id": 278,
            "constituency_name": "RUHINDA NORTH COUNTY"
        },
        {
            "id": 529,
            "name": "RWOHO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 78,
            "county_name": "RUHAAMA EAST County",
            "constituency_id": 99,
            "constituency_name": "RUHAAMA EAST COUNTY"
        },
        {
            "id": 118,
            "name": "RYAKARIMIRA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 9,
            "district_name": "KABALE",
            "county_id": 18,
            "county_name": "NDORWA County",
            "constituency_id": 21,
            "constituency_name": "NDORWA COUNTY WEST"
        },
        {
            "id": 1844,
            "name": "RYERU",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 112,
            "district_name": "RUBIRIZI",
            "county_id": 255,
            "county_name": "BUNYARUGURU County",
            "constituency_id": 291,
            "constituency_name": "BUNYARUGURU COUNTY"
        },
        {
            "id": 1153,
            "name": "SANGA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA",
            "county_id": 159,
            "county_name": "NYABUSHOZI County",
            "constituency_id": 193,
            "constituency_name": "NYABUSHOZI COUNTY"
        },
        {
            "id": 1154,
            "name": "SANGA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 65,
            "district_name": "KIRUHURA",
            "county_id": 159,
            "county_name": "NYABUSHOZI County",
            "constituency_id": 193,
            "constituency_name": "NYABUSHOZI COUNTY"
        },
        {
            "id": 2105,
            "name": "SANGAR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 130,
            "district_name": "KARENGA",
            "county_id": 283,
            "county_name": "DODOTH WEST County",
            "constituency_id": 321,
            "constituency_name": "DODOTH WEST COUNTY"
        },
        {
            "id": 1228,
            "name": "SEMUTO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 170,
            "county_name": "NAKASEKE SOUTH County",
            "constituency_id": 205,
            "constituency_name": "NAKASEKE SOUTH COUNTY"
        },
        {
            "id": 1229,
            "name": "SEMUTO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 170,
            "county_name": "NAKASEKE SOUTH County",
            "constituency_id": 205,
            "constituency_name": "NAKASEKE SOUTH COUNTY"
        },
        {
            "id": 1033,
            "name": "SENENDET",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 143,
            "county_name": "KONGASIS County",
            "constituency_id": 175,
            "constituency_name": "KONGASIS COUNTY"
        },
        {
            "id": 653,
            "name": "SERE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 97,
            "county_name": "WEST BUDAMA NORTH EAST County",
            "constituency_id": 120,
            "constituency_name": "WEST BUDAMA NORTH EAST COUNTY"
        },
        {
            "id": 1653,
            "name": "SERERE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 226,
            "county_name": "SERERE County",
            "constituency_id": 262,
            "constituency_name": "SERERE COUNTY"
        },
        {
            "id": 1654,
            "name": "SERERE/OLIO",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 97,
            "district_name": "SERERE",
            "county_id": 226,
            "county_name": "SERERE County",
            "constituency_id": 262,
            "constituency_name": "SERERE COUNTY"
        },
        {
            "id": 1692,
            "name": "SHEEMA CENTRAL DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 233,
            "county_name": "SHEEMA Municipality",
            "constituency_id": 270,
            "constituency_name": "SHEEMA MUNICIPALITY"
        },
        {
            "id": 1688,
            "name": "SHUUKU TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 101,
            "district_name": "SHEEMA",
            "county_id": 232,
            "county_name": "SHEEMA County",
            "constituency_id": 269,
            "constituency_name": "SHEEMA COUNTY SOUTH"
        },
        {
            "id": 1183,
            "name": "SIBANGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 1115,
            "name": "SIDOK (KOPOTH)",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 153,
            "county_name": "DODOTH EAST County",
            "constituency_id": 187,
            "constituency_name": "DODOTH EAST COUNTY"
        },
        {
            "id": 1621,
            "name": "SIGULU ISLANDS",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 95,
            "district_name": "NAMAYINGO",
            "county_id": 220,
            "county_name": "BUKOOLI ISLAND County",
            "constituency_id": 256,
            "constituency_name": "BUKOOLI ISLAND COUNTY"
        },
        {
            "id": 700,
            "name": "SIKUDA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 104,
            "county_name": "SAMIA BUGWE County",
            "constituency_id": 128,
            "constituency_name": "SAMIA BUGWE COUNTY NORTH"
        },
        {
            "id": 1544,
            "name": "SIMU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 209,
            "county_name": "ELGON County",
            "constituency_id": 245,
            "constituency_name": "ELGON COUNTY"
        },
        {
            "id": 30,
            "name": "SINDILA",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 5,
            "county_name": "BUGHENDERA County",
            "constituency_id": 5,
            "constituency_name": "BUGHENDERA COUNTY"
        },
        {
            "id": 176,
            "name": "SIPI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 31,
            "county_name": "TINGEY County",
            "constituency_id": 39,
            "constituency_name": "TINGEY COUNTY"
        },
        {
            "id": 177,
            "name": "SIPI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 31,
            "county_name": "TINGEY County",
            "constituency_id": 39,
            "constituency_name": "TINGEY COUNTY"
        },
        {
            "id": 871,
            "name": "SIRONKO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 151,
            "constituency_name": "BUDADIRI COUNTY WEST"
        },
        {
            "id": 1554,
            "name": "SISIYI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 210,
            "county_name": "ELGON NORTH County",
            "constituency_id": 246,
            "constituency_name": "ELGON NORTH COUNTY"
        },
        {
            "id": 1197,
            "name": "SISUNI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 164,
            "county_name": "BUTIRU County",
            "constituency_id": 198,
            "constituency_name": "BUTIRU COUNTY"
        },
        {
            "id": 637,
            "name": "SIWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 95,
            "county_name": "WEST BUDAMA CENTRAL County",
            "constituency_id": 117,
            "constituency_name": "WEST BUDAMA CENTRAL COUNTY"
        },
        {
            "id": 654,
            "name": "SONI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 97,
            "county_name": "WEST BUDAMA NORTH EAST County",
            "constituency_id": 120,
            "constituency_name": "WEST BUDAMA NORTH EAST COUNTY"
        },
        {
            "id": 655,
            "name": "SOPSOP",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 97,
            "county_name": "WEST BUDAMA NORTH EAST County",
            "constituency_id": 120,
            "constituency_name": "WEST BUDAMA NORTH EAST COUNTY"
        },
        {
            "id": 1545,
            "name": "SOTTI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 89,
            "district_name": "BULAMBULI",
            "county_id": 209,
            "county_name": "ELGON County",
            "constituency_id": 245,
            "constituency_name": "ELGON COUNTY"
        },
        {
            "id": 1164,
            "name": "SOUTH DIVISION",
            "type": "Municipal-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 66,
            "district_name": "KOBOKO",
            "county_id": 162,
            "county_name": "KOBOKO Municipality",
            "constituency_id": 196,
            "constituency_name": "KOBOKO MUNICIPALITY"
        },
        {
            "id": 273,
            "name": "SOUTH DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 18,
            "district_name": "KISORO",
            "county_id": 41,
            "county_name": "KISORO Municipality",
            "constituency_id": 53,
            "constituency_name": "KISORO MUNICIPALITY"
        },
        {
            "id": 467,
            "name": "SOUTH DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 69,
            "county_name": "MUBENDE Municipality",
            "constituency_id": 89,
            "constituency_name": "MUBENDE MUNICIPALITY"
        },
        {
            "id": 332,
            "name": "SOUTH DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 49,
            "county_name": "KUMI Municipality",
            "constituency_id": 61,
            "constituency_name": "KUMI MUNICIPALITY"
        },
        {
            "id": 313,
            "name": "SOUTH DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 46,
            "county_name": "KOTIDO Municipality",
            "constituency_id": 58,
            "constituency_name": "KOTIDO MUNICIPALITY"
        },
        {
            "id": 430,
            "name": "SOUTH DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 28,
            "district_name": "MOROTO",
            "county_id": 63,
            "county_name": "MOROTO Municipality",
            "constituency_id": 82,
            "constituency_name": "MOROTO MUNICIPALITY"
        },
        {
            "id": 162,
            "name": "SOUTHERN DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 29,
            "county_name": "KAMULI Municipality",
            "constituency_id": 37,
            "constituency_name": "KAMULI MUNICIPALITY"
        },
        {
            "id": 600,
            "name": "SOUTHERN DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 88,
            "county_name": "RUKUNGIRI Municipality",
            "constituency_id": 110,
            "constituency_name": "RUKUNGIRI MUNICIPALITY"
        },
        {
            "id": 1210,
            "name": "SSEKANYONYI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 166,
            "county_name": "MITYANA County",
            "constituency_id": 201,
            "constituency_name": "MITYANA COUNTY SOUTH"
        },
        {
            "id": 1211,
            "name": "SSEKANYONYI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 166,
            "county_name": "MITYANA County",
            "constituency_id": 201,
            "constituency_name": "MITYANA COUNTY SOUTH"
        },
        {
            "id": 755,
            "name": "SSEMBABULE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 45,
            "district_name": "SSEMBABULE",
            "county_id": 112,
            "county_name": "MAWOGOLA NORTH County",
            "constituency_id": 137,
            "constituency_name": "MAWOGOLA NORTH COUNTY"
        },
        {
            "id": 1427,
            "name": "SSI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE",
            "county_id": 192,
            "county_name": "BUIKWE County",
            "constituency_id": 228,
            "constituency_name": "BUIKWE COUNTY SOUTH"
        },
        {
            "id": 1034,
            "name": "SUAM",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 143,
            "county_name": "KONGASIS County",
            "constituency_id": 175,
            "constituency_name": "KONGASIS COUNTY"
        },
        {
            "id": 1035,
            "name": "SUAM TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 143,
            "county_name": "KONGASIS County",
            "constituency_id": 175,
            "constituency_name": "KONGASIS COUNTY"
        },
        {
            "id": 1744,
            "name": "SUNDET",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 239,
            "county_name": "SOI County",
            "constituency_id": 276,
            "constituency_name": "SOI COUNTY"
        },
        {
            "id": 1269,
            "name": "TADEMERI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 72,
            "district_name": "BUDAKA",
            "county_id": 174,
            "county_name": "BUDAKA County",
            "constituency_id": 209,
            "constituency_name": "BUDAKA COUNTY"
        },
        {
            "id": 428,
            "name": "TAPAC",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 28,
            "district_name": "MOROTO",
            "county_id": 62,
            "county_name": "TEPETH County",
            "constituency_id": 81,
            "constituency_name": "TEPETH COUNTY"
        },
        {
            "id": 1356,
            "name": "TARA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 184,
            "county_name": "MARACHA EAST County",
            "constituency_id": 220,
            "constituency_name": "MARACHA EAST COUNTY"
        },
        {
            "id": 12,
            "name": "TE-BOKE",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 1,
            "district_name": "APAC",
            "county_id": 3,
            "county_name": "MARUZI NORTH County",
            "constituency_id": 3,
            "constituency_name": "MARUZI NORTH COUNTY"
        },
        {
            "id": 836,
            "name": "TE-NAM",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 50,
            "district_name": "PADER",
            "county_id": 121,
            "county_name": "ARUU County",
            "constituency_id": 149,
            "constituency_name": "ARUU COUNTY"
        },
        {
            "id": 489,
            "name": "THATHA DIVISION",
            "type": "Municipal-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 33,
            "district_name": "NEBBI",
            "county_id": 73,
            "county_name": "NEBBI Municipality",
            "constituency_id": 94,
            "constituency_name": "NEBBI MUNICIPALITY"
        },
        {
            "id": 701,
            "name": "TIIRA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 104,
            "county_name": "SAMIA BUGWE County",
            "constituency_id": 128,
            "constituency_name": "SAMIA BUGWE COUNTY NORTH"
        },
        {
            "id": 1125,
            "name": "TIMU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 63,
            "district_name": "KAABONG",
            "county_id": 155,
            "county_name": "IK County",
            "constituency_id": 189,
            "constituency_name": "IK COUNTY"
        },
        {
            "id": 1713,
            "name": "TIRINYI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 235,
            "county_name": "KIBUKU County",
            "constituency_id": 272,
            "constituency_name": "KIBUKU COUNTY"
        },
        {
            "id": 1714,
            "name": "TIRINYI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 102,
            "district_name": "KIBUKU",
            "county_id": 235,
            "county_name": "KIBUKU County",
            "constituency_id": 272,
            "constituency_name": "KIBUKU COUNTY"
        },
        {
            "id": 330,
            "name": "TISAI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 21,
            "district_name": "KUMI",
            "county_id": 48,
            "county_name": "KUMI County",
            "constituency_id": 60,
            "constituency_name": "KUMI COUNTY"
        },
        {
            "id": 990,
            "name": "TOKORA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 56,
            "district_name": "NAKAPIRIPIRIT",
            "county_id": 138,
            "county_name": "CHEKWII EAST County",
            "constituency_id": 170,
            "constituency_name": "CHEKWII EAST COUNTY"
        },
        {
            "id": 43,
            "name": "TOKWE",
            "type": "Sub-County",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 15,
            "sub_region_name": "Rwenzori",
            "district_id": 3,
            "district_name": "BUNDIBUGYO",
            "county_id": 6,
            "county_name": "BWAMBA County",
            "constituency_id": 6,
            "constituency_name": "BWAMBA COUNTY"
        },
        {
            "id": 720,
            "name": "TOROMA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 106,
            "county_name": "TOROMA County",
            "constituency_id": 131,
            "constituency_name": "TOROMA COUNTY"
        },
        {
            "id": 721,
            "name": "TOROMA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 106,
            "county_name": "TOROMA County",
            "constituency_id": 131,
            "constituency_name": "TOROMA COUNTY"
        },
        {
            "id": 614,
            "name": "TORORO EASTERN",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 92,
            "county_name": "TORORO Municipality",
            "constituency_id": 114,
            "constituency_name": "TORORO MUNICIPALITY"
        },
        {
            "id": 615,
            "name": "TORORO WESTERN",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 39,
            "district_name": "TORORO",
            "county_id": 92,
            "county_name": "TORORO Municipality",
            "constituency_id": 114,
            "constituency_name": "TORORO MUNICIPALITY"
        },
        {
            "id": 2015,
            "name": "TSEKULULU",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 120,
            "district_name": "NAMISINDWA",
            "county_id": 270,
            "county_name": "NAMISINDWA County",
            "constituency_id": 307,
            "constituency_name": "NAMISINDWA COUNTY"
        },
        {
            "id": 1567,
            "name": "TTABA-BBINZI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 91,
            "district_name": "GOMBA",
            "county_id": 212,
            "county_name": "GOMBA EAST County",
            "constituency_id": 248,
            "constituency_name": "GOMBA EAST COUNTY"
        },
        {
            "id": 1214,
            "name": "TTAMU DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 167,
            "county_name": "MITYANA Municipality",
            "constituency_id": 202,
            "constituency_name": "MITYANA MUNICIPALITY"
        },
        {
            "id": 605,
            "name": "TUBUR",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI",
            "county_id": 89,
            "county_name": "DAKABELA County",
            "constituency_id": 111,
            "constituency_name": "DAKABELA COUNTY"
        },
        {
            "id": 606,
            "name": "TUBUR TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 38,
            "district_name": "SOROTI",
            "county_id": 89,
            "county_name": "DAKABELA County",
            "constituency_id": 111,
            "constituency_name": "DAKABELA COUNTY"
        },
        {
            "id": 1740,
            "name": "TUIKAT",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 104,
            "district_name": "KWEEN",
            "county_id": 238,
            "county_name": "KWEEN County",
            "constituency_id": 275,
            "constituency_name": "KWEEN COUNTY"
        },
        {
            "id": 1045,
            "name": "TULEL",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 59,
            "district_name": "BUKWO",
            "county_id": 144,
            "county_name": "TOO County",
            "constituency_id": 176,
            "constituency_name": "TOO COUNTY"
        },
        {
            "id": 666,
            "name": "UKUSIJONI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 10,
            "sub_region_name": "Madi",
            "district_id": 40,
            "district_name": "ADJUMANI",
            "county_id": 99,
            "county_name": "ADJUMANI WEST County",
            "constituency_id": 122,
            "constituency_name": "ADJUMANI WEST COUNTY"
        },
        {
            "id": 2144,
            "name": "ULEPPI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 133,
            "district_name": "MADI-OKOLLO",
            "county_id": 288,
            "county_name": "UPPER MADI County",
            "constituency_id": 326,
            "constituency_name": "UPPER MADI COUNTY"
        },
        {
            "id": 71,
            "name": "UNYAMA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 5,
            "district_name": "GULU",
            "county_id": 9,
            "county_name": "ASWA County",
            "constituency_id": 10,
            "constituency_name": "ASWA COUNTY"
        },
        {
            "id": 2161,
            "name": "URIAMA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 143,
            "district_name": "TEREGO",
            "county_id": 306,
            "county_name": "TEREGO EAST County",
            "constituency_id": 346,
            "constituency_name": "TEREGO EAST COUNTY"
        },
        {
            "id": 725,
            "name": "USUK",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 107,
            "county_name": "USUK County",
            "constituency_id": 132,
            "constituency_name": "USUK COUNTY"
        },
        {
            "id": 726,
            "name": "USUK TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 43,
            "district_name": "KATAKWI",
            "county_id": 107,
            "county_name": "USUK County",
            "constituency_id": 132,
            "constituency_name": "USUK COUNTY"
        },
        {
            "id": 16,
            "name": "VURRA",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 2,
            "district_name": "ARUA",
            "county_id": 4,
            "county_name": "VURRA County",
            "constituency_id": 4,
            "constituency_name": "VURRA COUNTY"
        },
        {
            "id": 741,
            "name": "WABINYONYI",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 44,
            "district_name": "NAKASONGOLA",
            "county_id": 109,
            "county_name": "NAKASONGOLA County",
            "constituency_id": 134,
            "constituency_name": "NAKASONGOLA COUNTY"
        },
        {
            "id": 2025,
            "name": "WADELAI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 121,
            "district_name": "PAKWACH",
            "county_id": 271,
            "county_name": "JONAM County",
            "constituency_id": 308,
            "constituency_name": "JONAM COUNTY"
        },
        {
            "id": 1618,
            "name": "WAIBUGA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 94,
            "district_name": "LUUKA",
            "county_id": 219,
            "county_name": "LUUKA SOUTH County",
            "constituency_id": 255,
            "constituency_name": "LUUKA SOUTH COUNTY"
        },
        {
            "id": 827,
            "name": "WAIRASA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 49,
            "district_name": "MAYUGE",
            "county_id": 120,
            "county_name": "BUNYA County",
            "constituency_id": 148,
            "constituency_name": "BUNYA COUNTY WEST"
        },
        {
            "id": 1433,
            "name": "WAKISI DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 82,
            "district_name": "BUIKWE",
            "county_id": 194,
            "county_name": "NJERU Municipality",
            "constituency_id": 230,
            "constituency_name": "NJERU MUNICIPALITY"
        },
        {
            "id": 895,
            "name": "WAKISO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 124,
            "county_name": "BUSIRO County",
            "constituency_id": 153,
            "constituency_name": "BUSIRO COUNTY EAST"
        },
        {
            "id": 896,
            "name": "WAKISO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 52,
            "district_name": "WAKISO",
            "county_id": 124,
            "county_name": "BUSIRO County",
            "constituency_id": 153,
            "constituency_name": "BUSIRO COUNTY EAST"
        },
        {
            "id": 1219,
            "name": "WAKYATO",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 69,
            "district_name": "NAKASEKE",
            "county_id": 168,
            "county_name": "NAKASEKE CENTRAL County",
            "constituency_id": 203,
            "constituency_name": "NAKASEKE CENTRAL COUNTY"
        },
        {
            "id": 404,
            "name": "WANALE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 26,
            "district_name": "MBALE",
            "county_id": 58,
            "county_name": "BUNGOKHO County",
            "constituency_id": 76,
            "constituency_name": "BUNGOKHO COUNTY NORTH"
        },
        {
            "id": 937,
            "name": "WANDI",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 132,
            "county_name": "ARINGA NORTH County",
            "constituency_id": 163,
            "constituency_name": "ARINGA NORTH COUNTY"
        },
        {
            "id": 160,
            "name": "WANKOLE",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 13,
            "district_name": "KAMULI",
            "county_id": 28,
            "county_name": "BUZAAYA County",
            "constituency_id": 36,
            "constituency_name": "BUZAAYA COUNTY"
        },
        {
            "id": 1286,
            "name": "WANSEKO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 13,
            "sub_region_name": "Bunyoro",
            "district_id": 73,
            "district_name": "BULIISA",
            "county_id": 176,
            "county_name": "BULIISA County",
            "constituency_id": 211,
            "constituency_name": "BULIISA COUNTY"
        },
        {
            "id": 1512,
            "name": "WARR",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 205,
            "county_name": "ORA County",
            "constituency_id": 241,
            "constituency_name": "ORA COUNTY"
        },
        {
            "id": 1513,
            "name": "WARR TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 205,
            "county_name": "ORA County",
            "constituency_id": 241,
            "constituency_name": "ORA COUNTY"
        },
        {
            "id": 1605,
            "name": "WATTUBA",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 217,
            "county_name": "NTWETWE County",
            "constituency_id": 253,
            "constituency_name": "NTWETWE COUNTY"
        },
        {
            "id": 1606,
            "name": "WATTUBA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 93,
            "district_name": "KYANKWANZI",
            "county_id": 217,
            "county_name": "NTWETWE County",
            "constituency_id": 253,
            "constituency_name": "NTWETWE COUNTY"
        },
        {
            "id": 1015,
            "name": "WERA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 141,
            "county_name": "AMURIA County",
            "constituency_id": 173,
            "constituency_name": "AMURIA COUNTY"
        },
        {
            "id": 1016,
            "name": "WERA TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 141,
            "county_name": "AMURIA County",
            "constituency_id": 173,
            "constituency_name": "AMURIA COUNTY"
        },
        {
            "id": 468,
            "name": "WEST DIVISION",
            "type": "Municipal-Division",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 31,
            "district_name": "MUBENDE",
            "county_id": 69,
            "county_name": "MUBENDE Municipality",
            "constituency_id": 89,
            "constituency_name": "MUBENDE MUNICIPALITY"
        },
        {
            "id": 165,
            "name": "WEST DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 6,
            "sub_region_name": "Sebei",
            "district_id": 14,
            "district_name": "KAPCHORWA",
            "county_id": 30,
            "county_name": "KAPCHORWA Municipality",
            "constituency_id": 38,
            "constituency_name": "KAPCHORWA MUNICIPALITY"
        },
        {
            "id": 314,
            "name": "WEST DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 5,
            "sub_region_name": "Karamoja",
            "district_id": 20,
            "district_name": "KOTIDO",
            "county_id": 46,
            "county_name": "KOTIDO Municipality",
            "constituency_id": 58,
            "constituency_name": "KOTIDO MUNICIPALITY"
        },
        {
            "id": 505,
            "name": "WESTERN DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 12,
            "sub_region_name": "Ankole",
            "district_id": 34,
            "district_name": "NTUNGAMO",
            "county_id": 75,
            "county_name": "NTUNGAMO Municipality",
            "constituency_id": 96,
            "constituency_name": "NTUNGAMO MUNICIPALITY"
        },
        {
            "id": 688,
            "name": "WESTERN DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 3,
            "sub_region_name": "Bukedi",
            "district_id": 42,
            "district_name": "BUSIA",
            "county_id": 102,
            "county_name": "BUSIA Municipality",
            "constituency_id": 126,
            "constituency_name": "BUSIA MUNICIPALITY"
        },
        {
            "id": 668,
            "name": "WESTERN DIVISION",
            "type": "Municipal-Division",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 4,
            "sub_region_name": "Busoga",
            "district_id": 41,
            "district_name": "BUGIRI",
            "county_id": 100,
            "county_name": "BUGIRI Municipality",
            "constituency_id": 123,
            "constituency_name": "BUGIRI MUNICIPALITY"
        },
        {
            "id": 601,
            "name": "WESTERN DIVISION",
            "type": "Municipal-Division",
            "region_id": 4,
            "region_name": "Western",
            "sub_region_id": 14,
            "sub_region_name": "Kigezi",
            "district_id": 37,
            "district_name": "RUKUNGIRI",
            "county_id": 88,
            "county_name": "RUKUNGIRI Municipality",
            "constituency_id": 110,
            "constituency_name": "RUKUNGIRI MUNICIPALITY"
        },
        {
            "id": 1165,
            "name": "WESTERN DIVISION",
            "type": "Municipal-Division",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 66,
            "district_name": "KOBOKO",
            "county_id": 162,
            "county_name": "KOBOKO Municipality",
            "constituency_id": 196,
            "constituency_name": "KOBOKO MUNICIPALITY"
        },
        {
            "id": 1184,
            "name": "WESWA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 67,
            "district_name": "MANAFWA",
            "county_id": 163,
            "county_name": "BUBULO WEST County",
            "constituency_id": 197,
            "constituency_name": "BUBULO WEST COUNTY"
        },
        {
            "id": 1017,
            "name": "WILLA",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 7,
            "sub_region_name": "Teso",
            "district_id": 58,
            "district_name": "AMURIA",
            "county_id": 141,
            "county_name": "AMURIA County",
            "constituency_id": 173,
            "constituency_name": "AMURIA COUNTY"
        },
        {
            "id": 343,
            "name": "WIODYEK",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 9,
            "sub_region_name": "Lango",
            "district_id": 22,
            "district_name": "LIRA",
            "county_id": 50,
            "county_name": "ERUTE County",
            "constituency_id": 63,
            "constituency_name": "ERUTE COUNTY SOUTH"
        },
        {
            "id": 361,
            "name": "WOBULENZI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 52,
            "county_name": "KATIKAMU County",
            "constituency_id": 66,
            "constituency_name": "KATIKAMU COUNTY SOUTH"
        },
        {
            "id": 1837,
            "name": "WOL",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 254,
            "county_name": "AGAGO NORTH County",
            "constituency_id": 290,
            "constituency_name": "AGAGO NORTH COUNTY"
        },
        {
            "id": 1838,
            "name": "WOL TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 8,
            "sub_region_name": "Acholi",
            "district_id": 111,
            "district_name": "AGAGO",
            "county_id": 254,
            "county_name": "AGAGO NORTH County",
            "constituency_id": 290,
            "constituency_name": "AGAGO NORTH COUNTY"
        },
        {
            "id": 1357,
            "name": "YIVU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 77,
            "district_name": "MARACHA",
            "county_id": 184,
            "county_name": "MARACHA EAST County",
            "constituency_id": 220,
            "constituency_name": "MARACHA EAST COUNTY"
        },
        {
            "id": 924,
            "name": "YUMBE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 53,
            "district_name": "YUMBE",
            "county_id": 130,
            "county_name": "ARINGA County",
            "constituency_id": 161,
            "constituency_name": "ARINGA COUNTY"
        },
        {
            "id": 892,
            "name": "ZESUI",
            "type": "Sub-County",
            "region_id": 2,
            "region_name": "Eastern",
            "sub_region_id": 2,
            "sub_region_name": "Bugisu",
            "district_id": 51,
            "district_name": "SIRONKO",
            "county_id": 123,
            "county_name": "BUDADIRI County",
            "constituency_id": 152,
            "constituency_name": "BUDADIRI COUNTY EAST"
        },
        {
            "id": 1514,
            "name": "ZEU",
            "type": "Sub-County",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 205,
            "county_name": "ORA County",
            "constituency_id": 241,
            "constituency_name": "ORA COUNTY"
        },
        {
            "id": 1204,
            "name": "ZIGOTI TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 68,
            "district_name": "MITYANA",
            "county_id": 165,
            "county_name": "BUSUJJU County",
            "constituency_id": 199,
            "constituency_name": "BUSUJJU COUNTY"
        },
        {
            "id": 351,
            "name": "ZIROBWE",
            "type": "Sub-County",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 51,
            "county_name": "BAMUNANIKA County",
            "constituency_id": 64,
            "constituency_name": "BAMUNANIKA COUNTY"
        },
        {
            "id": 352,
            "name": "ZIROBWE TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 1,
            "region_name": "Central",
            "sub_region_id": 1,
            "sub_region_name": "Buganda",
            "district_id": 23,
            "district_name": "LUWEERO",
            "county_id": 51,
            "county_name": "BAMUNANIKA County",
            "constituency_id": 64,
            "constituency_name": "BAMUNANIKA COUNTY"
        },
        {
            "id": 1507,
            "name": "ZOMBO TOWN COUNCIL",
            "type": "Town-Council",
            "region_id": 3,
            "region_name": "Northern",
            "sub_region_id": 11,
            "sub_region_name": "West Nile",
            "district_id": 87,
            "district_name": "ZOMBO",
            "county_id": 204,
            "county_name": "OKORO County",
            "constituency_id": 240,
            "constituency_name": "OKORO COUNTY"
        }
    ],
    types: [
        { id: 1, label: "Individual", value: "Individual" },
        { id: 2, label: "Entity", value: "Entity" }
    ],
    genders: [
        { id: 1, label: "Male", value: "Male" },
        { id: 2, label: "Female", value: "Female" }
    ],
    isc_codes: [
        { itemId: 1, sector: "Section A", title: "Agriculture, Forestry and Fishing",  divisions: [
            { id: 1, slug: 1, name: "Division 01", title: "Crop and Animal Production, Hunting and related service activities", group: [
                { id: 1, groupCode: "011", groupTitle: "Growing of non-perennial Crops", classes: [
                    { id: 1, code: "0111", label: "Growing of Cereals( Except Rice), Leguminous Crops and Oil Seeds" },
                    { id: 2, code: "0112", label: "Growing of Rice" },
                    { id: 3, code: "0113", label: "Growing of Vegetables and Melons, Roots and Tubers" },
                    { id: 4, code: "0114", label: "Growing of Sugarcane no for secondary" },
                    { id: 5, code: "0115", label: "Growing of tobacco" },
                    { id: 6, code: "0116", label: "Growing of fibre crops" },
                    { id: 7, code: "0117", label: "Growing of Other Non-Perennial Crops" }
                ] },
                { id: 2, groupCode: "012", groupTitle: "Growing of perennial crops", classes: [
                    { id: 1, code: "0121", label: "Growing of Grapes" },
                    { id: 2, code: "0122", label: "Growing of Tropical and Subtropical Fruits" },
                    { id: 3, code: "0123", label: "Growing of Citrus Fruits" },
                    { id: 4, code: "0124", label: "Growing of Pome Fruits and Stone Fruits" },
                    { id: 5, code: "0125", label: "Growing of other tree and bush fruits and nuts" },
                    { id: 6, code: "0126", label: "Growing of oleaginous fruits" },
                    { id: 7, code: "0127", label: "Growing of beverage crops" },
                    { id: 8, code: "0128", label: "Growing of spices, aromatic, drug and pharmaceutical crops" },
                    { id: 9, code: "0129", label: "Growing of other perennial crops" }
                ] },
                { id: 3, groupCode: "013", groupTitle: "Planting Propogation", classes: [
                    { id: 1, code: "0131", label: "N/A" }
                ] },
                { id: 4, groupCode: "014", groupTitle: "Animal Production", classes: [
                    { id: 2, code: "0142", label: "Raising of cattle and buffaloes" },
                    { id: 3, code: "0143", label: "Raising of horses and other equines" },
                    { id: 4, code: "0144", label: "Raising of camels and camelids" },
                    { id: 5, code: "0145", label: "Raising of sheep and goats" },
                    { id: 6, code: "0146", label: "Raising of swine/pigs" },
                    { id: 7, code: "0147", label: "Raising of Poultry" },
                    { id: 8, code: "0148", label: "Raising of other animals" }
                ] },
                { id: 5, groupCode: "015", groupTitle: "Mixed Farming", classes: [
                    { id: 2, code: "0152", label: "NA" }
                ] },
                { id: 6, groupCode: "016", groupTitle: "Support activities to agriculture and post-harvest crop activities", classes: [
                    { id: 2, code: "0162", label: "Support activities for crop production" },
                    { id: 3, code: "0163", label: "Support activities for animal production" },
                    { id: 4, code: "0164", label: "Post-harvest crop activities" },
                    { id: 5, code: "0165", label: "Seed processing for propagation" }
                ] },
                { id: 7, groupCode: "017", groupTitle: "Hunting, trapping and related service activities", classes: [
                    { id: 2, code: "0172", label: "NA" }
                ] }
            ] },
            { id: 2, slug: 2, name: "Division 02", title: "Forestry and Logging", group: [
                { id: 1, groupCode: "211", groupTitle: "Silviculture and other Forestry Activities", classes: [
                    { id: 1, code: "2111", label: "NA" },
                ] },
                { id: 2, groupCode: "212", groupTitle: "Logging", classes: [
                    { id: 1, code: "2121", label: "NA" },
                ] },
                { id: 3, groupCode: "213", groupTitle: "Gathering of non-woord forest products", classes: [
                    { id: 1, code: "2131", label: "N/A" }
                ] },
                { id: 4, groupCode: "214", groupTitle: "Support services to forestry", classes: [
                    { id: 1, code: "2142", label: "NA" }
                ] }
            ] },
            { id: 3, slug: 3, name: "Division 02", title: "Fishing and aquaculture", group: [
                { id: 1, groupCode: "311", groupTitle: "Fishing", classes: [
                    { id: 1, code: "3111", label: "Marine Fishing" },
                    { id: 2, code: "3112", label: "Freshwater fishing" }
                ] },
                { id: 2, groupCode: "312", groupTitle: "Aquaculture", classes: [
                    { id: 1, code: "3121", label: "Marine Aquaculture" },
                    { id: 2, code: "3122", label: "Freshwater aquaculture" }
                ] },
            ] },
        ] },
        { itemId: 2, sector: "Section B", title: "Mining and Quarrying",  divisions: [
            { id: 1, slug: 5, name: "Division 05", title: "Mining of Coal and Lignite", group: [
                { id: 1, groupCode: "511", groupTitle: "Mining of hard coal", classes: [
                    { id: 1, code: "5111", label: "NA" },
                ] },
                { id: 2, groupCode: "521", groupTitle: "Mining of Ignite", classes: [
                    { id: 1, code: "5211", label: "NA" },
                ] },
                ]  },
            { id: 2, slug: 6, name: "Division 06", title: "Extraction of Crude Petroleum and Natural Gas", group: [
                { id: 1, groupCode: "611", groupTitle: "Extraction of crude petroleum", classes: [
                    { id: 1, code: "6111", label: "NA" },
                ] },
                { id: 2, groupCode: "621", groupTitle: "Extraction of natural gas", classes: [
                    { id: 1, code: "6211", label: "NA" },
                ] },
                ] },
                { id: 3, slug: 7, name: "Division 07", title: "Mining of Metal Ores", group: [
                { id: 1, groupCode: "711", groupTitle: "Mining of Iron Ores", classes: [
                    { id: 1, code: "7111", label: "NA" },
                ] },
                { id: 2, groupCode: "721", groupTitle: "Mining of non-ferrous metal ores", classes: [
                    { id: 1, code: "7211", label: "Mining of Uranium and thorium ores" },
                    { id: 2, code: "7212", label: "Mining of other non-ferrous metal ores" }
                ] },
                ] },
            { id: 4, slug: 8, name: "Division 08", title: "Other mining and quarrying", group: [
                { id: 1, groupCode: "811", groupTitle: "Quarrying of stone, sand and clay", classes: [
                    { id: 1, code: "8111", label: "NA" },
                ] },
                { id: 2, groupCode: "821", groupTitle: "Mining and quarrying", classes: [
                    { id: 1, code: "8211", label: "Mining of Chemical and Fertilizer Minerals" },
                    { id: 2, code: "8212", label: "Extraction of Peat" },
                    { id: 3, code: "8213", label: "Extraction of Salt" },
                    { id: 4, code: "8214", label: "Other mining and quarrying" },
                ] },
                ] },
            { id: 5, slug: 9, name: "Division 09", title: "Mining support service activities", group: [
                { id: 1, groupCode: "911", groupTitle: "Support activities for petroleum and natural gas extraction", classes: [
                    { id: 1, code: "9111", label: "NA" },
                ] },
                { id: 2, groupCode: "921", groupTitle: "Support activities for other mining and quarrying", classes: [
                    { id: 1, code: "9211", label: "NA" },
                ] },
                ] }
        ] },
        { itemId: 3, sector: "Section C", title: "Manufacturing",  divisions: [

            { id: 10, slug: 10, name: "Division 05", title: "Manufacture of food products", group: [
                { id: 1, groupCode: "1011", groupTitle: "Processing and preserving of meat", classes: [
                    { id: 1, code: "10111", label: "NA" },
                ] },
                { id: 2, groupCode: "1021", groupTitle: "Processing and Preserving of Fish, crustaceans and mollusks", classes: [
                    { id: 1, code: "10211", label: "NA" },
                ] },
                { id: 3, groupCode: "1031", groupTitle: "Processing and Preserving of Fruit and Vegetables", classes: [
                    { id: 1, code: "10311", label: "NA" },
                ] },
                { id: 4, groupCode: "1041", groupTitle: "Manufacture of Vegetable and Animal Oils and Fats", classes: [
                    { id: 1, code: "10411", label: "NA" },
                ] },
                { id: 5, groupCode: "1051", groupTitle: "Manufacture of dairy products", classes: [
                    { id: 1, code: "10511", label: "NA" },
                ] },
                { id: 6, groupCode: "1061", groupTitle: "Manufacture of grain mill products, starches and starch products", classes: [
                    { id: 1, code: "10611", label: "Manufacture of grain mill products" },
                    { id: 2, code: "10612", label: "Manufacture of  starches and starch products" },
                ] },
                { id: 7, groupCode: "1071", groupTitle: "Manufacture of other products", classes: [
                    { id: 1, code: "10711", label: "Manufacture of bakery products" },
                    { id: 2, code: "10712", label: "Manufacture of sugar" },
                    { id: 3, code: "10713", label: "Manufacture of of cocoa, chocolate and sugar confectionery" },
                    { id: 4, code: "10714", label: "Manufacture of macaroni, noodles, couscous and similar farinaceous products" },
                    { id: 5, code: "10715", label: "Manufacture of prepared meals and dishes" },
                    { id: 6, code: "10716", label: "Manufacture of other food products" }
                ] },
                { id: 8, groupCode: "1081", groupTitle: "Manufacture of prepared animal feeds", classes: [
                    { id: 1, code: "10811", label: "NA" },
                ] },
                ]  },

                { id: 11, slug: 11, name: "Division 05", title: "Manufacture of beverages", group: [
                    { id: 1, groupCode: "1111", groupTitle: "Distilling, rectifying and blending of spirits", classes: [
                        { id: 1, code: "5111", label: "NA" },
                    ] },
                    { id: 2, groupCode: "1121", groupTitle: "Manufacture of wines", classes: [
                        { id: 1, code: "11211", label: "NA" },
                    ] },
                    { id: 3, groupCode: "1131", groupTitle: "Manufacture of malt liquors and malt", classes: [
                        { id: 1, code: "11311", label: "NA" },
                    ] },
                    { id: 4, groupCode: "1141", groupTitle: "Manufacture of soft drinks; production of mineral waters and other bottled waters", classes: [
                        { id: 1, code: "10411", label: "NA" },
                    ] },
                    ]  
                },

                { id: 12, slug: 12, name: "Division 05", title: "Manufacture of tobbacco products", group: [
                    { id: 1, groupCode: "1211", groupTitle: "Manufacture of tobacco products", classes: [
                        { id: 1, code: "12111", label: "NA" },
                    ] },
                    ]  
                },

                { id: 13, slug: 13, name: "Division 05", title: "Manufacture of textiles", group: [
                    { id: 1, groupCode: "1311", groupTitle: "Spinning, weaving and finishing of textiles", classes: [
                        { id: 1, code: "13111", label: "Preparation and spinning of textile fibres" },
                        { id: 2, code: "13112", label: "Weaving of textiles" },
                        { id: 3, code: "13113", label: "Finishing of textiles" },
                     ] 
                    },

                    { id: 2, groupCode: "1312", groupTitle: "Manufacture of other textiles", classes: [
                        { id: 1, code: "13121", label: "Manufacture of knitted and crocheted fabrics" },
                        { id: 2, code: "13122", label: "Manufacture of made-up textile articles, except apparel" },
                        { id: 3, code: "13123", label: "Manufacture of carpets and rugs" },
                        { id: 4, code: "13124", label: "Manufacture of cordage, rople, twine and netting" },
                        { id: 5, code: "13125", label: "Manufacture of other textiles" }
                     ] 
                    },
                 ]  
                },

                { id: 14, slug: 14, name: "Division 05", title: "Manufacture of wearing apparel", group: [
                    { id: 1, groupCode: "1411", groupTitle: "manufacture of wearing apparel, except fur apparel", classes: [
                        { id: 1, code: "14111", label: "NA" },
                     ] 
                    },

                    { id: 2, groupCode: "1412", groupTitle: "Manufacture of articles of fur", classes: [
                        { id: 1, code: "14121", label: "NA" },
                     ] 
                    },

                    { id: 3, groupCode: "1413", groupTitle: "Manufacture of knitted and crocheted apparel", classes: [
                        { id: 1, code: "14131", label: "NA" },
                     ] 
                    },
                 ]  
                },


                { id: 15, slug: 15, name: "Division 05", title: "Manufacture of leather and related products", group: [
                    { id: 1, groupCode: "1511", groupTitle: "Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur", classes: [
                        { id: 1, code: "15111", label: "Tanning and dressing of leather; dressing and dyeing of fur" },
                        { id: 2, code: "15112", label: "Manufacture of luggage, handbags and the like, saddlery and harness" },
                     ] 
                    },

                    { id: 2, groupCode: "1512", groupTitle: "Manufacture of footwear", classes: [
                        { id: 1, code: "15121", label: "NA" },
                     ] 
                    },
                 ]  
                },

               { id: 16, slug: 16, name: "Division 05", title: "Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials", group: [
                    { id: 1, groupCode: "1611", groupTitle: "Sawmilling and planning of wood", classes: [
                        { id: 1, code: "16111", label: "NA" },] 
                    },
                    { id: 2, groupCode: "1612", groupTitle: "Manufacture of products of wood, cork, straw and plaiting materials", classes: [
                        { id: 1, code: "16121", label: "Manufacture of veneer sheets and wood-based panels" },
                        { id: 2, code: "16122", label: "Manufacture of builders carpentry and joinery" },
                        { id: 3, code: "16123", label: "Manufacture of wooden containers" },
                        { id: 4, code: "16124", label: "Manufacture of other products of wood, manufacture of articles of cork, straw and plaiting materials" },
                     ] 
                    },
                 ]  
                },

                { id: 17, slug: 17, name: "Division 05", title: "Manufacture of paper and paper products", group: [
                    { id: 1, groupCode: "1711", groupTitle: "Manufacture of pulp, paper and paperboard", classes: [
                        { id: 1, code: "17111", label: "NA" },
                     ] 
                    },
                    { id: 2, groupCode: "1712", groupTitle: "Manufacture of corrugated paper and paperboard and containers of paper and paper board", classes: [
                        { id: 1, code: "17121", label: "NA" },
                     ] 
                    },

                    { id: 3, groupCode: "1713", groupTitle: "Manufacture of other articles of paper and paperboard", classes: [
                        { id: 1, code: "17131", label: "NA" },
                     ] 
                    },
                 ]  
                },

                { id: 18, slug: 18, name: "Division 05", title: "Printing and reproduction of recorded media", group: [
                    { id: 1, groupCode: "1811", groupTitle: "Printing and service activities related to printing", classes: [
                        { id: 1, code: "18111", label: "Service activities related to printing" },
                     ] 
                    },
                    { id: 2, groupCode: "1812", groupTitle: "Reproduction of recorded media", classes: [
                        { id: 1, code: "18121", label: "NA" },
                     ] 
                    },
                 ]  
                },

                { id: 19, slug: 19, name: "Division 05", title: "Manufacture of coke and refined petroleum products", group: [
                    { id: 1, groupCode: "1911", groupTitle: "Manufacture of coke oven products", classes: [
                        { id: 1, code: "19111", label: "NA" },
                     ] 
                    },
                    { id: 2, groupCode: "1912", groupTitle: "Manufacture of refined petroleum products", classes: [
                        { id: 2, code: "19121", label: "NA" },
                     ] 
                    },
                 ]  
                },

                { id: 20, slug: 20, name: "Division 05", title: "Manufacture of chemicals and chemical products", group: [
                    { id: 1, groupCode: "2011", groupTitle: "Manufacture of basic chemicals, fertilizers and nitrogen compounds , plastics and synthetic rubber in primary forms", classes: [
                        { id: 1, code: "20111", label: "Manufacture of basic chemicals" },
                        { id: 2, code: "20112", label: "Manufacture of fertilizers and nitrogen compounds" },
                        { id: 3, code: "20113", label: "Manufacture of plastics and synthetic rubber in primary forms" },
                     ] 
                    },
                    { id: 2, groupCode: "2012", groupTitle: "Manufacture of other chemical products", classes: [
                        { id: 1, code: "20121", label: "Manufacture of pesticides and other agrochemical products" },
                        { id: 2, code: "20122", label: "Manufacture of paints, varnishes and similar coatings, printing ink and mastics" },
                        { id: 3, code: "20123", label: "Manufacture of soap and detergents, cleaning and polishing, preparations, perfumes and toilet preparations" },
                     ] 
                    },
                    { id: 3, groupCode: "2013", groupTitle: "Manufacture of man-made fibres", classes: [
                        { id: 1, code: "20131", label: "NA" },
                     ] 
                    },
                 ]  
                },

                { id: 21, slug: 21, name: "Division 05", title: "Manufacture of pharmaceuticals, medicinal chemical and botanical products", group: [
                    { id: 1, groupCode: "2111", groupTitle: "Manufacture of pharmaceuticals, medicinal chemical and botanical products", classes: [
                        { id: 1, code: "21111", label: "NA" },
                     ] 
                    },
                 ]  
                },

                { id: 22, slug: 22, name: "Division 05", title: "Manufacture of rubber and plastic products", group: [
                    { id: 1, groupCode: "2212", groupTitle: "Manufacture of rubber products", classes: [
                        { id: 1, code: "22121", label: "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres" },
                        { id: 2, code: "22122", label: "Manufacture of other rubber products" }
                     ] 
                    },
                    { id: 3, groupCode: "2113", groupTitle: "Manufacture of plastic products", classes: [
                        { id: 1, code: "21113", label: "NA" },
                     ] 
                    },
                 ]  
                },

                { id: 23, slug: 23, name: "Division 05", title: "Manufacture of other non-metallic mineral products", group: [
                    { id: 1, groupCode: "2311", groupTitle: "Manufacture of non-metallic mineral products", classes: [
                        { id: 1, code: "23111", label: "Manufacture of refractory products" },
                        { id: 2, code: "23112", label: "Manufacture of clay building materials" },
                        { id: 3, code: "23113", label: "Manufacture of other porcelain and ceramic products" },
                        { id: 4, code: "23114", label: "Manufacture of cement, lime and plaster" },
                        { id: 5, code: "23115", label: "Manufacture of articles of concrete, cement and plaster" },
                        { id: 6, code: "23116", label: "Cutting, shaping and finishing of stone" },
                        { id: 7, code: "23117", label: "Manufacture of other non-metallic mineral products" }
                     ] 
                    },
                 ]  
                },

                { id: 24, slug: 24, name: "Division 05", title: "Manufacture of basic metals", group: [
                    { id: 1, groupCode: "2411", groupTitle: "Manufacture of basic precious and other non-ferrous metals", classes: [
                        { id: 1, code: "2411", label: "NA" },
                     ] 
                    },
                    { id: 2, groupCode: "2412", groupTitle: "Manufacture of basic iron and steel", classes: [
                        { id: 1, code: "24121", label: "NA" },
                     ] 
                    },
                    { id: 3, groupCode: "2413", groupTitle: "Casting of metals", classes: [
                        { id: 1, code: "24131", label: "Casting of iron and steel" },
                        { id: 2, code: "24132", label: "Casting of non-ferrous metals" }
                     ] 
                    },
                 ]  
                },

                { id: 25, slug: 25, name: "Division 05", title: "Manufacture of fabricated metal products, except machinery and equipment", group: [
                    { id: 1, groupCode: "2511", groupTitle: "Manufacture of structural metal products, tanks, reservoirs and steam generators", classes: [
                        { id: 1, code: "25111", label: "Manufacture of structural metal products" },
                        { id: 2, code: "25112", label: "Manufacture of tanks, reservoirs and containers of metal" },
                        { id: 3, code: "25113", label: "Manufacture of steam generators, except central heating hot water boilers" }
                     ] 
                    },
                    { id: 2, groupCode: "2512", groupTitle: "Manufacture of weapons and ammunition", classes: [
                        { id: 1, code: "25121", label: "NA" },
                     ] 
                    },
                    { id: 3, groupCode: "2513", groupTitle: "Manufacture of other fabricated metal products; metalworking service activities", classes: [
                        { id: 1, code: "25131", label: "Forging, pressing, stamping and roll-forming of metal; powder metallurgy" },
                        { id: 2, code: "25132", label: "Treatment and coating of metals; machining" },
                        { id: 3, code: "25133", label: "Manufacture of cutlery, hand tools and general hardware" },
                        { id: 4, code: "25134", label: "Manufacture of other fabricated metal products" }
                     ] 
                    },
                 ]  
                },


                { id: 26, slug: 26, name: "Division 05", title: "Manufacture of computer, electronic and optical products", group: [
                    { id: 1, groupCode: "2611", groupTitle: "Manufacture of electronic components and boards", classes: [
                        { id: 1, code: "26111", label: "NA" },
                     ] 
                    },
                    { id: 2, groupCode: "2612", groupTitle: "Manufacture of computers and peripheral equipment", classes: [
                        { id: 1, code: "26121", label: "NA" },
                     ] 
                    },
                    { id: 3, groupCode: "2613", groupTitle: "Manufacture of communication equipment", classes: [
                        { id: 1, code: "26131", label: "NA" },
                     ] 
                    },
                    { id: 4, groupCode: "2614", groupTitle: "Manufacture of consumer electronics", classes: [
                        { id: 1, code: "26141", label: "NA" },
                     ] 
                    },
                    { id: 6, groupCode: "2616", groupTitle: "Manufacture of measuring, testing, navigating and control equipment; watches and clocks", classes: [
                        { id: 1, code: "26161", label: "Manufacture of measuring, testing, navigating and control equipment" },
                        { id: 2, code: "26161", label: "Manufacture of watches and clocks" }
                     ] 
                    },
                    { id: 5, groupCode: "2615", groupTitle: "Manufacture of irradiation, electromedical and electrotherapeutic equipment", classes: [
                        { id: 1, code: "26151", label: "NA" },
                     ] 
                    },
                    { id: 7, groupCode: "2617", groupTitle: "Manufacture of optical instruments and photographic equipment", classes: [
                        { id: 1, code: "26171", label: "NA" },
                     ] 
                    },
                    { id: 8, groupCode: "2618", groupTitle: "Manufacture of magnetic and optical media", classes: [
                        { id: 1, code: "26151", label: "NA" },
                     ] 
                    },
                 ]  
                },


                { id: 27, slug: 27, name: "Division 07", title: "Manufacture of electrical equipment", group: [
                    { id: 1, groupCode: "2711", groupTitle: "Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus", classes: [
                        { id: 1, code: "27111", label: "NA" },
                     ] 
                    },
                    { id: 2, groupCode: "2712", groupTitle: "Manufacture of batteries and accumulators", classes: [
                        { id: 1, code: "27121", label: "NA" },
                     ] 
                    },
                    { id: 3, groupCode: "2713", groupTitle: "Manufacture of wiring and wiring devices", classes: [
                        { id: 1, code: "27131", label: "Manufacture of fibre optic cables" },
                        { id: 2, code: "27132", label: "Manufacture of other electronic and electric wires and cables " },
                        { id: 3, code: "27133", label: "Manufacture of wiring devices" }
                     ] 
                    },
                    { id: 4, groupCode: "2714", groupTitle: "Manufacture of electric lighting equipment", classes: [
                        { id: 1, code: "27141", label: "NA" },
                     ] 
                    },
                    { id: 5, groupCode: "2715", groupTitle: "Manufacture of domestic appliances", classes: [
                        { id: 1, code: "27151", label: "NA" },
                     ] 
                    },
                    { id: 6, groupCode: "2716", groupTitle: "Manufacture of other electric equipment", classes: [
                        { id: 1, code: "27161", label: "NA" },
                     ] 
                    },
                 ]  
                },


                { id: 28, slug: 28, name: "Division 07", title: "Manufacture of machinery and equipment", group: [
                    { id: 1, groupCode: "2811", groupTitle: "Manufacture of general-purpose machinery", classes: [
                        { id: 1, code: "28131", label: "Manufacture of other pumps, compressors, taps and valves" },
                        { id: 2, code: "28132", label: "Manufacture of bearings,gears, gearing and driving elements" },
                        { id: 3, code: "28133", label: "Manufacture of ovens, furnances and furnance burners" },
                        { id: 4, code: "28134", label: "Manufacture of lifting and handling equipment" },
                        { id: 5, code: "28135", label: "Manufacture of office machinery and equipment( except computers and peripheral equipment)" },
                        { id: 6, code: "28136", label: "Manufacture of power-driven hand tools" },
                        { id: 7, code: "28137", label: "Manufacture of other general-purpose machinery" }
                     ] 
                    },
                    { id: 2, groupCode: "2812", groupTitle: "Manufacture of special-purpose machinery", classes: [
                        { id: 1, code: "28121", label: "Manufacture of agricultural and forestry machinery" },
                        { id: 2, code: "28122", label: "Manufacture of metal-forming machinery and machine tools" },
                        { id: 3, code: "28123", label: "Manufacture of machinery for metallurgy" },
                        { id: 4, code: "28124", label: "Manufacture of machinery for food, beverage and tobbacoo processing" },
                        { id: 5, code: "28125", label: "Manufacture of machinery for mining, quarring and construction" },
                        { id: 6, code: "28126", label: "Manufacture of machinery for textile, apparel and leather production" }
                     ] 
                    },
                 ]  
                },


                { id: 29, slug: 29, name: "Division 07", title: "Manufacture of motor vehicles, trailers and semi-trailers", group: [
                    { id: 1, groupCode: "2911", groupTitle: "Manufacture of motor vehicles", classes: [
                        { id: 1, code: "29111", label: "N/A" },
                     ] 
                    },
                    { id: 2, groupCode: "2912", groupTitle: "Manufacture of bodies(coachwork) for motor vehicles; manufacture of trailers and semi-trailers", classes: [
                        { id: 1, code: "29121", label: "N/A" },
                     ] 
                    },
                    { id: 3, groupCode: "2913", groupTitle: "Manufacture of motor vehicles", classes: [
                        { id: 1, code: "29131", label: "N/A" },
                     ] 
                    },
                 ]  
                },


                { id: 30, slug: 30, name: "Division 07", title: "Manufacture of other transport equipment", group: [
                    { id: 1, groupCode: "3011", groupTitle: "Building of ships and boats", classes: [
                        { id: 1, code: "30111", label: "Building of ships and floating structures" },
                        { id: 2, code: "30112", label: "Building of pleasure and sporting boats" }
                     ] 
                    },
                    { id: 2, groupCode: "3012", groupTitle: "Manufacture of railway locomotives and rolling stock", classes: [
                        { id: 1, code: "30121", label: "N/A" },
                     ] 
                    },
                    { id: 3, groupCode: "3013", groupTitle: "Manufacture of air and spacecraft and related machinery", classes: [
                        { id: 1, code: "30131", label: "N/A" },
                     ] 
                    },
                    { id: 4, groupCode: "3014", groupTitle: "Manufacture of military fighting vehicles", classes: [
                        { id: 1, code: "30141", label: "N/A" },
                     ] 
                    },
                    { id: 5, groupCode: "3015", groupTitle: "Manufacture of transport equipment", classes: [
                        { id: 1, code: "30151", label: "Manufacture of motorcyles" },
                        { id: 2, code: "30152", label: "Manufacture of bicycles and invalid carriages" },
                        { id: 3, code: "30153", label: "Manufacture of other transport and equipment" }
                     ] 
                    },
                 ]  
                },


                { id: 31, slug: 31, name: "Division 07", title: "Manufacture of furniture", group: [
                    { id: 1, groupCode: "3111", groupTitle: "Manufacture of furniture", classes: [
                        { id: 1, code: "31111", label: "N/A" },
                     ] 
                    },
                 ]  
                },

                { id: 32, slug: 32, name: "Division 07", title: "Other manufacturing", group: [
                    { id: 1, groupCode: "3211", groupTitle: "Manufacture of musical instruments", classes: [
                        { id: 1, code: "32111", label: "N/A" },
                     ] 
                    },

                    { id: 2, groupCode: "3212", groupTitle: "Manufacture of sports goods", classes: [
                        { id: 1, code: "32121", label: "N/A" },
                     ] 
                    },

                    { id: 3, groupCode: "3213", groupTitle: "Manufacture of games and toys", classes: [
                        { id: 1, code: "32131", label: "N/A" },
                     ] 
                    },
                    { id: 4, groupCode: "3214", groupTitle: "Manufacture of medical and dental instruments and supplies", classes: [
                        { id: 1, code: "32141", label: "N/A" },
                     ] 
                    },
                    { id: 5, groupCode: "3215", groupTitle: "Other manufacturing", classes: [
                        { id: 1, code: "32151", label: "N/A" },
                     ] 
                    },
                 ]  
                },


                { id: 33, slug: 33, name: "Division 07", title: "Repair and installation of machinery and equipment", group: [
                    { id: 1, groupCode: "3311", groupTitle: "Repair of fabricated metal products, machinery and equipment", classes: [
                        { id: 1, code: "33111", label: "Repair of fabricated metal products" },
                        { id: 2, code: "33112", label: "Repair of machinery" },
                        { id: 3, code: "33113", label: "Repair of electronic and optical equipment" },
                        { id: 4, code: "33114", label: "Repair of electrical equipment" },
                        { id: 5, code: "33115", label: "Repair of transport equipment, except motor vehicles" },
                        { id: 6, code: "33116", label: "Repair of other equipment" },
                     ] 
                    },

                    { id: 2, groupCode: "3212", groupTitle: "Installation of industrial machinery and equipment", classes: [
                        { id: 1, code: "32121", label: "N/A" },
                     ] 
                    },
                 ]  
                },
        ] },
        { itemId: 4, sector: "Section D", title: "Electricity, Gas, Steam and Air Conditioning Supply",  divisions: [

            { id: 40, slug: 40, name: "Division 07", title: "Electricity, gas,steam and air conditioning supply", group: [
                { id: 1, groupCode: "4011", groupTitle: "Electricity, gas, steam and air conditioning supply", classes: [
                    { id: 1, code: "40111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "4112", groupTitle: "Electric power generation, transmission and distribution", classes: [
                    { id: 1, code: "41121", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "4212", groupTitle: "Manufacture of gas; distribution of gaseous fuels through mains", classes: [
                    { id: 1, code: "42121", label: "N/A" },
                 ] 
                },

                { id: 3, groupCode: "4312", groupTitle: "Steam and air conditioning supply, if only import and supply", classes: [
                    { id: 1, code: "43121", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 41, slug: 41, name: "Division 07", title: "Water collection, treatment and supply", group: [
                { id: 1, groupCode: "4411", groupTitle: "Water collection, treatment and supply", classes: [
                    { id: 1, code: "44111", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 42, slug: 42, name: "Division 07", title: "Sewerage", group: [
                { id: 1, groupCode: "4511", groupTitle: "Sewerage", classes: [
                    { id: 1, code: "45111", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 43, slug: 43, name: "Division 07", title: "Waste collection, treatment and disposal activities; materials recovery", group: [
                { id: 1, groupCode: "4611", groupTitle: "Waste collection", classes: [
                    { id: 1, code: "46111", label: "Collection of non hazardous waste" },
                    { id: 2, code: "46112", label: "Collection of hazardous waste" }
                 ] 
                },

                { id: 2, groupCode: "4711", groupTitle: "Waste treatment and disposal", classes: [
                    { id: 1, code: "47111", label: "Treatment and disposal of non hazardous waste" },
                    { id: 2, code: "47112", label: "Treatment and disposal of hazardous waste" }
                 ] 
                },

                { id: 3, groupCode: "4811", groupTitle: "Materials recovery", classes: [
                    { id: 1, code: "48111", label: "N/A" }
                 ] 
                },
             ]  
            },

            { id: 44, slug: 44, name: "Division 07", title: "Remediation activities and other waste management services", group: [
                { id: 1, groupCode: "4911", groupTitle: "Remediation activities and other waste management services", classes: [
                    { id: 1, code: "49111", label: "N/A" }
                 ] 
                },
             ]  
            },
        ] },
        { itemId: 5, sector: "Section E", title: "Construction",  divisions: [

            { id: 50, slug: 50, name: "Division 07", title: "Construction of buildings", group: [
                { id: 1, groupCode: "5011", groupTitle: "Construction of buildings", classes: [
                    { id: 1, code: "50111", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 51, slug: 51, name: "Division 07", title: "Civil Engineering", group: [
                { id: 1, groupCode: "5111", groupTitle: "Construction of roads and railways", classes: [
                    { id: 1, code: "51111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "5112", groupTitle: "Construction of utility projects", classes: [
                    { id: 1, code: "51121", label: "N/A" },
                 ] 
                },

                { id: 3, groupCode: "5113", groupTitle: "Construction of other civil engineeering projects", classes: [
                    { id: 1, code: "51131", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 52, slug: 52, name: "Division 07", title: "Specialized constructuion activities", group: [
                { id: 1, groupCode: "5211", groupTitle: "Demolition and site preparation", classes: [
                    { id: 1, code: "52111", label: "Demolition" },
                    { id: 2, code: "52111", label: "Site preparation" }
                 ] 
                },

                { id: 2, groupCode: "5212", groupTitle: "Electrical, plumbing and other construction installation activities", classes: [
                    { id: 1, code: "52121", label: "Electrical installation" },
                    { id: 1, code: "52121", label: "Plumbing,heat and air-conditioning installation" },
                    { id: 1, code: "52121", label: "Other construction installtion" },
                 ] 
                },

                { id: 3, groupCode: "5213", groupTitle: "Building completion and finishing", classes: [
                    { id: 1, code: "52131", label: "N/A" },
                 ] 
                },

                { id: 3, groupCode: "5213", groupTitle: "Other specialized construcction activities", classes: [
                    { id: 1, code: "52131", label: "N/A" },
                 ] 
                },
             ]  
            }

        ] },
        { itemId: 6, sector: "Section F", title: "Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles", divisions: [

            { id: 60, slug: 60, name: "Division 07", title: "Wholesale trade, except of motor vehicles and motorcycles", group: [
                { id: 1, groupCode: "6011", groupTitle: "Wholesale on a fee or contract basis", classes: [
                    { id: 1, code: "60111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "6021", groupTitle: "Wholesale of agricultural raw materials and live animals", classes: [
                    { id: 1, code: "60211", label: "N/A" },
                 ] 
                },

                { id: 3, groupCode: "6031", groupTitle: "Wholesale of foods, beverages and tobacco", classes: [
                    { id: 1, code: "60311", label: "N/A" },
                 ] 
                },

                { id: 4, groupCode: "6041", groupTitle: "Wholesale of household goods", classes: [
                    { id: 1, code: "60411", label: "Wholesale of textiles, clothing and footwear" },
                    { id: 2, code: "60412", label: "Wholesale of other household goods" },
                 ] 
                },
                { id: 5, groupCode: "6051", groupTitle: "Wholesale of machinery, equipment and supplies", classes: [
                    { id: 1, code: "60511", label: "Wholesale of computers, computer peripheral equipment and software" },
                    { id: 1, code: "60512", label: "Wholesale of electronic and telecommunications equipment and parts" },
                    { id: 1, code: "60513", label: "Wholesale of agricultural machinery, equipment and supplies" },
                    { id: 1, code: "60514", label: "Wholesale of other machinery and equipment" }

                 ] 
                },
                { id: 6, groupCode: "6061", groupTitle: "Other specialized wholesale", classes: [
                    { id: 1, code: "60611", label: "Wholesale of construction materials,hardware, plumbing and heating equipment nad supplies" },
                    { id: 1, code: "60612", label: "Wholesale of waste and scrap and other products" },
                 ] 
                },
                { id: 7, groupCode: "6071", groupTitle: "Non-specialized wholesale trade", classes: [
                    { id: 1, code: "60711", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 61, slug: 61, name: "Division 07", title: "Retail trade, except of motor vehicles and motorcycles", group: [
                { id: 1, groupCode: "6111", groupTitle: "Retail sale in non-specialized stores", classes: [
                    { id: 1, code: "61111", label: "Retail sale in non-specialized stores with food, beverages or tobacco predominating" },
                    { id: 2, code: "61112", label: "Other retail sale in non-specialized stores" }
                 ] 
                },

                { id: 2, groupCode: "6112", groupTitle: "Retail sale of food, beverages and tobacco in specialized stores", classes: [
                    { id: 1, code: "61121", label: "Retail sale of food in specialized stores" },
                    { id: 2, code: "61122", label: "Retail sale of tobacco products in specialized stores" },
                 ] 
                },

                { id: 3, groupCode: "6113", groupTitle: "Retail sale of automotive fuel in specialized stores", classes: [
                    { id: 1, code: "61131", label: "N/A" },
                 ] 
                },
                { id: 4, groupCode: "6114", groupTitle: "Retail sale of information and communications equipment in specialized stores", classes: [
                    { id: 1, code: "61141", label: "Retail sale of computers, peripheral units, software and telecommunications and equipment in specialized stores" },
                    { id: 2, code: "61142", label: "Retail sale of audio and video equipment in specialized stores" }
                 ] 
                },
                { id: 5, groupCode: "6115", groupTitle: "Retail sale of other household equipment in specialized stores", classes: [
                    { id: 1, code: "61151", label: "Retail sale of hardware, paints and glass in specialized stores" },
                    { id: 2, code: "61152", label: "Retail sale of carpets, rugs, wall and floor coverings in specialized stores" },
                    { id: 3, code: "61153", label: "Retail sale of electrical household appliances, furniture, lighting equipment and other household articles in specialized stores" }
                 ] 
                },
                { id: 6, groupCode: "6116", groupTitle: "Retail sale of cultural and recreation goods in specialized stores", classes: [
                    { id: 1, code: "61161", label: "Retail sale of books, newspapers and stationary in specialized stores" },
                    { id: 2, code: "61162", label: "Retail sale of music and video recordings in specialized stores" },
                    { id: 3, code: "61163", label: "Retail sale of sporting equipment in specialized stores" },
                    { id: 4, code: "61164", label: "Retail sale of games and toys in specialized stores" }
                 ] 
                },
                { id: 7, groupCode: "6117", groupTitle: "Retail sale of other goods in specialized stores", classes: [
                    { id: 1, code: "61171", label: "Retail sale of clothing, footwear and leather articles in specialized stores" },
                    { id: 2, code: "61172", label: "Retail sale of pharmaceutical and medical goods, cosmetic and toilet articles in specialized stores" },
                    { id: 3, code: "61173", label: "Other retail sale of new goods in specialized stores" },
                    { id: 4, code: "61174", label: "Retail sale of second-hand goods" }
                 ] 
                },
                { id: 8, groupCode: "6118", groupTitle: "Retail sale via stalls and markets", classes: [
                    { id: 1, code: "61181", label: "Retail sale via stalls and markets of food, beverages and tobacco products" },
                    { id: 2, code: "61182", label: "Retail sale via stalls and markets of textiles, clothing and footwear" },
                    { id: 3, code: "61183", label: "Retail sale via stalls and markets of other goods" }
                 ] 
                },
                { id: 9, groupCode: "6119", groupTitle: "Retail trade not in stores, stalls or markets", classes: [
                    { id: 1, code: "61191", label: "Retail sale via mail order houses or via Internet" },
                    { id: 2, code: "61192", label: "Other retail sale not in stores, stalls or markets" }
                 ] 
                },
             ]  
            },
        ]  },
        { itemId: 7, sector: "Section G", title: "Transportation and storage",  divisions: [
            
            { id: 70, slug: 70, name: "Division 07", title: "Land transport and transport via pipelines", group: [
                { id: 1, groupCode: "7011", groupTitle: "Transport via railways", classes: [
                    { id: 1, code: "70111", label: "Passenger rail transport, inter-urban" },
                    { id: 2, code: "70112", label: "Freight rail transport" },
                 ] 
                },

                { id: 2, groupCode: "7021", groupTitle: "Other land transport", classes: [
                    { id: 1, code: "70211", label: "Urban and suburban passenger land transport" },
                    { id: 2, code: "70212", label: "Other passenger land transport" },
                    { id: 3, code: "70213", label: "Freight transport by road" },
                 ] 
                },

                { id: 3, groupCode: "7031", groupTitle: "Transport via pipeline", classes: [
                    { id: 1, code: "70311", label: "N/A" },
                 ] 
                }
             ]  
            },

            { id: 71, slug: 71, name: "Division 07", title: "Water transport", group: [
                { id: 1, groupCode: "7111", groupTitle: "Sea and coastal water transport", classes: [
                    { id: 1, code: "71111", label: "Sea and coastal passenger water transport" },
                    { id: 2, code: "71112", label: "Sea and coastal freight water transport" },
                 ] 
                },

                { id: 2, groupCode: "7121", groupTitle: "Inland water transport", classes: [
                    { id: 1, code: "71211", label: "Inland passenger water transport" },
                    { id: 2, code: "71212", label: "Inland freight water transport" },
                 ] 
                },
             ]  
            },

            { id: 71, slug: 71, name: "Division 07", title: "Air transport", group: [
                { id: 1, groupCode: "7111", groupTitle: "Passenger air transport", classes: [
                    { id: 1, code: "71111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "7021", groupTitle: "Freight air transport", classes: [
                    { id: 1, code: "71211", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 72, slug: 72, name: "Division 07", title: "Warehousing and support activities for transportation", group: [
                { id: 1, groupCode: "7211", groupTitle: "Warehousing and storage", classes: [
                    { id: 1, code: "71211", label: "N/A" },
                 ] 
                },
                { id: 2, groupCode: "7212", groupTitle: "Support activities for transportation", classes: [
                    { id: 1, code: "72121", label: "Service activities incidental to water transportation" },
                    { id: 2, code: "72122", label: "Service activities incidental to air transportation" },
                    { id: 3, code: "72123", label: "Cargo handling" },
                    { id: 4, code: "72124", label: "Other transportation support activities" }
                 ]
                },
             ]  
            },

            { id: 73, slug: 73, name: "Division 07", title: "Postal and courier activities", group: [
                { id: 1, groupCode: "7311", groupTitle: "Postal Activities", classes: [
                    { id: 1, code: "73111", label: "N/A" }
                 ]
                },

                { id: 2, groupCode: "7311", groupTitle: "Courier Activities", classes: [
                    { id: 1, code: "73111", label: "N/A" },
                 ]
                },
             ]  
            },
        ] },
        { itemId: 8, sector: "Section H", title: "Accomodation and food service activities", divisions: [
            
            { id: 80, slug: 80, name: "Division 07", title: "Accomodation", group: [
                { id: 1, groupCode: "8011", groupTitle: "Short term accommodation activities", classes: [
                    { id: 1, code: "80111", label: "N/A" },
                 ] 
                },
                { id: 2, groupCode: "8012", groupTitle: "Camping grounds, recreational vehicle parks and trailer parks", classes: [
                    { id: 1, code: "80121", label: "N/A" },
                 ] 
                },
                { id: 3, groupCode: "8013", groupTitle: "Other accommodation", classes: [
                    { id: 1, code: "80131", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 81, slug: 81, name: "Division 07", title: "Food and beverage service activities", group: [
                { id: 1, groupCode: "8111", groupTitle: "Restaurants and mobile food service activities", classes: [
                    { id: 1, code: "81111", label: "N/A" },
                 ] 
                },
                { id: 2, groupCode: "8112", groupTitle: "Event catering and other food service activities", classes: [
                    { id: 1, code: "81121", label: "Event catering" },
                    { id: 2, code: "81122", label: "Other food service activities" },
                 ] 
                },
                { id: 3, groupCode: "8113", groupTitle: "Beverage serving activities", classes: [
                    { id: 1, code: "81131", label: "N/A" },
                 ] 
                },
             ]  
            },

        ] },
        { itemId: 9, sector: "Section I", title: "Information and communication",  divisions: [
            
            { id: 90, slug: 90, name: "Division 07", title: "Publishing activities", group: [
                { id: 1, groupCode: "9011", groupTitle: "Publishing of books, periodicals and other publishing activities", classes: [
                    { id: 1, code: "90111", label: "Book publishing" },
                    { id: 2, code: "90112", label: "Publishing of directories and mailing lists" },
                    { id: 3, code: "90113", label: "Publishing of newspapers, journals and periodicals" },
                    { id: 4, code: "90114", label: "Other publishing activities" }
                 ] 
                },
                { id: 2, groupCode: "9012", groupTitle: "Software publishing", classes: [
                    { id: 1, code: "90121", label: "N/A" },
                 ] 
                }
             ]  
            },

            { id: 91, slug: 91, name: "Division 07", title: "Motion picture, video and television programme production, sound recording and music publishing activities", group: [
                
                { id: 1, groupCode: "9111", groupTitle: "Motion picture, video and television programme activities", classes: [
                    { id: 1, code: "91111", label: "Motion picture, video and television programme distribution activities" },
                    { id: 2, code: "91112", label: "Motion picture projection activities" },
                    { id: 3, code: "91113", label: "Motion picture, video and television programme production activities" },
                    { id: 4, code: "91114", label: "Motion picture, video and television programme post-production activities" }
                 ] 
                },
                { id: 2, groupCode: "9112", groupTitle: "Sound recording and music publishing activities", classes: [
                    { id: 1, code: "91121", label: "N/A" },
                 ] 
                }
             ]  
            },

            { id: 92, slug: 92, name: "Division 07", title: "Programming and broadcasting activities", group: [
                
                { id: 1, groupCode: "9211", groupTitle: "Radio broadcasting", classes: [
                    { id: 1, code: "92111", label: "N/A" },
                 ] 
                },
                { id: 2, groupCode: "9212", groupTitle: "Television programming and broadcasting activities", classes: [
                    { id: 1, code: "92121", label: "N/A" },
                 ] 
                }
             ]  
            },

            { id: 93, slug: 93, name: "Division 07", title: "Telecommunications", group: [
                
                { id: 1, groupCode: "9311", groupTitle: "Wired telecommunications activities", classes: [
                    { id: 1, code: "93111", label: "N/A" },
                 ] 
                },
                { id: 2, groupCode: "9312", groupTitle: "Wireless telecommunications activities", classes: [
                    { id: 1, code: "93121", label: "N/A" },
                 ] 
                },
                { id: 2, groupCode: "9312", groupTitle: "Satellite telecommunications activities", classes: [
                    { id: 1, code: "93121", label: "N/A" },
                 ] 
                },
                { id: 2, groupCode: "9312", groupTitle: "Other telecommunications activities", classes: [
                    { id: 1, code: "93121", label: "N/A" },
                 ] 
                }
             ]  
            },

            { id: 94, slug: 94, name: "Division 07", title: "Computer programming, consultancy and related activities", group: [
                
                { id: 1, groupCode: "9411", groupTitle: "Computer programming activities", classes: [
                    { id: 1, code: "94111", label: "N/A" },
                 ] 
                },
                { id: 2, groupCode: "9412", groupTitle: "Computer consultancy and computer facilities management activities", classes: [
                    { id: 1, code: "94121", label: "N/A" },
                 ] 
                },
                { id: 3, groupCode: "9413", groupTitle: "Other information technology and computer service activities", classes: [
                    { id: 1, code: "94131", label: "N/A" },
                 ] 
                },
                { id: 4, groupCode: "9414", groupTitle: "Information service activities", classes: [
                    { id: 1, code: "94141", label: "N/A" },
                 ] 
                },
                { id: 5, groupCode: "9415", groupTitle: "Data processing, hosting and related activities; web portals", classes: [
                    { id: 1, code: "94151", label: "Data processing, hosting and related activities" },
                    { id: 2, code: "94152", label: "Web portals" },
                 ] 
                },
                { id: 6, groupCode: "9416", groupTitle: "Other information service activities", classes: [
                    { id: 1, code: "94161", label: "News agency activities" },
                    { id: 2, code: "94162", label: "Other information service activities" },
                 ] 
                }
             ]  
            },
        ] },



        { itemId: 10, sector: "Section J", title: "Financial and Insurance activities",  divisions: [
            
            { id: 95, slug: 95, name: "Division 07", title: "Financial service activities, except insurance and pension funding", group: [
                { id: 1, groupCode: "9511", groupTitle: "Monetary intermediation", classes: [
                    { id: 1, code: "95111", label: "Book publishing" },
                    { id: 2, code: "95112", label: "Publishing of directories and mailing lists" },
                    { id: 3, code: "95113", label: "Publishing of newspapers, journals and periodicals" },
                    { id: 4, code: "95114", label: "Other publishing activities" }
                 ] 
                },
                { id: 2, groupCode: "9512", groupTitle: "Activities of holding companies", classes: [
                    { id: 1, code: "95121", label: "N/A" },
                 ] 
                },
                { id: 3, groupCode: "9513", groupTitle: "Trusts, funds and similar financial entities", classes: [
                    { id: 1, code: "95131", label: "N/A" },
                 ] 
                },
                { id: 4, groupCode: "9514", groupTitle: "Trusts, funds and similar financial entities", classes: [
                    { id: 1, code: "95141", label: "N/A" },
                 ] 
                },
                { id: 5, groupCode: "9515", groupTitle: "Other financial service activities, except insurance and pension funding activities", classes: [
                    { id: 1, code: "95151", label: "Financial leasing" },
                    { id: 2, code: "95152", label: "Other credit granting" },
                    { id: 3, code: "95153", label: "Other financial service activities, except insurance and pension funding activities" },
                    { id: 4, code: "95154", label: "Insurance, reinsurance and pension funding, except compulsory social security" },
                 ] 
                },
                { id: 6, groupCode: "9516", groupTitle: "Insurance", classes: [
                    { id: 1, code: "95161", label: "Life insurance " },
                    { id: 2, code: "95162", label: "Non-life insurance" }
                 ] 
                },
                { id: 7, groupCode: "9517", groupTitle: "Reinsurance", classes: [
                    { id: 1, code: "95171", label: "N/A" },
                 ] 
                },
                { id: 8, groupCode: "9518", groupTitle: "Pension funding", classes: [
                    { id: 1, code: "95181", label: "N/A" },
                 ] 
                }
             ]  
            },

            { id: 96, slug: 96, name: "Division 07", title: "Activities auxiliary to financial service and insurance activities", group: [
                
                { id: 1, groupCode: "9611", groupTitle: "Activities auxiliary to financial service activities, except insurance and pension funding", classes: [
                    { id: 1, code: "96111", label: "Administration of financial markets" },
                    { id: 2, code: "96112", label: "Security and commodity contracts brokerage" },
                    { id: 3, code: "96113", label: "Other activities auxiliary to financial service activities" },
                 ] 
                },
                { id: 2, groupCode: "9612", groupTitle: "Activities auxiliary to insurance and pension funding", classes: [
                    { id: 1, code: "96121", label: "Risk and damage evaluation" },
                    { id: 2, code: "96122", label: "Activities of insurance agents and brokers" },
                    { id: 3, code: "96123", label: "Other activities auxiliary to insurance and pension funding" },
                    { id: 4, code: "96124", label: "Fund management activities" }
                 ] 
                }
             ]  
            }
        ] },
        { itemId: 11, sector: "Section K", title: "Real Estate Activities",  divisions: [
            { id: 97, slug: 97, name: "Division 07", title: "Real estate activities", group: [
                
                { id: 1, groupCode: "9711", groupTitle: "Real estate activities with own or leased property", classes: [
                    { id: 1, code: "97111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "9721", groupTitle: "Real estate activities on a fee or contract basis", classes: [
                    { id: 1, code: "97211", label: "N/A" },
                 ] 
                },
             ]  
            }
        ] },
        { itemId: 12, sector: "Section L", title: "Professional scientific and techinical activities",  divisions: [
            { id: 98, slug: 98, name: "Division 07", title: "Legal and accounting activities", group: [
                
                { id: 1, groupCode: "9811", groupTitle: "Legal activities", classes: [
                    { id: 1, code: "98111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "9821", groupTitle: "Accounting, bookkeeping and auditing activities; tax consultancy", classes: [
                    { id: 1, code: "98211", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 99, slug: 99, name: "Division 07", title: "Activities of head offices; management consultancy activities", group: [
                
                { id: 1, groupCode: "9911", groupTitle: "Activities of head offices", classes: [
                    { id: 1, code: "99111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "9921", groupTitle: "Management consultancy activities", classes: [
                    { id: 1, code: "99211", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 100, slug: 100, name: "Division 07", title: "Architectural and engineering activities; technical testing and analysis", group: [
                
                { id: 1, groupCode: "10011", groupTitle: "Architectural and engineering activities and related technical consultancy", classes: [
                    { id: 1, code: "100111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "10021", groupTitle: "Technical testing and analysis", classes: [
                    { id: 1, code: "100211", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 101, slug: 101, name: "Division 07", title: "Scientific research and development", group: [
                
                { id: 1, groupCode: "10111", groupTitle: "Research and experimental development on natural sciences and engineering", classes: [
                    { id: 1, code: "101111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "10121", groupTitle: "Research and experimental development on social sciences and humanities", classes: [
                    { id: 1, code: "101211", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 102, slug: 102, name: "Division 07", title: "Advertising and market research", group: [
                
                { id: 1, groupCode: "10211", groupTitle: "Advertising", classes: [
                    { id: 1, code: "102111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "10221", groupTitle: "Market research and public opinion polling", classes: [
                    { id: 1, code: "102211", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 103, slug: 103, name: "Division 07", title: "Other professional, scientific and technical activities", group: [
                
                { id: 1, groupCode: "10311", groupTitle: "Specialized design activities", classes: [
                    { id: 1, code: "103111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "10321", groupTitle: "Photographic activities", classes: [
                    { id: 1, code: "103211", label: "N/A" },
                 ] 
                },

                { id: 3, groupCode: "10331", groupTitle: "Other professional, scientific and technical activities", classes: [
                    { id: 1, code: "103311", label: "N/A" },
                 ] 
                },
             ]  
            },
            { id: 104, slug: 104, name: "Division 07", title: "Veterinary activities", group: [
                
                { id: 1, groupCode: "10411", groupTitle: "Veterinary activities", classes: [
                    { id: 1, code: "104111", label: "N/A" },
                 ] 
                },
             ]  
            }

        ] },

        { itemId: 13, sector: "Section M", title: "Administrative and support service activities",  divisions: [
            { id: 130, slug: 130, name: "Division 07", title: "Rental and leasing activities", group: [
                
                { id: 1, groupCode: "13011", groupTitle: "Renting and leasing of motor vehicles", classes: [
                    { id: 1, code: "130111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "13021", groupTitle: "Renting and leasing of other machinery, equipment and tangible goods", classes: [
                    { id: 1, code: "130211", label: "Renting and leasing of other personal and household goods" },
                    { id: 2, code: "130212", label: "Renting of video tapes and disks" },
                    { id: 3, code: "130213", label: "Renting and leasing of recreational and sports goods" }
                 ] 
                },

                { id: 2, groupCode: "9821", groupTitle: "Leasing of intellectual property and similar products, except copyrighted works", classes: [
                    { id: 1, code: "98211", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 131, slug: 131, name: "Division 07", title: "Employment activities", group: [
                
                { id: 1, groupCode: "13111", groupTitle: "Activities of employment placement agencies", classes: [
                    { id: 1, code: "131111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "13121", groupTitle: "Temporary employment agency activities", classes: [
                    { id: 1, code: "131211", label: "N/A" },
                 ] 
                },

                { id: 3, groupCode: "13121", groupTitle: "Other human resources provision", classes: [
                    { id: 1, code: "131211", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 132, slug: 132, name: "Division 07", title: "Travel agency, tour operator, reservation service and related activities", group: [
                
                { id: 1, groupCode: "13211", groupTitle: "Travel agency and tour operator activities", classes: [
                    { id: 1, code: "132111", label: "Travel agency activities" },
                    { id: 2, code: "132112", label: "Tour operator activities" },
                 ] 
                },

                { id: 2, groupCode: "13212", groupTitle: "Other reservation service and related activities", classes: [
                    { id: 1, code: "132121", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 133, slug: 133, name: "Division 07", title: "Security and investigation activities", group: [
                
                { id: 1, groupCode: "13311", groupTitle: "Private security activities", classes: [
                    { id: 1, code: "133111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "13321", groupTitle: "Security systems service activities", classes: [
                    { id: 1, code: "133211", label: "N/A" },
                 ] 
                },

                { id: 3, groupCode: "13331", groupTitle: "Investigation activities", classes: [
                    { id: 1, code: "133311", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 134, slug: 134, name: "Division 07", title: "Services to buildings and landscape activities", group: [
                
                { id: 1, groupCode: "13411", groupTitle: "Landscape care and maintenance service activities", classes: [
                    { id: 1, code: "134111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "13421", groupTitle: "Cleaning activities", classes: [
                    { id: 1, code: "134211", label: "General cleaning of buildings" },
                    { id: 2, code: "134212", label: "Other building and industrial cleaning activities" }
                 ] 
                },
             ]  
            },

            { id: 135, slug: 135, name: "Division 07", title: "Office administrative, office support and other business support activities", group: [
                
                { id: 1, groupCode: "13511", groupTitle: "Office administrative and support activities", classes: [
                    { id: 1, code: "135111", label: "Combined office administrative service activities" },
                    { id: 2, code: "135112", label: "Photocopying, document preparation and other specialized office support activities" },
                 ] 
                },

                { id: 2, groupCode: "13521", groupTitle: "Activities of call centres", classes: [
                    { id: 1, code: "135211", label: "N/A" },
                 ] 
                },

                { id: 3, groupCode: "13531", groupTitle: "Organization of conventions and trade shows", classes: [
                    { id: 1, code: "135311", label: "N/A" },
                 ] 
                },
                { id: 4, groupCode: "13541", groupTitle: "Business support service activities", classes: [
                    { id: 1, code: "135411", label: "Activities of collection agencies and credit bureaus" },
                    { id: 1, code: "135412", label: "Packaging activities" },
                    { id: 1, code: "135413", label: "Other business support service activities" },
                 ] 
                },
             ]  
            },
        ] },


        { itemId: 14, sector: "Section N", title: "Public administration and defence; compulsory social security",  divisions: [
            { id: 140, slug: 140, name: "Division 07", title: "Public administration and defence; compulsory social security", group: [
                
                { id: 1, groupCode: "14011", groupTitle: "Administration of the State and the economic and social policy of the community", classes: [
                    { id: 1, code: "140111", label: "General public administration activities" },
                    { id: 2, code: "140112", label: "Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security" },
                    { id: 3, code: "140113", label: "Regulation of and contribution to more efficient operation of businesses" },

                 ] 
                },

                { id: 2, groupCode: "14021", groupTitle: "Provision of services to the community as a whole", classes: [
                    { id: 1, code: "140211", label: "Foreign affairs" },
                    { id: 2, code: "140212", label: "Defence activities" },
                    { id: 3, code: "140213", label: "Public order and safety activities" }
                 ] 
                },

                { id: 3, groupCode: "14031", groupTitle: "Compulsory social security activities", classes: [
                    { id: 1, code: "140311", label: "N/A" },
                 ] 
                },
             ]  
        } ] },

        { itemId: 15, sector: "Section O", title: "Education",  divisions: [
            { id: 150, slug: 150, name: "Division 07", title: "Education", group: [
                
                { id: 1, groupCode: "15011", groupTitle: "Pre-primary and primary education", classes: [
                    { id: 1, code: "150111", label: "N/A" },

                 ] 
                },

                { id: 2, groupCode: "15021", groupTitle: "Secondary education", classes: [
                    { id: 1, code: "150211", label: "General secondary education" },
                    { id: 2, code: "150212", label: "Technical and vocational secondary education" },
                 ] 
                },

                { id: 3, groupCode: "15031", groupTitle: "Higher education", classes: [
                    { id: 1, code: "150311", label: "N/A" },
                 ] 
                },

                { id: 4, groupCode: "15041", groupTitle: "Other education", classes: [
                    { id: 1, code: "150411", label: "Sports and recreation education" },
                    { id: 2, code: "150412", label: "Cultural education" },
                    { id: 3, code: "150413", label: "Other education" }
                 ] 
                },

                { id: 3, groupCode: "14031", groupTitle: "Educational support activities", classes: [
                    { id: 1, code: "140311", label: "N/A" },
                 ] 
                },
             ]  
            } ] },

        { itemId: 16, sector: "Section P", title: "Human health and social work activities",  divisions: [
            { id: 160, slug: 160, name: "Division 07", title: "Human health activities", group: [
                
                { id: 1, groupCode: "16011", groupTitle: "Hospital activities", classes: [
                    { id: 1, code: "160111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "16021", groupTitle: "Medical and dental practice activities", classes: [
                    { id: 1, code: "160211", label: "N/A" }
                 ] 
                },

                { id: 3, groupCode: "16031", groupTitle: "Other human health activities", classes: [
                    { id: 1, code: "160311", label: "N/A" },
                 ] 
                },
             ] },

             { id: 161, slug: 161, name: "Division 07", title: "Residential care activities", group: [
                
                { id: 1, groupCode: "16111", groupTitle: "Residential nursing care facilities", classes: [
                    { id: 1, code: "161111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "16121", groupTitle: "Residential care activities for mental retardation, mental health and substance abuse", classes: [
                    { id: 1, code: "161211", label: "N/A" }
                 ] 
                },

                { id: 3, groupCode: "16131", groupTitle: "Residential care activities for the elderly and disabled", classes: [
                    { id: 1, code: "161311", label: "N/A" },
                 ] 
                },

                { id: 4, groupCode: "16141", groupTitle: "Other residential care activities", classes: [
                    { id: 1, code: "161411", label: "N/A" },
                 ] 
                },
             ] },


             { id: 162, slug: 162, name: "Division 07", title: "Social work activities without accommodation", group: [
                
                { id: 1, groupCode: "16211", groupTitle: "Social work activities without accommodation for the elderly and disabled", classes: [
                    { id: 1, code: "162111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "16221", groupTitle: "Other social work activities without accommodation", classes: [
                    { id: 1, code: "162211", label: "N/A" }
                 ] 
                },
             ] }

        ] },
        { itemId: 17, sector: "Section Q", title: "Arts, Entertainment and recreation",  divisions: [
            { id: 170, slug: 170, name: "Division 07", title: "Creative, arts and entertainment activities", group: [
                
                { id: 1, groupCode: "17011", groupTitle: "Creative, arts and entertainment activities", classes: [
                    { id: 1, code: "170111", label: "N/A" },
                 ] 
                },
             ] },
            
            { id: 171, slug: 171, name: "Division 07", title: "Libraries, archives, museums and other cultural activities", group: [
                
                { id: 1, groupCode: "17111", groupTitle: "Library and archives activities", classes: [
                    { id: 1, code: "171111", label: "Museums activities and operation of historical sites and buildings" },
                    { id: 2, code: "171112", label: "Botanical and zoological gardens and nature reserves activities" }
                 ] 
                },
             ] },

             { id: 172, slug: 172, name: "Division 07", title: "Gambling and betting activities", group: [
                
                { id: 1, groupCode: "17211", groupTitle: "Gambling and betting activities yes for secondary, and no for environment", classes: [
                    { id: 1, code: "172111", label: "N/A" },
                 ] 
                },
             ] },

             { id: 173, slug: 173, name: "Division 07", title: "Sports activities and amusement and recreation activities", group: [
                
                { id: 1, groupCode: "17311", groupTitle: "Sports activities", classes: [
                    { id: 1, code: "173111", label: "Operation of sports facilities" },
                    { id: 2, code: "173112", label: "Activities of sports clubs" },
                    { id: 3, code: "173113", label: "Other sports activities" },
                 ] 
                },
                { id: 2, groupCode: "17321", groupTitle: "Other amusement and recreation activities", classes: [
                    { id: 1, code: "173211", label: "Activities of amusement parks and theme parks" },
                    { id: 2, code: "173212", label: "Other amusement and recreation activities" },
                 ] 
                },
             ] },


        ] },

        { itemId: 18, sector: "Section R", title: "Other service activities",   divisions: [
            { id: 180, slug: 180, name: "Division 07", title: "Activities of membership organizations", group: [
                
            { id: 1, groupCode: "18011", groupTitle: "Activities of business, employers and professional membership organizations", classes: [
                { id: 1, code: "180111", label: "Activities of business and employers membership organizations" },
                { id: 2, code: "180112", label: "Activities of professional membership organizations" }
             ] 
            },

            { id: 2, groupCode: "18021", groupTitle: "Activities of trade unions", classes: [
                { id: 1, code: "180211", label: "N/A" }
             ] 
            },
            { id: 3, groupCode: "18031", groupTitle: "Activities of other membership organizations", classes: [
                { id: 1, code: "180311", label: "Activities of religious organizations" },
                { id: 2, code: "180312", label: "Activities of political organizations" },
                { id: 3, code: "180313", label: "Activities of other membership organizations" },
                { id: 4, code: "180314", label: "Repair of computers and peripheral equipment" }
             ] 
            },
            ] },

            { id: 181, slug: 181, name: "Division 07", title: "Repair of computers and personal and household goods", group: [
                
                { id: 1, groupCode: "18111", groupTitle: "Repair of computers and communication equipment", classes: [
                    { id: 1, code: "181111", label: "Repair of communication equipment" },
                 ] 
                },
    
                { id: 2, groupCode: "18121", groupTitle: "Repair of personal and household goods", classes: [
                    { id: 1, code: "181211", label: "Repair of consumer electronics" },
                    { id: 2, code: "181212", label: "Repair of household appliances and home and garden equipment" },
                    { id: 3, code: "181213", label: "Repair of footwear and leather goods" },
                    { id: 4, code: "181214", label: "Repair of furniture and home furnishings" },
                    { id: 5, code: "181215", label: "Repair of other personal and household goods" }
                 ] 
                },
            ] },

            { id: 182, slug: 182, name: "Division 07", title: "Other personal service activities", group: [
                
                { id: 1, groupCode: "18211", groupTitle: "Washing and (dry-) cleaning of textile and fur products", classes: [
                    { id: 1, code: "182111", label: "N/A" },
                 ] 
                },
    
                { id: 2, groupCode: "18221", groupTitle: "Hairdressing and other beauty treatment", classes: [
                    { id: 1, code: "182211", label: "N/A" }
                 ] 
                },

                { id: 3, groupCode: "18231", groupTitle: "Funeral and related activities", classes: [
                    { id: 1, code: "182311", label: "N/A" }
                 ] 
                },

                { id: 4, groupCode: "18241", groupTitle: "Other personal service activities ", classes: [
                    { id: 1, code: "182411", label: "N/A" }
                 ] 
                },
            ] },


        ] },
        { itemId: 19, sector: "Section S", title: "Activities of households as employers; undifferentiated goods and services producing activities of households for own use",  
            divisions: [
            { id: 190, slug: 190, name: "Division 07", title: "Activities of households as employers of domestic personnel", group: [
                
                { id: 1, groupCode: "19011", groupTitle: "Activities of households as employers of domestic personnel", classes: [
                    { id: 1, code: "190111", label: "N/A" },
                 ] 
                },
             ]  
            },

            { id: 191, slug: 191, name: "Division 07", title: "Undifferentiated goods and services producing activities of private households for own use", group: [
                
                { id: 1, groupCode: "19111", groupTitle: "Undifferentiated goods-producing activities of private households for own use", classes: [
                    { id: 1, code: "191111", label: "N/A" },
                 ] 
                },

                { id: 2, groupCode: "19121", groupTitle: "Undifferentiated service-producing activities of private households for own use", classes: [
                    { id: 1, code: "191211", label: "N/A" },
                 ] 
                },
             ]  
            },


        ] },
        { itemId: 20, sector: "Section T", title: "Activities of extraterritorial organizations and bodies", 
            divisions: [
            { id: 200, slug: 200, name: "Division 07", title: "Activities of extraterritorial organizations and bodies", group: [
                
                { id: 1, groupCode: "20011", groupTitle: "Activities of extraterritorial organizations and bodies", classes: [
                    { id: 1, code: "200111", label: "N/A" },
                 ] 
                },
             ]  
            },
        ] },
    ],
    engineers: [ 
{ reg_no:"69", name:"Ghata-Aura Sardul Singh", gender: "M", field:"Civil",  date: "", type:"PERMANENT" },
{ reg_no:"78", name:"Bagarukayo Eris Kahenano", gender: "M", field:"Civil",  date: "", type:"PERMANENT" },
{ reg_no:"82", name:"Onen Perezi M. Oryem", gender: "M", field:"Civil",  date: "", type:"PERMANENT" },
{ reg_no:"113", name:"Lugya Hannington Erieza", gender: "M", field:"Civil",  date: "", type:"PERMANENT" },
{ reg_no:"128", name:"Kagga Abdu Ssenkubuge", gender: "M", field:"Civil",  date: "", type:"PERMANENT" },
{ reg_no:"135", name:"Besigiroha Nekemia", gender: "M", field:"Civil",  date: "", type:"PERMANENT" },
{ reg_no:"147", name:"Adriko Eric Tiyo Ssekebuga (Dr.)", gender: "M", field:"Mechanical",  date: "", type:"PERMANENT" },
{ reg_no:"181", name:"Byansi Valentine Mwase", gender: "M", field:"Civil",  date: "", type:"PERMANENT" },
{ reg_no:"186", name:"Walusimbi J.B.", gender: "M", field:"Civil", date: "May/07/1974" , type:"PERMANENT"},
{ reg_no:"194", name:"Muyobo Nathan Besweri Keneth", gender: "M", field:"Electrical", date: "Aug/12/1976", type:"PERMANENT" },
{ reg_no:"200", name:"Kiggundu Badru M. (Dr.)", gender: "M", field:"Civil", date: "Sep/07/1977", type:"PERMANENT" },
{ reg_no:"202", name:"Batumbya C. M. Patrick ", gender: "M", field:"Civil", date: "Jun/08/1978", type:"PERMANENT" },
{ reg_no:"209", name:"Tusubira Francis Frederick (Dr.)", gender: "M", field:"Electrical", date: "Aug/07/1981" ,type:"PERMANENT" },
{ reg_no:"210", name:"Gashaija Zirimwabagabo Venant", gender: "M", field:"Civil", date: "May/27/1981" ,type:"PERMANENT" },
{ reg_no:"214", name:"Babinga Daudi Paul", gender: "M", field:"Civil", date: "Sep/18/1981", type:"PERMANENT" },
{ reg_no:"216", name:"Mwedde Jonathan Grant", gender: "M", field:"Civil", date: "Oct/22/1981", type:"PERMANENT" },
{ reg_no:"223", name:"Sekisambu Serubiri Samuel ", gender: "M", field:"Civil", date: "Oct/17/1983", type:"PERMANENT" },
{ reg_no:"231", name:"Mugisa Augustine Obyero (Dr.)", gender: "M", field:"Civil", date: "Apr/24/1984" ,type:"PERMANENT" },
{ reg_no:"233", name:"Musuuza Charles K.", gender: "M", field:"Civil", date: "Jun/06/1984", type:"PERMANENT" },
{ reg_no:"234", name:"Kakeeto Hillary Ddungu", gender: "M", field:"Civil", date: "Jul/11/1984", type:"PERMANENT" },
{ reg_no:"237", name:"Nnyanzi George William", gender: "M", field:"Mechanical", date: "Aug/30/1984" ,type:"PERMANENT" },
{ reg_no:"239", name:"Okurut George William", gender: "M", field:"Mechanical", date: "Feb/27/1985" ,type:"PERMANENT" },
{ reg_no:"246", name:"Katahoire Abel M.S (Dr.)", gender: "M", field:"Electrical", date: "Jul/23/1985" ,type:"PERMANENT" },
{ reg_no:"248", name:"Kagoda Stephen Paul (Dr.)", gender: "M", field:"Mechanical", date: "Aug/23/1985" ,type:"PERMANENT" },
{ reg_no:"249", name:"Odongo Thomas", gender: "M", field:"Electrical", date: "Aug/23/1985" ,type:"PERMANENT" },
{ reg_no:"252", name:"Nsereko Michael", gender: "M", field:"Civil", date: "Sep/03/1985" ,type:"PERMANENT" },
{ reg_no:"257", name:"Sagala Paul Neil Sekikubo (Dr.)", gender: "M", field:"Mechanical", date: "Apr/11/1986" ,type:"PERMANENT" },
{ reg_no:"259", name:"Irumba Henry Kaliisa", gender: "M", field:"Mechanical", date: "Jun/24/1986" ,type:"PERMANENT" },
{ reg_no:"260", name:"D'Ujanga Simon Giw", gender: "M", field:"Electrical", date: "Jul/23/1986" ,type:"PERMANENT" },
{ reg_no:"264", name:"Kasangaki Vincent B.A (Dr.)", gender: "M", field:"Electrical", date: "Oct/15/1986" ,type:"PERMANENT" },
{ reg_no:"276", name:"Mutabazi Joseph Balikurungi", gender: "M", field:"Mechanical", date: "Dec/08/1987" ,type:"PERMANENT" },
{ reg_no:"278", name:"Byandala Abraham James", gender: "M", field:"Civil", date: "Dec/22/1988" ,type:"PERMANENT" },
{ reg_no:"280", name:"Ssebugwawo G.F.", gender: "M", field:"Electrical", date: "Dec/30/1988" ,type:"PERMANENT" },
{ reg_no:"283", name:"Musubire Vincent C.", gender: "M", field:"Mechanical", date: "Dec/12/1988" ,type:"PERMANENT" },
{ reg_no:"284", name:"Wana-Etyem Charles (Dr.)", gender: "M", field:"Civil", date: "Dec/28/1988" ,type:"PERMANENT" },
{ reg_no:"286", name:"Ssebanakitta Peter W.", gender: "M", field:"Civil", date: "Dec/21/1988" ,type:"PERMANENT" },
{ reg_no:"288", name:"Busisa J.T.", gender: "M", field:"Civil", date: "Dec/28/1988" ,type:"PERMANENT" },
{ reg_no:"289", name:"Nasasira John Moono", gender: "M", field:"Civil", date: "Feb/02/1989" ,type:"PERMANENT" },
{ reg_no:"292", name:"Senfuma Stanley J.R.", gender: "M", field:"Civil", date: "Nov/16/1989" ,type:"PERMANENT" },
{ reg_no:"296", name:"Karekaho Kamu Rugumiire", gender: "M", field:"Electrical", date: "Nov/16/1989" ,type:"PERMANENT" },
{ reg_no:"297", name:"Njuki P.M. Lwanga (Mrs)", gender: "F", field:"Electrical", date: "Nov/23/1989" ,type:"PERMANENT" },
{ reg_no:"301", name:"Gakyaro Pascal Rushwiga", gender: "M", field:"Electrical", date: "Jul/21/1990" ,type:"PERMANENT" },
{ reg_no:"302", name:"Ssematiko Godfrey Stephen", gender: "M", field:"Electrical", date: "Jul/28/1990" ,type:"PERMANENT" },
{ reg_no:"305", name:"Lubowa Aloysius Gonzaga", gender: "M", field:"Civil", date: "Jul/28/1990" ,type:"PERMANENT" },
{ reg_no:"306", name:"Bagonza Samson", gender: "M", field:"Civil", date: "Jul/18/1990" ,type:"PERMANENT" },
{ reg_no:"307", name:"Onek Hillary Obolaker", gender: "M", field:"Civil", date: "Jul/25/1990" ,type:"PERMANENT" },
{ reg_no:"308", name:"Owora Sam", gender: "M", field:"Civil", date: "Jul/29/1990" ,type:"PERMANENT" },
{ reg_no:"309", name:"Chandarana Bharat", gender: "M", field:"Civil", date: "Aug/29/1990" ,type:"PERMANENT" },
{ reg_no:"311", name:"Murengezi Moses", gender: "M", field:"Electrical", date: "Sep/05/1990" ,type:"PERMANENT" },
{ reg_no:"312", name:"Labite Victorio Ocaya", gender: "M", field:"Civil", date: "Aug/28/1990" ,type:"PERMANENT" },
{ reg_no:"313", name:"Wanda Sam Steve Balayo", gender: "M", field:"Civil", date: "Sep/05/1991" ,type:"PERMANENT" },
{ reg_no:"314", name:"Angiro Justin Omoding", gender: "M", field:"Civil", date: "Sep/12/1991" ,type:"PERMANENT" },
{ reg_no:"315", name:"Onen Alex", gender: "M", field:"Civil", date: "Jan/16/1992" ,type:"PERMANENT" },
{ reg_no:"320", name:"Musumba William Emmanuel", gender: "M", field:"Civil", date: "Apr/06/1992" ,type:"PERMANENT" },
{ reg_no:"323", name:"Kagyina Karuma", gender: "M", field:"Civil", date: "Sep/11/1992" ,type:"PERMANENT" },
{ reg_no:"325", name:"Anglin John S", gender: "M", field:"Mechanical", date: "/7/1992" ,type:"PERMANENT" },
{ reg_no:"326", name:"Ssebbugga-Kimeze Berunado", gender: "M", field:"Civil", date: "Sep/04/1992" ,type:"PERMANENT" },
{ reg_no:"327", name:"Kazinduki Francis N", gender: "M", field:"Electrical", date: "Oct/21/1992" ,type:"PERMANENT" },
{ reg_no:"330", name:"Magambo Peter Julius", gender: "M", field:"Civil", date: "Dec/03/1992" ,type:"PERMANENT" },
{ reg_no:"331", name:"Kiwanuka Frederick (Dr.)", gender: "M", field:"Civil", date: "Apr/08/1993" ,type:"PERMANENT" },
{ reg_no:"333", name:"Ariyo Frank K", gender: "M", field:"Mechanical", date: "Oct/07/1993" ,type:"PERMANENT" },
{ reg_no:"334", name:"Mubiru Paul", gender: "M", field:"Mechanical", date: "Aug/10/1993" ,type:"PERMANENT" },
{ reg_no:"335", name:"Sentongo Samuel Sebide", gender: "M", field:"Electrical", date: "Nov/04/1993" ,type:"PERMANENT" },
{ reg_no:"338", name:"Okiror James", gender: "M", field:"Civil", date: "Aug/10/1993" ,type:"PERMANENT" },
{ reg_no:"342", name:"Bwanga George William", gender: "M", field:"Civil", date: "Jun/14/1994" ,type:"PERMANENT" },
{ reg_no:"343", name:"Drazu Charles", gender: "M", field:"Civil", date: "Jun/14/1994" ,type:"PERMANENT" },
{ reg_no:"344", name:"Wodeya Edward", gender: "M", field:"Civil", date: "Jun/15/1994" ,type:"PERMANENT" },
{ reg_no:"348", name:"Semuju Charles Roy Ddiba", gender: "M", field:"Electrical", date: "Jul/13/1994" ,type:"PERMANENT" },
{ reg_no:"349", name:"Luswata Musa Buzibwa", gender: "M", field:"Civil", date: "Sep/14/1994" ,type:"PERMANENT" },
{ reg_no:"351", name:"Katesigwa Deogratias M.K", gender: "M", field:"Civil", date: "Mar/02/1995" ,type:"PERMANENT" },
{ reg_no:"352", name:"Kangere Livingstone S", gender: "M", field:"Mechanical", date: "Mar/09/1995" ,type:"PERMANENT" },
{ reg_no:"355", name:"Odongo Michael Moses(Dr.)", gender: "M", field:"Civil", date: "Aug/16/1995" ,type:"PERMANENT" },
{ reg_no:"356", name:"Mwakali Jackson A. (Prof.)", gender: "M", field:"Civil", date: "Aug/29/1995" ,type:"PERMANENT" },
{ reg_no:"357", name:"Manyindo Ben (Dr.)", gender: "M", field:"Mechanical", date: "Sep/07/1995" ,type:"PERMANENT" },
{ reg_no:"359", name:"Kinyera Stephen Otto", gender: "M", field:"Civil", date: "Sep/29/1995" ,type:"PERMANENT" },
{ reg_no:"360", name:"Mwesigwa Hans J.W.B.", gender: "M", field:"Civil", date: "Oct/16/1995" ,type:"PERMANENT" },
{ reg_no:"366", name:"Okello Wilfred", gender: "M", field:"Civil", date: "Apr/24/1996" ,type:"PERMANENT" },
{ reg_no:"368", name:"Kikuyo  Peter", gender: "M", field:"Electrical", date: "Oct/10/1996" ,type:"PERMANENT" },
{ reg_no:"372", name:"Sebbowa Frank Bunnya (Dr.)", gender: "M", field:"Mechanical", date: "May/05/1997" ,type:"PERMANENT" },
{ reg_no:"373", name:"Were-Higenyi Frederick Millan (Dr.)", gender: "M", field:"Civil", date: "May/14/1997" ,type:"PERMANENT" },
{ reg_no:"374", name:"Kasule David Lutwama", gender: "M", field:"Civil", date: "May/21/1997" ,type:"PERMANENT" },
{ reg_no:"376", name:"Musisi Frederick", gender: "M", field:"Civil", date: "May/27/1997" ,type:"PERMANENT" },
{ reg_no:"377", name:"Kawuma Stephen", gender: "M", field:"Civil", date: "Jun/06/1997" ,type:"PERMANENT" },
{ reg_no:"382", name:"Gisagaara Alex", gender: "M", field:"Mechanical", date: "Sep/17/1997" ,type:"PERMANENT" },
{ reg_no:"383", name:"Mbabazi John Geoffrey", gender: "M", field:"Mechanical", date: "Oct/17/1997" ,type:"PERMANENT" },
{ reg_no:"384", name:"Ebal Christopher", gender: "M", field:"Civil", date: "Oct/22/1997" ,type:"PERMANENT" },
{ reg_no:"386", name:"Kisembo Bernard Amooti", gender: "M", field:"Electrical", date: "Nov/28/1997" ,type:"PERMANENT" },
{ reg_no:"387", name:"Seryazi John C.T.", gender: "M", field:"Civil", date: "Feb/13/1998" ,type:"PERMANENT" },
{ reg_no:"388", name:"Ebangu-Orari Benedict", gender: "M", field:"Electrical", date: "Dec/08/1998" ,type:"PERMANENT" },
{ reg_no:"389", name:"Aaron Mwidu Kabirizi", gender: "M", field:"Civil", date: "Jul/01/1998" ,type:"PERMANENT" },
{ reg_no:"390", name:"Ndawula Edward Mike (Dr.)", gender: "M", field:"Electrical", date: "Jul/27/1998" ,type:"PERMANENT" },
{ reg_no:"391", name:"Kakaire Patrick", gender: "M", field:"Electrical", date: "Jul/31/1998" ,type:"PERMANENT" },
{ reg_no:"394", name:"Ssambwa Godfrey", gender: "M", field:"Civil", date: "Jul/28/1998" ,type:"PERMANENT" },
{ reg_no:"395", name:"Okinyal Henry Francis", gender: "M", field:"Mechanical", date: "Aug/19/1998" ,type:"PERMANENT" },
{ reg_no:"396", name:"Kangwamu Stephen", gender: "M", field:"Electrical", date: "Aug/05/1998" ,type:"PERMANENT" },
{ reg_no:"399", name:"Musiimenta Julius Bakeine ", gender: "M", field:"Mechanical", date: "Aug/28/1998" ,type:"PERMANENT" },
{ reg_no:"400", name:"Mukasa-Kaaya Godfrey", gender: "M", field:"Civil", date: "Nov/19/1998" ,type:"PERMANENT" },
{ reg_no:"402", name:"Gisembe Robert Orwenyo", gender: "M", field:"Civil", date: "Sep/30/1998" ,type:"PERMANENT" },
{ reg_no:"404", name:"Muchiri John Mwangi", gender: "M", field:"Civil", date: "Sep/16/1998" ,type:"PERMANENT" },
{ reg_no:"411", name:"Wobusobozi Nebert B.", gender: "M", field:"Civil", date: "Feb/11/1999" ,type:"PERMANENT" },
{ reg_no:"412", name:"Mulumba Julius", gender: "M", field:"Civil", date: "Apr/14/1999" ,type:"PERMANENT" },
{ reg_no:"413", name:"Odongo Justine Ongom", gender: "M", field:"Civil", date: "Jul/08/1999" ,type:"PERMANENT" },
{ reg_no:"414", name:"Muhairwe Lawrence", gender: "M", field:"Civil", date: "Jul/15/1999" ,type:"PERMANENT" },
{ reg_no:"415", name:"Tushabe Aus-Ali", gender: "M", field:"Civil", date: "Jul/19/1999" ,type:"PERMANENT" },
{ reg_no:"416", name:"Musisi Edward Kasule", gender: "M", field:"Electrical", date: "Jul/28/1999" ,type:"PERMANENT" },
{ reg_no:"417", name:"Ojok Michael", gender: "M", field:"Civil", date: "Sep/06/1999" ,type:"PERMANENT" },
{ reg_no:"419", name:"Kasule-Mukasa Paul E", gender: "M", field:"Civil", date: "Nov/03/1999" ,type:"PERMANENT" },
{ reg_no:"421", name:"Libokoyi Laban Imbat", gender: "M", field:"Civil", date: "Dec/09/1999" ,type:"PERMANENT" },
{ reg_no:"422", name:"Eyatu Oriono Joseph ", gender: "M", field:"Civil", date: "Dec/28/1999" ,type:"PERMANENT" },
{ reg_no:"423", name:"Kitonsa Stephen Kiwanuka", gender: "M", field:"Civil", date: "Mar/23/2000" ,type:"PERMANENT" },
{ reg_no:"424", name:"Assedri Charles Olivu", gender: "M", field:"Civil", date: "Mar/28/2000" ,type:"PERMANENT" },
{ reg_no:"429", name:"Barasa I. Wandera", gender: "M", field:"Civil", date: "May/25/2001" ,type:"PERMANENT" },
{ reg_no:"430", name:"Muloiti D. Albert", gender: "M", field:"Civil", date: "May/25/2001" ,type:"PERMANENT" },
{ reg_no:"431", name:"Mugisha Silver (Dr.)", gender: "M", field:"Civil", date: "May/30/2001" ,type:"PERMANENT" },
{ reg_no:"432", name:"Sabiiti Charles Habarugira", gender: "M", field:"Civil", date: "Jun/01/2001" ,type:"PERMANENT" },
{ reg_no:"433", name:"Odenigbo Francis Chigozie", gender: "M", field:"Civil", date: "Jun/11/2001" ,type:"PERMANENT" },
{ reg_no:"434", name:"Nkuusa-Kalali J. Emmanuel", gender: "M", field:"Civil", date: "Jun/14/2001" ,type:"PERMANENT" },
{ reg_no:"436", name:"Twinomujuni John M. Vianney", gender: "M", field:"Civil", date: "Jun/15/2001" ,type:"PERMANENT" },
{ reg_no:"437", name:"Omwolo Nathaniel Wilson", gender: "M", field:"Civil", date: "Jun/15/2001" ,type:"PERMANENT" },
{ reg_no:"438", name:"Kiiza Nkya Retreat", gender: "M", field:"Mechanical", date: "Jun/22/2001" ,type:"PERMANENT" },
{ reg_no:"439", name:"Kavuma Tony Bafirawala", gender: "M", field:"Mechanical", date: "May/22/2001" ,type:"PERMANENT" },
{ reg_no:"442", name:"Munduga Gideon E. E.", gender: "M", field:"Civil", date: "Jun/29/2001" ,type:"PERMANENT" },
{ reg_no:"443", name:"Besigye Cyrus Bekunda", gender: "M", field:"Civil", date: "Jun/10/2001" ,type:"PERMANENT" },
{ reg_no:"444", name:"Sooma Ayub", gender: "M", field:"Electrical", date: "Jul/20/2001" ,type:"PERMANENT" },
{ reg_no:"445", name:"Ajalu John Stephen", gender: "M", field:"Civil", date: "Jul/20/2001" ,type:"PERMANENT" },
{ reg_no:"447", name:"Otim Vincent", gender: "M", field:"Civil", date: "Jul/31/2001" ,type:"PERMANENT" },
{ reg_no:"448", name:"Middleton James Peter", gender: "M", field:"Civil", date: "Aug/02/2001" ,type:"PERMANENT" },
{ reg_no:"451", name:"Okwija Vincent Buleetwa", gender: "M", field:"Electrical", date: "Aug/28/2001" ,type:"PERMANENT" },
{ reg_no:"452", name:"Amayo Johnson", gender: "M", field:"Civil", date: "Sep/20/2001" ,type:"PERMANENT" },
{ reg_no:"456", name:"Amonya Fred David (Dr.)", gender: "M", field:"Civil", date: "Jan/17/2002" ,type:"PERMANENT" },
{ reg_no:"458", name:"Ludigo Patrick James", gender: "M", field:"Civil", date: "Jan/23/2002" ,type:"PERMANENT" },
{ reg_no:"459", name:"Kasozi Ronald Malcolm", gender: "M", field:"Civil", date: "Jan/28/2002" ,type:"PERMANENT" },
{ reg_no:"460", name:"Tumwesigye John K (Dr.)", gender: "M", field:"Mechanical", date: "Jan/29/2002" ,type:"PERMANENT" },
{ reg_no:"461", name:"Mutikanga Harrison (Dr.)", gender: "M", field:"Civil", date: "Feb/04/2002" ,type:"PERMANENT" },
{ reg_no:"462", name:"Teklehaimanot Abraha", gender: "M", field:"Civil", date: "Feb/15/2002" ,type:"PERMANENT" },
{ reg_no:"463", name:"Kyankya Fred Guyina", gender: "M", field:"Mechanical", date: "Feb/25/2002" ,type:"PERMANENT" },
{ reg_no:"466", name:"Baringanire Paul", gender: "M", field:"Electrical", date: "May/21/2002" ,type:"PERMANENT" },
{ reg_no:"467", name:"Omara-Ogwang James Moses", gender: "M", field:"Electrical", date: "Jun/07/2002" ,type:"PERMANENT" },
{ reg_no:"468", name:"Luyimbazi David Ssali", gender: "M", field:"Civil", date: "Jul/12/2002" ,type:"PERMANENT" },
{ reg_no:"469", name:"Naluyinda Winfred", gender: "M", field:"Mechanical", date: "Jul/16/2002" ,type:"PERMANENT" },
{ reg_no:"470", name:"Rwanga Robert", gender: "M", field:"Civil", date: "Aug/09/2002" ,type:"PERMANENT" },
{ reg_no:"472", name:"Rubarenzya Mark Henry (Dr.)", gender: "M", field:"Civil", date: "Aug/21/2002" ,type:"PERMANENT" },
{ reg_no:"473", name:"Bekure Samson", gender: "M", field:"Civil", date: "Aug/27/2002" ,type:"PERMANENT" },
{ reg_no:"474", name:"Nkezza Barizene Angello", gender: "M", field:"Electrical", date: "Sep/04/2002" ,type:"PERMANENT" },
{ reg_no:"476", name:"Naita Charles", gender: "M", field:"Civil", date: "Oct/24/2002" ,type:"PERMANENT" },
{ reg_no:"477", name:"Kitaka Mubiru Andrew", gender: "M", field:"Civil", date: "Nov/06/2002" ,type:"PERMANENT" },
{ reg_no:"478", name:"Sebbit Adam Mohammed", gender: "M", field:"Mechanical", date: "Nov/25/2002" ,type:"PERMANENT" },
{ reg_no:"479", name:"Tugumisirize Caleb", gender: "M", field:"Civil", date: "Nov/25/2002" ,type:"PERMANENT" },
{ reg_no:"482", name:"Balimunsi Peter", gender: "M", field:"Electrical", date: "Jan/27/2003" ,type:"PERMANENT" },
{ reg_no:"483", name:"Mwerinde Bernard K", gender: "M", field:"Electrical", date: "Feb/17/2003" ,type:"PERMANENT" },
{ reg_no:"484", name:"Baliremwa Novati Mukajanga", gender: "M", field:"Civil", date: "Feb/25/2003" ,type:"PERMANENT" },
{ reg_no:"486", name:"Kayima John Kisa ", gender: "M", field:"Civil", date: "Feb/27/2003" ,type:"PERMANENT" },
{ reg_no:"487", name:"Katuramu Samson Kaita", gender: "M", field:"Civil", date: "Mar/17/2003" ,type:"PERMANENT" },
{ reg_no:"489", name:"Muwuluke Jimmy Zikusooka", gender: "M", field:"Civil", date: "Apr/28/2003" ,type:"PERMANENT" },
{ reg_no:"491", name:"Ssesanga Paul", gender: "M", field:"Civil", date: "Jun/04/2003" ,type:"PERMANENT" },
{ reg_no:"492", name:"Musoke Wilfred George", gender: "M", field:"Civil", date: "Jun/16/2003" ,type:"PERMANENT" },
{ reg_no:"493", name:"Kabanga Joseph Kiswa", gender: "M", field:"Civil", date: "Jun/20/2003" ,type:"PERMANENT" },
{ reg_no:"494", name:"Ghataura Jasbir Singh", gender: "M", field:"Civil", date: "Jun/20/2003" ,type:"PERMANENT" },
{ reg_no:"495", name:"Kaliba Paul", gender: "M", field:"Civil", date: "Jun/26/2003" ,type:"PERMANENT" },
{ reg_no:"496", name:"Bigirwa Nathan Amooti", gender: "M", field:"Civil", date: "Jun/30/2003" ,type:"PERMANENT" },
{ reg_no:"497", name:"Wetala Michael Mabonga", gender: "M", field:"Civil", date: "Jul/01/2003" ,type:"PERMANENT" },
{ reg_no:"499", name:"Mpango Addyson Eddy", gender: "M", field:"Civil", date: "Aug/28/2003" ,type:"PERMANENT" },
{ reg_no:"500", name:"Cong Richard", gender: "M", field:"Civil", date: "Aug/26/2003" ,type:"PERMANENT" },
{ reg_no:"501", name:"Kulata Paul Stephen", gender: "M", field:"Civil", date: "Aug/27/2003" ,type:"PERMANENT" },
{ reg_no:"503", name:"Otim Joseph", gender: "M", field:"Civil", date: "Sep/25/2003" ,type:"PERMANENT" },
{ reg_no:"505", name:"Bakaki Charles", gender: "M", field:"Civil", date: "Sep/30/2003" ,type:"PERMANENT" },
{ reg_no:"506", name:"Mulondo Saul", gender: "M", field:"Civil", date: "Oct/02/2003" ,type:"PERMANENT" },
{ reg_no:"507", name:"Kagoro Tusubira John Saviour", gender: "M", field:"Electrical", date: "Oct/07/2003" ,type:"PERMANENT" },
{ reg_no:"508", name:"Otoi Simon Peter", gender: "M", field:"Civil", date: "Oct/21/2003" ,type:"PERMANENT" },
{ reg_no:"511", name:"Okidi-Lating Peter (Dr.)", gender: "M", field:"Mechanical", date: "Oct/31/2003" ,type:"PERMANENT" },
{ reg_no:"513", name:"Ogwal Jimmy Bosco", gender: "M", field:"Civil", date: "Oct/24/2003" ,type:"PERMANENT" },
{ reg_no:"514", name:"Matua Richard", gender: "M", field:"Civil", date: "Jan/08/2004" ,type:"PERMANENT" },
{ reg_no:"515", name:"Manyindo Christopher R.", gender: "M", field:"Civil", date: "Jan/23/2004" ,type:"PERMANENT" },
{ reg_no:"516", name:"Ssozi Disan K", gender: "M", field:"Civil", date: "Jan/27/2004" ,type:"PERMANENT" },
{ reg_no:"517", name:"Tumusiime Christopher", gender: "M", field:"Civil", date: "Jan/27/2004" ,type:"PERMANENT" },
{ reg_no:"520", name:"Munyaami Masitula Male (Mrs.)", gender: "F", field:"Civil", date: "Apr/22/2004" ,type:"PERMANENT" },
{ reg_no:"521", name:"Bigabwa John", gender: "M", field:"Civil", date: "Apr/22/2004" ,type:"PERMANENT" },
{ reg_no:"522", name:"Ntale Henry Kayondo (Dr.)", gender: "M", field:"Civil", date: "Apr/22/2004" ,type:"PERMANENT" },
{ reg_no:"524", name:"Akankwasa Justus", gender: "M", field:"Civil", date: "May/26/2004" ,type:"PERMANENT" },
{ reg_no:"525", name:"Wazimbe Jonathan", gender: "M", field:"Civil", date: "Jan/02/2004" ,type:"PERMANENT" },
{ reg_no:"530", name:"Arebahona Ian Prots", gender: "M", field:"Civil", date: "Jul/02/2004" ,type:"PERMANENT" },
{ reg_no:"532", name:"Kimanzi Gilbert", gender: "M", field:"Civil", date: "Jul/23/2004" ,type:"PERMANENT" },
{ reg_no:"533", name:"Kabiiho Willy Dickens", gender: "M", field:"Civil", date: "Jul/29/2004" ,type:"PERMANENT" },
{ reg_no:"534", name:"Semitala Norbert", gender: "M", field:"Electrical", date: "Aug/19/2004" ,type:"PERMANENT" },
{ reg_no:"535", name:"Mutenyo Isaac (Dr.)", gender: "M", field:"Civil", date: "Aug/26/2004" ,type:"PERMANENT" },
{ reg_no:"536", name:"Kyamugambi Kasingye", gender: "M", field:"Civil", date: "Oct/29/2004" ,type:"PERMANENT" },
{ reg_no:"538", name:"Sebwato Cyrus", gender: "M", field:"Civil", date: "Nov/08/2004" ,type:"PERMANENT" },
{ reg_no:"539", name:"Byakika Patrick Frederick", gender: "M", field:"Civil", date: "Nov/22/2004" ,type:"PERMANENT" },
{ reg_no:"540", name:"Mbabazi Anania (Dr.)", gender: "M", field:"Civil", date: "Nov/24/2004" ,type:"PERMANENT" },
{ reg_no:"545", name:"Luswata Stephen Lugemwa", gender: "M", field:"Mechanical", date: "Dec/21/2004" ,type:"PERMANENT" },
{ reg_no:"546", name:"Odong Khalil Bakhit", gender: "M", field:"Civil", date: "Dec/21/2004" ,type:"PERMANENT" },
{ reg_no:"547", name:"Kahuma Adolf Kusemererwa", gender: "M", field:"Civil", date: "Dec/22/2004" ,type:"PERMANENT" },
{ reg_no:"548", name:"Kivumbi David Andrew K", gender: "M", field:"Civil", date: "Dec/28/2004" ,type:"PERMANENT" },
{ reg_no:"551", name:"Kasekende Seremba Martin", gender: "M", field:"Civil", date: "Feb/02/2005" ,type:"PERMANENT" },
{ reg_no:"552", name:"Kariko-Buhwezi Bernard (Dr.)", gender: "M", field:"Mechanical", date: "Feb/07/2005" ,type:"PERMANENT" },
{ reg_no:"553", name:"Rusongoza Patrick Kusemererwa", gender: "M", field:"Civil", date: "Mar/29/2005" ,type:"PERMANENT" },
{ reg_no:"554", name:"Nyabeeya Elicad Elly", gender: "M", field:"Civil", date: "Apr/04/2005" ,type:"PERMANENT" },
{ reg_no:"555", name:"Badaza Mohammed", gender: "M", field:"Civil", date: "Apr/14/2005" ,type:"PERMANENT" },
{ reg_no:"556", name:"Bagampadde Umaru (Dr.)", gender: "M", field:"Civil", date: "Apr/18/2005" ,type:"PERMANENT" },
{ reg_no:"558", name:"Mangali Robert", gender: "M", field:"Civil", date: "Apr/28/2005" ,type:"PERMANENT" },
{ reg_no:"559", name:"Watenga Stanley", gender: "M", field:"Civil", date: "Apr/29/2005" ,type:"PERMANENT" },
{ reg_no:"560", name:"Komakec Michael", gender: "M", field:"Civil", date: "May/02/2005" ,type:"PERMANENT" },
{ reg_no:"563", name:"Mawube Judah", gender: "M", field:"Mechanical", date: "May/19/2005" ,type:"PERMANENT" },
{ reg_no:"564", name:"Wakataama Stephen", gender: "M", field:"Civil", date: "May/22/2005" ,type:"PERMANENT" },
{ reg_no:"566", name:"Pulaparthi Murali Krishna", gender: "M", field:"Civil", date: "Jun/21/2005" ,type:"PERMANENT" },
{ reg_no:"567", name:"Olet Emmanuel", gender: "M", field:"Civil", date: "Jun/27/2005" ,type:"PERMANENT" },
{ reg_no:"568", name:"Kusemererwa Anthony", gender: "M", field:"Civil", date: "Jun/30/2005" ,type:"PERMANENT" },
{ reg_no:"569", name:"Mubiru Charles", gender: "M", field:"Civil", date: "Jul/25/2005" ,type:"PERMANENT" },
{ reg_no:"570", name:"Kajubi Lammeck (Dr.)", gender: "M", field:"Civil", date: "Jul/26/2005" ,type:"PERMANENT" },
{ reg_no:"571", name:"Mugambe Ronald Joseph M", gender: "M", field:"Civil", date: "Jul/29/2005" ,type:"PERMANENT" },
{ reg_no:"572", name:"Sentumbwe Ahmed", gender: "M", field:"Civil", date: "Aug/01/2005" ,type:"PERMANENT" },
{ reg_no:"573", name:"Droruga Nelson", gender: "M", field:"Civil", date: "Aug/08/2005" ,type:"PERMANENT" },
{ reg_no:"574", name:"Kaana Justus Dalton", gender: "M", field:"Electrical", date: "Aug/24/2005" ,type:"PERMANENT" },
{ reg_no:"575", name:"Nabbosa Betty Kajumba (Mrs.)", gender: "F", field:"Civil", date: "Aug/24/2005" ,type:"PERMANENT" },
{ reg_no:"576", name:"Nakiboneka Priscilla", gender: "F", field:"Civil", date: "Aug/24/2005" ,type:"PERMANENT" },
{ reg_no:"577", name:"Otemo Simon J.", gender: "M", field:"Civil", date: "Sep/26/2005" ,type:"PERMANENT" },
{ reg_no:"578", name:"Kakeeto Christina L. (Mrs.)", gender: "F", field:"Civil", date: "Sep/26/2005" ,type:"PERMANENT" },
{ reg_no:"579", name:"Muhenda Francis", gender: "M", field:"Civil", date: "Sep/27/2005" ,type:"PERMANENT" },
{ reg_no:"580", name:"Luguma Ayeya John", gender: "M", field:"Civil", date: "Oct/03/2005" ,type:"PERMANENT" },
{ reg_no:"581", name:"Higenyi John Malinga", gender: "M", field:"Civil", date: "Oct/10/2005" ,type:"PERMANENT" },
{ reg_no:"582", name:"Girukwishaka Remegie", gender: "M", field:"Civil", date: "Oct/12/2005" ,type:"PERMANENT" },
{ reg_no:"583", name:"Obetia Robert", gender: "M", field:"Civil", date: "Oct/17/2005" ,type:"PERMANENT" },
{ reg_no:"584", name:"Pario Lawrence", gender: "M", field:"Civil", date: "Oct/31/2005" ,type:"PERMANENT" },
{ reg_no:"585", name:"Sebuli Joseph Musoke", gender: "M", field:"Civil", date: "Nov/21/2005" ,type:"PERMANENT" },
{ reg_no:"586", name:"Obara Geoffrey", gender: "M", field:"Civil", date: "Nov/21/2005" ,type:"PERMANENT" },
{ reg_no:"587", name:"Jjunju Emmanuel", gender: "M", field:"Civil", date: "Dec/07/2005" ,type:"PERMANENT" },
{ reg_no:"588", name:"Isooba Julius", gender: "M", field:"Civil", date: "Dec/14/2005" ,type:"PERMANENT" },
{ reg_no:"589", name:"Bidasala-Igaga Henry Daniel M", gender: "M", field:"Electrical", date: "Jan/16/2006" ,type:"PERMANENT" },
{ reg_no:"591", name:"Kusiima Ernest Mike", gender: "M", field:"Electrical", date: "Jan/19/2006" ,type:"PERMANENT" },
{ reg_no:"594", name:"Ogwal Joseph Richard Oyie", gender: "M", field:"Civil", date: "Mar/01/2006" ,type:"PERMANENT" },
{ reg_no:"595", name:"Kamure Alfred Wandera", gender: "M", field:"Civil", date: "Mar/18/2006" ,type:"PERMANENT" },
{ reg_no:"596", name:"Kimezere Wilberforce", gender: "M", field:"Civil", date: "Apr/24/2006" ,type:"PERMANENT" },
{ reg_no:"598", name:"Lumonya Jacob Egondi", gender: "M", field:"Mechanical", date: "Apr/10/2006" ,type:"PERMANENT" },
{ reg_no:"599", name:"Isanga B. Thomas", gender: "M", field:"Civil", date: "Jun/19/2006" ,type:"PERMANENT" },
{ reg_no:"600", name:"Turihohabwe Alex", gender: "M", field:"Civil", date: "Jul/17/2006" ,type:"PERMANENT" },
{ reg_no:"601", name:"Drici Louis Ibiya", gender: "M", field:"Civil", date: "Jul/28/2006" ,type:"PERMANENT" },
{ reg_no:"602", name:"Kapkusum Robert Bartile", gender: "M", field:"Civil", date: "Aug/01/2006" ,type:"PERMANENT" },
{ reg_no:"603", name:"Naturinda Dans Nshekanabo", gender: "M", field:"Civil", date: "Aug/04/2006" ,type:"PERMANENT" },
{ reg_no:"604", name:"Yiga Lawrence", gender: "M", field:"Civil", date: "Aug/09/2006" ,type:"PERMANENT" },
{ reg_no:"605", name:"Mubiru Frederick", gender: "M", field:"Civil", date: "Aug/16/2006" ,type:"PERMANENT" },
{ reg_no:"606", name:"Okure Mackay Akoori Ecuman", gender: "M", field:"Mechanical", date: "Aug/16/2006" ,type:"PERMANENT" },
{ reg_no:"607", name:"Ssentaba Simon James", gender: "M", field:"Civil", date: "Aug/16/2006" ,type:"PERMANENT" },
{ reg_no:"608", name:"Kairania Maurice Robert", gender: "M", field:"Civil", date: "Oct/01/2006" ,type:"PERMANENT" },
{ reg_no:"609", name:"Drakuma Richard", gender: "M", field:"Mechanical", date: "Oct/17/2006" ,type:"PERMANENT" },
{ reg_no:"610", name:"Woffenden Robert David", gender: "M", field:"Civil", date: "Nov/17/2006" ,type:"PERMANENT" },
{ reg_no:"611", name:"Muzibira John Baptist L.", gender: "M", field:"Civil", date: "Dec/18/2006" ,type:"PERMANENT" },
{ reg_no:"612", name:"Ocan Bernard Nelson", gender: "M", field:"Civil", date: "Jan/05/2006" ,type:"PERMANENT" },
{ reg_no:"613", name:"Mukalazi Edward", gender: "M", field:"Civil", date: "Jan/09/2007" ,type:"PERMANENT" },
{ reg_no:"614", name:"Jemba Nicholas Seezi", gender: "M", field:"Civil", date: "Jan/15/2007" ,type:"PERMANENT" },
{ reg_no:"615", name:"Ochieng Lucas A. Nyandondo", gender: "M", field:"Civil", date: "Jan/22/2007" ,type:"PERMANENT" },
{ reg_no:"617", name:"Pande Michael Mabonga", gender: "M", field:"Civil", date: "Apr/26/2007" ,type:"PERMANENT" },
{ reg_no:"618", name:"Mukhwana Asuman Hyuha", gender: "M", field:"Civil", date: "May/04/2007" ,type:"PERMANENT" },
{ reg_no:"620", name:"Magezi Benon Denis", gender: "M", field:"Civil", date: "Jun/27/2007" ,type:"PERMANENT" },
{ reg_no:"621", name:"Tadhuba Andrew Nakikamba", gender: "M", field:"Civil", date: "Jul/08/2007" ,type:"PERMANENT" },
{ reg_no:"622", name:"Kibuuka Stephen", gender: "M", field:"Civil", date: "Sep/26/2007" ,type:"PERMANENT" },
{ reg_no:"623", name:"Kasana Isaac J.M.", gender: "M", field:"Electrical", date: "Oct/29/2007" ,type:"PERMANENT" },
{ reg_no:"624", name:"Abenaitwe Asaph T.", gender: "M", field:"Civil", date: "Oct/31/2007" ,type:"PERMANENT" },
{ reg_no:"625", name:"Ngabo Simon Peter", gender: "M", field:"Civil", date: "Dec/14/2007" ,type:"PERMANENT" },
{ reg_no:"626", name:"Bahanda Elias", gender: "M", field:"Electrical", date: "Dec/21/2007" ,type:"PERMANENT" },
{ reg_no:"628", name:"Semugooma David Rogers", gender: "M", field:"Civil", date: "Feb/28/2008" ,type:"PERMANENT" },
{ reg_no:"629", name:"Turyakira B. Kirya George", gender: "M", field:"Civil", date: "May/29/2008" ,type:"PERMANENT" },
{ reg_no:"630", name:"Ogwang Geoffrey", gender: "M", field:"Mechanical", date: "Jun/12/2008" ,type:"PERMANENT" },
{ reg_no:"631", name:"Olobo Benjamin", gender: "M", field:"Civil", date: "Jun/12/2008" ,type:"PERMANENT" },
{ reg_no:"632", name:"Katukiza Alex Yasoni (Dr.)", gender: "M", field:"Civil", date: "Jul/08/2008" ,type:"PERMANENT" },
{ reg_no:"633", name:"Gusolo Henry Namisi", gender: "M", field:"Civil", date: "Jul/24/2008" ,type:"PERMANENT" },
{ reg_no:"634", name:"Kirabira John Baptist (Dr.)", gender: "M", field:"Mechanical", date: "Jul/28/2008" ,type:"PERMANENT" },
{ reg_no:"635", name:"Kirungi Jackson Barnabus", gender: "M", field:"Civil", date: "Aug/08/2008" ,type:"PERMANENT" },
{ reg_no:"637", name:"Kagga Karim Kato ", gender: "M", field:"Civil", date: "Sep/12/2008" ,type:"PERMANENT" },
{ reg_no:"638", name:"Sempewo Jotham Ivan", gender: "M", field:"Civil", date: "Oct/15/2008" ,type:"PERMANENT" },
{ reg_no:"639", name:"Lwanga Frederick Kisooka", gender: "M", field:"Civil", date: "Oct/21/2008" ,type:"PERMANENT" },
{ reg_no:"640", name:"Ndangizi Allan Kagimba", gender: "M", field:"Mechanical", date: "Dec/03/2008" ,type:"PERMANENT" },
{ reg_no:"641", name:"Okello Geatano", gender: "M", field:"Civil", date: "Mar/26/2009" ,type:"PERMANENT" },
{ reg_no:"642", name:"Katongole Umar", gender: "M", field:"Civil", date: "Aug/24/2009" ,type:"PERMANENT" },
{ reg_no:"643", name:"Daka Michael", gender: "M", field:"Civil", date: "Aug/25/2009" ,type:"PERMANENT" },
{ reg_no:"645", name:"Atiire Victor", gender: "M", field:"Civil", date: "Aug/31/2008" ,type:"PERMANENT" },
{ reg_no:"646", name:"Mubangizi Kabikire Jackson", gender: "M", field:"Mechanical", date: "Aug/31/2008" ,type:"PERMANENT" },
{ reg_no:"647", name:"Byarugaba Silver", gender: "M", field:"Civil", date: "Sep/23/2009" ,type:"PERMANENT" },
{ reg_no:"648", name:"Nuwamanya Herbert", gender: "M", field:"Civil", date: "Oct/05/2009" ,type:"PERMANENT" },
{ reg_no:"650", name:"Kakiiza Robert Kagaba", gender: "M", field:"Civil", date: "Jan/05/2010" ,type:"PERMANENT" },
{ reg_no:"651", name:"Mugabe Jack", gender: "M", field:"Civil", date: "Feb/23/2010" ,type:"PERMANENT" },
{ reg_no:"652", name:"Nkuyahaga Innocent Acheng", gender: "M", field:"Civil", date: "Apr/06/2010" ,type:"PERMANENT" },
{ reg_no:"653", name:"Semyano Sarah", gender: "F", field:"Civil", date: "Jun/16/2010" ,type:"PERMANENT" },
{ reg_no:"654", name:"Turinawe Januarius B.", gender: "M", field:"Civil", date: "Jun/17/2010" ,type:"PERMANENT" },
{ reg_no:"655", name:"Wani Isaac L", gender: "M", field:"Civil", date: "Jul/26/2010" ,type:"PERMANENT" },
{ reg_no:"656", name:"Keeya Francis", gender: "M", field:"Mechanical", date: "Jul/27/2010" ,type:"PERMANENT" },
{ reg_no:"657", name:"Bagombeka Bunnet", gender: "M", field:"Civil", date: "Jul/30/2010" ,type:"PERMANENT" },
{ reg_no:"658", name:"Tumwine Francis Murangira", gender: "M", field:"Civil", date: "Sep/01/2010" ,type:"PERMANENT" },
{ reg_no:"659", name:"Kisira Daniel", gender: "M", field:"Electrical", date: "Oct/21/2010" ,type:"PERMANENT" },
{ reg_no:"660", name:"Birungi Ivan", gender: "M", field:"Civil", date: "Nov/04/2010" ,type:"PERMANENT" },
{ reg_no:"661", name:"Sserunkuuma Herbert", gender: "M", field:"Civil", date: "Nov/18/2010" ,type:"PERMANENT" },
{ reg_no:"662", name:"Ochwo Vincent Olie", gender: "M", field:"Mechanical", date: "Nov/19/2010" ,type:"PERMANENT" },
{ reg_no:"663", name:"Malaba Philip Davis", gender: "M", field:"Electrical", date: "Nov/25/2010" ,type:"PERMANENT" },
{ reg_no:"665", name:"Kagoda Andrew Festus", gender: "M", field:"Civil", date: "Dec/16/2010" ,type:"PERMANENT" },
{ reg_no:"666", name:"Barugahare Rodney B", gender: "M", field:"Civil", date: "Dec/16/2010" ,type:"PERMANENT" },
{ reg_no:"667", name:"Odong Francis Gimoro", gender: "M", field:"Mechanical", date: "Dec/21/2010" ,type:"PERMANENT" },
{ reg_no:"670", name:"Twesigye Ronald", gender: "M", field:"Civil", date: "Feb/01/2011" ,type:"PERMANENT" },
{ reg_no:"671", name:"Barongo Ronny", gender: "M", field:"Electrical", date: "Feb/03/2011" ,type:"PERMANENT" },
{ reg_no:"672", name:"Kiwu Ben Rogers", gender: "M", field:"Civil", date: "Feb/16/2011" ,type:"PERMANENT" },
{ reg_no:"673", name:"Wesigomwe Jamil Mohammed", gender: "M", field:"Civil", date: "Mar/16/2011" ,type:"PERMANENT" },
{ reg_no:"674", name:"Wabuna Richard Kaamu", gender: "M", field:"Civil", date: "Mar/21/2011" ,type:"PERMANENT" },
{ reg_no:"675", name:"Ssebirumbi Ronald", gender: "M", field:"Civil", date: "Mar/24/2011" ,type:"PERMANENT" },
{ reg_no:"676", name:"Mwebaze Denis Bakesigaki", gender: "M", field:"Civil", date: "Mar/28/2011" ,type:"PERMANENT" },
{ reg_no:"677", name:"Byaruhanga John William", gender: "M", field:"Civil", date: "May/04/2011" ,type:"PERMANENT" },
{ reg_no:"678", name:"Ssembata Patrick", gender: "M", field:"Civil", date: "May/10/2011" ,type:"PERMANENT" },
{ reg_no:"679", name:"Sseguya James", gender: "M", field:"Civil", date: "Jun/29/2011" ,type:"PERMANENT" },
{ reg_no:"680", name:"Ssentamu Hassan", gender: "M", field:"Civil", date: "Jul/21/2011" ,type:"PERMANENT" },
{ reg_no:"681", name:"Adrole Dennis", gender: "M", field:"Civil", date: "Jul/21/2011" ,type:"PERMANENT" },
{ reg_no:"682", name:"Tugume Jonathan", gender: "M", field:"Civil", date: "Jul/21/2011" ,type:"PERMANENT" },
{ reg_no:"683", name:"Alinaitwe Henry Mwanaki (Dr.)", gender: "M", field:"Civil", date: "Jul/22/2011" ,type:"PERMANENT" },
{ reg_no:"684", name:"Olweny Lamu", gender: "M", field:"Civil", date: "Jul/25/2011" ,type:"PERMANENT" },
{ reg_no:"685", name:"Ishungisa Henry Aine", gender: "M", field:"Electrical", date: "Aug/11/2011" ,type:"PERMANENT" },
{ reg_no:"686", name:"Abdon Atwine", gender: "M", field:"Electrical", date: "Aug/15/2011" ,type:"PERMANENT" },
{ reg_no:"687", name:"Twinomucunguzi Felix B.R.", gender: "M", field:"Civil", date: "Aug/31/2011" ,type:"PERMANENT" },
{ reg_no:"688", name:"Mutyaba Herbert", gender: "M", field:"Mechanical", date: "Oct/25/2011" ,type:"PERMANENT" },
{ reg_no:"689", name:"Kigumba Ronald Kenneth", gender: "M", field:"Electrical", date: "Oct/27/2011" ,type:"PERMANENT" },
{ reg_no:"690", name:"Ndizihiwe Denis", gender: "M", field:"Civil", date: "Nov/03/2011" ,type:"PERMANENT" },
{ reg_no:"691", name:"Ochola Michael Jackson", gender: "M", field:"Civil", date: "Nov/17/2011" ,type:"PERMANENT" },
{ reg_no:"692", name:"Kizito Henry Lwawuga", gender: "M", field:"Civil", date: "Nov/17/2011" ,type:"PERMANENT" },
{ reg_no:"693", name:"Adwek Makmot Jimmy", gender: "M", field:"Civil", date: "Nov/21/2011" ,type:"PERMANENT" },
{ reg_no:"694", name:"Kyakulaga, David Taligedha", gender: "M", field:"Civil", date: "Nov/30/2011" ,type:"PERMANENT" },
{ reg_no:"696", name:"Kidega Jude Clement", gender: "M", field:"Electrical", date: "Dec/21/2011" ,type:"PERMANENT" },
{ reg_no:"697", name:"Musazi Musa", gender: "M", field:"Electrical", date: "Dec/21/2011" ,type:"PERMANENT" },
{ reg_no:"698", name:"Kyobe Sozi Joel Muwanga", gender: "M", field:"Civil", date: "Dec/21/2011" ,type:"PERMANENT" },
{ reg_no:"699", name:"Muhoozi, Samuel", gender: "M", field:"Civil", date: "Dec/21/2011" ,type:"PERMANENT" },
{ reg_no:"700", name:"Mujunansi Patrick", gender: "M", field:"Civil", date: "Apr/03/2012" ,type:"PERMANENT" },
{ reg_no:"702", name:"Mubbala Timothy Noah", gender: "M", field:"Civil", date: "Jul/16/2012" ,type:"PERMANENT" },
{ reg_no:"703", name:"Mubangizi Buregyeya Jude", gender: "M", field:"Civil", date: "Jul/25/2012" ,type:"PERMANENT" },
{ reg_no:"704", name:"Muleme Patrick", gender: "M", field:"Civil", date: "Jul/27/2012" ,type:"PERMANENT" },
{ reg_no:"706", name:"Magembe Kenneth", gender: "M", field:"Civil", date: "Sep/20/2012" ,type:"PERMANENT" },
{ reg_no:"707", name:"Oboko Innocent Yotkum", gender: "M", field:"Civil", date: "Sep/27/2012" ,type:"PERMANENT" },
{ reg_no:"708", name:"Otwane Dan Etiange", gender: "M", field:"Civil", date: "Oct/03/2012" ,type:"PERMANENT" },
{ reg_no:"709", name:"Ogik Alfred", gender: "M", field:"Civil", date: "Oct/23/2012" ,type:"PERMANENT" },
{ reg_no:"710", name:"Isoke Samuel", gender: "M", field:"Mechanical", date: "Nov/06/2012" ,type:"PERMANENT" },
{ reg_no:"711", name:"Muhwezi Andrew", gender: "M", field:"Mechanical", date: "Nov/07/2012" ,type:"PERMANENT" },
{ reg_no:"712", name:"Nayoga Kisuule Ezra", gender: "M", field:"Civil", date: "Dec/12/2012" ,type:"PERMANENT" },
{ reg_no:"713", name:"Kalinaki Charles", gender: "M", field:"Civil", date: "Dec/19/2012" ,type:"PERMANENT" },
{ reg_no:"714", name:"Nyende Hussien Hassan", gender: "M", field:"Civil", date: "Jan/17/2013" ,type:"PERMANENT" },
{ reg_no:"715", name:"Male Anthony Victor", gender: "M", field:"Civil", date: "Jan/17/2013" ,type:"PERMANENT" },
{ reg_no:"716", name:"Munyambanza Constant", gender: "M", field:"Civil", date: "Jan/17/2013" ,type:"PERMANENT" },
{ reg_no:"717", name:"Kafifi Wilson", gender: "M", field:"Civil", date: "Jan/21/2013" ,type:"PERMANENT" },
{ reg_no:"718", name:"Komakech Gerald", gender: "M", field:"Civil", date: "Jan/21/2013" ,type:"PERMANENT" },
{ reg_no:"719", name:"Mudanye Michael", gender: "M", field:"Civil", date: "Feb/11/2013" ,type:"PERMANENT" },
{ reg_no:"720", name:"Rwakasanga Gershom Kateera", gender: "M", field:"Mechanical", date: "Feb/25/2013" ,type:"PERMANENT" },
{ reg_no:"721", name:"Kibirango Joseph", gender: "M", field:"Civil", date: "Feb/25/2013" ,type:"PERMANENT" },
{ reg_no:"722", name:"Mayanja Michael", gender: "M", field:"Civil", date: "Feb/28/2013" ,type:"PERMANENT" },
{ reg_no:"723", name:"Omiat Albert Fidel", gender: "M", field:"Civil", date: "Feb/28/2013" ,type:"PERMANENT" },
{ reg_no:"724", name:"Kakube Dennis", gender: "M", field:"Civil", date: "Mar/11/2013" ,type:"PERMANENT" },
{ reg_no:"725", name:"Arinaitwe Mutahindukah Isaac J.", gender: "M", field:"Civil", date: "Mar/15/2013" ,type:"PERMANENT" },
{ reg_no:"726", name:"Niwagaba Silver", gender: "M", field:"Civil", date: "Mar/22/2013" ,type:"PERMANENT" },
{ reg_no:"727", name:"Twinamatsiko Emmanuel", gender: "M", field:"Civil", date: "Mar/22/2013" ,type:"PERMANENT" },
{ reg_no:"728", name:"Mwesigwa Emmanuel", gender: "M", field:"Civil", date: "Mar/25/2013" ,type:"PERMANENT" },
{ reg_no:"729", name:"Kaggwa Fred Kayondo", gender: "M", field:"Civil", date: "Mar/25/2013" ,type:"PERMANENT" },
{ reg_no:"730", name:"Mukiibi Joseph Kiwanuka", gender: "M", field:"Civil", date: "Mar/27/2013" ,type:"PERMANENT" },
{ reg_no:"731", name:"Kato Paul", gender: "M", field:"Civil", date: "Apr/15/2013" ,type:"PERMANENT" },
{ reg_no:"732", name:"Liiki Samuel", gender: "M", field:"Civil", date: "May/09/2013" ,type:"PERMANENT" },
{ reg_no:"733", name:"Nyende Byakika Stephen", gender: "M", field:"Civil", date: "May/09/2013" ,type:"PERMANENT" },
{ reg_no:"734", name:"Wanyama Geoffrey Andrew", gender: "M", field:"Civil", date: "May/17/2013" ,type:"PERMANENT" },
{ reg_no:"735", name:"Kasibante George", gender: "M", field:"Civil", date: "May/17/2013" ,type:"PERMANENT" },
{ reg_no:"736", name:"Namara Juliet Jessie", gender: "F", field:"Civil", date: "May/17/2013" ,type:"PERMANENT" },
{ reg_no:"737", name:"Walyemwa James", gender: "M", field:"Civil", date: "May/17/2013" ,type:"PERMANENT" },
{ reg_no:"738", name:"Seninde Stephen", gender: "M", field:"Civil", date: "May/17/2013" ,type:"PERMANENT" },
{ reg_no:"739", name:"Greene Philip Macdonald", gender: "M", field:"Civil", date: "Jun/12/2013" ,type:"PERMANENT" },
{ reg_no:"740", name:"Kisitu Simon Peter", gender: "M", field:"Civil", date: "Jun/26/2013" ,type:"PERMANENT" },
{ reg_no:"741", name:"Serunjogi Steven", gender: "M", field:"Civil", date: "Apr/03/2014" ,type:"PERMANENT" },
{ reg_no:"742", name:"Kariisa JohnBosco Koy", gender: "M", field:"Mechanical", date: "Apr/03/2014" ,type:"PERMANENT" },
{ reg_no:"743", name:"Mugenyi Richard Tonny", gender: "M", field:"Civil", date: "Apr/03/2014" ,type:"PERMANENT" },
{ reg_no:"744", name:"Debuni Joseph", gender: "M", field:"Civil", date: "Apr/03/2014" ,type:"PERMANENT" },
{ reg_no:"745", name:"Sematimba Ivan", gender: "M", field:"Civil", date: "Apr/07/2014" ,type:"PERMANENT" },
{ reg_no:"746", name:"Kyemba Ben", gender: "M", field:"Civil", date: "Apr/11/2014" ,type:"PERMANENT" },
{ reg_no:"747", name:"Odongo Frederick Obala", gender: "M", field:"Mechanical", date: "Apr/22/2014" ,type:"PERMANENT" },
{ reg_no:"749", name:"Jagwe Wycliff", gender: "M", field:"Electrical", date: "May/22/2014" ,type:"PERMANENT" },
{ reg_no:"750", name:"Senabulya Mugabi Timothy", gender: "M", field:"Mechanical", date: "Jun/27/2014" ,type:"PERMANENT" },
{ reg_no:"751", name:"Katusabe Chrisptopher", gender: "M", field:"Mechanical", date: "Jul/07/2014" ,type:"PERMANENT" },
{ reg_no:"752", name:"Kiyimba Florence Lubwama (Dr.)", gender: "F", field:"Agricultural", date: "Jul/08/2014" ,type:"PERMANENT" },
{ reg_no:"754", name:"Hashakimana Godfrey", gender: "M", field:"Civil", date: "Oct/13/2014" ,type:"PERMANENT" },
{ reg_no:"755", name:"Masuba Ivan", gender: "M", field:"Civil", date: "Oct/16/2014" ,type:"PERMANENT" },
{ reg_no:"756", name:"Mukunyu Timothy", gender: "M", field:"Civil", date: "Oct/17/2014" ,type:"PERMANENT" },
{ reg_no:"757", name:"Etoko Patrick", gender: "M", field:"Civil", date: "Oct/20/2014" ,type:"PERMANENT" },
{ reg_no:"758", name:"Kasujja Matiya Godfrey", gender: "M", field:"Civil", date: "Nov/11/2014" ,type:"PERMANENT" },
{ reg_no:"759", name:"Kaddu David", gender: "M", field:"Civil", date: "Nov/11/2014" ,type:"PERMANENT" },
{ reg_no:"760", name:"Namugera Ronald", gender: "M", field:"Civil", date: "Nov/13/2014" ,type:"PERMANENT" },
{ reg_no:"761", name:"Oleja Albert", gender: "M", field:"Civil", date: "Nov/14/2014" ,type:"PERMANENT" },
{ reg_no:"762", name:"Musooka George", gender: "M", field:"Mechanical", date: "Nov/20/2014" ,type:"PERMANENT" },
{ reg_no:"763", name:"Kasawuli John Kennedy Kasumba", gender: "M", field:"Civil", date: "Nov/20/2014" ,type:"PERMANENT" },
{ reg_no:"764", name:"Kasango Fred", gender: "M", field:"Civil", date: "Feb/04/2015" ,type:"PERMANENT" },
{ reg_no:"765", name:"Jjingo Kizito", gender: "M", field:"Civil", date: "Feb/04/2015" ,type:"PERMANENT" },
{ reg_no:"766", name:"Turyagyenda John Abouf", gender: "M", field:"Electrical", date: "May/05/2015" ,type:"PERMANENT" },
{ reg_no:"767", name:"Were Happy Moses", gender: "M", field:"Civil", date: "Feb/06/2015" ,type:"PERMANENT" },
{ reg_no:"768", name:"Ssemakula Richard Mutyaba", gender: "M", field:"Civil", date: "Feb/09/2015" ,type:"PERMANENT" },
{ reg_no:"769", name:"Nakasenge Deborah Nuwagaba", gender: "F", field:"Civil", date: "Feb/11/2015" ,type:"PERMANENT" },
{ reg_no:"770", name:"Kagga Babirye Mariam", gender: "F", field:"Civil", date: "Feb/12/2015" ,type:"PERMANENT" },
{ reg_no:"771", name:"Okello Dominic Louis P'Abur", gender: "M", field:"Civil", date: "Feb/12/2015" ,type:"PERMANENT" },
{ reg_no:"772", name:"Tibesigwa Timothy", gender: "M", field:"Mechanical", date: "Apr/08/2015" ,type:"PERMANENT" },
{ reg_no:"773", name:"Matovu Ishaaka Musisi", gender: "M", field:"Civil", date: "May/06/2021" ,type:"PERMANENT" },
{ reg_no:"774", name:"Mbadhwe John (Dr.)", gender: "M", field:"Civil", date: "Apr/09/2015" ,type:"PERMANENT" },
{ reg_no:"775", name:"Adubango Brand", gender: "M", field:"Civil", date: "Apr/20/2015" ,type:"PERMANENT" },
{ reg_no:"776", name:"Kiyimba Daniel Ssuuna", gender: "M", field:"Civil", date: "Apr/20/2015" ,type:"PERMANENT" },
{ reg_no:"777", name:"Tumushabe Kajuba Innocent", gender: "M", field:"Civil", date: "Apr/21/2015" ,type:"PERMANENT" },
{ reg_no:"778", name:"Bongire Jackson", gender: "M", field:"Civil", date: "Apr/24/2015" ,type:"PERMANENT" },
{ reg_no:"779", name:"Naimanye Andrew Grace (Dr.)", gender: "M", field:"Civil", date: "Apr/27/2015" ,type:"PERMANENT" },
{ reg_no:"780", name:"Kibera Gloria Guma", gender: "F", field:"Civil", date: "Apr/29/2015" ,type:"PERMANENT" },
{ reg_no:"781", name:"Okaronon Edmond", gender: "M", field:"Civil", date: "May/05/2015" ,type:"PERMANENT" },
{ reg_no:"782", name:"Mugisha Ronald", gender: "M", field:"Civil", date: "May/11/2015" ,type:"PERMANENT" },
{ reg_no:"783", name:"Alito George", gender: "M", field:"Civil", date: "Aug/08/2015" ,type:"PERMANENT" },
{ reg_no:"784", name:"Kweronda Frank Katusiime", gender: "M", field:"Civil", date: "Aug/10/2015" ,type:"PERMANENT" },
{ reg_no:"785", name:"Wacoo Fred", gender: "M", field:"Civil", date: "Aug/10/2015" ,type:"PERMANENT" },
{ reg_no:"786", name:"Kitayimbwa Martin", gender: "M", field:"Civil", date: "Aug/11/2015" ,type:"PERMANENT" },
{ reg_no:"787", name:"Biyomotho Jimmy", gender: "M", field:"Civil", date: "Aug/14/2015" ,type:"PERMANENT" },
{ reg_no:"788", name:"Rutaagi Joseph", gender: "M", field:"Civil", date: "Aug/14/2015" ,type:"PERMANENT" },
{ reg_no:"789", name:"Mugabi Allan", gender: "M", field:"Civil", date: "Aug/17/2015" ,type:"PERMANENT" },
{ reg_no:"790", name:"Akita Chad Silas", gender: "M", field:"Civil", date: "Aug/17/2015" ,type:"PERMANENT" },
{ reg_no:"791", name:"Dhizaala Tom", gender: "M", field:"Civil", date: "Aug/17/2015" ,type:"PERMANENT" },
{ reg_no:"792", name:"Kawuki Gonza Patrick", gender: "M", field:"Electrical", date: "Aug/17/2015" ,type:"PERMANENT" },
{ reg_no:"793", name:"Okello Kabagaju Dorothy (Dr.)", gender: "F", field:"Electrical", date: "Dec/08/2015" ,type:"PERMANENT" },
{ reg_no:"794", name:"Okello Geoffrey Levi", gender: "M", field:"Civil", date: "Dec/08/2015" ,type:"PERMANENT" },
{ reg_no:"795", name:"Opige Johnson", gender: "M", field:"Civil", date: "Dec/11/2015" ,type:"PERMANENT" },
{ reg_no:"796", name:"Yohannes Mesfin", gender: "M", field:"Civil", date: "Jan/05/2016" ,type:"PERMANENT" },
{ reg_no:"797", name:"Nkurunziza Allan Gilbert", gender: "M", field:"Civil", date: "Feb/10/2016" ,type:"PERMANENT" },
{ reg_no:"798", name:"Kasaata Edison", gender: "M", field:"Civil", date: "Mar/07/2016" ,type:"PERMANENT" },
{ reg_no:"799", name:"Manji Dominic", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"800", name:"Kamoga Vincent", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"801", name:"Mukwaya Nelson Isaiah", gender: "M", field:"Electrical", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"802", name:"Ojok Patrick", gender: "M", field:"Electrical", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"803", name:"Aleper Titus", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"805", name:"Gutto Flavia Lillian", gender: "F", field:"Electrical", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"806", name:"Abigaba Cuthburt Mirembe", gender: "M", field:"Electrical", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"807", name:"Otim Daniel", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"808", name:"Asingwire Myers", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"809", name:"Musoke Herbert Darren", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"810", name:"Lwanga Apollo", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"811", name:"Mutyaba Robert", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"812", name:"Serwadda Isaac", gender: "M", field:"Electrical", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"813", name:"Kahonaho Alfred Tugume", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"814", name:"Wanjusi Febiano", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"815", name:"Okwel Moses", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"816", name:"Sserunjogi Andrew Mukiibi", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"817", name:"Kitamirike Gabula Edward", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"818", name:"Jabweli Jacob", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"819", name:"Tumwine William Sharpe", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"820", name:"Akello Esther", gender: "F", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"821", name:"Kaahwa David", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"822", name:"Ngabirano Deneth", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"823", name:"Waneloba Francis", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"824", name:"Mubialiwo Ambrose", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"825", name:"Bagyenda Drake", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"826", name:"Odong Justine", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"827", name:"Mwanje Enoch", gender: "M", field:"Civil", date: "Mar/09/2016" ,type:"PERMANENT" },
{ reg_no:"828", name:"Kyobe Victor", gender: "M", field:"Civil", date: "Mar/10/2016" ,type:"PERMANENT" },
{ reg_no:"829", name:"Tibalwa Ziria Waako", gender: "F", field:"Electrical", date: "Mar/10/2016" ,type:"PERMANENT" },
{ reg_no:"830", name:"Ngabirano Julius", gender: "M", field:"Civil", date: "Mar/10/2016" ,type:"PERMANENT" },
{ reg_no:"831", name:"Osele Patrick", gender: "M", field:"Civil", date: "Mar/10/2016" ,type:"PERMANENT" },
{ reg_no:"832", name:"Aziku Diana", gender: "F", field:"Civil", date: "Mar/11/2016" ,type:"PERMANENT" },
{ reg_no:"833", name:"Busiinge Mike Douglas", gender: "M", field:"Civil", date: "Mar/11/2016" ,type:"PERMANENT" },
{ reg_no:"834", name:"Lubega Henry Andrew Tondo", gender: "M", field:"Civil", date: "Mar/11/2016" ,type:"PERMANENT" },
{ reg_no:"835", name:"Kakwezi, Allan", gender: "M", field:"Civil", date: "Mar/14/2016" ,type:"PERMANENT" },
{ reg_no:"836", name:"Bukenya Nuludin", gender: "M", field:"Civil", date: "Mar/14/2016" ,type:"PERMANENT" },
{ reg_no:"837", name:"Makiika Mwidu George", gender: "M", field:"Civil", date: "Mar/14/2016" ,type:"PERMANENT" },
{ reg_no:"838", name:"Okwong Gilbert Okonye", gender: "M", field:"Civil", date: "Mar/15/2016" ,type:"PERMANENT" },
{ reg_no:"839", name:"Lukungu Hussein", gender: "M", field:"Civil", date: "Mar/15/2016" ,type:"PERMANENT" },
{ reg_no:"840", name:"Muhumuza Ronald", gender: "M", field:"Mechanical", date: "Mar/15/2016" ,type:"PERMANENT" },
{ reg_no:"841", name:"Musinguzi Begumisa", gender: "M", field:"Civil", date: "Mar/16/2016" ,type:"PERMANENT" },
{ reg_no:"842", name:"Mugisha Edmond", gender: "M", field:"Civil", date: "Mar/17/2016" ,type:"PERMANENT" },
{ reg_no:"843", name:"Ssebanenya Kyobe Ronald", gender: "M", field:"Civil", date: "Mar/17/2016" ,type:"PERMANENT" },
{ reg_no:"844", name:"Meri Tony Carlos", gender: "M", field:"Civil", date: "Mar/17/2016" ,type:"PERMANENT" },
{ reg_no:"845", name:"Sseremba Raymond", gender: "M", field:"Civil", date: "Mar/17/2016" ,type:"PERMANENT" },
{ reg_no:"846", name:"Muwumuza Linda", gender: "F", field:"Mechanical", date: "Mar/22/2016" ,type:"PERMANENT" },
{ reg_no:"847", name:"Miiro James Maiteki", gender: "M", field:"Civil", date: "Mar/23/2016" ,type:"PERMANENT" },
{ reg_no:"848", name:"Asio Lydia", gender: "F", field:"Civil", date: "Mar/29/2016" ,type:"PERMANENT" },
{ reg_no:"849", name:"Haruna Juma", gender: "M", field:"Civil", date: "Mar/30/2016" ,type:"PERMANENT" },
{ reg_no:"850", name:"Okello Ronald Daniel", gender: "M", field:"Civil", date: "Apr/07/2016" ,type:"PERMANENT" },
{ reg_no:"851", name:"Balamu Isaac", gender: "M", field:"Civil", date: "Apr/08/2016" ,type:"PERMANENT" },
{ reg_no:"852", name:"Lutaaya Fred", gender: "M", field:"Mechanical", date: "Apr/11/2016" ,type:"PERMANENT" },
{ reg_no:"853", name:"Namuteete Andrew", gender: "M", field:"Civil", date: "Apr/11/2016" ,type:"PERMANENT" },
{ reg_no:"854", name:"Wasswa Joel", gender: "M", field:"Civil", date: "Apr/12/2016" ,type:"PERMANENT" },
{ reg_no:"855", name:"Namuli Rachel (Dr.)", gender: "F", field:"Electrical", date: "Apr/12/2016" ,type:"PERMANENT" },
{ reg_no:"856", name:"Mwalwega Beatrice", gender: "F", field:"Civil", date: "Apr/12/2016" ,type:"PERMANENT" },
{ reg_no:"857", name:"Mutetweka George Tusingwire", gender: "M", field:"Mechanical", date: "Apr/13/2016" ,type:"PERMANENT" },
{ reg_no:"858", name:"Mpuuga Henry", gender: "M", field:"Mechanical", date: "Apr/13/2016" ,type:"PERMANENT" },
{ reg_no:"859", name:"Namuwonge Harriet", gender: "F", field:"Civil", date: "Apr/15/2016" ,type:"PERMANENT" },
{ reg_no:"860", name:"Tuliraba Frederick Daniel", gender: "M", field:"Civil", date: "Apr/18/2016" ,type:"PERMANENT" },
{ reg_no:"861", name:"Kwizera Edrisa", gender: "M", field:"Mechanical", date: "Apr/18/2016" ,type:"PERMANENT" },
{ reg_no:"862", name:"Kabanda Augustine", gender: "M", field:"Civil", date: "Apr/18/2016" ,type:"PERMANENT" },
{ reg_no:"863", name:"Tumwesige Robert", gender: "M", field:"Civil", date: "Apr/18/2016" ,type:"PERMANENT" },
{ reg_no:"864", name:"Luyinda Moses", gender: "M", field:"Civil", date: "Apr/19/2016" ,type:"PERMANENT" },
{ reg_no:"865", name:"Nawasswa Jackson Mbulaate", gender: "M", field:"Civil", date: "Apr/19/2016" ,type:"PERMANENT" },
{ reg_no:"866", name:"Aomu Cyrus Titus", gender: "M", field:"Civil", date: "Apr/20/2016" ,type:"PERMANENT" },
{ reg_no:"867", name:"Wasike Fredrick", gender: "M", field:"Civil", date: "Apr/20/2016" ,type:"PERMANENT" },
{ reg_no:"868", name:"Kalema John Casmir", gender: "M", field:"Electrical", date: "Apr/20/2016" ,type:"PERMANENT" },
{ reg_no:"869", name:"Mutungi Sylvia Nakakande", gender: "F", field:"Civil", date: "Apr/28/2016" ,type:"PERMANENT" },
{ reg_no:"870", name:"Olaki Ronald", gender: "M", field:"Civil", date: "Apr/28/2016" ,type:"PERMANENT" },
{ reg_no:"871", name:"Male Richard Mukasa", gender: "M", field:"Civil", date: "Apr/28/2016" ,type:"PERMANENT" },
{ reg_no:"872", name:"Omoding Nicholas", gender: "M", field:"Civil", date: "May/06/2016" ,type:"PERMANENT" },
{ reg_no:"873", name:"Ogwang Charles ", gender: "M", field:"Electrical", date: "May/09/2016" ,type:"PERMANENT" },
{ reg_no:"874", name:"Tibaijuka Godwin", gender: "M", field:"Civil", date: "May/09/2016" ,type:"PERMANENT" },
{ reg_no:"875", name:"Ssemaganda Mathias", gender: "M", field:"Electrical", date: "May/16/2016" ,type:"PERMANENT" },
{ reg_no:"876", name:"Kalanzi Adam", gender: "M", field:"Mechanical", date: "May/16/2016" ,type:"PERMANENT" },
{ reg_no:"877", name:"Ogwete Steven", gender: "M", field:"Agricultural", date: "May/16/2016" ,type:"PERMANENT" },
{ reg_no:"878", name:"Ocet Tonny", gender: "M", field:"Civil", date: "May/16/2016" ,type:"PERMANENT" },
{ reg_no:"879", name:"Mutabazi Hillary", gender: "M", field:"Civil", date: "May/17/2016" ,type:"PERMANENT" },
{ reg_no:"880", name:"Ssebagala Geoffrey Kisitu", gender: "M", field:"Mechanical", date: "May/20/2016" ,type:"PERMANENT" },
{ reg_no:"881", name:"Balaza Timothy", gender: "M", field:"Civil", date: "May/23/2016" ,type:"PERMANENT" },
{ reg_no:"882", name:"Menya Cecilia Nakiranda", gender: "F", field:"Electrical", date: "May/24/2016" ,type:"PERMANENT" },
{ reg_no:"883", name:"Amaca Santos Okello", gender: "M", field:"Civil", date: "May/24/2016" ,type:"PERMANENT" },
{ reg_no:"884", name:"Oidu Kizito Franklin", gender: "M", field:"Electrical", date: "May/25/2016" ,type:"PERMANENT" },
{ reg_no:"885", name:"Mukombe Ronald", gender: "M", field:"Civil", date: "May/30/2016" ,type:"PERMANENT" },
{ reg_no:"886", name:"Senengo Joshua", gender: "M", field:"Civil", date: "Jun/08/2016" ,type:"PERMANENT" },
{ reg_no:"887", name:"Ssempebwa Ronald", gender: "M", field:"Mechanical", date: "Jun/08/2016" ,type:"PERMANENT" },
{ reg_no:"888", name:"Kiyingi Denis", gender: "M", field:"Mechanical", date: "Jun/08/2016" ,type:"PERMANENT" },
{ reg_no:"889", name:"Nuwagaba Fred", gender: "M", field:"Civil", date: "Jun/08/2016" ,type:"PERMANENT" },
{ reg_no:"890", name:"Nakonde Zaituni", gender: "F", field:"Civil", date: "Jun/10/2016" ,type:"PERMANENT" },
{ reg_no:"891", name:"Enzama Godfrey Addison", gender: "M", field:"Civil", date: "Jun/10/2016" ,type:"PERMANENT" },
{ reg_no:"892", name:"Nsamba Leandro Rogers", gender: "M", field:"Electrical", date: "Jun/10/2016" ,type:"PERMANENT" },
{ reg_no:"893", name:"Katalo Ronald", gender: "M", field:"Civil", date: "Jun/10/2016" ,type:"PERMANENT" },
{ reg_no:"894", name:"Senoga Moses", gender: "M", field:"Civil", date: "Jun/13/2016" ,type:"PERMANENT" },
{ reg_no:"895", name:"Atukunda Sandra", gender: "F", field:"Civil", date: "Jun/13/2016" ,type:"PERMANENT" },
{ reg_no:"896", name:"Ahumuza Ezra", gender: "M", field:"Civil", date: "Jun/13/2016" ,type:"PERMANENT" },
{ reg_no:"897", name:"Kaweesa Paul", gender: "M", field:"Civil", date: "Jun/13/2016" ,type:"PERMANENT" },
{ reg_no:"898", name:"Bujure Moses Godfrey", gender: "M", field:"Civil", date: "Jun/14/2016" ,type:"PERMANENT" },
{ reg_no:"899", name:"Nyakana Peter", gender: "M", field:"Civil", date: "Jun/14/2016" ,type:"PERMANENT" },
{ reg_no:"900", name:"Senfuka Christopher", gender: "M", field:"Mechanical", date: "Jun/14/2016" ,type:"PERMANENT" },
{ reg_no:"901", name:"Odoch Simon Peter", gender: "M", field:"Civil", date: "Jun/24/2016" ,type:"PERMANENT" },
{ reg_no:"902", name:"Tiyo William Taylor Odaa", gender: "M", field:"Civil", date: "Jul/08/2016" ,type:"PERMANENT" },
{ reg_no:"904", name:"Onyok Patrick", gender: "M", field:"Civil", date: "Jul/14/2016" ,type:"PERMANENT" },
{ reg_no:"905", name:"Nakiyingi Rebecca", gender: "F", field:"Civil", date: "Jul/14/2016" ,type:"PERMANENT" },
{ reg_no:"906", name:"Sajja Ssali Godfrey", gender: "M", field:"Mechanical", date: "Jul/14/2016" ,type:"PERMANENT" },
{ reg_no:"907", name:"Kamanyi Joe", gender: "M", field:"Civil", date: "Jul/14/2016" ,type:"PERMANENT" },
{ reg_no:"908", name:"Magoba Frank", gender: "M", field:"Electrical", date: "Jul/14/2016" ,type:"PERMANENT" },
{ reg_no:"909", name:"Nanfuka Betty Christine", gender: "F", field:"Civil", date: "Jul/14/2016" ,type:"PERMANENT" },
{ reg_no:"910", name:"Epiot Michael", gender: "M", field:"Civil", date: "Jul/14/2016" ,type:"PERMANENT" },
{ reg_no:"911", name:"Kisauzi Carolyne Namagga", gender: "F", field:"Civil", date: "Jul/15/2016" ,type:"PERMANENT" },
{ reg_no:"912", name:"Semuwemba James (Dr.)", gender: "M", field:"Civil", date: "Jul/15/2016" ,type:"PERMANENT" },
{ reg_no:"913", name:"Balemezi Ndugwa Solomon", gender: "M", field:"Civil", date: "Jul/15/2016" ,type:"PERMANENT" },
{ reg_no:"914", name:"Kwiri Peter", gender: "M", field:"Civil", date: "Jul/15/2016" ,type:"PERMANENT" },
{ reg_no:"915", name:"Mutyaba Twaib", gender: "M", field:"Civil", date: "Jul/15/2016" ,type:"PERMANENT" },
{ reg_no:"916", name:"Musinguzi Julius", gender: "M", field:"Civil", date: "Jul/18/2016" ,type:"PERMANENT" },
{ reg_no:"917", name:"Alunyu Denis", gender: "M", field:"Civil", date: "Jul/18/2016" ,type:"PERMANENT" },
{ reg_no:"918", name:"Bamuleseyo Mukabane Angella", gender: "M", field:"Electrical", date: "Jul/18/2016" ,type:"PERMANENT" },
{ reg_no:"919", name:"Lukindu John", gender: "M", field:"Civil", date: "Jul/19/2016" ,type:"PERMANENT" },
{ reg_no:"920", name:"Kisira Samuel Edward", gender: "M", field:"Civil", date: "Jul/19/2016" ,type:"PERMANENT" },
{ reg_no:"921", name:"Kitimba Philip Patrick", gender: "M", field:"Civil", date: "Jul/20/2016" ,type:"PERMANENT" },
{ reg_no:"922", name:"Tumusiime Dennis", gender: "M", field:"Civil", date: "Jul/20/2016" ,type:"PERMANENT" },
{ reg_no:"923", name:"Otim Alex P'Okene", gender: "M", field:"Civil", date: "Jul/21/2016" ,type:"PERMANENT" },
{ reg_no:"924", name:"Mukundane Micheal", gender: "M", field:"Civil", date: "Jul/21/2016" ,type:"PERMANENT" },
{ reg_no:"925", name:"Muhumuza Bagonza Allan C", gender: "M", field:"Civil", date: "Jul/25/2016" ,type:"PERMANENT" },
{ reg_no:"926", name:"Suda Samuel Nelson", gender: "M", field:"Civil", date: "Jul/30/2016" ,type:"PERMANENT" },
{ reg_no:"927", name:"Abonyo Rebecca", gender: "F", field:"Civil", date: "Aug/10/2016" ,type:"PERMANENT" },
{ reg_no:"928", name:"Nsubuga Jacob", gender: "M", field:"Civil", date: "Aug/11/2016" ,type:"PERMANENT" },
{ reg_no:"929", name:"Wilfred Okello", gender: "M", field:"Civil", date: "Aug/16/2016" ,type:"PERMANENT" },
{ reg_no:"930", name:"Atuhairwe Rodney", gender: "M", field:"Civil", date: "Aug/16/2016" ,type:"PERMANENT" },
{ reg_no:"931", name:"Katende Jack", gender: "M", field:"Civil", date: "Aug/17/2016" ,type:"PERMANENT" },
{ reg_no:"932", name:"Mulindwa Imaam Kyeyune", gender: "M", field:"Civil", date: "Aug/17/2016" ,type:"PERMANENT" },
{ reg_no:"933", name:"Kemigisha Catherine", gender: "F", field:"Civil", date: "Aug/17/2016" ,type:"PERMANENT" },
{ reg_no:"934", name:"Obwor Emmanuel", gender: "M", field:"Civil", date: "Aug/18/2016" ,type:"PERMANENT" },
{ reg_no:"935", name:"Niyoyita Phocas", gender: "M", field:"Civil", date: "Aug/19/2016" ,type:"PERMANENT" },
{ reg_no:"936", name:"Mucunguzi Dominic Banaga", gender: "M", field:"Agricultural", date: "Aug/19/2016" ,type:"PERMANENT" },
{ reg_no:"937", name:"Bihemaiso Godfrey", gender: "M", field:"Civil", date: "Aug/19/2016" ,type:"PERMANENT" },
{ reg_no:"938", name:"Mpora Asaph Koshaho", gender: "M", field:"Civil", date: "Aug/19/2016" ,type:"PERMANENT" },
{ reg_no:"939", name:"Okitoi Jackson", gender: "M", field:"Civil", date: "Aug/22/2016" ,type:"PERMANENT" },
{ reg_no:"940", name:"Otim George", gender: "M", field:"Civil", date: "Aug/24/2016" ,type:"PERMANENT" },
{ reg_no:"941", name:"Atukunda Michael", gender: "M", field:"Civil", date: "Aug/25/2016" ,type:"PERMANENT" },
{ reg_no:"942", name:"Nakamya Betty", gender: "F", field:"Civil", date: "Sep/02/2016" ,type:"PERMANENT" },
{ reg_no:"943", name:"Nalubulwa Joweria", gender: "F", field:"Civil", date: "Sep/13/2016" ,type:"PERMANENT" },
{ reg_no:"944", name:"Were Patrick", gender: "M", field:"Civil", date: "Sep/13/2016" ,type:"PERMANENT" },
{ reg_no:"945", name:"Ndagije Deo", gender: "M", field:"Mechanical", date: "Sep/15/2016" ,type:"PERMANENT" },
{ reg_no:"946", name:"Bogezi Asuman", gender: "M", field:"Electrical", date: "Sep/16/2016" ,type:"PERMANENT" },
{ reg_no:"947", name:"Bwambale Joseph", gender: "M", field:"Telecommunication", date: "Sep/16/2016" ,type:"PERMANENT" },
{ reg_no:"948", name:"Maate Tusiime Eva", gender: "F", field:"Civil", date: "Sep/16/2016" ,type:"PERMANENT" },
{ reg_no:"949", name:"Tibwitta Willy G.B.", gender: "M", field:"Civil", date: "Sep/16/2016" ,type:"PERMANENT" },
{ reg_no:"950", name:"Kaweesa Jimmy", gender: "M", field:"Civil", date: "Sep/16/2016" ,type:"PERMANENT" },
{ reg_no:"951", name:"Namajja Gloria", gender: "F", field:"Civil", date: "Sep/19/2016" ,type:"PERMANENT" },
{ reg_no:"952", name:"Byamukama Jacob", gender: "M", field:"Civil", date: "Oct/07/2016" ,type:"PERMANENT" },
{ reg_no:"953", name:"Mwebaze Emmanuel", gender: "M", field:"Civil", date: "Oct/07/2016" ,type:"PERMANENT" },
{ reg_no:"954", name:"Fataki Gabriel", gender: "M", field:"Civil", date: "Oct/07/2016" ,type:"PERMANENT" },
{ reg_no:"955", name:"Omona Mathias", gender: "M", field:"Civil", date: "Oct/07/2016" ,type:"PERMANENT" },
{ reg_no:"956", name:"Nsambu Reuben", gender: "M", field:"Civil", date: "Oct/07/2016" ,type:"PERMANENT" },
{ reg_no:"957", name:"Umasis Bose", gender: "M", field:"Civil", date: "Oct/07/2016" ,type:"PERMANENT" },
{ reg_no:"958", name:"Denga Yosia", gender: "M", field:"Civil", date: "Oct/07/2016" ,type:"PERMANENT" },
{ reg_no:"959", name:"Kugonza Franklin Benjamin", gender: "M", field:"Mechanical", date: "Oct/10/2016" ,type:"PERMANENT" },
{ reg_no:"960", name:"Byarugaba Godfrey", gender: "M", field:"Civil", date: "Oct/10/2016" ,type:"PERMANENT" },
{ reg_no:"961", name:"Kasaija John", gender: "M", field:"Civil", date: "Oct/11/2016" ,type:"PERMANENT" },
{ reg_no:"962", name:"Kisubi Tom ", gender: "M", field:"Civil", date: "Oct/12/2016" ,type:"PERMANENT" },
{ reg_no:"963", name:"Haumba Milton ", gender: "M", field:"Civil", date: "Oct/13/2016" ,type:"PERMANENT" },
{ reg_no:"964", name:"Kisembo Moses Muyonjo ", gender: "M", field:"Civil", date: "Oct/14/2016" ,type:"PERMANENT" },
{ reg_no:"965", name:"Wobuya Emmanuel ", gender: "M", field:"Civil", date: "Oct/18/2016" ,type:"PERMANENT" },
{ reg_no:"966", name:"Tumuheirwe Emmanuel", gender: "M", field:"Mechanical", date: "Oct/20/2016" ,type:"PERMANENT" },
{ reg_no:"967", name:"Taremwa Denis Kamugisha", gender: "M", field:"Civil", date: "Oct/24/2016" ,type:"PERMANENT" },
{ reg_no:"968", name:"Ongom Oyat Martin", gender: "M", field:"Civil", date: "Oct/27/2016" ,type:"PERMANENT" },
{ reg_no:"969", name:"Mujuni Bareba Arnold", gender: "M", field:"Electrical", date: "Nov/15/2016" ,type:"PERMANENT" },
{ reg_no:"970", name:"Andima Moses", gender: "M", field:"Telecommunication", date: "Dec/05/2016" ,type:"PERMANENT" },
{ reg_no:"971", name:"Aikoa Patrick", gender: "M", field:"Civil", date: "Dec/09/2016" ,type:"PERMANENT" },
{ reg_no:"972", name:"Roma Godfrey", gender: "M", field:"Civil", date: "Dec/09/2016" ,type:"PERMANENT" },
{ reg_no:"973", name:"Kalule Patrick", gender: "M", field:"Civil", date: "Dec/12/2016" ,type:"PERMANENT" },
{ reg_no:"974", name:"Sekanyu Nooh Haroon", gender: "M", field:"Civil", date: "Dec/13/2016" ,type:"PERMANENT" },
{ reg_no:"975", name:"Nakatte Lilian Segujja", gender: "F", field:"Civil", date: "Dec/13/2016" ,type:"PERMANENT" },
{ reg_no:"976", name:"Mukiibi Ismail", gender: "M", field:"Civil", date: "Dec/14/2016" ,type:"PERMANENT" },
{ reg_no:"978", name:"Mweisgwa Julius Kacebonaho", gender: "M", field:"Civil", date: "Jan/05/2017" ,type:"PERMANENT" },
{ reg_no:"979", name:"Nsimbe Joseph", gender: "M", field:"Civil", date: "Jan/09/2017" ,type:"PERMANENT" },
{ reg_no:"980", name:"Apolot Ruth Engeu", gender: "F", field:"Civil", date: "Feb/03/2017" ,type:"PERMANENT" },
{ reg_no:"981", name:"Twinamatsiko Douglas", gender: "M", field:"Electrical", date: "Feb/10/2017" ,type:"PERMANENT" },
{ reg_no:"982", name:"Tiberondwa Henry Kagaaga", gender: "M", field:"Civl", date: "Feb/13/2017" ,type:"PERMANENT" },
{ reg_no:"983", name:"Muwomya Fred", gender: "M", field:"Civil", date: "Feb/15/2017" ,type:"PERMANENT" },
{ reg_no:"984", name:"Kabanda Herman", gender: "M", field:"Civil", date: "Feb/15/2017" ,type:"PERMANENT" },
{ reg_no:"985", name:"Kayima Usama", gender: "M", field:"Civil", date: "Feb/15/2017" ,type:"PERMANENT" },
{ reg_no:"986", name:"Kayima Fred", gender: "M", field:"Civil", date: "Feb/17/2017" ,type:"PERMANENT" },
{ reg_no:"987", name:"Akankwasa Michael", gender: "M", field:"Civil", date: "Feb/20/2017" ,type:"PERMANENT" },
{ reg_no:"988", name:"Tibyakinura Protaze", gender: "M", field:"Electrical", date: "Feb/20/2017" ,type:"PERMANENT" },
{ reg_no:"990", name:"Ochole Moses", gender: "M", field:"Civil", date: "Feb/21/2017" ,type:"PERMANENT" },
{ reg_no:"991", name:"Agume Edson Wilbert", gender: "M", field:"Civil", date: "Feb/23/2017" ,type:"PERMANENT" },
{ reg_no:"992", name:"Kaggwa Moses Luwemba", gender: "M", field:"Civil", date: "Feb/23/2017" ,type:"PERMANENT" },
{ reg_no:"993", name:"Nalubega Beatrice", gender: "F", field:"Electrical", date: "Feb/23/2017" ,type:"PERMANENT" },
{ reg_no:"994", name:"Ssevvume Stanley", gender: "M", field:"Civil", date: "Feb/24/2017" ,type:"PERMANENT" },
{ reg_no:"995", name:"Nibyagaba Lorna Martha", gender: "F", field:"Civil", date: "Feb/27/2017" ,type:"PERMANENT" },
{ reg_no:"996", name:"Mugabi Ronald", gender: "M", field:"Civil", date: "Mar/01/2017" ,type:"PERMANENT" },
{ reg_no:"997", name:"Kasozi Paul Nsamba", gender: "M", field:"Civil", date: "Apr/11/2017" ,type:"PERMANENT" },
{ reg_no:"998", name:"Oryem John Speke", gender: "M", field:"Civil", date: "Apr/11/2017" ,type:"PERMANENT" },
{ reg_no:"999", name:"Mukasa Ddungu Charles", gender: "M", field:"Civil", date: "Apr/11/2017" ,type:"PERMANENT" },
{ reg_no:"1000", name:"Owagage Innocent Alex", gender: "M", field:"Civil", date: "Apr/13/2017" ,type:"PERMANENT" },
{ reg_no:"1001", name:"Pariyo Bernard", gender: "M", field:"Civil", date: "Apr/13/2017" ,type:"PERMANENT" },
{ reg_no:"1002", name:"Kyambadde Andrew", gender: "M", field:"Civil", date: "Apr/18/2017" ,type:"PERMANENT" },
{ reg_no:"1003", name:"Tumweboneire Emmanuel", gender: "M", field:"Mechanical", date: "Apr/20/2017" ,type:"PERMANENT" },
{ reg_no:"1004", name:"Galabuzi Denis", gender: "M", field:"Civil", date: "Apr/27/2017" ,type:"PERMANENT" },
{ reg_no:"1005", name:"Okel Ogwang Daniel", gender: "M", field:"Civil", date: "Apr/27/2017" ,type:"PERMANENT" },
{ reg_no:"1006", name:"Okello Paul", gender: "M", field:"Electrical", date: "May/02/2017" ,type:"PERMANENT" },
{ reg_no:"1007", name:"Sakwa Darlington Nanywaka", gender: "M", field:"Electrical", date: "May/02/2017" ,type:"PERMANENT" },
{ reg_no:"1008", name:"Mubiru Joel", gender: "M", field:"Civil", date: "May/02/2017" ,type:"PERMANENT" },
{ reg_no:"1009", name:"Mwesigwa Samuel Damulira", gender: "M", field:"Civil", date: "May/04/2017" ,type:"PERMANENT" },
{ reg_no:"1010", name:"Kaggwa Irene Sewankambo", gender: "F", field:"Electrical", date: "May/08/2017" ,type:"PERMANENT" },
{ reg_no:"1011", name:"Onek Paul", gender: "M", field:"Electrical", date: "May/10/2017" ,type:"PERMANENT" },
{ reg_no:"1012", name:"Kiyemba Eriasi", gender: "M", field:"Electrical", date: "May/16/2017" ,type:"PERMANENT" },
{ reg_no:"1013", name:"Bateganya David", gender: "M", field:"Civil", date: "May/17/2017" ,type:"PERMANENT" },
{ reg_no:"1014", name:"Musabe Kenneth", gender: "M", field:"Civil", date: "May/24/2017" ,type:"PERMANENT" },
{ reg_no:"1015", name:"Omitta Gilbert", gender: "M", field:"Mechanical", date: "Jun/13/2017" ,type:"PERMANENT" },
{ reg_no:"1016", name:"Serukenya Isaac Wassanyi", gender: "M", field:"Electrical", date: "Jun/13/2017" ,type:"PERMANENT" },
{ reg_no:"1017", name:"Mugala Paul Kalanzi", gender: "M", field:"Mechanical", date: "Jun/13/2017" ,type:"PERMANENT" },
{ reg_no:"1018", name:"Turyamureba Mafigiri", gender: "M", field:"Electrical", date: "Jun/16/2017" ,type:"PERMANENT" },
{ reg_no:"1019", name:"Werikhe Khaukha Godfrey", gender: "M", field:"Electrical", date: "Jun/22/2017" ,type:"PERMANENT" },
{ reg_no:"1020", name:"Oluga Daniel", gender: "M", field:"Electrical", date: "Jun/23/2017" ,type:"PERMANENT" },
{ reg_no:"1021", name:"Mukesha Gilbert", gender: "M", field:"Civil", date: "Jun/23/2017" ,type:"PERMANENT" },
{ reg_no:"1022", name:"Ngirabakunzi Emmanuel", gender: "M", field:"Civil", date: "Jun/29/2017" ,type:"PERMANENT" },
{ reg_no:"1023", name:"Nayiga Judith", gender: "F", field:"Electrical", date: "Jun/29/2017" ,type:"PERMANENT" },
{ reg_no:"1024", name:"Mwebesa Joseph", gender: "M", field:"Civil", date: "Jul/03/2017" ,type:"PERMANENT" },
{ reg_no:"1025", name:"Biryahabwe Patrick Mbooga", gender: "M", field:"Civil", date: "Jul/04/2017" ,type:"PERMANENT" },
{ reg_no:"1026", name:"Muyingo Edward", gender: "M", field:"Civil", date: "Jul/11/2017" ,type:"PERMANENT" },
{ reg_no:"1027", name:"Kizza Stephen", gender: "M", field:"Civil", date: "Jul/17/2017" ,type:"PERMANENT" },
{ reg_no:"1028", name:"Mugume Seith (Dr.)", gender: "M", field:"Civil", date: "Jul/17/2017" ,type:"PERMANENT" },
{ reg_no:"1030", name:"Joloba James", gender: "M", field:"Civil", date: "Jul/19/2017" ,type:"PERMANENT" },
{ reg_no:"1032", name:"Tunguta Ellias", gender: "M", field:"Mechanical", date: "Aug/18/2017" ,type:"PERMANENT" },
{ reg_no:"1033", name:"Omara Christo Balmoyi", gender: "M", field:"Civil", date: "Aug/18/2017" ,type:"PERMANENT" },
{ reg_no:"1034", name:"Tusingwire Bagarukayo Edgar", gender: "M", field:"Mechanical", date: "Aug/23/2017" ,type:"PERMANENT" },
{ reg_no:"1035", name:"Lutwama Charles William", gender: "M", field:"Electrical", date: "Aug/23/2017" ,type:"PERMANENT" },
{ reg_no:"1036", name:"Mwine James Martindale", gender: "M", field:"Electrical", date: "Aug/29/2017" ,type:"PERMANENT" },
{ reg_no:"1037", name:"Kigozi Robert", gender: "M", field:"Civil", date: "Sep/06/2017" ,type:"PERMANENT" },
{ reg_no:"1038", name:"Byangire Paul Rusoke", gender: "M", field:"Civil", date: "Sep/06/2017" ,type:"PERMANENT" },
{ reg_no:"1040", name:"Mukobe Jacob", gender: "M", field:"Civil", date: "Sep/07/2017" ,type:"PERMANENT" },
{ reg_no:"1041", name:"Kitayimbwa Godfrey", gender: "M", field:"Electrical", date: "Sep/07/2017" ,type:"PERMANENT" },
{ reg_no:"1042", name:"Arinaitwe Fredrick Tebekiza", gender: "M", field:"Civil", date: "Sep/07/2017" ,type:"PERMANENT" },
{ reg_no:"1043", name:"Matsiko Richard", gender: "M", field:"Electrical", date: "Sep/08/2017" ,type:"PERMANENT" },
{ reg_no:"1045", name:"Mukiibi Julius", gender: "M", field:"Civil", date: "Sep/19/2017" ,type:"PERMANENT" },
{ reg_no:"1046", name:"Ssebugwawo Athanasius", gender: "M", field:"Agricultural", date: "Sep/20/2017" ,type:"PERMANENT" },
{ reg_no:"1047", name:"Kwesiga Dickson", gender: "M", field:"Mechanical", date: "Sep/20/2017" ,type:"PERMANENT" },
{ reg_no:"1048", name:"Nyakango Joash", gender: "M", field:"Civil", date: "Sep/23/2017" ,type:"PERMANENT" },
{ reg_no:"1049", name:"Semusu Haruna", gender: "M", field:"Civil", date: "Oct/04/2017" ,type:"PERMANENT" },
{ reg_no:"1050", name:"Byaruhanga Edward", gender: "M", field:"Civil", date: "Oct/05/2017" ,type:"PERMANENT" },
{ reg_no:"1051", name:"Atuhairwe Ronald", gender: "M", field:"Electrical", date: "Oct/10/2017" ,type:"PERMANENT" },
{ reg_no:"1052", name:"Konde Stanley", gender: "M", field:"Civil", date: "Nov/02/2017" ,type:"PERMANENT" },
{ reg_no:"1053", name:"Odongo Joseph", gender: "M", field:"Civil", date: "Nov/02/2017" ,type:"PERMANENT" },
{ reg_no:"1054", name:"Kiyega George William", gender: "M", field:"Civil", date: "Nov/06/2017" ,type:"PERMANENT" },
{ reg_no:"1055", name:"Okuyo Bernard ", gender: "M", field:"Civil", date: "Nov/07/2017" ,type:"PERMANENT" },
{ reg_no:"1056", name:"Turyatunga Emmex", gender: "M", field:"Civil", date: "Nov/07/2017" ,type:"PERMANENT" },
{ reg_no:"1057", name:"Mateega Henry", gender: "M", field:"Civil", date: "Nov/07/2017" ,type:"PERMANENT" },
{ reg_no:"1058", name:"Kasango Banuli", gender: "M", field:"Civil", date: "Nov/08/2017" ,type:"PERMANENT" },
{ reg_no:"1059", name:"Muwanguzi J. Bumalirivu Abraham", gender: "M", field:"Mechanical", date: "Nov/09/2017" ,type:"PERMANENT" },
{ reg_no:"1060", name:"Kiberu Faisal", gender: "M", field:"Civil", date: "Nov/10/2017" ,type:"PERMANENT" },
{ reg_no:"1062", name:"Hategekimana Sylver", gender: "M", field:"Electrical", date: "Dec/14/2017" ,type:"PERMANENT" },
{ reg_no:"1063", name:"Kaijuka Kenneth", gender: "M", field:"Civil", date: "Dec/14/2017" ,type:"PERMANENT" },
{ reg_no:"1065", name:"Jjembe Edgar Tefiro", gender: "M", field:"Civil", date: "Dec/15/2017" ,type:"PERMANENT" },
{ reg_no:"1066", name:"Nyakaana Moses", gender: "M", field:"Civil", date: "Dec/15/2017" ,type:"PERMANENT" },
{ reg_no:"1067", name:"Muloni Irene Margaret Nafuna", gender: "F", field:"Electrical", date: "Dec/15/2017" ,type:"PERMANENT" },
{ reg_no:"1068", name:"Omona Charles Kasongo", gender: "M", field:"Civil", date: "Dec/18/2017" ,type:"PERMANENT" },
{ reg_no:"1069", name:"Makanga Timothy Paul", gender: "M", field:"Electrical", date: "Dec/19/2017" ,type:"PERMANENT" },
{ reg_no:"1070", name:"Nankanja Anne Gitta ", gender: "F", field:"Civil", date: "Dec/22/2017" ,type:"PERMANENT" },
{ reg_no:"1071", name:"Kibuuka Joseph Brian", gender: "M", field:"Electrical", date: "Jan/18/2018" ,type:"PERMANENT" },
{ reg_no:"1072", name:"Amuya Steve Okwalinga", gender: "M", field:"Civil", date: "Jan/23/2018" ,type:"PERMANENT" },
{ reg_no:"1073", name:"Matsiko Jude Benda", gender: "M", field:"Civil", date: "Feb/01/2018" ,type:"PERMANENT" },
{ reg_no:"1074", name:"Yikii Gordon", gender: "M", field:"Civil", date: "Feb/02/2018" ,type:"PERMANENT" },
{ reg_no:"1075", name:"Kayondo Charles", gender: "M", field:"Civil", date: "Feb/02/2018" ,type:"PERMANENT" },
{ reg_no:"1076", name:"Byaruhanga Davis", gender: "M", field:"Civil", date: "Feb/02/2018" ,type:"PERMANENT" },
{ reg_no:"1077", name:"Mawanga Festo Ngobi", gender: "M", field:"Civil", date: "Feb/28/2018" ,type:"PERMANENT" },
{ reg_no:"1078", name:"Arinda Rachel Baalessanvu ", gender: "F", field:"Electrical", date: "Apr/16/2018" ,type:"PERMANENT" },
{ reg_no:"1079", name:"Kayanga Joan Dhamutudha Mutiibwa ", gender: "F", field:"Electrical", date: "Apr/18/2018" ,type:"PERMANENT" },
{ reg_no:"1080", name:"Alioka George William", gender: "M", field:"Telecommunication", date: "Aug/21/2018" ,type:"PERMANENT" },
{ reg_no:"1081", name:"Kaghalana Daniel", gender: "M", field:"Civil", date: "May/03/2018" ,type:"PERMANENT" },
{ reg_no:"1082", name:"Aromborach Doris ", gender: "M", field:"Civil", date: "May/04/2018" ,type:"PERMANENT" },
{ reg_no:"1083", name:"Katamba Ivan", gender: "M", field:"Civil", date: "May/04/2018" ,type:"PERMANENT" },
{ reg_no:"1084", name:"Kitaka Mark Leo", gender: "M", field:"Civil", date: "May/07/2018" ,type:"PERMANENT" },
{ reg_no:"1085", name:"Berabose Dickson", gender: "M", field:"Civil", date: "May/07/2018" ,type:"PERMANENT" },
{ reg_no:"1086", name:"Nakagiri Anne (Dr.) ", gender: "F", field:"Civil", date: "May/09/2018" ,type:"PERMANENT" },
{ reg_no:"1087", name:"Okullu Jeroboam Steve", gender: "M", field:"Civil", date: "May/10/2018" ,type:"PERMANENT" },
{ reg_no:"1088", name:"Hanghusi Waswa Robert", gender: "M", field:"Civil", date: "Jun/08/2018" ,type:"PERMANENT" },
{ reg_no:"1089", name:"Mirembe Diana ", gender: "F", field:"Civil", date: "Jun/08/2018" ,type:"PERMANENT" },
{ reg_no:"1090", name:"Opwonya Daniel", gender: "M", field:"Agricultural", date: "Jun/08/2018" ,type:"PERMANENT" },
{ reg_no:"1091", name:"Ariho Denis", gender: "M", field:"Agricultural", date: "Jun/08/2018" ,type:"PERMANENT" },
{ reg_no:"1092", name:"Kobusinge Irene Priscilla ", gender: "F", field:"Mechanical", date: "Jun/08/2018" ,type:"PERMANENT" },
{ reg_no:"1093", name:"Semiyaga Swaib (Dr)", gender: "M", field:"Civil", date: "Jun/11/2018" ,type:"PERMANENT" },
{ reg_no:"1094", name:"Kawuma Isaac", gender: "M", field:"Mechanical", date: "Jun/13/2018" ,type:"PERMANENT" },
{ reg_no:"1095", name:"Nakachwa Maria Mutagubya ", gender: "F", field:"Civil", date: "Jun/13/2018" ,type:"PERMANENT" },
{ reg_no:"1096", name:"Akello Anna Muhereza (Mrs.) ", gender: "F", field:"Electrical", date: "Jun/18/2018" ,type:"PERMANENT" },
{ reg_no:"1097", name:"Mugisha Johnson", gender: "M", field:"Telecommunication", date: "Jun/18/2018" ,type:"PERMANENT" },
{ reg_no:"1098", name:"Walulya Lawrence", gender: "M", field:"Civil", date: "Jun/21/2018" ,type:"PERMANENT" },
{ reg_no:"1099", name:"Kabugo David", gender: "M", field:"Civil", date: "Jun/21/2018" ,type:"PERMANENT" },
{ reg_no:"1100", name:"Oola Jimmy Neol Sande", gender: "M", field:"Electrical", date: "Jun/26/2018" ,type:"PERMANENT" },
{ reg_no:"1101", name:"Isabirye Gerald", gender: "M", field:"Telecommunication", date: "Jul/02/2018" ,type:"PERMANENT" },
{ reg_no:"1102", name:"Nakasiga Fiona Kisaakye ", gender: "F", field:"Civil", date: "Jul/11/2018" ,type:"PERMANENT" },
{ reg_no:"1103", name:"Kalanzi Simon Tebasulwa", gender: "M", field:"Mechanical", date: "Jul/16/2018" ,type:"PERMANENT" },
{ reg_no:"1104", name:"Lubega Isaac Sempogo ", gender: "M", field:"Electrical", date: "Aug/09/2018" ,type:"PERMANENT" },
{ reg_no:"1105", name:"Tusiime Gloria Baitwa", gender: "F", field:"Mechanical", date: "Aug/10/2018" ,type:"PERMANENT" },
{ reg_no:"1106", name:"Wesonga Emmanuel  F.  ", gender: "M", field:"Electrical", date: "Aug/30/2018" ,type:"PERMANENT" },
{ reg_no:"1107", name:"Kato Ronald Kayizzi ", gender: "M", field:"Agricultural", date: "Sep/06/2018" ,type:"PERMANENT" },
{ reg_no:"1108", name:"Kibuuka Yusuf Nsubuga", gender: "M", field:"Electrical", date: "Sep/06/2018" ,type:"PERMANENT" },
{ reg_no:"1109", name:"Magezi Samuel ", gender: "M", field:"Telecommunication", date: "Sep/07/2018" ,type:"PERMANENT" },
{ reg_no:"1110", name:"Okwii Teddy ", gender: "F", field:"Civil", date: "Sep/07/2018" ,type:"PERMANENT" },
{ reg_no:"1111", name:"Wanyama Peter Paul ", gender: "M", field:"Civil", date: "Sep/07/2018" ,type:"PERMANENT" },
{ reg_no:"1112", name:"Umutoni Brenda ", gender: "F", field:"Civil", date: "Sep/10/2018" ,type:"PERMANENT" },
{ reg_no:"1113", name:"Otedor Samuel Noah", gender: "M", field:"Civil", date: "Sep/10/2018" ,type:"PERMANENT" },
{ reg_no:"1114", name:"Sekadde Ezra", gender: "M", field:"Civil", date: "Sep/11/2018" ,type:"PERMANENT" },
{ reg_no:"1115", name:"Kabahuma Sarah ", gender: "F", field:"Electrical", date: "Sep/13/2018" ,type:"PERMANENT" },
{ reg_no:"1116", name:"Ndiwalana Geoffrey Buts", gender: "M", field:"Civil", date: "Sep/13/2018" ,type:"PERMANENT" },
{ reg_no:"1117", name:"Mugume Eric", gender: "M", field:"Mechanical", date: "Oct/04/2018" ,type:"PERMANENT" },
{ reg_no:"1118", name:"Mubiru Gyagenda Ronald", gender: "M", field:"Agricultural", date: "Oct/04/2018" ,type:"PERMANENT" },
{ reg_no:"1119", name:"Mugabe Tonny", gender: "M", field:"Civil", date: "Oct/04/2018" ,type:"PERMANENT" },
{ reg_no:"1120", name:"Byabazaire Nkinzi Rita Makumbi", gender: "F", field:"Mechanical", date: "Oct/05/2018" ,type:"PERMANENT" },
{ reg_no:"1121", name:"Bamulese James", gender: "M", field:"Civil", date: "Oct/08/2018" ,type:"PERMANENT" },
{ reg_no:"1122", name:"Lepi Bosco", gender: "M", field:"Civil", date: "Oct/08/2018" ,type:"PERMANENT" },
{ reg_no:"1123", name:"Mugisha Hillary ", gender: "M", field:"Civil", date: "Oct/08/2018" ,type:"PERMANENT" },
{ reg_no:"1124", name:"Semakula Edward Jerimiah", gender: "M", field:"Telecommunication", date: "Oct/08/2018" ,type:"PERMANENT" },
{ reg_no:"1125", name:"Mwebesa Enock Bwesigye", gender: "M", field:"Electrical", date: "Oct/21/2018" ,type:"PERMANENT" },
{ reg_no:"1126", name:"Iyamulemye Emmanuel", gender: "M", field:"Civil", date: "Nov/08/2018" ,type:"PERMANENT" },
{ reg_no:"1127", name:"Odoi Paul", gender: "M", field:"Electrical", date: "Nov/08/2018" ,type:"PERMANENT" },
{ reg_no:"1128", name:"Rutalo Michael", gender: "M", field:"Electrical", date: "Nov/09/2018" ,type:"PERMANENT" },
{ reg_no:"1129", name:"Musumba Steven", gender: "M", field:"Civil", date: "Nov/09/2018" ,type:"PERMANENT" },
{ reg_no:"1130", name:"Kabaggoza Daniel", gender: "M", field:"Civil", date: "Nov/09/2018" ,type:"PERMANENT" },
{ reg_no:"1131", name:"Opwanya Peter", gender: "M", field:"Civil", date: "Nov/12/2018" ,type:"PERMANENT" },
{ reg_no:"1132", name:" Achola Patricia Ocan", gender: "F", field:"Electrical", date: "Nov/16/2018" ,type:"PERMANENT" },
{ reg_no:"1133", name:"Malinga Paul", gender: "M", field:"Civil", date: "Nov/16/2018" ,type:"PERMANENT" },
{ reg_no:"1134", name:"Okot Dennis ", gender: "M", field:"Electrical", date: "Nov/19/2018" ,type:"PERMANENT" },
{ reg_no:"1135", name:"Kirenzi Asuman", gender: "M", field:"Civil", date: "Nov/19/2018" ,type:"PERMANENT" },
{ reg_no:"1136", name:"Kiberu George Patrick", gender: "M", field:"Civil", date: "Dec/06/2018" ,type:"PERMANENT" },
{ reg_no:"1137", name:"Kizito Emmanuel", gender: "M", field:"Civil", date: "Dec/06/2018" ,type:"PERMANENT" },
{ reg_no:"1138", name:"Baguma Grace Gariyo", gender: "M", field:"Civil", date: "Dec/07/2018" ,type:"PERMANENT" },
{ reg_no:"1139", name:"Tushemereirwe Charity", gender: "F", field:"Agricultural", date: "Dec/14/2018" ,type:"PERMANENT" },
{ reg_no:"1140", name:"Wapakabulo Josephine (Dr.)", gender: "F", field:"Electrical", date: "Dec/14/2018" ,type:"PERMANENT" },
{ reg_no:"1141", name:"Lule Ivan (Dr.)", gender: "M", field:"Chemical", date: "Dec/20/2018" ,type:"PERMANENT" },
{ reg_no:"1142", name:"Namaganda Irene", gender: "F", field:"Electrical", date: "Jan/08/2019" ,type:"PERMANENT" },
{ reg_no:"1143", name:"Ahimbisibwe Paddy", gender: "M", field:"Civil", date: "Jan/09/2019" ,type:"PERMANENT" },
{ reg_no:"1144", name:"Iga Dan", gender: "M", field:"Civil", date: "Jan/10/2019" ,type:"PERMANENT" },
{ reg_no:"1145", name:"Musinguzi Ambrose", gender: "M", field:"Civil", date: "Jan/10/2019" ,type:"PERMANENT" },
{ reg_no:"1146", name:"Kayima Geoffrey", gender: "M", field:"Civil", date: "Jan/11/2019" ,type:"PERMANENT" },
{ reg_no:"1147", name:"Nabucha Aloysius", gender: "M", field:"Civil", date: "Jan/17/2019" ,type:"PERMANENT" },
{ reg_no:"1148", name:"Kaweesi JohnMary", gender: "M", field:"Civil", date: "Jan/17/2019" ,type:"PERMANENT" },
{ reg_no:"1149", name:"Ntabadde Christine Mugimba", gender: "F", field:"Electrical", date: "Jan/25/2019" ,type:"PERMANENT" },
{ reg_no:"1150", name:"Wegulo S. Byakatonda", gender: "M", field:"Electrical", date: "Feb/07/2019" ,type:"PERMANENT" },
{ reg_no:"1151", name:"Ssebuguzi Derrick", gender: "M", field:"Electrical", date: "Feb/08/2019" ,type:"PERMANENT" },
{ reg_no:"1152", name:"Owilli Ben ", gender: "M", field:"Civil", date: "Feb/12/2019" ,type:"PERMANENT" },
{ reg_no:"1153", name:"Wakyabya Godfrey", gender: "M", field:"Civil", date: "Feb/19/2019" ,type:"PERMANENT" },
{ reg_no:"1154", name:"Sentamu Jamidu", gender: "M", field:"Mechanical", date: "Mar/13/2019" ,type:"PERMANENT" },
{ reg_no:"1155", name:"Asiimwe Arnold ", gender: "M", field:"Civil", date: "Mar/13/2019" ,type:"PERMANENT" },
{ reg_no:"1156", name:"Katende Christopher Eddy", gender: "M", field:"Electrical", date: "Mar/21/2019" ,type:"PERMANENT" },
{ reg_no:"1157", name:"Lutwama Henry", gender: "M", field:"Electrical", date: "Mar/21/2019" ,type:"PERMANENT" },
{ reg_no:"1158", name:"Muhirirwe Cecilia Sandra", gender: "F", field:"Civil", date: "Apr/03/2019" ,type:"PERMANENT" },
{ reg_no:"1159", name:"Mbaziira Joseph ", gender: "M", field:"Civil", date: "Apr/04/2019" ,type:"PERMANENT" },
{ reg_no:"1160", name:"Lubowa Stephen", gender: "M", field:"Civil", date: "Apr/04/2019" ,type:"PERMANENT" },
{ reg_no:"1161", name:"Semanda Hassan", gender: "M", field:"Civil", date: "Apr/04/2019" ,type:"PERMANENT" },
{ reg_no:"1162", name:"Kiroobe Roger", gender: "M", field:"Civil", date: "Apr/04/2019" ,type:"PERMANENT" },
{ reg_no:"1163", name:"Turibarungi Augustus", gender: "M", field:"Civil", date: "Apr/05/2019" ,type:"PERMANENT" },
{ reg_no:"1164", name:"Mwogeza Mary", gender: "F", field:"Civil", date: "Apr/05/2019" ,type:"PERMANENT" },
{ reg_no:"1165", name:"Mulolo Francis", gender: "M", field:"Civil", date: "Apr/23/2019" ,type:"PERMANENT" },
{ reg_no:"1166", name:"Ssebutemba Shakibu", gender: "M", field:"Civil", date: "May/08/2019" ,type:"PERMANENT" },
{ reg_no:"1167", name:"Ngobi Herbert Muzaale", gender: "M", field:"Civil", date: "May/09/2019" ,type:"PERMANENT" },
{ reg_no:"1168", name:"Ngonzebwa Racheal ", gender: "F", field:"Civil", date: "May/09/2019" ,type:"PERMANENT" },
{ reg_no:"1170", name:"Kizito Nathan Musisi", gender: "M", field:"Civil", date: "May/10/2019" ,type:"PERMANENT" },
{ reg_no:"1171", name:"Gombya Ivan", gender: "M", field:"Civil", date: "May/13/2019" ,type:"PERMANENT" },
{ reg_no:"1172", name:"Keesiga Diana", gender: "F", field:"Civil", date: "May/13/2019" ,type:"PERMANENT" },
{ reg_no:"1173", name:"Anguyo Davis Madaraka", gender: "M", field:"Civil", date: "May/20/2019" ,type:"PERMANENT" },
{ reg_no:"1174", name:"Migadde Johnmary", gender: "M", field:"Electrical", date: "Jun/07/2019" ,type:"PERMANENT" },
{ reg_no:"1175", name:"Masereka Enos Bright", gender: "M", field:"Electrical", date: "Jun/13/2019" ,type:"PERMANENT" },
{ reg_no:"1176", name:"Okello Francis", gender: "M", field:"Civil", date: "Jun/14/2019" ,type:"PERMANENT" },
{ reg_no:"1177", name:"Baireghaka Benedicto", gender: "M", field:"Civil", date: "Jun/14/2019" ,type:"PERMANENT" },
{ reg_no:"1178", name:"Kalyesubula John", gender: "M", field:"Electrical", date: "Jun/14/2019" ,type:"PERMANENT" },
{ reg_no:"1179", name:"Ambazimana Andrew ", gender: "M", field:"Civil", date: "Jun/17/2019" ,type:"PERMANENT" },
{ reg_no:"1180", name:"Nattabi Irene", gender: "F", field:"Telecommunication", date: "Jun/17/2019" ,type:"PERMANENT" },
{ reg_no:"1181", name:"Owino Innocent Godwins", gender: "M", field:"Civil", date: "Jun/18/2019" ,type:"PERMANENT" },
{ reg_no:"1182", name:"Kalanzi Richard", gender: "M", field:"Mechanical", date: "Jul/04/2019" ,type:"PERMANENT" },
{ reg_no:"1183", name:"Yebazamukama Collins", gender: "M", field:"Civil", date: "Jul/04/2019" ,type:"PERMANENT" },
{ reg_no:"1184", name:"Ssekulima Edward Baleke", gender: "M", field:"Electrical", date: "Jul/04/2019" ,type:"PERMANENT" },
{ reg_no:"1185", name:"Kaheeru Fredi", gender: "M", field:"Civil", date: "Jul/04/2019" ,type:"PERMANENT" },
{ reg_no:"1186", name:"Ekukut Yokosofaty ", gender: "M", field:"Civil", date: "Jul/05/2019" ,type:"PERMANENT" },
{ reg_no:"1187", name:"Kiwanuka Samuel ", gender: "M", field:"Civil", date: "Jul/05/2019" ,type:"PERMANENT" },
{ reg_no:"1188", name:"Namukasa Joan Kamya", gender: "F", field:"Mechanical", date: "Jul/05/2019" ,type:"PERMANENT" },
{ reg_no:"1189", name:"Apio Juliet Pauline", gender: "F", field:"Mechanical", date: "Aug/09/2019" ,type:"PERMANENT" },
{ reg_no:"1190", name:"Kiboigo Nelson Junior", gender: "M", field:"Electrical", date: "Aug/12/2019" ,type:"PERMANENT" },
{ reg_no:"1191", name:"Mugisa Stephen ", gender: "M", field:"Electrical", date: "Aug/15/2019" ,type:"PERMANENT" },
{ reg_no:"1192", name:"Agaba Rugaba Nicholas ", gender: "M", field:"Civil", date: "Aug/17/2019" ,type:"PERMANENT" },
{ reg_no:"1193", name:"Ojara Allan Bruce", gender: "M", field:"Civil", date: "Aug/27/2019" ,type:"PERMANENT" },
{ reg_no:"1194", name:"Opuch Chris", gender: "M", field:"Civil", date: "Aug/30/2019" ,type:"PERMANENT" },
{ reg_no:"1195", name:"Mutegeki Godwin Geoffrey", gender: "M", field:"Civil", date: "Sep/05/2019" ,type:"PERMANENT" },
{ reg_no:"1196", name:"Kabazira Ireen", gender: "F", field:"Civil", date: "Sep/05/2019" ,type:"PERMANENT" },
{ reg_no:"1197", name:"Mufumba Daniel", gender: "M", field:"Civil", date: "Sep/05/2019" ,type:"PERMANENT" },
{ reg_no:"1198", name:"Batenda Felix", gender: "M", field:"Civil", date: "Sep/05/2019" ,type:"PERMANENT" },
{ reg_no:"1199", name:"Kajwalo Nancy", gender: "F", field:"Civil", date: "Sep/06/2019" ,type:"PERMANENT" },
{ reg_no:"1200", name:"Lukala Justine", gender: "F", field:"Civil", date: "Sep/06/2019" ,type:"PERMANENT" },
{ reg_no:"1201", name:"Turyamureeba Perezi", gender: "M", field:"Civil", date: "Oct/03/2019" ,type:"PERMANENT" },
{ reg_no:"1202", name:"Kafeero Frederick Ssali", gender: "M", field:"Electrical", date: "Oct/04/2019" ,type:"PERMANENT" },
{ reg_no:"1203", name:"Ssemakalu Ttondo Samson", gender: "M", field:"Electrical", date: "Oct/07/2019" ,type:"PERMANENT" },
{ reg_no:"1204", name:"Nanyombi Barbara", gender: "F", field:"Civil", date: "Oct/08/2019" ,type:"PERMANENT" },
{ reg_no:"1205", name:"Kaweesa Wilfred", gender: "M", field:"Electrical", date: "Nov/07/2019" ,type:"PERMANENT" },
{ reg_no:"1206", name:"Odongo Mark Ajal", gender: "M", field:"Electrical", date: "Nov/07/2019" ,type:"PERMANENT" },
{ reg_no:"1207", name:"Ssegawa Dennis Williams", gender: "M", field:"Civil", date: "Nov/07/2019" ,type:"PERMANENT" },
{ reg_no:"1208", name:"Mugizi Herbert Magyezi", gender: "M", field:"Mechanical", date: "Nov/08/2019" ,type:"PERMANENT" },
{ reg_no:"1209", name:"Wasukira James Waniala", gender: "M", field:"Mechanical (Marine)", date: "Nov/11/2019" ,type:"PERMANENT" },
{ reg_no:"1210", name:"Byaruhanga Atwooki Deo", gender: "M", field:"Mechanical", date: "Nov/11/2019" ,type:"PERMANENT" },
{ reg_no:"1211", name:"Kaddu Kenneth", gender: "M", field:"Electrical", date: "Nov/11/2019" ,type:"PERMANENT" },
{ reg_no:"1212", name:"Beinamaryo Tumukunde Felix", gender: "M", field:"Civil", date: "Nov/23/2019" ,type:"PERMANENT" },
{ reg_no:"1213", name:"Mwesigwa Leonard", gender: "M", field:"Civil", date: "Nov/25/2019" ,type:"PERMANENT" },
{ reg_no:"1214", name:"Nuwamanya Titus", gender: "M", field:"Civil", date: "Nov/27/2019" ,type:"PERMANENT" },
{ reg_no:"1215", name:"Bakiza Ian Paul", gender: "M", field:"Civil", date: "Nov/27/2019" ,type:"PERMANENT" },
{ reg_no:"1216", name:"Sewagudde Ali", gender: "M", field:"Electrical", date: "Nov/27/2019" ,type:"PERMANENT" },
{ reg_no:"1217", name:"Zimbe Ivan", gender: "M", field:"Electrical", date: "Nov/27/2019" ,type:"PERMANENT" },
{ reg_no:"1218", name:"Bbira Sam", gender: "M", field:"Civil", date: "Nov/28/2019" ,type:"PERMANENT" },
{ reg_no:"1219", name:"Okanya Boniface", gender: "M", field:"Mechanical", date: "Nov/28/2019" ,type:"PERMANENT" },
{ reg_no:"1220", name:"Sembatya Richard Kato", gender: "M", field:"Telecommunication", date: "Nov/28/2019" ,type:"PERMANENT" },
{ reg_no:"1221", name:"Nakyazze Prossy", gender: "F", field:"Mechanical", date: "Nov/28/2019" ,type:"PERMANENT" },
{ reg_no:"1222", name:"Oyoo Eliseo", gender: "M", field:"Civil", date: "Nov/28/2019" ,type:"PERMANENT" },
{ reg_no:"1223", name:"Sekasala Kenneth", gender: "M", field:"Civil", date: "Nov/28/2019" ,type:"PERMANENT" },
{ reg_no:"1224", name:"Eleanor Wozei (Dr.)", gender: "F", field:"Civil", date: "Nov/28/2019" ,type:"PERMANENT" },
{ reg_no:"1225", name:"Kairu Robert Muluvu", gender: "M", field:"Civil", date: "Nov/28/2019" ,type:"PERMANENT" },
{ reg_no:"1226", name:"Walakira Joseph", gender: "M", field:"Electrical", date: "Nov/06/2019" ,type:"PERMANENT" },
{ reg_no:"1227", name:"Kaliba Joshua", gender: "M", field:"Mechanical", date: "Nov/13/2019" ,type:"PERMANENT" },
{ reg_no:"1228", name:"Lubwama Michael (Dr.)", gender: "M", field:"Mechanical", date: "Jan/14/2020" ,type:"PERMANENT" },
{ reg_no:"1229", name:"Isanga Richard Mike", gender: "M", field:"Civil", date: "Jan/14/2020" ,type:"PERMANENT" },
{ reg_no:"1230", name:"Osilo Tom", gender: "M", field:"Mechanical", date: "Jan/14/2020" ,type:"PERMANENT" },
{ reg_no:"1231", name:"Macho David", gender: "M", field:"Telecommunication", date: "Jan/14/2020" ,type:"PERMANENT" },
{ reg_no:"1232", name:"Hiire Nicholas", gender: "M", field:"Electrical", date: "Jan/14/2020" ,type:"PERMANENT" },
{ reg_no:"1233", name:"Izael Pereira Da Silva (Dr.)", gender: "M", field:"Electrical", date: "Jan/15/2020" ,type:"PERMANENT" },
{ reg_no:"1234", name:"Kayiwa Ronald", gender: "M", field:"Mechanical", date: "Jan/22/2020" ,type:"PERMANENT" },
{ reg_no:"1235", name:"Nakalembe Victoria Mary", gender: "F", field:"Electrical", date: "Jan/24/2020" ,type:"PERMANENT" },
{ reg_no:"1236", name:"Owori Ogola Nicholas", gender: "M", field:"Mechanical", date: "Jan/28/2020" ,type:"PERMANENT" },
{ reg_no:"1237", name:"Musaasizi Joseph", gender: "M", field:"Mechanical", date: "Jan/28/2020" ,type:"PERMANENT" },
{ reg_no:"1238", name:"Nanono Mildred", gender: "F", field:"Electrical", date: "Jan/28/2020" ,type:"PERMANENT" },
{ reg_no:"1239", name:"Byamukama Norman John", gender: "M", field:"Civil", date: "Jan/29/2020" ,type:"PERMANENT" },
{ reg_no:"1240", name:"Nsubuga Geoffrey", gender: "M", field:"Civil", date: "Jan/30/2020" ,type:"PERMANENT" },
{ reg_no:"1241", name:"Olega George", gender: "M", field:"Civil", date: "Jan/30/2020" ,type:"PERMANENT" },
{ reg_no:"1242", name:"Sempebwa Brian", gender: "M", field:"Civil", date: "Jan/30/2020" ,type:"PERMANENT" },
{ reg_no:"1243", name:"Kahiigi Raymond", gender: "M", field:"Civil", date: "Feb/12/2020" ,type:"PERMANENT" },
{ reg_no:"1244", name:"Nuwagira Deus", gender: "M", field:"Civil", date: "Feb/17/2020" ,type:"PERMANENT" },
{ reg_no:"1245", name:"Kafuko Kenneth Bemba", gender: "M", field:"Civil", date: "Feb/19/2020" ,type:"PERMANENT" },
{ reg_no:"1246", name:"Kiboome William", gender: "M", field:"Civil", date: "Feb/25/2020" ,type:"PERMANENT" },
{ reg_no:"1248", name:"Kato Benard", gender: "M", field:"Mechanical", date: "Aug/06/2020" ,type:"PERMANENT" },
{ reg_no:"1249", name:"Sedirimba Ian", gender: "M", field:"Electrical", date: "Aug/06/2020" ,type:"PERMANENT" },
{ reg_no:"1250", name:"Akampurira Onesmus", gender: "M", field:"Electrical", date: "Aug/07/2020" ,type:"PERMANENT" },
{ reg_no:"1251", name:"Buregyeya Dennis", gender: "M", field:"Electrical", date: "Aug/07/2020" ,type:"PERMANENT" },
{ reg_no:"1252", name:"Kato Jeffrey Kanyike", gender: "M", field:"Electro - Mechanical", date: "Aug/07/2020" ,type:"PERMANENT" },
{ reg_no:"1253", name:"Nabaggala Irene Grace", gender: "F", field:"Mechanical", date: "Aug/10/2020" ,type:"PERMANENT" },
{ reg_no:"1254", name:"Angwec Catherine Agwai", gender: "F", field:"Civil", date: "Aug/10/2020" ,type:"PERMANENT" },
{ reg_no:"1255", name:"Nabatanzi Anita", gender: "F", field:"Electrical", date: "Aug/10/2020" ,type:"PERMANENT" },
{ reg_no:"1256", name:"Nnassuuna Mirembe", gender: "F", field:"Civil", date: "Aug/10/2020" ,type:"PERMANENT" },
{ reg_no:"1257", name:"Ibaale Daniel Joseph", gender: "M", field:"Electrical", date: "Aug/10/2020" ,type:"PERMANENT" },
{ reg_no:"1258", name:"Mbonyebyombi Aloysius", gender: "M", field:"Electrical", date: "Aug/10/2020" ,type:"PERMANENT" },
{ reg_no:"1259", name:"Opio Emmanuel", gender: "M", field:"Civil", date: "Aug/14/2020" ,type:"PERMANENT" },
{ reg_no:"1260", name:"Kayizzi Patrick", gender: "M", field:"Civil", date: "Aug/14/2020" ,type:"PERMANENT" },
{ reg_no:"1261", name:"Mwesigwa Robert", gender: "M", field:"Civil", date: "Aug/14/2020" ,type:"PERMANENT" },
{ reg_no:"1262", name:"Mutebi Joseph Ssemanobe", gender: "M", field:"Civil", date: "Aug/17/2020" ,type:"PERMANENT" },
{ reg_no:"1263", name:"Nakawunde Robinah Kulabako (Dr.)", gender: "F", field:"Civil", date: "Aug/17/2020" ,type:"PERMANENT" },
{ reg_no:"1264", name:"Ddamulira Dunstan Paul", gender: "M", field:"Civil", date: "Aug/18/2020" ,type:"PERMANENT" },
{ reg_no:"1265", name:"Kironde Dennis", gender: "M", field:"Civil", date: "Aug/18/2020" ,type:"PERMANENT" },
{ reg_no:"1266", name:"Kasolo Ronald", gender: "M", field:"Civil", date: "Aug/19/2020" ,type:"PERMANENT" },
{ reg_no:"1267", name:"Kasirikale Geoffrey", gender: "M", field:"Civil", date: "Aug/20/2020" ,type:"PERMANENT" },
{ reg_no:"1268", name:"Izama Hellen", gender: "F", field:"Civil", date: "Aug/21/2020" ,type:"PERMANENT" },
{ reg_no:"1269", name:"Can Ogura Raymond", gender: "M", field:"Civil", date: "Aug/24/2020" ,type:"PERMANENT" },
{ reg_no:"1270", name:"Kikomeko Samuel", gender: "M", field:"Mechanical", date: "Aug/24/2020" ,type:"PERMANENT" },
{ reg_no:"1271", name:"Kiwuwa Arthur", gender: "M", field:"Civil", date: "Aug/26/2020" ,type:"PERMANENT" },
{ reg_no:"1272", name:"Wamala Julius Namusanga", gender: "M", field:"Electrical", date: "Sep/03/2020" ,type:"PERMANENT" },
{ reg_no:"1273", name:"Ojambo Lawrence", gender: "M", field:"Civil", date: "Sep/03/2020" ,type:"PERMANENT" },
{ reg_no:"1274", name:"Okotel Patrick", gender: "M", field:"Civil", date: "Sep/03/2020" ,type:"PERMANENT" },
{ reg_no:"1275", name:"Patel Harish Jashbhai", gender: "M", field:"Electrical", date: "Sep/03/2020" ,type:"PERMANENT" },
{ reg_no:"1276", name:"Sekitene Ronald Mukasa", gender: "M", field:"Electrical", date: "Sep/03/2020" ,type:"PERMANENT" },
{ reg_no:"1277", name:"Ssekubunga Isaac", gender: "M", field:"Civil", date: "Sep/03/2020" ,type:"PERMANENT" },
{ reg_no:"1278", name:"Tumwine Paul", gender: "M", field:"Mechanical", date: "Sep/04/2020" ,type:"PERMANENT" },
{ reg_no:"1279", name:"Kwitonda Angelo", gender: "M", field:"Civil", date: "Sep/04/2020" ,type:"PERMANENT" },
{ reg_no:"1280", name:"Lubang Benedict", gender: "M", field:"Civil", date: "Oct/08/2020" ,type:"PERMANENT" },
{ reg_no:"1281", name:"Semazzi Zaake Wilfred", gender: "M", field:"Electrical", date: "Oct/08/2020" ,type:"PERMANENT" },
{ reg_no:"1282", name:"Kabuusu Ronald Tonny", gender: "M", field:"Mechanical", date: "Oct/08/2020" ,type:"PERMANENT" },
{ reg_no:"1283", name:"Mugweri Charles", gender: "M", field:"Civil", date: "Oct/12/2020" ,type:"PERMANENT" },
{ reg_no:"1284", name:"Katerega Johnson", gender: "M", field:"Civil", date: "Oct/12/2020" ,type:"PERMANENT" },
{ reg_no:"1285", name:"Lugolobi Thaddeus", gender: "M", field:"Civil", date: "Nov/05/2020" ,type:"PERMANENT" },
{ reg_no:"1286", name:"Olaro Oscar", gender: "M", field:"Mechanical", date: "Nov/05/2020" ,type:"PERMANENT" },
{ reg_no:"1287", name:"Kirungudha Godfrey", gender: "M", field:"Civil", date: "Nov/05/2020" ,type:"PERMANENT" },
{ reg_no:"1288", name:"Muniina Kenneth (Dr.)", gender: "M", field:"Civil", date: "Nov/09/2020" ,type:"PERMANENT" },
{ reg_no:"1289", name:"Kibaya Allan Rogers", gender: "M", field:"Agricultural", date: "Dec/02/2020" ,type:"PERMANENT" },
{ reg_no:"1290", name:"Katende Rashid", gender: "M", field:"Mechanical", date: "Dec/02/2020" ,type:"PERMANENT" },
{ reg_no:"1291", name:"Mbabazi Caroline", gender: "F", field:"Electrical", date: "Dec/03/2020" ,type:"PERMANENT" },
{ reg_no:"1292", name:"Watta Ivan", gender: "M", field:"Mechanical", date: "Jan/06/2021" ,type:"PERMANENT" },
{ reg_no:"1293", name:"Lutaaya Philip", gender: "M", field:"Electrical", date: "Jan/07/2021" ,type:"PERMANENT" },
{ reg_no:"1294", name:"Sevume Samson", gender: "M", field:"Electrical", date: "Jan/07/2021" ,type:"PERMANENT" },
{ reg_no:"1295", name:"Ocan Jolly Joe", gender: "M", field:"Mechanical", date: "Jan/08/2021" ,type:"PERMANENT" },
{ reg_no:"1296", name:"Mpaata Steven", gender: "M", field:"Civil", date: "Jan/11/2021" ,type:"PERMANENT" },
{ reg_no:"1297", name:"Wasswa Bernard", gender: "M", field:"Civil", date: "Jan/11/2021" ,type:"PERMANENT" },
{ reg_no:"1298", name:"Kafeeza Aloysius", gender: "M", field:"Civil", date: "Jan/12/2021" ,type:"PERMANENT" },
{ reg_no:"1299", name:"Kalenzi Robert Segawa", gender: "M", field:"Civil", date: "Jan/12/2021" ,type:"PERMANENT" },
{ reg_no:"1300", name:"Kibuuka Gilbert", gender: "M", field:"Civil", date: "Jan/12/2021" ,type:"PERMANENT" },
{ reg_no:"1301", name:"Butime Eric Katabarwa ", gender: "M", field:"Electrical", date: "Jan/21/2021" ,type:"PERMANENT" },
{ reg_no:"1302", name:"Wasswa James Nsubuga", gender: "M", field:"Electrical", date: "Jan/21/2021" ,type:"PERMANENT" },
{ reg_no:"1303", name:"Okuk Geoffrey Bright Owera", gender: "M", field:"Civil", date: "Feb/03/2021" ,type:"PERMANENT" },
{ reg_no:"1304", name:"Kiraire Enock", gender: "M", field:"Civil", date: "Feb/03/2021" ,type:"PERMANENT" },
{ reg_no:"1305", name:"Musinguzi George Tinda", gender: "M", field:"Civil", date: "Feb/03/2021" ,type:"PERMANENT" },
{ reg_no:"1306", name:"Arinanye James", gender: "M", field:"Civil", date: "Feb/03/2021" ,type:"PERMANENT" },
{ reg_no:"1307", name:"Agumenaitwe Andrew", gender: "M", field:"Civil", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1308", name:"Ssozi Geoffrey Ssekimpi", gender: "M", field:"Environmental", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1309", name:"Bongomin Charles", gender: "M", field:"Civil", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1310", name:"Oleng Morris", gender: "M", field:"Civil", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1311", name:"Ngabirano Lillian", gender: "F", field:"Civil", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1312", name:"Omark Speke", gender: "M", field:"Mechanical", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1313", name:"Buhanda Brian", gender: "M", field:"Civil", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1314", name:"Olupot Simon Julius", gender: "M", field:"Civil", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1315", name:"Lugeye Henry", gender: "M", field:"Civil", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1316", name:"Katabira Valentine", gender: "M", field:"Electrical", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1317", name:"Okite George", gender: "M", field:"Civil", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1318", name:"Orishaba Catherine", gender: "F", field:"Civil", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1319", name:"Atwijukye Osbert", gender: "M", field:"Civil", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1320", name:"Kikomeko Ali Kigongo", gender: "M", field:"Civil", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1321", name:"Lule Yusuf", gender: "M", field:"Civil", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1322", name:"Biira Fortunate", gender: "F", field:"Civil", date: "Feb/04/2021" ,type:"PERMANENT" },
{ reg_no:"1323", name:"Rubahamya Marcelliano", gender: "M", field:"Civil", date: "Feb/08/2021" ,type:"PERMANENT" },
{ reg_no:"1324", name:"Opio Atingi Christopher", gender: "M", field:"Electrical", date: "Mar/04/2021" ,type:"PERMANENT" },
{ reg_no:"1325", name:"Mugabi Abdulrahman", gender: "M", field:"Civil", date: "Mar/04/2021" ,type:"PERMANENT" },
{ reg_no:"1326", name:"Niwandiinda Ivan", gender: "M", field:"Civil", date: "Mar/04/2021" ,type:"PERMANENT" },
{ reg_no:"1327", name:"Otim Kevin", gender: "M", field:"Civil", date: "Mar/04/2021" ,type:"PERMANENT" },
{ reg_no:"1328", name:"Edhaya David", gender: "M", field:"Civil", date: "Mar/04/2021" ,type:"PERMANENT" },
{ reg_no:"1329", name:"Ongwara Michael", gender: "M", field:"Civil", date: "Mar/04/2021" ,type:"PERMANENT" },
{ reg_no:"1330", name:"Ssali James", gender: "M", field:"Civil", date: "Mar/04/2021" ,type:"PERMANENT" },
{ reg_no:"1331", name:"Olupot Wilberforce Peter (Dr.)", gender: "M", field:"Mechanical", date: "Mar/04/2021" ,type:"PERMANENT" },
{ reg_no:"1332", name:"Ssempebwa John Paul", gender: "M", field:"Automotive & Power", date: "Mar/05/2021" ,type:"PERMANENT" },
{ reg_no:"1333", name:"Namazzi Rosette Lule", gender: "F", field:"Electrical", date: "Mar/05/2021" ,type:"PERMANENT" },
{ reg_no:"1334", name:"Kajumba Sandra Matty", gender: "F", field:"Electrical", date: "Mar/05/2021" ,type:"PERMANENT" },
{ reg_no:"1335", name:"Kalibbala Francis Kabali", gender: "M", field:"Civil", date: "Mar/05/2021" ,type:"PERMANENT" },
{ reg_no:"1336", name:"Nyamutale Peter", gender: "M", field:"Civil", date: "Mar/16/2021" ,type:"PERMANENT" },
{ reg_no:"1337", name:"Rucukye Anthony", gender: "M", field:"Civil", date: "Apr/14/2021" ,type:"PERMANENT" },
{ reg_no:"1338", name:"Kaguhangire Steven", gender: "M", field:"Civil", date: "Apr/15/2021" ,type:"PERMANENT" },
{ reg_no:"1339", name:"Muganga Jonathan", gender: "M", field:"Civil", date: "Apr/15/2021" ,type:"PERMANENT" },
{ reg_no:"1340", name:"Kasiita Ibrahim Kakooza", gender: "M", field:"Civil", date: "Apr/15/2021" ,type:"PERMANENT" },
{ reg_no:"1341", name:"Kasozi Arthur", gender: "M", field:"Civil", date: "Apr/15/2021" ,type:"PERMANENT" },
{ reg_no:"1342", name:"Kasisira Carolyn", gender: "F", field:"Civil", date: "Apr/15/2021" ,type:"PERMANENT" },
{ reg_no:"1343", name:"Kabenge Isa (Dr.)", gender: "M", field:"Agricultural", date: "Apr/15/2021" ,type:"PERMANENT" },
{ reg_no:"1344", name:"Mugumya Peter", gender: "M", field:"Civil", date: "Apr/15/2021" ,type:"PERMANENT" },
{ reg_no:"1345", name:"Obeja Roderick", gender: "M", field:"Civil", date: "Apr/15/2021" ,type:"PERMANENT" },
{ reg_no:"1346", name:"Kangwagye Fredrick", gender: "M", field:"Mechanical", date: "Apr/15/2021" ,type:"PERMANENT" },
{ reg_no:"1347", name:"Odul Stephenson", gender: "M", field:"Civil", date: "Apr/19/2021" ,type:"PERMANENT" },
{ reg_no:"1348", name:"Mwesige Andrew Mulindwa", gender: "M", field:"Electrical", date: "Apr/19/2021" ,type:"PERMANENT" },
{ reg_no:"1349", name:"Nsubuga Abubaker", gender: "M", field:"Mechanical", date: "Apr/19/2021" ,type:"PERMANENT" },
{ reg_no:"1350", name:"Ntwirenabo Coleb", gender: "M", field:"Civil", date: "May/05/2021" ,type:"PERMANENT" },
{ reg_no:"1351", name:"Mabweijano George Patrick Joseph", gender: "M", field:"Mechanical", date: "May/05/2021" ,type:"PERMANENT" },
{ reg_no:"1352", name:"Mugisha Allan", gender: "M", field:"Mechanical", date: "May/06/2021" ,type:"PERMANENT" },
{ reg_no:"1353", name:"Mulowooza Robert", gender: "M", field:"Civil", date: "May/06/2021" ,type:"PERMANENT" },
{ reg_no:"1354", name:"Ahabyona Manyiraho Evelyn", gender: "F", field:"Civil", date: "May/06/2021" ,type:"PERMANENT" },
{ reg_no:"1355", name:"Kayigwa Richard", gender: "M", field:"Civil", date: "May/06/2021" ,type:"PERMANENT" },
{ reg_no:"1356", name:"Buyi Tom", gender: "M", field:"Civil", date: "May/06/2021" ,type:"PERMANENT" },
{ reg_no:"1357", name:"Mujuni Herbert", gender: "M", field:"Civil", date: "May/06/2021" ,type:"PERMANENT" },
{ reg_no:"1358", name:"Kimera Allan", gender: "M", field:"Electrical", date: "May/06/2021" ,type:"PERMANENT" },
{ reg_no:"1359", name:"Ongodia Joan Evelyn", gender: "F", field:"Civil", date: "May/06/2021" ,type:"PERMANENT" },
{ reg_no:"1360", name:"Kavuma Fredrick", gender: "M", field:"Electrical", date: "May/06/2021" ,type:"PERMANENT" },
{ reg_no:"1361", name:"Enyuku James Benjamin", gender: "M", field:"Civil", date: "May/07/2021" ,type:"PERMANENT" },
{ reg_no:"1362", name:"Ssekayingo Stephen", gender: "M", field:"Civil", date: "May/11/2021" ,type:"PERMANENT" },
{ reg_no:"1363", name:"Atwine Ronald", gender: "M", field:"Civil", date: "Jun/02/2021" ,type:"PERMANENT" },
{ reg_no:"1364", name:"Ashaba James", gender: "M", field:"Civil", date: "Jun/02/2021" ,type:"PERMANENT" },
{ reg_no:"1365", name:"Nantabwaza Emmanuel Mubeezi", gender: "M", field:"Electrical", date: "Jun/04/2021" ,type:"PERMANENT" },
{ reg_no:"1366", name:"Birimumaso David", gender: "M", field:"Mechanical", date: "Jun/04/2021" ,type:"PERMANENT" },
{ reg_no:"1367", name:"Echatu Andrew", gender: "M", field:"Civil", date: "Jun/04/2021" ,type:"PERMANENT" },
{ reg_no:"1368", name:"Thibenda Moris", gender: "M", field:"Civil", date: "Jun/04/2021" ,type:"PERMANENT" },
{ reg_no:"1369", name:"Kiggundu Expedit", gender: "M", field:"Electrical", date: "Jun/04/2021" ,type:"PERMANENT" },
{ reg_no:"1370", name:"Asiimwe Jacob Kameraho", gender: "M", field:"Civil", date: "Jun/04/2021" ,type:"PERMANENT" },
{ reg_no:"1371", name:"Lwanga Benon", gender: "M", field:"Agricultural", date: "Jun/04/2021" ,type:"PERMANENT" },
{ reg_no:"1372", name:"Awoii Ahmad Okwir", gender: "M", field:"Civil", date: "Jun/04/2021" ,type:"PERMANENT" },
{ reg_no:"1373", name:"Kamya Daniel Wamala", gender: "M", field:"Civil", date: "Jun/07/2021" ,type:"PERMANENT" },
{ reg_no:"1375", name:"Sseruwu Patrick", gender: "M", field:"Civil", date: "Jun/08/2021" ,type:"PERMANENT" },
{ reg_no:"1376", name:"Nazimuli Rebeccah", gender: "F", field:"Civil", date: "Jun/08/2021" ,type:"PERMANENT" },
{ reg_no:"1378", name:"Bayiyana Catherine", gender: "F", field:"Mechanical", date: "Jun/10/2021" ,type:"PERMANENT" },
{ reg_no:"1379", name:"Muhigirwa Charles", gender: "M", field:"Civil", date: "Jun/23/2021" ,type:"PERMANENT" },
{ reg_no:"1380", name:"Bugembe Hajab", gender: "M", field:"Civil", date: "Jul/02/2021" ,type:"PERMANENT" },
{ reg_no:"1381", name:"Ssonko George", gender: "M", field:"Civil", date: "Jul/14/2021" ,type:"PERMANENT" },
{ reg_no:"1382", name:"Okumu George", gender: "M", field:"Civil", date: "Jul/14/2021" ,type:"PERMANENT" },
{ reg_no:"1383", name:"Geria Kennedy", gender: "M", field:"Civil", date: "Jul/14/2021" ,type:"PERMANENT" },
{ reg_no:"1384", name:"Labongo James", gender: "M", field:"Civil", date: "Jul/15/2021" ,type:"PERMANENT" },
{ reg_no:"1385", name:"Kamya Elimia", gender: "M", field:"Civil", date: "Jul/15/2021" ,type:"PERMANENT" },
{ reg_no:"1386", name:"Kasajja Godfrey", gender: "M", field:"Electrical", date: "Jul/15/2021" ,type:"PERMANENT" },
{ reg_no:"1387", name:"Namakhola Rajab", gender: "M", field:"Agricultural", date: "Jul/15/2021" ,type:"PERMANENT" },
{ reg_no:"1388", name:"Nantume Deborah Pamela", gender: "F", field:"Electrical", date: "Jul/15/2021" ,type:"PERMANENT" },
{ reg_no:"1389", name:"Auma Elizabeth", gender: "F", field:"Electrical", date: "Jul/15/2021" ,type:"PERMANENT" },
{ reg_no:"1390", name:"Bogere Joseph Alfred", gender: "M", field:"Telecommunication", date: "Jul/15/2021" ,type:"PERMANENT" },
{ reg_no:"1391", name:"Ahimbisibwe Dickens", gender: "M", field:"Civil", date: "Aug/04/2021" ,type:"PERMANENT" },
{ reg_no:"1392", name:"Kazibwe Ronald", gender: "M", field:"Civil", date: "Aug/04/2021" ,type:"PERMANENT" },
{ reg_no:"1393", name:"Mutyaba Johnson", gender: "M", field:"Civil", date: "Aug/04/2021" ,type:"PERMANENT" },
{ reg_no:"1394", name:"Acai Joseph", gender: "M", field:"Civil", date: "Aug/05/2021" ,type:"PERMANENT" },
{ reg_no:"1395", name:"Gumisiriza Ezra", gender: "M", field:"Civil", date: "Aug/05/2021" ,type:"PERMANENT" },
{ reg_no:"1396", name:"Kiwanda Louis", gender: "M", field:"Civil", date: "Aug/05/2021" ,type:"PERMANENT" },
{ reg_no:"1397", name:"Sseguja Ibrahim Kamonde", gender: "M", field:"Electrical", date: "Sep/08/2021" ,type:"PERMANENT" },
{ reg_no:"1398", name:"Kyobe Luke Inyensiko", gender: "M", field:"Civil", date: "Sep/08/2021" ,type:"PERMANENT" },
{ reg_no:"1399", name:"Serwanja Emmanuel", gender: "M", field:"Civil", date: "Sep/08/2021" ,type:"PERMANENT" },
{ reg_no:"1400", name:"Tumusiime Musiime Fred", gender: "M", field:"Civil", date: "Sep/08/2021" ,type:"PERMANENT" },
{ reg_no:"1401", name:"Amugambe Eliab Tumwine", gender: "M", field:"Automotive & Power", date: "Sep/08/2021" ,type:"PERMANENT" },
{ reg_no:"1402", name:"Mugisa Richard", gender: "M", field:"Civil", date: "Sep/08/2021" ,type:"PERMANENT" },
{ reg_no:"1403", name:"Nantongo Olivia Ritah", gender: "F", field:"Civil", date: "Sep/09/2021" ,type:"PERMANENT" },
{ reg_no:"1404", name:"Nagawa Lilian", gender: "F", field:"Mechanical", date: "Sep/09/2021" ,type:"PERMANENT" },
{ reg_no:"1405", name:"Rachkara Paul", gender: "M", field:"Mechanical", date: "Sep/09/2021" ,type:"PERMANENT" },
{ reg_no:"1406", name:"Kiryahika Willy Kobujuna", gender: "M", field:"Electrical", date: "Sep/09/2021" ,type:"PERMANENT" },
{ reg_no:"1407", name:"Lutaaya Hakimu Mahmood", gender: "M", field:"Mechanical", date: "Oct/06/2021" ,type:"PERMANENT" },
{ reg_no:"1408", name:"Tibaleka Tutu Cara ", gender: "F", field:"Mechanical", date: "Oct/06/2021" ,type:"PERMANENT" },
{ reg_no:"1409", name:"Kizito Samuel", gender: "M", field:"Electrical", date: "Oct/06/2021" ,type:"PERMANENT" },
{ reg_no:"1410", name:"Karungi Sharon ", gender: "F", field:"Civil", date: "Oct/06/2021" ,type:"PERMANENT" },
{ reg_no:"1411", name:"Mayanja Rebecca ", gender: "F", field:"Electrical", date: "Oct/06/2021" ,type:"PERMANENT" },
{ reg_no:"1412", name:"Nseerikomawa Charles Ssennyonjo", gender: "M", field:"Civil", date: "Oct/06/2021" ,type:"PERMANENT" },
{ reg_no:"1413", name:"Okello Isaac", gender: "M", field:"Civil", date: "Oct/06/2021" ,type:"PERMANENT" },
{ reg_no:"1414", name:"Buhanga Boneventura", gender: "M", field:"Electrical", date: "Oct/19/2021" ,type:"PERMANENT" },
{ reg_no:"1415", name:"Musimami John Paul", gender: "M", field:"Mechanical", date: "Nov/04/2021" ,type:"PERMANENT" },
{ reg_no:"1416", name:"Kimbowa Dansturn", gender: "M", field:"Electrical", date: "Nov/05/2021" ,type:"PERMANENT" },
{ reg_no:"1417", name:"Arembe Anthony Mathias", gender: "M", field:"Automotive & Power", date: "Nov/08/2021" ,type:"PERMANENT" },
{ reg_no:"1418", name:"Nakanwagi Susan Magdalene", gender: "F", field:"Telecommunication", date: "Nov/15/2021" ,type:"PERMANENT" },
{ reg_no:"1419", name:"Kyokaali Lydia", gender: "F", field:"Civil", date: "Nov/17/2021" ,type:"PERMANENT" },
{ reg_no:"1420", name:"Kamya Nathan", gender: "M", field:"Civil", date: "Nov/17/2021" ,type:"PERMANENT" },
{ reg_no:"1421", name:"Binyaga Tatian", gender: "M", field:"Civil", date: "Nov/17/2021" ,type:"PERMANENT" },
{ reg_no:"1422", name:"Mwesiga Asbert", gender: "M", field:"Civil", date: "Nov/17/2021" ,type:"PERMANENT" },
{ reg_no:"1423", name:"Lusundo Fred Moses", gender: "M", field:"Civil", date: "Nov/17/2021" ,type:"PERMANENT" },
{ reg_no:"1424", name:"Kizza Kazibwe Deogratius", gender: "M", field:"Electrical", date: "Dec/06/2021" ,type:"PERMANENT" },
{ reg_no:"1425", name:"Mwoga Jude", gender: "M", field:"Civil", date: "Dec/06/2021" ,type:"PERMANENT" },
{ reg_no:"1426", name:"Mbonimpa Nicholas ", gender: "M", field:"Electrical", date: "Dec/06/2021" ,type:"PERMANENT" },
{ reg_no:"1427", name:"Sande William", gender: "M", field:"Civil", date: "Dec/06/2021" ,type:"PERMANENT" },
{ reg_no:"1428", name:"Juuko Felix", gender: "M", field:"Civil", date: "Dec/06/2021" ,type:"PERMANENT" },
{ reg_no:"1429", name:"Musenero Leah ", gender: "F", field:"Civil", date: "Dec/06/2021" ,type:"PERMANENT" },
{ reg_no:"1430", name:"Lumanyika Nicholas", gender: "M", field:"Mechnical", date: "Dec/07/2021" ,type:"PERMANENT" },
{ reg_no:"1431", name:"Picho Jerry", gender: "M", field:"Electrical", date: "Dec/07/2021" ,type:"PERMANENT" },
{ reg_no:"1432", name:"Nabateesa Sylivia", gender: "F", field:"Agricultural", date: "Dec/07/2021" ,type:"PERMANENT" },
{ reg_no:"1433", name:"Odongo Brian", gender: "M", field:"Electrical", date: "Dec/08/2021" ,type:"PERMANENT" },
{ reg_no:"1434", name:"Nsiimire Annet", gender: "F", field:"Telecommunication", date: "Dec/08/2021" ,type:"PERMANENT" },
{ reg_no:"1435", name:"Akol Samuel", gender: "M", field:"Civil", date: "Dec/08/2021" ,type:"PERMANENT" },
{ reg_no:"1436", name:"Mason Andrew", gender: "M", field:"Civil", date: "Dec/09/2021" ,type:"PERMANENT" },
{ reg_no:"1437", name:"Ssenteza Yusuf", gender: "M", field:"Electrical", date: "Dec/13/2021" ,type:"PERMANENT" },
{ reg_no:"1438", name:"Baluku Richard", gender: "M", field:"Civil", date: "Jan/13/2022" ,type:"PERMANENT" },
{ reg_no:"1439", name:"Nabugere Andrew Ceasor", gender: "M", field:"Civil", date: "Jan/13/2022" ,type:"PERMANENT" },
{ reg_no:"1440", name:"Kateeba Francis Divine", gender: "M", field:"Civil", date: "Jan/13/2022" ,type:"PERMANENT" },
{ reg_no:"1441", name:"Nakamatte Olivia", gender: "F", field:"Mechanical", date: "Jan/13/2022" ,type:"PERMANENT" },
{ reg_no:"1442", name:"Anguria Paul", gender: "M", field:"Civil", date: "Jan/13/2022" ,type:"PERMANENT" },
{ reg_no:"1443", name:"Katungi Raymond ", gender: "M", field:"Electrical", date: "Jan/14/2022" ,type:"PERMANENT" },
{ reg_no:"1444", name:"Kagaba Geoffrey", gender: "M", field:"Civil", date: "Jan/17/2022" ,type:"PERMANENT" },
{ reg_no:"1445", name:"Okoth Joseph Micheal ", gender: "M", field:"Agricultural", date: "Jan/17/2022" ,type:"PERMANENT" },
{ reg_no:"1446", name:"Birungi Janet", gender: "F", field:"Civil", date: "Jan/18/2022" ,type:"PERMANENT" },
{ reg_no:"1447", name:"Yiga Samwuel", gender: "M", field:"Electrical", date: "Jan/25/2022" ,type:"PERMANENT" },
{ reg_no:"1448", name:"Butanda Yusuf", gender: "M", field:"Electrical", date: "Feb/03/2022" ,type:"PERMANENT" },
{ reg_no:"1449", name:"Iragena Anthelem", gender: "M", field:"Civil", date: "Feb/03/2022" ,type:"PERMANENT" },
{ reg_no:"1450", name:"Lubwama Festo", gender: "M", field:"Mechanical", date: "Feb/03/2022" ,type:"PERMANENT" },
{ reg_no:"1451", name:"Ayeni Ronald", gender: "M", field:"Mechanical", date: "Feb/03/2022" ,type:"PERMANENT" },
{ reg_no:"1452", name:"Mukiibi Isaac", gender: "M", field:"Civil", date: "Feb/03/2022" ,type:"PERMANENT" },
{ reg_no:"1453", name:"Okello Santo", gender: "M", field:"Mechanical", date: "Feb/03/2022" ,type:"PERMANENT" },
{ reg_no:"1454", name:"Onen Stephen", gender: "M", field:"Civil", date: "Feb/03/2022" ,type:"PERMANENT" },
{ reg_no:"1455", name:"Namusoke Sulaina", gender: "F", field:"Civil", date: "Feb/03/2022" ,type:"PERMANENT" },
{ reg_no:"1456", name:"Mujuni Emmanuel", gender: "M", field:"Civil", date: "Feb/07/2022" ,type:"PERMANENT" },
{ reg_no:"1457", name:"Kimera Raymond", gender: "M", field:"Electrical", date: "Feb/07/2022" ,type:"PERMANENT" },
{ reg_no:"1458", name:"Wamala Fred", gender: "M", field:"Electrical", date: "Feb/07/2022" ,type:"PERMANENT" },
{ reg_no:"1459", name:"Sekajja Allan Gitta", gender: "M", field:"Civil", date: "Feb/07/2022" ,type:"PERMANENT" },
{ reg_no:"1460", name:"Mutegeki Henry", gender: "M", field:"Electrical", date: "Feb/07/2022" ,type:"PERMANENT" },
{ reg_no:"1461", name:"Rukundo Christopher", gender: "M", field:"Telecommunication", date: "Feb/10/2022" ,type:"PERMANENT" },
{ reg_no:"1462", name:"Owori Wilbrod Milwen", gender: "M", field:"Civil", date: "Mar/03/2022" ,type:"PERMANENT" },
{ reg_no:"1463", name:"Nyangweso  Marie Gorretti", gender: "F", field:"Civil", date: "Mar/04/2022" ,type:"PERMANENT" },
{ reg_no:"1464", name:"Tayebwa Mayres", gender: "M", field:"Civil", date: "Mar/04/2022" ,type:"PERMANENT" },
{ reg_no:"1465", name:"Kwesiga Emmy Kuriigamba", gender: "M", field:"Civil", date: "Mar/04/2022" ,type:"PERMANENT" },
{ reg_no:"1466", name:"Ekure Charles (Dr.)", gender: "M", field:"Civil", date: "Mar/04/2022" ,type:"PERMANENT" },
{ reg_no:"1467", name:"Okiror Richard", gender: "M", field:"Civil", date: "Mar/04/2022" ,type:"PERMANENT" },
{ reg_no:"1468", name:"Okidi Perry Robert", gender: "M", field:"Civil", date: "Mar/04/2022" ,type:"PERMANENT" },
{ reg_no:"1469", name:"Orwenyo Morris Gunya", gender: "M", field:"Civil", date: "Mar/04/2022" ,type:"PERMANENT" },
{ reg_no:"1470", name:"Namagembe Winnie", gender: "F", field:"Civil", date: "Mar/04/2022" ,type:"PERMANENT" },
{ reg_no:"1471", name:"Byakatonda Jimmy (Dr.)", gender: "M", field:"Agricultural", date: "Mar/04/2022" ,type:"PERMANENT" },
{ reg_no:"1472", name:"Nyamaizi Immaculate", gender: "F", field:"Mechanical", date: "Mar/04/2022" ,type:"PERMANENT" },
{ reg_no:"1473", name:"Muyomba Emmanuel", gender: "M", field:"Electrical", date: "Mar/04/2022" ,type:"PERMANENT" },
{ reg_no:"1474", name:"Tebusweke Semakula", gender: "M", field:"Electrical", date: "Mar/04/2022" ,type:"PERMANENT" },
{ reg_no:"1475", name:"Naijuka Kabondo Arthur", gender: "M", field:"Telecommunication", date: "Mar/04/2022" ,type:"PERMANENT" },
{ reg_no:"1476", name:"Kalembe Lydia", gender: "F", field:"Civil", date: "Mar/07/2022" ,type:"PERMANENT" },
{ reg_no:"1477", name:"Kuteesa Ferdinand Tumwiine", gender: "M", field:"Civil", date: "Apr/06/2022" ,type:"PERMANENT" },
{ reg_no:"1478", name:"Ogwang George", gender: "M", field:"Civil", date: "Apr/06/2022" ,type:"PERMANENT" },
{ reg_no:"1479", name:"Arinaitwe Baldo Kinigah", gender: "M", field:"Civil", date: "Apr/06/2022" ,type:"PERMANENT" },
{ reg_no:"1480", name:"Byakagaba Bernard", gender: "M", field:"Civil", date: "Apr/06/2022" ,type:"PERMANENT" },
{ reg_no:"1481", name:"Athiyo Ronald Andrew", gender: "M", field:"Civil", date: "Apr/06/2022" ,type:"PERMANENT" },
{ reg_no:"1482", name:"Nsubuga Nyenje Accram", gender: "M", field:"Electrical", date: "Apr/06/2022" ,type:"PERMANENT" },
{ reg_no:"1483", name:"Birungi Merab", gender: "F", field:"Electrical", date: "Apr/06/2022" ,type:"PERMANENT" },
{ reg_no:"1484", name:"Asiimwe Fred Arali", gender: "M", field:"Electrical", date: "Apr/06/2022" ,type:"PERMANENT" },
{ reg_no:"1485", name:"Moro Phillips", gender: "M", field:"Civil", date: "Apr/06/2022" ,type:"PERMANENT" },
{ reg_no:"1486", name:"Atukwasa Aggrey", gender: "M", field:"Civil", date: "Apr/07/2022" ,type:"PERMANENT" },
{ reg_no:"1487", name:"Amanyire Willington", gender: "M", field:"Agricultural", date: "Apr/07/2022" ,type:"PERMANENT" },
{ reg_no:"1488", name:"Kigongo Mutaasa Henry", gender: "M", field:"Civil", date: "Apr/07/2022" ,type:"PERMANENT" },
{ reg_no:"1489", name:"Ocen Samson Jackey", gender: "M", field:"Civil", date: "Apr/07/2022" ,type:"PERMANENT" },
{ reg_no:"1490", name:"Butime Julius (Dr.)", gender: "M", field:"Electrical", date: "Apr/07/2022" ,type:"PERMANENT" },
{ reg_no:"1491", name:"Nakamatte Olivie", gender: "F", field:"Telecommunication", date: "Apr/07/2022" ,type:"PERMANENT" },
{ reg_no:"1492", name:"Barigye Maurice", gender: "M", field:"Electrical", date: "Apr/11/2022" ,type:"PERMANENT" },
{ reg_no:"1493", name:"Kasozi James Tondo", gender: "M", field:"Agricultural", date: "Apr/11/2022" ,type:"PERMANENT" },
{ reg_no:"1494", name:"Atuhairwe Hillary Dammie", gender: "M", field:"Mechanical", date: "Apr/11/2022" ,type:"PERMANENT" },
{ reg_no:"1495", name:"Kyomuhendo Angella", gender: "F", field:"Civil", date: "May/11/2022" ,type:"PERMANENT" },
{ reg_no:"1496", name:"Agumisiriza Solomon", gender: "M", field:"Civil", date: "May/11/2022" ,type:"PERMANENT" },
{ reg_no:"1497", name:"Nassali Salaamah", gender: "F", field:"Civil", date: "May/11/2022" ,type:"PERMANENT" },
{ reg_no:"1498", name:"Muheise Araali Dennys", gender: "M", field:"Civil", date: "May/11/2022" ,type:"PERMANENT" },
{ reg_no:"1499", name:"Nantongo Annette Kezia", gender: "F", field:"Civil", date: "May/11/2022" ,type:"PERMANENT" },
{ reg_no:"1500", name:"Kahima Nuwagaba Dathan", gender: "M", field:"Civil", date: "May/11/2022" ,type:"PERMANENT" },
{ reg_no:"1501", name:"Kibira John", gender: "M", field:"Civil", date: "May/11/2022" ,type:"PERMANENT" },
{ reg_no:"1502", name:"Nsubuga Emmanuel Sande", gender: "M", field:"Electrical", date: "May/11/2022" ,type:"PERMANENT" },
{ reg_no:"1503", name:"Walugembe John Baptist", gender: "M", field:"Electrical", date: "May/11/2022" ,type:"PERMANENT" },
{ reg_no:"1504", name:"Kakeeto Peter", gender: "M", field:"Electronic", date: "May/11/2022" ,type:"PERMANENT" },
{ reg_no:"1505", name:"Baluku Joseph", gender: "M", field:"Civil", date: "May/11/2022" ,type:"PERMANENT" },
{ reg_no:"1506", name:"Nkamwesiga Lilian", gender: "F", field:"Civil", date: "May/11/2022" ,type:"PERMANENT" },
{ reg_no:"1507", name:"Ayebare Hope Brenda", gender: "F", field:"Civil", date: "May/11/2022" ,type:"PERMANENT" },
{ reg_no:"1508", name:"Ewama Moses Morris", gender: "M", field:"Civil", date: "May/11/2022" ,type:"PERMANENT" },
{ reg_no:"1509", name:"Tugume Moses King David", gender: "M", field:"Civil", date: "May/12/2022" ,type:"PERMANENT" },
{ reg_no:"1510", name:"Mugume Marvin", gender: "M", field:"Electrical", date: "May/12/2022" ,type:"PERMANENT" },
{ reg_no:"1511", name:"Rukanga Marvin", gender: "M", field:"Civil", date: "May/12/2022" ,type:"PERMANENT" },
{ reg_no:"1512", name:"Twinomuhangi Basheija Maximo", gender: "M", field:"Civil", date: "May/12/2022" ,type:"PERMANENT" },
{ reg_no:"1513", name:"Obura Anthony Anyuru", gender: "M", field:"Civil", date: "May/12/2022" ,type:"PERMANENT" },
{ reg_no:"1514", name:"Mugisha Daniel", gender: "M", field:"Electrical", date: "May/12/2022" ,type:"PERMANENT" },
{ reg_no:"1515", name:"Nakombe Dinah", gender: "F", field:"Civil", date: "May/13/2022" ,type:"PERMANENT" },
{ reg_no:"1516", name:"Magumba Beatrice", gender: "F", field:"Civil", date: "May/13/2022" ,type:"PERMANENT" },
{ reg_no:"1517", name:"Mulinda Khassim", gender: "M", field:"Civil", date: "Jun/08/2022" ,type:"PERMANENT" },
{ reg_no:"1518", name:"Nassiwa Agnes", gender: "F", field:"Civil", date: "Jun/08/2022" ,type:"PERMANENT" },
{ reg_no:"1519", name:"Bukenya John", gender: "M", field:"Electrical", date: "Jun/08/2022" ,type:"PERMANENT" },
{ reg_no:"1520", name:"Dr. Kagwisagye Sam", gender: "M", field:"Civil", date: "Jun/08/2022" ,type:"PERMANENT" },
{ reg_no:"1521", name:"Oloya Oyweyo James", gender: "M", field:"Electrical", date: "Jun/08/2022" ,type:"PERMANENT" },
{ reg_no:"1522", name:"Omara George", gender: "M", field:"Civil", date: "Jun/08/2022" ,type:"PERMANENT" },
{ reg_no:"1523", name:"Nuwenyesiga Dean", gender: "M", field:"Civil", date: "Jun/08/2022" ,type:"PERMANENT" },
{ reg_no:"1524", name:"Walimbwa Paul", gender: "M", field:"Civil", date: "Jun/08/2022" ,type:"PERMANENT" },
{ reg_no:"1525", name:"Sitra Mulepo Cyrus Sira", gender: "M", field:"Mechanical", date: "Jun/08/2022" ,type:"PERMANENT" },
{ reg_no:"1526", name:"Babita Harriet", gender: "F", field:"Civil", date: "Jun/08/2022" ,type:"PERMANENT" },
{ reg_no:"1527", name:"Tusiime Robert Kasoota", gender: "M", field:"Environmental", date: "Jun/08/2022" ,type:"PERMANENT" },
{ reg_no:"1528", name:"Kiyimba Charles", gender: "M", field:"Mechanical", date: "Jun/08/2022" ,type:"PERMANENT" },
{ reg_no:"1529", name:"Nsabimana Robert", gender: "M", field:"Civil", date: "Jun/10/2022" ,type:"PERMANENT" },
{ reg_no:"1530", name:"Kizito Nicholas Magera                          ", gender: "M", field:"Civil", date: "Jun/10/2022" ,type:"PERMANENT" },
{ reg_no:"1531", name:"Lubega Amir", gender: "M", field:"Electrical", date: "Jun/10/2022" ,type:"PERMANENT" },
{ reg_no:"1532", name:"Nakayenga Mariam", gender: "F", field:"Civil", date: "Jun/10/2022" ,type:"PERMANENT" },
{ reg_no:"1533", name:"Okot Daniel", gender: "M", field:"Electrical", date: "Jun/10/2022" ,type:"PERMANENT" },
{ reg_no:"1534", name:"Baguma Andrew", gender: "M", field:"Civil", date: "Jun/10/2022" ,type:"PERMANENT" },
{ reg_no:"1535", name:"Sepuya Daniel", gender: "M", field:"Electrical", date: "Jun/10/2022" ,type:"PERMANENT" },
{ reg_no:"1536", name:"Amanya William", gender: "M", field:"Civil", date: "Jun/10/2022" ,type:"PERMANENT" },
{ reg_no:"1537", name:"Okany Henry Jacob", gender: "M", field:"Civil", date: "Jun/10/2022" ,type:"PERMANENT" },
{ reg_no:"1538", name:"Mugambwa Robert", gender: "M", field:"Civil", date: "Jun/10/2022" ,type:"PERMANENT" },
{ reg_no:"1539", name:"Matovu Doreen Lwanga", gender: "F", field:"Civil", date: "Jun/10/2022" ,type:"PERMANENT" },
{ reg_no:"1540", name:"Kakyo Doreen", gender: "F", field:"Civil", date: "Jun/10/2022" ,type:"PERMANENT" },
{ reg_no:"1541", name:"Arinda Asha", gender: "F", field:"Civil", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1542", name:"Nakalanzi Esther", gender: "F", field:"Civil", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1543", name:"Friday Micah", gender: "M", field:"Civil", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1544", name:"Abenabo Alison Twine", gender: "F", field:"Civil", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1545", name:"Kaford Allan", gender: "M", field:"Civil", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1546", name:"Menya Isaac", gender: "M", field:"Civil", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1547", name:"Byaruhanga Kiiza Donati", gender: "M", field:"Civil", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1548", name:"Tayebwa Ashbert", gender: "M", field:"Civil", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1549", name:"Hatejeka Godfrey", gender: "M", field:"Civil", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1550", name:"Opige Denis", gender: "M", field:"Civil", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1551", name:"Mbetabaitu Kenneth", gender: "M", field:"Civil", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1552", name:"Besigye Daniel ", gender: "M", field:"Electrical", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1553", name:"Mayanja Hassan    ", gender: "M", field:"Electrical", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1554", name:"Mutyaba Christopher ", gender: "M", field:"Electrical", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1555", name:"Twongirwe Caleb  ", gender: "M", field:"Electrical", date: "Jul/08/2022" ,type:"PERMANENT" },
{ reg_no:"1556", name:"Magaja Dennis", gender: "M", field:"Civil", date: "Jul/11/2022" ,type:"PERMANENT" },
{ reg_no:"1557", name:"Avutia Ben Amos", gender: "M", field:"Civil", date: "Jul/11/2022" ,type:"PERMANENT" },
{ reg_no:"1558", name:"Kemigisha Gloria", gender: "F", field:"Civil", date: "Jul/11/2022" ,type:"PERMANENT" },
{ reg_no:"1559", name:"Kiija Grace ", gender: "F", field:"Electrical", date: "Sep/07/2022" ,type:"PERMANENT" },
{ reg_no:"1560", name:"Kigozi John Walusimbi", gender: "M", field:"Electrical", date: "Sep/07/2022" ,type:"PERMANENT" },
{ reg_no:"1561", name:"Korukundo Pauline", gender: "F", field:"Telecommunication", date: "Sep/07/2022" ,type:"PERMANENT" },
{ reg_no:"1562", name:"Muzaaya Richard Vernon", gender: "M", field:"Telecommunication", date: "Sep/08/2022" ,type:"PERMANENT" },
{ reg_no:"1563", name:"Enyetu Joshua ", gender: "M", field:"Agricultural", date: "Sep/08/2022" ,type:"PERMANENT" },
{ reg_no:"1564", name:"Ouma Oris ", gender: "M", field:"Chemical", date: "Sep/08/2022" ,type:"PERMANENT" },
{ reg_no:"1565", name:"Agaba Edwin", gender: "M", field:"Mechanical", date: "Sep/08/2022" ,type:"PERMANENT" },
{ reg_no:"1566", name:"Dr. Mugerwa Michael Nkambo", gender: "M", field:"Chemical", date: "Oct/05/2022" ,type:"PERMANENT" },
{ reg_no:"1567", name:"Kigonya Allan", gender: "M", field:"Civil", date: "Oct/05/2022" ,type:"PERMANENT" },
{ reg_no:"1568", name:"Kibuuka Kharim", gender: "M", field:"Mechanical", date: "Oct/05/2022" ,type:"PERMANENT" },
{ reg_no:"1569", name:"Bahati Keneth Hilary", gender: "M", field:"Civil", date: "Oct/05/2022" ,type:"PERMANENT" },
{ reg_no:"1570", name:"Ndugga Noah Godfrey", gender: "M", field:"Civil", date: "Oct/05/2022" ,type:"PERMANENT" },
{ reg_no:"1571", name:"Matovu Gary Cosma", gender: "M", field:"Civil", date: "Oct/06/2022" ,type:"PERMANENT" },
{ reg_no:"1572", name:"Kiggundu Ponsiano", gender: "M", field:"Civil", date: "Oct/06/2022" ,type:"PERMANENT" },
{ reg_no:"1573", name:"Lwamaza Allon", gender: "M", field:"Civil", date: "Oct/06/2022" ,type:"PERMANENT" },
{ reg_no:"1574", name:"Chepsikor Aggrey", gender: "M", field:"Civil", date: "Oct/06/2022" ,type:"PERMANENT" },
{ reg_no:"1575", name:"Ampiire Derrick ", gender: "M", field:"Civil", date: "Oct/06/2022" ,type:"PERMANENT" },
{ reg_no:"1576", name:"Namulindwa Milly", gender: "F", field:"Civil", date: "Oct/06/2022" ,type:"PERMANENT" },
{ reg_no:"1577", name:"Odoch Daniel", gender: "M", field:"Civil", date: "Oct/06/2022" ,type:"PERMANENT" },
{ reg_no:"1578", name:"Sakaya Ronald", gender: "M", field:"Civil", date: "Oct/06/2022" ,type:"PERMANENT" },
{ reg_no:"1579", name:"Ocen Willy", gender: "M", field:"Civil", date: "Oct/06/2022" ,type:"PERMANENT" },
{ reg_no:"1580", name:"Kansiime Sharon", gender: "F", field:"Mechanical", date: "Oct/07/2022" ,type:"PERMANENT" },
{ reg_no:"1581", name:"Dr. Kabanda John Samuel", gender: "M", field:"Civil", date: "Oct/07/2022" ,type:"PERMANENT" },
{ reg_no:"1582", name:"Alinda Joan Yvonne", gender: "F", field:"Civil", date: "Oct/19/2022" ,type:"PERMANENT" },
{ reg_no:"1583", name:"Matua Moses Trevor Mandela ", gender: "M", field:"Civil", date: "Nov/02/2022" ,type:"PERMANENT" },
{ reg_no:"1584", name:"Orodriyo Augusto", gender: "M", field:"Civil", date: "Nov/02/2022" ,type:"PERMANENT" },
{ reg_no:"1585", name:"Turyagyenda Eddie Ndyabegyera", gender: "M", field:"Civil", date: "Nov/03/2022" ,type:"PERMANENT" },
{ reg_no:"1586", name:"Namutebi Christine", gender: "F", field:"Electrical", date: "Nov/03/2022" ,type:"PERMANENT" },
{ reg_no:"1587", name:"Rwanyarare William", gender: "M", field:"Civil", date: "Nov/03/2022" ,type:"PERMANENT" },
{ reg_no:"1588", name:"Alany Jimmy Max ", gender: "M", field:"Civil", date: "Nov/03/2022" ,type:"PERMANENT" },
{ reg_no:"1589", name:"Opito Sam", gender: "M", field:"Civil", date: "Nov/03/2022" ,type:"PERMANENT" },
{ reg_no:"1590", name:"Twinamatsiko Dicklus ", gender: "M", field:"Civil", date: "Nov/04/2022" ,type:"PERMANENT" },
{ reg_no:"1591", name:"Nabanoba Catherine", gender: "F", field:"Civil", date: "Dec/07/2022" ,type:"PERMANENT" },
{ reg_no:"1592", name:"Bukenya Deo", gender: "M", field:"Mechanical", date: "Dec/07/2022" ,type:"PERMANENT" },
{ reg_no:"1593", name:"Kiganda Bennice", gender: "F", field:"Civil", date: "Dec/07/2022" ,type:"PERMANENT" },
{ reg_no:"1594", name:"Kahigwa Hosea Mugyenyi", gender: "M", field:"Civil", date: "Dec/07/2022" ,type:"PERMANENT" },
{ reg_no:"1595", name:"Wasswa Joseph", gender: "M", field:"Civil", date: "Dec/07/2022" ,type:"PERMANENT" },
{ reg_no:"1596", name:"Bondet Ngeywo Innocent", gender: "M", field:"Agricultural", date: "Dec/07/2022" ,type:"PERMANENT" },
{ reg_no:"1597", name:"Kasigwa James Nkamwesiga", gender: "M", field:"Electrical", date: "Dec/07/2022" ,type:"PERMANENT" },
{ reg_no:"1598", name:"Kiyimba Edward", gender: "M", field:"Electrical", date: "Dec/07/2022" ,type:"PERMANENT" },
{ reg_no:"1600", name:"Mukiibi Ssemakula Peter", gender: "M", field:"Civil", date: "Dec/07/2022" ,type:"PERMANENT" },
{ reg_no:"1601", name:"Wamala Isaac Samson", gender: "M", field:"Civil", date: "Dec/07/2022" ,type:"PERMANENT" },
{ reg_no:"1602", name:"Turyatunga Deus", gender: "M", field:"Civil", date: "Dec/08/2022" ,type:"PERMANENT" },
{ reg_no:"1603", name:"Cheptoek David", gender: "M", field:"Civil", date: "Dec/08/2022" ,type:"PERMANENT" },
{ reg_no:"1604", name:"Okirya Martin", gender: "M", field:"Agricultural", date: "Dec/08/2022" ,type:"PERMANENT" },
{ reg_no:"1605", name:"Liberty Enock", gender: "M", field:"Civil", date: "Dec/08/2022" ,type:"PERMANENT" },
{ reg_no:"1606", name:"Kaihura Herbert", gender: "M", field:"Civil", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1607", name:"Epeet Thomas", gender: "M", field:"Civil", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1608", name:"Agaba Pamela", gender: "F", field:"Agricultural", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1609", name:"Kabugo Ibrahim Sekitoleko", gender: "M", field:"Civil", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1610", name:"Tashobya Nelson Ruhaabwa", gender: "M", field:"Electrical", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1611", name:"Kyalikunda George ", gender: "M", field:"Telecommunication", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1612", name:"Tusiime Jarvis Bosco", gender: "M", field:"Civil", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1613", name:"Galyaki Cyrus ", gender: "M", field:"Agricultural", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1614", name:"Wanyama Joshua (Dr.)", gender: "M", field:"Agricultural", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1615", name:"Tebugulwa Allen", gender: "F", field:"Electrical", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1616", name:"Namudongo Robert", gender: "M", field:"Civil", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1617", name:"Musiime Ruhiriri Richard", gender: "M", field:"Civil", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1618", name:"Andama Douglas", gender: "M", field:"Mechanical", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1619", name:"Tebagalika Duncan", gender: "M", field:"Electrical", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1620", name:"Byabashaija Achilles", gender: "M", field:"Civil", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1621", name:"Owora Ronald", gender: "M", field:"Electrical", date: "Jan/18/2023" ,type:"PERMANENT" },
{ reg_no:"1622", name:"Mwebembezi Mbaga Allan", gender: "M", field:"Civil", date: "Jan/19/2023" ,type:"PERMANENT" },
{ reg_no:"1623", name:"Mwesigye Simon Enoch", gender: "M", field:"Civil", date: "Jan/19/2023" ,type:"PERMANENT" },
{ reg_no:"1624", name:"Mugisha Sandra", gender: "F", field:"Electrical", date: "Jan/19/2023" ,type:"PERMANENT" },
{ reg_no:"1625", name:"Kasekende Ronald", gender: "M", field:"Mechanical", date: "Jan/19/2023" ,type:"PERMANENT" },
{ reg_no:"1626", name:"Tibasiima Noah", gender: "M", field:"Chemical", date: "Jan/19/2023" ,type:"PERMANENT" },
{ reg_no:"1627", name:"Nakasozi Mary Dawn", gender: "F", field:"Electrical", date: "Feb/07/2023" ,type:"PERMANENT" },
{ reg_no:"1628", name:"Nuwagaba Deus", gender: "M", field:"Civil", date: "Feb/07/2023" ,type:"PERMANENT" },
{ reg_no:"1629", name:"Oluka Patrick Ogole", gender: "M", field:"Civil", date: "Feb/07/2023" ,type:"PERMANENT" },
{ reg_no:"1630", name:"Arinaitwe Agnes", gender: "F", field:"Electrical", date: "Feb/07/2023" ,type:"PERMANENT" },
{ reg_no:"1631", name:"Kaijuka Edwin Enock", gender: "M", field:"Electrical", date: "Feb/08/2023" ,type:"PERMANENT" },
{ reg_no:"1632", name:"Kasemire Susan", gender: "F", field:"Environmental", date: "Feb/08/2023" ,type:"PERMANENT" },
{ reg_no:"1633", name:"Okello John Robert", gender: "M", field:"Civil", date: "Feb/08/2023" ,type:"PERMANENT" },
{ reg_no:"1634", name:"Beyaraaza Fiona Mary Kamikazi", gender: "F", field:"Telecommunication", date: "Feb/08/2023" ,type:"PERMANENT" },
{ reg_no:"1635", name:"Ssentaba Herbert", gender: "M", field:"Civil", date: "Feb/08/2023" ,type:"PERMANENT" },
{ reg_no:"1636", name:"Byarugaba Muzamilu", gender: "M", field:"Civil", date: "Feb/08/2023" ,type:"PERMANENT" },
{ reg_no:"1637", name:"Mukalazi Vincent", gender: "M", field:"Civil", date: "Feb/08/2023" ,type:"PERMANENT" },
{ reg_no:"1638", name:"Kekiijo Merynah", gender: "F", field:"Civil", date: "Feb/08/2023" ,type:"PERMANENT" },
{ reg_no:"1639", name:"Otiang Ivan Rogers", gender: "M", field:"Civil", date: "Feb/08/2023" ,type:"PERMANENT" },
{ reg_no:"1640", name:"Mworyem Bill", gender: "M", field:"Civil", date: "Feb/08/2023" ,type:"PERMANENT" },
{ reg_no:"1641", name:"Tayebwa Nicholas", gender: "M", field:"Mechanical", date: "Feb/09/2023" ,type:"PERMANENT" },
{ reg_no:"1642", name:"Matovu Fred", gender: "M", field:"Electrical", date: "Feb/09/2023" ,type:"PERMANENT" },
{ reg_no:"1643", name:"Mawanga Peter Patience", gender: "M", field:"Environmental", date: "Mar/06/2023" ,type:"PERMANENT" },
{ reg_no:"1644", name:"Kinoko Nestory Wambura", gender: "M", field:"Civil", date: "Mar/10/2023" ,type:"PERMANENT" },
{ reg_no:"1645", name:"Mawanda Roselyne Nagaba", gender: "F", field:"Civil", date: "Apr/05/2023" ,type:"PERMANENT" },
{ reg_no:"1646", name:"Onjima Bashir", gender: "M", field:"Civil", date: "Apr/06/2023" ,type:"PERMANENT" },
{ reg_no:"1647", name:"Kinyanjui Matthew Stanley", gender: "M", field:"Chemical", date: "Apr/06/2023" ,type:"PERMANENT" },
{ reg_no:"1648", name:"Sinandugu Silvaster", gender: "M", field:"Civil", date: "Apr/06/2023" ,type:"PERMANENT" },
{ reg_no:"1649", name:"Matsiko Osbert", gender: "M", field:"Mechanical", date: "Apr/20/2023" ,type:"PERMANENT" },
{ reg_no:"1650", name:"Asiimwe Evelyne Pamella", gender: "F", field:"Civil", date: "Apr/20/2023" ,type:"PERMANENT" },
{ reg_no:"1651", name:"Ssentongo Rogers", gender: "M", field:"Civil", date: "May/03/2023" ,type:"PERMANENT" },
{ reg_no:"1652", name:"Tugumenawe Evarist", gender: "M", field:"Civil", date: "May/03/2023" ,type:"PERMANENT" },
{ reg_no:"1653", name:"Tayebwa Julius Arnold", gender: "M", field:"Civil", date: "May/03/2023" ,type:"PERMANENT" },
{ reg_no:"1654", name:"Turyomurugyendo Stephen", gender: "M", field:"Civil", date: "May/03/2023" ,type:"PERMANENT" },
{ reg_no:"1655", name:"Kitakufe Ronald", gender: "M", field:"Environmental", date: "May/03/2023" ,type:"PERMANENT" },
{ reg_no:"1656", name:"Kimuli Kizza Ismail", gender: "M", field:"Agricultural", date: "May/04/2023" ,type:"PERMANENT" },
{ reg_no:"1657", name:"Dr. Lwanyaga Joseph Ddumba", gender: "M", field:"Agricultural", date: "May/04/2023" ,type:"PERMANENT" },
{ reg_no:"1658", name:"Wonaira Sheila", gender: "F", field:"Agricultural", date: "May/04/2023" ,type:"PERMANENT" },
{ reg_no:"1659", name:"Asaya Andrew Peter", gender: "M", field:"Civil", date: "Jun/07/2023" ,type:"PERMANENT" },
{ reg_no:"1660", name:"Serunjogi Michael Dodo", gender: "M", field:"Civil", date: "Jun/07/2023" ,type:"PERMANENT" },
{ reg_no:"1661", name:"Rugumayo Mpuuga Bitanywaine", gender: "M", field:"Mechanical", date: "Jun/07/2023" ,type:"PERMANENT" },
{ reg_no:"1662", name:"Enou Emmanuel", gender: "M", field:"Agricultural", date: "Jun/07/2023" ,type:"PERMANENT" },
{ reg_no:"1663", name:"Mugagga David", gender: "M", field:"Civil", date: "Jun/07/2023" ,type:"PERMANENT" },
{ reg_no:"1664", name:"Kamusiime Ambrose", gender: "M", field:"Agricultural", date: "Jun/07/2023" ,type:"PERMANENT" },
{ reg_no:"1665", name:"Kahuma Evelyne", gender: "F", field:"Civil", date: "Jun/08/2023" ,type:"PERMANENT" },
{ reg_no:"1666", name:"Muhumuza Enoch", gender: "M", field:"Civil", date: "Jun/08/2023" ,type:"PERMANENT" },
{ reg_no:"1667", name:"Kiiza Nelson", gender: "M", field:"Mechanical", date: "Jun/08/2023" ,type:"PERMANENT" },
{ reg_no:"1668", name:"Karuhanga Samantha", gender: "F", field:"Electrical", date: "Jun/08/2023" ,type:"PERMANENT" },
{ reg_no:"1669", name:"Muhumuza Cornelius", gender: "M", field:"Civil", date: "Jul/05/2023" ,type:"PERMANENT" },
{ reg_no:"1670", name:"Kwitwa John", gender: "M", field:"Civil", date: "Jul/05/2023" ,type:"PERMANENT" },
{ reg_no:"1671", name:"Mukasa Wilber Herman", gender: "M", field:"Civil", date: "Jul/05/2023" ,type:"PERMANENT" },
{ reg_no:"1672", name:"Mbabazi Vicent", gender: "M", field:"Civil", date: "Jul/05/2023" ,type:"PERMANENT" },
{ reg_no:"1673", name:"Bakidde Amuli", gender: "M", field:"Civil", date: "Jul/05/2023" ,type:"PERMANENT" },
{ reg_no:"1674", name:"Lutalo Samuel Nsalasatta", gender: "M", field:"Mechanical", date: "Jul/05/2023" ,type:"PERMANENT" },
{ reg_no:"1675", name:"Naturinda Phionah", gender: "F", field:"Mechanical", date: "Jul/06/2023" ,type:"PERMANENT" },
{ reg_no:"1676", name:"Ariho Samuel", gender: "M", field:"Civil", date: "Jul/06/2023" ,type:"PERMANENT" },
{ reg_no:"1677", name:"Okello Emmanuel Daniel ", gender: "M", field:"Electrical", date: "Jul/06/2023" ,type:"PERMANENT" },
{ reg_no:"1678", name:"Atwiine Nelson", gender: "M", field:"Civil", date: "Jul/06/2023" ,type:"PERMANENT" },
{ reg_no:"1679", name:"Niyonzima Emmanuel", gender: "M", field:"Civil", date: "Jul/06/2023" ,type:"PERMANENT" },
{ reg_no:"1680", name:"Buyambi Anthony", gender: "M", field:"Mechanical", date: "Jul/06/2023" ,type:"PERMANENT" },
{ reg_no:"1681", name:"Madanda Richard", gender: "M", field:"Electrical", date: "Jul/06/2023" ,type:"PERMANENT" },
{ reg_no:"1682", name:"Mugabi Julius", gender: "M", field:"Mechanical", date: "Jul/06/2023" ,type:"PERMANENT" },
{ reg_no:"1683", name:"Ssali John", gender: "M", field:"Electrical", date: "Jul/07/2023" ,type:"PERMANENT" },
{ reg_no:"1684", name:"Kinene James ", gender: "M", field:"Agricultural", date: "Jul/07/2023" ,type:"PERMANENT" },
{ reg_no:"1685", name:"Nalubega MariaAssumpta", gender: "F", field:"Mechanical", date: "Jul/07/2023" ,type:"PERMANENT" },
{ reg_no:"1686", name:"Kwesiga Bryan Buhwezi", gender: "M", field:"Civil", date: "Aug/04/2023" ,type:"PERMANENT" },
{ reg_no:"1687", name:"Odong Patrick Parsley", gender: "M", field:"Civil", date: "Aug/04/2023" ,type:"PERMANENT" },
{ reg_no:"1688", name:"Tindimwebwa Jonadab ", gender: "M", field:"Civil", date: "Aug/04/2023" ,type:"PERMANENT" },
{ reg_no:"1689", name:"Yiga Simon", gender: "M", field:"Civil", date: "Aug/04/2023" ,type:"PERMANENT" },
{ reg_no:"1690", name:"Kirabo Mutebi Angella", gender: "F", field:"Mechanical", date: "Aug/04/2023" ,type:"PERMANENT" },
{ reg_no:"1691", name:"Namuleme Annet", gender: "F", field:"Civil", date: "Aug/04/2023" ,type:"PERMANENT" },
{ reg_no:"1692", name:"Mwine Musa", gender: "M", field:"Civil", date: "Aug/07/2023" ,type:"PERMANENT" },
{ reg_no:"1693", name:"Nyakundi Wilfred Lumumba Omari", gender: "M", field:"Civil", date: "Aug/07/2023" ,type:"PERMANENT" },
{ reg_no:"1694", name:"Muliisa Simon Peter Byenkya", gender: "M", field:"Civil", date: "Aug/07/2023" ,type:"PERMANENT" },
{ reg_no:"1695", name:"Omeke Joseph Andrew", gender: "M", field:"Electrical", date: "Aug/07/2023" ,type:"PERMANENT" },
{ reg_no:"1696", name:"Kabuye John Bosco", gender: "M", field:"Mechanical", date: "Aug/07/2023" ,type:"PERMANENT" },
{ reg_no:"1697", name:"Kirya Godfrey", gender: "M", field:"Civil", date: "Aug/07/2023" ,type:"PERMANENT" },
{ reg_no:"1698", name:"Kirabo Isaac", gender: "M", field:"Civil", date: "Aug/07/2023" ,type:"PERMANENT" },
{ reg_no:"1699", name:"Ndyamusiima Archangel", gender: "M", field:"Civil", date: "Sep/06/2023" ,type:"PERMANENT" },
{ reg_no:"1700", name:"Mugavu Martin", gender: "M", field:"Civil", date: "Sep/06/2023" ,type:"PERMANENT" },
{ reg_no:"1701", name:"Nuwamanya Hangson Sulait", gender: "M", field:"Civil", date: "Sep/06/2023" ,type:"PERMANENT" },
{ reg_no:"1702", name:"Okello Francis Defrano", gender: "M", field:"Civil", date: "Sep/06/2023" ,type:"PERMANENT" },
{ reg_no:"1703", name:"Ayebazibwe Mishaki", gender: "M", field:"Civil", date: "Sep/06/2023" ,type:"PERMANENT" },
{ reg_no:"1704", name:"Matovu Rogers ", gender: "M", field:"Civil", date: "Sep/07/2023" ,type:"PERMANENT" },
{ reg_no:"1705", name:"Ddungu Ibrahim", gender: "M", field:"Civil", date: "Sep/07/2023" ,type:"PERMANENT" },
{ reg_no:"1706", name:"Turyasingura Amuza", gender: "M", field:"Electrical", date: "Sep/07/2023" ,type:"PERMANENT" },
{ reg_no:"1707", name:"Mudanye Tito", gender: "M", field:"Civil", date: "Sep/08/2023" ,type:"PERMANENT" },
{ reg_no:"1708", name:"Turyaremera Silver", gender: "M", field:"Civil", date: "Sep/08/2023" ,type:"PERMANENT" },
{ reg_no:"1709", name:"Nakanwagi Christine", gender: "F", field:"Electrical", date: "Sep/08/2023" ,type:"PERMANENT" },
{ reg_no:"1710", name:"Ssekidde Moses", gender: "M", field:"Telecommunication", date: "Sep/08/2023" ,type:"PERMANENT" },
{ reg_no:"1711", name:"Balamaga Njiri Julius", gender: "M", field:"Electrical", date: "Sep/08/2023" ,type:"PERMANENT" },
{ reg_no:"1712", name:"Nakabugo Diana", gender: "F", field:"Electrical", date: "Sep/08/2023" ,type:"PERMANENT" },
{ reg_no:"1713", name:"Muhamya Horace", gender: "M", field:"Civil", date: "Sep/11/2023" ,type:"PERMANENT" },
{ reg_no:"1714", name:"Bulolo Sam (Dr.)", gender: "M", field:"Civil", date: "Sep/11/2023" ,type:"PERMANENT" },
{ reg_no:"1715", name:"Mugula Julius", gender: "M", field:"Civil", date: "Oct/04/2023" ,type:"PERMANENT" },
{ reg_no:"1716", name:"Muruhura Wycliffe", gender: "M", field:"Civil", date: "Oct/04/2023" ,type:"PERMANENT" },
{ reg_no:"1717", name:"Mugungu Helaman Johnson", gender: "M", field:"Civil", date: "Oct/04/2023" ,type:"PERMANENT" },
{ reg_no:"1718", name:"Uyirwoth Innocent", gender: "M", field:"Mechanical", date: "Oct/04/2023" ,type:"PERMANENT" },
{ reg_no:"1719", name:"Otyeka Denis", gender: "M", field:"Mechanical", date: "Oct/04/2023" ,type:"PERMANENT" },
{ reg_no:"1720", name:"Andinda Daniel", gender: "M", field:"Civil", date: "Oct/04/2023" ,type:"PERMANENT" },
{ reg_no:"1721", name:"Muhairwe Kenedy", gender: "M", field:"Civil", date: "Oct/04/2023" ,type:"PERMANENT" },
{ reg_no:"1722", name:"Matovu Muzafaluh", gender: "M", field:"Mechanical", date: "Oct/05/2023" ,type:"PERMANENT" },
{ reg_no:"1723", name:"Alima Dennis", gender: "M", field:"Civil", date: "Oct/05/2023" ,type:"PERMANENT" },
{ reg_no:"1724", name:"Kyalisiima Irene", gender: "F", field:"Civil", date: "Oct/05/2023" ,type:"PERMANENT" },
{ reg_no:"1725", name:"Muzinya Edgar", gender: "M", field:"Civil", date: "Oct/05/2023" ,type:"PERMANENT" },
{ reg_no:"1726", name:"Ayella Godfrey ", gender: "M", field:"Electrical", date: "Oct/05/2023" ,type:"PERMANENT" },
{ reg_no:"1727", name:"Kaigumba Paddy ", gender: "M", field:"Electrical", date: "Oct/06/2023" ,type:"PERMANENT" },
{ reg_no:"1728", name:"Olinga James Bonn", gender: "M", field:"Civil", date: "Oct/06/2023" ,type:"PERMANENT" },
{ reg_no:"1729", name:"Akampamya Bruce ", gender: "M", field:"Mechanical", date: "Oct/27/2023" ,type:"PERMANENT" },
{ reg_no:"1730", name:"Muyama Moreen Cynthia", gender: "F", field:"Civil", date: "Nov/01/2023" ,type:"PERMANENT" },
{ reg_no:"1731", name:"Kafeero Ssekitoleko Robert", gender: "M", field:"Mechanical", date: "Nov/01/2023" ,type:"PERMANENT" },
{ reg_no:"1732", name:"Mugabi Raymond", gender: "M", field:"Civil", date: "Nov/01/2023" ,type:"PERMANENT" },
{ reg_no:"1733", name:"Inyensiko George", gender: "M", field:"Civil", date: "Nov/02/2023" ,type:"PERMANENT" },
{ reg_no:"1734", name:"Byaruhanga Albert Musoke", gender: "M", field:"Civil", date: "Nov/02/2023" ,type:"PERMANENT" },
{ reg_no:"1735", name:"Odokonyero James", gender: "M", field:"Civil", date: "Nov/02/2023" ,type:"PERMANENT" },
{ reg_no:"1736", name:"Itungo Iris Twirire", gender: "F", field:"Electrical", date: "Nov/02/2023" ,type:"PERMANENT" },
{ reg_no:"1737", name:"Semahoro Aloysius", gender: "M", field:"Civil", date: "Nov/02/2023" ,type:"PERMANENT" },
{ reg_no:"1738", name:"Opio Kenneth", gender: "M", field:"Civil", date: "Nov/02/2023" ,type:"PERMANENT" },
{ reg_no:"1739", name:"Nsubuga Hannington", gender: "M", field:"Electrical", date: "Nov/02/2023" ,type:"PERMANENT" },
{ reg_no:"1740", name:"Nalumansi Ritah", gender: "F", field:"Civil", date: "Nov/02/2023" ,type:"PERMANENT" },
{ reg_no:"1741", name:"Ssekitoleko Ronald", gender: "M", field:"Civil", date: "Nov/02/2023" ,type:"PERMANENT" },
{ reg_no:"1742", name:"Dr. Nansubuga Irene Genevieve", gender: "F", field:"Civil", date: "Nov/02/2023" ,type:"PERMANENT" },
{ reg_no:"1743", name:"Singoma Dennis", gender: "M", field:"Civil", date: "Nov/02/2023" ,type:"PERMANENT" },
{ reg_no:"1744", name:"Musiime Emmer Rava", gender: "F", field:"Electrical", date: "Nov/02/2023" ,type:"PERMANENT" },
{ reg_no:"1745", name:"Byaruhanga Augustine", gender: "M", field:"Civil", date: "Nov/03/2023" ,type:"PERMANENT" },
{ reg_no:"1746", name:"Wabwire Stephen", gender: "M", field:"Civil", date: "Nov/03/2023" ,type:"PERMANENT" },
{ reg_no:"1747", name:"Ssenyonjo Abudallah", gender: "M", field:"Electrical", date: "Nov/03/2023" ,type:"PERMANENT" },
{ reg_no:"1748", name:"Sebbaale Derrick", gender: "M", field:"Electrical", date: "Dec/06/2023" ,type:"PERMANENT" },
{ reg_no:"1749", name:"Karekaho Mugisha Allan", gender: "M", field:"Civil", date: "Dec/06/2023" ,type:"PERMANENT" },
{ reg_no:"1750", name:"Nduhuura Jensen", gender: "M", field:"Electrical", date: "Dec/06/2023" ,type:"PERMANENT" },
{ reg_no:"1751", name:"Dr. Kimera David", gender: "M", field:"Mechanical", date: "Dec/06/2023" ,type:"PERMANENT" },
{ reg_no:"1752", name:"Ngolobe Julius Geoffrey ", gender: "M", field:"Electrical", date: "Dec/06/2023" ,type:"PERMANENT" },
{ reg_no:"1753", name:"Kigonya Mutebi Ronald", gender: "M", field:"Civil", date: "Dec/06/2023" ,type:"PERMANENT" },
{ reg_no:"1754", name:"Ongur Godfrey Kerfua", gender: "M", field:"Electrical", date: "Dec/07/2023" ,type:"PERMANENT" },
{ reg_no:"1755", name:"Kibirige Moses", gender: "M", field:"Civil", date: "Dec/07/2023" ,type:"PERMANENT" },
{ reg_no:"1756", name:"Sendifa George William", gender: "M", field:"Electrical", date: "Dec/07/2023" ,type:"PERMANENT" },
{ reg_no:"1757", name:"Oweka David", gender: "M", field:"Electrical", date: "Dec/07/2023" ,type:"PERMANENT" },
{ reg_no:"1758", name:"Nuwagaba Eric", gender: "M", field:"Telecommunication", date: "Dec/07/2023" ,type:"PERMANENT" },
{ reg_no:"1759", name:"Mukuye Christopher Job", gender: "M", field:"Civil", date: "Dec/07/2023" ,type:"PERMANENT" },
{ reg_no:"1760", name:"Arinaitwe Emmy Bikumu ", gender: "M", field:"Civil", date: "Dec/08/2023" ,type:"PERMANENT" },
{ reg_no:"1761", name:"Kakeeto Godfrey", gender: "M", field:"Telecommunication", date: "Dec/08/2023" ,type:"PERMANENT" },
{ reg_no:"1762", name:"Sevume Allan", gender: "M", field:"Civil", date: "Dec/08/2023" ,type:"PERMANENT" },
{ reg_no:"1763", name:"Nanyunja Sylvia", gender: "F", field:"Civil", date: "Dec/28/2023" ,type:"PERMANENT" },
{ reg_no:"1764", name:"Gidongo Sam", gender: "M", field:"Civil", date: "Jan/10/2024" ,type:"PERMANENT" },
{ reg_no:"1765", name:"Kawuuki Timothy", gender: "M", field:"Civil", date: "Jan/10/2024" ,type:"PERMANENT" },
{ reg_no:"1766", name:"Lule Ivan", gender: "M", field:"Civil", date: "Jan/10/2024" ,type:"PERMANENT" },
{ reg_no:"1767", name:"Muhanguzi Peter", gender: "M", field:"Civil", date: "Jan/10/2024" ,type:"PERMANENT" },
{ reg_no:"1768", name:"Mukeera Ibrahim ", gender: "M", field:"Electrical", date: "Jan/10/2024" ,type:"PERMANENT" },
{ reg_no:"1769", name:"Otim Emmanuel Edwaru", gender: "M", field:"Civil", date: "Jan/10/2024" ,type:"PERMANENT" },
{ reg_no:"1770", name:"Namubiru Jolly", gender: "F", field:"Mechanical", date: "Jan/11/2024" ,type:"PERMANENT" },
{ reg_no:"1771", name:"Mutabingwa Martin ", gender: "M", field:"Electrical", date: "Jan/11/2024" ,type:"PERMANENT" },
{ reg_no:"1772", name:"Natumanya Douglas", gender: "M", field:"Civil", date: "Jan/11/2024" ,type:"PERMANENT" },
{ reg_no:"1773", name:"Ouma Godfrey", gender: "M", field:"Agricultural", date: "Jan/11/2024" ,type:"PERMANENT" },
{ reg_no:"1774", name:"Otto James", gender: "M", field:"Mechanical", date: "Jan/11/2024" ,type:"PERMANENT" },
{ reg_no:"1775", name:"Apio Rose", gender: "F", field:"Civil", date: "Jan/12/2024" ,type:"PERMANENT" },
{ reg_no:"1776", name:"Kasoba Albert", gender: "M", field:"Civil", date: "Jan/24/2024" ,type:"PERMANENT" },
{ reg_no:"1777", name:"Kintu Julius Ceaser", gender: "M", field:"Electrical", date: "Feb/07/2024" ,type:"PERMANENT" },
{ reg_no:"1778", name:"Maate Etiene", gender: "M", field:"Industrial", date: "Feb/07/2024" ,type:"PERMANENT" },
{ reg_no:"1779", name:"Dr. Kalibbala Martin", gender: "M", field:"Civil", date: "Feb/07/2024" ,type:"PERMANENT" },
{ reg_no:"1780", name:"Ssonzi Kiwanuka Patrick", gender: "M", field:"Civil", date: "Feb/07/2024" ,type:"PERMANENT" },
{ reg_no:"1781", name:"Sewagudde Sowedi", gender: "M", field:"Agricultural", date: "Feb/07/2024" ,type:"PERMANENT" },
{ reg_no:"1782", name:"Muhangi Gilbert ", gender: "M", field:"Civil", date: "Feb/07/2024" ,type:"PERMANENT" },
{ reg_no:"1783", name:"Mangeni Moses Peter", gender: "M", field:"Electrical", date: "Feb/07/2024" ,type:"PERMANENT" },
{ reg_no:"1784", name:"Wakatama Nicholas", gender: "M", field:"Electrical", date: "Feb/08/2024" ,type:"PERMANENT" },
{ reg_no:"1785", name:"Muwada Huzairu", gender: "M", field:"Electrical", date: "Feb/08/2024" ,type:"PERMANENT" },
{ reg_no:"1786", name:"Mucunguzi Joseph", gender: "M", field:"Civil", date: "Feb/08/2024" ,type:"PERMANENT" },
{ reg_no:"1787", name:"Nakabaale Ivan", gender: "M", field:"Electrical", date: "Feb/08/2024" ,type:"PERMANENT" },
{ reg_no:"1788", name:"Akwede Eunice", gender: "F", field:"Civil", date: "Feb/08/2024" ,type:"PERMANENT" },
{ reg_no:"1789", name:"Ntimba Michael Nyaika", gender: "M", field:"Civil", date: "Feb/08/2024" ,type:"PERMANENT" },
{ reg_no:"1790", name:"Nsubuga Ibrahim", gender: "M", field:"Civil", date: "Mar/13/2024" ,type:"PERMANENT" },
{ reg_no:"1791", name:"Kirya Simon", gender: "M", field:"Electrical", date: "Mar/13/2024" ,type:"PERMANENT" },
{ reg_no:"1792", name:"Mpabaisi Tom Tomson", gender: "M", field:"Civil", date: "Mar/13/2024" ,type:"PERMANENT" },
{ reg_no:"1793", name:"Buluma Melinda Nyangu", gender: "F", field:"Civil", date: "Mar/13/2024" ,type:"PERMANENT" },
{ reg_no:"1794", name:"Masaaba Rogers", gender: "M", field:"Agricultural", date: "Mar/13/2024" ,type:"PERMANENT" },
{ reg_no:"1795", name:"Okidi Patrick", gender: "M", field:"Electrical", date: "Mar/14/2024" ,type:"PERMANENT" },
{ reg_no:"1796", name:"Kirunda Richard Gaster", gender: "M", field:"Electrical", date: "Mar/14/2024" ,type:"PERMANENT" },
{ reg_no:"1797", name:"Mabaale James", gender: "M", field:"Civil", date: "Mar/14/2024" ,type:"PERMANENT" },
{ reg_no:"1798", name:"Bakunzi Martin", gender: "M", field:"Civil", date: "Mar/14/2024" ,type:"PERMANENT" },
{ reg_no:"1799", name:"Ddamulira Patrick Hezekiah", gender: "M", field:"Electrical", date: "Mar/14/2024" ,type:"PERMANENT" },
{ reg_no:"1800", name:"Muyanja Michael Davis", gender: "M", field:"Civil", date: "Mar/14/2024" ,type:"PERMANENT" },
{ reg_no:"1801", name:"Aijuka Akankunda Mugarura", gender: "M", field:"Mechanical", date: "Mar/14/2024" ,type:"PERMANENT" },
{ reg_no:"1802", name:"Weteeka Michael Samuel Tseema", gender: "M", field:"Mechanical", date: "Mar/15/2024" ,type:"PERMANENT" },
{ reg_no:"1803", name:"Kishaija Paul", gender: "M", field:"Civil", date: "Apr/03/2024" ,type:"PERMANENT" },
{ reg_no:"1804", name:"Otai Emmanuel", gender: "M", field:"Civil", date: "Apr/03/2024" ,type:"PERMANENT" },
{ reg_no:"1805", name:"Kaabi William", gender: "M", field:"Civil", date: "Apr/03/2024" ,type:"PERMANENT" },
{ reg_no:"1806", name:"Odwar Costa Moses", gender: "M", field:"Civil", date: "Apr/04/2024" ,type:"PERMANENT" },
{ reg_no:"1807", name:"Tomusange David", gender: "M", field:"Civil", date: "Apr/04/2024" ,type:"PERMANENT" },
{ reg_no:"1808", name:"Birime Patrick", gender: "M", field:"Civil", date: "Apr/04/2024" ,type:"PERMANENT" },
{ reg_no:"1809", name:"Mweteise Samuel ", gender: "M", field:"Civil", date: "Apr/04/2024" ,type:"PERMANENT" },
{ reg_no:"1810", name:"Kibuule Brian", gender: "M", field:"Civil", date: "Apr/04/2024" ,type:"PERMANENT" },
{ reg_no:"1811", name:"Bwambale Mulhondi Isaac", gender: "M", field:"Civil", date: "Apr/04/2024" ,type:"PERMANENT" },
{ reg_no:"1812", name:"Aboth Yakoba", gender: "F", field:"Civil", date: "Apr/04/2024" ,type:"PERMANENT" },
{ reg_no:"1813", name:"Kwitegetse Penlope", gender: "M", field:"Environmental", date: "Apr/05/2024" ,type:"PERMANENT" },
{ reg_no:"1814", name:"Manana Martin Hunter", gender: "M", field:"Electrical", date: "May/08/2024" ,type:"PERMANENT" },
{ reg_no:"1815", name:"Mori Artur", gender: "M", field:"Mechanical", date: "May/08/2024" ,type:"PERMANENT" },
{ reg_no:"1816", name:"Tumuhimbise Daniel", gender: "M", field:"Electrical", date: "May/08/2024" ,type:"PERMANENT" },
{ reg_no:"1817", name:"Dr. Edimu Milton", gender: "M", field:"Electrical", date: "May/08/2024" ,type:"PERMANENT" },
{ reg_no:"1818", name:"Karugaba Amuli", gender: "M", field:"Civil", date: "May/08/2024" ,type:"PERMANENT" },
{ reg_no:"1819", name:"Mutekanga Samuel Tonny", gender: "M", field:"Electrical", date: "May/08/2024" ,type:"PERMANENT" },
{ reg_no:"1820", name:"Kavuma Ian John ", gender: "M", field:"Mechanical", date: "May/08/2024" ,type:"PERMANENT" },
{ reg_no:"1821", name:"Nyesigire Resty", gender: "F", field:"Civil", date: "May/08/2024" ,type:"PERMANENT" },
{ reg_no:"1822", name:"Nayiga Zainah", gender: "F", field:"Electrical", date: "May/08/2024" ,type:"PERMANENT" },
{ reg_no:"1823", name:"Ddumba Nathan Mayanja", gender: "M", field:"Civil", date: "May/08/2024" ,type:"PERMANENT" },
{ reg_no:"1824", name:"Ogwal Isaac", gender: "M", field:"Civil", date: "May/09/2024" ,type:"PERMANENT" },
{ reg_no:"1825", name:"Bigabwa Bategeka Moses", gender: "M", field:"Civil", date: "May/09/2024" ,type:"PERMANENT" },
{ reg_no:"1826", name:"Rutebarika Frank", gender: "M", field:"Civil", date: "May/09/2024" ,type:"PERMANENT" },
{ reg_no:"1827", name:"Kadaali Andrew", gender: "M", field:"Civil", date: "May/09/2024" ,type:"PERMANENT" },
{ reg_no:"1828", name:"Kisuule Mikka", gender: "M", field:"Electrical", date: "May/09/2024" ,type:"PERMANENT" },
{ reg_no:"1829", name:"Mumali Christopher Mulama", gender: "M", field:"Electrical", date: "May/09/2024" ,type:"PERMANENT" },
{ reg_no:"1830", name:"Wakachunga Edmond Saratuki", gender: "M", field:"Mechanical", date: "May/09/2024" ,type:"PERMANENT" },
{ reg_no:"1831", name:"Ngobi Joshua", gender: "M", field:"Civil", date: "May/09/2024" ,type:"PERMANENT" },
{ reg_no:"1832", name:"Nalubowa Oliver", gender: "F", field:"Civil", date: "May/09/2024" ,type:"PERMANENT" },
{ reg_no:"1833", name:"Twesigye Paddy Bernard", gender: "M", field:"Civil", date: "Jun/05/2024" ,type:"PERMANENT" },
{ reg_no:"1834", name:"Eonya Julius Elolu", gender: "M", field:"Civil", date: "Jun/05/2024" ,type:"PERMANENT" },
{ reg_no:"1835", name:"Barya Johnson", gender: "M", field:"Automotive & Power", date: "Jun/05/2024" ,type:"PERMANENT" },
{ reg_no:"1836", name:"Nuwamanya Goden", gender: "M", field:"Agricultural", date: "Jun/05/2024" ,type:"PERMANENT" },
{ reg_no:"1837", name:"Tibenderana Philip", gender: "M", field:"Civil", date: "Jun/05/2024" ,type:"PERMANENT" },
{ reg_no:"1838", name:"Gonza Daniel Samuel Wankiiri", gender: "M", field:"Civil", date: "Jun/05/2024" ,type:"PERMANENT" },
{ reg_no:"1839", name:"Akol David", gender: "M", field:"Civil", date: "Jun/05/2024" ,type:"PERMANENT" },
{ reg_no:"1840", name:"Safali Israel", gender: "M", field:"Civil", date: "Jun/05/2024" ,type:"PERMANENT" },
{ reg_no:"1841", name:"Kamoga James Mary", gender: "M", field:"Civil", date: "Jun/05/2024" ,type:"PERMANENT" },
{ reg_no:"1842", name:"Mukalazi Jimmy", gender: "M", field:"Electrical", date: "Jun/05/2024" ,type:"PERMANENT" },
{ reg_no:"1843", name:"Isabirye Fred", gender: "M", field:"Mechanical", date: "Jun/05/2024" ,type:"PERMANENT" },
{ reg_no:"1844", name:"Muhumuza Esther Caroline", gender: "F", field:"Civil", date: "Jun/05/2024" ,type:"PERMANENT" },
{ reg_no:"1845", name:"Yiga Francis", gender: "M", field:"Civil", date: "Jun/06/2024" ,type:"PERMANENT" },
{ reg_no:"1846", name:"Bateebe Irene Pauline", gender: "F", field:"Chemical", date: "Jun/06/2024" ,type:"PERMANENT" },
{ reg_no:"1847", name:"Maganda David", gender: "M", field:"Electrical", date: "Jun/06/2024" ,type:"PERMANENT" },
{ reg_no:"1848", name:"Katende Fred Serunjogi", gender: "M", field:"Mechanical", date: "Jun/06/2024" ,type:"PERMANENT" },
{ reg_no:"1849", name:"Kanyike Tom", gender: "M", field:"Civil", date: "Jun/06/2024" ,type:"PERMANENT" },
{ reg_no:"1850", name:"Mukaaya Denis", gender: "M", field:"Civil", date: "Jun/06/2024" ,type:"PERMANENT" },
{ reg_no:"1851", name:"Gamubaka Fred Cedric", gender: "M", field:"Civil", date: "Jun/06/2024" ,type:"PERMANENT" },
{ reg_no:"1852", name:"Wakiibi Ceaser Kisa", gender: "M", field:"Civil", date: "Jun/06/2024" ,type:"PERMANENT" },
{ reg_no:"1853", name:"Kwihangana Michael", gender: "M", field:"Civil", date: "Jul/03/2024" ,type:"PERMANENT" },
{ reg_no:"1854", name:"Nshemereirwe Ephrance", gender: "F", field:"Civil", date: "Jul/03/2024" ,type:"PERMANENT" },
{ reg_no:"1855", name:"Olanya Tonny", gender: "M", field:"Civil", date: "Jul/03/2024" ,type:"PERMANENT" },
{ reg_no:"1856", name:"Kayemba Yasin", gender: "M", field:"Civil", date: "Jul/03/2024" ,type:"PERMANENT" },
{ reg_no:"1857", name:"Kakooza Abudkarim Kaweesi", gender: "M", field:"Civil", date: "Jul/03/2024" ,type:"PERMANENT" },
{ reg_no:"1858", name:"Karyeija Geoffrey", gender: "M", field:"Civil", date: "Jul/03/2024" ,type:"PERMANENT" },
{ reg_no:"1859", name:"Mutesa Justus", gender: "M", field:"Civil", date: "Jul/03/2024" ,type:"PERMANENT" },
{ reg_no:"1860", name:"Atwine Alison Brian", gender: "M", field:"Electrical", date: "Jul/03/2024" ,type:"PERMANENT" },
{ reg_no:"1861", name:"Tayebwa Asaph Kamau", gender: "M", field:"Civil", date: "Jul/03/2024" ,type:"PERMANENT" },
{ reg_no:"1862", name:"Magezi Wilson", gender: "M", field:"Civil", date: "Jul/03/2024" ,type:"PERMANENT" },
{ reg_no:"1863", name:"Kabanda Patrick", gender: "M", field:"Electrical", date: "Jul/03/2024" ,type:"PERMANENT" },
{ reg_no:"1864", name:"Kiyingi Duncan", gender: "M", field:"Agricultural", date: "Jul/04/2024" ,type:"PERMANENT" },
{ reg_no:"1865", name:"Kihika Emily Kogere", gender: "F", field:"Mechanical", date: "Jul/04/2024" ,type:"PERMANENT" },
{ reg_no:"1866", name:"Mwesigwa Shafik", gender: "M", field:"Civil", date: "Jul/04/2024" ,type:"PERMANENT" },
{ reg_no:"1867", name:"Tumukunde Gibson", gender: "M", field:"Civil", date: "Jul/04/2024" ,type:"PERMANENT" },
{ reg_no:"1868", name:"Kisakye Samuel", gender: "M", field:"Civil", date: "Jul/04/2024" ,type:"PERMANENT" },
{ reg_no:"1869", name:"Kajubi Enock", gender: "M", field:"Water Resources", date: "Jul/05/2024" ,type:"PERMANENT" },
{ reg_no:"ETN 1", name:"Kiroko Shalif", gender: "M", field:"Civil", date: "May/17/2024" ,type:"TECHNICIAN" },
{ reg_no:"ETN 2", name:"Hirani Devshi Karshan", gender: "M", field:"Civil", date: "Jun/05/2024" ,type:"TECHNICIAN" },
{ reg_no:"ETN 3", name:"Oyet Ben", gender: "M", field:"Civil", date: "Jul/04/2024" ,type:"TECHNICIAN" },
{ reg_no:"ETG 1", name:"Asaba Lawrence", gender: "M", field:"Civil", date: "Apr/04/2024" ,type:"TECHNOLOGIST" },
{ reg_no:"ETG 2", name:"Twesigomwe David", gender: "M", field:"Civil", date: "Apr/04/2024" ,type:"TECHNOLOGIST" },
{ reg_no:"ETG 3", name:"Obolin John", gender: "M", field:"Civil", date: "Apr/22/2024" ,type:"TECHNOLOGIST" },
{ reg_no:"ETG 4", name:"Katerega Joseph ", gender: "M", field:"Civil", date: "May/08/2024" ,type:"TECHNOLOGIST" },
{ reg_no:"ETG 5", name:"Nsadhu Shafik ", gender: "M", field:"Civil", date: "May/09/2024" ,type:"TECHNOLOGIST" },
{ reg_no:"ETG 6", name:"Nalumansi Jemima", gender: "F", field:"Civil", date: "Jun/05/2024" ,type:"TECHNOLOGIST" },
{ reg_no:"TR 12", name:"Ketema Tariku Abate", gender: "M", field:"Civil", date: "May/07/2010" ,type:"TEMPORARY" },
{ reg_no:"TR 27", name:"Tesfaye Gelahun Taye", gender: "M", field:"Civil", date: "Jan/25/2012" ,type:"TEMPORARY" },
{ reg_no:"TR 35", name:"Beruk Berhane Ayele", gender: "M", field:"Civil", date: "Feb/10/2016" ,type:"TEMPORARY" },
{ reg_no:"TR 38", name:"Alem Bemnet Yohans", gender: "M", field:"Civil", date: "Apr/25/2016" ,type:"TEMPORARY" },
{ reg_no:"TR 39", name:"Fesshaye, Berhe Sebhatu", gender: "M", field:"Civil", date: "Apr/25/2016" ,type:"TEMPORARY" },
{ reg_no:"TR 41", name:"Di Modica, Salvatore", gender: "M", field:"Civil", date: "May/23/2016" ,type:"TEMPORARY" },
{ reg_no:"TR 49", name:"Lemma Temesgen Gebre", gender: "M", field:"Civil", date: "Oct/04/2016" ,type:"TEMPORARY" },
{ reg_no:"TR 52", name:"Yu Meng", gender: "M", field:"Civil", date: "Dec/13/2016" ,type:"TEMPORARY" },
{ reg_no:"TR 54", name:"Slobodan Blagojevic", gender: "M", field:"Civil", date: "Mar/10/2017" ,type:"TEMPORARY" },
{ reg_no:"TR 55", name:"Gorachinov Mihail", gender: "M", field:"Civil", date: "Mar/10/2017" ,type:"TEMPORARY" },
{ reg_no:"TR 57", name:"Debmalya Bhar", gender: "M", field:"Civil", date: "Mar/10/2017" ,type:"TEMPORARY" },
{ reg_no:"TR 58", name:"Buchbut Shalom Sharli", gender: "M", field:"Civil", date: "Mar/27/2017" ,type:"TEMPORARY" },
{ reg_no:"TR 59", name:"Amin Hammoud", gender: "M", field:"Civil", date: "Mar/27/2017" ,type:"TEMPORARY" },
{ reg_no:"TR 72", name:"Uppalapati Vijaya Bhaskar", gender: "M", field:"Civil", date: "Sep/19/2017" ,type:"TEMPORARY" },
{ reg_no:"TR 73", name:"Simbiso Chimbima", gender: "M", field:"Electrical", date: "Oct/16/2017" ,type:"TEMPORARY" },
{ reg_no:"TR 74", name:"Balasubramanian Chandrasekara Bharathy", gender: "M", field:"Mechanical", date: "Nov/01/2017" ,type:"TEMPORARY" },
{ reg_no:"TR 79", name:"Mao Jiawei ", gender: "M", field:"Civil", date: "Nov/28/2017" ,type:"TEMPORARY" },
{ reg_no:"TR 80", name:"Feng Junpeng", gender: "M", field:"Civil", date: "Dec/19/2017" ,type:"TEMPORARY" },
{ reg_no:"TR 81", name:"Ju Wenjie", gender: "M", field:"Civil", date: "Dec/19/2017" ,type:"TEMPORARY" },
{ reg_no:"TR 82", name:"Li Jincheng", gender: "M", field:"Civil", date: "Dec/19/2017" ,type:"TEMPORARY" },
{ reg_no:"TR 84", name:"Li Qinpu", gender: "M", field:"Civil", date: "Dec/19/2017" ,type:"TEMPORARY" },
{ reg_no:"TR 89", name:"Le Nouvel Pierre - Bertrand Henri", gender: "M", field:"Civil", date: "Feb/21/2018" ,type:"TEMPORARY" },
{ reg_no:"TR 95", name:"Pinaki Roychowdhury", gender: "M", field:"Civil", date: "Jun/25/2018" ,type:"TEMPORARY" },
{ reg_no:"TR 102", name:"Lars Peter Jensen", gender: "M", field:"Mechanical", date: "Jul/17/2018" ,type:"TEMPORARY" },
{ reg_no:"TR 104", name:"Chen Yao", gender: "M", field:"Civil", date: "Aug/14/2018" ,type:"TEMPORARY" },
{ reg_no:"TR 110", name:"Ma Rui", gender: "M", field:"Civil", date: "Oct/05/2018" ,type:"TEMPORARY" },
{ reg_no:"TR 112", name:"Subir Kumar Roy", gender: "M", field:"Civil", date: "Mar/15/2019" ,type:"TEMPORARY" },
{ reg_no:"TR 115", name:"Biswajit Mallik", gender: "M", field:"Civil", date: "Jun/28/2019" ,type:"TEMPORARY" },
{ reg_no:"TR 107", name:"Mahmoud Ismael", gender: "M", field:"Civil", date: "Sept/27/2018" ,type:"TEMPORARY" },
{ reg_no:"TR 120", name:"Guo Haibo", gender: "M", field:"Civil", date: "Jul/24/2019" ,type:"TEMPORARY" },
{ reg_no:"TR 121", name:"Manonandana A.P. Wickramaratna", gender: "M", field:"Civil", date: "Oct/23/2019" ,type:"TEMPORARY" },
{ reg_no:"TR 122", name:"Semma Tarekegn Fenta", gender: "M", field:"Civil", date: "Nov/27/2019" ,type:"TEMPORARY" },
{ reg_no:"TR 123", name:"Gaurang Rohitbhai Patel", gender: "M", field:"Electrical", date: "Dec/09/2019" ,type:"TEMPORARY" },
{ reg_no:"TR 126", name:"Joern Michael Seitz", gender: "M", field:"Civil", date: "Aug/14/2020" ,type:"TEMPORARY" },
{ reg_no:"TR 129", name:"Vinnakota Trinadh", gender: "M", field:"Civil", date: "Dec/17/2020" ,type:"TEMPORARY" },
{ reg_no:"TR 133", name:"Guo Can", gender: "M", field:"Civil", date: "Feb/24/2021" ,type:"TEMPORARY" },
{ reg_no:"TR 135", name:"Lu Kai", gender: "M", field:"Civil", date: "Mar/09/2021" ,type:"TEMPORARY" },
{ reg_no:"TR 140", name:"Xiong Pailiang", gender: "M", field:"Civil", date: "Jun/08/2021" ,type:"TEMPORARY" },
{ reg_no:"TR 145", name:"Chi Yuyi", gender: "M", field:"Civil", date: "Aug/06/2021" ,type:"TEMPORARY" },
{ reg_no:"TR 147", name:"Bhavesh Knuvarjibhai Pindoria", gender: "M", field:"Civil", date: "Aug/10/2021" ,type:"TEMPORARY" },
{ reg_no:"TR 149", name:"Huang Xiaolong", gender: "M", field:"Civil", date: "Sep/09/2021" ,type:"TEMPORARY" },
{ reg_no:"TR 150", name:"Zheng Guijin", gender: "M", field:"Civil", date: "Sep/09/2021" ,type:"TEMPORARY" },
{ reg_no:"TR 151", name:"Yang Xiang", gender: "M", field:"Civil", date: "Sep/09/2021" ,type:"TEMPORARY" },
{ reg_no:"TR 155", name:"Safet Keco", gender: "M", field:"Civil", date: "Mar/14/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 156", name:"Wasin M. Diego Jr.", gender: "M", field:"Civil", date: "May/13/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 157", name:"Shimeles Molla Tsegaye", gender: "M", field:"Civil", date: "May/24/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 158", name:"Bamboat Hussain Shabbir", gender: "M", field:"Civil", date: "Jul/08/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 162", name:"Taddele Derese Beyene", gender: "M", field:"Civil", date: "Jul/27/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 164", name:"Narinder Kumar", gender: "M", field:"Electrical", date: "Sep/09/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 165", name:"Selemon Girmachew Yohannes", gender: "M", field:"Civil", date: "Oct/13/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 166", name:"Liu Guang", gender: "M", field:"Civil", date: "Oct/20/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 167", name:"Kanakogi Kiyoshi", gender: "M", field:"Civil", date: "Oct/26/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 168", name:"Yanase Takashi", gender: "M", field:"Electrical", date: "Oct/26/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 169", name:"Kondo Kazuaki", gender: "M", field:"Electrical", date: "Oct/28/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 170", name:"Rahmatalla Mohamed Musa Mohamed", gender: "M", field:"Civil", date: "Nov/07/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 172", name:"Alexander Michael Stephens", gender: "M", field:"Mechanical", date: "Dec/12/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 173", name:"Akira Niwa (Dr.)", gender: "M", field:"Civil", date: "Dec/27/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 174", name:"Hiromichi Okumo", gender: "M", field:"Electrical", date: "Dec/27/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 175", name:"Masanobu Kaminaga", gender: "M", field:"Electrical", date: "Dec/27/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 176", name:"Masayuki Tamai", gender: "M", field:"Electrical", date: "Dec/27/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 177", name:"Fumiyasu Minagwa ", gender: "M", field:"Electrical", date: "Dec/27/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 178", name:"Tsukasa Midorikawa", gender: "M", field:"Electrical", date: "Dec/27/2022" ,type:"TEMPORARY" },
{ reg_no:"TR 179", name:"Bamba Kanvaly", gender: "M", field:"Mechanical", date: "Jan/19/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 180", name:"Sarfaraz Sultanali Jiwani", gender: "M", field:"Civil", date: "Jan/25/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 182", name:"Li Wenqiang", gender: "M", field:"Civil", date: "Apr/11/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 183", name:"Li Wangxiang", gender: "M", field:"Civil", date: "May/04/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 185", name:"Pawar Laxman Gangaram", gender: "M", field:"Civil", date: "May/05/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 187", name:"Cavelti Ursin Luis ", gender: "M", field:"Civil", date: "May/08/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 188", name:"Nishantha Priyanandana Maddapitigala", gender: "M", field:"Civil", date: "Aug/15/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 189", name:"Tadesse Melaku Wolde", gender: "M", field:"Civil", date: "Sep/08/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 190", name:"Rosskhin Alexander ", gender: "M", field:"Civil", date: "Sep/25/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 191", name:"Anurag Kalavagunta", gender: "M", field:"Civil", date: "Sep/25/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 192", name:"Peng Min", gender: "M", field:"Civil", date: "Oct/05/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 195", name:"Behonegne Arega Ayalew", gender: "M", field:"Civil", date: "Oct/06/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 196", name:"Jiao Junfeng", gender: "M", field:"Civil", date: "Nov/02/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 197", name:"Jing Haibin", gender: "M", field:"Civil", date: "Nov/02/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 198", name:"Li Hongyan ", gender: "M", field:"Civil", date: "Nov/02/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 199", name:"Wu Jianchuan", gender: "M", field:"Civil", date: "Nov/02/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 200", name:"Awalom Daniel Mengsteab", gender: "M", field:"Electrical", date: "Dec/11/2023" ,type:"TEMPORARY" },
{ reg_no:"TR 201", name:"Chen Zhen", gender: "M", field:"Civil", date: "Jan/10/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 202", name:"Li Jie", gender: "M", field:"Civil", date: "Jan/10/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 203", name:"Xiao Maolin", gender: "M", field:"Civil", date: "Jan/10/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 204", name:"Xing Jun", gender: "M", field:"Civil", date: "Jan/10/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 205", name:"Prakash Mavji Kara", gender: "M", field:"Civil", date: "Jan/10/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 206", name:"Liang Chuan", gender: "M", field:"Civil", date: "Jan/10/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 207", name:"Shi Haiquan", gender: "M", field:"Civil", date: "Jan/10/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 208", name:"Akhilesh Chandra", gender: "M", field:"Civil", date: "Feb/07/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 209", name:"Patil Rajesh Nemgounda", gender: "M", field:"Civil", date: "Feb/07/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 210", name:"Mohammed Yasin Worke", gender: "M", field:"Civil", date: "Mar/14/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 211", name:"Demissie Biruk Asfaw", gender: "M", field:"Civil", date: "Apr/03/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 212", name:"Gebremichael Tsegaye Yihdego ", gender: "M", field:"Civil", date: "Apr/03/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 213", name:"Abegaze Solomon Nigatu ", gender: "M", field:"Civil", date: "Apr/03/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 214", name:"Abebe Henok Endale ", gender: "M", field:"Civil", date: "Apr/03/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 215", name:"Gurmu Getachew Megerssa", gender: "M", field:"Civil", date: "Apr/03/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 216", name:"Mashru Tushar Narandas", gender: "M", field:"Civil", date: "Apr/04/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 217", name:"Tessema Zegeye Tenagashaw", gender: "M", field:"Civil", date: "May/08/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 218", name:"Woldeysus Aberham Habte", gender: "M", field:"Civil", date: "May/08/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 219", name:"Chauya Patrick", gender: "M", field:"Electrical", date: "May/08/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 220", name:"Gordon Adam Samuel", gender: "M", field:"Civil", date: "May/09/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 221", name:"Gruen Thomas Frank", gender: "M", field:"Civil", date: "May/09/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 222", name:"Mason Daniel Raymond ", gender: "M", field:"Civil", date: "May/09/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 223", name:"Lammers Matthew Peter ", gender: "M", field:"Civil", date: "May/09/2024" ,type:"TEMPORARY" },
{ reg_no:"TR 224", name:"Jones Kent Warren", gender: "M", field:"Electrical", date: "May/09/2024" ,type:"TEMPORARY" },
{ reg_no:"TR  225", name:"Tullu Girmachew Semunigus", gender: "M", field:"Irrigation", date: "Jun/05/2024" ,type:"TEMPORARY" },
{ reg_no:"TR  226", name:"Chekol Lamesgin Melese", gender: "M", field:"Civil", date: "Jul/05/2024" ,type:"TEMPORARY" },
{ reg_no:"TR  227", name:"Abrha Biniam Gebreslassie", gender: "M", field:"Civil", date: "Jul/05/2024" ,type:"TEMPORARY" },
{ reg_no:"TR  228", name:"Wu Weibin ", gender: "M", field:"Civil", date: "Jul/05/2024" ,type:"TEMPORARY" },
]
}