import React, { useState } from "react";
import Modal from "./Modal";


export default function( { open, title, children, handleOpen } ) {
    // const [ open, setOpen ] = useState( false );

    return (
      <div className="relative min-h-screen">
            {/* <div className="flex flex-col items-center my-24">
              <button onClick={() => setOpen(!open)} className="py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white font-bold text-lg rounded-full">Open Modal</button>
            </div> */}

            {open ? ( 
              <Modal>
                <div className="flex flex-col gap-2 bg-white px-4 pb-4 rounded-lg shadow-md">
                  <h1 className="text-lg text-black mt-2 pr-48 uppercase">
                    {title}
                  </h1>
                  <hr/>
                  {children}
                  <hr/>
                  {/* <div className="flex flex-row gap-2">
                      <button onClick={() => handleOpen(!open)} className="flex-1 py-2 px-4 bg-gray-500 hover:bg-gray-600 text-white font-bold text-lg rounded-full">
                        {`Close`}
                      </button>
                      <button onClick={() => handleOpen(!open)} className="flex-1 py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white font-bold text-lg rounded-full">
                        {`Save`}
                      </button>
                  </div> */}
                </div>
              </Modal> ) : null}
      </div>
    )
}

{/* <div className="flex flex-col gap-2">
    <label htmlFor="email">
    {`Please enter your email address.`}
    </label>
    <input id="email" type="email" className="py-2 px-4 border border-gray-200 rounded-lg" placeholder="E.g: test@gmail.com"/>
</div> */}