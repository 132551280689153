import React from 'react'
import { View } from '@react-pdf/renderer'
import Header from './Header'
import Body from './Body'
import styles from './styles'

const ContentSection = ( { record, type, imageQR, licenseData, remarks } ) => {
    return (
        <View style={styles.contentContainer}>
           {/* <Header imageQR={imageQR} licenseData={licenseData}/> */}
           <Body imageQR={imageQR} record={record} remarks={remarks} />
        </View>
    )
}  

export default ContentSection
 