import React, { useState } from 'react'
import NavStripBar from '../_components/NavStripBar'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import { Formik } from 'formik'
import { TextFieldIcon } from '../../_components'
import { CircularProgress } from '@material-ui/core'
import Button from '../_components/Button'
import { API_BASE } from '../../config'


export default function ChangePassword() {
  const navigate = useNavigate()

  return (
   <div className="w-screen h-screen bg-gray-50">
      <NavStripBar />
      <div className="px-5 sm:px-8 md:px-0 lg:px-0">
        <Form navigate={navigate}/>
      </div>
   </div>
  )
}

const Form = ( { navigate } ) => {
    const [ signing, setSigning ] = useState( false )
  
    const handleSubmit = async ( values ) => {
      try {
        setSigning( true )
        const response = await Axios.post( `${API_BASE}/account/password?email=${values.email}`, {} )
  
        if( response.data ){
          await localStorage.setItem( "email_reset", values?.email )
          alert( response.data?.message );
          navigate( "/login" )
        }
      } catch (error) {
       if( error?.response ) {
        const { status, statusText } =  error.response
        if( status === 401 ) {
          alert( `${statusText} - Your account may not exist yet.` )
        }
        else {
          alert( `Your Email Address or Password may be incorrect.` )
        }
       }
       else {
        alert( "You may have an internet connection issue. Check your network connectivity." )
       }
       setSigning( false )
      }
    }
  
    return (
      <div className="py-5" style={{fontFamily: "Roboto, sans-serif"}}>
        <div className="w-full h-full flex items-center justify-center">
          <div className= "md:p-12 lg:p-12 p-5 lg:w-2/5 md:w-2/5 w-full h-auto bg-white rounded shadow-md mx-2">
            <div className="flex flex-col items-center justify-center">
              <h2 className="font-bold px-3 py-2 text-center md:text-xl lg:text-xl text-base">
                {`Provide Email Address to change your Password`}
              </h2>
            </div>
            <Formik initialValues={{
                email: "",
                password: "",
            }} onSubmit={handleSubmit}>
                { ( { handleSubmit, handleBlur, handleChange, values, isSubmitting } ) => {
                  return (
                      <form onSubmit={handleSubmit}>
                        <TextFieldIcon 
                          type="email"
                          name="email"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.email}
                          label="Email Address"
                          required={true}
                          disabled={signing}
                          question="Email Address" />
  
                        <div className="w-full py-2 grid grid-cols-1">
                            {signing ? (
                            <div className="flex items-center justify-center">
                              <CircularProgress size={24} color="primary" />
                            </div>
                            ):
                              ( //bg-[#EE1C26] hover:bg-red-500
                            <div className="flex flex-col">
                              <Button 
                                type="Continue"
                                loading={isSubmitting} 
                                disabled={isSubmitting} 
                                label={isSubmitting ? "Submitting..." : "Submit"}
                                style={{backgroundColour: '#EE1C26'}} />
                            </div> 
                            )
                            }
                        </div>
                      </form>
                    )
                  }  }
            </Formik>
          </div>
          </div>
        <div> 
        </div>
      </div>
    )
}