import { call, put, takeLatest } from 'redux-saga/effects'
import { requestGetUser, authLoginUser } from './requests'
import { authLogin, authLoginSuccess, authLoginFailed  } from '../_slices/appSlice'


export function* watchLogin() {
    yield takeLatest( authLogin.type, postLogin )
}

export function* postLogin( action ){
    let { values } = action.payload
    try {
        const response = yield call( () => authLoginUser( values ) )
        
        if( response.data ) {
            let { data, mesage, status } = response.data

            yield put( authLoginSuccess( {
                data,
                message: mesage,
                status
            } ) )

            // navigate( '/verify-otp' )
        }

    } 
    catch( error ){
        console.log( 'error', error )
        // yield put( authLoginFailed( { 
        //     error
        // } ) )
    }
}