import React from 'react'
import { classNames } from '../_utils'

import { at } from 'lodash'
import { useField } from 'formik'

export default function SelectFieldIcon( { disabled = false, required = false, nopadding = false, question,name, iconComponent, defaultOption, label, handleChange, handleBlur, value, options } ) {
  
  const [ field, meta ] = useField( name )

  const _renderText = () => {
    const [ touched, error ] = at( meta, 'touched', 'error' ) 
    if( touched && error )
      return error
  }

  return (
    <div className="my-0 flex flex-col items-start justify-center">
      <div className="text-black text-xs md:text-base lg:text-base py-2">
        { question }{ required && ( <span className="text-red-500">*</span>) }
      </div>
      <div className={classNames( ( meta.touched && meta.error ) ? "inline-flex w-full rounded-sm border border-red-500 h-8 my-1 relative" : "inline-flex w-full rounded-sm border-2 border-gray-500 h-8 my-1 relative" ) } >
          {/* <div className="w-1/6 flex items-center justify-center bg-gray-50">
            <span>{iconComponent}</span>
          </div> */}
          <select 
            id={name}
            name={name}
            className={ classNames( (  meta.touched && meta.error ) ? "w-full px-2 py-0 focus:outline-none focus:text-gray-700 placeholder-red-500 text-black text-base" : "w-full px-2 py-0 focus:outline-none focus:text-gray-700 placeholder-gray-400 text-black text-base bg-white") } 
            required={required}
            placeholder={label}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
            style={{ backgroundColor: disabled ? "#DDD" : "#FFF", color: disabled ? "#000" : "#666" }}
            value={value || defaultOption}>
              <option className="text-gray-500" value="">
                {`Choose...`}
              </option>
              {options?.map( item => {
                  return (
                    <option className="text-gray-500"  key={item.id} value={item.value}>
                      {item.label}
                    </option>
                  )
              } )}
          </select>

          {(meta.touched && meta.error) ? (
            <p className="absolute -bottom-6 left-0 text-red-500 py-1 text-xs">
              {_renderText()}
            </p>
            ) : (
            <p className="absolute -bottom-6 left-0 text-gray-700 py-1 text-xs">
              {/* {question}{ required && ( <span className="text-red-500">*</span>) } */}
            </p>
          ) }

      </div>
    </div>
  )
}
