import { configureStore, createSlice } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { logger } from 'redux-logger'
import rootSaga from '../_sagas'

// import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import data from '../_scenes/fixtures'


//config for the key
const persistConfig  = {
    key: "persist-key",
    storage,
    transforms: [
        encryptTransform( {
            secretKey: "my-super-secret-key",
            onError: function( error ){
                //handle the error
            }
        } )
    ]
}

let initialState = {
    loading: false,
    loginCompleted: null,
    loadingAuth: null,
    logoutFlag: false,
    employersList: [],
    educationList: [],
    spouseList: [],
    refereesList: [],
    travelList: [],
    workList: [],
    areas: [],
    displayModal: null,
    shareholders: [],
    deferred_holders: [],
    financing: [],
    finance: [],
    funding: [],
    employment: [],
    markets: [],
    inputs: [],
    files: [],
    edit: null,
    counties: [],
    subcounties: [],
    divisions: [],
    groups: [],
    classes: [],
    groupTitle: "",
    classTitle: "",
    divisionTitle: "",
    sectorTitle: "",
    classTitle: "",
    totals: {},
    documents: [],
    resetFlag: false,
    education: [],
    engineering: [],
    practicals: [],
    positions: [],
    membership: [],
    sponsors: []
}

const appSlice = createSlice( {
    name: "app",
    initialState,
    reducers: {
        setUsers: ( state, action ) => {
            return { ...state }
        },
        getUsers: () => {},
        authLogin: ( state, action ) => {
            state.loadingAuth = { loading: true }
        },
        authLoginSuccess: ( state, action ) => {
            state.loginCompleted = action.payload 
        },
        authLoginFailed: ( state, action ) => {
            state.loginCompleted = action.payload
        },
        loginRequest: ( state, action ) => {},
        logout: ( state, action ) => {
            state.logoutFlag = action.payload.flag
        },
        test: ( state, action ) => {
            state.addedItems = parseInt( typeof action.payload.param === "undefined" ? 0 : action.payload.param ) + 1
        },
        saveEmployers: ( state, action ) => {
            state.employersList = action.payload.employersList
        },
        saveEducation: ( state, action ) => {
            state.educationList = action.payload
        },
        saveSpouses: ( state, action ) => {
            state.spouseList = action.payload.spouseList
        },
        saveWorkPlaces: ( state, action ) => {
            state.workList = action.payload.workList
        },
        saveRefereeList: ( state, action ) => {
            state.refereesList = action.payload.refereesList
        },
        saveTravelList: ( state, action ) => {
            state.travelList = action.payload.travelList
        },
        saveAccessArea: ( state,action ) => {
            state.areas = action.payload.areas
        },
        setDisplayModal: ( state, action ) => {
            state.displayModal = {
                flag: action.payload.flag,
                title: action.payload.title,
                tag: action.payload.tag,
                operation: action.payload.operation
            }
        },
        saveShareholders: ( state, action ) => {
            state.shareholders = action.payload.shareholders
        },
        setDeferredShareholders: ( state, action ) => {
            state.deferred_holders = action.payload.shareholders
        },
        setSaveFinance: ( state, action ) => {
            state.financing = action.payload.finance
        },
        setSaveFunding: ( state, action ) => {
            state.funding = action.payload.funding
        },
        setSaveEmployment: ( state, action ) => {
            state.employment = action.payload.employment
        },
        setSaveMarkets: ( state, action ) => {
            state.markets = action.payload.markets
        },
        setSaveInputs: ( state, action ) => {
            state.inputs = action.payload.inputs
        },
        setSaveFiles: ( state, action ) => {
            state.files = action.payload.files
        },
        removeShareholder: ( state, action ) => {
            state.shareholders.splice( action.payload.idx, 1 )
        },
        removeDeferredShareholder: ( state, action ) => {
            state.deferred_holders.splice( action.payload.idx, 1 )
        },
        setDisplayEdit: ( state, action ) => {
            state.edit = { 
                data: action.payload?.data,
                displayModal: action.payload.displayModal
            }
        },
        setGetCounties: ( state, action ) => {
            let counties = data.counties.filter( item => item.district_name === action.payload.district )
            state.counties = counties
        },
        setGetSubcounties: ( state, action ) => {
            let subcounties = data.subcounties.filter( ( item ) => item.county_name === action.payload.county )
            state.subcounties = subcounties
        },
        setGetDivisions: ( state, action ) => {
            let filtered = data?.isc_codes.filter( val => { 
                return parseInt( val.itemId ) === parseInt( action.payload.sector )
            } )
            state.sectorTitle = data?.isc_codes.filter( item => parseInt( item?.itemId ) === parseInt( action.payload.sector ) )?.[ 0 ]?.title 
            state.divisions = filtered?.[ 0 ]?.[ "divisions" ]
        },
        setGetGroups: ( state, action ) => {
            let filtered = state.divisions.filter( val => { 
                return  val.title === action.payload.division
            } )
            state.divisionTitle = state.divisions.filter( item => item.title === action.payload.division )?.[ 0 ]?.title
            state.groups = filtered?.[ 0 ]?.[ "group" ]
            
        },
        setGetClasses: ( state, action ) => {
            let filtered = action.payload.groups.filter( val => { 
                return val.id === parseInt( action.payload.group )
            } )
            state.groupTitle = state.groups.filter( item => item.id === parseInt( action.payload.group ) )?.[ 0 ]?.groupTitle
            state.classes = filtered?.[ 0 ]?.[ "classes" ]
        },
        setUnitClasses: ( state, action ) => {
           state.classTitle =  state.classes?.filter( item => item.id === parseInt( action.payload.id ) )?.[ 0 ]?.label || "NA"
        },
        setDeferDivisions: ( state, action ) => {
            state.sectorTitle = action.payload.sectorTitle
            state.divisions = action.payload.divisions
        },
        setDeferGroups: ( state, action ) => {
            state.divisionTitle = action.payload.divisionTitle
            state.groups = action.payload.groups
        },
        setDeferClasses: ( state, action ) => {
            state.groupTitle = action.payload.groupTitle
            state.classes = action.payload.classes
        },
        setDeferClassTitle: ( state, action ) => {
            state.classTitle =  state.classes?.filter( item => item.id === parseInt( action.payload.id ) )?.[ 0 ]?.label || "NA"
         },
        setSaveDocuments: ( state, action ) => {
            state.documents =  action.payload.files
        },
        setNoReset: ( state, action ) => {
            state.resetFlag = action.payload.flag
        },
        setSaveDraft: ( state, action ) => {
            state.setSaveDraft = action.payload
        },
        setEducationList: ( state, action ) => {
            state.education = action.payload
        },
        setEngineeringList: ( state, action ) => {
            state.engineering = action.payload
        },
        setPracticalList: ( state, action ) => {
            state.practicals = action.payload
        },
        setPositionList: ( state, action ) => {
            state.positions = action.payload
        },
        setMembershipList:  ( state, action ) => {
            state.membership = action.payload
        },
        setSponsorList:  ( state, action ) => {
            state.sponsors = action.payload
        },
    }
} )


//action creators / actions
export const { setUsers, getUsers, 
    authLogin, authLoginSuccess, authLoginFailed,
    loginRequest, logout, test, 
    saveEmployers, saveEducation, saveSpouses,  saveWorkPlaces,  
    saveRefereeList,  saveTravelList, saveAccessArea,
    saveShareholders, setDisplayModal, setSaveFinance, setSaveFunding,
    setSaveEmployment, setSaveMarkets, setSaveInputs, setSaveFiles,
    removeShareholder, setDisplayEdit, setGetCounties, setGetSubcounties,
    setGetDivisions, setGetGroups, setGetClasses, setUnitClasses, saveFinanceTotal,
    setSaveDocuments, setDeferDivisions, setDeferGroups, setDeferClasses, setDeferClassTitle,
    setNoReset, setDeferredShareholders, removeDeferredShareholder, setSaveDraft,
    setEducationList, setEngineeringList, setPracticalList, setPositionList, setMembershipList,
    setSponsorList
} = appSlice.actions


//saga middleware
let sagaMiddleware = createSagaMiddleware();


//persist reducer - removed persistReducer
// const persistedReducer =  persistReducer( persistConfig, appSlice.reducer )

//initialize the store with configure store
const store = configureStore( {
    reducer: {
        application: appSlice.reducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat( 
        sagaMiddleware,
        logger
    )
} )

//persistored store
// const persistor = persistStore( store )

//run the saga middleware
sagaMiddleware.run( rootSaga )

// export {
//     persistor
// }

//default store
export default store