import React from 'react'
import { Wrap } from '../_components'
import { useNavigate } from 'react-router-dom'

export default function Welcome() {
    const navigate = useNavigate()

    return (
        <Wrap navigate={navigate} sidebar={false}>
            <div className="w-full flex flex-col items-center">
                <div className="container mx-auto h-full">
                    <h1 className="text-center  text-red-500 md:text-3xl lg:text-3xl text-base p-2 font-semibold">
                        {`Foreword`}
                    </h1>
                    <p className="text-base md:text-xl lg:text-xl p-2">
                        {`ERB is delighted to introduce this inventive platform designed to streamline and simplify the application process for engineers seeking registration to the Uganda Engineers Registration Board and renewal of annual practicing licenses.This portal is a significant milestone in our efforts to leverage technology and enhance our services' efficiency.`}
                    </p>
                    <p className="text-base md:text-xl lg:text-xl p-2">
                        {`Through this platform, applicants can easily submit their applications, upload required documents, and track the progress of their applications timely. Our vision is to ensure that excellent engineering services are offered to Society, and this portal is a crucial step towards achieving that goal. We are committed to supporting the growth and development of our Engineers, Technologists and Techinicians of different engineering disciplines and this portal is a testament to that committment.`}
                    </p>
                    <p className="text-base md:text-xl lg:text-xl p-2 py-3">
                        {`It equally acts as an avenue to update the records of the already Registered Engineers across all categories. The platform shall continously be improved to include aggregation of member CPD points and other ERB e-updates. I therefore, invite you to explore this portal, and I am confident that you will find it convenient and user-friendly. If you have any inquiries or need assistance, please do not hesitate to contact us. We are always here to help.`}
                    </p>
                    <p className="text-base md:text-xl lg:text-xl p-2 py-3">
                        {`Thank you for choosing to register with the Engineers Registration Board. We look forward to walking the professional journey with you.`}
                    </p>
                </div>

                <div className="py-2 flex items-center justify-center">
                    <button type="button" onClick={() => navigate( "/register" )} className="p-2 my-2 md:text-xl lg:text-xl text-base text-white bg-red-500 rounded">
                        {`Register Here`}
                    </button>
                </div>
            </div>
        </Wrap>
    )
}
