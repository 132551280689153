import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Container from '../Container'
import FormWrapper from '../FormWrapper'
import Table from '../Table'
import Navbar from '../Navbar'
// import { getSlug } from '../../_screens/_utils'

export const getSlug = ( location, path = false ) => {
  if( path ) {
      return location.pathname.split( "/" )?.[ 2 ]
  }
  return location.pathname.split( "/" )?.[ 3 ]
}

export default function( { navigate, handleLogout, tableConfig, PassedComponent } ) {
  const location = useLocation()
  const form = getSlug( location, true )

  const { 
      title = "", 
      formTitle = "",
      columns,
      data,
      rightBtnCallback,
      rightBtnLabel,
      openForm,
      stages
  } = tableConfig 

  return (
    <Container>
      <Navbar navigate={navigate}/>
      <TopNavBarContainer form={form} navigate={navigate} location={location} />
      {/* <SidebarContainer handleLogout={handleLogout}/> */}
      <div className="w-full h-full flex bg-gray-200m-1">
          <div className="w-full">
          <FormWrapper  title={title} stages={stages} openForm={openForm}>
              { openForm ? PassedComponent : (
                <div className="flex flex-col rounded-sm w-full h-auto px-3 py-2 bg-white">
                  <h2 className="text-2xl">
                      {`Your ${title}`}
                  </h2>
                  <Table
                    rightButton={true}
                    rightBtnCallback={() => rightBtnCallback() }
                    rightButtonLabel={rightBtnLabel}
                    label={title}
                    columns={columns}
                    data={data}
                    hidePagination={false}
                  />
                </div>
              ) }  
          </FormWrapper>
          </div>
      </div>
    </Container>
  )
}

export const TopNavBarContainer = ( { form, navigate, location } ) => {

  const handleNavigate = ( tag ) => {
    if( form === tag ) {
      return
    }
    return navigate( `/permit/${tag}` )
  }
  return (
    <div className="w-full flex items-center justify-start px-5 bg-green-100 shadow-md h-10">
      <NavListItem label="One Day Permit" slug="OneDay" tag="oneday" handlePress={() => handleNavigate( "oneday" )} />
      <NavListItem label="Citizen Permit" slug="Citizen" tag="citizen" handlePress={() => handleNavigate( "citizen" )}/>
      <NavListItem label="Non-Citizen Permit" tag="noncitizen" slug="Non-Citizen" handlePress={() => handleNavigate( "noncitizen" )}/>
      <NavListItem label="Diplomatic Permit" tag="diplomatic" slug="Diplomatic" handlePress={() => handleNavigate( "diplomatic" )}/>
      <NavListItem label="Replace Card" tag="lostcard" slug="LostCard" handlePress={() => handleNavigate( "lostcard" )}/>
    </div>
  )
}

export const NavListItem = ( { label, active, handlePress } ) => {
  return (
    <div onClick={handlePress} className={`${ active ? "border-b-2 border-green-700 text-green-700" : "" } cursor-pointer h-full text-black text-xs flex items-center justify-center px-3 uppercase font-bold`}>
      {label}
    </div>
  )
}
