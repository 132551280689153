import React, { useState } from 'react'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const NavLink =  styled( Link )`
  font-size: 12px;
`

const NavLinkParent = styled( NavLink )`
  font-size: 14px;
`


export default function MenuLink( { url, icon, label, expand = true, options = [], handlePress } ) {
  const [ openSubMenu, setSubMenu ] = useState( true )

  const handleToggle = () => {
    setSubMenu( !openSubMenu )
  } 

  return (
    <>
        <div className="flex flex-row my-1 items-center relative px-3 w-full" onClick={ ( options.length > 0 ) && handleToggle}>
          
            <div className="flex flex-row justify-start items-center w-full py-1">
              <span>{icon}</span>
              <span className="px-4 text-white text-sm">
                  {label}
              </span>
              { ( expand && options.length > 0 ) && ( 
                  <span>{openSubMenu ? <FaAngleUp color="#FFF" /> : <FaAngleDown color="#FFF" />}</span>
              )}
            </div>
        </div>
        {openSubMenu && ( 
        <div className="relative h-auto bg-blue-900 w-full py-2 px-2">
            {options.map( ( { id, name, url } ) => (
                <div className="w-full px-2 py-1 flex flex-row items-center border-b border-gray-400" key={id}>
                   <NavLink className="text-sm text-white" to={url}>
                     {name}
                    </NavLink>
                </div>
            ) )}
        </div> ) }
    </>
    
  )
}
