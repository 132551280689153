import React, { useState,useEffect, useRef } from 'react'
import NavStripBar from '../_components/NavStripBar'
import { Formik } from 'formik'
import axios from 'axios'
import moment from 'moment'
import { CircularProgress } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { FaGlobe, FaUserAlt, FaPhoneAlt, FaMapMarker, FaEnvelope } from 'react-icons/fa'
import { TextFieldIcon, SelectFieldIcon, PhoneFieldIcon, Panel, Wrap } from '../../_components'
import data from '../fixtures'
import BlackProfileIcon from '../../_images/BlackProfileIcon.png'
import { API_BASE, sanitizeValue } from '../../_utils'
import Upload from './Upload'
import people from '../fixtures'


let current_date = moment( new Date() ).format( "YYYY-MM-DD" )

export default function Register({handleChange}) {
  return (
    <div>
      {/* <NavStripBar /> */}
        <div className=" lg:overflow-y-hidden md:overflow-y-hidden">
          <RegistrationForm />
        </div>
   </div>
  )
}


const RegistrationForm = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [ baseImage, setBaseImage ] = useState( null )
    const firstNameRef = useRef(null);
    const [ pageLoading, setPageLoading ] = useState( true )
    const [ ERBNumber, setERBNumber ] = useState( "" )
    const [ fetching, setFetching ] = useState( false )
    const [ engineers, setEngineers ] = useState( [] )
    const [ engineer, setEngineer ] = useState( [] )
    const [ birthDate, setBirthDate ] = useState( "" )
    const [ submitting, setSubmitting ] = useState( false )

    const divRef = useRef(null);

    useEffect( () => {
      if( pageLoading ) {
        divRef.current.scroll( { 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        } );
        setPageLoading( false )
      }
    }, [ pageLoading ] )

    const handleSubmit = async ( values ) => {
      if( Object.values( values ).length > 0 ) {
       

        let currentYear = moment( new Date() ).format( "YYYY" )
        let birthYear = moment( values?.birth_date ).format( "YYYY" )

        if( ( parseFloat( currentYear ) - parseFloat( birthYear ) ) <= 25  ) {
          return alert( "You have to be 25 years and older to be able to register on this platform." )
        }

        if( !( baseImage?.length > 0 ) ) {
          return alert( "Please attach your profile image before you proceed...")
        }

        let payload = {
          "name": engineer?.[ 0 ]?.name || values?.surname + " " + sanitizeValue( values?.other_names ) + " " + sanitizeValue( values?.first_name ) + " ",
          "first_name": sanitizeValue( values?.first_name ),
          "surname": values?.surname,
          "other_names": sanitizeValue( values?.other_names ),
          "country": values?.country,
          "telephone": values?.phone_number,
          "password": values?.password,
          "address": values?.postal_address,
          "company_name": values?.company_name || "Default",
          "birth_date": values?.birth_date || "1985-06-15",
          "birth_place": values?.birth_place,
          "middlename": sanitizeValue( values?.other_names ) || "NA",
          "gender": values?.gender || engineer?.[ 0 ]?.gender,
          "email": values?.email_address,
          "registered": values?.registered,
          "licence_no": values?.registration_number,
          "status": "APPROVED",
          "user_picture": baseImage,
          "category": values.discipline === "Others" ? values?.other_specify : values?.discipline || engineer?.[ 0 ]?.field,
          "belongs_to": values?.belongs_to,
          "type": values?.belongs_to
        }

        setSubmitting( true )

        try {
          const response = await axios.post( `${API_BASE}/erb_storeUser`, payload )
          if( response.data ){
            alert( response.data?.message )
            setSubmitting( false )
            navigate( "/" )
          }
        } catch (error) {
          setSubmitting( false )
          if( error?.response ) {
            let { data } = error?.response 
            alert( data[ Object.keys( data )?.[ 0 ] ] )
          }
          else {
            alert( "Server may be unreacheable. Try again later." ) 
          }
          setSubmitting( false )
        }
      }
      else {
        return alert( "Please fill in all required fields, before proceeding." )
      }
    }


    const handleSelectedImage = ( base ) => {
      if( base ){
        setBaseImage( base )
      }
    }

    const fetchNumbers = async ( category )  => {
      setFetching( true )
      try {
        let filteredPeople = people?.engineers?.filter( person => {
          return person?.type?.toUpperCase() === category?.toUpperCase()
        } )
  
        setEngineers( filteredPeople )
        setFetching( false )
      } catch (error) {
        setFetching( false )
        alert( "We could not find the engineers at this time, please try again later." )
      }
    }

    const handleFetchDetails = ( number ) => {
      let engineer = engineers.filter( item => item?.reg_no === number )
      return setEngineer( engineer )
    }

    return (
      <>
        <Wrap navigate={navigate} noScroll={false} sidebar={false}>
        <Formik initialValues={{
          surname: "",
          name: engineer?.[ 0 ]?.name || "",
          first_name: "",
          other_names: "",
          phone_number: "",
          birth_date: "",
          birth_place: "",
          gender: "",
          company_name: "",
          address: "",
          password: "",
          country: "Uganda",
          registered: "",
          registration_number: "",
          discipline: "",
          other_specify: "",
          postal_address: "",
          belongs_to: ""
        }}
        onSubmit={handleSubmit}>
          { ( { handleSubmit, handleBlur, handleChange, errors, values, touched, isSubmitting } ) => {

            return (
              <form onSubmit={handleSubmit} className="md:overflow-y-hidden lg:overflow-y-hidden sm:overflow-hidden px-10 py-2">
                <h2 className="text-center text-base md:text-xl font-bold lg:text-xl">
                  {`Sign Up`}
                </h2>
                 <div ref={divRef} className="md:h-[500px] lg:h-[500px] h-[350px] md:overflow-y-scroll lg:overflow-y-scroll overflow-y-scroll overflow-x-hidden">
                  <Panel label="Section A: Registration General Details">
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3 my-2">
                      <SelectFieldIcon 
                        noPadding={true}
                        label="Registered"
                        name="registered"
                        required={true}
                        handleBlur={handleBlur}
                        handleChange={ evt => {
                          handleChange( evt )
                          if( evt.target.value === "Yes" || evt.target.value === "No" ){
                            setPageLoading( true )
                          }
                        }}
                        value={values.registered}
                        options={[
                          { id: 1, label: "Yes", value: "Yes" },
                          { id: 2, label: "No", value: "No" }
                        ]}
                        iconComponent={<FaGlobe color={  errors.registered && touched.registered ? `red` : `#1e3a8a` }/>}
                        question="i. Are you a registered engineer(ERB)?" 
                      />

                      {values.registered === "Yes" && (
                        <>
                        <SelectFieldIcon 
                          noPadding={true}
                          label="Category"
                          name="belongs_to"
                          required={true}
                          handleBlur={handleBlur}
                          handleChange={ evt => {
                            handleChange( evt )
                            fetchNumbers( evt.target.value )
                          }}
                          value={values.belongs_to}
                          options={[
                            { id: 1, label: "Permanent", value: "PERMANENT" },
                            { id: 2, label: "Technician", value: "TECHNICIAN" },
                            { id: 3, label: "Technologist", value: "TECHNOLOGIST" },
                            { id: 4, label: "Temporary", value: "TEMPORARY" }
                          ]}
                          iconComponent={<FaGlobe color={  errors.belongs_to && touched.belongs_to ? `red` : `#1e3a8a` }/>}
                          question="ii. Choose Your Category?" 
                        />
                        
                        {fetching ? (
                          <div className="flex flex-row">
                            <CircularProgress size={24} color="primary" />
                            <span className="text-xs">{`Fetching Registered Engineers List...`}</span>
                          </div>
                        ) : 
                        <SelectFieldIcon 
                          noPadding={true}
                          label="Registration Number"
                          name="registration_number"
                          required={true}
                          handleBlur={handleBlur}
                          handleChange={ evt => {
                            handleChange( evt )
                            handleFetchDetails( evt.target.value )
                          } }
                          value={values.registration_number}
                          options={engineers?.map( ( item, idx ) => {
                            return {
                              id: idx+1,
                              label: item?.reg_no,
                              value: item?.reg_no
                            }
                            } )}
                          iconComponent={<FaGlobe color={  errors.registration_number && touched.registration_number ? `red` : `#1e3a8a` }/>}
                          question="iii. Choose your Registration Number(ERB):" 
                       /> }
                       </>
                      ) }
                    </div>

                    { values.registered?.length > 0 && 
                    <>
                      <Upload initialImage={BlackProfileIcon} handleSelectedImage={handleSelectedImage}/>
                      <div className="w-full py-5 grid md:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-10 px-5">
                        
                        {values.registered === "Yes" && (
                          <>
                            <TextFieldIcon 
                              noPadding={true}
                              name="name"
                              value={engineer?.[ 0 ]?.name}
                              handleChange={handleChange} 
                              handleBlur={handleBlur} 
                              iconComponent={<FaUserAlt color={  errors.name && touched.name ? `red` : `#1e3a8a` } />} 
                              label="Name"
                              required={false}
                              disabled={true}
                              question="iv. Name"  />

                            <TextFieldIcon 
                              noPadding={true}
                              name="date_registered"
                              value={engineer?.[ 0 ]?.date || "NA"}
                              handleChange={handleChange} 
                              handleBlur={handleBlur} 
                              iconComponent={<FaUserAlt color={  errors.name && touched.name ? `red` : `#1e3a8a` } />} 
                              label="Date Registered"
                              required={false}
                              disabled={true}
                              question="v. Date Registered"  />   
                          </>
                        )}

                        {values.registered === "No" && 
                        <>
                        <TextFieldIcon 
                          noPadding={true}
                          name="first_name"
                          handleChange={handleChange} 
                          handleBlur={handleBlur} 
                          autoFocus
                          value={values.first_name}
                          iconComponent={<FaUserAlt color={  errors.first_name && touched.first_name ? `red` : `#1e3a8a` } />} 
                          label="First Name" 
                          question={"iv. Your first name e.g John"}
                          required={true} />

                        <TextFieldIcon 
                          noPadding={true}
                          name="surname"
                          value={values.surname}
                          handleChange={handleChange} 
                          handleBlur={handleBlur} 
                          iconComponent={<FaUserAlt color={  errors.surname && touched.surname ? `red` : `#1e3a8a` } />} 
                          label="Surname"
                          required={true}
                          question="v. Last Name/Surname"  />
                    
                        <TextFieldIcon 
                          noPadding={true}
                          name="other_names"
                          value={values.other_names}
                          handleChange={handleChange} 
                          handleBlur={handleBlur} 
                          iconComponent={<FaUserAlt color={  errors.other_names && touched.other_names ? `red` : `#1e3a8a` } />} 
                          label="Other Names"
                          required={false}
                          question="vi. Other Names" />
                          </> }
                      </div>

                      <div className="w-full py-2 grid md:grid-cols-4 lg:grid-cols-4 grid-cols-1 gap-5 px-5">
                        <TextFieldIcon 
                          type="date" 
                          noPadding={true} 
                          name="birth_date" 
                          required={true} 
                          value={values?.birth_date} 
                          max={current_date}
                          handleChange={handleChange} 
                          handleBlur={handleBlur} label="Date of Birth"
                          iconComponent={<FaMapMarker color={  errors.birth_date && touched.birth_date ? `red` : `#1e3a8a` } />}
                          question="vii. Date of Birth"
                        />

                        <TextFieldIcon 
                          type="text" 
                          noPadding={true} 
                          name="birth_place" 
                          required={true} 
                          value={values?.birth_place} 
                          handleChange={handleChange} 
                          handleBlur={handleBlur} 
                          label="Place of Birth"
                          iconComponent={<FaMapMarker color={  errors.birth_place && touched.birth_place ? `red` : `#1e3a8a` } />}
                          question="viii. Place of Birth"
                        />

                        <TextFieldIcon 
                          type="text" 
                          noPadding={true} 
                          name="postal_address" 
                          required={true} 
                          value={values?.postal_address} 
                          handleChange={handleChange} 
                          handleBlur={handleBlur} 
                          label="e.g P.O Box XXX"
                          iconComponent={<FaMapMarker color={  errors.postal_address && touched.postal_address ? `red` : `#1e3a8a` } />}
                          question="ix. Postal Address"
                        />

                      <SelectFieldIcon 
                        noPadding={true}
                        label="Gender"
                        name="gender"
                        required={true}
                        handleBlur={handleBlur}
                        disabled={values.registered === "Yes"}
                        handleChange={handleChange}
                        value={values.registered === "Yes" ? engineer?.[ 0 ]?.gender: values?.gender}
                        options={[
                          { id: 1, label: "MALE", value: "M" },
                          { id: 2, label: "FEMALE", value: "F" }
                        ]}
                        iconComponent={<FaGlobe color={  errors.gender && touched.gender ? `red` : `#1e3a8a` }/>}
                        question="x. Gender" 
                      />
                      </div>

                      <div className="w-full py-2 grid md:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-10 px-5">
                      <SelectFieldIcon 
                        noPadding={true}
                        label="Country"
                        name="country"
                        required={true}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        defaultOption={"UGANDA"}
                        value={values?.country}
                        options={data.countries.map( country => ( { id: country.en_short_name, label: country.en_short_name?.toUpperCase(), value: country.en_short_name } ))}
                        iconComponent={<FaGlobe color={  errors.category && touched.category ? `red` : `#1e3a8a` }/>}
                        question="xi. Country" 
                      />

                      <TextFieldIcon
                        noPadding={true}
                        name="address"
                        required={true}
                        value={values.address}
                        handleChange={handleChange} 
                        handleBlur={handleBlur} 
                        label="Physical Address"
                        iconComponent={<FaMapMarker color={  errors.address && touched.address ? `red` : `#1e3a8a` } />}
                        question={location.state?.name === "company" ? "xii. Current Address of Company" : "xii. Your current physical address location" }
                      />

                      <SelectFieldIcon 
                        noPadding={true}
                        label="Discipline"
                        name="discipline"
                        required={true}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        disabled={values.registered === "Yes"}
                        value={values.registered === "Yes" ? engineer?.[ 0 ]?.field : values.discipline}
                        options={[
                          { id: 1, label: "Aerospace", value: "Aerospace" },
                          { id: 2, label: "Agricultural", value: "Agricultural" },
                          { id: 3, label: "Biomedical", value: "Biomedical" },
                          { id: 4, label: "Chemical", value: "Chemical" },
                          { id: 5, label: "Civil", value: "Civil" },
                          { id: 6, label: "Computer", value: "Computer" },
                          { id: 7, label: "Electrical", value: "Electrical" },
                          { id: 9, label: "Environmental", value: "Environmental" },
                          { id: 10, label: "Industrial", value: "Industrial" },
                          { id: 11, label: "Mechanical", value: "Mechanical" },
                          { id: 12, label: "Telecommunications", value: "Telecommunications" },
                          { id: 13, label: "Petroleum", value: "Petroleum" },
                          { id: 14, label: "Automative and Power", value: "Automative" },
                          { id: 15, label: "Electro-Mechanical", value: "ElectroMechanical" },
                          { id: 17, label: "Mining", value: "Mining" },
                          { id: 18, label: "Water Resources", value: "Water Resources" },
                          { id: 16, label: "Others(Specify)", value: "Others" },
                        ]}
                        iconComponent={<FaGlobe color={  errors.discipline && touched.discipline ? `red` : `#1e3a8a` }/>}
                        question="xiii. Your Discipline" 
                      />

                      {values.discipline === "Others" && <TextFieldIcon
                        noPadding={true}
                        type="text"
                        name="other_specify"
                        required={values.discipline === "Others" ? true : false}
                        value={values.other_specify}
                        handleChange={handleChange} 
                        handleBlur={handleBlur} 
                        label="Other Discipline"
                        iconComponent={<FaMapMarker color={  errors.other_specify && touched.other_specify ? `red` : `#1e3a8a` } />}
                        question={ "xiii. Specify Other Discipline" }
                      /> }
                      </div>

                      <div className="w-full py-5 grid  md:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-10 px-5">
                        <PhoneFieldIcon 
                          noPadding={true} 
                          required={true}  
                          name="phone_number" 
                          value={values.phone_number}
                          handleChange={handleChange}  handleBlur={handleBlur}  type="number"
                          label="Phone Number"
                          iconComponent={<FaPhoneAlt color={ errors.phone_number && touched.phone_number ? `red` : `#1e3a8a` } />}
                          question={location.state?.name === "company" ? "xiv. Company Telephone Contact" :"xiv. Your valid telephone number e.g 77xxxxx"}
                          questionStyle={{ color: 'black' }} 
                        />

                        <TextFieldIcon 
                          noPadding={true}
                          type="email"
                          name="email_address"
                          value={values.email_address}
                          handleChange={handleChange} 
                          handleBlur={handleBlur} 
                          iconComponent={<FaEnvelope color={  errors.email_address && touched.email_address ? `red` : `#1e3a8a` } />} 
                          label="Email Address" 
                          required={true}
                          question={"xv. Your valid Email Address"} />

                        <TextFieldIcon
                          type="password"
                          noPadding={true}
                          name="password"
                          required={true}
                          value={values.password}
                          handleChange={handleChange} 
                          handleBlur={handleBlur} 
                          label="Password"
                          iconComponent={<FaMapMarker color={  errors.password && touched.password ? `red` : `#1e3a8a` } />}
                          question="xvi. Your Password"
                        />
                      </div>

                      { submitting ? (
                        <div className="flex flex-row items-center justify-center">
                          <CircularProgress size={24} color="primary" />
                          <span>{`Please wait while we submit your Account Details...`}</span>
                        </div>
                      ) : <div className="w-full flex items-center justify-center p-2">
                          {isSubmitting ? (
                            <div className="flex items-center justify-center">
                              <CircularProgress size={24} color="primary" />
                            </div>
                          ) : ( <button disabled={isSubmitting || submitting} className="uppercase w-full  md:w-1/4 lg:w-1/4 bg-[#EE1C26] rounded-2xl text-white px-5 py-2 
                                text-base focus:outline-none focus:border-none" type="submit" style={{ borderRadius: '30px', width: '200px' }}>
                              {`Submit`}
                          </button> ) }
                      </div> }
                    </> }
                  </Panel>
	              </div>
              </form>
            )
          } }
        </Formik>
       </Wrap>
      </>
      
    )
}
