import React, { useEffect, useState } from 'react'
import NavStripBar from '../_components/NavStripBar'
import { useNavigate } from 'react-router-dom'


export default function PrivacyPolicy() {
  const navigate = useNavigate()
  const [ token, setToken ] = useState( "" )
  const [ emailAddress, setEmail ] = useState( "" )

  useEffect( () => {
    let queryParameters = new URLSearchParams( window.location.search )
    let token = queryParameters.get( "token" )
    let email = queryParameters.get( "email" )
    
    if( token ) {
        setToken( token )
    }
    if( token ) {
      setEmail( email )
  }
  }, [])

  return (
   <div className="w-screen h-screen bg-gray-50 py-5">
      <NavStripBar />
      <div className="w-full p-5 px-10">
        <Form navigate={navigate} token={token} emailAddress={emailAddress}/>
      </div>
   </div>
  )
}

const Form = ( { navigate, token, emailAddress } ) => {
    const [ signing, setSigning ] = useState( false )
  
  
    return (
      <div className="grid grid-cols-1 bg-white shadow-xl">
        <h2 className="border-b border-gray-[#CCC] font-bold px-3 py-2 text-center md:text-xl lg:text-xl text-base">
            {`Privacy Policy Statement`}
        </h2>
        <div className="py-2 my-2">
            <p className="text-xs md:text-sm lg:text-base p-4">
                <span className="text-green-700 font-bold">{`At Uganda Investment Authority,`}</span>
                {` we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Statement outlines our practices concerning the collection, use, and safeguarding of your data as you interact with our online licensing system in compliance with Ugandan data protection and privacy laws.`}
            </p>
        </div>
        <div className="flex flex-col items-start h-[70%] md:h-[450px] lg:h-[500px] overflow-y-scroll">
            <ItemFieldComponent 
                showContent={false}
                number="A."
                title="Information We Collect" 
                label="We may collect the following types of information when you use our online licensing system:" 
                list={[ 
                { 
                    heading: "Personal Information", 
                    content: "This includes, but is not limited to, your name, contact details, identification documents, and other data required for the licensing process.", 
                },
                { 
                    heading: "Transactional Information", 
                    content: "Information related to your interactions with our system, including application details, payments, and licensing history.", 
                },
                { 
                    heading: "Usage Information", 
                    content: "Data on how you navigate and use our online system, including IP addresses, device information, and browsing history.", 
                } 
            ]}
            />

            <ItemFieldComponent 
                number="B"
                showContent={false}
                title="How We Use Your Information" 
                label="We use the information collected for the following purposes:" 
                list={[ 
                { 
                    heading: "Licensing and Service Delivery", 
                    content: "To process and administer licensing requests and provide associated services.", 
                },
                { 
                    heading: "Communications", 
                    content: "To communicate with you regarding licensing status, updates, and important notices.", 
                },
                { 
                    heading: "Improvements", 
                    content: "To enhance and optimize our online licensing system for better user experience.", 
                },
                { 
                    heading: "Legal Compliance", 
                    content: "To comply with applicable laws, regulations, and legal processes.", 
                },
            ]}
            />

            <ItemFieldComponent 
                number="C"
                title="Data Security"
                showContent={true}
                content={`We employ reasonable security measures to protect your personal data from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.`}
            />

            <ItemFieldComponent 
                number="D"
                title="Data Retention"
                showContent={true}
                content={`We retain your personal data for as long as it is necessary for the purposes outlined in this Privacy Statement or as required by applicable laws and regulations. When no longer required, we will securely dispose of or anonymize the data.`}
            />

            <ItemFieldComponent 
                number="E"
                title="Third Parties"
                showContent={true}
                content={`We may share your data with trusted third parties, such as government authorities, payment processors, or service providers, solely for the purposes of processing your licensing requests and ensuring compliance with legal obligations.`}
            />

            <ItemFieldComponent 
                number="F"
                title="Your Rights"
                showContent={true}
                content={`You have rights regarding your personal data, including the right to access, correct, update, or delete your information. To exercise these rights or if you have any questions or concerns about your data, please contact us.`}
            />

            <ItemFieldComponent 
                number="G"
                title="Cookies and Tracking"
                showContent={true}
                content={`Our online licensing system may use cookies and similar tracking technologies to enhance your user experience. You may adjust your browser settings to manage cookies, but this may affect the functionality of the system.`}
            />

            <ItemFieldComponent 
                number="H"
                title="Changes to this Privacy Statement"
                showContent={true}
                content={`We reserve the right to update or amend this Privacy Statement to reflect changes in our practices or legal requirements. We will post any revisions on our website, and the revised version will be effective from the date of publication.`}
            />

            <ItemFieldComponent 
                number="I"
                showContent={false}
                title="Contact Information" 
                label="If you have questions or concerns about this Privacy Statement or our data handling practices, please contact us at:" 
                list={[ 
                { 
                    heading: "+256 414 301100", 
                    content: "", 
                },
                { 
                    heading: "info@ugandainvest.go.ug", 
                    content: "", 
                },
            ]}
            />
        </div>
      </div>
    )
}


const ItemFieldComponent = ( { showContent, content, number, label, title, list } ) => {
    return (
        <div className="grid grid-cols-1 px-5 my-3">
            <h2 className="font-bold text-xs md:text-sm lg:text-base underline">{`${number} ${title}`}</h2>
            {showContent ? (
                <div>
                    <p className="text-xs md:text-sm lg:text-sm py-2">
                        {content}
                    </p>
                </div>
            ) : (
                <>
                <p className="text-xs md:text-sm lg:text-sm py-1">
                    {label}
                </p>
                <div>
                {list.map( ( item, idx ) => {
                    return (
                      <div className="flex py-1">
                        <h5 className="text-xs md:text-sm lg:text-sm font-bold">{`${idx+1}. ${item.heading} ${item.content?.length > 0 ? ":" : ""}`}</h5>
                        {item?.content?.length > 0 && <p className="mx-2 text-xs md:text-sm lg:text-sm">{item.content}</p>}
                      </div>  
                    )
                } )}
                </div>
                </>
            )}
        </div>
    )
}