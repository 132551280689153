import { CircularProgress } from "@material-ui/core"

export default function Button( { loading, disabled = false, color, type, label, handlePress } ) {
  return (
    
    <button disabled={disabled} onClick={handlePress} 
      className={`${ loading && `flex flex-row justify-between items-center px-1` } bg-[#1BB0CE] rounded-2xl text-white px-5 py-2 text-sm focus:outline-none focus:border-none`}
      type={type}
      style={{ backgroundColor: color && "red" }}>
        <span>{label}</span>
        {loading && <CircularProgress size={14} color="primary" />}
    </button>
  )
}
