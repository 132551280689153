import Axios from 'axios'

const convert = require("xml-js");
const remote = "http://uat.caa.works.go.ug/api/v1"
const local = "http://localhost:8000/api/v1"

// export const API_BASE = `https://mis.nec.go.ug/api/auth`
export const API_BASE = `https://forest.works.go.ug/api/auth`

export const classNames = ( ...classes ) => classes.filter( Boolean ).join("")

export const fetchRefreshToken = async () => {
    try {
        const response = await Axios.post( `${remote}/account/token/refresh/`, {
            refresh: localStorage.getItem( "refresh" )
        }  )

        if( response.data ){
            await localStorage.removeItem( "token" )
            await localStorage.setItem( "token", response.data.access )
        }

    } catch( error ){
        alert( "Error refreshing token" )
    }
}  

export const truncate = ( str, n ) => ( str.length > n ) ? str.substr( 0, n - 1 ) + '...' : str

export const formatName = ( name ) => {
    let splitNames = name.split( " " )
    return splitNames[ 0 ].slice( 0, 1 )?.toUpperCase() + splitNames[ 0 ].slice( 1 ) + " "  +  splitNames[ 1 ].slice( 0, 1 )?.toUpperCase() + splitNames[ 1 ].slice( 1 )
}

export const createReferenceID = ( length, tag = true ) => {
    let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return tag ? result?.toUpperCase() : result;
}

export const generateOTP = ( length ) => {
    let chars = "0123456789"
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}


export const getSlug = ( location, path = false ) => {
    if( path ) {
        return location.pathname.split( "/" )?.[ 2 ]
    }
    return location.pathname.split( "/" )?.[ 3 ]
}

export const convertFileToBase = ( selectedFile, successCallback ) => {
    if( selectedFile.length > 0 ) {
        let fileToLoad = selectedFile[ 0 ]
        let fileReader = new FileReader()
        let base64 

        fileReader.onload = function( fileLoadedEvt ) {
            base64 = fileLoadedEvt.target.result 
            successCallback( base64 )
        }
        fileReader.readAsDataURL( fileToLoad )
    }
}

export const handleFileChange = (e, tag, callback) => {
    e.persist()
    if( e.target?.files.length > 0 &&  e.target.files[ 0 ].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  ){
      convertFileToBase(  e.target.files, result => {
        callback( result, e.target.files[ 0 ] )
      })
    }
    else {
      alert('File may not be attached correctly or of the correct format. Only pdf files are allowed to be attached')
    }
}

export const formatCurrency = amount => {
    const formatter = new Intl.NumberFormat('en-US', {
        // style: 'currency',
        // currency: 'UGX',
    } )
    return formatter.format( amount );
}

export const fetchShareholders = async ( businessNumber, successCallback, errorCallback ) => {
    if( businessNumber && businessNumber?.length > 0 ) {
        try {
            const response = await Axios.get( `${API_BASE}/companies?reg_no=${businessNumber}` )
            if( response ) {
              const data = JSON.parse( convert.xml2json( response.data, { compact: true, spaces: 2 } ) );
              successCallback( data.entity.metadata?.list_shareholders )
            }
            
        } catch (error) {
            errorCallback( error )
        }
    }
    else {
        return alert( "Please select the Business Registration Number" )
    }
    
}

export const handleError = async ( error ) => {
    if( Object.keys( error ).includes( "response" ) ) {
        const { data, status } = error.response 
        console.log( status );
    }
}

export const calcFooterSums = ( tag, data ) => {
    //employment details
    if( tag === "foreign" ) {
        let total = parseFloat( data.filter( val => val.slug === "administration" )?.[ 0 ]?.foreign2 || 0 ) +
         parseFloat( data.filter( val => val.slug === "managerial" )?.[ 0 ]?.foreign2 || 0 ) + parseFloat( data.filter( val => val.slug === "technician" )?.[ 0 ]?.foreign2 || 0 ) +
         parseFloat( data.filter( val => val.slug === "skilled" )?.[ 0 ]?.foreign2 || 0 ) + parseFloat( data.filter( val => val.slug === "semiskilled" )?.[ 0 ]?.foreign2 || 0 ) +
         parseFloat( data.filter( val => val.slug === "notskilled" )?.[ 0 ]?.foreign2 || 0 )
        return total
    }

    if( tag === "local" ) {
        let total = parseFloat( data.filter( val => val.slug === "administration" )?.[ 0 ]?.local2 || 0 ) +
         parseFloat( data.filter( val => val.slug === "managerial" )?.[ 0 ]?.local2 || 0 ) + parseFloat( data.filter( val => val.slug === "technician" )?.[ 0 ]?.local2 || 0 ) +
         parseFloat( data.filter( val => val.slug === "skilled" )?.[ 0 ]?.local2 || 0 ) + parseFloat( data.filter( val => val.slug === "semiskilled" )?.[ 0 ]?.local2 || 0 ) +
         parseFloat( data.filter( val => val.slug === "notskilled" )?.[ 0 ]?.local2 || 0 )
        return total
    }

    if( tag === "total" ) {
        let total = parseFloat( data?.employment?.administration_accounts_total ) +
         parseFloat( data?.employment?.managerial_supervisory_total ) + parseFloat( data?.employment?.technicians_total ) +
         parseFloat( data?.employment?.not_skilled_total ) + parseFloat( data?.employment?.skilled_total ) +
         parseFloat( data?.employment?.semi_skilled_total )
        return total
    }

    //finance
    if( tag === "first" ) {
        let total = parseFloat( data.filter( val => val.slug === "building" )?.[ 0 ]?.firstYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "furniture" )?.[ 0 ]?.firstYear || 0 ) + parseFloat( data.filter( val => val.slug === "installation" )?.[ 0 ]?.firstYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "land" )?.[ 0 ]?.firstYear || 0 ) + parseFloat( data.filter( val => val.slug === "machinery" )?.[ 0 ]?.firstYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "equipment" )?.[ 0 ]?.firstYear || 0 ) + parseFloat( data.filter( val => val.slug === "plant" )?.[ 0 ]?.firstYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "preoperating" )?.[ 0 ]?.firstYear || 0 ) + parseFloat( data.filter( val => val.slug === "transport" )?.[ 0 ]?.firstYear || 0 )
        return total
    }

    if( tag === "second" ) {
        let total = parseFloat( data.filter( val => val.slug === "building" )?.[ 0 ]?.secondYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "furniture" )?.[ 0 ]?.secondYear || 0 ) + parseFloat( data.filter( val => val.slug === "installation" )?.[ 0 ]?.secondYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "land" )?.[ 0 ]?.secondYear || 0 ) + parseFloat( data.filter( val => val.slug === "machinery" )?.[ 0 ]?.secondYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "equipment" )?.[ 0 ]?.secondYear || 0 ) + parseFloat( data.filter( val => val.slug === "plant" )?.[ 0 ]?.secondYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "preoperating" )?.[ 0 ]?.secondYear || 0 ) + parseFloat( data.filter( val => val.slug === "transport" )?.[ 0 ]?.secondYear || 0 )
        return total
    }

    if( tag === "third" ) {
        let total = parseFloat( data.filter( val => val.slug === "building" )?.[ 0 ]?.thirdYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "furniture" )?.[ 0 ]?.thirdYear || 0 ) + parseFloat( data.filter( val => val.slug === "installation" )?.[ 0 ]?.thirdYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "land" )?.[ 0 ]?.thirdYear || 0 ) + parseFloat( data.filter( val => val.slug === "machinery" )?.[ 0 ]?.thirdYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "equipment" )?.[ 0 ]?.thirdYear || 0 ) + parseFloat( data.filter( val => val.slug === "plant" )?.[ 0 ]?.thirdYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "preoperating" )?.[ 0 ]?.thirdYear || 0 ) + parseFloat( data.filter( val => val.slug === "transport" )?.[ 0 ]?.thirdYear || 0 )
        return total
    }


    if( tag === "funding_foreign" ) {
        let total = parseFloat( data.filter( val => val.slug === "loan" )?.[ 0 ]?.foreign || 0 ) + parseFloat( data.filter( val => val.slug === "share" )?.[ 0 ]?.foreign || 0 ) + parseFloat( data.filter( val => val.slug === "other" )?.[ 0 ]?.foreign || 0 )
        return total
    }

    if( tag === "funding_local" ) {
        let total = parseFloat( data.filter( val => val.slug === "loan" )?.[ 0 ]?.local || 0 ) + parseFloat( data.filter( val => val.slug === "share" )?.[ 0 ]?.local || 0 ) + parseFloat( data.filter( val => val.slug === "other" )?.[ 0 ]?.local || 0 )
        return total
    }
}

export const sanitizeValue = ( val ) => {
    return val?.replace(/([^\w ]|_)/g, '')
}