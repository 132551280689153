import React, { useState, useMemo } from 'react'
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy, usePagination } from 'react-table'
import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid'
import { Button, PageButton } from './ButtonComponents'
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai'
import { FaPlus } from 'react-icons/fa'



function GlobalFilter( { preGlobalFilteredRows, globalFilter, setGlobalFilter } ) {
    const count = preGlobalFilteredRows.length
    const [ value, setValue ] = useState( globalFilter )
    const onChange = useAsyncDebounce( value => {
        setGlobalFilter( value || undefined )
    }, 200 )

    return (
        <label className="flex gap-x-2 items-center justify-center px-2 py-1 w-full">
            {/* <span className="text-gray-700"> Search </span> */}
            {/* focus:border-indigo-50 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 */}
            <input 
                type="text"
                className="p-2 px-2 mt-1 block w-full rounded-sm focus:outline-none focus:text-gray-600 border border-gray-300"
                value={value || ""} 
                onChange={ evt => {
                    setValue( evt.target.value )
                    onChange( evt.target.value )
                } }
                placeholder={`Search through ${count} records...`} />
        </label>
    )
}

export const SelectColumnFilter = ( { column: { render, filterValue, setFilter, preFilteredRows, id } } ) => {
    const options = useMemo( () => {
        const options = new Set()
        preFilteredRows.forEach( ( row ) => {
            options.add( row.values[ id ])
        }  )
        return [ ...options.values() ]
    }, [ id, preFilteredRows ] )

    return (
        <label className="flex gap-x-2 items-baseline">
            <span className="text-gray-700">{render( "Header" )}</span>
            <select 
                name={id}
                id={id}
                value={filterValue}
                onChange={(e) => setFilter( e.target.value || undefined )}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                    <option value="">All</option>
                    {options.map( ( option, i ) => (
                        <option key={i} value={option}>
                            {option}
                        </option>
                    ) )}
            </select>
        </label>
    )
}

export default ( { columns, data, hidePagination = false, label, rightButton, rightButtonLabel, rightBtnCallback, exportToExcel, exportToCSV } ) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,

        canPreviousPage,
        canNextPage, 
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,


        state,
        preGlobalFilteredRows,
        setGlobalFilter,

        prepareRow
    } = useTable({ columns, data }, useFilters, useGlobalFilter, useSortBy, usePagination )
      
      return (
        <>
        {/* {!hidePagination && 
        <div className="flex flex-row px-0 my-2">
            {headerGroups.map( ( headerGroup ) => (
                headerGroup.headers.map( ( column ) => (
                    column.Filter ? (
                        <div key={column.id} className="border border-gray-300 px-2 rounded-sm w-48 mx-2 focus:outline-none">
                           
                            {column.render( "Filter" )}
                        </div>
                    ) : null
                ) )
            ) )}
        </div>} */}
         {/* <label for={column.id}>{column.render( "Header" )}:</label> */}

        {/* apply the table props */}
        <div className="flex flex-col w-full">
            {/* -mx-4 sm:-mx-6 lg:mx-8 */}
            <div className="overflow-x-auto py-2">
            {/* sm:px-6 lg:px-8 */}
                <div className="align-middle inline-block min-w-full px-0 py-3">
                    <div className="shadow-xl overflow-hidden sm:rounded">
                        
                        <div className="h-12 bg-white border border-gray-300 grid" style={{ gridTemplateColumns: "1fr 1fr 2fr"}}>
                            <div />
                            <div />

                            <div className="grid"  style={{ gridTemplateColumns: "1.5fr 0.5fr"}}>
                                {!hidePagination && ( <div className="flex flex-col gap-x-2 text-gray-300" style={{ fontSize: '0.75rem' }}>
                                    <GlobalFilter  
                                        preGlobalFilteredRows={preGlobalFilteredRows}
                                        globalFilter={state.globalFilter}
                                        setGlobalFilter={setGlobalFilter}
                                    />
                                </div> ) }
                                {rightButton && (
                                <button type="button" onClick={rightBtnCallback} 
                                    className="bg-green-700 border h-3/4 flex flex-row items-center justify-center text-sm text-white my-2 rounded-sm">
                                   <span className="text-center">{rightButtonLabel}</span>
                                   <span className="mx-2"><FaPlus color="white" /></span>
                                </button> )}
                            </div>
                        </div>

                        <table {...getTableProps()} className="w-full bg-gray-50 text-sm rounded-sm border border-red-0">
                            <thead className="bg-gray-300">
                                {// Loop over the header rows
                                headerGroups.map(headerGroup => (
                                // Apply the header row props
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {// Loop over the headers in each row
                                    headerGroup.headers.map(column => (
                                    // Apply the header cell props
                                    <th scope="col" 
                                        className="px-2 py-1 text-black text-left tracking-wider text-xs md:text-sm lg:text-sm" 
                                        {...column.getHeaderProps( column.getSortByToggleProps() )}>
                                        {// Render the header
                                        column.render( "Header" )}
                                        <span className="inline-flex items-center justify-center">
                                            {column.isSorted ? column.isSortedDesc ? <AiOutlineSortAscending /> : <AiOutlineSortDescending /> : ''  }
                                        </span>
                                    </th>
                                    ))}
                                </tr>
                                ))}
                            </thead>
                                {/* Apply the table body props */}
                                <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                                    {// Loop over the table rows
                                    page.map(row => {
                                    // Prepare the row for display
                                    prepareRow( row )
                                    return (
                                        // Apply the row props
                                        <tr {...row.getRowProps()} className="hover:bg-gray-100 text-left">
                                            {// Loop over the rows cells
                                            row.cells.map(cell => {
                                                // Apply the cell props
                                                return (
                                                <td {...cell.getCellProps()} className="p-2 whitespace-nowrap text-xs md:text-sm lg:text-sm text-left text-gray-700">
                                                    {// Render the cell contents
                                                    cell.render('Cell')}
                                                </td>
                                                )
                                            })}
                                        </tr>
                                    )} ) }
                                </tbody>
                                <tfoot className="w-full h-10 border relative">
                                    <div className="absolute grid gap-2 right-0 w-3/4 h-full" style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                                        <div className="grid grid-cols-4">
                                            <div />
                                            <div />
                                            {/* <button onClick={exportToExcel} className="h-full text-center text-sm text-gray-500 uppercase" type="button" style={{ fontSize: "0.75rem" }}>
                                                xls
                                            </button>
                                            <button onClick={exportToCSV} className="h-full text-center text-sm text-gray-500 uppercase" type="button" style={{ fontSize: "0.75rem" }}>
                                                csv
                                            </button> */}
                                        </div>

                                        <div className="grid grid-cols-2">
                                            
                                            {!hidePagination && ( <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                                <div className="flex gap-x-2">
                                                    <span className="text-gray-700 text-xs">
                                                        Page <span className="font-medium">{state.pageIndex + 1 }</span> of <span className="font-medium">{pageOptions.length}</span>
                                                    </span>
                                                </div>
                                            </div> ) }
                                            {!hidePagination && <label className="flex items-center">
                                                <span className="sr-only">Per page</span>
                                                <select
                                                    className="text-xs py-2 block w-full border border-gray-100 shadow-sm focus:border-none focus:outline-none focus:ring-opacity-50" 
                                                    value={state.pageSize} 
                                                    onChange={ evt => setPageSize( Number( evt.target.value ) )}>
                                                    { [ 5, 10 ].map( pageSize => (
                                                        <option key={pageSize} value={pageSize}>
                                                            Per Page {pageSize}
                                                        </option>
                                                    ) ) }
                                                </select>
                                            </label>}
                                        </div>
                                        <div>
                                         {!hidePagination && ( <div className="px-2 flex items-center justify-end py-1">
                                            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                                <PageButton className="rounded-l-md" onClick={() =>gotoPage( 0 )} disabled={!canPreviousPage}>
                                                    <span className="sr-only">First</span>
                                                    <ChevronDoubleLeftIcon className="h-4 w-5" aria-hidden="true" />
                                                </PageButton>

                                                <PageButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                    <span className="sr-only">Previous</span>
                                                    <ChevronLeftIcon className="h-4 w-5" aria-hidden="true"/>
                                                </PageButton>

                                                <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                                                    <span className="sr-only">Next</span>
                                                    <ChevronRightIcon className="h-4 w-5" aria-hidden="true"/>
                                                </PageButton>

                                                <PageButton onClick={() => gotoPage( pageCount -1 )} disabled={!canNextPage}>
                                                    <span className="sr-only">Last</span>
                                                    <ChevronDoubleRightIcon className="h-4 w-5" aria-hidden="true"/>
                                                </PageButton>
                                            </nav>
                                         </div>)}        
                                        </div>
                                    </div>
                                </tfoot>            
                            </table>
                    </div>
                </div>
            </div>
        </div>
        </>
      )
}
