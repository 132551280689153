import React, { useEffect, useState } from 'react'
import { Wrap, Panel, TextAreaIcon, TextFieldIcon, SelectFieldIcon, PhoneFieldIcon, TextField } from '../../../../_components'
import { API_BASE, truncate, convertFileToBase } from '../../../../_utils'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setSponsorList, setDisplayModal } from '../../../../_slices/appSlice'

export default function SectionC({ userInfo, handleChange, handleBlur , values}) {
  const navigate = useNavigate();

  const dispatch = useDispatch()
  const state = useSelector( state => {
    return {
      education: state?.application?.education,
      engineering: state?.application?.engineering,
      practicals:  state?.application?.practicals,
      positions:  state?.application?.positions,
      membership:  state?.application?.membership,
      sponsors:  state?.application?.sponsors,
      flag: state?.application?.displayModal?.flag
    }
  } )

  const [passportFile, setPassportFile] = useState(null);
  const [uceFile, setUCEFile] = useState(null);
  const [uaceFile, setUACEFile] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [transcriptFile, setTranscriptFile] = useState(null);
  const [trainings, setTrainings] = useState(['', '']);
  const [ inputs, setInputs ] = useState( {} )

  const [ display, setDisplay ] = useState( false )

  const [ sponsors, setSponsors ] = useState( {} )
  

  const [ file, setFile] = useState( null )
  const [ fileEngineering, setEngineeringFile] = useState( null )
  const [ membershipFile, setMembershipFile ] = useState( null )
  const [ filePosition, setPositionFile] = useState( null )

  const [ GeneralEducation, setGeneralEducation ] = useState( [] )

  useEffect( () => {
    if( sponsors?.attach_file ){
      handleFile( sponsors?.attach_file );
    }
  }, [ sponsors ])


  const handleFile = ( obj  ) => {
    convertFileToBase(obj, response => {
      setFile( response )
    } )
  }

  const handleInputChange = async ( evt ) => {
    setSponsors( prevState => {
      return { ...prevState, [ evt.target.name ] : evt.target.name === "attach_file" ? evt.target.files :  evt.target.value }
    } )
  }


  const handleOnSave = async () => {
    await dispatch( setSponsorList( [ ...state.sponsors, {
      sponsor_name: sponsors?.sponsor_name,
      registered: sponsors?.registered,
      registration_number: sponsors?.registered === "Yes" ? sponsors?.registration_number : "NA",
      discipline: sponsors?.discipline
    } ] ) )

    setDisplay( !display )
  }

  const handleDisplayModal = async ( payload ) => {
    await dispatch( setDisplayModal( {
      flag: !state?.flag,
      title: payload.title,
      tag: payload.tag,
      operation: "add"
    } ) )
  }

  return (
    <>
      <Panel label="Section C: Sponsor Particulars">
        {/* {display && <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3">
            

            <div className="flex flex-row justify-end items-end">
              <div />
              <button type="button" className="my-2 w-16 h-8 text-sm bg-blue-500 p-2 text-white" onClick={handleOnSave}>
                {`Save`}
              </button>
            </div>
        </div>} */}

        <div className="grid grid-cols-1 bg-white">
          <div className="flex flex-row items-center justify-between p-2">
            <div />
            {/* {!display && <button onClick={ () => setDisplay( !display )} type="button" className="text-xs bg-red-500 p-1 px-3 text-white">
              {`Add`}
            </button>} */}
            {!display && <button onClick={() => handleDisplayModal( {
              title: "Sponsor Particulars",
              tag: "sponsors"
            } )} type="button" className="text-xs bg-red-500 p-1 px-3 text-white">
              {`Add`}
            </button>}
          </div>
          <table className="w-full border">
            <tr className="text-xs font-bold text-start">
              <th className="text-start p-2">{`Name`}</th>
              <th className="text-start p-2">{`Registered Engineer`}</th>
              <th className="text-start">{`Registration Number`}</th>
              <th className="text-start p-2">{`Discipline`}</th>
            </tr>
            <tbody>
                {state?.sponsors?.map( ( item, index ) => {
                    return (
                        <tr key={index} className="text-xs p-2 text-start border">
                            <td className="py-2 text-center">{item?.sponsor_name}</td>
                            <td className="py-2 text-center">{item?.registered}</td>
                            <td className="py-2 text-center">{item?.registered === "Yes" ? item?.registration_number : "N/A"}</td>
                            <td className="py-2 text-center">{item?.discipline}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
      </div>
      </Panel> 

      <Panel label="Report Attachments">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
            <div className="flex flex-col">
              <label>{`Attach A Technical Report`}</label>
              <TextFieldIcon 
                  name="attach_file"
                  type="file"
                  handleChange={ evt => {
                    handleChange( evt )
                    // handleFileConvert( evt.target.files )
                  }}
                  value={values.attach_file}
                  question="Attach File(PDF)"
                  label="Attach File"
                  required={true}
                  disabled={false}
              />
            </div>


            <div className="flex flex-col">
              <label>{`Attach A Career Report`}</label>
              <TextFieldIcon 
                  name="career_report"
                  type="file"
                  handleChange={ evt => {
                    handleChange( evt )
                    // handleFileConvert( evt.target.files )
                  }}
                  value={values.career_report}
                  question="Attach File(PDF)"
                  label="Attach File"
                  required={true}
                  disabled={false}
              />
            </div>
          </div>
      </Panel>
    </>
  );
}
