import React, { useEffect, useMemo, useState } from 'react'
import { Wrap, Panel, ProgressBar } from '../_components'
import Table from '../_components/Table'
import { CircularProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import moment from 'moment'
import Board from './_components/Board'
import { API_BASE } from '../config'
import { truncate } from '../_utils'
import { calculateProgress } from './_utils'
import axios from 'axios'


const StatusField = record => {
    const display = () => {
        switch( record.value?.toLowerCase() ) {
            case "review":
                return "UNDER PROCESSING"
            case "issued":
                return "APPROVED"
            case "rejected":
                return "REJECTED";
            case "revisions_required":
                return "DEFERRED"
            default:
                return "UNDER PROCESSING";
        }
    }
    //${display() === "APPROVED" ? `bg-purple-500` : `bg-red-500`}

    return (
        <span className={`px-2 py-1 text-xs uppercase rounded-sm text-black`}>
            {display()}
        </span>
    )
}

const ProgressField = ( record ) => {
    let progress = calculateProgress( record.value, record.row.original?.status )
    return (
        <div className="flex w-full items-center justify-center">
            <ProgressBar value={progress}/>
        </div>
    )
}

export default function Home() {
    const navigate = useNavigate()
    const columns = useMemo(
    () => [
        {
            Header: "Date Created",
            accessor: "created_at",
        },
        {
            Header: "Tracking ID",
            accessor: "id",
        },
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Progress",
            accessor: "stage",
            Cell: ProgressField
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: StatusField
        },
        ]
    )

    const [ loading, setLoading ] = useState( true )
    const [ results, setResults ] = useState( [] )
    const [ admin, setAdmin ] = useState( "")
  
    useEffect( () => {
        if( loading ) {
            fetchApplications()
        }
    }, [ loading ] )

    const fetchApplications  = async () => {
        let role = await localStorage.getItem( "role" )
        setAdmin( role )
        let user = JSON.parse( localStorage.getItem( "user" ) )

        try {
            const response = await axios.get( `${API_BASE}/erb_getLicences`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            } ) 
            if( response.data ){
                const records = response.data?.records?.map( result => {
                    console.log( result )
                    return {
                        ...result,
                        resultId: result.id,
                        fullname: result?.name,
                        date: result.created_at,
                        applicationType: result.type === "1" ? "Citizen" : "Foreigner",
                        date_submitted: moment( result.created_at ).format( "DD-MMM-YYYY hh:mm A" )
                    }
                } )
                .sort( ( a, b ) => {
                    return moment( b.date ).format( "YYYYMMDDHHMM" ) - moment( a.date ).format( "YYYYMMDDHHMM" )
                } )

                // set the state
                if( localStorage.getItem( "role" )?.toUpperCase() === "REGISTRAR" ) {
                    let res = records.filter( item => item.stage === "1" ) 
                    setResults( res )
                }
                else if( localStorage.getItem( "role" )?.toUpperCase() === "BOARD" ) {
                    let res = records.filter( item => item.stage === "2" ) 
                    setResults( res )
                }
                // else if( localStorage.getItem( "role" )?.toUpperCase() === "BOARD" ) {
                //     let res = records.filter( item => item.stage === "3" ) 
                //     setResults( res )
                // }
                else {
                    setResults( records.filter( item => item?.applicant_id === user?.id ) )
                }
                
                setLoading( false )
            }
        } catch ( error ) {
            return alert( error?.response?.data?.message || "Could not return applications. Server error occured while Fetching applications." )
        }
    }

    if( loading ) {
        return (
            <div className="h-screen w-screen flex items-center justify-center">
                <div className="flex">
                    <span className="text-xs md:text-base lg:text-base">{`Loading...`}</span>
                    <CircularProgress size={24} color={`blue`} />
                </div>
            </div>
        )
    }

  
    return (
    <Wrap navigate={navigate}>
        <div className="grid grid-cols-4 gap-3 py-5">
                <div className="grid col-span-3">
                    <div className="grid md:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-5 bg-gray-100 py-2 px-3">
                    <Board color={`blue`} handlePress={() => {}} count={results.length} title="Pending Applications" />
                    <Board color={`red`} handlePress={() => {}} title="Under Processing" count={results.filter( item => ( item.status?.toLowerCase() !== "issued" && item.status?.toLowerCase() !== "rejected")  ||  item.status?.toLowerCase() === "approved" ).length} />
                    <Board color={`green`} handlePress={() => {}} title="Approved" count={results.filter( item => item.status?.toLowerCase() === "issued").length}  />
                </div>
                    <div className="flex flex-col items-center py-2 px-3 bg-white my-4">
                        <div className="w-full flex items-center">
                            <h2 className="font-bold">{`My Latest Applications`}</h2>
                        </div>
                        { loading ? (
                            <div className="flex items-center justify-center">
                                <CircularProgress size={24} color="green" />
                            </div>
                        ) : (  results.length > 0  ) ?
                            <Table
                                label={"Applications"}
                                columns={columns}
                                data={results}
                                hidePagination={true}
                            /> 
                        : (
                            <div className="text-xs flex items-center justify-center">
                                {`There are no records at the moment.`}
                            </div>
                        ) }
                    </div>
                </div>
            </div>
    </Wrap>
  )
}
