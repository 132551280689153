import React from 'react'
import { FaDashcube, FaPlusCircle } from 'react-icons/fa'

const Panel = ( { label, onHandleAdd, handlePress, removeBtn, handleRemove, children, margin } ) => {
    return (
        <div className="shadow-xl my-2 h-auto border border-gray-300 outlie-none">
            <div className="w-full bg-black px-2 flex flex-row items-center justify-between">
                <span className="md:text-base lg:text-base text-xs text-white px-2 py-1 font-normal">
                    {label}
                </span>
                {onHandleAdd && (
                    <div className="flex items-center justify-center">
                        <button type="button" className="px-2" onClick={handlePress}>
                            <FaPlusCircle color="#FFF" />
                        </button>
                        {removeBtn && ( 
                        <button type="button" className="mx-2 px-2" onClick={handleRemove}>
                            <FaDashcube color="#FFF" />
                        </button> )}
                    </div>
                ) }
            </div>
            <div className="px-2 py-3">
                {children}
            </div>
        </div>

    )
}
export default Panel
