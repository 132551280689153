import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { classNames } from '../_utils'
import { at } from 'lodash'
import { useField } from 'formik'

export default function PhoneFieldIcon( { required, noPadding, disabled =  false, question = "", name, label, handleChange, handleBlur, value } ) {
  const [ field, meta ] = useField( name )
  const [ phoneNumber, setPhone ] = useState( "" )

  const _renderText = () => {
    const [ touched, error ] = at( meta, 'touched', 'error' ) 
    if( touched && error )
      return error
  }
    

  return (
    <div className={`${ noPadding ? `flex flex-col items-start justify-center my-4` : `flex flex-col items-start justify-center my-2`}`}>
      <p className="text-gray-700 py-1 text-xs md:text-base lg:text-base">
        {question}{ required && ( <span className="text-red-500">*</span>) }
      </p>
      <PhoneInput
          country={'ug'}
          placeholder="Phone Number"
          value={phoneNumber || value }
          onChange={( value, country, evt, formattedValue ) => {
              setPhone( value )
              handleChange( evt )
          }} 
          onBlur={handleBlur}
          inputProps={{
              name: name,
              id: name,
              required: true,
              autoFocus: true
          }}
          // disabled={disabled}
          countryCodeEditable={false}
          inputStyle={{ width: '100%', border: "1px solid #8cadfb", borderRadius: "2px", fontSize:"0.75rem"  }}
          containerClass="inline-flex text-xs"
      />
      <p className="text-gray-700 py-1 text-xs">
        {/* {question}{ required && ( <span className="text-red-500">*</span>) } */}
      </p>
    </div>
  )
}
