import React from 'react'
import NavStripBar from '../_components/NavStripBar'
// import  Wrap  from 'src/_components/Wrap'/
import SignIn from './SignIn'


export default function Login() {

  return (
   <div className="w-screen h-screen bg-gray-50">
      <NavStripBar />
      <div className="px-5 sm:px-8 md:px-0 lg:px-0 my-5">
        {/* <Wrap /> */}
        <SignIn />
      </div>
   </div>
  )
}