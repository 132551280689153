import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create( {
    page: {
        display: "flex",
        paddingHorizontal: "25px",
        paddingVertical: "10px;"
    },
    container: {
        display: 'flex',
        backgroundColor: 'red'
    },
    contentContainer: {
        flex: 1,
        flexDirection: 'column',
        padding: "15px",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    
    header: {
        display: 'flex',
        flexDirection: 'row',
        width: "100%",
        flex: 2,
        borderWidth: 1
    },
    leftHeaderContainer: {
    },
    rightHeaderContainer: {

    },
    body: {
        flex: 3
    },
    footer: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey"
    }
    // leftImage: {
    //     width: '60px',
    //     height: '60px'
    // },
    // rightImage: {
    //     width: '16px',
    //     height: '16px'
    // }
} )