import React, { useEffect, useMemo, useState } from 'react'
import { Wrap, ProgressBar } from '../../_components'
import Table from '../../_components/Table'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { CircularProgress } from '@material-ui/core'
import { API_BASE } from '../../config'
import { truncate, calculateProgress } from '../_utils'

    const ProgressField = ( record ) => {
        // let progress = calculateProgress( record.value, record.row.original?.status )
        return (
            <div className="flex w-full items-center justify-center">
                <ProgressBar value={record.value}/>
            </div>
        )
    }

    const ActionView = ( record ) => {
        const navigate = useNavigate()

        return (
            <div className="flex flex-row items-center cursor-pointer" onClick={ () => navigate( `/my-application-details/${record?.value}`, { licenseNo: record?.row.original.license_no } )}>
                <FaExternalLinkAlt color="green" />
                <span className="px-2 underline text-green-700 uppercase">
                    {`Open`}
                </span>
            </div>
        )
    }

    const StatusField = record => {
        let color = ""
        switch( record.value?.toLowerCase() ){
            case "review":
                color =  "blue"
            default:
                color = "white"
        }

        const display = () => {
            switch( record.value?.toLowerCase() ) {
                case "review":
                    return "UNDER PROCESSING"
                case "verified":
                    return "VERIFIED"
                case "issued":
                case "approved":
                    return "APPROVED"
                case "rejected":
                    return "REJECTED";
                case "deferred":
                    return "DEFERRED"
                default:
                    return "UNDER PROCESSING";
            }
        }

        return (
            <span className={`px-2 py-1 text-xs font-bold uppercase text-black`}>
                {display()}
            </span>
        )
    }

export default function MyApplications() {
    const navigate = useNavigate()
    const columns = useMemo(
    () => [
            {
                Header: "Name",
                accessor: "fullname",
            },
            {
                Header: "Form Type",
                accessor: "type",
            },
            {
                Header: "Tracking ID",
                accessor: "id",
            },
            {
                Header: "Submission Date",
                accessor: "date_submitted",
            },
            {
                Header: "Progress",
                accessor: "progress",
                sortable: false,
                filterable: false,
                Cell: ProgressField
            },
            {
                Header: "Status",
                accessor: "status",
                sortable: false,
                filterable: false,
                Cell: StatusField
            },
            {
                Header:  "Actions",
                accessor: "resultId",
                sortable: false,
                filterable: false,
                Cell: ActionView
            },
        ]
    )
    const [ loading, setLoading ] = useState( true )
    const [ results, setResults ] = useState( [] )
    const [ admin, setAdmin ] = useState( "" )
  
    useEffect( () => {
        if( loading ) {
            fetchApplications()
        }
    }, [ loading ] )

    const fetchApplications  = async () => {
        let role = await localStorage.getItem( "role" )
        setAdmin( role )
        let user = JSON.parse( localStorage.getItem( "user" ) )

        try {
            const response = await axios.get( `${API_BASE}/erb_getLicences`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            } ) 
            if( response.data ){
                const records = response.data?.records?.map( result => {
                    return {
                        ...result,
                        type: result?.type,
                        resultId: result.id,
                        fullname: result?.name,
                        date: result.created_at,
                        applicationType: result.type === "1" ? "Citizen" : "Foreigner",
                        date_submitted: moment( result.created_at ).format( "DD-MMM-YYYY hh:mm A" )
                    }
                } )
                .sort( ( a, b ) => {
                    return moment( b.date ).format( "YYYYMMDDHHMM" ) - moment( a.date ).format( "YYYYMMDDHHMM" )
                } )

                // set the state
                if( localStorage.getItem( "role" )?.toUpperCase() === "REGISTRAR" ) {
                    let res = records.filter( item => item.stage === "1" && item?.application_type !== "RENEW" && item?.status !== "DEFERRED" ) 
                    setResults( res )
                }
                else if( localStorage.getItem( "role" )?.toUpperCase() === "BOARD" ) {
                    let res = records.filter( item => item.stage === "2"  && item?.application_type !== "RENEW" && item?.status !== "DEFERRED" ) 
                    setResults( res )
                }
                // else if( localStorage.getItem( "role" )?.toUpperCase() === "BOARD" ) {
                //     let res = records.filter( item => item.stage === "3" ) 
                //     setResults( res )
                // }
                else {
                    setResults( records.filter( item => item?.applicant_id === user?.id ) )
                }
                
                setLoading( false )
            }
        } catch ( error ) {
            return alert( error?.response?.data?.message || "Could not return applications. Server error occured while Fetching applications." )
        }
    }

    return (
    <Wrap navigate={navigate}>
        <div className="flex flex-col items-center py-2 px-3 bg-white my-4 w-full">
            <div className="w-full flex items-center justify-between">
                {results.length > 0 ? <h2 className="font-bold">
                    {`${ ( localStorage.getItem( "role" )?.toUpperCase() === "REGISTRAR" || localStorage.getItem( "role" )?.toUpperCase() === "BOARD" ) ? "Pending" : "My" } Applications`}
                </h2> : (
                    <div />
                )}
                {localStorage.getItem( "registration_type" ) === "No" && results.length > 0 && !( admin === "REGISTRAR" || admin === "SUBCOMMITEE" || admin === "BOARD" ) && <button type="button" className="bg-green-500 px-2 py-1 text-white" onClick={ () => navigate( "/application" )}>
                    {`Make A New Application`}
                </button>}
            </div>
            { loading ? (
                <div className="flex items-center justify-center">
                    <span>{`Loading Applications...`}</span>
                    <CircularProgress size={24} color="green" />
                </div>
            ) :( results.length > 0 ) ?
             <Table
                label={"Applications"}
                columns={columns}
                data={results}
                hidePagination={false}
            /> 
            : (
                <div className="flex flex-col items-center justify-center py-2">
                    <h5 className="font-bold uppercase">
                        {`Your Applications`}
                    </h5>
                    {( admin === "REGISTRAR" || admin === "SUBCOMMITEE" || admin === "BOARD" ) ? (
                        <p className="p-2">
                            {`You currently do not have any pending applications on your docket. Your Docket is Empty.`}
                        </p>
                    ) :
                    <p className="p-2">
                        {`You have not made any new Applications. Please Click Apply for a Licence and make a new Application.`}
                    </p>}
                </div>
            ) }
        </div>
        
    </Wrap>
  )
}
