import React from 'react'
import { FaJournalWhills } from 'react-icons/fa'

const Board = ( { count, title, color } ) => {
    return (
        <div className={`${ color == "blue" ? "bg-blue-500" : ( color == "red" ? "bg-red-500" : "bg-green-700" )  } grid h-14 md:h-32 lg:h-36  bg-white rounded-md`} style={{ gridTemplateRows: "4rem 1fr" }}>
            <div className="flex items-center justify-center p-2 text-white">
                <div className="w-auto flex flex-wrap p-1 text-base">
                    {title}
                </div>
            </div>
            <div className="p-3 flex items-center justify-center  text-white">
                <div className="text-base md:text-3xl lg:text-5xl">
                    {(parseFloat( count ) < 10 && parseFloat( count ) > 0 ) ? `0` + "" + count : `${count}` }
                </div>
            </div>
        </div>
    )
}

export default Board
