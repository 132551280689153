import React from 'react'

const TextField = ( { iconComponent, label, name, value, type = "text" } ) => {
    return (
      <div className={"flex flex-col items-start my-2 px-3"}>
          <label className="text-sm py-1 font-normal">{label}</label>
          <div className={"inline-flex w-full rounded" }>
            <input 
              name={name}
              disabled={true}
              id={name}
              type={type} 
              placeholder={label}
              value={value || `NA`}
              autoComplete="off"
              style={{ fontFamily: "LatoBold" }}
              className={ "w-full px-0 py-1 focus:outline-none focus:text-gray-600 placeholder-gray-300 text-sm font-bold"  } 
            />
        </div>
      </div>
    )
}

export default TextField