import React, { useState } from 'react'
import Elogo from '../../_images/shortERBlogo.png'
import footerImg from '../../_images/gears.png'
import { useNavigate } from 'react-router-dom'
import Visible from '../../_images/visibility.png'
import NotVisible from '../../_images/ClosedEyes.png'
import axios from 'axios'
import { API_BASE } from '../../config'

export default function SignIn({noScroll = false }) {
  const navigate = useNavigate()
  const [ signing, setSigning ] = useState( false )
  const [ inputs, setInputs ] = useState( {} )
  const [password, setPassword] = useState('');
  const [ showPassword, setShowPassword ] = useState( false )

  const handleSubmit = async ( e ) => {
    e.preventDefault()

    try {
      setSigning( true )
      const response = await axios.post( `${API_BASE}/erb_loginUser`, {
        "email": inputs.email,
        "password": inputs.password
      } )

      if( response.data ){

       const { record  } = response.data 

       if( record?.length > 0 ) {

        await localStorage.setItem( "token", "12345678910ABCDE" )
        await localStorage.setItem( "registration_type", record?.[ 0 ]?.registered )
        await localStorage.setItem( "user", JSON.stringify( record?.[ 0 ] ) )
        await localStorage.setItem( "role", record?.[ 0 ]?.user_type ?  record?.[ 0 ]?.user_type : "applicant"  )

        if( record?.[ 0 ]?.user_type ) {
          navigate( "/dashboard" )
          setSigning( false )
        }
        else {
          navigate( "/application" )
          setSigning( false )
        }
       }
      }
      else {
        navigate( "/login" )
        setSigning( false )
        window.location.reload()
        
      }
    } catch (error) {
     if( error?.response ) {
      const { status, statusText } =  error.response
      if( status === 401 ) {
        alert( `${statusText} - Your account may not exist yet or Your Account Credentials may be wrong. Please check your Email Address or Password and try again.` )
      }
      else {
        alert( `Your Email Address or Password may be incorrect.` )
      }
     }
     else {
      alert( "Server error occured. Could not log in." )
     }
     setSigning( false )
    }
    // return alert( "Admin will enable access to your account at a later time." )
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };


  const handleChange = ( evt ) => {
    setInputs( prevState => {
      return { ...prevState, [evt.target.name]: evt.target.value }
    } )
  }

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState)
  }

  // const togglePasswordVisibility = () => {
  //   setShowPassword(!showPassword);
  // };

  return (
    <div className={'min-h-screen flex flex-col justify-center items-center bg-gray-100 ${ noScroll ? "scrollbar-hide" : ""}'}>
      <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-lg"> 
        <div className="flex w-full items-center justify-evenly">
          <img src={Elogo} alt="Engineering Board Logo" className="md:w-24 lg:w-24 w-20" />
        </div>
        {/* <h2 className="py-2 md:text-base lg:text-base text-center text-xs uppercase font-bold">{'Engineers Registration Board'}</h2> */}
        <h2 className="px-3 py-2 text-center md:text-xl font-bold lg:text-xl text-xs">Log In</h2>
        <form className="mb-4" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email"
              className="block text-gray-700 text-sm font-bold mb-2">
              Email Address
            </label>
            <input type="email" id="email" name="email" autoComplete='off'
              className="shadow appearance-none border-b rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Your Email Address"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password"
              className="block text-gray-700 text-sm font-bold mb-2">
                {`Password`}
            </label>
            <input 
              type={showPassword ? 'text' : 'password'} 
              autoComplete='off'
              id="password"  
              name="password"
              className="shadow appearance-none border-b rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter your Password"
              onChange={handleChange}
            />
            
            <span onClick={togglePasswordVisibility}
              className="absolute   pr-3 flex  cursor-pointer"
              style={{ width: 35, height:25, marginLeft:360, marginTop:-34, }}> 
              {showPassword ? <img src={NotVisible}/> : <img src={Visible}/>}
            </span>
            <a href="/reset-password" className="text-[#3071F2] hover:text-[#0256FE] text-xs " style={{ marginTop:-27}}>
              Forgot Password?</a>
          </div>
          <div className='flex justify-center'>
            <button
              type="submit"
              className="bg-[#EE1C26] hover:bg-red-500 text-white font-bold py-2 px-4 
              rounded-full focus:outline-none focus:shadow-outline justify-center uppercase"
              style={{ borderRadius: '30px', width: '150px' }}>Sign in</button>
          </div>
        </form>
        <div className="mb-4">
          <p>
            Don't have an account?{" "}
            <a href="/Register" className="text-[#3071F2] hover:text-[#0256FE]" rel="noopener noreferrer">
              Create A New Account
            </a>
          </p>
        </div>
      </div>
      <img src={footerImg} alt="gears" className="mt-8 rounded-lg shadow-lg mr-0"/>
    </div>
  )
}
