import React, { useState } from 'react'
import { Panel, Wrap } from '../../_components'
import { useLocation, useNavigate } from 'react-router-dom'
import mining1 from '../../_images/Mining1.png'
import { Box, Stepper, Step, StepLabel, StepContent, Button, Paper, Typography } from '@material-ui/core'
// import AllPages from '../_components/AllPages'

import Buganda1 from "../../_documents/Buganda-Investment-Profile.pdf"
import Bukedi1 from "../../_documents/Bukedi-Investment-Profile.pdf"
import Karamoja1 from "../../_documents/Buganda-Investment-Profile.pdf"
import Teso from "../../_documents/Teso-Investment-Profile.pdf"

import Arua from "../../_documents/Arua.pdf"
import Hoima from "../../_documents/Hoima.pdf"
import Isingiro from "../../_documents/Isingiro.pdf"
import Lamwo from "../../_documents/Lamwo.pdf"
import Moyo from "../../_documents/Moyo.pdf"
import Obongi from "../../_documents/Obongi.pdf"
import Yumbe from "../../_documents/Yumbe.pdf"

const steps = [
    {
      label: 'Tourism',
      description1: `Tourism is a fast-growing sector supported by the fact that Uganda is ranked as a top tourist destination and one of only three countries with about 50% of the world’s known population of endangered mountain gorillas. (Source: Lonely planet 2012 and CNN 2016, which ranked Uganda among the top 16 holiday destinations in the world)`,
      description2: "Game viewing is the most popular tourist activity in the country’s national parks. This is because the country has a variety of game, including the rare tree-climbing lions of Ishasha, white rhinoceros, and elephant and it is home to 11% of the world’s bird species (a total of 1,060 bird species). It is also blessed with unspoiled scenic beauty including forests, hills and mountains, rivers and lakes",
      subtitle1: "Opportunities in tourism range from",
      list: [ 
        "Constructing high quality accommodation facilities", 
        "Operating tour and travel circuits (bicycle tours, air balloon travel, marine activities on Lake Victoria and river rafting on the Nile River)",
        "Development of specialised eco and community tourism facilities and faith-based tourism such as pilgrimages to the Namugongo-Uganda martyrs, Mahatma Gandhi statue, and Bishop Hannington landing site on the Nile",
        "Steadily growing tourist numbers are estimated to have reached 1.5 million per year, contributing 7.7% to GDP" 
      ],
      subtitle2: "Featured Investment Projects",
      panels: [
        { 
            title: "1. HIGH END COTTAGES", 
            amount: "US$3,000,000", 
            location: "10 NATIONAL PARKS", 
            content: "The project will construct 100 cottages for tourist accommodation. The project seeks to address bed shortage in Bwindi, Mgahinga, Queen Elizabeth, Rwenzori, Semuliki, Kibale, Elgon, Murchison falls, Kidepo & Lake Mburo. In the high tourism season, there’s a 50% shortage of beds in the national parks",
        },
        { 
            title: "2. SOURCE OF THE NILE DEV'T", 
            amount: "US$150 Million", 
            location: "SOURCE OF THE NILE, JINJA", 
            content: "The project is meant to build infrastructure around the source of the Nile such as public infrastructure (beautified), Cultural centres, Entertainment facilities, Hotel and recreation, Tourism water activities , Zip lines, Information Centre, Monuments and floating restaurants, alongside an improved golf already in existence for socio- economic benefits",
        },
        { 
            title: "3. ECO-TOURISM PRODUCTS", 
            amount: "US$ 1 - 55 MILLION", 
            location: "COUNTRYWIDE", 
            content: "There are numerous investments which can be undertaken in different national parks and en route to national parks. These opportunities include Luxury hotels, tented camps and cottages; House Boats, Cruise Launches on the Nile and Sport fishing on the Albert, Edward and George; Walking Safaris in the wilderness areas and the volcanic craters; Canopy Walkways in tropical forests and sky trams",
        }
      ]
    },
    {
      label: 'Pharmaceuticals',
      description1: 'Uganda combines a growing pharma market with access to the EAC market of 175 million people. With relatively high levels of skills and education and an investor-friendly environment, Uganda is a burgeoning pharmaceuticals centre.',
      description2: `Strength of the Pharma sector in Uganda:
      In 2018, there were over 300 life sciences companies and over 30 pharmaceuticals and medical devices manufacturing companies in Uganda {Source: fDi Benchmark from the Financial Times Limited).
        These companies currently manufacture 173 pharmaceutical products in Uganda {Source: Uganda National Drug Authority).
        Uganda is highly specialised in pharmaceuticals; the chemical sector (which includes pharmaceuticals) accounts for over 10% of Uganda’s manufacturing value added – the highest in the region.
        `,
      description3: `Medical Consumables:
     Medical consumables is a rapidly growing segment of Uganda’s healthcare sector. In 2017, the import of medical consumables in Uganda grew by over 60% compared to 6% growth in Sub-Saharan Africa as a whole.
      Total medical consumables imports into EAC are averaging over $250 million per annum with Uganda the fastest growing market.
      There is strong investment potential to produce cotton-based consumables in Uganda including wadding, gauze, bandages and surgical cotton wool. Raw materials can be sourced
      locally given Uganda’s comparative advantage in cotton production.
      
      There are also opportunities to manufacture medical supplies and instruments which have strong market
      .`,
      subtitle1: "Why Uganda ?",
      list: [
        "Uganda has a large domestic market with demand for edible oils expanding rapidly (Uganda’s imports of edible oils increased more than five-fold to over $ 30 million in 2017).",
        "Member of EAC, which had recorded ever imports of edible oils in 2017, with 50% growth reaching nearly $1 billion",
        "Uganda has a strong track in the production of agricultural products and is the most open trading country in the region",
        "35% of Uganda’s land is arable and suitable for growing edible oil seeds. such as sesame, soya bean, and sunflower"
      ],
      subtitle2: "Featured Success Stories",
      panels: [
        { 
            title: "1. CIPLA",
            content: "CIPLA India has established a WHO pre-qualified plant in Uganda producing ARVs, anti-malarials and anti-hepatitis medicines with purchase agreements with the Governments of Uganda and Zambia, In 2018, CIPLA Uganda raised US$ 43.8 million in a local IPO",
        },
        { 
            title: "2. ALFASAN", 
            content: "Alfasan of the Netherlands invested in Uganda via a joint venture with Farm Support, a Ugandan company, to produce vet and human drugs",
        },
      ]
    },
    {
      label: 'Mineral Value Addition',
      description1: `More than 80% of the country has been surveyed for mineral quantities and locations. New geo-data shows that Uganda has large underexploited mineral deposits of gold, oil, high grade tin, tungsten/wolfram, salt, beryllium, cobalt, kaolin, iron ore, glass sand, vermiculite, phosphates (agricultural fertiliser), uranium and rare earth elements.`,
      description2: "There are also significant quantities of clay and gypsum. Investment opportunities exist in mining and mineral processing with special incentives provided to the mining sector, including writing off capital expenditures in full",
      subtitle2: "Featured Investment Projects",
      image: mining1,
      panels: [
        { 
            title: "1. STEEL MANUFACTURING PLANT", 
            amount: "US$15 Million", 
            location: "KAMPALA", 
            content: "EAISC will be incorporated as a Ugandan company and operate throughout East Africa as a regional miner, producer and marketer of Iron and Steel Products. The business plan sets out the intention to develop a new full integrated Ugandan iron and steel company which will develop substantial mining and product engagements in key mining and distribution regions throughout East Africa. EAISC will seek to consolidate and optimize its capital structure and balance sheet (expected to consist entirely of equity capital). To that end therefore EAISC is looking for investment which will help it acquire further upstream machines and maintain appropriate levels of working capital for the full running and production of a fully integrated iron and steel company",
        }
      ]
    },
    {
      label: 'Infrastructure',
      description1: `Infrastructure is a key priority of the Ugandan Government. During the past few years, infrastructure development has contributed immensely to increased productivity by facilitating efficient connectivity and easing the movement of goods and the provision of services.`,
      description2: "Infrastructure funding currently comprises about 32.8% of the Government’s total annual expenditure.",
      subtitle2: "Featured Investment Projects",
      list: [ 
        "The road network includes the national road network, city road network, as well as district, urban and community access roads. In line with the Government’s target of starting commercial oil production by 2020, physical works on three oil roads equivalent to 363km out of 600km are under way. This will help the development of an oil and gas industrial park in western Uganda and the promotion of business start-ups", 
        "The Government of Uganda is committed to the development of the national railway system as the cheapest means of transport. The rail sub-sector is set to undergo major changes, including upgrading from the meter gauge rail (1 067mm) to the standard gauge (1 435mm).",
        "The air transport system is served by 47 aerodromes distributed evenly throughout the country. Of these, 14 are owned, operated and managed by Uganda Civil Aviation Authority (UCAA), including Entebbe International Airport which is the main entry and exit point for international traffic. The Government has designated five other airports as entry/exit (international) points to promote trade and tourism. These are Arua, Gulu, Pakuba, Kidepo and Kasese. The rest of the aerodromes are owned, operated and managed by the private sector and local authorities. The government has revived the National Airline, Uganda Airline to fully support the aviation industry. It is also developing Kabale International Airport in the Albertine region in the western Uganda to service the oil sector.",
        `Water transport is one of the quickest and cheapest means of transport in Uganda.  The country is endowed with 18 inland water ports spread across the different water bodies, most of which are on Lake Victoria, which are plied by more than 170 vessels carrying an estimated 6 to 8 million passengers a year.

         The Government is increasing investment in water transport infrastructure to enhance the movement of both passenger and cargo traffic. The underexploited infrastructure provides a viable investment opportunity, especially for the increasing tourism market and intra-regional trade activities
        `,
        `Energy Infrastructure
         The total electricity generation capacity is currently 1,200MW. A total of 17 hydro and solar power projects are under construction and an additional 800MW will be added to the national grid. The Government is adding 726.3km of electricity transmission lines to the network
        `,
        `ICT Infrastructure
         Uganda’s National Backbone Infrastructure, an initiative that aims to connect all major towns within the country to an optical fibre cable-based network and to connect Government entities to the e-Government Network, has been extended to districts countrywide
         The total span of fibre optic cables laid out across the country by both the government and private sector is 5,110km, providing seamless connections. Additionally, in order to provide faster internet speeds, 4G technologies have been rolled out with the objective of transforming access to public and private spectrums as well as promoting e-commerce to boost economic growth and efficiency`
      ],
      panels: [
        { 
            title: "1. PORT AT L.VICTORIA", 
            amount: "US$18 Million", 
            location: "L.VICTORIA REGION", 
            content: "Ports around the world are currently dredging their ship channels and investing heavily in the necessary infrastructure in order to meet current and future demand on navigation. The potential benefit is characterised not only by increasing port competitiveness.",
        },
        { 
            title: "2. KLA BUS RAPID TRANSIT", 
            amount: "US$612.06 Million", 
            location: "GREATER KAMPALA METROPOLITAN AREA", 
            content: "Bus rapid Transit (BRT) is a high quality mass public transport system based on buses using existing roads. BRT can be fast, reliable, frequent and comfortable; it has the characteristics of an urban railway service at a fraction of the cost.",
        },
        { 
          title: "3. KAMPALA CABLE CAR PROJECT", 
          amount: "US$200 Million", 
          location: "KAMPALA", 
          content: "Kampala Capital City Authority is proposing the introduction of an Urban Cable Car (Ropeway Vehicle) system. This technology is envisioned to achieve high order objectives such as; reduction in pollution (air and noise), traffic decongestion, improvement in transit connectivity, beautification of the urban environments, and development of a reliable and highly profitable transportation means.",
        }
      ]
    },
    {
      label: 'ICT',
      description1: `Uganda’s ICT sector is one of the most vibrant within the region and a fast-growing sector in the economy, offering many opportunities. Growth in this sector is supported by solid legal and regulatory frameworks.`,
      description2: "It is also connected to the three marine fiber optic cables off Africa’ east coast in the Indian Ocean. The newly developed and quantitative ICT infrastructure is ready to accommodate new investment. Uganda is positioning itself as a hub for business processing and management outsourcing in East Africa",
      description3: "The main opportunities for business process outsourcing and ICT services exist in agriculture, health, tourism, banks, insurance and public administration",
      subtitle2: "Featured Investment Projects",
      panels: [
        { 
            title: "1. IT/BPO PARK", 
            amount: "US$428,00,000", 
            location: "ENTEBBE", 
            content: "17 acres of land are available for the development of an Information Technology (IT) – Business Process Outsourcing (BPO) Park that will act as a catalyst from raw material based exports to a knowledge-based economy.",
        },
        { 
            title: "2. MICROPAY ", 
            amount: "US$4,000,000", 
            location: "KAMWOKYA, KAMPALA", 
            content: "MicroPay (U) Ltd is an e-payment platform independent of any telecommunications network with which clients may send/ receive money and carry out financial transactions such as buy airtime of all telecoms, or pay utility bills like power, water & pay TV using a smartphone app or unstructured supplementary service data for non-smart phone users.",
        },
        { 
          title: "3. SCIENCE,TECHNOLOGY & INNOVATION (STI) PARK", 
          amount: "US$59,000,000", 
          location: "20km From City Centre", 
          content: "The project aims to setup a place for interaction of innovators, the academia and international and local entrepreneurs, comprising of; Centers for science technology and innovation; Centers for academic excellence in STI; Centers for industrial commercial rollout of indigenous innovations; A business center; Residential neighborhoods. The project is estimated to cost US $ 1.5billion and shall be implemented in five( 5) phases in fifteen (15) years.",
        }
      ]
    },
    {
      label: 'Energy',
      description1: `Energy is the prime mover of any country’s economic growth and prosperity. Uganda needs adequate, reliable and affordable energy to enhance sustainable development. It is for this reason that energy is a priority sector for development in the Country.`,
      description2: "Uganda is well endowed with enormous natural resources such as Minerals, hydro, geothermal, petroleum resources, solar thermal yet only a small fraction of this potential has been harnessed",
      list: [
        "Power Generation Projects in the Medium Term Government is making effort to develop the following power generation projects through Public-Private Partnership (PPP).",
        "30MW cogeneration from bagasse by 2020.",
        "450MW geothermal power",
        "Oil & Gas",
        "The oil and gas sector has significant potential to contribute to inclusive growth in the country as it expected to bring in investments between US$15 to US$20 billion in the next three to five years. With the discovery of 6.5 billion barrels of oil, 1.2 billion barrels of it recoverable, it is anticipated to spur two major investments",
        "An oil refinery that will process 60,000 barrels per day and the 1,445km oil pipeline from Hoima in Uganda to Tanga port in Tanzania. Opportunities exist in community development, upgrading transport infrastructure and the development of a petroleum-based industrial park in Kabale Hoima. Uganda’s policy on local content will unlock many opportunities for investors and Ugandans",
        ""

      ],
      subtitle2: "Featured Investment Projects",
      panels: [
        { 
            title: "1. GEOTHERMAL EXPLORATION", 
            amount: "US$42 Million", 
            location: "BURANGA HOTSPRINGS, BUNDIBUGYO", 
            content: "GIDS Consult Limited is currently carrying out exploration and evaluation of geothermal energy with an aim of developing at least 100 MW Geothermal Power Plant at Buranga prospect. The company is planning to drill 3 deep exploration/ production wells at the prospect and develop a 30 MW well-head geothermal power plant units at each successful wells. The company shall develop a 100 MW geothermal power plant.",
        },
        { 
            title: "2. SOLAR CAR MANUFACTURING", 
            amount: "", 
            location: "KAKIRA TOWN COUNCIL", 
            content: "Kiira Motors Corporation (KMC) is supported through the Presidential Initiative for Science and Technology Innovation to commercialise the Kiira Electric Vehicle Project, a brainchild of Makerere University. KMC is aimed at setting up and operating a vehicle assembly plant in Uganda. The KMC Plant shall be designed with a Multiple Line and Multiple Model Assembly Shop for Pick- Up Trucks, Light and Medium Duty Trucks, SUVs and Buses. The assembly plant shall operate in partnership with seasoned global auto manufacturers",
        },
        { 
          title: "3. KABALE ENERGY PROJECT", 
          amount: "US$5 Million", 
          location: "KABALE", 
          content: "Kabale Energy project is in the advanced stage of implementing a “Peat” based power generating Project for 33 MW power station near Kabale town, South West of Uganda. The project is estimated to cost Usd 60 – 70 Million all inclusive. The power is to be supplied to the Government utility.",
        }
      ]
    },
    {
      label: 'Electronics',
      description1: `Uganda provides an unrivalled and largely untapped opportunity for electronics assembly operations. Uganda combines first-rate labour availability, the most competitive labour and overall operating costs in the EAC, an attractive and stable investment and working environment, an attractive incentives package and major market access`,
      description2: `Why Uganda ?`,
      list: [
        "Lowest labour and operating costs in EAC",
        "Highly trainable workforce, excellent recruitment potential and labour participation",
        "Good availability of management & technical employees in Uganda and EAC",
        "Generous incentives on tax, import tariffs and training costs",
        "Large and growing market opportunity",
        "Core member of free trade areas – EAC (175 million) and COMESA (529 million)",
        "Stable and investor-friendly political and economic environment",
        "Great location for key employees – excellent working and living environment",
        "Excellent potential for early-mover advantage"
      ],
      subtitle2: "Featured Successful Stories",
      panels: [
        { 
            title: "1. HISENSE", 
            amount: "", 
            location: "UGANDA", 
            content: "Chinese firm, Hisense, assembled its first TV in Uganda in 2012 and is building a new 2,000 employee plant in Uganda, to assemble TVs, DVD players, radios, speakers, LEDs, home theatres, and home appliances.",
        },
        { 
            title: "2. CTI", 
            amount: "", 
            location: "UGANDA", 
            content: "US firm CTI Africa is investing $10 million to start assembling smart phones in Uganda with deliveries in 2019.",
        },
      ]
    },
    {
      label: 'Edible Oils',
      description1: `Uganda combines a rapidly growing market of nearly 40 million people with access to the EAC market of 175 million people. With the lowest cost and fastest growing labour force in the region, suitable land availability, and an investor-friendly environment, Uganda is a good location for edible oils.`,
      description2: `Why Uganda ?`,
      list: [
        `Uganda has a large domestic market with demand for edible oils expanding rapidly (Uganda’s imports of edible oils increased more than five-fold to over $ 30 million in 2017`,
        `Member of EAC, which had recorded ever imports of edible oils in 2017, with 50% growth reaching nearly $1 billion`,
        `Uganda has a strong track in the production of agricultural products and is the most open trading country in the region`,
        `35% of Uganda’s land is arable and suitable for growing edible oil seeds. such as sesame, soya bean, and sunflower`,
        `Opportunities in premium oils include: Production of Premium Virgin oils for export is realisable in Uganda in the medium to longer term. The premium oils segment has higher margins and less aggressive. Some players like Mukwano are already exporting premium/pure sunflower oils from Uganda to Switzerland and Belgium and demand in these and Asian markets is expected to continue growing steadily as awareness and incomes`,
        `There are also opportunities for investment in the up-stream value chain in Uganda to expand raw material supplies {e.g. avocado production), for which there is strong demand`
      ],
      subtitle2: "Featured Successful Stories",
      panels: [
        { 
            title: "1.	Mukwano Enterprises", 
            amount: "", 
            location: "UGANDA", 
            content: "Established in Uganda in the 1980s and has grown to become a diversified enterprise with oil seed crop production {mainly sunflower and soya bean with over 100,000 farmers) and manufacturing of oil products, soap, detergents, plastics, and water.",
        },
        { 
            title: "2. BIDCO Uganda", 
            amount: "", 
            location: "UGANDA", 
            content: "Bidco Uganda was established as a Joint Venture investment with Wilmar International from Singapore {the leading agribusiness group in Asia). The company produces oils, fats, soaps, and other products in Uganda and is investing US$ 160 million in its Uganda refining capacity. The company currently has nearly 2,000 registered farmers, withUS$ 2.734 million in annual production.",
        },
      ]
    },
    {
      label: 'Agricultural Sector',
      description1: `The Agricultural Sector continues to be the most important sector in Uganda; it employs approximately 72% of the population and contributed about 32% to the GDP.  However, Productivity is limited by reliance on natural weather conditions and the still widespread use of traditional methods and equipment.`,
      description2: `Uganda is among the leading producers of coffee, bananas and oil seed crops (sesame, soybean, sunflower etc.). It is also a major producer of other crops like tea, cotton, organic cotton, tobacco, cereals, fresh foods and vegetables, nuts, essential oils, flowers, poultry and freshwater fish.`,
      description3: `The Ugandan Government is pushing for greater commercialisation of agriculture by encouraging the use of irrigation and mechanised farming. Opportunities for investment exist in:`,
      list: [
        `Commercial farming in both crops and animal industries as well as aquaculture`,
        `Value addition (Agro-industries, agro food industries)`,
        `Manufacturing of inputs such as improved seeds, fertilisers and pesticides`,
        `Cold storage facilities and logistics`,
        `Farm machinery manufacturing and assembly`,
        `Packaging`,
        `Irrigation schemes`
      ],
      subtitle2: "Featured Successful Stories",
      panels: [
        { 
            title: "1. MULTI FRUIT JUICE FACTORY", 
            amount: "US$3,400,000", 
            location: "KIBP, NAMANVE", 
            content: "Reco currently produces a variety of foods such as jams, chilli sauces, honey, herbal products etc. and is seeking to expand its product line through value addition of local fruits and vegetable.",
        },
        { 
          title: "2.	MODERN SLAUGHTERHOUSE", 
          amount: "US$916,667", 
          location: "KALERWE, KAMPALA", 
          content: "The project is to set up a modern slaughterhouse facility where farm animals are killed and processed into meat products.",
        },
        { 
          title: "3.	SPINNING MILL (14,000) SPINDLES", 
          amount: "US$23,500,000", 
          location: "LIRA", 
          content: "Lira Spinning Mill has a functioning ginnery that produces lint. The complex has buildings and structures that can be renovated to accommodate the proposed spinning mill and future expansion.",
        },
      ]
    }
];

export default function CategoryDetails() {
  const navigate = useNavigate()
  const location = useLocation()

  const [activeStep, setActiveStep] = React.useState(0);
  const [ displayFile, setDisplayFile ] = useState( false )
  const [ slugTag, setTag ] = useState( "" )
  const [ documentFile, setDisplayDocument ] = useState( null )

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleReset = () => {
    setActiveStep(0);
  }

  const handleDisplayFile = ( tag, document ) => {
    setTag( tag )
    setDisplayFile( true )
    setDisplayDocument( document )
  }


  return (
    <Wrap navigate={navigate} sidebar={false}>
        <div className="p-5 w-full">
          {location.state?.page === "sector" && 
          ( <Box className="w-full">
            <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  optional={
                    index === 2 ? (
                        <Typography variant="caption">Last step</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  <div className="h-auto p-3" style={{ fontFamily: "LatoRegular" }}>
                    <Typography className="py-2" style={{ fontFamily: "LatoRegular" }}>{step.description1}</Typography>
                    <Typography className="py-2" style={{ fontFamily: "LatoRegular" }}>{step.description2}</Typography>
                    <Typography className="py-2" style={{ fontFamily: "LatoRegular" }}>{step.description3}</Typography>
                    {step?.image && (
                        <img src={step?.image} className="w-auto" />
                    ) }
                    <h2 className="text-base font-bold my-3">{step.subtitle1}</h2>
                    {step?.list?.map( ( listItem ) => {
                        if( listItem ) {
                            return (
                                <li className="text-sm py-1">{listItem}</li>
                            )
                        }
                        
                    } )}
                    {step?.panels?.map( ( item ) => {
                        if( item ) {
                            return (
                                <Panel label={item.title}>
                                    {item?.amount && <h2 className="text-sm py-1">{`Amount ${item?.amount}`}</h2>}
                                    {item?.location && <h2 className="text-sm py-1">{`Location ${item?.location}`}</h2>}
                                    <p className="text-sm py-1">{item.content}</p>
                                </Panel>
                            )
                        }
                    } )}
                  </div>
                  
                  
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>

                </StepContent>
              </Step>
            ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>All steps completed - you&apos;re finished</Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  {`Reset`}
                </Button>
                </Paper>
            )}
          </Box> ) }

          {location.state?.page === "region" && (
            <>
              <div className="text-red-500 text-base cursor-pointer py-2 border-b border-gray-300" onClick={() => navigate( -1 )}>{`Back`}</div>
              <h2 className="uppercase text-xl text-start text-black">{`Regions`}</h2>
              <div>
                {[ 
                  { id: 1, title: "Buganda Investment Profile", tag: "Buganda", document: Buganda1  },
                  { id: 2, title: "Bukedi Investment Profile", tag: "Bukedi", document: Bukedi1  },
                  { id: 3, title: "Karamoja Investment Profile", tag: "Karamoja", document: Karamoja1  },
                  { id: 4, title: "Teso Investment Profile", tag: "Teso", document: Teso  },
                ].map( ( item, idx ) => {
                  return (
                  <>
                    <div key={idx+1} className="bg-green-100 px-2 py-1 my-1" onClick={() => handleDisplayFile( item.tag, item?.document )}>
                      {item?.title}
                    </div>
                    <div className="h-auto">
                      <a className="text-center text-xs p-2 text-blue-700" href={item.document} target="_blank">{`Open the ${item.title}`}</a>
                    </div>
                    </>
                  )
                } )}
              </div>
            </>
          )}

          {location.state?.page === "district" && (
            <>
              <div className="text-red-500 text-base cursor-pointer py-2 border-b border-gray-300" onClick={() => navigate( -1 )}>{`Back`}</div>
              <h2 className="uppercase text-xl text-start text-black">{`Districts`}</h2>
              <div>
                {[ 
                  { id: 1, title: "Arua", tag: "Arua", document: Arua  },
                  { id: 2, title: "Hoima", tag: "Hoima", document: Hoima  },
                  { id: 3, title: "Isingiro", tag: "Isingiro", document: Isingiro  },
                  { id: 4, title: "Lamwo", tag: "Lamwo", document: Lamwo  },
                  { id: 5, title: "Moyo", tag: "Moyo", document: Moyo  },
                  { id: 6, title: "Obongi", tag: "Obongi", document: Obongi  },
                  { id: 7, title: "Yumbe", tag: "Yumbe", document: Yumbe  }
                ].map( ( item, idx ) => {
                  return (
                  <>
                    <div key={idx+1} className="bg-green-100 px-2 py-1 my-1" onClick={() => handleDisplayFile( item.tag, item?.document )}>
                      {item?.title}
                    </div>
                    <div className="h-auto">
                      <a className="text-center text-xs p-2 text-blue-700" href={item.document} target="_blank">{`Open the ${item.title} Investment Profile`}</a>
                    </div>
                    </>
                  )
                } )}
              </div>
            </>
          )}
        </div>
    </Wrap>
  )
}

// const DisplayPDF = ( { file } ) => {
//   return (
//     <Document file={file}>
//       <Page pageNumber={1} />
//     </Document>
//   )
// }
