import React from 'react'
import { Panel, Wrap, TextFieldIcon, TextAreaIcon } from '../../_components'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { CircularProgress } from '@material-ui/core'

export default function Contact() {
  const navigate = useNavigate()

  const handleSubmit = async ( values ) => {
    try {
         
    } catch (error) {
        
    }
  }

  return (
    <Wrap navigate={navigate} sidebar={false}>
        <div className="p-5 w-full">
            <Formik 
                initialValues={{
                    name: "",
                    email: "",
                    message: ""
                }}
                onSubmit={handleSubmit}>
                    { ( { values, handleSubmit, handleChange, handleBlur, isSubmitting } ) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Panel label="Contact Form">
                                    <div className="grid grid-cols-1 gap-2">
                                        <TextFieldIcon 
                                            name="name"
                                            label="Name"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            value={values.name}
                                            question={`Your Name`}
                                            required={true}
                                        />
                                        <TextFieldIcon 
                                            name="email"
                                            label="Email Address"
                                            type="email"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            value={values.email}
                                            question={`Your Email Address`}
                                            required={true}      />
                                        <TextAreaIcon 
                                            rows={5}
                                            cols={3}
                                            label="Message"
                                            name="message"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            value={values.message}
                                            question={`Your Message`}
                                            required={true}
                                        />
                                    </div>

                                    <div className="w-full flex items-center justify-start py-5 px-5">
                                        {isSubmitting ? (
                                        <div className="flex items-center justify-center">
                                            <CircularProgress size={24} color="primary" />
                                        </div>
                                        ) : ( <button disabled={isSubmitting} className="w-full  md:w-auto lg:w-auto bg-[#EE1C26] rounded-xl text-white px-5 py-2 text-base focus:outline-none focus:border-none" type="submit">
                                            {`Submit`}
                                        </button> ) }
                                    </div>
                                </Panel>

                                
                            </form>
                        )
                    } }

            </Formik>
        </div>
    </Wrap>
  )
}
