import React, { useState, useEffect } from 'react'
import { Stepper, Step, StepLabel, CircularProgress } from '@material-ui/core'
import { Wrap, Panel } from '../../../_components'
import axios from 'axios'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { setDisplayModal, setGetCounties, setGetSubcounties, setNoReset, setDeferredShareholders, saveShareholders, setSaveDraft } from '../../../_slices/appSlice'
import data from '../../fixtures'
import { useNavigate, useLocation } from 'react-router-dom'
import { Guidelines, SectionA, SectionB, SectionC, Attachments , SectionD, Payment } from './forms'
import FormEntry from './FormEntry'
import { fetchShareholders, API_BASE, sanitizeValue } from '../../../_utils'
import { SelectFieldIcon } from '../../../_components'
import { act } from 'react'


let applicationData =  localStorage.getItem( "applicationData" )
let flag  = localStorage.getItem( "flag" )

// steps within the application process
const steps = [
  "Introduction",
  "Guidelines",
  "Section A",
  "Section B",
  "Section C",
  "Section D(Payment)"
]

// Main React functional component named Application responsible for 
// rendering the application form and managing its state. 
export default function Application () {
  // creating functions using different hooks ie useDispatch, useNavigate, useLocation
  const dispatch = useDispatch() //dispatches actions to the Redux store
  const navigate = useNavigate() //hook navigates to different routes within the app
  const location = useLocation() //provides info about the current URL's path, hash and state

  const state = useSelector( state => { //this hook extracts particular pieces from the Redux store
    return {
      flag: state.application.displayModal?.flag,
      title: state.application.displayModal?.title,
      tag: state.application.displayModal?.tag,
      operation: state.application.displayModal?.operation,
      shareholders: state.application.shareholders,
      finance: state.application.financing,
      funding: state.application.funding,
      employment: state.application.employment,
      markets: state.application.markets,
      inputs: state.application.inputs,
      files: state.application.files,
      edit: state.application.edit,
      sectorTitle: state.application.sectorTitle,
      divisionTitle: state.application.divisionTitle,
      groupTitle: state.application.groupTitle,
      classTitle: state.application.classTitle,
      groups: state.application.groups,
      deferred_holders: state.application.deferred_holders,
      savedDraft: state.application.savedDraft,
      education: state.application.education,
      engineering: state.application.engineering,
      practicals: state.application.practicals,
      positions: state.application.positions,
      membership: state.application.membership,
      sponsors: state.application.sponsors
    }
  } )

  // These state variables are initialized with default values or null values using the useState hook and
  // then updated as needed throughout the component's lifecycle.
  const [ loading, setLoading ] = React.useState( true )
  const [ organisation, setOrganisation ] = useState( null )

  const [ activeStep, setActiveStep ] = useState( 0 )
  const isLastStep = activeStep === ( steps.length - 1 )

  const [ proceed, setProceed ] = useState( true )
  const [ stockers, setFetchedStockers ] = useState( [] )
  const [ stockLoading, setStockersLoading ] = useState( true )
  const [ verifiedList, setVerifiedCompanies ] = useState( [] )
  const [ errorTIN, setTINError ] = useState( null )
  const [ selectedBRN, setSelectedBRN ] = useState( null )

  const [ sector, setSector ] = useState( {} )
  const [ result, setResult ] = useState( null )
  const [ remarks, setRemarks ] = useState( [] )
  const [ isUploading, setUploading ] = useState( false )
  const [ uploadingDraft, setUploadingDraft ] = useState( false )
  const [ admin, setAdmin ] = useState( "" )
  const [ userInfo, setUser ] = useState( {} )
  const [ flag, setFlag ] = useState( false )
  const [ formType, setType ] = useState( "" )

  useEffect( () => {
    if( loading ) {
      setAdminRights()
    }
  },[ loading ])

  useEffect( () => {
    if( formType?.length > 0  ){
      setFlag( true )
    }
  }, [ formType ] )

  const setAdminRights = async () => {
    let role = await localStorage.getItem( "role" )
    let userInformation = JSON.parse( localStorage.getItem( "user" ))
    setAdmin( role )
    setUser( userInformation )
    setLoading( false )
  }

  const handleSubmit = async ( values ) => {
    let user = JSON.parse( localStorage.getItem( "user" ))

    if( localStorage.getItem( "registration_type" ) === "Yes" ) {
      let params = { 
        ...values, 
        stage: 1, 
        progress: 100, 
        applicant_id: user?.id,
        education: [],
        engineering: [],
        practicals: [],
        positions: [],
        membership: [],
        sponsors: [],
        payment_mode: "MOBILE",
        payment_phone_no: "0773234110",
        payment_source_system: "FLEXIPAY",
        application_type: "RENEW",
        status: "APPROVED"
      }

         try {
          const response = await axios( {
            method: "post",
            url: `${API_BASE}/erb_storeLicence`, 
            data: params,
            headers: {
              "Authorization": `Bearer ${localStorage.getItem( "token" )}`
            }
          } )
  
          if( response.data ){
            alert( "Your Licence has been renewed successfully." )
            navigate( "/my-applications" )
            setUploading( false )
          }
        } catch ( error ) {
          setUploading( false )
          if( Object.keys( error ).includes( "response" ) ) {
            const { data, status } =  error.response
            if( status === 422 ) {
              alert( `Application could not be submitted. ${Object.values( data )}`  )
            }
            else { alert( data?.message || "Application could not be Submitted."  ) }
          }
          else { alert( "Something went wrong. Application could not be Submitted." ) }
        }      
    }
    else {
      if( isLastStep ) {
        setUploading( true )
        await localStorage.setItem( "previous", false )
        
  
        let payload = { 
          ...values, 
          stage: 1, 
          progress: 10, 
          applicant_id: user?.id,
          education: state?.education,
          engineering: state?.engineering,
          practicals: state?.practicals,
          positions: state?.positions,
          membership: state?.membership,
          sponsors: state?.sponsors,
          payment_mode: "MOBILE",
          payment_phone_no: "0773234110",
          payment_amount: 5000,
          payment_source_system: "FLEXIPAY",
          application_type: "NEW"
        }
  
        try {
          const response = await axios( {
            method: "post",
            url: `${API_BASE}/erb_storeLicence`, 
            data: payload,
            headers: {
              "Authorization": `Bearer ${localStorage.getItem( "token" )}`
            }
          } )
  
          if( response.data ){
            alert( response.data?.message || `Your Application has been successfully submitted.` )
            navigate( "/my-applications" )
            setUploading( false )
          }
        } catch ( error ) {
          setUploading( false )
          if( Object.keys( error ).includes( "response" ) ) {
            const { data, status } =  error.response
            if( status === 422 ) {
              alert( `Application could not be submitted. ${Object.values( data )}`  )
            }
            else { alert( data?.message || "Application could not be Submitted."  ) }
          }
          else { alert( "Something went wrong. Application could not be Submitted." ) }
        }
       }
       else {
        //shareholders
        setActiveStep( activeStep + 1 )
       }
    }
  }

  const handleTIN = ( tin, generatedTIN ) => {
    // if( !( generatedTIN === tin ) ){
    //   setProceed( false )
    //   alert( "TIN doesnt match" )
    // }
    // else {
    //   setProceed( true  )
    // }
  }

  const handleErrorTIN = response => {
    setTINError( response )
  }

  const handleSelectedBRN = brn => {
    setSelectedBRN( brn )
  }

  const displayTitle = ( title ) => {
    switch( title ){
      case "ERA1":
        return "ERA1 - UIPE Corporate Members under JAC and East African Direct Applicants."
      case "ERA2":
        return "ERA2 - For Non East Africans Engineers applying for Temporary Registration"
      case "ERA3":
        return "ERA3 - For East African Techinicians and Technologists applying for Registration"
      default:
        return ""
    }
    
  }


  if( loading ) {
    return (
      <Wrap navigate={navigate} noScroll={true}>
        <div className="flex items-center justify-center h-full w-full py-2">
          <div className="mx-2 text-xs md:text-base lg:text-base">
            {`Loading Depedencies...`}
          </div>
          <CircularProgress size={24} color={`blue`} />
        </div>
      </Wrap>
    )
  }


  return (
    <>
      {localStorage.getItem( "registration_type" ) === "No" && <Wrap navigate={navigate} noScroll={false}>
        <div className="relative bg-white grid gap-3 px-2 py-5 w-full h-auto">
          <div className="rounded-sm grid">
              <div className="bg-gray-100">
              <div className="flex flex-col items-center justify-center py-2">
                <h5 className="font-bold uppercase">
                  {`Welcome to the Engineering Registration Board Application Form(s)`}
                </h5>
              </div>

              <div className="w-full">
              { activeStep === 0 ? 
                ( <ul className="p-2">
                  <li className="py-1">
                    <p className="text-sm">
                      {`1.0. Subject to provision of the Engineers Registration Act (ERA), an engineer is entitled, on making a successful
                        application to the Board in the prescribed manner and on payment of the prescribed fee, to have his/her name
                        entered into the register.`}
                    </p>
                  </li>

                  <li className="py-1">
                    <p className="text-sm">
                      {`2.0. An applicant for registration must have the following qualifications:`}
                    </p>
                  </li>

                  <li className="py-1">
                    <p className="text-sm">
                      {`2.1. A Degree, Diploma or Licence of a University or School of Engineering reviewed and assessed by
                        Board and then accredited by NCHE, or any other awards recognized by the Board as furnishing a
                        sufficient guarantee of an adequate academic training in engineering are eligible for registration`}
                    </p>
                  </li>

                  <li className="py-1">
                    <p className="text-sm">
                      {`2.2. At least 2 years training as a pupil engineer after graduation (i.e., pupillage), under the supervision of a Registered Engineer`}
                    </p>
                  </li>

                  <li className="py-1">
                    <p className="text-sm">
                      {`2.3. A further 2 years working experience in a position or positions of some responsibility (i.e., post pupillage), again under the supervision of a Registered Engineer`}
                    </p>
                  </li>

                  <li className="py-1">
                    <p className="text-sm">
                      {`2.4. Corporate Membership of the Uganda Institution of Professional Engineers (UIPE);`}
                    </p>
                  </li>

                  <li className="py-1">
                    <p className="text-sm">
                      {`2.5. The applicant must have attained the age of 25 years`}
                    </p>
                  </li>

                  <li className="py-1">
                    <p className="text-sm">
                      {`2.6. Applicants who possess demonstrable prior engineering practice of at least 4 years after acquiring an
                        Ordinary, or a Higher Diploma in an Engineering field or other technical qualification from a
                        recognized institution of higher learning, upon upgrading to a recognized Bachelor’s degree in
                        engineering are eligible to apply for registration at post pupillage level, (i.e., the first 2 years of pupillage
                        are waived in place of the 4 years demonstrable engineering practice).`}
                    </p>
                  </li>
                </ul> ) : (
                  <Stepper activeStep={activeStep}>
                  {steps.map( label => {
                      return (
                        <Step key={label}>
                          <StepLabel className="font-bold">
                            {label}
                          </StepLabel>
                        </Step>
                      )
                  } )}
                </Stepper>
                )}
              </div>

              <Formik initialValues={{
                type: "",
                date: moment( new Date() ).format( "DD-MMM-YYYY hh:MM A" ),
                name: userInfo?.first_name + " " + userInfo?.surname || "",
                address: userInfo?.address || "",
                email_address: userInfo?.email,
                telephone: userInfo?.telephone,
                birth_date: userInfo?.dob || "",
                birth_place: userInfo?.birth_place || "",
              }} onSubmit={handleSubmit}>
                { ( { handleSubmit, handleChange, handleBlur, values } ) => {
                  return (
                    <form onSubmit={handleSubmit}>
                       <h1 className="text-center text-lg">
                        {displayTitle( values?.type )}
                       </h1>
                       {activeStep === 0 && ( <div className="flex flex-col px-[50px] py-3" style={{ backgroundColor: "#FFF" }}>
                        <label className="p-2 uppercase font-bold">{`Choose Type of Application:`}</label>
                        <select name="type" className="rounded p-2 my-1 border-2 border-gray-500" value={values.type} required onChange={ evt => handleChange( evt )}>
                          <option selected>Choose</option>
                          <option value="ERA1">{`(ERA1) - UIPE Corporate Members under JAC and East African Direct Applicants.`}</option>
                          <option value="ERA2">{`(ERA2) -For Non East Africans Engineers applying for Temporary Registration.`}</option>
                          <option value="ERA3">{`(ERA3) - For East African Techinicians and Technologists applying for Registration.`}</option>
                        </select>
                      </div> ) }

                        {activeStep === 1 && (
                           <Guidelines  values={values} />
                        )}

                        {activeStep === 2 && (
                            <SectionA 
                              formType={formType}
                              userInfo={userInfo}
                              type={location.state?.type}  
                              deferData={location?.state?.result}
                              stockers={stockers} 
                              handleBlur={handleBlur} 
                              handleChange={handleChange} 
                              values={values} setAlertTIN={( result ) => handleTIN( result, values.tin )}
                              loading={stockLoading} list={verifiedList} handleErrorTIN={handleErrorTIN}
                              handleSelectedBRN={handleSelectedBRN}
                            />
                          )}

                          {activeStep === 3 && (
                            <SectionB 
                              type={location.state?.type} 
                              deferData={location?.state?.result}
                              result={result} 
                              handleBlur={handleBlur} 
                              handleChange={handleChange}
                              values={values}
                              />
                          )}

                          {activeStep === 4 && (
                            <SectionC
                              userInfo={userInfo}
                              type={location.state?.type} 
                              deferData={location?.state?.result}
                              result={result} 
                              handleBlur={handleBlur} 
                              handleChange={handleChange}
                              values={values}
                              />
                          )}


                          {activeStep === 5 && (
                            <Payment
                              type={location.state?.type} 
                              deferData={location?.state?.result}
                              result={result} 
                              handleBlur={handleBlur} 
                              handleChange={handleChange}
                              values={values}
                            />
                          )}

                      <div className="bg-white flex flex-row items-center justify-between">
                          {activeStep > 0 && (!isUploading && 
                          ( <button disabled={uploadingDraft || isUploading} type="button" onClick={async () => {
                            setActiveStep( activeStep - 1 )
                            await localStorage.setItem( "previous", true )
                          }} className="text-base bg-blue-500 text-white px-2 py-1 rounded mx-2">
                            {`Previous`}
                          </button> ) )}

                          <div className={`${ activeStep === 0 ? "flex flex-row justify-between items-center" : "" }`}>
                            <div />
                            {(isUploading ) ? (
                              <span className="font-bold text-red-500">{`Please wait while we Submitting your License Application...`}<CircularProgress size={24} color={`blue`} /></span>
                            ) : ( 
                            <button type="submit" disabled={isUploading || uploadingDraft} className={`rounded text-base bg-green-800 text-white px-3 py-2 mx-2`}>
                              <span>{ isLastStep ? ( location?.state?.type === "defer" ? `Update Application`: `Submit Application` ) : `Proceed`}</span>
                            </button> )}
                            
                            {( uploadingDraft && (
                              <span className="font-bold text-red-500">
                                {`Saving...`}
                                <CircularProgress size={24} color={`blue`} />
                              </span>
                            ))}
                          </div>
                      </div>
                    </form>
                  )
                }}
              </Formik>
              </div>
          </div>
        </div>
      </Wrap>}

      {localStorage.getItem( "registration_type" ) === "Yes" && <Wrap navigate={navigate} noScroll={false}>
        <div className="relative bg-white grid gap-3 px-2 py-5 w-full h-auto">
          <div className="rounded-sm grid">
              <div className="bg-gray-100">
              <div className="flex flex-col items-center justify-center py-2">
                <h5 className="font-bold uppercase">
                  {`Welcome to the Engineering Registration Board Application Form(s)`}
                </h5>
              </div>

              <div className="w-full">
              { activeStep === 0 ? 
                ( <ul className="p-2">
                  <li className="py-1">
                    <p className="text-sm">
                      {`1.0. Renewal Application`}
                    </p>
                  </li>
                </ul> ) : (
                  <Stepper activeStep={activeStep}>
                  {[ "Renewal" ].map( label => {
                      return (
                        <Step key={label}>
                          <StepLabel className="font-bold">{label}</StepLabel>
                        </Step>
                      )
                  } )}
                </Stepper>
                )}
              </div>

              <Formik initialValues={{
                type: "",
                date: moment( new Date() ).format( "DD-MMM-YYYY hh:MM A" ),
                name: userInfo?.first_name + " " + userInfo?.surname || "",
                address: userInfo?.address || "",
                email_address: userInfo?.email,
                telephone: userInfo?.telephone,
                birth_date: userInfo?.dob || "",
                birth_place: userInfo?.birth_place || "",
                discipline: userInfo?.category,
                country: userInfo?.country,
                registered: userInfo?.registered,
                licence_no: userInfo?.licence_no,
              }} onSubmit={handleSubmit}>
                { ( { handleSubmit, handleChange, handleBlur, values } ) => {
                  return (
                    <form onSubmit={handleSubmit}>
                       <h1 className="text-center text-lg">
                        {"Renew your License"}
                       </h1>
                       {/* {activeStep === 0 && ( <div className="flex flex-col px-[50px] py-3" style={{ backgroundColor: "#FFF" }}>
                        <label className="p-2 uppercase font-bold">{`Choose Type of Application:`}</label>
                        <select name="type" className="rounded p-2 my-1 border-2 border-gray-500" value={values.type} required onChange={ evt => handleChange( evt )}>
                          <option selected>Choose</option>
                          <option value="ERA1">{`(ERA1) - UIPE Corporate Members under JAC and East African Direct Applicants.`}</option>
                          <option value="ERA2">{`(ERA2) -For Non East Africans Engineers applying for Temporary Registration.`}</option>
                          <option value="ERA3">{`(ERA3) - For East African Techinicians and Technologists applying for Registration.`}</option>
                        </select>
                      </div> ) } */}

                        <SectionA 
                          formType={formType}
                          userInfo={userInfo}
                          type={location.state?.type}  
                          deferData={location?.state?.result}
                          stockers={stockers} 
                          handleBlur={handleBlur} 
                          handleChange={handleChange} 
                          values={values} setAlertTIN={( result ) => handleTIN( result, values.tin )}
                          loading={stockLoading} list={verifiedList} handleErrorTIN={handleErrorTIN}
                          handleSelectedBRN={handleSelectedBRN}
                        />

                      <div className="bg-white flex flex-row items-center justify-between">
                          {activeStep > 0 && (!isUploading && 
                          ( <button disabled={uploadingDraft || isUploading} type="button" onClick={async () => {
                            setActiveStep( activeStep - 1 )
                            await localStorage.setItem( "previous", true )
                          }} className="text-base bg-blue-500 text-white px-2 py-1 rounded mx-2">
                            {`Previous`}
                          </button> ) )}

                          <div>
                            {(isUploading ) ? (
                              <span className="font-bold text-red-500">{`Please wait while we Submitting your License Application...`}<CircularProgress size={24} color={`blue`} /></span>
                            ) : ( 
                            <button type="submit" disabled={isUploading || uploadingDraft} className={`rounded text-base bg-green-800 text-white px-3 py-2 mx-2`}>
                              <span>{`Submit Your Renewal Application`}</span>
                            </button> )}
                          </div>
                      </div>
                    </form>
                  )
                }}
              </Formik>
              </div>
          </div>
        </div>
      </Wrap>}

      <FormEntry userInfo={userInfo} actionType={ location?.state?.type === "defer" ? "defer" : "create" }/>
    </>
  )
}



