// import React from 'react'

// export default function Modal( { children, visible, onClose } ) {
//     if( !visible ) {
//         return null
//     }

//     const handleonBackDropClick = e => {
//         if( e.target.id === "backdrop" ) onClose && onClose()
//     }

//     return (
//         <div id="backdrop" className="bg-black bg-opacity-50 backdrop-blur-sm fixed inset-0 flex items-center justify-center" onClick={handleonBackDropClick}>
//             {children}
//         </div>
//     )
// }
export default function( { children } ) {
    return (
        <div className="relative z-50 w-4/5">
            <div className="fixed inset-0 bg-black/10" aria-hidden="true"/>

            <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className="flex min-h-full items-center justify-center">
                    {children}
                </div>
            </div>
        </div>
    );
}