import React from 'react'
import { classNames } from '../_utils'

import { at } from 'lodash'
import { useField } from 'formik'

export default function TextAreaIcon ( { cols = 5 , rows = 2, required = false, disabled =  false, question = "", name, iconComponent, type =  "text", label, handleChange, handleBlur, value } ) {
  
  const [ field, meta ] = useField( name )

  const _renderText = () => {
    const [ touched, error ] = at( meta, 'touched', 'error' ) 
    if( touched && error )
      return error
    
  }

  return (
    <div className="flex flex-col items-start my-2">
      <div className="text-black text-xs md:text-base lg:text-base py-2">
        {question}{ required && ( <span className="text-red-500">*</span>) }
      </div>
      <div className={ classNames( ( meta.touched && meta.error ) ? "inline-flex w-full rounded-sm border border-red-500" : "inline-flex w-full rounded-sm border border-gray-500" ) }>
          {/* <div className="w-1/6 flex items-center justify-center bg-gray-50">
            <span>{iconComponent}</span>
          </div> */}
          <textarea  
            name={name}
            disabled={disabled}
            id={name}
            type={type} 
            placeholder={label}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
            autocomplete="off"
            rows={rows}
            cols={cols}
            className={ classNames( ( meta.touched && meta.error ) ? "w-full px-3 py-1 focus:outline-none focus:text-gray-700 placeholder-red-700 text-xs" : "w-full px-3 py-1 focus:outline-none focus:text-gray-700 placeholder-gray-400 text-base" ) }>
          </textarea>
      </div>
    
    {(meta.touched && meta.error) ? (
      <p className="text-red-500 py-1 text-xs">
        {_renderText()}
    </p>
    ) : (
      <p className="text-blue-900 py-1" style={{ fontSize: "0.7rem" }}>
        {/* {question}{ required && ( <span className="text-red-500">*</span>) } */}
      </p>
    ) }

  </div>
  )
}
