import React, { useState } from 'react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { FaPhoneAlt, FaUserAlt } from 'react-icons/fa'
import { ModalComponent, TextFieldIcon, SelectFieldIcon, PhoneFieldIcon } from '../../../_components'
import { setDisplayModal, saveShareholders, setSaveFinance, setSaveFunding,
    setSaveEmployment, setSponsorList, setPositionList, setMembershipList, setEducationList, setEngineeringList, setPracticalList, setSaveMarkets, setSaveInputs, setSaveFiles, setDeferredShareholders, 
    saveEducation} from '../../../_slices/appSlice'
import data from '../../fixtures'
import { convertFileToBase } from '../../../_utils'

export default function FormEntry( { userInfo } ) {
    const dispatch = useDispatch()
    const state = useSelector( state => {
        return {
          flag: state.application.displayModal?.flag,
          title: state.application.displayModal?.title,
          tag: state.application.displayModal?.tag,  
          operation: state.application.displayModal?.operation,
          shareholders: state.application.shareholders,
          finance: state.application.financing,
          funding: state.application.funding,
          employment: state.application.employment,
          markets: state.application.markets,
          inputs: state.application.inputs,
          files: state.application.files,
          edit: state.application.edit,
          deferred_holders: state.application.deferred_holders,
          education: state?.application?.education,
          engineering: state?.application?.engineering,
          practicals:  state?.application?.practicals,
          positions:  state?.application?.positions,
          membership:  state?.application?.membership,
          sponsors:  state?.application?.sponsors
        }
    } )

    const [ fileItem, setFile ] = useState( null )

    const formatValue = value => {
      if( value > 0 ) {
        return value
      }
      if( value < 0 ) {
        alert( "You may not add negative values" )
        return 0
      }
      return 0
    }

    const handleSubmitForm = async ( values ) => {
        if( state.tag?.toLowerCase() === "education" ) {
          if( values?.start_date?.length  === 0 ) {
            return alert( "Please enter a Start Date" )
          }
          if( values?.end_date?.length  === 0 ) {
            return alert( "Please enter a End Date" )
          }

          await dispatch( setEducationList( [ ...state.education, {
            start_date: values?.start_date,
            end_date: values?.end_date,
            qualification: values?.qualification,
            institution: values?.institution,
            attach_file: fileItem
          } ] ) )

          // if( actionType === "defer" ) {
          //   await dispatch( setDeferredShareholders( { shareholders: [ ...state.deferred_holders, values ] }  ) )
          // }
          // else {
          //   await dispatch( saveShareholders( { shareholders: [ ...state.shareholders, values ] }  ) )
          // }
        }


        if( state.tag?.toLowerCase() === "engineering" ) {
          if( values?.start_date?.length  === 0 ) {
            return alert( "Please enter a Start Date" )
          }
          if( values?.end_date?.length  === 0 ) {
            return alert( "Please enter a End Date" )
          }

          await dispatch( setEngineeringList( [ ...state.engineering, {
            start_date: values?.start_date,
            end_date: values?.end_date,
            institution: values?.institution,
            attach_file: fileItem
          } ] ) )
        }

        if( state.tag?.toLowerCase() === "practicals" ) {
          if( values?.start_date?.length  === 0 ) {
            return alert( "Please enter a Start Date" )
          }
          if( values?.end_date?.length  === 0 ) {
            return alert( "Please enter a End Date" )
          }

          await dispatch( setPracticalList( [ ...state.practicals, {
            start_date: values?.start_date,
            end_date: values?.end_date,
            organisation: values?.organisation,
            cadre: values?.cadre
          } ] ) )
        }

        if( state.tag?.toLowerCase() === "positions" ) {
          if( values?.start_date?.length  === 0 ) {
            return alert( "Please enter a Start Date" )
          }
          if( values?.end_date?.length  === 0 ) {
            return alert( "Please enter a End Date" )
          }

          await dispatch( setPositionList( [ ...state.positions, {
            start_date: values?.start_date,
            end_date: values?.end_date,
            organisation: values?.organisation,
            cadre: values?.cadre
          } ] ) )
        }

        if( state.tag?.toLowerCase() === "membership" ) {
          if( values?.membership_name?.length  === 0 ) {
            return alert( "Please enter a Membership Name" )
          }

          await dispatch( setMembershipList( [ ...state.membership, {
            membership_name: values?.membership_name,
            attach_file: fileItem
          } ] ) )
        }


        if( state.tag?.toLowerCase() === "sponsors" ) {
          await dispatch( setSponsorList( [ ...state.sponsors, {
            sponsor_name: values?.sponsor_name,
            user_id: userInfo?.id,
            registered: values?.registered,
            registration_number: values?.registered === "Yes" ? values?.registration_number : "NA",
            discipline: values?.discipline
          } ] ) )
        }
    
        // if( state.tag?.toLowerCase() === "finance" ) {
        //   if( state.operation === "edit" ) {
        //     //find the index
        //     let list1 = state.finance.filter( ( item ) => {
        //       return item.slug?.toLowerCase() !== state.title?.toLowerCase()
        //     } )

        //     await dispatch( setSaveFinance( { finance: [ ...list1, { 
        //       firstYear: formatValue( values.firstYear ),
        //       secondYear: formatValue( values.secondYear ),
        //       thirdYear: formatValue( values.thirdYear ),
        //       total: parseFloat( formatValue( values.firstYear ) ) + parseFloat( formatValue( values.secondYear ) ) + parseFloat( formatValue( values.thirdYear ) ),
        //       slug: state.title,
        //     } ]} ) )
            
        //   }
        //   else {
        //     await dispatch( setSaveFinance( { finance: [ ...state.finance, { 
        //       firstYear: formatValue( values.firstYear ),
        //       secondYear: formatValue( values.secondYear ),
        //       thirdYear: formatValue( values.thirdYear ),
        //       total: parseFloat( formatValue( values.firstYear ) ) + parseFloat( formatValue( values.secondYear ) ) + parseFloat( formatValue( values.thirdYear ) ),
        //       slug: state.title,
        //     } ]} ) )
        //   }
          
        // }
    
        // if( state.tag?.toLowerCase() === "funding" ) {
        //   if( state.operation === "edit" ) {
        //     //find the index
        //     let list2 = state.funding.filter( ( item ) => {
        //       return item.slug?.toLowerCase() !== state.title?.toLowerCase()
        //     } )

        //     await dispatch( setSaveFunding( { funding: [ ...list2, { 
        //       foreign: formatValue( values.foreign ),
        //       local: values.local,
        //       total2: parseFloat( values.foreign ) + parseFloat( values.local ),
        //       slug: state.title,
        //     } ]} ) )
            
        //   } 
        //   else {
        //     await dispatch( setSaveFunding( { funding: [ ...state.funding, { 
        //       foreign: formatValue( values.foreign ),
        //       local: values.local,
        //       total2: parseFloat( values.foreign ) + parseFloat( values.local ),
        //       slug: state.title,
        //     } ]} ) )
        //   }
        // }
    
        // if( state.tag?.toLowerCase() === "employment" ) {
        //   if( state.operation === "edit" ) {
        //     //find the index
        //     let list3 = state.employment.filter( ( item ) => {
        //       return item.slug?.toLowerCase() !== state.title?.toLowerCase()
        //     } )

        //     await dispatch( setSaveEmployment( { employment: [ ...list3, { 
        //       foreign2: formatValue( values.foreign2 ),
        //       local2: formatValue( values.local2 ),
        //       total3: parseFloat( formatValue( values.foreign2 ) ) + parseFloat( formatValue( values.local2 ) ),
        //       slug: state.title,
        //     } ]} ) )
            
        //   } 
        //   else {
        //     await dispatch( setSaveEmployment( { employment: [ ...state.employment, { 
        //       foreign2: formatValue( values.foreign2 ),
        //       local2: formatValue( values.local2 ),
        //       total3: parseFloat( formatValue( values.foreign2 ) ) + parseFloat( formatValue( values.local2 ) ),
        //       slug: state.title,
        //     } ]} ) )
        //   }
        // }
    
        // if( state.tag?.toLowerCase() === "markets" ) {
        //   if( state.operation === "edit" ) {
        //     //find the index
        //     let list4 = state.markets.filter( ( item ) => {
        //       return item.slug?.toLowerCase() !== state.title?.toLowerCase()
        //     } )

        //     await dispatch( setSaveMarkets( { markets: [ ...list4, { 
        //       slug: state.title,
        //       percentage: formatValue( values.market_percentage )
        //     } ]} ) )
            
        //   } 
        //   else {
        //     await dispatch( setSaveMarkets( { markets: [ ...state.markets, { 
        //       slug: state.title,
        //       percentage: formatValue( values.market_percentage )
        //     } ]} ) )
        //   }
          
        // }
    
        // if( state.tag?.toLowerCase() === "inputs" ) {
        //   if( state.operation === "edit" ) {
        //     //find the index
        //     let list5 = state.inputs.filter( ( item ) => {
        //       return item.slug?.toLowerCase() !== state.title?.toLowerCase()
        //     } )

        //     await dispatch( setSaveInputs( { inputs: [ ...list5, { 
        //       slug: state.title,
        //       percentage: formatValue( values.input_percentage )
        //     } ]} ) )
            
        //   } 
        //   else {
        //     await dispatch( setSaveInputs( { inputs: [ ...state.inputs, { 
        //       slug: state.title,
        //       percentage: formatValue( values.input_percentage )
        //     } ]} ) )
        //   }
        // }
    
        // if( state.tag?.toLowerCase() === "files" ) {
        //   await dispatch( setSaveFiles( { files: [ ...state.files, { 
        //     slug: state.title
        //   } ]} ) )
        // }
    
        dispatch( setDisplayModal( { flag: false, title: "", operation: "" } ) )
    }

    const getInitValue = column => {
        if( state.operation === "edit" && state.title && state.tag ) {

          let Val = state[ state.tag ].filter( item => {
            return item.slug?.toLowerCase() === state.title
          } )?.[ 0 ]

          if( Val[ column ] ) {
            return Val[ column ]
          }
          return 0
        }
        return 0
    }

    const handleFileConvert = ( obj ) => {
      convertFileToBase(obj, response => {
        setFile( response )
      } )
    }

    return (
    <ModalComponent title={state.title} open={state.flag} handleOpen={() => dispatch( setDisplayModal( { flag: false, title: "" } ) )}>
      <Formik
        initialValues={{
            start_date: "",
            end_date: "",
            qualification: "",
            institution: "",
            organisation: "",
            cadre: "",
            membership_name: "",
            sponsor_name: "",
            registered: "",
            registration_number: "",
            discipline: ""
        }}
        onSubmit={handleSubmitForm}>
        { ( { handleSubmit, handleChange, values } ) => {
            return (
                <form onSubmit={handleSubmit}>
                    {state.tag?.toLowerCase() === "education" && (
                      <>
                        <TextFieldIcon 
                          name="start_date"
                          type="date"
                          handleChange={handleChange}
                          value={values.start_date}
                          question="Start Date"
                          label="Start Date"
                          required={state.tag?.toLowerCase() === "education" && true}
                          disabled={false}
                        />

                        <TextFieldIcon 
                          name="end_date"
                          type="date"
                          handleChange={handleChange}
                          value={values.end_date}
                          question="End Date"
                          label="End Date"
                          required={state.tag?.toLowerCase() === "education" && true}
                          disabled={false}
                        />

                        <TextFieldIcon 
                          name="qualification"
                          type="text"
                          handleChange={handleChange}
                          value={values.qualification}
                          question="Qualification"
                          label="Qualification"
                          required={state.tag?.toLowerCase() === "education" && true}
                          disabled={false}
                        />

                        <TextFieldIcon 
                          name="institution"
                          type="text"
                          handleChange={handleChange}
                          value={values.institution}
                          question="Institution"
                          label="Institution"
                          required={state.tag?.toLowerCase() === "education" && true}
                          disabled={false}
                        />

                        <TextFieldIcon 
                          name="attach_file"
                          type="file"
                          handleChange={ evt => {
                            handleChange( evt )
                            handleFileConvert( evt.target.files )
                          }}
                          value={values.attach_file}
                          question="Attach Documentary Evidence(PDF)"
                          label="Attach Documentary Evidence"
                          required={state.tag?.toLowerCase() === "education" && true}
                          disabled={false}
                        />
                      </>
                    )}


                    {/* finance  */}
                    {( state.tag?.toLowerCase() === "engineering" ) && (
                      <>
                        <TextFieldIcon 
                          name="start_date"
                          type="date"
                          handleChange={handleChange}
                          value={values.start_date}
                          question="Start Date"
                          label="Start Date"
                          required={state.tag?.toLowerCase() === "education" && true}
                          disabled={false}
                        />

                        <TextFieldIcon 
                          name="end_date"
                          type="date"
                          handleChange={handleChange}
                          value={values.end_date}
                          question="End Date"
                          label="End Date"
                          required={state.tag?.toLowerCase() === "education" && true}
                          disabled={false}
                        />

                        <TextFieldIcon 
                          name="qualification"
                          type="text"
                          handleChange={handleChange}
                          value={values.qualification}
                          question="Qualification"
                          label="Qualification"
                          required={state.tag?.toLowerCase() === "education" && true}
                          disabled={false}
                        />

                        <TextFieldIcon 
                          name="institution"
                          type="text"
                          handleChange={handleChange}
                          value={values.institution}
                          question="Institution"
                          label="Institution"
                          required={state.tag?.toLowerCase() === "education" && true}
                          disabled={false}
                        />

                        <TextFieldIcon 
                          name="attach_file"
                          type="file"
                          handleChange={ evt => {
                            handleChange( evt )
                            handleFileConvert( evt.target.files )
                          }}
                          value={values.attach_file}
                          question="Attach Documentary Evidence(PDF)"
                          label="Attach Documentary Evidence"
                          required={state.tag?.toLowerCase() === "education" && true}
                          disabled={false}
                        />
                      </>
                    )}

                    {/* funding  */}
                    {( state.tag?.toLowerCase() === "practicals" || state.tag?.toLowerCase() === "positions" ) && (
                      <>
                        <TextFieldIcon 
                          name="start_date"
                          type="date"
                          handleChange={handleChange}
                          value={values.start_date}
                          question="Start Date"
                          label="Start Date"
                          required={state.tag?.toLowerCase() === "practicals" && true}
                          disabled={false}
                        />

                        <TextFieldIcon 
                          name="end_date"
                          type="date"
                          handleChange={handleChange}
                          value={values.end_date}
                          question="End Date"
                          label="End Date"
                          required={state.tag?.toLowerCase() === "practicals" && true}
                          disabled={false}
                        />

                        <TextFieldIcon 
                          name="organisation"
                          type="text"
                          handleChange={handleChange}
                          value={values.organisation}
                          question="Organisation"
                          label="Organisation"
                          required={state.tag?.toLowerCase() === "practicals" && true}
                          disabled={false}
                        />

                        <TextFieldIcon 
                          name="cadre"
                          type="text"
                          handleChange={handleChange}
                          value={values.cadre}
                          question="Engineering Cadre"
                          label="Engineering Cadre"
                          required={state.tag?.toLowerCase() === "practicals" && true}
                          disabled={false}
                        />
                      </>
                    )}

                    {( state.tag?.toLowerCase() === "membership" ) && (
                      <>
                        <TextFieldIcon 
                          name="membership_name"
                          type="text"
                          handleChange={handleChange}
                          value={values.membership_name}
                          question="Name of Institution(Membership)"
                          label="Name of Institution"
                          required={state.tag?.toLowerCase() === "membership" && true}
                          disabled={false}
                        />

                        <TextFieldIcon 
                          name="attach_file"
                          type="file"
                          handleChange={ evt => {
                            handleChange( evt )
                            handleFileConvert( evt.target.files )
                          }}
                          value={values.attach_file}
                          question="Attach Documentary Evidence(PDF)"
                          label="Attach Documentary Evidence"
                          required={state.tag?.toLowerCase() === "membership" && true}
                          disabled={false}
                        />
                      </>
                    )}

                    { ( state.tag?.toLowerCase() === "sponsors" ) && (
                      <>
                        <TextFieldIcon 
                          name="sponsor_name"
                          type="text"
                          handleChange={handleChange}
                          value={values.sponsor_name}
                          question="Sponsor( Name of the Sponsor)"
                          label="Sponsor( Name of the Sponsor)"
                          required={state.tag?.toLowerCase() === "sponsors" && true}
                          disabled={false}
                        />
          
                        <SelectFieldIcon 
                        noPadding={true}
                        label="Is the sponsor a registered Engineer?"
                        name="registered"
                        required={state.tag?.toLowerCase() === "sponsors" && true}
                        handleChange={handleChange}
                        value={values.registered}
                        options={[
                          { id: 1, label: "Yes", value: "Yes" },
                          { id: 2, label: "No", value: "No" }
                        ]}
                        question="Is the Sponsor a registered Engineer?" 
                      />
          
                      {values?.registered === "Yes" && 
                      <TextFieldIcon 
                        name="registration_number"
                        type="number"
                        handleChange={ evt => {
                          handleChange( evt )
                        } }
                        value={values.registration_number}
                        question="Registration No."
                        label="Registration No."
                        required={state.tag?.toLowerCase() === "sponsors" && true}
                        disabled={false}
                      />}
          
          
                        <SelectFieldIcon 
                          noPadding={true}
                          label="Discipline"
                          name="discipline"
                          required={state.tag?.toLowerCase() === "sponsors" && true}
                          handleChange={handleChange}
                          value={values.discipline}
                          options={[
                            { id: 1, label: "Aerospace", value: "Aerospace" },
                            { id: 2, label: "Agricultural", value: "Agricultural" },
                            { id: 3, label: "Biomedical", value: "Biomedical" },
                            { id: 4, label: "Chemical", value: "Chemical" },
                            { id: 5, label: "Civil", value: "Civil" },
                            { id: 6, label: "Computer", value: "Computer" },
                            { id: 7, label: "Electrical", value: "Electrical" },
                            { id: 8, label: "Food", value: "Food" },
                            { id: 9, label: "Environmental", value: "Environmental" },
                            { id: 10, label: "Industrial", value: "Industrial" },
                            { id: 11, label: "Mechanical", value: "Mechanical" },
                            { id: 12, label: "Networks", value: "Networks" },
                            { id: 13, label: "Petroleum", value: "Petroleum" },
                            { id: 14, label: "Software", value: "Software" },
                            { id: 15, label: "Systems", value: "Systems" },
                          ]}
                          question="Discipline" 
                        />
                      </>
                    ) }
                     

                    <div className="self-end flex items-center my-2">
                        <button className="bg-blue-500 px-2 py-1 mx-2 text-sm text-white rounded-sm" type="button" onClick={() => dispatch( setDisplayModal( { flag: false, title: "" } ) )}>
                            {`Cancel`}
                        </button>
                        <button type="submit" className="bg-green-700 px-3 py-1 mx-2 text-sm text-white rounded-sm">
                            {`Save`}
                        </button>
                    </div>
                </form>
            )
        } }
      </Formik>
    </ModalComponent>
  )
}
