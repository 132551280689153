import React, { useEffect, useState } from 'react'
import { Panel, TextFieldIcon, SelectFieldIcon, PhoneFieldIcon, TextField } from '../../../../_components'
import { useDispatch, useSelector } from 'react-redux'
import data from '../../../fixtures'
import { convertFileToBase } from '../../../../_utils'

export default function Guidelines( { formType, type, deferData, handleBlur, handleChange, values, setAlertTIN, stockers, loading, list, handleErrorTIN, handleSelectedBRN } ) {
  const dispatch = useDispatch()

  const handleFileChange = e => {
    convertFileToBase( e.target.files, response => {
      // console.log( response );
    } )
  }
  
  return (
    <>
        <Panel label="Guidelines">
          <div className="flex flex-col items-center">
          <ul className="p-2">
                  <li className="py-1">
                    <p className="text-sm">
                      {`1.0. Subject to provision of the Engineers Registration Act (ERA), an engineer is entitled, on making a successful
                        application to the Board in the prescribed manner and on payment of the prescribed fee, to have his/her name
                        entered into the register.`}
                    </p>
                  </li>

                  <li className="py-1">
                    <p className="text-sm">
                      {`2.0. An applicant for registration must have the following qualifications:`}
                    </p>
                  </li>

                  <li className="py-1">
                    <p className="text-sm">
                      {`2.1. A Degree, Diploma or Licence of a University or School of Engineering reviewed and assessed by
                        Board and then accredited by NCHE, or any other awards recognized by the Board as furnishing a
                        sufficient guarantee of an adequate academic training in engineering are eligible for registration`}
                    </p>
                  </li>

                  <li className="py-1">
                    <p className="text-sm">
                      {`2.2. At least 2 years training as a pupil engineer after graduation (i.e., pupillage), under the supervision of a Registered Engineer`}
                    </p>
                  </li>

                  <li className="py-1">
                    <p className="text-sm">
                      {`2.3. A further 2 years working experience in a position or positions of some responsibility (i.e., post pupillage), again under the supervision of a Registered Engineer`}
                    </p>
                  </li>

                  <li className="py-1">
                    <p className="text-sm">
                      {`2.4. Corporate Membership of the Uganda Institution of Professional Engineers (UIPE);`}
                    </p>
                  </li>

                  <li className="py-1">
                    <p className="text-sm">
                      {`2.5. The applicant must have attained the age of 25 years`}
                    </p>
                  </li>

                  <li className="py-1">
                    <p className="text-sm">
                      {`2.6. Applicants who possess demonstrable prior engineering practice of at least 4 years after acquiring an
                        Ordinary, or a Higher Diploma in an Engineering field or other technical qualification from a
                        recognized institution of higher learning, upon upgrading to a recognized Bachelor’s degree in
                        engineering are eligible to apply for registration at post pupillage level, (i.e., the first 2 years of pupillage
                        are waived in place of the 4 years demonstrable engineering practice).`}
                    </p>
                  </li>
                </ul>
          </div>
        </Panel>
    </>
  )
}
