import React, { useState, useEffect } from 'react'
import { PDFViewer, Document, Page, StyleSheet, Font } from '@react-pdf/renderer'
import { useLocation } from 'react-router-dom';
import ContentSection from './ContentSection'
import LastPage from './LastPage'
import QRGenerator from './QRCode'
import { useNavigate } from 'react-router-dom';
import Axios from 'axios'
import { API_BASE } from '../../_utils';

import OpenRegular from '../../../fonts/Roboto-Regular.ttf'
import OpenBold from '../../../fonts/Roboto-Bold.ttf'
import RobotoBold from '../../../fonts/Roboto-Bold.ttf'
import OpenLight from '../../../fonts/Roboto-Light.ttf'
import OpenMedium from '../../../fonts/Roboto-Medium.ttf'

Font.register( {
    family: "OpenRegular",
    fonts: [
        { src: OpenRegular, fontWeight: "normal" },
    ]
} )

Font.register( {
    family: "OpenLight",
    fonts: [
        { src: OpenLight, fontWeight: "normal" },
    ]
} )

Font.register( {
    family: "OpenMedium",
    fonts: [
        { src: OpenMedium, fontWeight: "normal" },
    ]
} )

Font.register( {
    family: "OpenBold",
    fonts: [
        { src: OpenBold, fontWeight: "bold" },
    ]
} )

Font.register( {
    family: "RobotoBold",
    fonts: [
        { src: RobotoBold, fontWeight: "bold" },
    ]
} )

const styles = StyleSheet.create( {
    page: {
        display: "grid",
        gridTemplateColumns: "50px auto"
    }
} )

const Certificate = ( {} ) => {
    const navigate = useNavigate()
    const location = useLocation()

    const [ loading, setLoading ] = useState( true )
    const [ QRDataURLCode, setQRDataCode ] = useState( null )
    const [ licenseData, setLicenseData ] = useState( null )
    const [ remarks, setRemarks ] = useState( [] )

    useEffect( () => {
        if( loading ) {
            const qrCodeCanvas = document.querySelector( "canvas" )
            const QRDataURL = qrCodeCanvas?.toDataURL( "image/jpg", 0.3 )
            setQRDataCode( QRDataURL )
            setLoading( false )
            // fetchRemarks()
            // fetchLicenseNumber()
        }
    }, [ loading ])

    const handleBack = () => navigate( `/my-applications` )

    return (
        <div className="h-screen w-full overflow-hidden bg-gray-100">
            <div className="w-full h-full grid gap-3 p-2" style={{ gridTemplateColumns: "12rem auto", }}>
                <div className="h-1/2 bg-white shadow-xl rounded flex flex-col">
                    <button className="bg-green-500" onClick={handleBack}>
                        <span className="text-sm text-white">
                            {`Back`}
                        </span>
                    </button>
                    <div className="flex py-2 justify-center items-center">
                        <QRGenerator data={location.state} licenseData={licenseData}/>
                    </div>
                </div>
                <div className="h-full">
                    <PDFViewer showToolbar={true} style={{ width: "100%", height: "100%" }}>
                        <Doc remarks={remarks} data={location.state?.result} image={QRDataURLCode} licenseData={licenseData}/>
                    </PDFViewer>
                </div>
            </div>
        </div>
    )
}

const Doc = ( { image, data, licenseData , remarks} ) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <ContentSection remarks={remarks} record={data} imageQR={image} licenseData={licenseData}/>
            </Page>
            {/* <Page size="A4" style={styles.page}>
                <LastPage record={data} />
            </Page> */}
        </Document>
    )
}

export default Certificate
