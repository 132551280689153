import React, { useEffect, useState } from 'react'
import Elogo from '../../_images/shortERBlogo.png'
import Coat from '../../_images/coat-of-arms.jpeg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { UserProfileImage } from '../../_components'
import { getUserDetails } from '../_utils'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'

export default function NavStripBar({ }) {
  const navigate = useNavigate()
  const location = useLocation()
  const [ profile, setName ] = useState( "" )
  const [ fade, setDisplayFade ] = useState( false )
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

   useEffect( () => {
    if( localStorage.getItem( "token" ) ) {
        const { name } = getUserDetails()
        setName( name )
    }
  },[ localStorage.getItem( "token" )] )

  const handleNavigateHome = async () => {
    let token = await localStorage.getItem( "token" )
    if( token && token?.length > 0 ) {
        return navigate( "/dashboard" )
    }
    return navigate( "/" )
  }

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleNavigateApplications = () => {
    navigate("/applications");
  };

  let user = JSON?.parse( localStorage.getItem( "user" ) )

  return (
    <nav className="sticky h-auto top-0 z-50 bg-white grid py-1 px-5 shadow-md relative" style={{ gridTemplateColumns: "25% 1fr 20%" }}>
        
        <div onClick={() => handleNavigateHome()} className="h-full flex items-center justify-center py-2 cursor-pointer hover:bg-white">
            <div className="h-full">
                <img src={Elogo} alt="Uganda Logo" className="md:h-20 lg:h-20 h-auto" />
            </div>
        </div>

        <div className="flex flex-col items-center justify-center">
            <h5 className="py-2 text-center font-semibold text-xs md:text-xl lg:text-xl uppercase">
                {`Uganda Engineers Registration Board`}
            </h5>
            <h2 className="text-xs md:text-base lg:text-base text-center font-normal">
                {`APPLICATION AND REGISTRATION PORTAL`}
            </h2>
        </div>

        <div className="md:flex lg:flex hidden flex-col items-center justify-start">
            <div className="flex items-center justify-center">
                <div className="rounded-full">
                </div>
                {localStorage.getItem( "token" ) ? 
                ( <div className="py-5">
                    <div onClick={() => setDisplayFade( !fade ) } className="flex flex-row items-center justify-between cursor-pointer">
                        <UserProfileImage img={user?.user_picture} label={`${user?.first_name + " " + user?.surname}`} />
                        <div className="mx-2">
                        {fade ? <FaAngleUp size={20} color={`#666`}/>: <FaAngleDown size={20} color={`#666`}/>}
                        </div>
                    </div>
                    <div className={`${ fade ? "block" : "hidden" } absolute z-10 h-auto bg-black top-15 w-auto`}>
                        <div className="w-full px-10 my-2">
                            <ListItem handlePress={async () => {
                                await localStorage.clear()
                                navigate( "/login" )
                                window.location.reload()
                            } } label="Logout"/>
                        </div>
                    </div> 
                </div> ) : (
                    <div className="h-full">
                        <img src={Coat} className="p-2 h-20" />
                    </div>
                ) }
            </div>
            <div className="text-xs md:text-sm lg:text-sm font-bold" />
        </div>

        <div className="absolute -bottom-2 w-full">
            <div className="h-1 max-h-1 bg-black w-full" />
            <div className="h-1 max-h-1 bg-yellow-300 w-full" />
            <div className="h-1 max-h-1 bg-red-500 w-full" />
        </div>
      </nav>
  )
}

export const ListItem = ( { label, handlePress } ) => {
    return (
        <div onClick={handlePress} className="cursor-pointer flex h-8 items-center justify-start text-xs md:text-sm lg:text-sm text-white cursor-pointer hover:text-red-500">
            {label}
        </div>
    )
}
