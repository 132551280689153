import React, { useEffect, useState } from 'react'
import { Panel, TextField } from '../../../../_components'
import { useDispatch, useSelector } from 'react-redux'
import { truncate } from '../../../_utils'
import moment from 'moment'
import fixturesData from '../../../fixtures'
import axios from 'axios'


export default function Payment( { type, result, handleChange, handleBlur, values, handleOpen, shareholders, sector } ) {
  const dispatch = useDispatch()
  const [ token, setToken ] = useState( null )

  const state = useSelector( state => {
    return {
        flag: state.application.displayModal?.flag,
        title: state.application.displayModal?.title,
        tag: state.application.displayModal?.tag,
        shareholders: state.application.shareholders,
        finance: state.application.financing,
        funding: state.application.funding,
        employment: state.application.employment,
        markets: state.application.markets,
        inputs: state.application.inputs,
        files: state.application.files,
        sectorTitle: state.application.sectorTitle,
        divisionTitle: state.application.divisionTitle,
        groupTitle: state.application.groupTitle,
        classTitle: state.application.classTitle,
        deferred_holders: state.application.deferred_holders
    }
  } )

  const [ pageLoading, setPageLoading ] = useState( true )

  useEffect( () => {
    if( pageLoading ) {
      window.scrollTo( { top: 0, left: 0, behavior: "smooth" } )
      setPageLoading( false )
    }
  }, [ pageLoading ])

  useEffect( () => {
    if( !token ) {
        fetchFlexiToken()
    }
  }, [ token ])

  const handleIDType = type => {
    switch( type ) {
      case "DT-NID":
        return "National ID"
      case "DT-DRIVELIC":
        return "Drivers License"
      default:
        return type

    }
  }

  const handleType = type => {
    switch( type ) {
      case "PT-IN":
        return "Individual"
    case "PT-EN":
        return "Entity"
      default:
        return
    }
  }

  const handleDisplayName = ( personType, item ) => {
    switch( personType ) {
      case "PT-IN":
        return item.first_name?._text + " " + item.surname?._text
      default:
        return 
    }
  }

  const fetchFlexiToken = async () => {
    try {
        let data = '';

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'http://localhost:8000/flexi/token/',
            headers: { 
                'Authorization': 'Basic  RVJCX1VHOmdpTWFPbTNLdUYxaHZMRk53c2lqSDZpbUNTckpHazEpMzlvQ1B3dWk='
            },
            data : data
        };

        const response = await axios.request( config )
        if( response.data ) {
            console.log( "xx", response?.data )
        }
    } catch (error) {
        console.log( "error", error?.response )
    }
  }

  const handlePayment = () => {
    
  }

  return (
    <>
        <Panel label="Payment Information">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2">
                <button className="w-32 text-white bg-blue-800 px-2 py-1 text-sm" type="button" onClick={handlePayment}>
                    {`Pay Application Fees`}
                </button>
            </div>
        </Panel>
    </>
  )
}
