import React, { useState, useEffect } from 'react'
import { SelectFieldIcon, TextFieldIcon, Panel, Wrap } from '../../_components'
import { getUserDetails } from '../_utils'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { FaUserAlt } from 'react-icons/fa'
import Axios from 'axios'
import { CircularProgress } from '@material-ui/core'
import moment from 'moment'
import { API_BASE, handleError, sanitizeValue } from '../../config'

let expSearch = /^8003/


const convert = require("xml-js");

export default function CompleteRegistration() {
    const navigate = useNavigate()
    const { name, email, company } = getUserDetails()
    const [ fetching, setFetching ] = useState( false )
    const [ organisation, setOrganisation ] = useState( null )
    const [ displayMessage, setDisplayMessage ] = useState( false )
    const [ loading, setLoading ] = useState( true )
    const [ verifiedList, setVerifiedCompanies ] = useState( [] )
    const [ errorOrg, setErrorOrg ] = useState( null )
    const [ display, setDisplay ] = useState( false )

    useEffect( () => {
        if( loading ) {
            fetchCompanyList()
        }
    }, [ loading ])

    const fetchCompanyList = async () => {
        try {
            const response = await Axios.get( `${API_BASE}/companies/list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem( "token" )}`
                }
            } )
           if( response.data ) {
            setVerifiedCompanies( response.data.sort( ( a, b ) => {
                return moment( b.created_at ).format( "YYYYMMDDHHMM" ) - moment( a.created_at ).format( "YYYYMMDDHHMM" )
            } ) )
            setLoading( false )
           }
        } catch (error) {
            alert( "Could not fetch verified companies" )
        }
    }

    const handleSubmitCompany = async ( values ) => {
       if( new Date( values.incorporation_date ) > new Date() ) {
        return alert( "Incorporation date cannot be in the future." )
       }

       if( new Date( values.reg_date ) > new Date() ) {
        return alert( "Registration date cannot be in the future." )
       }

       try {
        const response = await Axios.post( `${API_BASE}/companies`, {
            name: sanitizeValue( values.company_name ),
            company_type: sanitizeValue( values.company_type ),
            reg_no: values.brn?.toString(),
            reg_date: values.reg_date,
            incorporation_date: values.incorporation_date,
            reg_status: sanitizeValue( values.reg_status )
        },
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem( "token" )}`
            }
        } )
        if( response.data ) {
            alert( response.data?.message )
            setLoading( true )
        }
       } catch (error) {
            handleError( error )
       }
    }

    const handleSubmit = async ( values ) => {
        let expStr = expSearch.test( values.brn  )
        if( expStr ) {
            handleSubmitCompany( values )
        }
        else {
            try {
                const response = await Axios.post( `${API_BASE}/companies?reg_no=${values.brn}`, {},
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem( "token" )}`
                    }
                } )
                
               if( response.data ) {
                setOrganisation( null )
                setLoading( true ) 
                
               }
            } catch (error) {
                alert( "Your account and organisation could not be verified." )
            }
        }
    }

    const handleFinish = async () => {
        await localStorage.setItem( "verified", true )
        alert( "Your account and organisation(s) have been verifed successfully." )
        navigate( "/" )
        window.location.reload()    
    }

    const fetchOrganisation = async ( values ) => {
        setFetching( true )
        setOrganisation( null )
        setErrorOrg( null )
        if( values?.brn ) {
            try {
                const response = await Axios.get( `${API_BASE}/companies?reg_no=${values.brn}` )
                if( response.data ) {
                  const data = JSON.parse( convert.xml2json( response.data, { compact: true, spaces: 2 } ) );
                  setFetching( false )

                  if( data?.entity?.error_code?._text ) {
                    setErrorOrg( data?.entity?.error_code?._text )
                  }
                  else {
                    setOrganisation( data );
                  }
                }
              } catch (error) {
                  alert( "We could not fetch organisation details at this time." )
                  setFetching( false )
              }
        }
        else {
            alert( "Enter Valid Business Registration Number" )
        }
        
    }

    const handleRegistration = evt => {
        if( evt.target.value === "No" ) {
            setDisplayMessage( true )
        }
    }

    if( loading ) {
        return (
            <div className="flex h-screen w-screen">
                <span className="text-xs md:text-base lg:text-base">{`Loading...`}</span>
                <CircularProgress size={24} color="primary"/>
            </div>
        )
    }

    return (
        <Wrap  navigate={navigate}>
            <div className="relative bg-[#F6F6F6] px-5 py-1">
            <div className="p-5 flex items-center justify-center">
                <h2 className="text-base md:text-xl lg:text-xl">
                    {`Verify your Company(s) Information`}
                </h2>
            </div>

                <Panel label="Your Verified Companies">
                    <div className="w-full flex items-center justify-between">
                        <div />
                        <button type="button" onClick={() => setDisplay( !display )} className="rounded text-xs text-white bg-blue-700 py-1 px-2">
                            {`Add A Company`}
                        </button>
                    </div>
                    <table className="w-full border">
                        <tr className="text-xs bg-gray-200 font-bold p-2 text-start">
                            <td className="text-start">{`Business Registration Number`}</td>
                            <td className="text-start">{`Entity Name`}</td>
                            <td className="text-start">{`Verification Date`}</td>
                        </tr>
                        <tbody>
                            {verifiedList?.map( ( item, index ) => {
                                return (
                                    <tr className="text-xs text-start border border-gray-300 p-2" key={index+1}>
                                        <td className="text-start p-2">{item?.reg_no}</td>
                                        <td className="text-start">{`${item?.name}`}</td>
                                        <td className="text-start">{`${moment( item?.created_at ).format( "DD MMM, YYYY" )}`}</td>
                                    </tr>
                                )
                            } )}
                        </tbody>
                    </table>
                </Panel>
            
                {display && 
                <Panel label="Verify Your Personal Information">
                    <Formik
                        initialValues={{
                            company,
                            registered: "",
                            brn: "",
                            email_address: email,
                            company_name: "",
                            company_type: "",
                            reg_date: "",
                            reg_status: "",
                            incorporation_date: ""

                        }} 
                        onSubmit={handleSubmit}>
                        { ( {  values, handleSubmit, handleChange, handleBlur, isSubmitting } ) => {

                            let expStr = expSearch.test( values.brn  )

                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                                        <SelectFieldIcon
                                            name="registered"
                                            value={values.registered}
                                            handleBlur={handleBlur}
                                            handleChange={evt => {
                                                handleRegistration( evt )
                                                handleChange( evt )
                                            }}
                                            iconComponent={<FaUserAlt color={`#666`} />}
                                            label={"Registered"}
                                            defaultOption=""
                                            question="Is the Company / Entity registered with Uganda Registration Services Bureau?"
                                            required={true} 
                                            options={[
                                                { id: 1, value: "Yes", label: "Yes" },
                                                { id: 2, value: "No", label: "No" },
                                            ]}  
                                        />
                                    </div>

                                    {values.registered === "No" && (
                                        <>
                                        <div className="bg-green-700 text-start py-5 px-2 font-bold text-center text-white text-lg">
                                            {`Please register with the Uganda Registration Services Bureau.`}
                                        </div>
                                        <div className="cursor-pointer hover:underline py-2 text-white bg-green-700 text-base text-center">
                                            <a href="https://obrs.ursb.go.ug" target="_blank">
                                                {`Visit the URSB Registration Portal`}
                                            </a>
                                        </div>
                                        </>
                                    ) }

                                    

                                    {values.registered === "Yes" && (
                                        <>
                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                                            <TextFieldIcon 
                                                name="brn"
                                                type="number"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={values.brn}
                                                disabled={false}
                                                question="Business Registration Number(BRN)"
                                                label="BRN"
                                                required={values.registered === "Yes" ? true : false}
                                            />
                                            
                                            {!expStr && <div className="relative">
                                                {fetching ? (
                                                    <div className="flex">
                                                        <span>{`Fetching Company Details...`}</span>
                                                        <CircularProgress size={24} color="primary"/>
                                                    </div>
                                                ) : <button type="button" onClick={() => fetchOrganisation( values )} className="rounded absolute bottom-5 left-5 text-xs bg-blue-500 text-white px-3 py-1">
                                                    {`Verify Company Details`}
                                                </button>}
                                            </div>}
                                        </div>
                                    
                                        </>
                                    ) }

                                    {expStr && (
                                        <Panel label="Add BRN Details">
                                            <div className="grid grid-cols-1 gap-2 md:grid-cols-3 lg:grid-cols-3 gap-2">
                                                <TextFieldIcon 
                                                    name="company_name"
                                                    type="text"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.company_name}
                                                    disabled={false}
                                                    question="Name of the Company"
                                                    label="Company Name"
                                                    required={expStr ? true : false}
                                                />

                                                <TextFieldIcon 
                                                    name="company_type"
                                                    type="text"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.company_type}
                                                    disabled={false}
                                                    question="Type of Company"
                                                    label="Company Type"
                                                    required={expStr ? true : false}
                                                />

                                                <TextFieldIcon 
                                                    name="reg_date"
                                                    type="date"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.reg_date}
                                                    disabled={false}
                                                    question="Date of Registration"
                                                    label="Registration Date"
                                                    required={expStr ? true : false}
                                                />

                                                <TextFieldIcon 
                                                    name="reg_status"
                                                    type="text"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.reg_status}
                                                    disabled={false}
                                                    question="Registration Status"
                                                    label="Status"
                                                    required={expStr ? true : false}
                                                />

                                                <TextFieldIcon 
                                                    name="incorporation_date"
                                                    type="date"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.incorporation_date}
                                                    disabled={false}
                                                    question="Date of Incorporation"
                                                    label="Incorporation Date"
                                                    required={expStr ? true : false}
                                                />
                                            </div>
                                        </Panel>
                                    ) }


                                    {errorOrg && (
                                        <>
                                            <div className="bg-red-500 text-start py-5 px-2 font-bold text-center text-white text-lg">
                                                {`Error Occured`}
                                            </div>
                                            <div className="cursor-pointer hover:underline py-2 text-white bg-red-500 text-base text-center">
                                                {errorOrg}
                                            </div>
                                        </>
                                    )}

                                    {( organisation && !errorOrg ) && 
                                    ( <Panel label="Organisation Details">
                                        <div className="grid grid-cols-3 gap-2">
                                            <TextFieldIcon 
                                                name="entity_name"
                                                type="text"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={organisation?.entity.entity_name?._text}
                                                question="Entity Name"
                                                label="Entity Name"
                                                required={true}
                                                disabled={true}
                                            />

                                            <TextFieldIcon 
                                                name="incorporation_date"
                                                type="text"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={organisation?.entity.incorporation_date?._text}
                                                question="Date of Incorporation"
                                                label="Date of Incorporation"
                                                required={true}
                                                disabled={true}
                                            />
                                            <TextFieldIcon 
                                                name="company_type"
                                                type="text"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={organisation?.entity.company_type?._text}
                                                question="Type of Company"
                                                label="Type of Company"
                                                required={true}
                                                disabled={true}
                                            />

                                            <TextFieldIcon 
                                                name="entity_type"
                                                type="text"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={organisation?.entity.metadata?.entity_type?._text}
                                                question="Entity Type"
                                                label="Entity Type"
                                                required={true}
                                                disabled={true}
                                            />
                                            <TextFieldIcon 
                                                name="registration_status"
                                                type="text"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={organisation?.entity.reg_status?._text}
                                                question="Registration Status"
                                                label="Registration Status"
                                                required={true}
                                                disabled={true}
                                            />
                                            <TextFieldIcon 
                                                name="registration_date"
                                                type="text"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={organisation?.entity.reg_date?._text}
                                                question="Registration Date"
                                                label="Registration Date"
                                                required={true}
                                                disabled={true}
                                            />
                                            <TextFieldIcon 
                                                name="phone_mobile"
                                                type="text"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={organisation?.entity.metadata?.phone_mobile?._text}
                                                question="Phone Number"
                                                label="Phone Number"
                                                required={true}
                                                disabled={true}
                                            />
                                        </div>
                                    </Panel> ) }

                                
                                    {( ( !errorOrg && organisation ) || expStr ) && ( 
                                    <div className="w-full flex items-center justify-end ">
                                        {isSubmitting ? (
                                        <div className="flex items-center justify-center">
                                            <CircularProgress size={24} color="primary" />
                                        </div>
                                        ) : ( 
                                        <div className="flex">
                                            <button disabled={isSubmitting} className="w-full bg-[#1BB0CE] text-white px-5 py-2 text-sm rounded focus:outline-none focus:border-none" type="submit">
                                                {`Submit and Verify Company`}
                                            </button>
                                        </div> ) }
                                    </div> ) }
                                </form>
                                
                            )
                        } }
                    </Formik> 
                </Panel>}

                {( verifiedList?.length > 0 ) && 
                <Panel label="Complete Company Verification">
                    <div className="grid grid-cols-1">
                        <h2>{`If you are done with adding your company/companies for verification, Please click finish to complete the profile setup.`}</h2>
                        <button disabled={fetching} onClick={() => handleFinish()} className="w-24 rounded my-2 mx-2 bg-red-500  text-white px-5 text-base focus:outline-none focus:border-none" type="button">
                            {`Finish`}
                        </button>
                    </div>
                </Panel>} 
            </div>
        </Wrap>
    )
}
 