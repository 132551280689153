import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode.react'
import moment from 'moment'
import Axios from 'axios'

const QRGenerator = ( { data, licenseData } ) => {
    // let license_no = await localStorage.getItem( "licenseNo" )
    // console.log( license_no )

    let ENTITY_NAME = data?.result?.entity_name
    // let LICENSE_NUMBER = license_no
    let DATE_EXPIRY = moment( data?.result?.updated_at ).add(5, 'years').format( "DD MMMM YYYY" )
    let DATE_ISSUED = moment( data?.result?.updated_at ).format( "DD MMMM YYYY" )

    return (
        <div>
            <QRCode 
                id={`000001`}     
                value={`{ 
                    Organisation: ${ENTITY_NAME},
                    License Number: ${`ABC`},
                    DateIssued: ${DATE_ISSUED},
                    DateExpiry: ${DATE_EXPIRY}
                    }`} 
                size={100}  
                bgColor={"#000"}
                fgColor={"#FFFFFF"}
                level={"L"}
                includeMargin={true}
            />
        </div>
    )
}
export default QRGenerator