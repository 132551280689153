import React from 'react'
import { classNames } from '../_utils'

export default function ProgressBar( { value } ) {
  
  return (
    <div className="flex">
    <div className="flex flex-row items-center w-28 h-3 border border-gray-400 rounded-md">
        <div className="h-full w-10 rounded-md" style={{ backgroundColor: parseInt( value ) === 100 ? "green" : ( parseInt( value ) <= 20 ? "red" : "orange" ), width: `${value}%` }} />
    </div>
    <div className="mx-2 text-xs">{`${value} %`}</div>
    </div>
  )
}
