import React, { useEffect, useState } from 'react'
import { Wrap, Panel, TextField, SelectFieldIcon, TextAreaIcon } from '../../_components'
import { CircularProgress } from '@material-ui/core'
import { useLocation, useNavigate  } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { sumFooterColumns, formatCurrency } from '../_utils'
import { API_BASE } from '../../_utils'
import localStorage from 'redux-persist/es/storage'

const menuItems = [
    "Your Application Details",
    "Review and Action",
]


export default function ApplicationDetails( props ) {

    const navigate = useNavigate()
    const location = useLocation()
    const licenseNumber = location.pathname?.split( "/" )?.[ 2 ]

    const [ loading, setLoading ] = useState( true )
    const [ result, setResult ] = useState( null )
    const [ activeStep, setActiveStep ] = useState( 0 )
    const isLastStep = activeStep === ( menuItems.length - 1 )
    const [ errorTIN, setErrorTIN ] = useState( null )
    const [ fetching, setFetching ] = useState( false )
    const [ TIN, setTIN ] = useState( null )

    const [ fetchingLicenseNo, setFetchingLicenseNo ] = useState( false )
    const [ remarks, setRemarks ] = useState( [] )
    const [ loadingLicense, setLoadingLicense ] = useState( true )
    const [ serialNumber, setSerialNumber ] = useState( null )
    const [ action, setAction ] = useState( "" )
    const [ comment, setComment ] = useState( "" )
    const [ admin, setAdmin ] = useState( "" )
    const [ uploading, setUploading] = useState( false )
  
    useEffect( () => {
        if( loading ) {
            fetchApplication()
        }
    }, [ loading ] )



    const fetchApplication  = async () => {
        let role = await localStorage.getItem( "role" )
        setAdmin( role )

        // if( licenseNumber ) {
            try {
                const response = await axios.get( `${API_BASE}/erb_getLicenceById/${licenseNumber}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                } ) 

                if( response.data ){
                    setResult( response.data )
                    setLoading( false )
                }
            } catch (error) {
                setLoading( false )
                alert ( error.response?.data?.message )
            }
        // }
    }

    const calculateStatus = () => {
        switch( admin ) {
            case "REGISTRAR":
                return { status: "VERIFIED", progress: 40, nextStage: 2   }
            case "SUBCOMMITEE":
                return { status: "PREAPPROVED", progress: 60,nextStage: 3 }
            case "BOARD":
                return { status: "APPROVED", progress: 100,nextStage: 3  }
            default:
                return {}
        }
    }


    const handleAction = async ( e ) => {
        e.preventDefault()

        let payload = {
            status: action?.toUpperCase() === "DEFER" ? "DEFERRED" : calculateStatus()?.status,
            progress: action?.toUpperCase() === "DEFER" ? 0 : calculateStatus()?.progress,
            tracking_no: result.id,
            stage: action?.toUpperCase() === "DEFER" ? 0 : calculateStatus()?.nextStage,
            id: result?.records?.id,
            actor: localStorage.getItem( "role" ),
            actor_id: 2,
            actor_email: localStorage.getItem( "role" ),
            actor_role: admin,
            comments: comment,
            action: action?.toUpperCase()
        }


        setUploading( false )
        try {
            const response = await axios.post( `${API_BASE}/erb_update`, payload, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem( "token" )}`
                }
            } )


            if( response.data ) {
                alert( response.data?.message )
                setUploading( false )
                navigate( "/my-applications" )
            }
        } catch (error) {
            alert( error?.response?.data?.message )
            setUploading( false )
        }
    }

    

    if( loading ) {
        return (
            <Wrap navigate={navigate}>
                <div className="flex w-screen h-screen items-center justify-center">
                    <span className="text-xs md:text-sm lg:text-sm">
                        {`Loading Application Details...`}
                    </span>
                    <CircularProgress size={24} color={`blue`} />
                </div>
            </Wrap>
        )
    }

    return (
    <Wrap navigate={navigate}>
        <div className="relative bg-[#F6F6F6] px-5 py-1 w-full">
        <div className="flex flex-col py-2 px-3 bg-white my-4">
        <div className="w-full flex items-center">
            {location?.state?.type?.toLowerCase() === "defer" ? (
                <h2 className="font-bold">
                    {`Please update the application and re-submit for approvals - Tracking ID - ${licenseNumber?.toUpperCase()}`}
                </h2>
            ) :  
                <h2 className="font-bold">
                    {`My Application - Tracking ID - ${licenseNumber?.toUpperCase()}`}
                </h2>
            }
        </div>
        </div>

        {activeStep === 0 && ( 
        <>
            <div className="w-full h-full">
            {result?.records?.status?.toUpperCase() === "APPROVED" && (
                <Panel label="Your Approved Licence">
                    <button type="button" className="bg-red-500 px-2 py-1" onClick={() => navigate( "/my-approved-licence" )}>
                        {`View your Approved Licence.`}
                    </button>
                </Panel>
            )}
            
            <Panel label="Section A: Particulars">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-2 bg-white">
                    {[
                      {id:1,label:'Name', val:result?.records?.name},
                      {id:2,label:'Postal Address', val:result?.records?.address},
                      {id:3,label:'Email Address', val:result?.records?.email_address},
                      {id:4,label:'Date Of Birth', val:result?.records?.dob},
                      {id:5,label:'Telephone', val:result?.records?.telephone},
                      {id:6,label:'Status', val:result?.records?.stage !== "3" ? "Pending" : "Approved"}  
                    ].map(item=>{return(
                        <div className='flex flex-col'>
                        <label htmlFor="" className="font-semibold">{item.label}</label>
                        <input className="text-sm p-1 font-normal" type="text" disabled value={item.val}/>
                        </div>
                    )})}
                </div>
            </Panel>

            {result?.application_type !== "RENEW" && <Panel label="Section B: General Education">
            <div className="grid grid-cols-1 bg-white">
                <table className="w-full border">
                    <tr className="text-xs font-bold text-start">
                        <th className="text-start p-2">{`Start Date`}</th>
                        <th className="text-start p-2">{`Qualification`}</th>
                        <th className="text-start">{`Institution`}</th>
                        <th className="text-start p-2">{`Document`}</th>
                    </tr>
                    <tbody>
                        {result?.education?.map( ( item, index ) => {
                            return (
                                <tr key={index} className="text-xs p-2 text-start border">
                                    <td className="py-2 text-center">{item?.start_date}</td>
                                    <td className="py-2 text-center">{item?.qualification}</td>
                                    <td className="py-2 text-center">{item?.institution}</td>
                                    <td className="py-2 text-center">
                                        <a className="bg-green-800 text-white px-3 py-1" href={item?.attach_file} download>{`Download File`}</a>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            </Panel>}

            {result?.application_type !== "RENEW" && <Panel label="Section B: Membership">
            <div className="grid grid-cols-1 bg-white">
                <table className="w-full border">
                    <tr className="text-xs font-bold text-start">
                        <th className="text-start p-2">{`Name`}</th>
                        <th className="text-start p-2">{`Attached Document`}</th>
                    </tr>
                    <tbody>
                        {result?.membership?.map( ( item, index ) => {
                            return (
                                <tr key={index} className="text-xs p-2 text-start border">
                                    <td className="py-2 text-center">{item?.membership_name}</td>
                                    <td className="py-2 text-center">
                                        <a className="bg-green-800 text-white px-3 py-1" href={item?.attach_file} download>{`Download File`}</a>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            </Panel>}

            {result?.application_type !== "RENEW" && <Panel label="Section B: Engineering Education">
            <div className="grid grid-cols-1 bg-white">
                <table className="w-full border">
                    <tr className="text-xs font-bold text-start">
                        <th className="text-start p-2">{`Start Date`}</th>
                        <th className="text-start p-2">{`Institution`}</th>
                        <th className="text-start p-2">{`Attached File`}</th>
                    </tr>
                    <tbody>
                        {result?.engineers?.map( ( item, index ) => {
                            return (
                                <tr key={index} className="text-xs p-2 text-start border">
                                    <td className="py-2 text-center">{item?.start_date}</td>
                                    <td className="py-2 text-center">{item?.institution}</td>
                                    <td className="py-2 text-center">
                                        <a className="bg-green-800 text-white px-3 py-1" href={item?.file} download>{`Download File`}</a>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            </Panel>}

            {result?.application_type !== "RENEW" && <Panel label="Section B: Positions Particulars">
                <div className="grid grid-cols-1 bg-white">
                    <table className="w-full border">
                        <tr className="text-xs font-bold text-start">
                            <th className="text-start p-2">{`Start Date`}</th>
                            <th className="text-start p-2">{`End Date`}</th>
                            <th className="text-start p-2">{`Organisation`}</th>
                            <th className="text-start p-2">{`Cadre`}</th>
                        </tr>
                        <tbody>
                            {result?.positions?.map( ( item, index ) => {
                                return (
                                    <tr key={index} className="text-xs p-2 text-start border">
                                        <td className="py-2 text-center">{item?.start_date}</td>
                                        <td className="py-2 text-center">{item?.end_date}</td>
                                        <td className="py-2 text-center">{item?.organisation}</td>
                                        <td className="py-2 text-center">{item?.cadre}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </Panel>}

            

            {result?.application_type !== "RENEW" && <Panel label="Section B: Practicals Particulars">
                <div className="grid grid-cols-1 bg-white">
                    <table className="w-full border">
                        <tr className="text-xs font-bold text-start">
                            <th className="text-start p-2">{`Start Date`}</th>
                            <th className="text-start p-2">{`End Date`}</th>
                            <th className="text-start p-2">{`Organisation`}</th>
                            <th className="text-start p-2">{`Cadre`}</th>
                        </tr>
                        <tbody>
                            {result?.practicals?.map( ( item, index ) => {
                                return (
                                    <tr key={index} className="text-xs p-2 text-start border">
                                        <td className="py-2 text-center">{item?.start_date}</td>
                                        <td className="py-2 text-center">{item?.end_date}</td>
                                        <td className="py-2 text-center">{item?.organisation}</td>
                                        <td className="py-2 text-center">{item?.cadre}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </Panel>}

            {result?.application_type !== "RENEW" && 
            <Panel label="Section B: Sponsor Particular">
                <div className="grid grid-cols-1 bg-white">
                    <table className="w-full border">
                        <tr className="text-xs font-bold text-start">
                            <th className="text-start p-2">{`Name`}</th>
                            <th className="text-start p-2">{`Registered`}</th>
                            <th className="text-start p-2">{`Reg #`}</th>
                            <th className="text-start p-2">{`Discipline`}</th>
                            <th className="text-start p-2">{`Status`}</th>
                        </tr>
                        <tbody>
                            {result?.sponsors?.map( ( item, index ) => {
                                return (
                                    <tr key={index} className="text-xs p-2 text-start border">
                                        <td className="py-2 text-center">{item?.sponsor_name}</td>
                                        <td className="py-2 text-center">{item?.registered}</td>
                                        <td className="py-2 text-center">{item?.registration_number}</td>
                                        <td className="py-2 text-center">{item?.discipline}</td>
                                        <td className="py-2 text-center">{item?.status}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </Panel>}

            {result?.application_type !== "RENEW" && 
            <Panel label="Section B: Reports">
                <div className="grid grid-cols-1 bg-white">
                <table className="w-full border">
                    <tr className="text-xs font-bold text-start">
                        <th className="text-start p-2">{`Technical Report`}</th>
                        <th className="text-start p-2">{`Career Report`}</th>
                    </tr>
                    <tbody>
                        {result?.membership?.map( ( item, index ) => {
                            return (
                                <tr key={index} className="text-xs p-2 text-start border">
                                    <td className="py-2 text-center">
                                        <a className="bg-green-800 text-white px-3 py-1" href={item?.attach_file} download>{`Download File`}</a>
                                    </td>
                                    <td className="py-2 text-center">
                                        <a className="bg-green-800 text-white px-3 py-1" href={item?.attach_file} download>{`Download File`}</a>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </div>
            </Panel>}
                

            {( admin === "REGISTRAR" || admin === "SUBCOMMITEE" || admin === "BOARD" ) && (
                <>
                    <Panel label="Remarks">
                        <div className="grid grid-cols-1 bg-white">
                            <table className="w-full border">
                                <tr className="text-xs font-bold text-start">
                                    <th className="text-start p-2">{`Date`}</th>
                                    <th className="text-start p-2">{`Remark`}</th>
                                    <th className="text-start">{`Actor`}</th>
                                    <th className="text-start p-2">{`Action`}</th>
                                </tr>
                                <tbody>
                                    {result?.remarks?.map( ( item, index ) => {
                                        return (
                                            <tr key={index} className="text-xs p-2 text-start border">
                                                <td className="py-2 text-center">{moment( item?.created_at ).format("DD-MMM-YYYY")}</td>
                                                <td className="py-2 text-center">{item?.comments}</td>
                                                <td className="py-2 text-center">{item?.actor_role}</td>
                                                <td className="py-2 text-center">{item?.action}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Panel>
                
                    <Panel label="Action">
                        <form onSubmit={handleAction}>
                            <div className="grid grid-cols-1 gap-2">
                                <div className="flex flex-col">
                                    <label>{`Comment/Remark`}</label>
                                    <textarea className="p-2" placeholder='Please add a comment' required rows={2} cols={1} name="comment" onChange={ e => setComment( e.target.value)} value={comment}></textarea>
                                </div>

                                <div className="flex flex-col items-start">
                                    <label>{`Actions`}</label>
                                    <select  className="p-2 border border-gray-100" name="action" value={action} onChange={ e => setAction( e.target.value )} required>
                                        <option selected>Choose...</option>
                                        {[ 
                                            { id: 1, val: "Defer", label: "Defer Application to Applicant" }, 
                                            { id: 2, val: "Approve", label: "Approve" } 
                                            ]?.map( item => {
                                                return <option key={item.id} value={item?.val}>
                                                    {item.label?.toUpperCase()}
                                                </option>
                                            } )}
                                    </select>
                                </div>
                                
                                <div className="w-full flex flex-row justify-between items-center">
                                    <div />
                                    { uploading ? (
                                        <CircularProgress size={24} color="primary" />
                                    ) : <button type="submit" className="bg-green-800 text-base text-white px-2">
                                        {`Submit`}
                                    </button> }
                                </div>

                            </div>
                        </form>
                    </Panel>
                </>
            ) }
            </div>
        </> ) }
        </div>
    </Wrap>
  )
}
