import Axios from 'axios'

const url  = `http://localhost:8000/account`

export function requestGetUser() {
    return Axios.request( {
        method: "get",
        url: "https://jsonplaceholder.typicode.com/todos/1"
    } )
}

export function authLoginUser( { 
    email, first_name, last_name, phone_number, legal_status, 
    company_name, company_email, contact_person, contact_person_position, 
    department, role, password 
} ) {
    return Axios.request( {
        method: "post",
        url: `${url}/register/`,
        data: {
            email,
            first_name,
            last_name,
            phone_number,
            legal_status,
            company_email,
            company_name,
            contact_person,
            contact_person_position,
            department,
            role,
            password
        }
    } )
}