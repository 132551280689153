export const getUserDetails = () => {
    if( localStorage.getItem( "token" ) ) {
        const user = JSON.parse( localStorage.getItem( "user" ) )
        return {
            name: user.name,
            company: user.company_name,
            email: user.email,
            phone: user.telephone
        }
    }
}

export const sumFooterColumns = ( result ) => {
    let { building_civil_works, furniture, installation_costs,
        machinery_equipment, land, office_equipment, plant, 
        pre_operating_cost, transport_vehicle, 
    share_capital, other_sources, loan_capital } = result?.financing | {}


   let finance_1 =  [ "1" ].map( item => {
    return parseFloat( result?.financing?.building_civil_works?.[ item ] || 0 ) + parseFloat( result?.financing?.furniture?.[ item ] || 0 ) +
    parseFloat( result?.financing?.installation_costs?.[ item ] || 0 ) + parseFloat( result?.financing?.machinery_equipment?.[ item ] || 0 ) + 
    parseFloat( result?.financing?.land?.[ item ] || 0 ) + parseFloat( result?.financing?.office_equipment?.[ item ] || 0 ) +
    parseFloat( result?.financing?.plant?.[ item ] || 0 ) + parseFloat( result?.financing?.pre_operating_cost?.[ item ] || 0 ) + 
    parseFloat( result?.financing?.transport_vehicle?.[ item ] || 0 )
   } )

   let finance_2 =  [ "2" ].map( item => {
    return parseFloat( result?.financing?.building_civil_works?.[ item ] || 0 ) + parseFloat( result?.financing?.furniture?.[ item ] || 0 ) +
    parseFloat( result?.financing?.installation_costs?.[ item ] || 0 ) + parseFloat( result?.financing?.machinery_equipment?.[ item ] || 0 ) + 
    parseFloat( result?.financing?.land?.[ item ] || 0 ) + parseFloat( result?.financing?.office_equipment?.[ item ] || 0 ) +
    parseFloat( result?.financing?.plant?.[ item ] || 0 ) + parseFloat( result?.financing?.pre_operating_cost?.[ item ] || 0 ) + 
    parseFloat( result?.financing?.transport_vehicle?.[ item ] || 0 )
   } )

   let finance_3 =  [ "3" ].map( item => {
    return parseFloat( result?.financing?.building_civil_works?.[ item ] || 0 ) + parseFloat( result?.financing?.furniture?.[ item ] || 0 ) +
    parseFloat( result?.financing?.installation_costs?.[ item ] || 0 ) + parseFloat( result?.financing?.machinery_equipment?.[ item ] || 0 ) + 
    parseFloat( result?.financing?.land?.[ item ] || 0 ) + parseFloat( result?.financing?.office_equipment?.[ item ] || 0 ) +
    parseFloat( result?.financing?.plant?.[ item ] || 0 ) + parseFloat( result?.financing?.pre_operating_cost?.[ item ] || 0 ) + 
    parseFloat( result?.financing?.transport_vehicle?.[ item ] || 0 )
   } )

   let funding_1 = [ "foreign" ].map( item => {
    return parseFloat( result?.financing?.share_capital?.[ item ] || 0 ) + parseFloat( result?.financing?.loan_capital?.[ item ] || 0 ) +
    parseFloat( result?.financing?.other_sources?.[ item ] || 0 ) 
   } )

   let funding_2 = [ "local" ].map( item => {
    return parseFloat( result?.financing?.share_capital?.[ item ] || 0 ) + parseFloat( result?.financing?.loan_capital?.[ item ] || 0 ) +
    parseFloat( result?.financing?.other_sources?.[ item ] || 0 ) 
   } )

   let funding_total = [ "total" ].map( item => {
    return parseFloat( result?.financing?.share_capital?.[ item ] || 0 ) + parseFloat( result?.financing?.loan_capital?.[ item ] || 0 ) +
    parseFloat( result?.financing?.other_sources?.[ item ] || 0 ) 
   } )


   //employment
   const { 
    administration_accounts_foreign, 
    administration_accounts_local,
    administration_accounts_total,
    managerial_supervisory_foreign,
    managerial_supervisory_local,
    managerial_supervisory_total,
    not_skilled_foreign,
    not_skilled_local,
    not_skilled_total,
    semi_skilled_foreign,
    semi_skilled_local,
    semi_skilled_total,
    skilled_foreign,
    skilled_local,
    skilled_total,
    technicians_foreign,
    technicians_local,
    technicians_total
   } = result?.employment || {}

   let employment_foreign = parseFloat( administration_accounts_foreign ) +  
   parseFloat( managerial_supervisory_foreign ) + 
   parseFloat( not_skilled_foreign ) + 
   parseFloat( semi_skilled_foreign ) + 
   parseFloat( skilled_foreign ) +
   parseFloat( technicians_foreign )

   let employment_local = parseFloat( administration_accounts_local ) +  
   parseFloat( managerial_supervisory_local ) + 
   parseFloat( not_skilled_local ) + 
   parseFloat( semi_skilled_local ) + 
   parseFloat( skilled_local ) +
   parseFloat( technicians_local )

   let employment_total = parseFloat( administration_accounts_total ) +  
   parseFloat( managerial_supervisory_total ) + 
   parseFloat( not_skilled_total ) + 
   parseFloat( semi_skilled_total ) + 
   parseFloat( skilled_total ) +
   parseFloat( technicians_total )

   return {
    finance_1,
    finance_2,
    finance_3,
    finance_total: parseFloat( finance_1 || 0 ) + parseFloat( finance_2 || 0 ) + parseFloat( finance_3 || 0 ),
    
    funding_1,
    funding_2,
    funding_total,

    employment_foreign,
    employment_local,
    employment_total
   }
}

export const truncate = ( str, n ) => ( str.length > n ) ? str.substr( 0, n - 1 ) + '...' : str

export const API_BASE = `https://mis.nec.go.ug/api/auth`
// export const API_BASE = `https://uia-api.taufeeq.dev/api`

export const calcFooterSums = ( tag, data ) => {
    //employment details
    if( tag === "foreign" ) {
        let total = parseFloat( data.filter( val => val.slug === "administration" )?.[ 0 ]?.foreign2 || 0 ) +
         parseFloat( data.filter( val => val.slug === "managerial" )?.[ 0 ]?.foreign2 || 0 ) + parseFloat( data.filter( val => val.slug === "technician" )?.[ 0 ]?.foreign2 || 0 ) +
         parseFloat( data.filter( val => val.slug === "skilled" )?.[ 0 ]?.foreign2 || 0 ) + parseFloat( data.filter( val => val.slug === "semiskilled" )?.[ 0 ]?.foreign2 || 0 ) +
         parseFloat( data.filter( val => val.slug === "notskilled" )?.[ 0 ]?.foreign2 || 0 )
        return total
    }

    if( tag === "local" ) {
        let total = parseFloat( data.filter( val => val.slug === "administration" )?.[ 0 ]?.local2 || 0 ) +
         parseFloat( data.filter( val => val.slug === "managerial" )?.[ 0 ]?.local2 || 0 ) + parseFloat( data.filter( val => val.slug === "technician" )?.[ 0 ]?.local2 || 0 ) +
         parseFloat( data.filter( val => val.slug === "skilled" )?.[ 0 ]?.local2 || 0 ) + parseFloat( data.filter( val => val.slug === "semiskilled" )?.[ 0 ]?.local2 || 0 ) +
         parseFloat( data.filter( val => val.slug === "notskilled" )?.[ 0 ]?.local2 || 0 )
        return total
    }

    if( tag === "total" ) {
        let total = parseFloat( data?.employment?.administration_accounts_total ) +
         parseFloat( data?.employment?.managerial_supervisory_total ) + parseFloat( data?.employment?.technicians_total ) +
         parseFloat( data?.employment?.not_skilled_total ) + parseFloat( data?.employment?.skilled_total ) +
         parseFloat( data?.employment?.semi_skilled_total )
        return total
    }

    //finance
    if( tag === "first" ) {
        let total = parseFloat( data.filter( val => val.slug === "building" )?.[ 0 ]?.firstYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "furniture" )?.[ 0 ]?.firstYear || 0 ) + parseFloat( data.filter( val => val.slug === "installation" )?.[ 0 ]?.firstYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "land" )?.[ 0 ]?.firstYear || 0 ) + parseFloat( data.filter( val => val.slug === "machinery" )?.[ 0 ]?.firstYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "equipment" )?.[ 0 ]?.firstYear || 0 ) + parseFloat( data.filter( val => val.slug === "plant" )?.[ 0 ]?.firstYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "preoperating" )?.[ 0 ]?.firstYear || 0 ) + parseFloat( data.filter( val => val.slug === "transport" )?.[ 0 ]?.firstYear || 0 )
        return total
    }

    if( tag === "second" ) {
        let total = parseFloat( data.filter( val => val.slug === "building" )?.[ 0 ]?.secondYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "furniture" )?.[ 0 ]?.secondYear || 0 ) + parseFloat( data.filter( val => val.slug === "installation" )?.[ 0 ]?.secondYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "land" )?.[ 0 ]?.secondYear || 0 ) + parseFloat( data.filter( val => val.slug === "machinery" )?.[ 0 ]?.secondYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "equipment" )?.[ 0 ]?.secondYear || 0 ) + parseFloat( data.filter( val => val.slug === "plant" )?.[ 0 ]?.secondYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "preoperating" )?.[ 0 ]?.secondYear || 0 ) + parseFloat( data.filter( val => val.slug === "transport" )?.[ 0 ]?.secondYear || 0 )
        return total
    }

    if( tag === "third" ) {
        let total = parseFloat( data.filter( val => val.slug === "building" )?.[ 0 ]?.thirdYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "furniture" )?.[ 0 ]?.thirdYear || 0 ) + parseFloat( data.filter( val => val.slug === "installation" )?.[ 0 ]?.thirdYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "land" )?.[ 0 ]?.thirdYear || 0 ) + parseFloat( data.filter( val => val.slug === "machinery" )?.[ 0 ]?.thirdYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "equipment" )?.[ 0 ]?.thirdYear || 0 ) + parseFloat( data.filter( val => val.slug === "plant" )?.[ 0 ]?.thirdYear || 0 ) +
         parseFloat( data.filter( val => val.slug === "preoperating" )?.[ 0 ]?.thirdYear || 0 ) + parseFloat( data.filter( val => val.slug === "transport" )?.[ 0 ]?.thirdYear || 0 )
        return total
    }


    if( tag === "funding_foreign" ) {
        let total = parseFloat( data.filter( val => val.slug === "loan" )?.[ 0 ]?.foreign || 0 ) + parseFloat( data.filter( val => val.slug === "share" )?.[ 0 ]?.foreign || 0 ) + parseFloat( data.filter( val => val.slug === "other" )?.[ 0 ]?.foreign || 0 )
        return total
    }

    if( tag === "funding_local" ) {
        let total = parseFloat( data.filter( val => val.slug === "loan" )?.[ 0 ]?.local || 0 ) + parseFloat( data.filter( val => val.slug === "share" )?.[ 0 ]?.local || 0 ) + parseFloat( data.filter( val => val.slug === "other" )?.[ 0 ]?.local || 0 )
        return total
    }
}

export const formatCurrency = amount => {
    const formatter = new Intl.NumberFormat('en-US', {
        // style: 'currency',
        // currency: 'UGX',
    } )
    return formatter.format( amount );
}

export const sanitizeValue = ( val ) => val?.replace(/([^\w ]|_)/g, '')


export const convertFileToBase = ( selectedFile, callback ) => {
    if( selectedFile.length > 0 ) {
        let fileToLoad = selectedFile[ 0 ]
        let fileReader = new FileReader()
        let base64
        fileReader.onload =  function( fileLoadedEvt ) {
            base64 = fileLoadedEvt.target.result
            callback( base64 )
        }
        fileReader.readAsDataURL( fileToLoad )
    }
}

export const calculateProgress =  () => {}