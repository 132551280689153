import React from 'react'
import avatar from '../_images/avatar.png'

export default function UserProfileImage( { img, label } ) {
  return (
    <div className="flex flex-row items-center">
      <div className="rounded-full w-8 h-8 overflow-hidden  border border-gray-300">
        <img src={img || avatar} alt="Image" />
      </div>
      <span className="text-gray-700 mx-2 text-xs">
        {label}
      </span>
    </div>
  )
}
