import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FaAddressBook, FaAddressCard, FaAngleDown, FaAngleUp, FaBookmark, FaHome, FaIdCard, FaPlusSquare } from 'react-icons/fa'

export const menu = [
    {
        id: 1,
        title: "Dashboard",
        icon: <FaHome size={16} color={`orange`} />,
        iconClosed: <div />,
        iconOpened: <div />,
        tag: "dashboard",
        slug: "dashboard"
    },
    {
        id: 2,
        title: "My Applications",
        icon: <FaHome size={16} color={`orange`} />,
        iconClosed: <div />,
        iconOpened: <div />,
        tag: "my-applications",
        slug: "my-applications"
    },
    {
        id: 3,
        title: "Renewals",
        icon: <FaHome size={16} color={`orange`} />,
        iconClosed: <div />,
        iconOpened: <div />,
        tag: "my-renewals",
        slug: "my-renewals"
    },
    {
        id: 4,
        title: "Register",
        icon: <FaHome size={16} color={`orange`} />,
        iconClosed: <div />,
        iconOpened: <div />,
        tag: "engineers-register",
        slug: "engineers-register"
    },
    
]


export const applicant_menu = [
    {
        id: 1,
        title: "Apply for a Licence",
        icon: <FaHome size={16} color={`orange`} />,
        iconClosed: <div />,
        iconOpened: <div />,
        tag: "application",
        slug: "application"
    },
    {
        id: 2,
        title: "My Applications",
        icon: <FaHome size={16} color={`orange`} />,
        iconClosed: <div />,
        iconOpened: <div />,
        tag: "my-applications",
        slug: "my-applications"
    },
    {
        id: 2,
        title: "My Deferred Applications",
        icon: <FaHome size={16} color={`orange`} />,
        iconClosed: <div />,
        iconOpened: <div />,
        tag: "my-deferred-applications",
        slug: "my-deferred-applications"
    },
    {
        id: 3,
        title: "Sponsor Approvals",
        icon: <FaHome size={16} color={`orange`} />,
        iconClosed: <div />,
        iconOpened: <div />,
        tag: "my-sponsor-applications",
        slug: "my-sponsor-applications"
    },
    // {
    //     id: 4,
    //     title: "My Profile",
    //     icon: <FaHome size={16} color={`orange`} />,
    //     iconClosed: <div />,
    //     iconOpened: <div />,
    //     tag: "my-profile",
    //     slug: "my-profile"
    // },
]

export const licensee_menu = [
    {
        id: 1,
        title: "Renewal",
        icon: <FaHome size={16} color={`orange`} />,
        iconClosed: <div />,
        iconOpened: <div />,
        tag: "application",
        slug: "application"
    },
    {
        id: 2,
        title: "My Applications",
        icon: <FaHome size={16} color={`orange`} />,
        iconClosed: <div />,
        iconOpened: <div />,
        tag: "my-applications",
        slug: "my-applications"
    },
    {
        id: 3,
        title: "Sponsor Approvals",
        icon: <FaHome size={16} color={`orange`} />,
        iconClosed: <div />,
        iconOpened: <div />,
        tag: "my-sponsor-applications",
        slug: "my-sponsor-applications"
    },
    {
        id: 4,
        title: "My Profile",
        icon: <FaHome size={16} color={`orange`} />,
        iconClosed: <div />,
        iconOpened: <div />,
        tag: "my-profile",
        slug: "my-profile"
    },
]

export default function Sidebar( { } ) {
  const navigate = useNavigate()
  const location = useLocation()

//   console.log(  )

  return (
    <div>
        <aside className="h-full md:block lg:block md:w-[200px] lg:w-[200px] bg-white border-none outline-none fixed flex flex-col items-center justify-start py-5 shadow-xl">
            {( localStorage.getItem( "role") === "applicant" && localStorage.getItem( "registration_type" ) === "No" ) && applicant_menu.map( ( item, index ) => {
                return <SidebarLink navigate={navigate} key={index} item={item} active={ location.pathname.split( "/" )?.[ 1 ] === item.tag } />
            } )}

            {( localStorage.getItem( "role") === "applicant" && localStorage.getItem( "registration_type" ) === "Yes" ) && licensee_menu.map( ( item, index ) => {
                return <SidebarLink navigate={navigate} key={index} item={item} active={ location.pathname.split( "/" )?.[ 1 ] === item.tag } />
            } )}

            {localStorage.getItem( "role") !== "applicant" && menu.map( ( item, index ) => {
                return <SidebarLink navigate={navigate} key={index} item={item} active={ location.pathname.split( "/" )?.[ 1 ] === item.tag } />
            } )}
        </aside>
    </div>
  )
}


const SidebarLink = ( { item, navigate, active } ) => {
    const [ subnav, setSubNav ] = React.useState( false )

    const showSubNav = () => setSubNav( !subnav )

    const handlePress  = async slug => {
        if( slug === "home" ) {
            return navigate( "/" )
        }

        if( typeof( slug ) === "string" ) {
            return navigate( `/${slug}` )
        }

        if( typeof( slug ) === "object" ) {
            setSubNav( !subnav )
            return navigate( `/${slug?.[ 1 ]}` );
        }
    }

    return (
        <>
        <div style={{ backgroundColor: active && "#000", color: active && `#FFF`}} onClick={item.subNav ? showSubNav : () => handlePress( item.slug )} className={`${localStorage.getItem( "parent" ) === item.slug ? "bg-[#252831] border-l-2 text-white flex justify-between items-center p-2 h-12 text-xs hover:bg-[#252831] hover:border-l-2 hover:text-white hover:cursor-pointer" : "text-black flex justify-between items-center p-2 h-10 text-xs hover:bg-[#252831] hover:border-l-2 hover:text-white hover:cursor-pointer" }`}>
            <div className="flex items-center justify-center">
                {item.icon}
                <div className="ml-4">
                    {item.title}
                </div>
            </div>
            <div>
                {item.subNav &&  subnav ? item.iconOpened : item.subNav ? item.iconClosed : null }
            </div>
        </div>
        {subnav && item.subNav.map( ( val, index ) => {
            return (
                <div onClick={() => handlePress( val.slug )} key={index} className={`${localStorage.getItem( "link" ) === val.slug?.[ 1 ] && "bg-gray-50 text-black font-bold"} bg-white h-10 border-b border-gray-300 flex items-center justify-start no-underline text-gray-700 text-xs hover:bg-gray-900 hover:text-white hover:cursor-pointer`}>
                    <div className="text-xs px-5">
                        {val.title}
                    </div>
                </div>
            )
        }  )}
        </>
    )
}
