import React, { useEffect, useState } from 'react'
import { Panel, TextFieldIcon, SelectFieldIcon, PhoneFieldIcon, TextField } from '../../../../_components'
import { useDispatch, useSelector } from 'react-redux'
import data from '../../../fixtures'
import { convertFileToBase } from '../../../../_utils'

export default function SectionA( {userInfo, handleBlur, handleChange, values, setAlertTIN, stockers, loading, list, handleErrorTIN, handleSelectedBRN } ) {
  const dispatch = useDispatch()

  const handleFileChange = e => {
    convertFileToBase( e.target.files, response => {
      // console.log( response );
    } )
  }
  
  return (
    <>
        <Panel label="Section A: Particulars Information">
          <div className="bg-white grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2 p-2">
              <TextFieldIcon 
                name="name"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.name}
                question="Name in Full"
                label="Name in Full"
                required={true}
                disabled={true}
              />

              <TextFieldIcon 
                name="address"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.address}
                question="Postal Address"
                label="Postal Address"
                required={true}
                disabled={true}
              />

              <TextFieldIcon 
                name="email_address"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.email_address}
                question="Email Address"
                label="Email Address"
                required={true}
                disabled={true}
              />

              <TextFieldIcon 
                name="telephone"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.telephone}
                question="Telephone No."
                label="Telephone No."
                required={true}
                disabled={true}
              />

              <TextFieldIcon 
                name="birth_place"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.birth_place}
                question="Birth Place"
                label="Birth Place"
                required={true}
                disabled={true}
              />

              <TextFieldIcon 
                name="birth_place"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={userInfo?.birth_place}
                question="Birth Place"
                label="Birth Place"
                required={true}
                disabled={true}
              />

              <TextFieldIcon 
                name="discipline"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={userInfo?.category}
                question="Discipline"
                label="Discipline"
                required={true}
                disabled={true}
              />

              <TextFieldIcon 
                name="registered"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={userInfo?.registered}
                question="Registration Status"
                label="Registration Status"
                required={true}
                disabled={true}
              />

              <TextFieldIcon 
                name="licence_no"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={userInfo?.registered === "Yes" ? userInfo?.licence_no : "N/A"}
                question="Licence Number"
                label="Licence Number"
                required={true}
                disabled={true}
              />
          </div>
        </Panel>
    </>
  )
}
