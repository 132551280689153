import React from 'react'
import UIALogo from '../../_images/UIA_logo.png'
import { useNavigate } from 'react-router-dom'

export default function ActivatedAccount() {
  const navigate = useNavigate()
  

  return (
    <div className="bg-gray-50 py-5">
      <div className="w-full h-full flex items-center justify-center">
        <div className= "md:p-12 lg:p-12 p-5 lg:w-2/5 md:w-2/5 w-full h-auto bg-white rounded shadow-md mx-2">
          <div className="flex flex-col items-center justify-center">
            <div className="flex w-full items-center justify-evenly">
              <img src={UIALogo} alt="Coat of Arms" className="md:w-24 lg:w-24 w-20" />
            </div>
            <h2 className="font-bold px-3 py-2 text-center md:text-xl lg:text-xl text-base">
              {`Welcome to eBiz`}
            </h2>
          </div>
            <h2 className="text-center text-base">
            {`Your Ebiz Account has been successfully activated`}
            </h2>
            <div onClick={() => navigate("/login")} className="cursor-pointer p-5 my-2 bg-green-500 flex items-center justify-center">
              {`Proceed to login to access your account`}
            </div>
        </div>
        </div>
      <div> 
      </div>
    </div>
  )
}
